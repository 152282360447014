import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages_en from './translations/en.json';
import messages_en_GB from './translations/en_GB.json';
import { getLocale } from './utils/useful_functions';

const ProviderWrapper = (props: any) => {
  const locale = getLocale();
  const messages = {
    en: messages_en,
    'en-GB': messages_en_GB,
  };

  return (
    <Provider store={props.store}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {props.children}
      </IntlProvider>
    </Provider>
  );
};

export default ProviderWrapper;
