import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Grid,
} from 'semantic-ui-react';
import { getWithAuth } from 'src/api';
import LPGrid from './controls/lpgrid/LPGrid';
import messages from 'src/messages';
import { useSelector } from 'react-redux';
import { postWithAuth } from '../api';
import Extras from './Extras';

export function BomGrid(props) {
  const {
    objId,
    accordionTitle,
    rowData,
    autoLoad,
    extras,
    setAddingExtra,
    refresh,
    deleteExtra,
    selectedItems,
    setSelectedItems,
    editable = true,
    tsActions,
    toolbarSlot,
    updateItemsUrlConfig,
  } = props;
  const { projectId, projectName, permissionWrite } = useSelector(
    (state) => state.mainFrame
  );
  const [error, setError] = useState(null);
  const tableRef = useRef();
  let actions = [];
  if (permissionWrite && extras != null) {
    actions = [
      {
        icon: 'plus',
        label: 'New Extra',
        onClick: (event, data, params) => {
          setAddingExtra(true);
        },
        disabled: false,
      },
      {
        icon: 'trash alternate',
        label: 'Delete Extra',
        onClick: (event, data, params) => {
          deleteExtra(selectedItems);
        },
        disabled: !selectedItems?.some((item) => item.is_extra),
      },
      ...tsActions,
    ];
  }

  const getItemUpdateUrlConfig = (
    item_id,
    quantity,
    note,
    kind,
    tsId,
    reset
  ) => {
    const url =
      updateItemsUrlConfig.url ??
      `project/${projectId}/bom/${kind ? tsId : objId}`;
    const params = updateItemsUrlConfig.postObj ?? {};
    const postObj = { ...params, item_id, quantity, note, reset };
    return { url, postObj };
  };

  const updateItem = (item_id, quantity, note, kind, tsId, reset = false) => {
    setError(null);
    const { url, postObj } = getItemUpdateUrlConfig(
      item_id,
      quantity,
      note,
      kind,
      tsId,
      reset
    );
    postWithAuth(url, postObj, 'PUT')
      .then(() => {
        if (refresh) {
          refresh();
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.detail);
      });
  };

  let qtyEditAttrs;
  if (editable) {
    qtyEditAttrs = {
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      cellEditorParams: {
        precision: 0,
        min: 0,
        max: 9999,
        step: 1,
        reset: ({ data, node }) => {
          if (Number(data.quantity) !== Number(data.original_quantity)) {
            updateItem(
              data.moto_no,
              data.original_quantity,
              null,
              data?.kind,
              data?.obj_id,
              true
            );
          }
        },
      },
      onCellValueChanged: (rowData) => {
        const { data, newValue, oldValue } = rowData;
        const value = parseFloat(newValue);
        if (value !== parseFloat(oldValue)) {
          updateItem(data.moto_no, value, null, data?.kind, data?.obj_id);
        }
      },
    };
  } else {
    qtyEditAttrs = {};
  }

  return (
    <div className="bom-grid-container">
      {error && (
        <Segment inverted color="red">
          {error}
        </Segment>
      )}
      <LPGrid
        rowSelection="multiple"
        autoSize={false}
        gridRef={tableRef}
        suppressRowClickSelection={false}
        onSelectionChanged={(event) => {
          if (setSelectedItems != null) {
            const selection = event.api.getSelectedRows();
            setSelectedItems(selection);
          }
        }}
        isRowSelectable={(node) => {
          return node.data?.is_extra && (node.data?.editable ?? true);
        }}
        stopEditingWhenCellsLoseFocus
        stopEditingWhenGridLosesFocus
        pagination={false}
        extraActions={actions}
        rowData={rowData}
        autoLoad={autoLoad}
        defaultCsvExportParams={{
          fileName: `${projectName}_${accordionTitle.replaceAll(' ', '_')}.csv`,
        }}
        getRowStyle={(params) => {
          if (params.data.is_extra) {
            return;
          }

          if (params.data.delta < 0) {
            return { color: 'red' };
          }

          if (params.data.delta > 0) {
            return { color: 'green' };
          }
        }}
        columnDefs={[
          {
            field: 'moto_no',
            headerName: 'P/N',
            pinned: true,
            valueGetter: ({ data }) => {
              if (data.moto_no == null) {
                return '(no part number)';
              }
              return data.moto_no;
            },
            cellRenderer: (params) => {
              if (params.data.is_extra) {
                return (
                  `<span style="display:flex;align-items:center">` +
                  `<img src="/assets/asterisk-small-yellow.png" alt="extra" /> ` +
                  `${params.value}</span>`
                );
              }
              return params.value;
            },
            suppressSizeToFit: true,
            checkboxSelection: ({ data }) => {
              return data.editable ?? editable;
            },
            headerCheckboxSelection: false,
            minWidth: 170,
            maxWidth: 170,
          },
          {
            field: 'description',
            minWidth: 390,
            wrapText: true,
            autoHeight: true,
            cellClass: ['less-line-height'],
          },
          {
            field: 'quantity',
            headerName: 'Qty',
            suppressSizeToFit: true,
            flex: 0,
            minWidth: 90,
            maxWidth: 90,
            ...qtyEditAttrs,
            editable: ({ data }) => {
              return data.editable ?? editable;
            },
          },
          {
            field: 'note',
            headerName: 'Notes',
            minWidth: 500,
          },
        ]}
        toolbarSlot={toolbarSlot}
      />
    </div>
  );
}

function gatherExtras(extras, selectedExtras, end_name) {
  if (extras == null) {
    return [];
  }

  return Object.values(extras)
    .flat()
    .filter((extra) => selectedExtras.has(extra.id))
    .map((item) => ({
      id: item.id,
      meta_description: item.meta_description,
      is_extra: true,
      end_name,
    }));
}

function BomModal(props) {
  const {
    objId,
    kind,
    url,
    modified,
    disabled,
    name,
    localProduct,
    remoteProduct,
    toolbarSlot,
    // below props are related to tower switch
    children = null,
    showTowerSwitch = false,
    setShowTowerSwitch = null,
    towerSwitch = null,
    tsActions = [],
    bomRefresh = null,
    setBomRefresh = null,
    onCloseCallback = null,
    updateItemsUrlConfig = {},
    addExtrasUrlConfig = {},
    deleteExtrasUrlConfig = {},
  } = props;
  const { projectId } = useSelector((state) => state.mainFrame);
  const { formatMessage } = props.intl;
  const [open, setOpen] = useState(false);
  const [bom, setBom] = useState(null);
  const [aggBom, setAggBom] = useState(null);
  const [warnings, setWarnings] = useState(null);
  const [extras, setExtras] = useState(null);
  const [remoteExtras, setRemoteExtras] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [addingExtra, setAddingExtra] = useState(false);
  // selected extras in the extras list view
  const [selectedExtras, setSelectedExtras] = useState(new Set());
  // selected items in the grid view
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (showTowerSwitch) setOpen(true);
  }, [showTowerSwitch]);

  useEffect(() => {
    if ((open || refresh) && url) {
      getWithAuth(url).then((res) => {
        setBom(res.bom);
        setWarnings(res.warnings);
        if ('aggregate' in res) {
          setAggBom(res.aggregate);
        }
        if ('extras' in res) {
          setExtras(res.extras);
        }
        if ('remote_extras' in res) {
          setRemoteExtras(res.remote_extras);
        } else {
          setRemoteExtras(null);
        }
      });
    }
  }, [open, refresh, url, bomRefresh]);

  useEffect(() => {
    if (towerSwitch) {
      setBom(towerSwitch.bom);
      setWarnings(towerSwitch.warnings);
      if ('aggregate' in towerSwitch) {
        setAggBom(towerSwitch.aggregate);
      }
      if ('extras' in towerSwitch) {
        setExtras(towerSwitch.extras);
      }
      if ('remote_extras' in towerSwitch) {
        setRemoteExtras(towerSwitch.remote_extras);
      } else {
        setRemoteExtras(null);
      }
    }
  }, [towerSwitch]);

  const addExtras = () => {
    let choices = gatherExtras(extras, selectedExtras, 'local');
    choices.push(...gatherExtras(remoteExtras, selectedExtras, 'remote'));

    if (choices.length === 0) {
      return;
    }

    const url = addExtrasUrlConfig.url
      ? addExtrasUrlConfig.url
      : `project/${projectId}/bom`;

    let postObj = {
      id: showTowerSwitch ? towerSwitch.id : objId,
      kind: showTowerSwitch ? 'tower_switch' : kind,
      items: choices,
    };

    if (addExtrasUrlConfig.postObj != null) {
      postObj = { ...addExtrasUrlConfig.postObj, kind, items: choices };
    }
    postWithAuth(url, postObj)
      .then((res) => {
        setRefresh({ status: true });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSelectedExtras(new Set());
        setAddingExtra(false);
      });
  };

  const deleteExtra = (items) => {
    if (!items.every((item) => item.is_extra)) {
      return;
    }

    const url = deleteExtrasUrlConfig.url
      ? deleteExtrasUrlConfig.url
      : `project/${projectId}/bom/${showTowerSwitch ? towerSwitch.id : objId}`;

    let postObj = {
      ids: items.map((item) => item.moto_no),
      meta_names: items.map((item) => item.meta_name),
      kinds: items.map((item) => item.kind),
      obj_ids: items.map((item) => item.obj_id),
    };
    if (deleteExtrasUrlConfig.postObj) {
      postObj = {
        ...deleteExtrasUrlConfig.postObj,
        item_ids: items.map((item) => item.meta_name),
      };
    }

    postWithAuth(url, postObj, 'DELETE')
      .then((res) => {
        if (res.status === 'success') {
          setRefresh({ status: true });
          setSelectedItems([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let headerText;
  if (
    kind === 'access_point' ||
    kind === 'subscriber' ||
    kind === 'network_site'
  ) {
    if (kind === 'network_site') {
      if (showTowerSwitch) headerText = 'Tower Switch';
      else headerText = name;
    } else headerText = `Bill of Materials: ${name}`;
  } else if (kind === 'ptp') {
    headerText = 'Bill of Materials for Link';
  } else {
    headerText = 'Bill of Materials';
  }

  const onClose = () => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    if (showTowerSwitch) {
      setShowTowerSwitch(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      closeOnEscape={false}
      size="large"
      onOpen={() => {
        setOpen(true);
        setAddingExtra(false);
        setSelectedExtras(new Set());
        if (kind === 'network_site')
          setBomRefresh({
            status: true,
            tsurl: `project/${projectId}/site/${objId}/tower_switch`,
          });
      }}
      open={open}
      trigger={
        !showTowerSwitch && (
          <Button
            compact
            type="button"
            basic
            icon
            disabled={disabled || modified}
            title={
              modified
                ? 'Please save changes to enable BOM'
                : kind === 'network_site'
                ? `PMP Network Devices ${formatMessage(messages.bom)} `
                : formatMessage(messages.bom)
            }
          >
            <Icon className="bom-icon" size="large" />
          </Button>
        )
      }
    >
      <Modal.Header>
        {headerText}
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Content>
        {kind === 'access_point' && !addingExtra && (
          <Header>Network Device BOM</Header>
        )}
        {warnings?.length > 0 && (
          <Message error>
            {warnings.map((warning, i) => (
              <p key={`bom-warning-${url}`}>{warning}</p>
            ))}
          </Message>
        )}
        {addingExtra ? (
          remoteExtras != null ? (
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Extras
                    title={localProduct}
                    extras={extras}
                    selectedExtras={selectedExtras}
                    setSelectedExtras={setSelectedExtras}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Extras
                    title={remoteProduct}
                    extras={remoteExtras}
                    selectedExtras={selectedExtras}
                    setSelectedExtras={setSelectedExtras}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <Extras
              title={localProduct}
              extras={extras}
              selectedExtras={selectedExtras}
              setSelectedExtras={setSelectedExtras}
            />
          )
        ) : (
          <>
            {children}
            <div className={showTowerSwitch ? 'tsBOM' : ''}>
              {showTowerSwitch && <h4>{formatMessage(messages.bom)}</h4>}
              {!showTowerSwitch && children && <h4>PMP Network Devices BOM</h4>}
              <BomGrid
                objId={showTowerSwitch ? towerSwitch?.id ?? objId : objId}
                rowData={bom}
                updateItemsUrlConfig={updateItemsUrlConfig}
                extras={extras}
                setAddingExtra={setAddingExtra}
                refresh={() => setRefresh({ status: true })}
                deleteExtra={deleteExtra}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                accordionTitle={
                  kind === 'access_point' ||
                  kind === 'subscriber' ||
                  kind === 'network_site'
                    ? kind === 'network_site'
                      ? `PMP ND ${name} BOM`
                      : `${name} BOM`
                    : headerText
                }
                tsActions={tsActions}
                toolbarSlot={toolbarSlot}
              />
            </div>
            {aggBom && (
              <>
                {kind === 'access_point' && (
                  <Header>Subscriber Modules BOM</Header>
                )}
                <BomGrid
                  objId={objId}
                  rowData={aggBom}
                  editable={false}
                  accordionTitle={
                    kind === 'access_point' ||
                    kind === 'subscriber' ||
                    kind === 'network_site'
                      ? `${name} Subscriber Modules BOM`
                      : headerText
                  }
                />
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {addingExtra ? (
          <>
            <Button
              onClick={() => {
                setSelectedExtras(new Set());
                setAddingExtra(false);
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              color="blue"
              disabled={selectedExtras.size === 0}
              onClick={addExtras}
            >
              {formatMessage(messages.ok)}
            </Button>
          </>
        ) : (
          <Button onClick={onClose}>
            {formatMessage(messages.close)}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default injectIntl(BomModal);
