import { ToolbarType } from '../Toolbar';
import { get } from 'lodash';
import { RangeRenderer, fixedRenderer } from './cellrenderers/UnitRenderer';
import { meterToAny, pyround } from 'src/utils/useful_functions';
import {
  ColDef,
  ProcessCellForExportParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import {
  ADD_EDIT_TOWERSWITCH,
  CONVERT_TO_NETWORK_SITE,
  CONVERT_TO_SUBSCRIBER_SITE,
  CREATE_DUPLICATE_NETWORK_SITE,
  CREATE_DUPLICATE_SUBSCRIBER_SITE,
  DELETE,
  DELETE_TOWERSWITCH,
} from 'src/app.constants';

export const lpGridSwitchActionIds = [
  DELETE,
  ADD_EDIT_TOWERSWITCH,
  DELETE_TOWERSWITCH,
  CONVERT_TO_SUBSCRIBER_SITE,
  CONVERT_TO_NETWORK_SITE,
  CREATE_DUPLICATE_SUBSCRIBER_SITE,
  CREATE_DUPLICATE_NETWORK_SITE,
];

export const lpGridActions = (
  api,
  actions,
  permissionWrite = true
): ToolbarType[] => {
  const length = api?.getSelectedNodes().length;
  const modifiedActions = actions.map((action: ToolbarType) => {
    if (lpGridSwitchActionIds.includes(action.id))
      action.disabled = !permissionWrite || length === 0 ? true : false;
    return action;
  });
  return modifiedActions;
};

export const processCellCallback = (params: ProcessCellForExportParams) => {
  const { value, column, context } = params;
  const { prefs } = context;
  const colDef: ColDef = column.getUserProvidedColDef();
  const cellRendererParams = get(colDef, 'cellRendererParams', {});
  if (colDef.cellRenderer === 'heightRenderer') {
    if (prefs.heightUnits.toLowerCase() !== 'm') {
      // convert to feet
      return meterToAny(value, prefs.heightUnits);
    }
    if (cellRendererParams['precision'] != null) {
      const { precision } = cellRendererParams;
      return pyround(value, precision);
    }
  } else if (colDef.cellRenderer === 'fixedRenderer') {
    return fixedRenderer({ value, ...cellRendererParams });
  } else if (colDef.cellRenderer === 'rangeRenderer') {
    return RangeRenderer({ value, ...cellRendererParams });
  } else if (
    colDef.valueFormatter &&
    typeof colDef.valueFormatter === 'function'
  ) {
    const valueFormatter = colDef.valueFormatter;
    const valueFormatterParams: ValueFormatterParams = {
      ...params,
      colDef,
      data: value,
    } as unknown as ValueFormatterParams;
    return valueFormatter(valueFormatterParams);
  }
  return value;
};
