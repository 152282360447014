import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { Step } from 'semantic-ui-react';
import additionalMessages from '../../messages';
export type SiteCSVWizardPropsType = {
  step: number;
  groupKind: string;
} & WrappedComponentProps;

const SiteCSVWizard = ({ step, groupKind, intl }: SiteCSVWizardPropsType) => {
  const { formatMessage } = intl;

  let sampleSitesCsvUrl;
  if (groupKind === 'network_sites') {
    sampleSitesCsvUrl = '/sample/Network_Sites_Sample.csv';
  } else {
    sampleSitesCsvUrl = '/sample/Subscriber_Sites_Sample.csv';
  }

  if (process.env.NODE_ENV !== 'development') {
    sampleSitesCsvUrl = '/assets' + sampleSitesCsvUrl;
  }

  return (
    <Step.Group widths={2} ordered>
      <Step completed={step > 1} active={step === 1}>
        <Step.Content>
          <Step.Title>
            {formatMessage(additionalMessages.chooseFile)}
          </Step.Title>
          <Step.Description>
            <FormattedMessage
              id="additionalMessages.chooseFileDescr"
              defaultMessage="Please select a CSV, KML or KMZ file containing the place information.
              Click <a> here </a> to download an example of the CSV file format, noting that all column headings must still be included when any optional fields are empty."
              values={{
                a: (msg: string) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={sampleSitesCsvUrl}
                  >
                    {msg}
                  </a>
                ),
              }}
            />
          </Step.Description>
        </Step.Content>
      </Step>

      <Step completed={step > 2} active={step === 2}>
        <Step.Content>
          <Step.Title>
            {formatMessage(additionalMessages.verifyFile)}
          </Step.Title>
          <Step.Description>
            {formatMessage(additionalMessages.verifyFileDescription)}
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};
export default injectIntl(SiteCSVWizard);
