import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/App.css';
import messages_en from './translations/en.json';
import messages_en_GB from './translations/en_GB.json';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './query-client';
import { getLocale } from './utils/useful_functions';
import ErrorBoundaryComponent from './components/controls/ErrorBoundary';
import * as Sentry from '@sentry/react';
import {
  Route,
  Navigate,
  useNavigate,
  Routes,
  useLocation,
} from 'react-router-dom';
import LoginPanel from './components/LoginPanel';
import { MainLayout } from './components/MainLayout';

import ProjectsPanel from './pages/project/ProjectsPanel';
import ProjectPanel from './pages/project/ProjectPanel';
import {
  NetworkSitesPanel,
  SubscriberSitesPanel,
} from './pages/sites/SitesPanel';
import SitePanel from './pages/sites/SitePanel';
import MeshLinkPanel from './pages/mesh/MeshLinkPanel';
import PTPLinksPanel from './pages/ptp/PTPLinksPanel';
import PTPLinkPanel from './pages/ptp/PTPLinkPanel';
import AntennasPanel from './pages/antennas/AntennasPanel';
import BomPanel from './pages/bom/BomPanel';
import BestServerPanel from './pages/best_server/BestServerPanel';
import AboutPanel from './components/AboutPanel';
import TermsAndConditions from './components/TermsAndConditions';
import {
  setNavigateFunc,
  unloadProject,
} from './pages/mainframe/mainframe.reducer';
import TerragraphPanel from './pages/terragraph/TerragraphPanel';
import NDPanel from './pages/pmp/nd/NDPanel';
import SMPanel from './pages/pmp/sm/SMPanel';
import { states } from './pages/map/leafletCanvas';
import { getCanvas } from './utils/mapUtils';
import { setAfcMode } from './pages/map/mapToolbar';
import { store } from './store';
import PMPTabbedView from './pages/equipment/PMPTabbedView';

const messages = {
  en: messages_en,
  'en-GB': messages_en_GB,
};

export const AppContainer = () => {
  const { language, hasWritePermission, mapState } = useSelector(
    (state) => state.mainFrame
  );
  const locale = getLocale(language);
  const messageTranslations = messages[locale];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Check to see if user has accessed
  // the site from the /afc url which
  // should provide restricted functionality
  // and immediately activate AFC mode in the map.
  // This has to be called after the controls have initially
  // rendered
  if (
    mapState !== states.MAP_SELECT &&
    !(location.pathname === '/' || location.pathname === '/afc')
  ) {
    getCanvas().resetState();
  }

  useEffect(() => {
    if (
      mapState !== states.MAP_AFC_MODE &&
      location.pathname === '/afc' &&
      getCanvas()
    ) {
      store.dispatch(unloadProject());
      setAfcMode(true);
    }

    dispatch(setNavigateFunc(navigate));
    return () => {
      dispatch(setNavigateFunc(() => {}));
    };
  }, [location.pathname, mapState]);

  const routes = (
    <Routes>
      <Route path="/login" element={<LoginPanel />} />
      <Route path="/about" element={<AboutPanel />} />
      <Route path="/terms" element={<TermsAndConditions />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="projects" element={<ProjectsPanel />} />
        <Route path="project" element={<ProjectPanel />} />
        <Route path="afc" element={null} />

        <Route path="network_sites" element={<NetworkSitesPanel />}></Route>
        <Route path="network_sites/:siteId" element={<SitePanel />} />
        <Route
          path="subscriber_sites"
          element={<SubscriberSitesPanel />}
        ></Route>
        <Route path="subscriber_sites/:siteId" element={<SitePanel />} />
        <Route path="mesh/:meshId" element={<MeshLinkPanel />} />
        <Route path="aps" element={<PMPTabbedView />} />
        <Route path="subscribers" element={<PMPTabbedView />} />
        <Route path="mesh" element={<PMPTabbedView />} />
        <Route path="aps/:id" element={<NDPanel />} />

        <Route path="subscribers/:id" element={<SMPanel />} />

        <Route path="ptp" element={<PTPLinksPanel />} />
        <Route path="ptp/:id" element={<PTPLinkPanel />} />

        <Route path="bom" element={<BomPanel />} />

        <Route path="antennas/:antennaType" element={<AntennasPanel />} />

        <Route path="best_server" element={<BestServerPanel />} />

        {hasWritePermission && (
          <Route path="terragraph_planner" element={<TerragraphPanel />} />
        )}
        <Route path="*" element={<Navigate to="/projects" />} />
      </Route>
    </Routes>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} messages={messageTranslations}>
        {window.runtime?.loggingKey ? (
          <Sentry.ErrorBoundary showDialog>{routes}</Sentry.ErrorBoundary>
        ) : (
          <ErrorBoundaryComponent>{routes}</ErrorBoundaryComponent>
        )}
      </IntlProvider>
    </QueryClientProvider>
  );
};
