import * as d3 from 'd3';

import { pyround } from 'src/utils/useful_functions';
import {
  Capacity,
  Y_AXIS_MAP,
  MAX_AVAILABILITY,
} from './PerformanceChart.data';
import { Unit } from './PerformanceChart.types';

const truncateDecimal = (value: number, decimal: number): number => {
  if (Number.isInteger(value)) return value;

  const valueString = value.toString();
  return parseFloat(valueString.slice(0, valueString.indexOf('.') + decimal));
};

export const formatCapacityLabel = (
  capacity: number,
  unit: string,
  decimal: number = 2
): string => {
  const capacityValue =
    unit === Capacity.Gbps ? capacity : pyround(capacity, 0);
  const capacityWithDecimal = capacityValue.toFixed(decimal);

  return Number.isInteger(capacityValue)
    ? capacityValue.toString()
    : capacityWithDecimal;
};

export const formatAvailabilityLabel = (availability: number): string => {
  // Make sure availability has always 4 decimal places
  return Math.min(availability, MAX_AVAILABILITY).toFixed(4);
};

export const prepareChartDataSeries = ({ availability, capacity }) => {
  const availabilityList = [];
  const capacityList = [];

  // Process each subarray in the availability data
  availability.forEach((subArray, i) => {
    const filteredAvailability = [];
    const filteredCapacity = [];
    let lastAvail = null;
    let lastCapacity = null;

    subArray.forEach((avail, index) => {
      const truncatedAvail = truncateDecimal(avail, 6);
      const capacityItem = capacity[i][index];

      // Check if we need to add a new data point
      if (
        lastAvail === null || // First iteration
        truncatedAvail !== lastAvail || // New availability value
        index === subArray.length - 1 // Last item in subarray
      ) {
        // Add the previous data point if it exists
        if (lastAvail !== null) {
          filteredAvailability.push(lastAvail);
          filteredCapacity.push(lastCapacity);
        }
        // Update last seen values
        lastAvail = Math.min(truncatedAvail, MAX_AVAILABILITY);
        lastCapacity = capacityItem;
      } else {
        // Same availability, update capacity if higher
        lastCapacity = Math.max(lastCapacity, capacityItem);
      }
    });

    // Add the last data point if it exists
    if (lastAvail !== null) {
      filteredAvailability.push(lastAvail);
      filteredCapacity.push(lastCapacity);
    }

    // Add filtered data to the result lists
    availabilityList.push(filteredAvailability);
    capacityList.push(filteredCapacity);
  });

  // Return the filtered and consolidated data
  return { availabilityList, capacityList };
};

// The Y_AXIS_MAP values are used for the chart Y tick marks.
// the chart uses a 10 "value" spacing for Y and the tick labels
// show the expected log values for 90.0%, 99.0%, 99.9%, etc.
// When we convert the availability to a Y value for display on the
// chart we need to scale the value into a log scale between 0 and 70,
// since this is the current range of values that are used for the Y axis.
const maxYValue = Math.max(...Object.keys(Y_AXIS_MAP).map(parseFloat));

export const getAvailabilityChartValue = (availability: number): number => {
  // The desktop chart uses an inverted logarithmic scale for the Y axis.
  // This results in minor tick marks that are closer together at smaller values
  // within a tick block compared to a standard logarithmic scale which has
  // minor ticks that are closer together at higher values within a major block.

  // Since the axis values need to be inverted, convert the availability
  // to an unavailability. Ensure that the value doesn't stray out of the bounds.
  const minAvailability = 0.00001;

  const unavailability = Math.min(
    MAX_AVAILABILITY,
    Math.max(minAvailability, MAX_AVAILABILITY - availability)
  );
  // Use scaling to get the appropriate Y value (between 0 and 70) for the unavailability.
  return (
    maxYValue -
    d3.scaleLog(
      [minAvailability, MAX_AVAILABILITY],
      [0, maxYValue]
    )(unavailability)
  );
};

export const getCapacityValue = (capacity: number, unit: Unit): number => {
  return unit === 'Gbps' ? +capacity.toFixed(2) : truncateDecimal(capacity, 2);
};

export const showChartCrosshairs = ({ focus, xPos, yPos }) => {
  // X Crosshair
  focus.select('line.crosshair-x').attr('y1', yPos).attr('y2', yPos);

  // Y Crosshair
  focus.select('line.crosshair-y').attr('x1', xPos).attr('x2', xPos);
};

export const showChartTooltip = ({
  chartRef,
  highlight,
  tooltip,
  tooltipContent,
  xPos,
  yPos,
  type,
}) => {
  // Y tick labels width
  const yTickLabelsNode = d3.select(`.y.axis.labels`).node();
  const yTickLabelsWidth = yTickLabelsNode.getBoundingClientRect().width;

  const { offsetWidth: chartWidth, offsetHeight: chartHeight } =
    chartRef.current ?? {};
  const tooltipNode = d3.select(`.${type}.tooltip`).node();
  const tooltipRect = tooltipNode.getBoundingClientRect();
  const tooltipMinDimensions = { width: 170, height: 30 };
  const tooltipWidth = tooltipRect.width || tooltipMinDimensions.width;
  const tooltipHeight = tooltipRect.height || tooltipMinDimensions.height;
  const padding = 10;

  // Do not display chart tooltip for very low availability values
  if (highlight.availabilityRealValue <= 10) return;

  // Calculate tooltip x position
  const tooltipLeft = Math.max(
    Math.min(xPos - tooltipWidth / 2, chartWidth - tooltipWidth),
    yTickLabelsWidth + padding
  );

  // Calculate tooltip y position
  const minTooltipTop = tooltipHeight * 2 + padding;
  const maxTooltipTop = chartHeight - tooltipHeight * 2 - padding;
  let tooltipTop = Math.max(minTooltipTop, Math.min(yPos, maxTooltipTop));

  tooltip
    .html(
      tooltipContent(
        highlight.capacityB,
        highlight.availabilityRealValue,
        highlight.layer
      )
    )
    .style('left', `${tooltipLeft}px`)
    .style('top', `${tooltipTop}px`)
    .style('display', 'block')
    .style('pointer-events', 'none');
};

export const highlightChartStep = ({
  sortedCapacityA,
  tooltipContent,
  tooltip,
  chartRef,
  height,
  focus,
  type,
  xPos,
  yPos,
  x0,
  y0,
  x,
  y,
}) => {
  // Find the region that gives the highest capacity and availability for
  // the current mouse position.
  // If 2 or more regions have the same availability then the highlight
  // will span multiple regions and the highest capacity value will be
  // shown in the tooltip
  let highlight = {
    availabilityChartValue: 0,
    availabilityRealValue: 0,
    capacityA: 0,
    capacityB: 0,
    hit: false,
    layer: undefined,
  };

  // Sort the values by availability ...
  const sortedByAvailability = [...sortedCapacityA].sort(
    (s1, s2) => s2.availabilityRealValue - s1.availabilityRealValue
  );
  // ... then find the region that surrounds the current cursor position ...
  sortedByAvailability.forEach((step) => {
    if (
      x0 > step.capacityA &&
      x0 < step.capacityB &&
      y0 <= step.availabilityChartValue
    ) {
      highlight.capacityA = step.capacityA;
      highlight.capacityB = step.capacityB;
      highlight.availabilityChartValue = step.availabilityChartValue;
      highlight.availabilityRealValue = step.availabilityRealValue;
      highlight.layer = step.layer;
      highlight.hit = true;
    }
  });

  // ... if the cursor was in a region, find the other regions that are
  // on the same layer and have the same availability value.
  if (highlight.hit) {
    sortedByAvailability.forEach((step) => {
      if (
        step.availabilityRealValue === highlight.availabilityRealValue &&
        step.layer === highlight.layer
      ) {
        highlight.capacityA = Math.min(highlight.capacityA, step.capacityA);
        highlight.capacityB = Math.max(highlight.capacityB, step.capacityB);
      }
    });
  }

  if (highlight.hit) {
    // Highlight step chart
    focus
      .select('rect.highlight')
      .attr('width', `${x(highlight.capacityB) - x(highlight.capacityA)}`)
      .attr('height', `${height - y(highlight.availabilityChartValue)}`)
      .attr(
        'transform',
        `translate(${x(highlight.capacityA)}, ${y(
          highlight.availabilityChartValue
        )})`
      );

    // Display chart highlight tooltip
    showChartTooltip({
      chartRef,
      highlight,
      tooltip,
      tooltipContent,
      xPos,
      yPos,
      type,
    });
  } else {
    // Hide the step highlight and tooltip
    focus.select('rect.highlight').attr('width', 0).attr('height', 0);
    tooltip.style('display', 'none');
  }
};
