import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormPanel } from '../../nd/utils';
import {
  band,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import { antenna, power } from './PMP450ViewConfig';
import { syncFormState } from '../utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [band, product, regulation],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'symmetry',
      getter: 'radios.0.equipment.symmetry',
    },
    {
      attrName: 'polarization',
      getter: 'radios.0.equipment.polarization',
    },
  ],
};

export const cnWave28ViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power,
};
