import { get } from 'lodash';
import NDEquipmentPanel from '../NDEquipmentPanel';
import { PMPFormPanel, syncFormState } from '../utils';
import {
  antennaHeightND,
  antennaSelection,
  azimuth,
  tilt,
  bandwidth,
  equipmentPanelFields,
  modeledBeamwidth,
  ndInterference,
  smDefaultInterference,
  smRangeFields,
  eirpRowConfig,
  POWER_PANEL_FIELDS_WIDTH,
  getUserEIRP,
} from './config-utils';
import NDSectorPanel from '../NDSectorPanel';
import NDAntennaPanel from '../NDAntennaPanel';
import { interferenceInfoFormatter } from '../../utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [
    ...equipmentPanelFields,
    bandwidth,
    ...smRangeFields,
    {
      attrName: 'pmp_max_mod_mode',
      getter: 'radios.0.equipment.pmp_max_mod_mode',
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'radios.0.equipment.dl_ul_ratio',
    },
  ],
};

const sector: PMPFormPanel = {
  name: 'apSectorPanel',
  title: 'Sector Panel',
  component: NDSectorPanel,
  fields: [
    {
      attrName: 'tx_channel',
      getter: 'radios.0.equipment.tx_channel',
    },
    {
      attrName: 'tx_channel',
      getter: 'radios.1.equipment.tx_channel',
    },
    {
      attrName: 'golay',
      getter: 'radios.0.equipment.golay',
    },
    {
      attrName: 'golay',
      getter: 'radios.1.equipment.golay',
    },
    {
      attrName: 'polarity',
      getter: 'radios.0.equipment.polarity',
      afterOnChange(newValue, { getValues, setValue }, newChoices) {
        const polarityOne = getValues('radios.1.equipment.polarity');
        if (newValue == 'Auto') {
          setValue('radios.1.equipment.polarity', 'Auto');
        } else if (polarityOne === 'Auto') {
          setValue('radios.1.equipment.polarity', newValue);
        }
      },
    },
    {
      attrName: 'polarity',
      getter: 'radios.1.equipment.polarity',
      afterOnChange(newValue, { getValues, setValue }, newChoices) {
        const polarityZero = getValues('radios.0.equipment.polarity');
        if (newValue == 'Auto') {
          setValue('radios.0.equipment.polarity', 'Auto');
        } else if (polarityZero === 'Auto') {
          setValue('radios.0.equipment.polarity', newValue);
        }
      },
    },
    {
      attrName: 'cn_capacity_limit',
      getter: 'radios.0.equipment.cn_capacity_limit',
      warning: ({ ap }) => {
        return get(ap, 'radios.0.warnings.cn_capacity_limit');
      },
    },
    {
      attrName: 'cn_capacity_limit',
      getter: 'radios.1.equipment.cn_capacity_limit',
      warning: ({ ap }) => {
        return get(ap, 'radios.1.warnings.cn_capacity_limit');
      },
    },
    {
      attrName: 'sm_registration_limit',
      getter: 'radios.0.equipment.sm_registration_limit',
      warning: ({ ap }) => {
        return get(ap, 'radios.0.warnings.sm_quantity');
      },
    },
    {
      attrName: 'sm_registration_limit',
      getter: 'radios[1].equipment.sm_registration_limit',
      warning: ({ ap }) => {
        return get(ap, 'radios.1.warnings.sm_quantity');
      },
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [antennaSelection, modeledBeamwidth, antennaHeightND, azimuth, tilt],
};

const getNotNullBandwidthPath = (formGetter) => {
  return formGetter('radios.1.equipment.bandwidth') != null
    ? 'radios.1.equipment.bandwidth'
    : 'radios.0.equipment.bandwidth';
};

const sectorPower: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  component: NDSectorPanel,
  customClass: 'cnwave-power',
  fieldLayout: [[0, 1], [2], [3, 4], [5, 6]],
  fields: [
    ...eirpRowConfig,
    getUserEIRP(true),
    {
      ...ndInterference,
      componentProps({ formGetter }) {
        const unitExtraTooltip = interferenceInfoFormatter(
          formGetter,
          'radios.0.equipment.bandwidth'
        );
        return {
          width: POWER_PANEL_FIELDS_WIDTH,
          unitExtraTooltip,
        };
      },
    },
    {
      ...ndInterference,
      checkboxGetter: 'radios.1.power.use_noise',
      valueGetter: 'radios.1.power.noise',
      componentProps({ formGetter }) {
        const bandwidthPath = getNotNullBandwidthPath(formGetter);
        const unitExtraTooltip = interferenceInfoFormatter(
          formGetter,
          bandwidthPath
        );
        return {
          width: POWER_PANEL_FIELDS_WIDTH,
          unitExtraTooltip,
        };
      },
      nextValue(currentValue, newChoices, formGetter) {
        if (currentValue == null) {
          return formGetter('radios.0.power.noise');
        } else {
          return currentValue;
        }
      },
    },
    {
      ...smDefaultInterference,
      componentProps({ formGetter }) {
        const unitExtraTooltip = interferenceInfoFormatter(
          formGetter,
          'radios.0.equipment.bandwidth'
        );
        return {
          unitExtraTooltip,
          width: POWER_PANEL_FIELDS_WIDTH,
        };
      },
    },
    {
      ...smDefaultInterference,
      checkboxGetter: 'radios.1.power.use_noise_sm',
      valueGetter: 'radios.1.power.noise_sm',
      componentProps({ formGetter }) {
        const bandwidthPath = getNotNullBandwidthPath(formGetter);
        const unitExtraTooltip = interferenceInfoFormatter(
          formGetter,
          bandwidthPath
        );
        return {
          unitExtraTooltip,
          width: POWER_PANEL_FIELDS_WIDTH,
        };
      },
      nextValue(currentValue, newChoices, formGetter) {
        if (currentValue == null) {
          return formGetter('radios.0.power.noise_sm');
        } else {
          return currentValue;
        }
      },
    },
  ],
};

export const cnWave60ViewConfig = {
  syncFormState,
  equipment,
  sector,
  antenna,
  sectorPower,
};
