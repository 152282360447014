import { ColDef } from 'ag-grid-community';
import { get } from 'lodash';
import { CUSTOM_ANTENNA_OTHER, SHORT_UNITS } from 'src/app.constants';
import { pyround } from 'src/utils/useful_functions';

function customSort(valueA, valueB, antennaA, antennaB, isDescending) {
  const multiplier = isDescending ? -1 : 1;
  const indexA = antennaA.data.index;
  const indexB = antennaB.data.index;

  if (indexA > indexB) {
    return multiplier;
  } else if (indexA < indexB) {
    return multiplier * -1;
  } else {
    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    } else {
      return 0;
    }
  }
}

export const defaultColumnDef: ColDef = {
  comparator: customSort,
  resizable: true,
  sortable: true,
  minWidth: 25,
};

export const beamwidthColumn: ColDef = {
  ...defaultColumnDef,
  field: 'beamwidth',
  valueFormatter: ({ value }) => {
    if (value == null) {
      return '';
    }

    const val = Number(value).toFixed(1);
    return `${val}\u00B0`;
  },
};

export const manufacturerColumn: ColDef = {
  ...defaultColumnDef,
  field: 'manufacturer',
};

export const descriptionColumn: ColDef = {
  ...defaultColumnDef,
  field: 'description',
};

export const userDefinedColumn: ColDef = {
  field: 'user_defined',
  hide: true,
};

export const partNumberColumn: ColDef = {
  ...defaultColumnDef,
  field: 'display_partno',
  headerName: 'Part',
  valueGetter({ data }) {
    if (data.motono) {
      return data.motono;
    } else if (data.display_partno) {
      return data.display_partno;
    } else {
      return '';
    }
  },
};

export const polarity: ColDef = {
  ...defaultColumnDef,
  width: 20,
  field: 'polarization',
  headerName: 'Pol.',
  valueFormatter: ({ value }) => {
    if (!value) {
      return '';
    }
    const val = value.toLowerCase();
    if (val.includes('vertical')) {
      return 'V';
    } else if (val.includes('single')) {
      return 'S';
    } else if (val.includes('dual')) {
      return 'D';
    } else {
      return 'H';
    }
  },
};

export const directColumn: ColDef = {
  ...defaultColumnDef,
  field: 'direct_mount',
  headerName: 'Direct',
  valueFormatter: (row) => {
    const { id, direct_mount, dual_mount } = row.data;
    if (id === CUSTOM_ANTENNA_OTHER.id) {
      return '';
    }
    if (dual_mount) {
      return 'Both';
    }
    if (direct_mount) {
      return 'Yes';
    } else {
      return 'No';
    }
  },
};

const getIndex = (gains, targetFreq) => {
  let index = 0;
  for (let idx in gains) {
    const freq = gains[idx][0];
    if (freq >= Math.floor(targetFreq)) {
      return index;
    }
    index += 1;
  }
  return gains.length - 1;
};

const doGainCalc = (nominalFreq, initialGains) => {
  if (!initialGains || !nominalFreq) {
    return 0;
  }
  let gains = [...initialGains];
  gains.sort((a, b) => a[0] - b[0]);

  const minIdx = getIndex(gains, nominalFreq);
  // If the frequency is above the known gains or
  // there is only one value then use the max freq gain
  if (minIdx === gains.length - 1 || gains.length === 1) {
    return gains[minIdx][1];
  }
  const [freqMin, gainMin] = gains[minIdx];
  if (freqMin === nominalFreq) {
    return gainMin;
  }
  const [freqMax, gainMax] = gains[minIdx + 1];
  if (freqMax === nominalFreq) {
    return gainMax;
  }

  const nominalGain =
    gainMin +
    ((nominalFreq - freqMin) / (freqMax - freqMin)) * (gainMax - gainMin);
  return nominalGain;
};

export const calcGain = (antenna) => {
  const gains = get(antenna, 'gains', []);
  return doGainCalc(antenna.center_frequency, gains);
};

const preferCambiumAntennas = (antennas) => {
  // We want any Cambium antennas to come out first
  const cambiumDirect = antennas.filter(
    (a) => a.data.direct_mount && a.data.manufacturer === 'Cambium Networks'
  );
  const cambiumOther = antennas.filter(
    (a) => !a.data.direct_mount && a.data.manufacturer === 'Cambium Networks'
  );
  const nonCambium = antennas.filter(
    (a) => a.data.manufacturer !== 'Cambium Networks'
  );
  // TODO: use a frequency to calculate the gain
  cambiumDirect.sort((a, b) => calcGain(a) - calcGain(b));
  cambiumOther.sort((a, b) => calcGain(a) - calcGain(b));
  nonCambium.sort((a, b) => calcGain(a) - calcGain(b));
  return [...cambiumDirect, ...cambiumOther, ...nonCambium];
};

export function sortAntennas(antennas) {
  // Sort the antennas to match the desktop order
  // Current antennas should come before obsolete antennas.
  const sorted = preferCambiumAntennas(antennas);
  const current = sorted.filter((a) => !a.data.is_obsolete);
  const obsolete = sorted.filter((a) => a.data.is_obsolete);
  return [...current, ...obsolete];
}

// specific to antenna selection start
export const getAntennaIndex = (antenna) => {
  const { user_defined, manufacturer, is_obsolete, id } = antenna;
  let index;
  if (id === CUSTOM_ANTENNA_OTHER.id) {
    return 0;
  } else if (manufacturer === 'Cambium Networks') {
    index = 1;
  } else if (user_defined) {
    index = 2;
  } else if (manufacturer !== 'Cambium Networks') {
    index = 3;
  } else {
    index = 4;
  }
  if (is_obsolete) {
    index += 10;
  }
  return index;
};

export const antennaLabel = (antenna) => {
  const nominalGain = calcGain(antenna);
  return `${get(antenna, 'data.display_name', '')} (${nominalGain.toFixed(
    1
  )} dBi)`;
};

function sizeSort(valueA, valueB, antennaA, antennaB, isDescending) {
  const sizeMetersA = antennaA['diameter'],
    sizeMetersB = antennaB['diameter'];
  return customSort(sizeMetersA, sizeMetersB, antennaA, antennaB, isDescending);
}

export const gainColumn: ColDef = {
  ...defaultColumnDef,
  field: 'gain',
  headerName: 'Gain (dBi)',
  valueFormatter: ({ data, value }) => {
    if (data.id === CUSTOM_ANTENNA_OTHER.id) {
      return '';
    }
    return pyround(value, 1).toFixed(1);
  },
};

export const sizeColumn: ColDef = {
  ...defaultColumnDef,
  width: 15,
  field: 'diameter',
  headerName: 'Size',
  comparator: sizeSort,
  valueFormatter: ({ value, data }) => {
    if (!value) {
      return '';
    } else {
      let diameter = value * data.diameter_scale_factor || 1;
      if (pyround(diameter, 1) === pyround(diameter, 0)) {
        diameter = pyround(diameter, 0);
      } else {
        diameter = pyround(diameter, 2);
      }
      return `${diameter}${SHORT_UNITS[data.diameter_units]}`;
    }
  },
};

export const complianceColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance',
  headerName: 'Compliance',
};
// specific to antenna selection end

// specific to antenna table start
export function isCommonBandExists(selectedBands, antennaBands) {
  return selectedBands.some((item) => antennaBands.includes(item));
}

export const customAntennaStringComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) => {
  if (valueA === undefined) {
    valueA = '';
  }
  if (valueB === undefined) {
    valueB = '';
  }
  return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
};

export const productsColumn: ColDef = {
  ...defaultColumnDef,
  field: 'products',
  headerName: 'Products',
  comparator: customAntennaStringComparator,
};

// Custom sorting function
const sizeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  const getValueInInches = (value) => {
    // for empty values
    if (!value) {
      return 0;
    }
    const numericValue = parseFloat(value);
    if (value.toString().toLowerCase().includes('ft')) {
      return numericValue * 12;
    } else if (value.toString().toLowerCase().includes('m')) {
      return numericValue * 39.3701;
    }
    return numericValue;
  };

  const convertedValueA = getValueInInches(valueA);
  const convertedValueB = getValueInInches(valueB);

  return convertedValueA - convertedValueB;
};

export const customAntennaSizeColumn: ColDef = {
  ...defaultColumnDef,
  ...sizeColumn,
  comparator: sizeComparator,
};

export const complianceEtsiColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance.etsi',
  headerName: 'ETSI',
  comparator: customAntennaStringComparator,
};

export const complianceFccColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance.fcc',
  headerName: 'FCC',
  comparator: customAntennaStringComparator,
};

export const complianceAnatelColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance.anatel',
  headerName: 'Anatel',
  comparator: customAntennaStringComparator,
};

export const complianceCanadaColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance.canada',
  headerName: 'Canada',
  comparator: customAntennaStringComparator,
};

export const complianceMexicoColumn: ColDef = {
  ...defaultColumnDef,
  field: 'compliance.mexico',
  tooltipField: 'compliance.mexico',
  headerName: 'Mexico',
  comparator: customAntennaStringComparator,
  cellStyle: {
    display: 'block',
    'line-height': '24px',
  },
};
// specific to antenna table end
