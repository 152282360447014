import { get } from 'lodash';
import { getWithAuth, postFileWithAuth, postWithAuth } from 'src/api';
import { anyToMeter, pyround } from 'src/utils/useful_functions';
import {
  CreateNetwork,
  EquipmentConfig,
  FormValues,
  Inputs,
  NetworkPlan,
  terragraphStatusTypes,
} from './terragraph.modal';
import { Countries } from './equipment/cnWave60';

export const mcsTypes = [
  { value: 'mcs2', text: 'MCS2' },
  { value: 'mcs3', text: 'MCS3' },
  { value: 'mcs4', text: 'MCS4' },
  { value: 'mcs6', text: 'MCS6' },
  { value: 'mcs7', text: 'MCS7' },
  { value: 'mcs8', text: 'MCS8' },
  { value: 'mcs9', text: 'MCS9' },
  { value: 'mcs10', text: 'MCS10' },
  { value: 'mcs11', text: 'MCS11' },
  { value: 'mcs12', text: 'MCS12' },
];

export const countries = Object.values(Countries)
  .map((c) => {
    return { value: c.name.toLowerCase(), text: c.name };
  })
  .sort((c1, c2) => c1.value.localeCompare(c2.value));

export const channels = [
  { value: 'channel1', text: 'Channel 1 (58.32 GHz)' },
  { value: 'channel2', text: 'Channel 2 (60.48 GHz)' },
  { value: 'channel3', text: 'Channel 3 (62.64 GHz)' },
  { value: 'channel4', text: 'Channel 4 (64.80 GHz)' },
];

export const handleNumbers = (
  e,
  isFloat = true,
  precision = 1,
  allowFirstZero = true
) => {
  const regPassed = isFloat
    ? /^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$/g.test(e.key)
    : /^[+]?([0-9]{0,})([0-9]{0,2})?$/g.test(e.key);
  const value = e.target.value;
  const allowedKeys = [8, 37, 38, 39, 40];
  const decimalLength = getDecimalNumber(value)?.length;
  if (value.length > 1 && value[0] === '0' && !allowFirstZero) {
    e.target.value = Number(e.target.value + ''.replace(/^0+/, ''));
  }
  if (allowedKeys.includes(e.keyCode)) {
    return;
  } else {
    !regPassed && e.preventDefault();
    !isValueSelected(value) && decimalLength >= precision && e.preventDefault();
  }
};

export const getError = (errors, path) => {
  let errMessage = get(errors, path + '.message', null);
  return errMessage ? { content: errMessage } : errors[path] !== undefined;
};

const isValueSelected = (value) => {
  const selection = window.getSelection().toString();
  return selection === value;
};

const getDecimalNumber = (value) => {
  return value.toString().includes('.') && value.toString().split('.')[1];
};

export const getNumberFieldValidateObject = (key) => {
  let validateObject = {};
  validateObject[key] = (v) => {
    return v !== '' && /^\d*\.?\d*$/.test(v);
  };
  return validateObject;
};

const getPayload = (
  formValues: FormValues,
  heightUnits,
  status: terragraphStatusTypes
) => {
  return {
    ...getNetworkPlan(formValues.networkPlan),
    ...getInputs(formValues.inputs, formValues.networkPlan, heightUnits),
    ...getEquipment(
      formValues.equipment,
      formValues.financials,
      formValues.networkPlan
    ),
    ...getFinancials(formValues.financials),
    ...getCreateNetwork(formValues.createNetwork),
    status,
  };
};

export const setPrecisionWithoutRounding = (value, precision) => {
  if (typeof value !== 'number') {
    value = parseFloat(value);
  }
  return pyround(value, precision);
};

const getNetworkPlan = (networkPlan: NetworkPlan) => {
  const payload = { network_plan: networkPlan.networkPlan };
  if (networkPlan.networkPlan === 'Mesh') {
    payload['network_sub_plan'] = networkPlan.networkSubPlan;
  }
  return payload;
};

const getInputs = (inputs: Inputs, networkPlan: NetworkPlan, heightUnits) => {
  const payload = {
    sites: [],
    pop_count: inputs.pops,
    dn_count: inputs.dns,
    cn_count: inputs.cns,
    los: {
      use_site_maximum_height: inputs.setHeight === 'useSiteMaxHeight',
      use_height_above_surface: inputs.setHeight === 'useHeightAboveSurface',
      max_los_distance: setUnitValues(
        Number(inputs.maxLosDistance),
        heightUnits
      ),
      cn_can_be_used_as_dn: inputs.cnsCanbeUsedAsDns,
    },
    minimum_mcs_mesh_link: inputs.minMcsOfMeshLink,
    minimum_mcs_access_link: inputs.minMcsOfAccessLink,
  };
  if (inputs.setHeight === 'useHeightAboveSurface') {
    let losHeights = {
      pop_height: setUnitValues(Number(inputs.popHeight), heightUnits),
      dn_height: setUnitValues(Number(inputs.dnHeight), heightUnits),
    };

    if (networkPlan.networkPlan === 'Distribution') {
      losHeights['cn_height'] = setUnitValues(
        Number(inputs.cnHeight),
        heightUnits
      );
    }
    payload.los = { ...payload.los, ...losHeights };
  }
  return payload;
};

const setUnitValues = (value, heightUnits) => {
  if (heightUnits.toLowerCase() !== 'm') {
    return anyToMeter(value, heightUnits, 1, false);
  } else {
    return value;
  }
};

const getEquipment = (
  equipment: EquipmentConfig,
  financials: any,
  networkPlan: NetworkPlan
) => {
  const payload = {
    equipment: {
      channel: equipment.channel,
      country: equipment.country,
      max_eirp: pyround(equipment.maxeirp, 1),
    },
    devices: [],
  };
  payload.devices.push({
    device_sku: 'V5000',
    device_type: 'POP',
    node_capex: financials.V5000 || 0,
    mcs_level: equipment.minmcspopdn,
  });
  if (equipment.cnv1000 && networkPlan.networkPlan === 'Distribution') {
    payload.devices.push({
      device_sku: 'V1000',
      device_type: 'CN',
      node_capex: financials.V1000 || 0,
      mcs_level: equipment.minmcscnv1000,
    });
  }
  if (equipment.cnv2000 && networkPlan.networkPlan === 'Distribution') {
    payload.devices.push({
      device_sku: 'V2000',
      device_type: 'CN',
      node_capex: financials.V2000 || 0,
      mcs_level: equipment.minmcscnv2000,
    });
  }
  if (equipment.cnv3000 && networkPlan.networkPlan === 'Distribution') {
    getv3000Type(equipment).forEach((value) => {
      payload.devices.push({
        device_sku: 'V3000',
        device_type: 'CN',
        device_options: value.data,
        node_capex: financials.V3000 || 0,
        mcs_level: equipment.minmcscnv3000,
      });
    });
  }

  return payload;
};

const getv3000Type = (equipment: EquipmentConfig) => {
  let vals = [
    {
      key: 'cnTypeV3000sf',
      value: equipment.cnTypeV3000sf,
      data: 'Small Footprint',
    },
  ];
  if (equipment.cnTypeV3000hgr) {
    vals.push({
      key: 'cnTypeV3000hr',
      value: equipment.cnTypeV3000hgr,
      data: 'High Gain + Radome',
    });
  } else if (equipment.cnTypeV3000hg) {
    vals.push({
      key: 'cnTypeV3000hg',
      value: equipment.cnTypeV3000hg,
      data: 'High Gain',
    });
  }
  let trueValues = vals.filter((el) => el.value === 1);
  return trueValues;
};

const getFinancials = (financials: any) => {
  const payload = {
    financial: {
      budget: financials.budget,
    },
  };
  return payload;
};

const getCreateNetwork = (createNetwork: CreateNetwork) => {
  const payload = {
    network_design: {
      dimension: {
        default_cin_cir: pyround(createNetwork.defaultCncir, 3),
        over_subscription: pyround(createNetwork.overSubscription, 1),
        pop_capacity: pyround(createNetwork.popCapacity, 3),
        link_availability: pyround(createNetwork.linkAvailability, 4),
      },
      extra_pop: createNetwork.extraPops,
      use_all_pop_sites: createNetwork.useAllPopSites,
      number_of_channels: createNetwork.noOfChannels,
      maximize_guaranteed_bw: createNetwork.maxGuaranteedBw,
      max_no_hops: createNetwork.maxNumberOfHops,
      demand_spacing: createNetwork.demandSpacing,
      demand_connection_radius: createNetwork.demandRadius,
      redundancy_level: createNetwork.redundancyLevel,
    },
  };
  return payload;
};

export function getParsedData(details: any): {
  networkPlan: NetworkPlan;
  inputs: Inputs;
  equipment: EquipmentConfig;
  financials: any;
  createNetwork: CreateNetwork;
} {
  let networkPlan: NetworkPlan = {
    networkPlan: details.network_plan,
    networkSubPlan: details.network_sub_plan?.trim() || 'Cost Model',
  };
  let inputs: Inputs = {
    boundryPolygon: '',
    cnHeight: details.los?.cn_height === null ? 2 : details.los?.cn_height,
    dnHeight: details.los?.dn_height === null ? 5 : details.los?.dn_height,
    maxLosDistance: details.los?.max_los_distance,
    popHeight: details.los?.pop_height === null ? 5 : details.los?.pop_height,
    cns: details.cn_count,
    dns: details.dn_count,
    pops: details.pop_count,
    cnsCanbeUsedAsDns: 0,
    loadDsm: 0,
    heightUnits: details.heightUnits,
    minMcsOfAccessLink: details.minimum_mcs_access_link.toLowerCase(),
    minMcsOfMeshLink: details.minimum_mcs_mesh_link.toLowerCase(),
    setHeight: details.los?.use_height_above_surface
      ? 'useHeightAboveSurface'
      : 'useSiteMaxHeight',
  };
  let equipment: EquipmentConfig = {
    channel: details.equipment?.channel,
    country: details.equipment?.country,
    popDn: 'V5000',
    cnv1000: isDeviceAvailable(details, 'V1000'),
    cnv2000: isDeviceAvailable(details, 'V2000'),
    cnv3000: isDeviceAvailable(details, 'V3000'),
    cnTypeV3000hg:
      isDeviceAvailable(details, 'V3000', 'High Gain') ||
      isDeviceAvailable(details, 'V3000', 'High Gain + Radome'),
    cnTypeV3000hgr: isDeviceAvailable(details, 'V3000', 'High Gain + Radome'),
    cnTypeV3000sf: isDeviceAvailable(details, 'V3000', 'Small Footprint'),
    minmcspopdn: getMinMcsLevel(details, 'V5000') || 'mcs2',
    minmcscnv1000: getMinMcsLevel(details, 'V1000') || 'mcs2',
    minmcscnv2000: getMinMcsLevel(details, 'V2000') || 'mcs2',
    minmcscnv3000: getMinMcsLevel(details, 'V3000') || 'mcs2',
    maxeirp: details.equipment?.max_eirp,
  };
  let financials: any = {
    budget: details.financial.budget,
    V5000: getNodeCapex(details, 'V5000') || 1578,
  };
  financials['V1000'] = getNodeCapex(details, 'V1000') || 365;
  financials['V2000'] = getNodeCapex(details, 'V2000') || 725;
  financials['V3000'] = getNodeCapex(details, 'V3000') || 1103;
  let createNetwork: CreateNetwork = {
    defaultCncir: details.network_design?.dimension.default_cin_cir,
    overSubscription: details.network_design?.dimension.over_subscription,
    popCapacity: details.network_design?.dimension.pop_capacity,
    linkAvailability: details.network_design?.dimension.link_availability,
    demandSpacing: details.network_design?.demand_spacing,
    demandRadius: details.network_design?.demand_connection_radius,
    extraPops: details.network_design?.extra_pop,
    maxNumberOfHops: details.network_design?.max_no_hops,
    maxGuaranteedBw: details.network_design?.maximize_guaranteed_bw,
    noOfChannels: details.network_design?.number_of_channels,
    redundancyLevel: details.network_design?.redundancy_level,
    useAllPopSites: details.network_design?.use_all_pop_sites,
  };
  return {
    networkPlan: networkPlan,
    inputs: inputs,
    equipment: equipment,
    financials: financials,
    createNetwork: createNetwork,
  };
}

export const getMinMcsLevel = (details, device_sku) => {
  let mcs_level = details.devices.filter(
    (dc) => dc.device_sku === device_sku
  )[0]?.mcs_level;
  return mcs_level ? 'mcs' + mcs_level : null;
};

function isDeviceAvailable(details, device_sku, type?) {
  if (type) {
    return details.devices.filter(
      (dc) => dc.device_sku === device_sku && dc.device_options === type
    )[0] !== undefined
      ? 1
      : 0;
  } else {
    return details.devices.filter((dc) => dc.device_sku === device_sku)[0] !==
      undefined
      ? 1
      : 0;
  }
}

function getNodeCapex(details, device_sku) {
  if (details.devices.filter((el) => el.device_sku === device_sku)[0]) {
    return details.devices.filter((el) => el.device_sku === device_sku)[0]
      .node_capex;
  } else {
    return false;
  }
}

export const CreateAndScheduleTerragraph = (
  projectId: string,
  formValues: FormValues,
  heightUnits,
  status: terragraphStatusTypes
): Promise<any> => {
  let payload = getPayload(formValues, heightUnits, status);
  console.log(payload);
  return new Promise((resolve, reject) => {
    postWithAuth(`projects/${projectId}/anp/schedule/data`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UpdateAndScheduleTerragraph = (
  projectId: string,
  formValues: FormValues,
  heightUnits,
  status: terragraphStatusTypes
): Promise<any> => {
  let payload = getPayload(formValues, heightUnits, status);
  console.log(payload);
  return new Promise((resolve, reject) => {
    postWithAuth(`projects/${projectId}/anp/schedule/run`, payload, 'PUT')
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CancelScheduledJobTerragraph = (
  projectId: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    postWithAuth(`projects/${projectId}/anp/schedule/cancel`, {}, 'PUT')
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const uploadBoundaryFiles = (boundaryFile, projectId, network_plan=null): Promise<any> => {
  const body = new FormData();
  body.append('boundary_polygon', boundaryFile);
  return new Promise((resolve, reject) => {
    postFileWithAuth(`projects/${projectId}/anp/schedule/boundary_files?network_plan=${network_plan}`, body)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getData = (projectId) => {
  return new Promise((resolve, reject) => {
    getWithAuth(`projects/${projectId}/anp/schedule/data`)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getResults = (projectId) => {
  return new Promise((resolve, reject) => {
    getWithAuth(`projects/${projectId}/anp/list_files`)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFiles = (projectId, network_plan=null) => {
  return new Promise((resolve, reject) => {
    getWithAuth(`projects/${projectId}/anp/schedule/boundary_files?network_plan=${network_plan}`)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getStatus = (projectId) => {
  return new Promise((resolve, reject) => {
    getWithAuth(`projects/${projectId}/anp/schedule/status`)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetAnp = (projectId) => {
  return new Promise((resolve, reject) => {
    postWithAuth(`projects/${projectId}/anp/reset`, null, 'DELETE')
      .then((resp) => {
        resolve(resp);
      })
      .catch((er) => {
        reject(er);
      });
  });
};

export const resetBoundaryDraft = (projectId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    postWithAuth(`projects/${projectId}/anp/schedule/boundary_files/discard`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
