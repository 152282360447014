import React, { useEffect } from 'react';
import { Form, Grid, Label, Popup } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import CustomAccordion from 'src/components/controls/CustomAccordion';
import { EquipmentConfig, NetworkPlan } from './terragraph.modal';
import {
  channels,
  countries,
  getError,
  getNumberFieldValidateObject,
  handleNumbers,
  mcsTypes,
} from './terragraph.service';
import { injectIntl } from 'react-intl';
import messages from 'src/messages';
import { maxEirpValues } from './equipment/cnWave60';
import { get } from 'lodash';

function EquipmentConfigStep(props: {
  equipmentConfigFormData: EquipmentConfig;
  networkPlanData: NetworkPlan;
  equipmentConfigFormDataChange: Function;
  setValidate: Function;
  intl: any;
}) {
  const {
    equipmentConfigFormData,
    equipmentConfigFormDataChange,
    networkPlanData,
    setValidate,
    intl,
  } = props;

  const { formatMessage } = intl;

  const {
    getValues,
    control,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues: equipmentConfigFormData });

  let { networkPlan } = networkPlanData;

  let cnTypeAvailable: number;

  const onFormValueChange = ($event?) => {
    if (get($event, 'target.id', null) !== 'maxeirp') {
      setValue('maxeirp', getMaxEirp());
      clearErrors('maxeirp');
    }
    equipmentConfigFormDataChange(getValues());
    setTimeout(checkValidation, 100);
  };

  const checkValidation = () => {
    let cnValuesAvailable =
      networkPlan === 'Mesh' ||
      getValues('cnv1000') ||
      getValues('cnv2000') ||
      getValues('cnv3000');
    setValidate((prev) => {
      return Object.keys(errors).length === 0 && cnValuesAvailable;
    });
    let values: any[] = Object.keys(getValues());
    if (getValues('cnv3000')) {
      cnTypeAvailable = [
        getValues('cnTypeV3000hg'),
        getValues('cnTypeV3000hgr'),
        getValues('cnTypeV3000sf'),
      ].filter((el) => el === 1).length;
      trigger(values).then((status) => {
        setValidate(
          status && cnValuesAvailable && getValues('cnv3000') && cnTypeAvailable
        );
      });
    } else {
      trigger(values).then((status) => {
        setValidate(status && cnValuesAvailable);
      });
    }
  };

  useEffect(() => {
    checkValidation();
  }, []);

  const checkForHighGainOptionValidation = () => {
    let check =
      networkPlan === 'Distribution' &&
      getValues('cnTypeV3000hg') === 1 &&
      getValues('cnTypeV3000hgr') === 1;
    setValidate(!check);
    return check;
  };

  const getMaxEirp = () => {
    const [popDn, country, channel] = getValues([
      'popDn',
      'country',
      'channel',
    ]);
    if (popDn) {
      return maxEirpValues(popDn, country, channel);
    }
    return 0;
  };

  const accordions = [
    {
      key: 'equipment',
      titleProps: {
        content: `Equipment`,
      },
      contentProps: {
        content: (
          <div>
            {/* <LpForm formControls={formControls} /> */}

            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              onChange={onFormValueChange}
            >
              <Form.Field
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Popup
                  content={formatMessage(messages.channelHelpText)}
                  position="right center"
                  trigger={<label>Channel</label>}
                />
                <Controller
                  name="channel"
                  control={control}
                  rules={{}}
                  render={({ field: { ref, ...rest } }) => (
                    <Form.Select
                      {...rest}
                      error={
                        errors.channel && { content: errors?.channel?.message }
                      }
                      key={'channel'}
                      onChange={async (e, { name, value }) => {
                        setValue(name, value);
                        onFormValueChange();
                      }}
                      options={channels}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Popup
                  content={formatMessage(messages.countryHelpText)}
                  position="right center"
                  trigger={<label>Country</label>}
                />
                <Controller
                  name="country"
                  control={control}
                  rules={{}}
                  render={({ field: { ref, ...rest } }) => (
                    <Popup
                      content={formatMessage(messages.countryHelpText)}
                      position="right center"
                      trigger={
                        <Form.Select
                          {...rest}
                          error={
                            errors.country && {
                              content: errors?.country?.message,
                            }
                          }
                          onChange={async (e, { name, value }) => {
                            setValue(name, value);
                            onFormValueChange();
                          }}
                          options={countries}
                        />
                      }
                    />
                  )}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Popup
                  content={formatMessage(messages.popDnHelpText)}
                  position="right center"
                  trigger={<label>POP / DN</label>}
                />
                <Form.Group>
                  <div style={{ marginLeft: '1.5em' }}>
                    <Form.Field>
                      <Controller
                        name="popDn"
                        control={control}
                        rules={{
                          required: { value: true, message: 'Required' },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <Form.Radio
                            {...rest}
                            label={'V5000'}
                            name="popDn"
                            value={'V5000'}
                            error={
                              errors.popDn && {
                                content: errors?.popDn?.message,
                              }
                            }
                            onChange={async () => {
                              setValue('popDn', 'V5000');
                              onFormValueChange();
                            }}
                            checked={getValues('popDn') === 'V5000'}
                          />
                        )}
                      />
                    </Form.Field>

                    {getValues('popDn') === 'V5000' ? (
                      <div style={{ marginLeft: '1.5em' }}>
                        <Form.Field
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Popup
                            content={formatMessage(
                              messages.minMcsLevelHelpText
                            )}
                            position="right center"
                            trigger={<label>Minimum MCS Level</label>}
                          />
                          <Controller
                            name="minmcspopdn"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                              <Form.Select
                                {...rest}
                                error={
                                  errors.minmcspopdn && {
                                    content: errors?.minmcspopdn?.message,
                                  }
                                }
                                onChange={async (e, { name, value }) => {
                                  setValue(name, value);
                                  onFormValueChange();
                                }}
                                options={mcsTypes}
                              />
                            )}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Popup
                            content={formatMessage(messages.maxEirpHelpText)}
                            position="right center"
                            trigger={<label>Max EIRP</label>}
                          />
                          <Controller
                            name="maxeirp"
                            control={control}
                            rules={{
                              required: { value: true, message: 'Required' },
                              min: {
                                value: 13,
                                message: formatMessage(messages.minValueError, {
                                  value: 13,
                                }),
                              },
                              max: {
                                value: getMaxEirp(),
                                message: formatMessage(messages.maxValueError, {
                                  value: getMaxEirp(),
                                }),
                              },
                              validate: getNumberFieldValidateObject('maxrirp'),
                            }}
                            render={({ field }) => (
                              <>
                                <Grid verticalAlign="middle" columns={2}>
                                  <Grid.Column>
                                    <Form.Input
                                      {...field}
                                      type={'number'}
                                      id={'maxeirp'}
                                      name={'maxeirp'}
                                      min={13}
                                      max={getMaxEirp()}
                                      error={getError(errors, 'maxeirp')}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Label className="ml-2">dBm</Label>
                                  </Grid.Column>
                                </Grid>
                              </>
                            )}
                          />
                        </Form.Field>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Form.Group>
              </Form.Field>

              {networkPlanData.networkPlan === 'Distribution' ? (
                <Form.Field
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Popup
                    content={formatMessage(messages.cnHelpText)}
                    position="right center"
                    trigger={<label>CN</label>}
                  />
                  <Form.Group>
                    <div className="ml-4">
                      <Form.Field>
                        <Controller
                          name="cnv1000"
                          control={control}
                          rules={{}}
                          render={({ field: { ref, ...rest } }) => (
                            <Form.Checkbox
                              {...rest}
                              label={'V1000'}
                              name="cnv1000"
                              checked={Boolean(equipmentConfigFormData.cnv1000)}
                              onChange={async (e, { name, checked }) => {
                                setValue('cnv1000', checked ? 1 : 0);
                                onFormValueChange();
                              }}
                            />
                          )}
                        />
                      </Form.Field>

                      {getValues('cnv1000') === 1 ? (
                        <div className="ml-4">
                          <Form.Field
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Popup
                              content={formatMessage(
                                messages.minMcsLevelHelpText
                              )}
                              position="right center"
                              trigger={<label>Minimum MCS Level</label>}
                            />
                            <Controller
                              name="minmcscnv1000"
                              control={control}
                              rules={{}}
                              render={({ field: { ref, ...rest } }) => (
                                <Form.Select
                                  {...rest}
                                  error={
                                    errors.minmcscnv1000 && {
                                      content: errors?.minmcscnv1000?.message,
                                    }
                                  }
                                  onChange={async (e, { name, value }) => {
                                    setValue(name, value);
                                    onFormValueChange();
                                  }}
                                  options={mcsTypes}
                                />
                              )}
                            />
                          </Form.Field>
                        </div>
                      ) : (
                        ''
                      )}

                      <Form.Field>
                        <Controller
                          name="cnv2000"
                          control={control}
                          rules={{}}
                          render={({ field: { ref, ...rest } }) => (
                            <Form.Checkbox
                              {...rest}
                              label={'V2000'}
                              name="cnv2000"
                              checked={Boolean(equipmentConfigFormData.cnv2000)}
                              onChange={async (e, { name, checked }) => {
                                setValue('cnv2000', checked ? 1 : 0);
                                onFormValueChange();
                              }}
                            />
                          )}
                        />
                      </Form.Field>

                      {getValues('cnv2000') === 1 ? (
                        <div className="ml-4">
                          <Form.Field
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Popup
                              content={formatMessage(
                                messages.minMcsLevelHelpText
                              )}
                              position="right center"
                              trigger={<label>Minimum MCS Level</label>}
                            />
                            <Controller
                              name="minmcscnv2000"
                              control={control}
                              rules={{}}
                              render={({ field: { ref, ...rest } }) => (
                                <Form.Select
                                  {...rest}
                                  error={
                                    errors.minmcscnv2000 && {
                                      content: errors?.minmcscnv2000?.message,
                                    }
                                  }
                                  onChange={async (e, { name, value }) => {
                                    setValue(name, value);
                                    onFormValueChange();
                                  }}
                                  options={mcsTypes}
                                />
                              )}
                            />
                          </Form.Field>
                        </div>
                      ) : (
                        ''
                      )}

                      <Form.Field>
                        <Controller
                          name="cnv3000"
                          control={control}
                          rules={{}}
                          render={({ field: { ref, ...rest } }) => (
                            <Form.Checkbox
                              {...rest}
                              label={'V3000'}
                              name="cnv3000"
                              checked={Boolean(equipmentConfigFormData.cnv3000)}
                              onChange={async (e, { name, checked }) => {
                                setValue('cnv3000', checked ? 1 : 0);
                                onFormValueChange();
                              }}
                            />
                          )}
                        />
                      </Form.Field>

                      {getValues('cnv3000') === 1 ? (
                        <div className="ml-4">
                          <Form.Field
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Popup
                              content={formatMessage(
                                messages.minMcsLevelHelpText
                              )}
                              position="right center"
                              trigger={<label>Minimum MCS Level</label>}
                            />
                            <Controller
                              name="minmcscnv3000"
                              control={control}
                              rules={{}}
                              render={({ field: { ref, ...rest } }) => (
                                <Form.Select
                                  {...rest}
                                  error={
                                    errors.minmcscnv3000 && {
                                      content: errors?.minmcscnv3000?.message,
                                    }
                                  }
                                  onChange={async (e, { name, value }) => {
                                    setValue(name, value);
                                    onFormValueChange();
                                  }}
                                  options={mcsTypes}
                                />
                              )}
                            />
                          </Form.Field>
                        </div>
                      ) : (
                        ''
                      )}

                      {!(
                        getValues('cnv1000') ||
                        getValues('cnv2000') ||
                        getValues('cnv3000')
                      ) ? (
                        <div className="error-text">
                          {formatMessage(messages.deviceRequired, {
                            value: 'CN',
                          })}
                        </div>
                      ) : (
                        ''
                      )}

                      {getValues('cnv3000') === 1 ? (
                        <Grid columns={1} className="ml-4 mt-2">
                          <Grid.Column>
                            <Form.Field>
                              <Controller
                                name="cnTypeV3000hg"
                                control={control}
                                rules={{}}
                                render={({ field: { ref, ...rest } }) => (
                                  <Form.Checkbox
                                    {...rest}
                                    label={'V3000 High Gain'}
                                    checked={Boolean(
                                      equipmentConfigFormData.cnTypeV3000hg
                                    )}
                                    onChange={async (e, { name, checked }) => {
                                      setValue(
                                        'cnTypeV3000hg',
                                        checked ? 1 : 0
                                      );
                                      if (!checked) {
                                        setValue('cnTypeV3000hgr', 0);
                                      }
                                      onFormValueChange();
                                    }}
                                  />
                                )}
                              />
                            </Form.Field>
                          </Grid.Column>
                          {equipmentConfigFormData.cnTypeV3000hg === 1 && (
                            <Grid.Column className="ml-4 m-1">
                              <Form.Field>
                                <Controller
                                  name="cnTypeV3000hgr"
                                  control={control}
                                  rules={{}}
                                  render={({ field: { ref, ...rest } }) => (
                                    <Form.Checkbox
                                      {...rest}
                                      label={'Radome'}
                                      checked={Boolean(
                                        equipmentConfigFormData.cnTypeV3000hgr
                                      )}
                                      onChange={async (
                                        e,
                                        { name, checked }
                                      ) => {
                                        setValue(
                                          'cnTypeV3000hgr',
                                          checked ? 1 : 0
                                        );
                                        onFormValueChange();
                                      }}
                                    />
                                  )}
                                />
                              </Form.Field>
                            </Grid.Column>
                          )}

                          <Grid.Column>
                            <Form.Field>
                              <Controller
                                name="cnTypeV3000sf"
                                control={control}
                                rules={{}}
                                render={({ field: { ref, ...rest } }) => (
                                  <Form.Checkbox
                                    {...rest}
                                    label={'V3000 Small Footprint'}
                                    checked={Boolean(
                                      equipmentConfigFormData.cnTypeV3000sf
                                    )}
                                    onChange={async (e, { name, checked }) => {
                                      setValue(
                                        'cnTypeV3000sf',
                                        checked ? 1 : 0
                                      );
                                      onFormValueChange();
                                    }}
                                  />
                                )}
                              />
                            </Form.Field>
                          </Grid.Column>
                          {[
                            getValues('cnTypeV3000hg'),
                            getValues('cnTypeV3000hgr'),
                            getValues('cnTypeV3000sf'),
                          ].filter((el) => el === 1).length === 0 ? (
                            <Grid.Column>
                              <span className="error-text">
                                {formatMessage(messages.deviceOptionsError)}
                              </span>
                            </Grid.Column>
                          ) : (
                            ''
                          )}
                          {/* {checkForHighGainOptionValidation() ? (
                            //LPWEB-628 Allow users to select only either V3000 HG or V3000 HGR
                            <Grid.Column>
                              <span className="error-text">
                                {formatMessage(
                                  messages.distributionDeviceOptionsError
                                )}
                              </span>
                            </Grid.Column>
                          ) : (
                            ''
                          )} */}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </div>
                  </Form.Group>
                </Form.Field>
              ) : (
                ''
              )}
            </Form>
          </div>
        ),
      },
    },
  ];

  return (
    <div>
      <Grid centered columns={2}>
        <Grid.Column>
          <CustomAccordion accordions={accordions}></CustomAccordion>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default injectIntl(EquipmentConfigStep);
