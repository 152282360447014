import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ToolbarType } from './components/controls/Toolbar';
import { ClutterDetailsType, ClutterTypes } from './utils/types/commonTypes';
import { NOMINAL_FREQUENCY_MAP } from './model/BandDefinition';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';

export const getEnvironment = (): string => {
  let apiURL = window['runtime'].apiURL;
  return apiURL.includes('apitest.lp.cambiumnetworks')
    ? 'TEST'
    : apiURL.includes('api.lp.cambiumnetworks')
    ? 'PROD'
    : apiURL.includes('localhost')
    ? 'DEV'
    : '';
};

export const TERMS_VERSION = 1;
export const ERROR_COLOR = '#9F3A38';
export const WARNING_COLOR = 'orange';
export const GRID_EDIT_SINGLE_CLICK = true;
export const DEFAULT_SM_RANGE = 2;
// export const WARNING_COLOR = '#FFBF00';  // amber

export enum LatLngFormats {
  deg = 'deg (ddd.dddddP)',
  degMin = 'deg:min (ddd:mm.mmmP)',
  degMinSec = 'deg:min:sec (ddd:mm:ss.ssP)',
  signedDeg = 'signed deg (+/-ddd.ddddd)',
}
export const MIN_LINK_LENGTH_KM = 0.001;
export const MIN_LINK_LENGTH_M = MIN_LINK_LENGTH_KM * 1000;
export const MAX_PTP_LINK_LEGNTH_KM = 250;
export const SITE_MAX_HEIGHT_M = 3000;
export const LENGTH_OPTIONS = [
  {
    key: 'k',
    text: (
      <FormattedMessage id="common.kilometers" defaultMessage="kilometers" />
    ),
    value: 'km',
  },
  {
    key: 'm',
    text: <FormattedMessage id="common.miles" defaultMessage="miles" />,
    value: 'mi',
  },
];

export const HEIGHT_OPTIONS = [
  {
    key: 'mt',
    text: <FormattedMessage id="common.meters" defaultMessage="meters" />,
    value: 'm',
  },
  {
    key: 'f',
    text: <FormattedMessage id="common.feet" defaultMessage="feet" />,
    value: 'ft',
  },
];

export const LANGUAGES_PREFERENCES = [
  {
    key: 'EngUS',
    text: (
      <FormattedMessage
        id="languageUSEnglish"
        defaultMessage="English (US)"
      ></FormattedMessage>
    ),
    value: 'en',
  },
  {
    key: 'EngUk',
    text: (
      <FormattedMessage
        id="languageUKEnglish"
        defaultMessage="English (UK)"
      ></FormattedMessage>
    ),
    value: 'en-GB',
  },
];

export const LAT_LNG_OPTIONS: {
  key: string;
  text: JSX.Element;
  value: string;
}[] = [
  {
    key: 'd',
    text: (
      <FormattedMessage
        id="preferences.latlngdegree"
        defaultMessage="deg (ddd.dddddP)"
      />
    ),
    value: 'deg (ddd.dddddP)',
  },
  {
    key: 'm',
    text: (
      <FormattedMessage
        id="preferences.latlngDegreeMin"
        defaultMessage="deg:min (ddd:mm.mmmP)"
      />
    ),
    value: 'deg:min (ddd:mm.mmmP)',
  },
  {
    key: 's',
    text: (
      <FormattedMessage
        id="preferences.latlngDegreeMinSec"
        defaultMessage="deg:min:sec (ddd:mm:ss.ssP)"
      />
    ),
    value: 'deg:min:sec (ddd:mm:ss.ssP)',
  },
  {
    key: 'sd',
    text: (
      <FormattedMessage
        id="preferences.latlngDegreeSigned"
        defaultMessage="signed deg (+/-ddd.ddddd)"
      />
    ),
    value: 'signed deg (+/-ddd.ddddd)',
  },
];

export const MAC_ADDRESS_OPTIONS = [
  {
    key: '2',
    text: '00:00:00:00:00:00',
    value: '00:00:00:00:00:00',
  },
  {
    key: '22',
    text: '00-00-00-00-00-00',
    value: '00-00-00-00-00-00',
  },
  {
    key: '3',
    text: '000.000.000.000',
    value: '000.000.000.000',
  },
  {
    key: '1',
    text: '000000000000',
    value: '000000000000',
  },
];

export const MAC_ADDRESS_OPT_VALUES = MAC_ADDRESS_OPTIONS.map((opt) => {
  return opt.value;
});

export const LAT_LNG_OPT_VALUES = LAT_LNG_OPTIONS.map((opt) => {
  return opt.value;
});

export const DEFAULT_USER_PREFERENCES = {
  rangeUnits: 'km',
  heightUnits: 'ft',
  latLngFormat: 'deg',
  macAddressFormat: '00:00:00:00:00:00',
};

//lpGrid Switch Action Label id's
export const DELETE = 'delete';
export const ADD_EDIT_TOWERSWITCH = 'add_edit_tower_switch';
export const DELETE_TOWERSWITCH = 'delete_tower_switch';
export const CONVERT_TO_SUBSCRIBER_SITE = 'convert_to_subscriber_site';
export const CONVERT_TO_NETWORK_SITE = 'convert_to_network_site';
export const CREATE_DUPLICATE_SUBSCRIBER_SITE =
  'create_duplicate_subscriber_site';
export const CREATE_DUPLICATE_NETWORK_SITE = 'create_duplicate_network_site';

//Chart constants
export const BROWN = '#996633';
export const LIGHT_GRAY = '#eeeeee';
export const GRAY = '#bbbbbb';
export const DARK_GRAY = '#808080';
export const RED = '#ff0000';
export const YELLOW = '#FFD300';
export const BLUE = '#0000ff';
export const EARTHRADIUS = 6371007;

export enum OperatingModes {
  // PMP 450m / Operating Mode:
  MEDUSA = 'Mu-MIMO',
  // ePMP 3000 / Operating Mode:
  MULTI_USER = '4x4 Multi-User (preliminary)',
}

export const isMuMimo = (opMode?: OperatingModes | string) => {
  console.log(opMode, OperatingModes.MEDUSA, opMode === OperatingModes.MEDUSA);
  return (
    opMode === OperatingModes.MEDUSA || opMode === OperatingModes.MULTI_USER
  );
};

//common
export const THROUGHPUT = 'throughput';
export const PERCENT = 'percent';
export const MAX_MUX_GAIN = 'max_max_gain';
export const NAME_MAX_LENGTH = 256;
export const DESCRIPTION_MAX_LENGTH = 10000;

//Localstore
export const STORE_PREFIX = 'cn.lp';

//Clutter details
export const DEFAULT_CLUTTER_DETAILS: ClutterDetailsType = [
  { key: 'H', details: ['#FFCCD5', 9] },
  { key: 'C', details: ['#CCFFCC', 5] },
  { key: 'B', details: ['#0000FF', 0] },
  { key: 'I', details: ['#FF4D6A', 12] },
  { key: 'D', details: ['#66FF66', 15] },
  { key: 'A', details: ['#A22A2A', 0] },
  { key: 'J', details: ['#FF8080', 20] },
  { key: 'E', details: ['#00E600', 20] },
  { key: 'G', details: ['#EAAEAE', 5] },
  { key: 'K', details: ['#FF0000', 25] },
  { key: 'F', details: ['#004D00', 20] },
  { key: 'M', details: ['#808080', 20] },
  { key: 'L', details: ['#990000', 35] },
  { key: 'U', details: ['#FFFFFF', 0] },
];

export const CLUTTER_LABELS: { [key in ClutterTypes]: string } = {
  H: 'Suburban',
  C: 'Light Trees - Shrubs',
  B: 'Water',
  I: 'Dense Suburban',
  D: 'Deciduous Forest',
  A: 'Rural - Open',
  J: 'Urban',
  E: 'Evergreen Forest',
  G: 'Sparse Houses',
  K: 'Dense Urban',
  F: 'Tropical Forest',
  M: 'Industrial Zone',
  L: 'High Rise Urban',
  U: 'Unclassified',
};

export const CLUTTER_PROPS_COL_PER_ROW = 3;

//Localstore keys
export const PMP_LINK_ACTIVE_PANELS = 'cn.lp.pmpLinkActivePanels';
export const GRID_PAGE_SIZE = 'cn.lp.gridPageSize';

//Unit conversion constants
export const METER_TO_FEET = 3.28083989;
export const KHZ_TO_MHZ = 0.001;
export const GHZ_TO_MHZ = 1000;
export const KILOMETERS_TO_MILES = 0.621371;
export const MILES_TO_KILOMETERS = 1.609344;
export const FEET_TO_METER = 0.3048;
export const CNREACH_BANDS = ['700 MHz', '900 MHz'];
export const GREY_COLOR = '#555555';
export const RECALC_COLOR = '#2185d0';
export const SECONDS_PER_YEAR = 365 * 24 * 60 * 60;

export const PREDICTION_MODEL_OPTIONS = [
  {
    key: 'ITU-RP.530-12',
    text: 'ITU-R P.530-12',
    value: 'ITU-R P.530-12',
  },
  {
    key: 'ITU-RP.530-17',
    text: 'ITU-R P.530-17',
    value: 'ITU-R P.530-17',
  },
  {
    key: 'VB with ITU rain P.530-12',
    text: 'VB with ITU rain P.530-12',
    value: 'VB with ITU rain P.530-12',
  },
  {
    key: 'VB with ITU rain P.530-17',
    text: 'VB with ITU rain P.530-17',
    value: 'VB with ITU rain P.530-17',
  },
];

const other = 'Other...';

export const CUSTOM_ANTENNA_OTHER = {
  data: {
    description: other,
    id: other,
    is_obsolete: false,
    user_defined: true,
  },
  id: other,
};

export const DOWNLOAD_ACTION_TOOLBAR: ToolbarType = {
  icon: 'cloud download',
  label: 'Download CSV',
  onClick: (event, data, params) => params.gridApi.exportDataAsCsv(),
};

export const DELETE_ACTION_TOOLBAR = {
  icon: 'trash alternate',
  label: <FormattedMessage id="common.delete" defaultMessage={'Delete'} />,
  id: DELETE,
};

export const REMOTE_END_DETAILS = {
  latitude: null,
  longitude: null,
  height: 0,
  site_id: null,
};

export const CONVERSION_TO_BITS_PS = {
  bps: 8,
  kbps: 1024 * 8,
  mbps: 1024 * 1024 * 8,
  gbps: 1024 * 1024 * 1024 * 8,
  tbps: 1024 * 1024 * 1024 * 1024 * 8,
  pbps: 1024 * 1024 * 1024 * 1024 * 1024 * 8,
  ebps: 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 8,
};

export const CONVERSION_TO_HZ = {
  hz: 1,
  khz: 1000,
  mhz: 1000000,
  ghz: 1000000000,
  thz: 1000000000000,
  phz: 1000000000000000,
  ehz: 1000000000000000000,
};

export const SM_AP_RECEIVE_LEVEL_DBM = (valueA, valueB) => {
  return (valueA === 'N/A' ? -1 : valueA) - (valueB === 'N/A' ? -1 : valueB);
};
export const THROUGHPUT_COMPARATOR = (valueA, valueB) => {
  if (valueA != null && valueB != null)
    return (
      (valueA === 'N/A'
        ? -1
        : valueA.split(' ')[0] *
          CONVERSION_TO_BITS_PS[valueA.split(' ')[1].toLowerCase()]) -
      (valueB === 'N/A'
        ? -1
        : valueB.split(' ')[0] *
          CONVERSION_TO_BITS_PS[valueB.split(' ')[1].toLowerCase()])
    );
  else return 0;
};
export const CASE_INSENSITIVE_STR_SORT = (valueA, valueB) => {
  if (valueA != null && valueB != null)
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  else return 0;
};

export const FIELD_COMPARATOR = {
  band: (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return NOMINAL_FREQUENCY_MAP[valueA] - NOMINAL_FREQUENCY_MAP[valueB];
    else return 0;
  },
  bandwidth: (valueA, valueB) => {
    if (valueA != null && valueB != null)
      return (
        valueA.split(' ')[0] *
          CONVERSION_TO_HZ[valueA.split(' ')[1].toLowerCase()] -
        valueB.split(' ')[0] *
          CONVERSION_TO_HZ[valueB.split(' ')[1].toLowerCase()]
      );
    else return 0;
  },
  total_throughput: THROUGHPUT_COMPARATOR,
  'properties.total_throughput': THROUGHPUT_COMPARATOR,
  'properties.dl_throughput': THROUGHPUT_COMPARATOR,
  'properties.ul_throughput': THROUGHPUT_COMPARATOR,
  sm_receive_level_dbm: SM_AP_RECEIVE_LEVEL_DBM,
  ap_receive_level_dbm: SM_AP_RECEIVE_LEVEL_DBM,
  product: CASE_INSENSITIVE_STR_SORT,
  regulation: CASE_INSENSITIVE_STR_SORT,
  range_units: CASE_INSENSITIVE_STR_SORT,
  name: CASE_INSENSITIVE_STR_SORT,
  ap_name: CASE_INSENSITIVE_STR_SORT,
  sm_name: CASE_INSENSITIVE_STR_SORT,
  sm_rx_max_usable_mode: CASE_INSENSITIVE_STR_SORT,
  ap_rx_max_usable_mode: CASE_INSENSITIVE_STR_SORT,
};

const defaultMeshCols = [
  'link_name',
  'local.name',
  'local.sector',
  'local.antenna_height',
  'local.rx_antenna_gain',
  'local.mean_data_rate_predicted',
  'remote.name',
  'remote.sector',
  'remote.antenna_height',
  'remote.rx_antenna_gain',
  'remote.mean_data_rate_predicted',
  'range_m',
  'link_availability',
  'total_path_loss',
];

export const defaultColumns = {
  pmp_nd_table: [
    'name',
    'band',
    'product',
    'regulation',
    'bandwidth',
    'height_m',
    'sm_range',
    'range_units',
    'eirp',
    'connected_subscribers',
    'unconnected_subscribers',
    'total_throughput',
  ],
  pmp_links_table: [
    'ap_name',
    'sm_name',
    'name',
    'range_m',
    'product',
    'sm_height_m',
    'excess_path_loss_db',
    'sm_receive_level_dbm',
    'sm_rx_max_usable_mode',
    'sm_predicted_availability',
    'ap_receive_level_dbm',
    'ap_rx_max_usable_mode',
    'ap_predicted_availability',
  ],

  links_to_subscriber_table: [
    'sm_name',
    'range_m',
    'product',
    'sm_height_m',
    'excess_path_loss_db',
    'sm_receive_level_dbm',
    'sm_rx_max_usable_mode',
    'sm_predicted_availability',
    'ap_receive_level_dbm',
    'ap_rx_max_usable_mode',
    'ap_predicted_availability',
  ],
  mesh_links_for_ap: defaultMeshCols,
  mesh_links_table: defaultMeshCols,
  ptp_links_table: [
    'name',
    'range_m',
    'band',
    'product',
    'remote_product',
    'regulation',
    'mean_aggregate_data_rate',
    'link_availability',
    'fresnel_zone_clearance',
    'total_path_loss',
  ],
  network_site: [
    'name',
    'latitude',
    'longitude',
    'maximum_height',
    'description',
    'node_type',
    'properties.access_point_count',
    'properties.mesh_links',
    'properties.total_sms',
    'properties.connected_subscribers',
    'properties.unconnected_subscribers',
    'properties.dl_throughput',
    'properties.ul_throughput',
    'properties.total_throughput',
    'properties.site_owner',
  ],
  subscriber_site: [
    'name',
    'latitude',
    'longitude',
    'maximum_height',
    'description',
    'properties.subscriber_count',
  ],
};

export const additionalColumns = {
  pmp_nd_table: [
    'network_site',
    'antenna',
    'antenna_azimuth',
    'antenna_beamwidth',
    'antenna_tilt',
    'latitude',
    'longitude',
    'mac_address',
    'modelled_beamwidth',
  ],
  pmp_links_table: [
    '001_rain_rate',
    'rain_attenuation',
    'antenna_beamwidth',
    'antenna_elevation',
    'antenna_gain',
    'ap_radio_number',
    'bearing',
    //'sm_bearing_mag_n',
    'bearing_from_boresight',
    'bearing_ap_to_sm',
    //'ap_bearing_mag_n',
    'ap_antenna_gain',
    'band',
    'bandwidth',
    'country',
    'fresnel_clearence_m',
    'link_loss',
    //'sm_clustter_type',
    'eirp',
    'mac_address',
    'antenna',
    'latitude',
    'longitude',
    'mu_mimo_grouping_is_ok',
  ],
};

export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const INITIAL_MAX_TABLE_PAGE_SIZE = 60;

export const BEST_SERVER_PROGRESS_MESSAGE = 'Best server in progress';
export const BEST_SERVER_PROGRESS_SUBMESSAGE =
  'Running best server calculations, editing the project is temporarily disabled';

export const ANP_PROGRESS_MESSAGE =
  'Terragraph Planner calculations in progress';
export const ANP_PROGRESS_SUBMESSAGE =
  'Editing the project is temporarily disabled';

export const REMOTE_TX_FREQUENCY_PATH =
  'remote.radios.0.frequency.tx_frequency';
export const LOCAL_TX_FREQUENCY_PATH = 'local.radios.0.frequency.tx_frequency';
export const SHORT_UNITS = { feet: 'ft', inches: 'in', meters: 'm' };

export const ANTENNA_TYPES = [
  'Other',
  'Flat Plate',
  'Horn',
  'Omni',
  'Parabolic',
  'Sector',
  'Whip',
  'Yagi',
] as const;

export const DIAMETER_UNITS = ['feet', 'inches', 'meters'] as const;

export const VERTICAL = 'Vertical';
export const HORIZONTAL = 'Horizontal';
export const SINGLE = 'Single (Either)';
export const DUAL = 'Dual';

export const POLARIZATION_OPTIONS = [
  VERTICAL,
  HORIZONTAL,
  SINGLE,
  DUAL,
] as const;

// Map constants
// export const TOWER_PANE = 'TowerSites';
// export const NETWORK_PANE = 'NetworkPane';
// // See https://leafletjs.com/reference.html#map-pane
// // for the default pane z index values
// // mapPane 	   - 'auto'
// // tilePane    - 200
// // overlayPane - 400
// // shadowPane  - 500
// // markerPane  - 600
// // tooltipPane - 650
// // popupPane   - 700
// export const TOWER_PANE_ZINDEX = 600;
// export const TOWER_ZINDEX_OFFSET = 2;
// export const NETWORK_PANE_ZINDEX = 600;
