import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { distanceBetweenTwoPoints } from '../aplayout/AccessPointProperties';
import { Form, Label } from 'semantic-ui-react';
import { getUnitsLabels } from 'src/utils/useful_functions';
import { METER_TO_FEET } from 'src/app.constants';

const OFFSET_LIMIT = 100;
function Offset({ ap, heightUnits, resetLatLng }) {
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [lat, lng] = useWatch({
    name: ['radios.0.antennas.0.latitude', 'radios.0.antennas.0.longitude'],
  });
  const [offset, setOffset] = useState(0);
  const siteLat = get(ap, 'site.latitude');
  const siteLng = get(ap, 'site.longitude');
  const latError = get(errors, 'radios.0.antennas.0.latitude');
  const lngError = get(errors, 'radios.0.antennas.0.longitude');
  let scaleFactor = heightUnits === 'm' ? 1 : METER_TO_FEET;
  useEffect(() => {
    if (latError == null && lngError == null) {
      const distance = distanceBetweenTwoPoints(
        { latitude: siteLat, longitude: siteLng },
        { latitude: lat, longitude: lng }
      );
      setOffset(distance * scaleFactor);
      if (distance > OFFSET_LIMIT) {
        setError('offset', { type: 'custom', message: '' });
      } else {
        clearErrors('offset');
      }
    }
  }, [lat, lng, lngError, latError, heightUnits]);

  return (
    <Form.Field
      title={`Limited to ${parseInt(
        100 * scaleFactor + ''
      )}${heightUnits} by Ethernet cable length`}
    >
      <label>Offset</label>
      <Form.Group>
        <Form.Input
          error={offset > OFFSET_LIMIT * scaleFactor}
          disabled={true}
          className="no-border"
          value={offset.toFixed(1)}
        ></Form.Input>
        <Label>{getUnitsLabels(heightUnits)}</Label>
        <Form.Button
          compact
          primary
          type="button"
          disabled={offset == 0}
          onClick={() => {
            setValue('radios.0.antennas.0.latitude', ap.site.latitude, {
              shouldDirty: true,
              shouldValidate: true,
            });
            setValue('radios.0.antennas.0.longitude', ap.site.longitude, {
              shouldDirty: true,
              shouldValidate: true,
            });
            resetLatLng();
          }}
        >
          Reset
        </Form.Button>
      </Form.Group>
    </Form.Field>
  );
}

export default Offset;
