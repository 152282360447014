import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Label, Popup } from 'semantic-ui-react';
import messages from 'src/messages';
import { PMPFormField } from 'src/pages/pmp/nd/utils';

// This component is used for displaying the readonly text with label and value
type Props = {
  formatter?: Function;
  className?: string;
  modified?: boolean;
  value?: string;
} & PMPFormField;

function CustomTextField(props: Props) {
  const { label, units, className, formatter, getter, reCalculate, modified } =
    props;
  const { getValues } = useFormContext();
  let value = getValues(getter);
  if (formatter) {
    value = formatter({ value, formGetter: getValues });
  }
  return (
    <Form.Field key={getter}>
      <Popup
        content={messages.saveChanges.defaultMessage}
        basic
        disabled={!(reCalculate && modified)}
        trigger={
          <label style={reCalculate && modified ? { color: '#2185d0' } : {}}>
            {label}
          </label>
        }
      />

      <Form.Group>
        <Form.Input
          value={props.value || value}
          disabled={true}
          className={`no-border ${className}`}
        ></Form.Input>
        {units != null && <Label>{units}</Label>}
      </Form.Group>
    </Form.Field>
  );
}

export default CustomTextField;
