import { get } from 'lodash';
import {
  getEIRPConfig,
  getUserPower,
  getInterferenceConfig,
  getPowerConfig,
} from '../../config-utils';
import { POWER_PANEL_FIELDS_WIDTH } from '../../nd/configs/config-utils';
import { PMPFormField } from '../../nd/utils';
import { powerFieldInfoFormatter } from '../../utils';

export const getSMEIRPConfig = (
  isMultiRadio = false,
  idx = 0
): PMPFormField => {
  return {
    ...getEIRPConfig(isMultiRadio, idx),
    warning({ sm }): string {
      return sm.radios[idx].warnings.eirp;
    },
  };
};

export const getSMPowerConfig = (
  isMultiRadio = false,
  idx = 0
): PMPFormField => {
  return {
    ...getPowerConfig(isMultiRadio, idx),
    componentProps({ choices }) {
      const unitExtraTooltip = powerFieldInfoFormatter(
        choices,
        `power.${idx}.maximum_settable_power`
      );
      const cProps = {
        unitExtraTooltip,
      };
      return isMultiRadio
        ? { width: POWER_PANEL_FIELDS_WIDTH, ...cProps }
        : cProps;
    },
  };
};

export const smUserPowerFields: PMPFormField = {
  ...getUserPower(false),
  nextValue(currentValue, newChoices, formGetter, attr) {
    return newChoices.power[0].user_power;
  },
  min: -40,
  max: 40,
};

export const getSMInterferenceFields = (
  isMultiRadio = false,
  idx = 0
): PMPFormField => {
  return {
    ...getInterferenceConfig(isMultiRadio, idx),
    label: 'Interference?',
    checkboxGetter: ({ formGetter, choices }) => {
      const { power } = choices;
      const apSMInterferenceEnabled = get(power, `${idx}.use_noise_sm`);
      return apSMInterferenceEnabled
        ? `radios.${idx}.power.use_noise_sm`
        : `radios.${idx}.power.use_noise`;
    },
    checkboxCompProps({ formGetter, choices }) {
      const { power } = choices;
      const apSMInterferenceEnabled = get(power, `${idx}.use_noise_sm`);
      const checked = apSMInterferenceEnabled
        ? formGetter(`radios.${idx}.power.use_noise_sm`)
        : formGetter(`radios.${idx}.power.use_noise`);
      return {
        disabled: apSMInterferenceEnabled,
        className: 'sm-interference',
        checked: checked,
      };
    },
  };
};
