import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NumberControlSemantic from './NumberControlSemantic';
import { METER_TO_FEET } from 'src/app.constants';
import { RootStateOrAny } from 'src/store';

function HeightControl(props) {
  const { value, min, max, units, ...rest } = props;
  const { prefs } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { heightUnits } = prefs;
  const [scaleFactor, setScaleFactor] = useState(1);
  useEffect(() => {
    if (heightUnits === 'ft') {
      setScaleFactor(METER_TO_FEET);
    } else {
      setScaleFactor(1);
    }
  }, [heightUnits]);

  return (
    <>
      <NumberControlSemantic
        value={isNaN(value) ? 0 : value}
        min={min * scaleFactor}
        max={max * scaleFactor}
        scaleFactor={scaleFactor}
        units={heightUnits}
        {...rest}
      ></NumberControlSemantic>
    </>
  );
}

export default React.memo(HeightControl);
