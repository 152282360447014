import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import {
  setImportingAnp,
  recalculateProject,
} from '../mainframe/mainframe.reducer';
import { ConfirmActionContainer } from 'src/components/ConfirmAction';

type Props = {
  imported: boolean | null;
};

const TerragraphImport = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector((state: any) => state.mainFrame.projectId);
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const importingAnp = useSelector(
    (state: any) => state.mainFrame.importingAnp
  );

  const show = (value) => {
    setOpen(value);
  };

  const startImport = () => {
    setError(false);
    setLoading(true);
    show(false);
    dispatch(setImportingAnp(true));
    postWithAuth(`projects/${projectId}/anp/import_project`, {})
      .then(() => {
        dispatch(
          recalculateProject({
            start: true,
            message: 'Full project recalculation is in progress.',
          })
        );
        navigate('/');
      })
      .catch(() => {
        dispatch(setImportingAnp(false));
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (props.imported === true) {
    return (
      <p style={{ textAlign: 'center' }} className="mt-4">
        Successfully imported.
      </p>
    );
  }

  return (
    <>
      <Button
        onClick={() => show(true)}
        color="blue"
        className="centered-btn mt-4"
        disabled={importingAnp || !permissionWrite}
        loading={loading}
        // style={{ opacity: importingAnp ? 0.5 : 1 }}
      >
        Import Network
      </Button>
      <ConfirmActionContainer
        open={open}
        size="mini"
        header="Are you sure?"
        message={
          <div className="content">
            <p>
              Importing your results into this project will delete all existing
              Network Devices, PMP links and Mesh links. If additional POPs have
              been requested then the Node Types may also change.
            </p>
            <p>Please press OK to confirm that you wish to continue.</p>
            <Message warning>
              <Message.Header>Please Note</Message.Header>
              Some links may show as NLOS in LINKPlanner following the import.
              Adjusting the location of the end closest to the obstruction by 1
              or 2 m should resolve this.
            </Message>
          </div>
        }
        cancelButton="No"
        confirmButton="Yes"
        onCancel={() => show(false)}
        onConfirm={startImport}
      />
      {error && (
        <p style={{ color: 'red', marginTop: '1rem' }}>
          An error occurred when trying to import the results.
        </p>
      )}
    </>
  );
};

export default TerragraphImport;
