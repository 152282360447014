import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import destination from '@turf/destination';
import { useSelector } from 'react-redux';
import { Segment, Header, Form, Message, Label } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { decodeLatLong, encodeLatLong } from 'src/utils/useful_functions';
import { store } from 'src/store';
import { setDefaultHeight } from './map.reducer';
import {
  createMapSite,
  createPTPLinkFromSiteLatLng,
  getCanvas,
} from 'src/utils/mapUtils';
import HeightControl from 'src/components/controls/HeightControl';
import SiteNameTemplate from './SiteNameTemplate';
import messages from '../../messages';
import { distanceBetweenTwoPoints } from '../pmp/aplayout/AccessPointProperties';
import {
  KILOMETERS_TO_MILES,
  MAX_PTP_LINK_LEGNTH_KM,
  MIN_LINK_LENGTH_KM,
} from 'src/app.constants';

const calculateLinkLength = (localCoordString, remoteCoordString) => {
  const {
    valid: localCoordsValid,
    lat: localLat,
    lng: localLng,
  } = parseCoords(localCoordString);
  const {
    valid: remoteCoordsValid,
    lat: remoteLat,
    lng: remoteLng,
  } = parseCoords(remoteCoordString);
  let distance = 0;
  if (
    localCoordsValid &&
    remoteCoordsValid &&
    localCoordString != remoteCoordString
  ) {
    distance = distanceBetweenTwoPoints(
      { latitude: localLat, longitude: localLng },
      { latitude: remoteLat, longitude: remoteLng }
    );
    distance = distance / 1000;
  }

  return { linkLength: distance };
};

export const parseCoords = (coordString) => {
  if (!coordString) {
    return { lat: 0, lng: 0, valid: false };
  }
  const coordArray = coordString.split(',');
  if (coordArray.length !== 2) {
    return { lat: 0, lng: 0, valid: false };
  }
  const [latStr, lngStr] = coordArray;
  const [lat, validLat] = decodeLatLong(latStr.trim(), true);
  const [lng, validLng] = decodeLatLong(lngStr.trim(), false);
  return { lat, lng, valid: validLat && validLng };
};

const getHelpText = (isNetwork, isPTPMode) => {
  if (isNetwork) {
    return additionalMessages.clickForNetworkSite;
  } else if (isPTPMode) {
    return additionalMessages.ptpModeHelpMessage;
  } else {
    return additionalMessages.clickForSubscriberSite;
  }
};

function SiteModePanel(props) {
  const { isNetwork, header, isPTPMode = false } = props;
  const { prefs } = useSelector((state) => state.mainFrame);
  const [enterCoords, setEnterCoords] = useState(false);
  const { userCoords, defaultHeight, mapSiteName } = useSelector(
    (state) => state.map
  );
  const [scaledDefaultHeight, setScaledHeight] = useState(defaultHeight);
  const remoteCoords = destination([userCoords.lng, userCoords.lat], 5, 90);
  const { geometry } = remoteCoords;
  const { coordinates } = geometry;
  const userCoordString = `${encodeLatLong(
    userCoords.lat,
    prefs.latLngFormat,
    true
  )}, ${encodeLatLong(userCoords.lng, prefs.latLngFormat, false)}`;

  const remoteCoordString = `${encodeLatLong(
    coordinates[1],
    prefs.latLngFormat,
    true
  )}, ${encodeLatLong(coordinates[0], prefs.latLngFormat, false)}`;
  const [coordString, setCoordString] = useState(userCoordString);
  const [ptpData, setPTPData] = useState({
    linkLength: calculateLinkLength(userCoordString, remoteCoordString)[
      'linkLength'
    ],
    remoteCoordString: remoteCoordString,
    localCoordString: userCoordString,
  });
  const { valid } = parseCoords(coordString || userCoordString);
  const coordsAreValid = valid;
  const localCoordsValid = parseCoords(
    ptpData.localCoordString || userCoordString
  )['valid'];
  const remoteCoordsValid = parseCoords(
    ptpData.remoteCoordString || userCoordString
  )['valid'];
  const { formatMessage } = props.intl;
  const message = getHelpText(isNetwork, isPTPMode);

  useEffect(() => {
    if (prefs.heightUnits === 'm') {
      setScaledHeight(defaultHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs.heightUnits]);

  const invalidCoords = () => {
    if (isPTPMode) {
      const { localCoordString, remoteCoordString } = ptpData;
      const { valid } = getLinkLengthMsg();
      return !(
        localCoordsValid &&
        remoteCoordsValid &&
        localCoordString !== remoteCoordString &&
        valid
      );
    } else {
      return !coordsAreValid || !coordString;
    }
  };

  const getLinkLengthMsg = () => {
    const lengthUnits = prefs.rangeUnits;
    let valid = true;
    let msg = '';
    let linkLength =
      lengthUnits === 'mi'
        ? ptpData.linkLength * KILOMETERS_TO_MILES
        : ptpData.linkLength;
    if (
      ptpData.linkLength > MIN_LINK_LENGTH_KM &&
      ptpData.linkLength < MAX_PTP_LINK_LEGNTH_KM
    ) {
      msg = `Link length is ${linkLength.toFixed(3)} ${lengthUnits}`;
    } else {
      valid = false;
      msg = `Invalid link length ${linkLength.toFixed(3)} ${lengthUnits}`;
    }
    return { msg, valid };
  };

  return (
    <>
      <Segment className="map-mode-panel">
        <Segment basic textAlign="center">
          <Header as="h2">{header}</Header>
        </Segment>
        <Form
          size="large"
          onSubmit={() => {
            if (isPTPMode) {
              const { localCoordString, remoteCoordString } = ptpData;
              const { lat: localLat, lng: localLng } =
                parseCoords(localCoordString);
              const { lat: remoteLat, lng: remoteLng } =
                parseCoords(remoteCoordString);
              createPTPLinkFromSiteLatLng(
                localLat,
                localLng,
                remoteLat,
                remoteLng
              );
            } else {
              const { lat, lng } = parseCoords(coordString || userCoordString);
              const kindUrl = isNetwork ? 'network_site' : 'subscriber_site';
              createMapSite(lat, lng, kindUrl);
            }
          }}
        >
          <SiteNameTemplate isPTPMode={isPTPMode} isNetwork={isNetwork} />
          <Form.Field>
            <label>
              <FormattedMessage
                id="mapToolbar.maxHeight"
                defaultMessage="Maximum Height"
              />
            </label>
            <HeightControl
              value={scaledDefaultHeight}
              min={0}
              max={3000}
              precision={1}
              valueType="decimal"
              step={0.1}
              width={16}
              onChange={(e, { value, unScaledVal }) => {
                if (value !== '') {
                  store.dispatch(
                    setDefaultHeight(parseFloat(unScaledVal || 0))
                  );
                  setScaledHeight(parseFloat(value));
                  const map = getCanvas().map;
                  if (unScaledVal < 0 || unScaledVal > 3000) {
                    L.DomUtil.addClass(
                      map._container,
                      'leaflet-crosshair-disabled'
                    );
                  } else {
                    L.DomUtil.removeClass(
                      map._container,
                      'leaflet-crosshair-disabled'
                    );
                  }
                }
              }}
            />
          </Form.Field>
          <Form.Field
            label={
              <FormattedMessage
                id="mapToolbar.enterCoords"
                defaultMessage="Enter coordinates?"
              />
            }
            control="input"
            type="checkbox"
            checked={enterCoords}
            onChange={() => setEnterCoords(!enterCoords)}
          />
          {isPTPMode && enterCoords ? (
            <>
              <Form.Input
                label={formatMessage(messages.localCoordinates)}
                name="localCoords"
                disabled={!enterCoords}
                className={enterCoords ? null : 'no-border'}
                error={!localCoordsValid && enterCoords}
                minLength={1}
                maxLength={60}
                value={enterCoords ? ptpData.localCoordString : userCoordString}
                onChange={(e, { value }) => {
                  const { linkLength } = calculateLinkLength(
                    value,
                    ptpData.remoteCoordString
                  );
                  setPTPData((ptpData) => {
                    return { ...ptpData, localCoordString: value, linkLength };
                  });
                }}
              />
              <Form.Input
                label={formatMessage(messages.remoteCoordinates)}
                name="remoteCoords"
                disabled={!enterCoords}
                className={enterCoords ? null : 'no-border'}
                error={!remoteCoordsValid && enterCoords}
                minLength={1}
                maxLength={60}
                value={
                  enterCoords ? ptpData.remoteCoordString : userCoordString
                }
                onChange={(e, { value }) => {
                  const { linkLength } = calculateLinkLength(
                    ptpData.localCoordString,
                    value
                  );
                  setPTPData((ptpData) => {
                    return { ...ptpData, remoteCoordString: value, linkLength };
                  });
                }}
              />
              <Label
                basic
                className="field no-border"
                size={'small'}
                color={getLinkLengthMsg()['valid'] ? '' : 'red'}
              >
                {getLinkLengthMsg()['msg']}
              </Label>
            </>
          ) : (
            <Form.Input
              label={
                <FormattedMessage
                  id="mapToolbar.coordinates"
                  defaultMessage="Coordinates"
                />
              }
              name="name"
              disabled={!enterCoords}
              className={enterCoords ? null : 'no-border'}
              error={!coordsAreValid && enterCoords}
              minLength={1}
              maxLength={60}
              value={enterCoords ? coordString : userCoordString}
              onChange={(e, { value }) => setCoordString(value)}
            />
          )}
          <Message info hidden={enterCoords}>
            {formatMessage(message)}
          </Message>
          {enterCoords ? (
            <Form.Button
              color="blue"
              type="submit"
              disabled={invalidCoords() || !mapSiteName}
            >
              <FormattedMessage
                id="mapToolbar.submit"
                defaultMessage="Submit"
              />
            </Form.Button>
          ) : null}
        </Form>
      </Segment>
    </>
  );
}

export default injectIntl(SiteModePanel);
