import React, {
  createRef,
  useEffect,
  useState,
  Component,
  useCallback,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Toolbar from 'src/components/controls/Toolbar';
import {
  DOWNLOAD_ACTION_TOOLBAR,
  DEFAULT_TABLE_PAGE_SIZE,
  INITIAL_MAX_TABLE_PAGE_SIZE,
} from 'src/app.constants';
import { meterToAny } from 'src/utils/useful_functions';
import { store } from 'src/store';
import { selectNDForResult } from './best_server.reducer';
import { postWithAuth } from 'src/api';
import { processCellCallback } from 'src/components/controls/lpgrid/LPGridUtils';
import { fixedRenderer } from 'src/components/controls/lpgrid/cellrenderers/UnitRenderer';
import { Checkbox } from 'semantic-ui-react';

class NDEditor extends Component {
  constructor(props) {
    super(props);
    this.inputRef = createRef();

    this.projectId = props.projectId;
    this.rowIndex = props.rowIndex;
    this.siteId = props.data.site_id;
    this.numOptions = props.data.best_aps.length;

    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  createOptions() {
    let result = [];
    for (let i = 1; i <= this.numOptions; ++i) {
      result.push(
        <option value={`ND ${i}`} key={`${this.siteId}-ND${i}`}>
          ND {i}
        </option>
      );
    }
    return result;
  }

  render() {
    return (
      <select
        ref={this.inputRef}
        value={this.state.value}
        onChange={(e) => {
          postWithAuth(
            `project/${this.projectId}/best_server/results/${this.siteId}/update`,
            {
              selected_nd: e.target.value,
            }
          ).catch((e) => console.error(e));
          store.dispatch(
            selectNDForResult({
              row: this.rowIndex,
              value: e.target.value,
            })
          );
        }}
      >
        {this.createOptions()}
      </select>
    );
  }
}

function ResultsTable(props) {
  const { results } = props;
  const [rowData, setRowData] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [gridApi, setGridApi] = useState(null);
  const [actions, setActions] = useState([DOWNLOAD_ACTION_TOOLBAR]);
  const [showAllRows, setShowAllRows] = useState(false);
  const { prefs, projectName, projectId } = useSelector(
    (state) => state.mainFrame
  );
  const gridRef = useRef();

  useEffect(() => {
    setRowData(results);
    const pageSize =
      results.length < INITIAL_MAX_TABLE_PAGE_SIZE
        ? results.length
        : DEFAULT_TABLE_PAGE_SIZE;
    setPageSize(pageSize);
    DOWNLOAD_ACTION_TOOLBAR.disabled = results.length ? false : true;
    setActions([DOWNLOAD_ACTION_TOOLBAR]);
    autoSizeAll(false);
    // Automatically check "Show All Rows" if table items is less than 60
    setShowAllRows(results.length < INITIAL_MAX_TABLE_PAGE_SIZE);
  }, [results]);

  //const actions = [DOWNLOAD_ACTION_TOOLBAR];
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();
  };

  const getRowStyle = (params) => {
    if (params.data.status === 'OOR' || params.data.status === 'NMR') {
      return { backgroundColor: '#ffc0cb', color: 'red' };
    }
    return {};
  };

  if (gridRef?.current) {
    if (showAllRows) {
      gridRef.current.api?.paginationSetPageSize(Number(rowData.length));
    } else {
      gridRef.current.api?.paginationSetPageSize(Number(pageSize));
    }
  }

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  return (
    <>
      <Toolbar
        actions={actions}
        clickHandlerParams={{ gridApi: gridApi }}
        rowDataLength={rowData?.length}
      ></Toolbar>
      <div className="p-relative">
        <div className="ag-theme-alpine generic-grid basic_server">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            pagination={true}
            paginationPageSize={pageSize}
            domLayout={'autoHeight'}
            context={{ prefs }}
            rowHeight={28}
            headerHeight={25}
            onGridReady={onGridReady}
            getRowStyle={getRowStyle}
            frameworkComponents={{
              ndEditor: NDEditor,
              fixedRenderer: fixedRenderer,
            }}
            defaultCsvExportParams={{
              fileName: `${projectName}_best_server.csv`,
              processCellCallback,
            }}
          >
            <AgGridColumn headerName="Site">
              <AgGridColumn
                field="site_name"
                headerName="Name"
                pinned="left"
                sortable
                filter
              />
              <AgGridColumn
                field="height"
                headerName={`Height (${prefs.heightUnits})`}
                sortable
                filter
                valueFormatter={({ value }) =>
                  meterToAny(value, prefs.heightUnits)
                }
              />
              <AgGridColumn
                field="status"
                headerName="Selected ND"
                sortable
                filter
                editable={({ data }) => {
                  return data.best_aps != null;
                }}
                cellEditor="ndEditor"
                cellEditorParams={{ projectId }}
                valueSetter={(_) => {
                  /* noop (prevent default behaviour = crash) */
                }}
              />
            </AgGridColumn>
            <AgGridColumn headerName="ND 1">
              <AgGridColumn
                headerName="Name"
                valueGetter={({ data }) =>
                  data.best_aps ? data.best_aps[0].ap_name : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Product"
                valueGetter={({ data }) =>
                  data.best_aps ? data.best_aps[0].product : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Fade Margin (dB)"
                valueGetter={({ data }) =>
                  data.best_aps ? data.best_aps[0].fade_margin : null
                }
                cellRenderer="fixedRenderer"
                cellRendererParams={{
                  precision: 2,
                }}
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Antenna Gain (dBi)"
                valueGetter={({ data }) =>
                  data.best_aps ? data.best_aps[0].antenna_gain : null
                }
                sortable
                filter
              />
            </AgGridColumn>
            <AgGridColumn headerName="ND 2">
              <AgGridColumn
                headerName="Name"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 1
                    ? data.best_aps[1].ap_name
                    : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Product"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 1
                    ? data.best_aps[1].product
                    : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Fade Margin (dB)"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 1
                    ? data.best_aps[1].fade_margin
                    : null
                }
                cellRenderer="fixedRenderer"
                cellRendererParams={{
                  precision: 2,
                }}
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Antenna Gain (dBi)"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 1
                    ? data.best_aps[1].antenna_gain
                    : null
                }
                sortable
                filter
              />
            </AgGridColumn>
            <AgGridColumn headerName="ND 3">
              <AgGridColumn
                headerName="Name"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 2
                    ? data.best_aps[2].ap_name
                    : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Product"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 2
                    ? data.best_aps[2].product
                    : null
                }
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Fade Margin (dB)"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 2
                    ? data.best_aps[2].fade_margin
                    : null
                }
                cellRenderer="fixedRenderer"
                cellRendererParams={{
                  precision: 2,
                }}
                sortable
                filter
              />
              <AgGridColumn
                headerName="SM Antenna Gain (dBi)"
                valueGetter={({ data }) =>
                  data.best_aps && data.best_aps.length > 2
                    ? data.best_aps[2].antenna_gain
                    : null
                }
                sortable
                filter
              />
            </AgGridColumn>
          </AgGridReact>
        </div>
        {rowData.length > INITIAL_MAX_TABLE_PAGE_SIZE &&
        rowData.length >= pageSize ? (
          <div className="showAllRows">
            <Checkbox
              label="Show All Rows"
              checked={showAllRows}
              onChange={() => {
                setShowAllRows(!showAllRows);
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default injectIntl(ResultsTable);
