import React, { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { PREDICTION_MODEL_OPTIONS, NAME_MAX_LENGTH } from 'src/app.constants';
import { useIntl } from 'react-intl';
import { newProject } from 'src/api';
import { store } from 'src/store';
import { loadProject } from '../mainframe/mainframe.reducer';
import { useNavigate } from 'react-router-dom';

const NewProjectDialog = (props) => {
  const { formatMessage } = useIntl();
  const { isOpen, onClose } = props;
  const [state, setState] = useState({
    useClutter: true,
    predictionModel: PREDICTION_MODEL_OPTIONS[1]['value'],
    projectPromise: null,
    modifiedName: '',
    error: null,
  });

  const cancelCreateProject = () => {
    onClose();
  };

  const onPropertiesChange = (e, value, name) => {
    setState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onCreateProjectNameChange = (e, { value }) => {
    setState((prevState) => {
      return { ...prevState, modifiedName: value };
    });
  };

  const onCreateProjectKey = (e) => {
    if (e.key === 'Enter' && state.modifiedName.length) {
      doCreate();
    }
  };

  const navigate = useNavigate();

  const doCreate = () => {
    if (!state.projectPromise) {
      const postObj = {
        name: state.modifiedName,
        use_clutter: state.useClutter,
        prediction_model: state.predictionModel,
      };
      const getProject = newProject(postObj).then((res) => {
        setState((prevState) => {
          return {
            ...prevState,
            busy: false,
            projectPromise: null,
            error: null,
          };
        });
        if (res.id) {
          store.dispatch(
            loadProject({
              projectId: res.id,
              projectName: res.name,
            })
          );
          onClose();
          navigate('/');
        } else {
          setState((prevState) => {
            return { ...prevState, error: res.detail };
          });
        }
      });
      setState((prevState) => {
        return { ...prevState, projectPromise: getProject };
      });
    }
    setState((prevState) => {
      return { ...prevState, getProjectName: false, modifiedName: null };
    });
  };

  return (
    <Modal open={isOpen} onClose={cancelCreateProject} size="mini">
      <Modal.Header>
        {formatMessage(additionalMessages.createNewProject)}
        <Button
          circular
          icon="close"
          title={formatMessage(additionalMessages.close)}
          floated="right"
          onClick={cancelCreateProject}
        />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Name"
            data-testid="newProjectName"
            onChange={onCreateProjectNameChange}
            onKeyPress={onCreateProjectKey}
            maxLength={NAME_MAX_LENGTH}
            fluid
            autoFocus
          />
          <Form.Group widths="equal">
            <Form.Select
              name="predictionModel"
              value={state.predictionModel}
              onChange={(e, data) =>
                onPropertiesChange(e, data.value, 'predictionModel')
              }
              label={formatMessage(additionalMessages.predictionModel)}
              options={PREDICTION_MODEL_OPTIONS}
              placeholder={formatMessage(additionalMessages.predictionModel)}
            />
            <Form.Checkbox
              name="useClutter"
              onChange={(e, data) =>
                onPropertiesChange(e, data.checked, 'useClutter')
              }
              checked={state.useClutter}
              style={{ marginTop: '22px' }}
              label={formatMessage(additionalMessages.useClutter)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancelCreateProject}>
          {formatMessage(additionalMessages.cancel)}
        </Button>
        <Button
          onClick={doCreate}
          data-testid="newProjectCreate"
          color="blue"
          disabled={!state.modifiedName?.length}
        >
          {formatMessage(additionalMessages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewProjectDialog;
