import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Grid, Icon, Message, Modal } from 'semantic-ui-react';
import AntennaForm from './AntennaForm';
import { store } from 'src/store';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { createCustomAntenna, updateCustomAntenna } from './antenna.service';
import { toast } from 'react-toastify';
import { IntlShape, injectIntl } from 'react-intl';
import { closeAntennaModal } from './antenna.reducer';

interface Props {
  intl: IntlShape;
  kind: 'ptp' | 'sm';
  refetch?: any;
  path?: any;
}

interface CompRef {
  handleOk: () => void;
}

const AntennaFormContainer = forwardRef<CompRef, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleOk,
  }));

  const { path, kind, refetch } = props;

  const { projectId } = useSelector((state: any) => state.mainFrame);
  const { modalOpen, antennaPayload } = useSelector(
    (state: any) => state.antenna
  );

  const formData = antennaPayload?.formData;
  const endName = antennaPayload?.endName;
  const isEdit = antennaPayload?.isEdit;
  const antennaFormRef = useRef(null);

  const [valid, setValid] = useState(false);

  const submitHandler = () => {
    handleOk()
      .then((el) => {
        if (refetch) {
          refetch({ field: { attrName: `${endName}_antenna` } });
        }
        let payload = {};
        if (endName) {
          payload[endName] = el;
        } else {
          payload = el;
        }
        store.dispatch(closeAntennaModal(payload));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelHandler = () => {
    store.dispatch(closeAntennaModal({}));
  };

  const getMandatoryBand = () => {
    if (kind === 'ptp') {
      return get(path?.[endName], 'radios[0].equipment.band', null);
    } else {
      return get(path, 'radios.0.equipment.band', null);
    }
  };

  function getValues() {
    if (antennaFormRef.current) {
      const getValues: any = get(antennaFormRef, 'current.getValues', null);
      return getValues();
    } else {
      return {};
    }
  }

  function showError(msg) {
    toast(<Message negative>{msg}</Message>, {
      autoClose: false,
    });
  }

  function showMessage(msg) {
    toast(<Message positive>{msg}</Message>, {
      autoClose: 1000,
    });
  }

  function handleOk() {
    return new Promise(async (resolve, reject) => {
      try {
        let result;
        let msg;

        if (isEdit && formData['user_defined']) {
          result = await updateCustomAntenna(
            projectId,
            formData['id'],
            getValues(),
            kind
          );
          msg = 'Antenna Updated.';
        } else {
          result = await createCustomAntenna(projectId, getValues(), kind);
          msg = 'Antenna Created.';
        }

        showMessage(msg);
        resolve(result);
      } catch (err) {
        const field = get(err, 'detail[0].loc[1]', '');
        const msg = get(err, 'detail[0].msg', 'Error');
        showError(field + ' ' + msg);
        reject(console.error(field + ' ' + msg));
      }
    });
  }

  return (
    <Modal size="tiny" open={modalOpen}>
      <Modal.Header>
        <Grid columns={'equal'}>
          <Grid.Column>
            <div>User Defined Antenna</div>
          </Grid.Column>
          <Grid.Column>
            <Button
              onClick={() => cancelHandler()}
              circular
              floated="right"
              icon
            >
              <Icon name="close" />
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <AntennaForm
          ref={antennaFormRef}
          kind={kind}
          setFormValid={(val) => setValid(val)}
          formData={formData}
          isEdit={isEdit}
          mandatoryBand={getMandatoryBand()}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" onClick={() => cancelHandler()}>
          Cancel
        </Button>
        <Button
          color="blue"
          type="button"
          disabled={!valid}
          onClick={() => submitHandler()}
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default injectIntl(AntennaFormContainer, { forwardRef: true });
