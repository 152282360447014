import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import about from './../About';
import { injectIntl } from 'react-intl';
import messages from 'src/messages';

function Footer({ intl }) {
  const { formatMessage } = intl;
  return (
    <Grid>
      <Grid.Column textAlign="center" data-testid="copyright">
        {about.copyright} <br />
        <Link to="/terms">
          {formatMessage(messages.termsAndConditions)}
        </Link> | <a
          href="https://www.cambiumnetworks.com/privacy-policy/"
          target="_blank"
        >
          {formatMessage(messages.privacyPolicy)}
        </a>
      </Grid.Column>
    </Grid>
  );
}

export default injectIntl(Footer);
