import createSagaMiddleware from 'redux-saga';
import { linkplannerReducers } from './reducers';
import { createBrowserHistory } from 'history';
import rootSaga from './sagas';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middleware = [
  // These settings make it quicker in development, but they are the defaults
  // for prod.
  // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
  ...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  //   ...getDefaultMiddleware({ serializableCheck: false }),
  sagaMiddleware,
];

export const store = configureStore({
  reducer: linkplannerReducers(history),
  middleware,
});

export const dispatchAction = (type, payload) =>
  store.dispatch({ type, payload });

sagaMiddleware.run(rootSaga);

store.subscribe(() => {});

if (process.env.NODE_ENV === 'development') {
  // dev hack to make it easy to get to the store in the browser
  window['store'] = store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateOrAny = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
