import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Label, List, Message, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import CustomAccordion, {
  AccordionType,
} from 'src/components/controls/CustomAccordion';
import messages from 'src/messages';
import TableComponent from './../../components/controls/TableComponent';
import {
  channels,
  countries,
  setPrecisionWithoutRounding,
} from './terragraph.service';
import { getWithAuth } from 'src/api';
import { meterToAny, sortObjectsByKey } from 'src/utils/useful_functions';
import TerragraphImport from './TerragraphImport';
import sortBy from 'lodash/sortBy';

const BOUNDARY_FILENAME_LABEL = 'Boundary Extracted From';

/**
 * Sort the Devices rows by Device SKU and then Device Options
 */
const deviceSort = (dc) => {
  return dc.sort((a1, a2) =>
    `${a1[0]} - ${a1[2]}`.localeCompare(`${a2[0]} - ${a2[2]}`)
  );
};

/**
 * Prioritise the boundary filename item
 * and then sort the remaining items in a case-insensitive
 * alphabetical order by their labels
 */
function sortLosItemsByLabel(items) {
  return sortBy(items, (item) => {
    const isFilename = item.label === BOUNDARY_FILENAME_LABEL ? -1 : 1;
    return [isFilename, item.label.toLowerCase()];
  });
}

const ERROR_MESSAGES = [
  // if the left message is in the error log then display the right message
  [
    'Total POP capacity cannot support the total demand',
    'Insufficient number of POPs to meet 50.0% of requested demand. We recommend to increase the number of POPs or reduce demand.',
  ],
  [
    'The Max MCS level in the mapping table must not smaller than input Min MCS.',
    'The Max MCS level in the mapping table must not smaller than input Min MCS.',
  ],
  [
    'No POP has a positive capacity outgoing link',
    `There are no LOS connections to any of the POPs.
     Please check Minimum MCS settings are not limiting the link lengths.`,
  ],
];

const simpleErrorMessage = (errorMsg: string | null): string => {
  if (errorMsg) {
    for (const msgs of ERROR_MESSAGES) {
      const [left, right] = msgs;
      if (errorMsg.indexOf(left) >= 0) {
        // Simplify the error message if it matches
        // one of the expected patterns
        return right;
      }
    }
    // If we don't have a simple message then just
    // return the original message.
    return errorMsg;
  }
  // null values should return a string
  return '';
};

function TerragraphDetailsViewer(props: {
  details: any;
  status: string;
  intl: any;
  results: any;
  updateEntity: () => void;
}) {
  let { details, intl, status, results } = props;

  const [files, setFiles] = useState({
    inputs: [],
    outputs: [],
  });

  const { formatMessage } = intl;

  let [networkPlan, setNetworkPlan] = useState('');

  let [subNetworkPlan, setSubNetworkPlan] = useState('');
  let [devices, setDevices] = useState([]);

  let [equipment, setEquipment] = useState([]);

  let [financial, setFinancial] = useState([]);

  let [los, setLos] = useState([]);
  let [boundaryWarning, setBoundaryWarning] = useState([]);

  let [networkDesign, setNetworkDesign] = useState([]);
  let [tpSummary, setTpSummary] = useState({
    DN: 0,
    POP: 0,
    CN: 0,
    mesh_links: 0,
    network_sites: 0,
    subscriber_sites: 0,
  });

  const { projectId, prefs, superuser } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const { heightUnits } = prefs;

  const [excludedFields, setExcludedFields] = useState({
    los: [],
    networkDesign: [],
  });

  const getKeys = (obj) => {
    if (obj) {
      return Object.keys(obj);
    } else {
      return [];
    }
  };

  const valuesMapper = {
    boundary_path: BOUNDARY_FILENAME_LABEL,
    cn_height: 'CN Height',
    dn_height: 'DN Height',
    pop_height: 'POP Height',
    cn_can_be_used_as_dn: 'CN Sites Can Be Used As DN Sites',
    default_cin_cir: 'Default CN CIR (Gbps)',
    pop_capacity: 'POP Capacity (Gbps)',
    maximize_guaranteed_bw: formatMessage(messages.maxGuaranteedBw),
  };

  useEffect(() => {
    const getExcludedFields = (networkPlan, subNetworkPlan) => {
      const excludedFields = {
        los: ['cn_can_be_used_as_dn'],
        equipment: ['country'],
        networkDesign: [
          'legacy_method',
          'topology_routing',
          'demand_spacing',
          'demand_connection_radius',
        ],
      };
      if (networkPlan === 'Mesh') {
        excludedFields['los'].push('cn_height');
        if (subNetworkPlan === 'Uniform Demand Model') {
          excludedFields['networkDesign'] = [
            'legacy_method',
            'topology_routing',
          ];
        }
      }
      if (get(details, 'los.use_site_maximum_height')) {
        excludedFields.los.push('cn_height', 'pop_height', 'dn_height');
      }
      return excludedFields;
    };
    const fields = getExcludedFields(networkPlan, subNetworkPlan);
    setExcludedFields(fields);
  }, [networkPlan, subNetworkPlan]);

  useEffect(() => {
    let inputs, outputs;

    if (!superuser) {
      inputs = results?.inputs?.filter(
        (ip) =>
          ![
            'site_list.csv',
            'v1k_mcs_with_backoff.csv',
            'mcs_file.csv',
          ].includes(ip)
      );
      outputs = results?.outputs?.filter(
        (ip) => !['heights.csv', 'job.log'].includes(ip)
      );
    } else {
      inputs = results?.inputs;
      outputs = results?.outputs;
    }
    setFiles({ inputs: inputs, outputs: outputs });
  }, [results]);

  const getVal = (val) => {
    return valuesMapper[val] || val.replace(/_/g, ' ');
  };

  const getDeviceHeaders = (details) => {
    let keys = getKeys(get(details, 'devices[0]', []));
    let headers = [];
    keys.forEach((el) => {
      if (el !== 'max_tx_power' && el !== 'min_tx_power') {
        const arr = el.split('_');
        for (var i = 0; i < arr.length; i++) {
          if (['mcs', 'sku', 'tx'].includes(arr[i])) {
            arr[i] =
              (arr[i] === 'mcs' && 'MCS') ||
              (arr[i] === 'sku' && 'SKU') ||
              (arr[i] === 'tx' && 'TX');
          } else {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          }
        }
        const header = arr.join(' ');
        headers.push(header);
      }
    });
    return headers;
  };

  const setUnitValues = (value, heightUnits, precision) => {
    if (heightUnits.toLowerCase() !== 'm')
      return meterToAny(value, heightUnits, precision);
    return setPrecisionWithoutRounding(value, precision);
  };

  useEffect(() => {
    setNetworkPlan(details?.network_plan);
    setSubNetworkPlan(details?.network_sub_plan);
    let dc = [];
    details &&
      details.devices.forEach((element: any) => {
        delete element.min_tx_power;
        delete element.max_tx_power;
        element.device_type =
          element.device_type === 'POP' ? 'POP/DN' : element.device_type;

        if (details?.network_plan === 'Mesh') {
          if (element.device_type !== 'CN') {
            dc.push(Object.values(element));
          }
        } else {
          dc.push(Object.values(element));
        }
      });
    // sort by device SKU and antenna
    setDevices(deviceSort(dc));

    const channel = get(details, 'equipment.channel', '');
    const country = get(details, 'equipment.country', '');
    const maxEirp = get(details, 'equipment.max_eirp', '');

    const countryFullName = get(
      countries.filter((obj) => obj.value === country),
      '0.text',
      country
    );

    const channelFormatted = get(
      channels.filter((obj) => obj.value === channel),
      '0.text',
      channel
    );
    let ec = [
      { label: 'channel', value: channelFormatted, units: '' },
      { label: 'country', value: countryFullName, units: '' },
      { label: 'Max EIRP', value: maxEirp, units: 'dBm' },
    ];
    setEquipment(ec);
    setBoundaryWarning(details.boundary_warning);
    setTpSummary(details.job_summary_data);
    let budget = get(details, 'financial.budget', '');
    let fc = [{ label: 'budget', value: budget, units: '' }];
    setFinancial(fc);

    let lc = [];
    details &&
      getKeys(details.los).forEach((element) => {
        if (!excludedFields['los'].includes(element)) {
          lc.push({
            label: getVal(element),
            value: ['cn_height', 'dn_height', 'pop_height'].includes(element)
              ? setUnitValues(details.los[element], heightUnits, 1)
              : element === 'max_los_distance'
              ? setUnitValues(details.los[element], heightUnits, 0)
              : details.los[element].toString(),
            units: typeof details.los[element] === 'number' ? heightUnits : '',
          });
        }
      });
    lc.push({
      label: 'Min MCS of Mesh Link',
      value: get(details, 'minimum_mcs_mesh_link', ''),
      units: '',
    });
    if (networkPlan !== 'Mesh') {
      lc.push({
        label: 'Min MCS of Access Link',
        value: get(details, 'minimum_mcs_access_link', ''),
        units: '',
      });
    }
    setLos(sortLosItemsByLabel(lc));

    let nc = [];
    details &&
      getKeys(details.network_design).forEach((element) => {
        if (!excludedFields['networkDesign'].includes(element)) {
          if (
            element === 'dimension' &&
            typeof details.network_design[element] === 'object'
          ) {
            details &&
              getKeys(details.network_design[element]).forEach((el2) => {
                let val = (
                  details.network_design[element][el2] || ''
                ).toString();
                nc.push({
                  label: getVal(el2),
                  value: val,
                  units: el2 === 'link_availability' ? '%' : '',
                });
              });
          } else {
            let val = get(details, `network_design.${element}`, '')?.toString();
            nc.push({ label: getVal(element), value: val, units: '' });
          }
        }
      });
    setNetworkDesign(sortObjectsByKey(nc, 'label', 'asc', false));
  }, [details, excludedFields, heightUnits]);

  const accordions = [
    {
      key: 'LOS',
      titleProps: {
        content: `Line Of Sight`,
      },
      contentProps: {
        content: (
          <>
            <Segment basic className="p-0">
              <Grid columns={2} centered verticalAlign="middle">
                {los.map((eq) => {
                  if (eq.label === BOUNDARY_FILENAME_LABEL) {
                    eq.error = boundaryWarning;
                  }
                  return <TerragraphDetailField key={eq.label} field={eq} />;
                })}
              </Grid>
            </Segment>
          </>
        ),
      },
    },
    {
      key: 'Equipment',
      titleProps: {
        content: `Equipment`,
      },
      contentProps: {
        content: (
          <>
            <Segment basic className="p-0">
              <Grid columns={2} centered verticalAlign="middle">
                {equipment.map((eq) => {
                  return <TerragraphDetailField key={eq.label} field={eq} />;
                })}
              </Grid>
            </Segment>
          </>
        ),
      },
    },
    {
      key: 'Devices',
      titleProps: {
        content: `Devices`,
      },
      contentProps: {
        content: (
          <>
            <TableComponent rows={devices} header={getDeviceHeaders(details)} />
          </>
        ),
      },
    },
    {
      key: 'Financial',
      titleProps: {
        content: `Financial`,
      },
      contentProps: {
        content: (
          <>
            <Segment basic className="p-0">
              <Grid columns={2} centered verticalAlign="middle">
                {financial.map((eq) => {
                  return <TerragraphDetailField key={eq.label} field={eq} />;
                })}
              </Grid>
            </Segment>
          </>
        ),
      },
    },
    {
      key: 'Network Design',
      titleProps: {
        content: `Network Design`,
      },
      contentProps: {
        content: (
          <>
            <Segment basic className="p-0">
              <Grid columns={2} centered verticalAlign="middle">
                {networkDesign.map((eq) => {
                  return (
                    <TerragraphDetailField
                      key={eq.label}
                      field={eq}
                      formatMessage={formatMessage}
                    />
                  );
                })}
              </Grid>
            </Segment>
          </>
        ),
      },
    },
  ];

  // We can only import when there are at least 3 CSV files in the outputs
  const canImport =
    results?.outputs?.length &&
    results.outputs.filter((fn) => fn.toLowerCase().endsWith('.csv')).length >=
      3 &&
    status === 'completed';

  let rightAccordions: AccordionType[] = [
    {
      key: 'FileList',
      titleProps: {
        content: `File List`,
      },
      contentProps: {
        content: (
          <>
            {canImport ? (
              <TerragraphImport imported={details.imported} />
            ) : null}
            <Grid columns={2} centered>
              <Grid.Row columns={2} stretched>
                <Grid.Column>
                  <Label content={'Inputs'} basic className="no-border p-0" />
                  {files.inputs.length ? (
                    <List className="allow-scroll">
                      {files.inputs.map((eq) => {
                        return (
                          <List.Item>
                            <FileLink
                              key={eq}
                              projectId={projectId}
                              fileName={eq}
                            />
                          </List.Item>
                        );
                      })}{' '}
                    </List>
                  ) : (
                    <Grid style={{ padding: '0.5833em 0.833em' }}>
                      No files could be found for the project
                    </Grid>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Label content={'Outputs'} basic className="no-border p-0" />
                  {files.outputs.length ? (
                    <List className="allow-scroll">
                      {files.outputs.map((eq) => {
                        return (
                          <List.Item>
                            <FileLink
                              key={eq}
                              projectId={projectId}
                              fileName={eq}
                              isInput={false}
                            />
                          </List.Item>
                        );
                      })}{' '}
                    </List>
                  ) : (
                    <Grid style={{ padding: '0.5833em 0.833em' }}>
                      No files could be found for the project
                    </Grid>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ),
      },
    },

    {
      key: 'inputSitesTable',
      titleProps: {
        content: `Input Sites`,
      },
      contentProps: {
        content: (
          <>
            <Grid columns={networkPlan === 'Distribution' ? 3 : 2} centered>
              <Grid.Row
                columns={networkPlan === 'Distribution' ? 3 : 2}
                stretched
              >
                <Grid.Column className="tpSiteCounts">
                  <Label content={"POP's"} basic className="no-border p-0" />

                  <List className="allow-scroll">
                    <List.Item>
                      {details.input_sites_count.POP
                        ? details.input_sites_count.POP
                        : 0}
                    </List.Item>
                  </List>
                </Grid.Column>

                <Grid.Column className="tpSiteCounts">
                  <Label content={"DN's"} basic className="no-border p-0" />

                  <List className="allow-scroll">
                    <List.Item>
                      {details.input_sites_count.DN
                        ? details.input_sites_count.DN
                        : 0}
                    </List.Item>
                  </List>
                </Grid.Column>

                {networkPlan === 'Distribution' ? (
                  <Grid.Column className="tpSiteCounts">
                    <Label content={"CN's"} basic className="no-border p-0" />

                    <List className="allow-scroll">
                      <List.Item>
                        {details.input_sites_count.CN
                          ? details.input_sites_count.CN
                          : 0}
                      </List.Item>
                    </List>
                  </Grid.Column>
                ) : null}
              </Grid.Row>
            </Grid>
          </>
        ),
      },
    },
    ...(status === 'completed' && tpSummary
      ? [
          {
            key: 'ResultSummary',
            titleProps: {
              content: `Result Summary`,
            },
            contentProps: {
              content: (
                <>
                  <Grid
                    columns={networkPlan === 'Distribution' ? 4 : 3}
                    centered
                  >
                    <Grid.Row
                      columns={networkPlan === 'Distribution' ? 4 : 3}
                      stretched
                    >
                      <Grid.Column className="tpSiteCounts">
                        <Label
                          content={"POP's"}
                          basic
                          className="no-border p-0"
                        />

                        <List className="allow-scroll">
                          <List.Item>
                            {tpSummary.POP ? tpSummary.POP : 0}
                          </List.Item>
                        </List>
                      </Grid.Column>

                      <Grid.Column className="tpSiteCounts">
                        <Label
                          content={"DN's"}
                          basic
                          className="no-border p-0"
                        />

                        <List className="allow-scroll">
                          <List.Item>
                            {tpSummary.DN ? tpSummary.DN : 0}
                          </List.Item>
                        </List>
                      </Grid.Column>
                      {networkPlan === 'Distribution' ? (
                        <Grid.Column className="tpSiteCounts">
                          <Label
                            content={"CN's"}
                            basic
                            className="no-border p-0"
                          />

                          <List className="allow-scroll">
                            <List.Item>
                              {tpSummary.CN ? tpSummary.CN : 0}
                            </List.Item>
                          </List>
                        </Grid.Column>
                      ) : (
                        ''
                      )}
                      <Grid.Column className="tpSiteCounts">
                        <Label
                          content={'Mesh Links'}
                          basic
                          className="no-border p-0"
                        />
                        <List className="allow-scroll">
                          <List.Item>
                            {tpSummary.mesh_links ? tpSummary.mesh_links : 0}
                          </List.Item>
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              ),
            },
          },
        ]
      : []),
  ];

  const simpleError = simpleErrorMessage(details?.error_msg);
  if (status === 'failed' && simpleError) {
    rightAccordions = [
      {
        key: 'Errors',
        titleProps: {
          content: `Runtime Errors`,
          className: 'custom-error',
        },
        contentProps: {
          className: 'custom-error',
          content: simpleError,
        },
      },
      ...rightAccordions,
    ];
  }

  return (
    <Grid columns={2} centered style={{ overflowY: 'auto' }}>
      <Grid.Row>
        <Grid.Column width={6}>
          <CustomAccordion accordions={accordions} />
        </Grid.Column>
        <Grid.Column width={6}>
          <CustomAccordion accordions={rightAccordions} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function TerragraphDetailField(props: any) {
  const { field } = props;
  const { label } = field;

  const getValue = (field) => {
    const { value, units } = field;
    if (value === 'true') {
      return 'Yes';
    } else if (value === false || value === 'false') {
      return 'No';
    } else if (!value) {
      return 0;
    } else {
      return (
        <div>
          {value} {units}
        </div>
      );
    }
  };
  return (
    <>
      <Grid.Row
        className="p-0"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid.Column textAlign="right">
          <Label basic className="no-border enhanced-label">
            {label}
          </Label>
        </Grid.Column>

        <Grid.Column textAlign="right" data-test-id={label}>
          {getValue(field)}
        </Grid.Column>
      </Grid.Row>
      {field.error ? (
        <Grid.Row columns={1}>
          <Grid.Column>
            <Message warning>{field.error}</Message>
          </Grid.Column>
        </Grid.Row>
      ) : null}
    </>
  );
}

function FileLink(props: any) {
  const { projectId, fileName, isInput = true } = props;
  const kind = isInput ? 'inputs' : 'output';
  const mimeTypes = {
    kml: 'application/vnd.google-earth.kml+xml',
    kmz: 'application/vnd.google-earth.kmz',
    yaml: 'text/yaml',
    csv: 'text/csv',
    log: 'text/plain',
  };
  const onClickHandler = function (path, name) {
    const ext = name.substring(fileName.lastIndexOf('.') + 1);
    const mimeType = mimeTypes[ext] || 'application/octet-stream';
    getWithAuth(path, 'GET', true)
      .then((res) => {
        const blobObj = new Blob([res], { type: mimeType });
        const blobUrl = window.URL.createObjectURL(blobObj);
        let a = document.createElement('a');
        a.href = blobUrl;
        a.download = `${name}`;
        a.click();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const url = `projects/${projectId}/anp/download/${kind}/${fileName}`;
  return (
    <button
      className="btn-link"
      style={{ padding: '0.5833em 0.833em' }}
      onClick={() => onClickHandler(url, fileName)}
    >
      {fileName}
    </button>
  );
}

export default injectIntl(TerragraphDetailsViewer);
