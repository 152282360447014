import { getCanvas } from '../../utils/mapUtils';
import { states } from './leafletCanvas';
import getFeatureStyle from './getFeatureStyle';
import { HandleDragging } from './apHandles';

/**
 * Feature click handler
 */
export const clickHandler = (event) => {
  const canvas = getCanvas();
  if (canvas.state !== states.MAP_SELECT) {
    return;
  }
  canvas.map.doubleClickZoom.disable();
  const { target, latlng } = event;
  const { lat, lng } = latlng;
  const feature = target.feature;
  const features = canvas.hittest(lat, lng, feature.properties.kind);
  //   console.log(features.forEach((f) => console.log(f.properties.name)));
  canvas.setMultipleSelection(feature, features);
  canvas.map.doubleClickZoom.enable();
};

/**
 * Feature mouseover event handler
 */
export const onmouseover = (event) => {
  const canvas = getCanvas();
  if (canvas.state !== states.MAP_SELECT) {
    return;
  }

  const dragger = new HandleDragging();
  if (!dragger.isDragging) {
    const target = event.target;
    const feature = target.feature;
    const style = getFeatureStyle(feature, true);
    if (!style) {
      return;
    }
    target.setStyle(style);
  }
};

/**
 * Feature mouseout event handler
 */
export const onmouseout = (event) => {
  const target = event.target;
  const feature = target.feature;
  const style = getFeatureStyle(feature, false);
  if (!style) {
    return;
  }
  target.setStyle(style);
};
