import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid, Header, Table } from 'semantic-ui-react';
import TableComponent from '../../../../components/controls/TableComponent';
import { adjustPrecision } from '../../../../utils/useful_functions';
import messages from '../../../../messages';
import { PERCENT, THROUGHPUT } from 'src/app.constants';

export type FloodTestSummaryType = {
  tableLayout: Function;
  downLink: any[];
  upLink: any[];
  downLinkTotal: { percent: number; quantity: number; throughput: number };
  upLinkTotal: { percent: number; quantity: number; throughput: number };
} & WrappedComponentProps;

function FloodTestSummary(props: FloodTestSummaryType) {
  const { intl, tableLayout } = props;
  const { formatMessage } = intl;
  const hasUplink = props.upLink !== null;
  const order = hasUplink ? ['downLink', 'upLink'] : ['downLink'];
  const titles = {
    downLink: formatMessage(messages.floodTestSummaryDownLink),
    upLink: formatMessage(messages.floodTestSummaryUpLink),
  };
  return (
    <Grid stackable columns={hasUplink ? 2 : 1}>
      {order.map((element, index) => {
        const attrs = ['mode', 'quantity', 'percent', 'throughput'];
        const header = [
          { width: 6, value: 'Mode' },
          { width: 2, value: 'Qty' },
          { width: 3, value: 'Percent' },
          { width: 4, value: 'Throughput (Mbps)' },
        ];
        const rows = tableLayout(attrs, props[element]);
        return (
          <Grid.Column>
            <Header as="h5" textAlign="center">
              {titles[element]}
            </Header>
            <TableComponent
              padded={false}
              size="small"
              compact
              header={header}
              rows={rows}
            ></TableComponent>
            <Table compact padded={false} textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={6}>
                    {formatMessage(messages.total)}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    {props[`${element}Total`].quantity}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    {adjustPrecision(props[`${element}Total`].percent, PERCENT)}{' '}
                    %
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    {adjustPrecision(
                      props[`${element}Total`].throughput,
                      THROUGHPUT
                    )}{' '}
                    Mbps
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Grid.Column>
        );
      })}
    </Grid>
  );
}

export default injectIntl(FloodTestSummary);
