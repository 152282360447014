import React, { useState } from 'react';
import {
  Form,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Button,
  Segment,
  Message,
} from 'semantic-ui-react';
import produce from 'immer';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { postWithAuth, getWithAuth } from 'src/api';
import ProjectDetails from '../project/ProjectDetails';

function CopyableId({ id }) {
  if (id == null) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyItems: 'space-between',
        columnGap: '0.5rem',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={() => {
          navigator.clipboard.writeText(id);
        }}
        compact
        basic
        size="mini"
        icon="copy"
        title="Copy ID"
      />
      <span>{id}</span>
    </div>
  );
}

function ProjectResultRow({ project, selectProject }) {
  const emailListStyle = {
    padding: 0,
    listStylePosition: 'inside',
    listStyleType: 'none',
  };
  return (
    <TableRow>
      <TableCell>{project.name}</TableCell>
      <TableCell>
        <CopyableId id={project.id} />
      </TableCell>
      <TableCell>
        <CopyableId id={project.master_id} />
      </TableCell>
      <TableCell>
        <CopyableId id={project.ref_id} />
      </TableCell>
      <TableCell>
        <ul style={emailListStyle}>
          {project.emails.split(',').map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </TableCell>
      <TableCell>
        <Button primary onClick={() => selectProject(project.id)}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
}

function ProjectQueryResults({ projectsList, selectProject }) {
  if (projectsList.length === 0) {
    return <p>No results were found</p>;
  }

  return (
    <>
      {projectsList.length === 100 ? (
        <Message info>
          These results may be truncated. Please try refining your search term.
        </Message>
      ) : null}
      <Table celled compact striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>Master Id</TableHeaderCell>
            <TableHeaderCell>Ref Id</TableHeaderCell>
            <TableHeaderCell>Users</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {projectsList.map((project) => (
            <ProjectResultRow
              key={project.id}
              project={project}
              selectProject={selectProject}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function ProjectDetailsWrapper({ id }) {
  const { data, isFetching } = useQuery({
    queryKey: ['admin', 'viewProject', id],
    queryFn: async () => {
      return await getWithAuth(`admin/view_project/${id}`);
    },
  });

  if (isFetching) {
    return <p>Loading...</p>;
  }

  return <ProjectDetails data={data} />;
}

function ProjectDetails({ data }) {
  const { project, master, summary, users, profiles } = data;
  const isInWork = project.master_id != null;

  const [updateError, setUpdateError] = useState(null);

  const [cloneEmail, setCloneEmail] = useState('');
  const [cloneMessage, setCloneMessage] = useState(null);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: { terragraph_import: project.terragraph_import },
  });

  const qc = useQueryClient();
  const modifyProject = useMutation({
    mutationFn: async ({ form, kind }) => {
      return await postWithAuth(
        `admin/modify_project/${project.id}?kind=${kind}`,
        form,
        'PATCH'
      );
    },
    onSuccess: (response) => {
      qc.setQueryData(['admin', 'viewProject', project.id], (oldData) => {
        return produce(oldData, (draft) => {
          draft.project.terragraph_project = response.project;
          if (
            draft.hasOwnProperty('master') &&
            response.hasOwnProperty('master')
          ) {
            draft.master.terragraph_import = response.master;
          }
        });
      });
      reset({ terragraph_import: response.project });
      setUpdateError(null);
    },
    onError: (response) => {
      setUpdateError(response.detail);
    },
  });

  const purgeProfiles = useMutation({
    mutationFn: async () => {
      return await postWithAuth(
        `admin/purge_profiles/${project.id}`,
        {},
        'DELETE'
      );
    },
    onSuccess: (response) => {
      qc.setQueryData(['admin', 'viewProject', project.id], (oldData) => {
        return produce(oldData, (draft) => {
          draft.profiles.project_specific = response.count;
          draft.profiles.non_lidar = response.count;
        });
      });
      setUpdateError(null);
    },
    onError: () => {
      setUpdateError('Error when trying to purge project specific profiles');
    },
  });

  const cloneProject = useMutation({
    mutationFn: async ({ email }) => {
      return await postWithAuth(`admin/clone_project/${project.id}`, { email });
    },
    onSuccess: (response) => {
      setCloneMessage({
        ok: true,
        message: `Cloned project to id: ${response.id} (ref id: ${response.ref_id})`,
      });
    },
    onError: (response) => {
      setCloneMessage({
        ok: false,
        message: `Failed to clone project: ${response.detail}`,
      });
    },
  });

  const onSubmit = (form, event) => {
    const [kind] = event.nativeEvent.submitter.id.split('-');
    modifyProject.mutate({ form, kind });
  };

  const detailGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: '1rem',
  };

  let masterLidarLinks = null;
  if (isInWork) {
    if (master?.terragraph_import === true) {
      masterLidarLinks = 'Yes';
    } else if (master?.terragraph_import === false) {
      masterLidarLinks = 'No';
    } else {
      masterLidarLinks = 'Unknown (error)';
    }
  }

  let enableApply = isDirty;
  let enableApplyMaster = isDirty;
  if (
    !enableApply &&
    master != null &&
    watch('terragraph_import') !== master.terragraph_import
  ) {
    // enable the master apply if there is a master and the terragraph import
    // setting in the in-work does not match the master
    enableApplyMaster = true;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {updateError != null && (
        <Segment color="red" inverted style={{ marginTop: '0.5rem' }}>
          {updateError}
        </Segment>
      )}
      {cloneMessage != null && (
        <Segment
          color={cloneMessage.ok ? 'blue' : 'red'}
          inverted
          style={{ marginTop: '0.5rem' }}
        >
          {cloneMessage.message}
        </Segment>
      )}

      <Segment
        style={{
          marginTop: '1rem',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <div>
          <h2>Project Details</h2>
          <div style={detailGridStyle}>
            <strong>Name</strong>
            <span>{project.name}</span>
            <strong>ID</strong>
            <span>
              <CopyableId id={project.id} />
            </span>
            <strong>Master ID</strong>
            <span>
              {project.master_id != null ? (
                <CopyableId id={project.master_id} />
              ) : (
                <>None</>
              )}
            </span>
            <strong>Ref ID</strong>
            <span>
              <CopyableId id={project.ref_id} />
            </span>
            <strong>Version</strong>
            <span>{project.version}</span>
            <strong>Last Modified</strong>
            <span>{project.last_modified}</span>
            <strong>In Work Version?</strong>
            <span>{isInWork ? 'Yes' : 'No'}</span>
            <strong>Lidar Links?</strong>
            <span>
              <Controller
                control={control}
                name="terragraph_import"
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Form.Checkbox
                    {...rest}
                    checked={rest.value}
                    onChange={(_, data) => {
                      onChange(data.checked);
                    }}
                  />
                )}
              />
            </span>
          </div>

          {isInWork ? (
            <>
              <h2>Master Details</h2>
              <div style={detailGridStyle}>
                <strong>Lidar Links?</strong>
                <span>{masterLidarLinks}</span>
              </div>
            </>
          ) : null}
        </div>

        <div>
          <h2>Project Summary</h2>
          <div style={detailGridStyle}>
            <strong>Network Sites</strong>
            <span>{summary.network_sites}</span>
            <strong>Subscriber Sites</strong>
            <span>{summary.subscriber_sites}</span>
            <strong>PTP Links</strong>
            <span>{summary.ptp_links}</span>
            <strong>Network Devices</strong>
            <span>{summary.nds}</span>
            <strong>PMP Links</strong>
            <span>{summary.pmp_links}</span>
            <strong>Mesh Links</strong>
            <span>{summary.mesh_links}</span>
          </div>

          <h2>Profiles</h2>
          <div style={detailGridStyle}>
            <strong>Project Specific Profiles</strong>
            <span>{profiles.project_specific}</span>
            <strong>Non-Lidar Profiles</strong>
            <span>{profiles.non_lidar}</span>
          </div>
          {profiles.project_specific > 0 ? (
            <Button
              onClick={() => purgeProfiles.mutate()}
              color="red"
              type="button"
            >
              Purge Project Profiles
            </Button>
          ) : null}
        </div>

        <div style={{ marginTop: '1rem', gridColumn: 'span 2 / span 2' }}>
          <h2>Users</h2>

          <Table celled compact striped>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Permission</TableHeaderCell>
                <TableHeaderCell>Last Accessed</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow key={user.email}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.permission}</TableCell>
                    <TableCell>{user.last_accessed}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <div
          style={{
            gridColumn: 'span 2 / span 2',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button id="in_work-apply" primary disabled={!enableApply}>
              Apply
            </Button>
            {isInWork ? (
              <Button id="master-apply" primary disabled={!enableApplyMaster}>
                and Apply to Master
              </Button>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '0.25rem',
            }}
          >
            <span>Email</span>
            <input
              type="email"
              onChange={(e) => setCloneEmail(e.target.value)}
            />
            <Button
              onClick={() => cloneProject.mutate({ email: cloneEmail })}
              color="orange"
              type="button"
            >
              Clone
            </Button>
          </div>
        </div>
      </Segment>
    </Form>
  );
}

export function ProjectToolsForm() {
  const qc = useQueryClient();
  const {
    control,
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: { kind: 'name', search: '' },
  });

  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const { data } = useQuery({
    queryKey: ['admin', 'findProject'],
    queryFn: async () => {
      return await postWithAuth(`admin/find_project`, getValues());
    },
    enabled: isSubmitted,
  });

  const onSubmit = () => {
    setSelectedProjectId(null);
    qc.removeQueries({
      queryKey: ['admin', 'findProject'],
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Controller
            control={control}
            name="kind"
            render={({ field: { value, ref, onChange, ...rest } }) => (
              <Form.Radio
                {...rest}
                label="Name"
                value="name"
                checked={value === 'name'}
                onChange={(e, data) => {
                  onChange(data.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="kind"
            render={({ field: { value, ref, onChange, ...rest } }) => (
              <Form.Radio
                {...rest}
                label="User Email"
                value="email"
                checked={value === 'email'}
                onChange={(e, data) => {
                  onChange(data.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="kind"
            render={({ field: { value, ref, onChange, ...rest } }) => (
              <Form.Radio
                {...rest}
                label="Project Id"
                value="id"
                checked={value === 'id'}
                onChange={(e, data) => {
                  onChange(data.value);
                }}
              />
            )}
          />
        </Form.Group>

        <div
          style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}
        >
          <input
            {...register('search', { required: true })}
            placeholder="Search"
            style={{ flex: '1 1 0%' }}
          />
          <Form.Button
            primary
            type="submit"
            disabled={watch('search')?.trim().length === 0}
          >
            Search
          </Form.Button>
        </div>
      </Form>

      {selectedProjectId != null ? (
        <ProjectDetailsWrapper id={selectedProjectId} />
      ) : null}

      {data != null ? (
        <ProjectQueryResults
          projectsList={data}
          selectProject={setSelectedProjectId}
        />
      ) : null}
    </>
  );
}
