import React, { useRef } from 'react';
import { get } from 'lodash';
import { Dropdown, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import CreateAccessPointModalFooter from './CreateAccessPointModalFooter';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { sortObjectsByKey } from 'src/utils/useful_functions';
import EquipmentTemplatePanel from './EquipmentTemplatePanel';

function ViewTemplate({
  template,
  handlePrevStep,
  handleNextStep,
  closeHandler,
  customClassName,
}) {
  let accessPoints = useSelector(
    (state: RootStateOrAny) => state.mainFrame.accessPoints
  );
  accessPoints = accessPoints.features.map((feature) => feature.properties);
  let apOptions = accessPoints.map((obj) => {
    return { key: obj.id, text: obj.name, value: obj.id };
  });
  apOptions = sortObjectsByKey(apOptions, 'text');
  const apId = useRef(get(apOptions, '0.key'));
  const apName = useRef(get(apOptions, '0.text'));

  if (template) {
    return (
      <div
        className={customClassName}
        style={{ height: '72vh', overflow: 'auto' }}
      >
        <EquipmentTemplatePanel
          templateId={template}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          closeHandler={closeHandler}
        ></EquipmentTemplatePanel>
      </div>
    );
  } else {
    return (
      <>
        <div
          className={customClassName}
          style={{
            height: '23vh',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '20vw',
            marginRight: '20vw',
          }}
        >
          <Header as="h4">
            <FormattedMessage
              id="common.selectPMPNetworkDevice"
              defaultMessage="Select PMP Network Device"
            />
          </Header>
          <Dropdown
            placeholder="Select Country"
            fluid
            search
            selection
            defaultValue={apId.current}
            options={apOptions}
            onChange={(e, data) => {
              console.log('e', e, 'data', data.value);
              apId.current = data.value;
              apName.current = e.target['textContent'];
            }}
          />
          <CreateAccessPointModalFooter
            handlePrevStep={handlePrevStep}
            handleNextStep={() =>
              handleNextStep({ apId: apId.current, apName: apName.current })
            }
            closeHandler={closeHandler}
          ></CreateAccessPointModalFooter>
        </div>
      </>
    );
  }
}

export default ViewTemplate;
