import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import AntennaPanel from './AntennaPanel';
import { PanelHeading } from 'src/components/PanelHeading';

const panes = [
  {
    menuItem: {
      as: NavLink,
      id: 'antennasPTPTab',
      content: 'PTP Antennas',
      to: '/antennas/ptp',
      key: 'antennasPTP',
    },
    render: () => <AntennaPanel />,
  },
  {
    menuItem: {
      as: NavLink,
      id: 'antennasAPTab',
      content: 'PMP Network Devices Antennas',
      to: '/antennas/network_device',
      key: 'antennasAP',
    },
    render: () => <AntennaPanel />,
  },
  {
    menuItem: {
      as: NavLink,
      id: 'antennasSMTab',
      content: 'Subscriber Module Antennas',
      to: '/antennas/subscriber',
      key: 'antennasSM',
    },
    render: () => <AntennaPanel />,
  },
];

// to activate the current index after refresh
export const antennaTypes = {
  ptp: 0,
  network_device: 1,
  subscriber: 2,
};

const getActiveIndex = () => {
  return panes.findIndex((el) => el.menuItem.to === location.pathname);
};

const AntennasPanel = () => {
  return (
    <>
      <PanelHeading />
      <Tab panes={panes} activeIndex={getActiveIndex()} />
    </>
  );
};

export default injectIntl(AntennasPanel);
