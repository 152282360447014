import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import { Form, Grid, Popup } from 'semantic-ui-react';
import CustomAccordion from 'src/components/controls/CustomAccordion';
import { CreateNetwork, NetworkPlan } from './terragraph.modal';
import messages from 'src/messages';
import {
  getError,
  getNumberFieldValidateObject,
  handleNumbers,
} from './terragraph.service';

function CreateNetworkDesignStep(props: {
  createNetworkFormData: CreateNetwork;
  createNetworkFormDataChange: Function;
  networkPlanData: NetworkPlan;
  setValidate: Function;
  intl: any;
  popSites: number;
}) {
  const {
    createNetworkFormData,
    createNetworkFormDataChange,
    setValidate,
    intl,
    popSites,
  } = props;

  const {
    getValues,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues: createNetworkFormData });

  const { formatMessage } = intl;

  const onFormValueChange = () => {
    createNetworkFormDataChange(getValues());
    validateForm();
  };

  const validateForm = () => {
    let values: any[] = Object.keys(getValues());
    trigger(values).then((status) => {
      setValidate(status);
    });
  };

  useEffect(() => {
    validateForm();
  }, []);

  const getExtraPopMinVal = () => {
    if (Number(popSites)) return 0;
    else return 1;
  };

  const accordions = [
    {
      key: 'dimensioning',
      titleProps: {
        content: `Dimensioning`,
      },
      contentProps: {
        content: (
          <Form onChange={onFormValueChange}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.defaultCnCirHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.defaultCnCir)}</label>
                      }
                    />
                    <Controller
                      name={'defaultCncir'}
                      control={control}
                      rules={{
                        min: {
                          value: 0.001,
                          message: 'Value needs to be greater than 0',
                        },
                        max: {
                          value: 10,
                          message: formatMessage(messages.maxValueError, {
                            value: 10,
                          }),
                        },
                        validate: getNumberFieldValidateObject('defaultCncir'),
                      }}
                      render={({ field }) => (
                        <Form.Input
                          min={0.001}
                          step={0.001}
                          {...field}
                          type={'number'}
                          error={getError(errors, 'defaultCncir')}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.overSubscriptionHelpText)}
                      position="right center"
                      trigger={
                        <label>
                          {formatMessage(messages.oversubscription)}
                        </label>
                      }
                    />
                    <Controller
                      name={'overSubscription'}
                      control={control}
                      rules={{
                        min: {
                          value: 1,
                          message: formatMessage(messages.minValueError, {
                            value: 1,
                          }),
                        },
                        max: {
                          value: 999,
                          message: formatMessage(messages.maxValueError, {
                            value: 999,
                          }),
                        },
                        validate:
                          getNumberFieldValidateObject('overSubscription'),
                      }}
                      render={({ field }) => (
                        <Form.Input
                          {...field}
                          min={1}
                          type={'number'}
                          error={getError(errors, 'overSubscription')}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.linkAvailabilityHelpText)}
                      position="right center"
                      trigger={
                        <label>
                          {formatMessage(messages.linkAvailability)}
                        </label>
                      }
                    />
                    <Controller
                      name={'linkAvailability'}
                      control={control}
                      rules={{
                        min: {
                          value: 1,
                          message: formatMessage(messages.minValueError, {
                            value: 1,
                          }),
                        },
                        max: {
                          value: 99.9999,
                          message: formatMessage(messages.maxValueError, {
                            value: 99.9999,
                          }),
                        },
                        validate:
                          getNumberFieldValidateObject('linkAvailability'),
                      }}
                      render={({ field }) => (
                        <Form.Input
                          {...field}
                          min={1}
                          type={'number'}
                          error={getError(errors, 'linkAvailability')}
                        />
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.popCapacityHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.popCapacity)}</label>
                      }
                    />
                    <Controller
                      name={'popCapacity'}
                      control={control}
                      rules={{
                        min: {
                          value: 0.0001,
                          message: 'Value needs to be greater than 0',
                        },
                        max: {
                          value: 50,
                          message: formatMessage(messages.maxValueError, {
                            value: 50,
                          }),
                        },
                        validate: getNumberFieldValidateObject('popCapacity'),
                      }}
                      render={({ field }) => (
                        <Form.Input
                          {...field}
                          min={0}
                          type={'number'}
                          error={getError(errors, 'popCapacity')}
                        />
                      )}
                    />
                  </Form.Field>

                  {/* {networkPlanData.networkPlan === 'Mesh' &&
                  networkPlanData.networkSubPlan === 'Uniform Demand Model' ? (
                    <>
                      <Grid columns={'equal'}>
                        <Grid.Row>
                          <Grid.Column>
                            <Popup
                              content={formatMessage(
                                messages.demandRadiusHelpText
                              )}
                              position="right center"
                              trigger={
                                <label htmlFor="demandRadius">
                                  {formatMessage(messages.demandRadius)}
                                </label>
                              }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Form.Field>
                              <Controller
                                name={'demandRadius'}
                                control={control}
                                rules={{
                                  min: {
                                    value: 1,
                                    message: formatMessage(
                                      messages.minValueError,
                                      { value: 1 }
                                    ),
                                  },
                                }}
                                render={({ field }) => (
                                  <Form.Input
                                    {...field}
                                    type="number"
                                    name={'demandRadius'}
                                    error={
                                      errors.demandRadius && {
                                        content: errors.demandRadius.message,
                                      }
                                    }
                                  />
                                )}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="ui label">
                              {formatMessage(messages.meters)}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Grid verticalAlign="middle" columns={'equal'}>
                        <Grid.Row>
                          <Grid.Column>
                            <Popup
                              content={formatMessage(
                                messages.demandSpacingHelpText
                              )}
                              position="right center"
                              trigger={
                                <label htmlFor="demandSpacing">
                                  {formatMessage(messages.demandSpacing)}
                                </label>
                              }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Form.Field>
                              <Controller
                                name={'demandSpacing'}
                                control={control}
                                rules={{
                                  min: {
                                    value: 1,
                                    message: formatMessage(
                                      messages.minValueError,
                                      { value: 1 }
                                    ),
                                  },
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                  <Form.Input
                                    {...rest}
                                    type="number"
                                    name={'demandSpacing'}
                                    error={
                                      errors.demandSpacing && {
                                        content: errors.demandSpacing.message,
                                      }
                                    }
                                  />
                                )}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="ui label">
                              {formatMessage(messages.meters)}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )} */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        ),
      },
    },
    {
      key: 'networkDesign',
      titleProps: {
        content: `Network Design`,
      },
      contentProps: {
        content: (
          <Form onChange={onFormValueChange}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.extraPopsHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.extraPops)}</label>
                      }
                    />
                    <Controller
                      name={'extraPops'}
                      control={control}
                      rules={{
                        min: {
                          value: getExtraPopMinVal(),
                          message: formatMessage(messages.minValueError, {
                            value: getExtraPopMinVal(),
                          }),
                        },
                        max: {
                          value: 999,
                          message: formatMessage(messages.maxValueError, {
                            value: 999,
                          }),
                        },
                      }}
                      render={({ field }) => (
                        <Form.Input
                          {...field}
                          min={0}
                          type={'number'}
                          onChange={(e) => {
                            field.onChange(Number(e.target.value));
                            handleNumbers(e, false, 0, false);
                          }}
                          onKeyPress={(e) => {
                            handleNumbers(e, false, 0, false);
                          }}
                          error={
                            errors.extraPops && {
                              content: errors?.extraPops?.message,
                            }
                          }
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.numberOfChannelsHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.noOfChannels)}</label>
                      }
                    />
                    <Controller
                      name={'noOfChannels'}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Form.Select
                          {...rest}
                          error={
                            errors.noOfChannels && {
                              content: errors?.noOfChannels?.message,
                            }
                          }
                          onChange={async (e, { name, value }) => {
                            setValue(name, value);
                            onFormValueChange();
                          }}
                          options={[
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                          ]}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.maxNumberOfHopsHelpText)}
                      position="right center"
                      trigger={<label>{formatMessage(messages.maxHops)}</label>}
                    />
                    <Controller
                      name={'maxNumberOfHops'}
                      control={control}
                      rules={{
                        min: {
                          value: 1,
                          message: formatMessage(messages.minValueError, {
                            value: 1,
                          }),
                        },
                        max: {
                          value: 99,
                          message: formatMessage(messages.maxValueError, {
                            value: 99,
                          }),
                        },
                      }}
                      render={({ field }) => (
                        <Form.Input
                          {...field}
                          min={1}
                          type={'number'}
                          onChange={(e) => {
                            field.onChange(Number(e.target.value));
                            handleNumbers(e, false, 0, false);
                          }}
                          onKeyPress={(e) => {
                            handleNumbers(e, false, 0, false);
                          }}
                          error={
                            errors.maxNumberOfHops && {
                              content: errors?.maxNumberOfHops?.message,
                            }
                          }
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.redundancyLevelHelpText, {
                        lineBreak: <br />,
                      })}
                      position="right center"
                      trigger={<label>Redundancy Level</label>}
                    />
                    <Controller
                      name={'redundancyLevel'}
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <Form.Select
                          {...rest}
                          error={
                            errors.redundancyLevel && {
                              content: errors?.redundancyLevel?.message,
                            }
                          }
                          onChange={async (e, { name, value }) => {
                            setValue(name, value);
                            onFormValueChange();
                          }}
                          options={[
                            { text: 'None', value: 'None' },
                            { text: 'Low', value: 'Low' },
                            { text: 'Medium', value: 'Medium' },
                            { text: 'High', value: 'High' },
                            { text: 'Legacy Method', value: 'Legacy Method' },
                          ]}
                        />
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.useAllPopSitesHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.useAllPopSites)}</label>
                      }
                    />
                    <Controller
                      name="useAllPopSites"
                      control={control}
                      rules={{}}
                      render={({ field: { ref, onChange, ...rest } }) => (
                        <Form.Checkbox
                          {...rest}
                          checked={Boolean(
                            createNetworkFormData.useAllPopSites
                          )}
                          onChange={async (e, { name, checked }) => {
                            setValue('useAllPopSites', checked ? 1 : 0);
                            onFormValueChange();
                          }}
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Popup
                      content={formatMessage(messages.maxGuaranteedBwHelpText)}
                      position="right center"
                      trigger={
                        <label>{formatMessage(messages.maxGuaranteedBw)}</label>
                      }
                    />
                    <Controller
                      name="maxGuaranteedBw"
                      control={control}
                      rules={{}}
                      render={({ field: { ref, onChange, ...rest } }) => (
                        <Form.Checkbox
                          {...rest}
                          checked={Boolean(
                            createNetworkFormData.maxGuaranteedBw
                          )}
                          onChange={async (e, { name, checked }) => {
                            setValue('maxGuaranteedBw', checked ? 1 : 0);
                            onFormValueChange();
                          }}
                        />
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        ),
      },
    },
  ];

  return (
    <div>
      <Grid centered columns={2}>
        <Grid.Column>
          <CustomAccordion accordions={accordions}></CustomAccordion>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default injectIntl(CreateNetworkDesignStep);
