import React from "react";
import { injectIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { Form } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";
import StoredAccordion from "src/components/StoredAccordion";
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from 'src/app.constants';

function PTPGeneralPanel(props: any) {
  const { control } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { title, path, setModified } = props;

  const changeHandler = (fn) => (e) => {
    setModified(true);
    return fn(e);
  }

  return (
    <StoredAccordion
      name="ptp_general_panel"
      title={title}
    >
      <Controller
        control={control}
        name="user_name"
        defaultValue={path.user_name}
        render={({ field: { ref, onChange, ...rest } }) => (
          <Form.Input
            label="Name"
            {...rest}
            onChange={changeHandler(onChange)}
            disabled={!permissionWrite}
            maxLength={NAME_MAX_LENGTH}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        defaultValue={path.description}
        render={({ field: { ref, onChange, ...rest } }) => (
          <Form.TextArea
            label="Description"
            {...rest}
            onChange={changeHandler(onChange)}
            disabled={!permissionWrite}
            maxLength={DESCRIPTION_MAX_LENGTH}
          />
        )}
      />
  </StoredAccordion>);
}

export default injectIntl(PTPGeneralPanel);
