import React, { useRef } from 'react';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import StoredAccordion from 'src/components/StoredAccordion';

function NDTableAccordion({ projectName, aps = [], siteName }) {
  const ref = useRef();
  return (
    <StoredAccordion title="PMP Network Devices">
      <LPGrid
        autoSize={false}
        gridRef={ref}
        rowData={aps}
        defaultCsvExportParams={{
          fileName: `${projectName}_network_sites_${siteName}_pmp_network_devices.csv`,
        }}
        addStatusColor
        columnDefs={[
          {
            field: 'name',
            sort: 'asc',
            cellRenderer: 'linkRenderer',
            cellRendererParams: {
              getHref: (data) => `/aps/${data.id}`,
            },
          },
          { field: 'product' },
        ]}
      />
    </StoredAccordion>
  );
}

export default NDTableAccordion;
