import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Button,
  ButtonProps,
  Container,
  Grid,
  Modal,
  Table,
} from 'semantic-ui-react';
import additionalMessages from '../../../../messages';
import FloodTestGrouping from './FloodTestGrouping';

export const mumimoGroupingLayout = (
  attrs: string[],
  rawData: string[][]
): string[][] => {
  return rawData;
};

export type MuMIMOReportType = {
  report: any;
  closeHandler: (event: any, data: ButtonProps) => void;
} & WrappedComponentProps;

function MuMIMOReport({ report, intl, closeHandler }: MuMIMOReportType) {
  const { formatMessage } = intl;
  const open = useState(true)[0];

  return (
    <Modal open={open} closeOnEscape closeOnDimmerClick>
      <Modal.Header className="floodtest-modalheader">
        <Grid>
          <Grid.Row>
            <Grid.Column width="10" as="h3">
              {formatMessage(additionalMessages.muimoReportTitle)}
            </Grid.Column>
            <Grid.Column width="1" floated="right">
              <Button circular floated="right" icon="close" className="mr-0" onClick={closeHandler} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <Container>
          This report shows the proportion of subscribers in each group size and
          compares the throughput in the normal SISO mode (using the long term
          average based on the chosen availability) with Mu-MIMO mode taken as
          an instantaneous view in dry conditions.
        </Container>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <FloodTestGrouping
                tableLayout={mumimoGroupingLayout}
                downLink={report.grouping_table_dl}
                downLinkMaxMuxGain={null}
                upLink={report.grouping_table_ul}
                upLinkMaxMuxGain={null}
              ></FloodTestGrouping>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                    <Table.HeaderCell>DL</Table.HeaderCell>
                    <Table.HeaderCell>UL</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>SISO (Long Term Average)</Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.siso_dl}&nbsp;Mbps
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.siso_ul}&nbsp;Mbps
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.siso_total}&nbsp;Mbps
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Mu-MIMO (Instantaneous Dry)</Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.mumimo_dl}&nbsp;Mbps
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.mumimo_ul}&nbsp;Mbps
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.mumimo_total}&nbsp;Mbps
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Spatial Multiplexing Gain</Table.Cell>
                    <Table.Cell textAlign="center">{report.mux_dl}</Table.Cell>
                    <Table.Cell textAlign="center">{report.mux_ul}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {report.mux_total}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

export default injectIntl(MuMIMOReport);
