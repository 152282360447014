import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PerformanceCharts from 'src/components/PerformanceCharts';
import {
  PerformanceDetailsTables,
  PerformanceDetailsTablesType,
} from './PerformanceDetailsTables';
import { Entities } from './PerformanceDetails';
import { uiSet } from 'src/pages/mainframe/mainframe.reducer';
import { store } from 'src/store';

type PerformanceDetailsViewTabsType = {
  perfChart: Entities;
  localEndName?: string;
  remoteEndName?: string;
} & PerformanceDetailsTablesType;

export const PerformanceDetailsViewTabs = ({
  perfChart,
  localEndName,
  remoteEndName,
  entities,
  kind,
  titles,
}: PerformanceDetailsViewTabsType) => {
  const { activePerformanceDetailsTabView } = useSelector(
    (state: any) => state.mainFrame
  );

  const panes = [
    {
      menuItem: { key: 'performanceDetailsChartView', content: 'Charts' },
      render: () => (
        <PerformanceCharts
          kind={kind}
          perfChart={perfChart}
          localEndName={localEndName}
          remoteEndName={remoteEndName}
        />
      ),
    },
    {
      menuItem: { key: 'performanceDetailsTableView', content: 'Details' },
      render: () => (
        <PerformanceDetailsTables
          entities={entities}
          kind={kind}
          titles={titles}
        />
      ),
    },
  ];

  return (
    <Tab
      menu={{ attached: false }}
      panes={panes}
      className="performance-details-view-tabs"
      activeIndex={activePerformanceDetailsTabView}
      onTabChange={(e, { activeIndex }) => {
        store.dispatch(
          uiSet({ activePerformanceDetailsTabView: activeIndex as number })
        );
      }}
    />
  );
};
