import React, { useRef } from 'react';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import StoredAccordion from 'src/components/StoredAccordion';

const getPTPLinks = (paths, id) => {
  let ptpLinks = [];

  if (paths) {
    ptpLinks = paths.filter((path) => {
      return path.kind == 'ptp';
    });
  }

  const ptpTableData = ptpLinks.map((link) => {
    const otherName =
      link.local_site_id === id ? link.remote_site_name : link.local_site_name;
    return {
      otherName,
      id: link.id,
      band: link.band,
    };
  });

  return ptpTableData;
};

function PTPLinksTableAccordion({ projectName, paths, siteName, siteId }) {
  const linksGridRef = useRef();
  return (
    <StoredAccordion title="PTP Links">
      <LPGrid
        autoSize={false}
        gridRef={linksGridRef}
        rowData={getPTPLinks(paths, siteId)}
        defaultCsvExportParams={{
          fileName: `${projectName}_network_sites_${siteName}_ptp_links.csv`,
        }}
        addStatusColor
        columnDefs={[
          {
            headerName: 'Remote End',
            field: 'otherName',
            sort: 'asc',
            cellRenderer: 'linkRenderer',
            cellRendererParams: {
              getHref: (data) => `/ptp/${data.id}`,
            },
          },
          { field: 'band' },
        ]}
      />
    </StoredAccordion>
  );
}

export default PTPLinksTableAccordion;
