import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Segment, Header, Form, Message } from 'semantic-ui-react';
import { store } from 'src/store';
import additionalMessages from '../../messages';
import { setDefaultHeight, setUseMaxHeight } from '../map/map.reducer';
import HeightControl from '../../components/controls/HeightControl';
import RangeUnitsComp from './RangeUnitsComp';
import { getCanvas } from 'src/utils/mapUtils';

function PMPModePanel(props) {
  const { isAP } = props;
  const header = isAP ? (
    <FormattedMessage
      id="mapToolbar.ndMode"
      defaultMessage="Network Device Mode"
    />
  ) : (
    <FormattedMessage
      id="mapToolbar.pmpLinkMode"
      defaultMessage="PMP Link Mode"
    />
  );

  const message = isAP
    ? additionalMessages.clickForAccessPoint
    : additionalMessages.clickForPMPLink;

  const { prefs, mapState } = useSelector((state) => state.mainFrame);
  const { defaultHeight, useSiteMaxHeight } = useSelector((state) => state.map);
  const [scaledDefaultHeight, setScaledHeight] = useState(defaultHeight);

  useEffect(() => {
    if (prefs.heightUnits === 'm') {
      setScaledHeight(defaultHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs.heightUnits]);

  const { formatMessage } = props.intl;
  return (
    <>
      <Segment className="map-mode-panel">
        <Segment basic textAlign="center">
          <Header as="h2">{header}</Header>
        </Segment>
        <Form size="large">
          <Form.Field>
            <label>
              <FormattedMessage
                id="mapToolbar.height"
                defaultMessage="Height"
              />
            </label>
            <HeightControl
              value={scaledDefaultHeight}
              min={0}
              max={3000}
              precision={1}
              step={0.1}
              valueType="decimal"
              width={16}
              onChange={(e, { value, unScaledVal }) => {
                if (value !== '') {
                  store.dispatch(setDefaultHeight(parseFloat(unScaledVal)));
                  setScaledHeight(parseFloat(value));
                  const map = getCanvas().map;
                  if (unScaledVal < 0 || unScaledVal > 3000) {
                    L.DomUtil.addClass(
                      map._container,
                      'leaflet-crosshair-disabled'
                    );
                  } else {
                    L.DomUtil.removeClass(
                      map._container,
                      'leaflet-crosshair-disabled'
                    );
                  }
                }
              }}
            />
          </Form.Field>
          <Form.Field
            label={
              <FormattedMessage
                id="mapToolbar.useMaxHeight"
                defaultMessage="Use the site Maximum Height?"
              />
            }
            control="input"
            type="checkbox"
            checked={useSiteMaxHeight}
            onChange={() => store.dispatch(setUseMaxHeight(!useSiteMaxHeight))}
          />
          {mapState === 'create access point' && <RangeUnitsComp />}
          <Message info>{formatMessage(message)}</Message>
        </Form>
      </Segment>
    </>
  );
}

export default injectIntl(PMPModePanel);
