import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    clearSocketMessages: (state) => {
      const stateKeys = Object.keys(state);
      stateKeys.map((k) => delete state[k]);
    },
    userMsg: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { clearSocketMessages, userMsg, } = socketSlice.actions;
export default socketSlice.reducer;
