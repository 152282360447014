import { get } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ProfileEditor from 'src/components/profilechart/ProfileEditorModal';

function PMPProfileChart({
  profile,
  ap,
  sm,
  local,
  remote,
  setModified,
  parentFormSubmitHandler,
  modified,
}) {
  const { getValues } = useFormContext();
  // we are setting the default value of component carrier to 1
  // which works wells now, but when we are going to support more than 2
  // carriers we need to look at sm state and set the default value
  const [componentCarrier, setComponentCarrier] = useState(1);
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const [remoteHeight, componentCarrierQty] = getValues([
    'radios.0.antennas.0.height',
    'radio_qty',
  ]);
  const carrierQty = get(ap, 'radios.0.equipment.carrier_qty');

  const profileProps = {
    accordionName: 'SubscriberPanelProfile',
    profileData: profile,
    localHeight: local.height,
    remoteHeight,
    sm,
    // Unlike on PTP, we don't have a path here (but we should).
    // Hack one in for now so that the profile works.
    path: {},
    frequencyGHz:
      get(ap, `radios.${componentCarrier - 1}.frequency.frequency_mhz`, 5800) /
      1000,
    rangeUnits: prefs.rangeUnits,
    heightUnits: prefs.heightUnits,
    localSiteName: local.site_name,
    remoteSiteName: remote.site_name,
    isMultiRadio: carrierQty > 1 && componentCarrierQty > 1,
    componentCarrierQty,
    componentCarrier,
    setComponentCarrier,
    //errMsg: profileData.errMsg,
    loading: false,
    kind: 'pmp',
    modified,
    setModified,
    parentFormSubmitHandler,
  };
  return <ProfileEditor {...profileProps} />;
}

export default PMPProfileChart;
