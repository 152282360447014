import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Label, Menu, Icon, Button } from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { shallowEqual, useSelector } from 'react-redux';
import { nameSort } from 'src/utils/naming';
import {
  LIST_ITEM_FONT_COLOR,
  SELECTED_LIST_ITEM_BG_COLOR,
  centerMap,
  deleteHandler,
} from './MenuItem';
import { filterItems } from 'src/workers/filter.utils';
import { getCanvas } from 'src/utils/mapUtils';
import { FilterContext } from './MainMenu';
import LocalStorageUtils from 'src/utils/LocalStorageUtils';
import { makePlural } from 'src/utils/useful_functions';

const removeAlpha = (col) => {
  if (col) {
    if (typeof col === 'string' && col.length > 7) {
      // colour contains alpha at the end of the string
      col = col.slice(0, col.length - 2);
    }
    return col;
  }
};

const apTree = (sms) => {
  const tree = {};
  sms.forEach((sm) => {
    if (tree[sm.ap_id] === undefined) {
      tree[sm.ap_id] = [];
    }
    tree[sm.ap_id].push(sm);
  });
  return tree;
};

const makeMeshLinkMap = (meshLinks) => {
  const map = {};
  meshLinks.forEach((meshLink) => {
    const { local_end_id } = meshLink;
    if (map[local_end_id] === undefined) {
      map[local_end_id] = [];
    }
    map[local_end_id].push(meshLink);
  });
  return map;
};

export const AccessPointsMenuItem = ({ expandSidebar }) => {
  const filter = useContext(FilterContext);
  const location = useLocation();

  const [apsAll, pmpLinksAll, meshLinksAll, filterOnMapBounds, mapBounds] =
    useSelector(
      (state) => [
        state.mainFrame.accessPoints,
        state.mainFrame.pmpLinks,
        state.mainFrame.meshLinks,
        state.mainFrame.filterOnMapBounds,
        state.mainFrame.mapBounds,
      ],
      shallowEqual
    );
  const meshLinks = useMemo(() => {
    if (meshLinksAll?.features) {
      const linksWithProps = meshLinksAll.features.map((item) => {
        const properties = item.properties;
        return { ...properties };
      });
      const copyLinks = [];
      linksWithProps.forEach((link) => {
        const copyLink = { ...link };
        const {
          name,
          remote_end_id,
          local_end_id,
          rem_lat,
          rem_lng,
          loc_lat,
          loc_lng,
        } = link;
        copyLink['local_end_id'] = remote_end_id;
        copyLink['remote_end_id'] = local_end_id;
        copyLink['loc_lat'] = rem_lat;
        copyLink['loc_lng'] = rem_lng;
        copyLink['rem_lat'] = loc_lat;
        copyLink['rem_lng'] = loc_lng;
        link['name'] = `DN to ${name.split(' to ')[1]}`;
        copyLink['name'] = `DN to ${name.split(' to ')[0]}`;
        copyLinks.push(copyLink);
      });
      return [...linksWithProps, ...copyLinks];
    } else {
      return [];
    }
  }, [meshLinksAll]);

  if (!apsAll || !pmpLinksAll) {
    return null;
  }
  let accessPoints = [...apsAll.features];
  accessPoints.sort(nameSort);
  const pmpLinks = pmpLinksAll.features.map((item) => {
    const properties = item.properties;
    const name = properties.name.split(' to ')[1];
    return { ...properties, name };
  });

  let allAps = accessPoints.map((item) => {
    const properties = item.properties;
    return { ...properties };
  });
  let finalPMPLinks;
  let finalMeshLinks;
  let meshLinkMap;
  let smsForEachAP;
  let finalApsList;

  if (filterOnMapBounds || filter != '') {
    const drawnFeatures = Object.keys(getCanvas().drawnFeatures);
    finalPMPLinks = filterItems(
      pmpLinks,
      filter,
      filterOnMapBounds ? mapBounds : null,
      drawnFeatures
    );
    finalMeshLinks = filterItems(
      meshLinks,
      filter,
      filterOnMapBounds ? mapBounds : null,
      drawnFeatures
    );
    const apsMatchingFilter = filterItems(
      allAps,
      filter,
      filterOnMapBounds ? mapBounds : null,
      drawnFeatures
    );
    meshLinkMap = makeMeshLinkMap(finalMeshLinks);
    smsForEachAP = apTree(finalPMPLinks);
    // along with ap name filter we need to look for sm/mesh links matching as well
    const apsWithLinks = allAps.filter((apObj) => {
      return (
        smsForEachAP[apObj['id']] != null || meshLinkMap[apObj['id']] != null
      );
    });
    finalApsList = [...new Set([...apsMatchingFilter, ...apsWithLinks])];
  } else {
    finalPMPLinks = pmpLinks;
    finalMeshLinks = meshLinks;
    meshLinkMap = makeMeshLinkMap(finalMeshLinks);
    smsForEachAP = apTree(finalPMPLinks);
    finalApsList = allAps;
  }

  return (
    <AccessPointsItem
      expandSidebar={expandSidebar}
      accessPoints={finalApsList}
      smsForEachAP={smsForEachAP}
      meshLinkMap={meshLinkMap}
    />
  );
};

const APMenuItem = ({ ap, sms, meshLinks, apNameMap }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [projectRefId, projectId, permissionWrite] = useSelector(
    (state) => [
      state.mainFrame.projectRefId,
      state.mainFrame.projectId,
      state.mainFrame.permissionWrite,
    ],
    shallowEqual
  );

  const apId = ap.id;
  const warnings = ap.warnings;
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timeStamp: new Date().getTime(),
    });
    setShowList(expandedItems[apId] ?? false);
  }, [projectRefId, apId]);

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timeStamp: new Date().getTime(),
    });
    if (showList) {
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        [apId]: showList,
        timeStamp: new Date().getTime(),
      });
    } else {
      delete expandedItems[apId];
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        timeStamp: new Date().getTime(),
      });
    }
  }, [apId, showList]);

  const isActive = location.pathname.endsWith(apId);

  let titleArray = [];
  if (sms) {
    titleArray.push(
      `${sms.length} PMP ${makePlural(sms.length, 'Link', 'Links')}`
    );
  }

  if (meshLinks) {
    titleArray.push(
      `${meshLinks.length} Mesh ${makePlural(
        meshLinks.length,
        'Link',
        'Links'
      )}`
    );
  }

  if (warnings && warnings.hover && warnings.hover.length > 0) {
    titleArray.push(warnings.hover.join(','));
  }

  const apdiv = (
    <div title={titleArray.join(' - ')} className="access-point-menu-item">
      {sms || meshLinks ? (
        <div
          className="sublist-dropdown"
          onClick={() => setShowList((show) => !show)}
        >
          <Icon
            name={showList ? 'chevron down' : 'chevron right'}
            style={{ margin: '0' }}
          />
        </div>
      ) : (
        // Placeholder icon to give the correct indentation
        <Icon className="i.icon" />
      )}
      <Icon
        className="access-point-menu-icon"
        title={formatMessage(messages.selectItem)}
      />

      <Link
        to={`/aps/${apId}`}
        className={isActive ? 'menu-item-active' : null}
        style={{
          color: removeAlpha(ap.strokeColor) || LIST_ITEM_FONT_COLOR,
          flexGrow: 1,
        }}
      >
        {ap.name}
      </Link>

      <div className="menu-ctrls">
        <Button
          icon="map marker alternate"
          style={{ padding: 0 }}
          title={formatMessage(messages.showInMap)}
          onClick={() => {
            navigate('/');
            centerMap(ap);
          }}
        />
        {permissionWrite && (
          <Button
            icon="trash alternate"
            style={{ padding: 0 }}
            onClick={() => {
              deleteHandler(
                'access_point',
                projectId,
                apId,
                formatMessage,
                navigate
              );
            }}
            title={formatMessage(messages.delete)}
          ></Button>
        )}
      </div>
    </div>
  );

  let meshLinkDiv;
  if (meshLinks && showList) {
    meshLinkDiv = meshLinks.map((meshLink) => {
      let subListStyle = {};
      const { local_end_id, remote_end_id, id, strokeColor, warnings, name } =
        meshLink;

      const isActive = location.pathname.endsWith(id);

      let title = ``;
      if (warnings?.hover) {
        title += `${warnings?.hover}`;
      }

      return (
        <div
          style={subListStyle}
          className="access-point-sub-list-item"
          key={`${local_end_id}-${remote_end_id}`}
          title={title}
        >
          <Icon
            className="ptp-link-menu-icon"
            title={formatMessage(messages.selectItem)}
          />
          <Link
            to={`/mesh/${id}`}
            className={isActive ? 'menu-item-active' : null}
            style={{
              color: strokeColor || LIST_ITEM_FONT_COLOR,
            }}
          >
            {name}
          </Link>

          <div className="menu-ctrls">
            <Button
              icon="map marker alternate"
              style={{ padding: 0 }}
              title={formatMessage(messages.showInMap)}
              onClick={() => {
                navigate('/');
                centerMap(meshLink);
              }}
            />
            {permissionWrite && (
              <Button
                icon="trash alternate"
                style={{ padding: 0 }}
                title={formatMessage(messages.delete)}
                onClick={() => {
                  deleteHandler(
                    'mesh_link',
                    projectId,
                    id,
                    formatMessage,
                    navigate
                  );
                }}
              ></Button>
            )}
          </div>
        </div>
      );
    });
  }

  let smDiv;
  if (sms && showList) {
    sms.sort((s1, s2) => {
      const name1 = s1.name.toLowerCase();
      const name2 = s2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      } else if (name1 < name2) {
        return -1;
      } else {
        return 0;
      }
    });
    smDiv = sms.map((sm) => {
      const { sm_id, strokeColor, name, warnings } = sm;
      let title = '';
      if (warnings?.hover) {
        title += `${warnings?.hover}`;
      }
      let subListStyle = {};
      const id = sm_id;
      const isActive = location.pathname.endsWith(id);
      if (strokeColor) {
        subListStyle['color'] = strokeColor;
      }

      return (
        <div
          style={subListStyle}
          title={title}
          className="access-point-sub-list-item"
          key={id}
        >
          <Icon
            className="subscriber-module-menu-icon"
            title={formatMessage(messages.selectItem)}
          />
          <Link
            to={`/subscribers/${id}`}
            className={isActive ? 'menu-item-active' : null}
            style={{
              color: strokeColor || LIST_ITEM_FONT_COLOR,
              flexGrow: 1,
            }}
          >
            {name}
          </Link>

          <div className="menu-ctrls">
            <Button
              icon="map marker alternate"
              style={{ padding: 0 }}
              title={formatMessage(messages.showInMap)}
              onClick={() => {
                navigate('/');
                centerMap(sm);
              }}
            />
            {permissionWrite && (
              <Button
                icon="trash alternate"
                style={{ padding: 0 }}
                title={formatMessage(messages.delete)}
                onClick={() => {
                  deleteHandler(
                    'pmp_link',
                    projectId,
                    sm.id,
                    formatMessage,
                    navigate,
                    id
                  );
                }}
              ></Button>
            )}
          </div>
        </div>
      );
    });
  }
  return (
    <>
      {apdiv}
      {meshLinkDiv}
      {smDiv}
    </>
  );
};

const AccessPointsItem = ({
  expandSidebar,
  accessPoints,
  smsForEachAP,
  meshLinkMap,
}) => {
  const [projectRefId, pmpLinksCount, meshLinksCount] = useSelector(
    (state) => [
      state.mainFrame.projectRefId,
      state.mainFrame.pmpLinksCount,
      state.mainFrame.meshLinksCount,
    ],
    shallowEqual
  );
  const pmpSelectedTableTab = useSelector(
    (state) => state.mainFrame.pmpSelectedTableTab
  );

  const [showList, setShowList] = useState(false);
  const label = expandSidebar ? (
    <FormattedMessage id="mainMenu.pmp" defaultMessage="PMP" />
  ) : null;

  const apNameMap = Object.fromEntries(
    accessPoints.map((ap) => {
      return [ap.id, ap.name];
    })
  );

  let titleArray = [
    `${accessPoints.length} Network ${makePlural(
      accessPoints.length,
      'Device',
      'Devices'
    )}`,
  ];
  if (pmpLinksCount != 0) {
    titleArray.push(
      `${pmpLinksCount} PMP ${makePlural(pmpLinksCount, 'Link', 'Links')}`
    );
  }
  if (meshLinksCount != 0) {
    titleArray.push(
      `${meshLinksCount} Mesh ${makePlural(meshLinksCount, 'Link', 'Links')}`
    );
  }

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timeStamp: new Date().getTime(),
    });
    setShowList(expandedItems['aps'] ?? false);
  }, [projectRefId]);

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timestamp: new Date().getTime(),
    });
    if (showList) {
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        aps: showList,
        timeStamp: new Date().getTime(),
      });
    } else {
      delete expandedItems['aps'];
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        timeStamp: new Date().getTime(),
      });
    }
  }, [showList]);

  const isActive =
    location.pathname.startsWith('/aps') ||
    location.pathname.startsWith('/mesh') ||
    location.pathname.startsWith('/subscribers');
  const isOnlyTopActive =
    location.pathname.endsWith('/aps') ||
    location.pathname.endsWith('/mesh') ||
    location.pathname.endsWith('/subscribers');

  return (
    <>
      <div className="menu-wrapper">
        {expandSidebar && (
          <div
            className="menu-dropdown"
            onClick={() => {
              setShowList((show) => !show);
            }}
          >
            <Icon name={showList ? 'chevron down' : 'chevron right'} />
          </div>
        )}

        <Menu.Item
          title={titleArray.join(', ')}
          name="PMP Network Devices"
          active={isActive}
          className="menu-header"
          as={Link}
          to={isOnlyTopActive ? '/' : pmpSelectedTableTab}
          style={{
            flexGrow: '1',
            placeContent: expandSidebar ? null : 'center',
          }}
        >
          <div>
            <Icon className="access-point-icon" />
          </div>
          {expandSidebar && <span>{label}</span>}
          {expandSidebar && (
            <div>
              <Label className="float-right">{accessPoints.length}</Label>
            </div>
          )}
        </Menu.Item>
      </div>

      {showList && expandSidebar && (
        <Menu.Menu
          style={{
            backgroundColor: 'white',
            borderBottom: '1px rgba(34, 36, 38, 0.1) solid',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          {accessPoints.map((ap) => {
            const { id } = ap;
            return (
              <APMenuItem
                key={id}
                ap={ap}
                apNameMap={apNameMap}
                meshLinks={meshLinkMap[id]}
                sms={smsForEachAP[id]}
              />
            );
          })}
        </Menu.Menu>
      )}
    </>
  );
};
