import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ProfileEditor from 'src/components/profilechart/ProfileEditorModal';
import { useLinkKind } from 'src/pages/ptp/hooks';

function getHeights(
  values: [number | string, number | string],
  formStates: [any, any],
  heightUnits: 'm' | 'ft'
): [number, number] {
  let result: number[] = [];
  if (values?.length !== 2) {
    return [0, 0];
  }

  for (let i = 0; i < values?.length; i++) {
    const value = values[i];
    let height: number;
    if (typeof value === 'string') {
      if (value.length === 0) {
        height = 0;
      } else {
        height = parseFloat(value);
      }
    } else {
      height = value;
    }

    result.push(height);
  }

  return result as [number, number];
}

function PTPProfileChart(props: any) {
  const { getValues, formState, getFieldState } = useFormContext();
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const { lk, supportsDiverseProfiles, diverseHeights } = useLinkKind();
  const {
    profile,
    path,
    reflectionLine,
    setModified,
    parentFormSubmitHandler,
    modified,
  } = props;

  const [localHeight, remoteHeight] = getHeights(
    getValues([
      'local.radios.0.antennas.0.height',
      'remote.radios.0.antennas.0.height',
    ]),
    [
      getFieldState('local.radios.0.antennas.0.height', formState),
      getFieldState('remote.radios.0.antennas.0.height', formState),
    ],
    prefs.heightUnits
  );

  const childProps = {
    accordionName: 'ptp_profile_panel',
    profileData: profile,
    localHeight,
    remoteHeight,
    path,
    frequencyGHz: path.local.radios[0].frequency.frequency_mhz / 1000,
    loading: false,
    rangeUnits: prefs.rangeUnits,
    heightUnits: prefs.heightUnits,
    localSiteName: path.local.site.name,
    remoteSiteName: path.remote.site.name,
    reflectionLine,
    setModified,
    kind: 'ptp',
    parentFormSubmitHandler,
    modified,
    lk,
    supportsDiverseProfiles,
    diverseHeights,
  };

  return <ProfileEditor {...childProps} />;
}

export default injectIntl(PTPProfileChart);
