import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import LPGrid from 'src/components/controls/lpgrid/LPGrid';
import StoredAccordion from 'src/components/StoredAccordion';
import messages from 'src/messages';

function PMPLinksTableAccordion({ projectName, paths = [], siteName }) {
  const ref = useRef();
  const { formatMessage } = useIntl();
  const pmpLinks = paths.filter((p) => p.kind === 'pmp');
  return (
    <StoredAccordion title="PMP Network Devices">
      <LPGrid
        gridRef={ref}
        autoSize={false}
        rowData={pmpLinks}
        defaultCsvExportParams={{
          fileName: `${projectName}_subscriber_sites_${siteName}_pmp_links.csv`,
        }}
        addStatusColor={true}
        columnDefs={[
          {
            field: 'local_name',
            headerName: formatMessage(messages.pmpNetworkDevice),
            sort: 'asc',
            cellRenderer: 'linkRenderer',
            cellRendererParams: {
              getHref: (data) => `/aps/${data.local_id}`,
            },
          },
          {
            field: 'remote_name',
            headerName: formatMessage(messages.subscriber),
            cellRenderer: 'linkRenderer',
            cellRendererParams: {
              getHref: (data) => `/subscribers/${data.remote_id}`,
            },
          },
        ]}
      ></LPGrid>
    </StoredAccordion>
  );
}

export default PMPLinksTableAccordion;
