/*
 * General configuration settings
 */

// Set in the public/runtime.js file
const apiURL = window?.runtime?.apiURL;

const data = {
  apiURL: apiURL,
  socketServer: `${apiURL}/ws`,
  userGuide: '/doc/',
  releaseNotes: '/doc/changes_to_linkplanner.html',
  support: 'https://support.cambiumnetworks.com/',
};
export default data;
