import { get } from 'lodash';
import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { Form, Label, Popup } from 'semantic-ui-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import type {
  PMPChoices,
  PTPChoices,
  PTPFormField,
  PTPFormPanel,
  PTPShowProps,
} from './utils';
import type { PTPPath } from './ptp-link-type';
import messages from 'src/messages';

type Props = PTPFormField<any> & {
  // End panel props
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  choices: PTPChoices;
  panel: PTPFormPanel<PTPShowProps>;
  path: PTPPath | any;
  // extra props
  disabled: boolean;
  // react intl
  type: 'PTP' | 'PMP';
  refreshChoices?: any;
};

function LimitControl(props: Props) {
  const {
    control,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();
  const {
    label,
    valueGetter,
    maxValueGetter,
    maxValueLabel,
    maxValueFormatter,
    path,
    setModified,
    units,
    min,
    max,
    modified,
    precision,
    componentProps,
    checkboxCompProps,
    choices,
    disabled,
    defaultValue,
    refreshChoices,
    reCalculate,
    afterOnChange,
    afterOnChangeCheckbox,
    type = 'PTP',
  } = props;

  const { formatMessage } = useIntl();
  let checkboxGetter = props.checkboxGetter;
  if (typeof checkboxGetter === 'function') {
    checkboxGetter = checkboxGetter({
      path,
      formGetter: getValues,
      choices,
    });
  }
  const checked = get(path, checkboxGetter);
  let defaultLimit = get(path, valueGetter);
  if (defaultValue) {
    defaultLimit = defaultValue({ formGetter: getValues });
  }
  let cprops: any = componentProps,
    checkboxProps = checkboxCompProps;
  let maxLimit = max;
  let minLimit = min;
  if (typeof max === 'function') {
    maxLimit = max({ ap: path, choices, formGetter: getValues });
  }
  if (typeof min === 'function') {
    minLimit = min({ ap: path, choices, formGetter: getValues });
  }
  if (typeof componentProps === 'function') {
    cprops = componentProps({
      path,
      formGetter: getValues,
      choices,
      formSetter: setValue,
      refreshChoices,
    });
  }
  if (typeof checkboxCompProps === 'function') {
    checkboxProps = checkboxCompProps({
      path,
      formGetter: getValues,
      choices,
      formSetter: setValue,
      refreshChoices,
    });
  }
  // when lk changes from 1+0 -> 2+0, original "max values" will
  // not exist on path, so handle the null case
  let maxValue = get(path, maxValueGetter);
  if (maxValue != null && maxValueFormatter != null) {
    maxValue = maxValueFormatter(maxValue).toFixed(precision);
  }

  const watch = useWatch({
    name: checkboxGetter,
    control,
    defaultValue: checked,
  });
  const warning = props.warning != null ? !!props.warning({ path }) : false;
  const ctrl = (
    <>
      {maxValueGetter && (
        <Popup
          key={maxValueGetter}
          content={formatMessage(messages.saveChanges)}
          basic
          disabled={!(reCalculate && modified)}
          trigger={
            <Form.Field>
              <Form.Group>
                <Form.Input
                  disabled
                  className={reCalculate && modified ? 'end-attr-recalc' : null}
                  label={maxValueLabel}
                  style={{ color: warning ? 'red' : null, width: '7rem' }}
                >
                  {maxValue ?? '---'}
                </Form.Input>
                <Form.Input disabled style={{ paddingTop: '1.7rem' }}>
                  <Label>{units}</Label>
                </Form.Input>
              </Form.Group>
            </Form.Field>
          }
        />
      )}
      <Form.Field
        key={checkboxGetter}
        style={{
          paddingTop: maxValueGetter ? '1.5rem' : '0rem',
        }}
      >
        <Controller
          control={control}
          name={checkboxGetter}
          defaultValue={checked}
          render={({ field: { ref, onChange, value, ...rest } }) => {
            return (
              <Form.Checkbox
                style={{ paddingTop: '0.4rem' }}
                label={label}
                onChange={(e, { checked }) => {
                  setModified(true);
                  onChange(checked);
                  if (props.checkboxRefreshChoices) {
                    refreshChoices({ field: { attrName: props.attrName } });
                  }
                  if (afterOnChangeCheckbox) {
                    afterOnChangeCheckbox(checked, {
                      setValue,
                      getValues,
                    });
                  }
                }}
                checked={value}
                disabled={disabled}
                {...checkboxProps}
                {...rest}
              />
            );
          }}
        />
      </Form.Field>
      {watch && (
        <Form.Field
          key={valueGetter}
          style={{
            paddingTop: maxValueGetter ? '1.3rem' : '0rem',
          }}
        >
          <GenericScaledField
            getter={valueGetter}
            defaultValue={defaultLimit}
            units={units}
            min={minLimit}
            max={maxLimit}
            precision={precision}
            recalc={reCalculate && modified}
            choices={choices}
            {...cprops}
            onChange={(value) => {
              if (afterOnChange) {
                afterOnChange(value, { getValues, setValue }, choices);
              }
              if (props.refreshesChoices) {
                refreshChoices({ field: { attrName: props.attrName } });
              }
            }}
            unitTooltip={cprops != null ? cprops.valueTooltip : null}
            setModified={setModified}
            disabled={disabled}
          />
        </Form.Field>
      )}
    </>
  );
  if (type !== 'PTP') {
    return ctrl;
  } else {
    return <Form.Group>{ctrl}</Form.Group>;
  }
}

export default LimitControl;
