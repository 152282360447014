import React from 'react';
import { Form, Table, Input, Message } from 'semantic-ui-react';

const TransceiversPanel = ({
  transceivers,
  keyPressHandler,
  changeHandler,
}) => {
  return (
    <Form style={{ flexBasis: '33%' }}>
      <div className="fieldset">
        <h1>
          <span>Transceivers</span>
        </h1>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>1 Gbps</Table.HeaderCell>
              <Table.HeaderCell>10 Gbps</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transceivers.list?.map((transceiver, i) => (
              <Table.Row key={i}>
                <Table.Cell
                  className={transceivers.getClassName(
                    transceiver.qty1Gbps,
                    transceiver.qty10Gbps
                  )}
                >
                  {transceiver.transceiverType}
                </Table.Cell>
                <Table.Cell
                  className={transceivers.getClassName(
                    transceiver.qty1Gbps,
                    transceiver.qty10Gbps
                  )}
                >
                  <Input
                    type="number"
                    min={0}
                    max={12}
                    onChange={(event) => changeHandler(event, transceiver)}
                    transparent
                    name={transceiver.getTransceiverName(
                      transceiver.transceiverType,
                      '_1gbps'
                    )}
                    value={transceiver.qty1Gbps}
                    onKeyPress={keyPressHandler}
                  />
                </Table.Cell>
                <Table.Cell
                  className={transceivers.getClassName(
                    transceiver.qty1Gbps,
                    transceiver.qty10Gbps
                  )}
                >
                  <Input
                    type="number"
                    min={0}
                    max={4}
                    onChange={(event) => changeHandler(event, transceiver)}
                    transparent
                    name={transceiver.getTransceiverName(
                      transceiver.transceiverType,
                      '_10gbps'
                    )}
                    value={transceiver.qty10Gbps}
                    onKeyPress={keyPressHandler}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {transceivers.warn ? (
          <Message color="red">{transceivers.warn}</Message>
        ) : (
          <Message color="yellow">{transceivers.info}</Message>
        )}
      </div>
    </Form>
  );
};

export default TransceiversPanel;
