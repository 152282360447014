import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  results: [],
  status: 'idle',
};

const calcTiltSlice = createSlice({
  name: 'calcTilt',
  initialState,
  reducers: {
    setCalcTiltResults: (state, action) => {
      state.results = action.payload
    },
    calcTiltStatusUpdate: (state, action) => {
      state.status = action.payload;
    },
    calcTiltProgressUpdate: (state, action) => {
      state.status = 'running';
      state.message = action.payload;
    },
    calcTiltCancel: (state, action) => {
      state.status = 'cancelled';
      state.message = null;
    },
  },
});

export const {
  setCalcTiltResults,
  calcTiltStatusUpdate,
  calcTiltProgressUpdate,
  calcTiltCancel,
} = calcTiltSlice.actions;
export default calcTiltSlice.reducer;