import React, { useEffect, useState } from 'react';
import AfcResultsPanel from './AFCResultsPanel';
import AFCModePanel from './AFCModePanel';
import {
  Button,
  Form,
  Header,
  Icon,
  Loader,
  Menu,
  Message,
} from 'semantic-ui-react';
import { get } from 'lodash';

import messages from 'src/messages';
import { useIntl } from 'react-intl';
import { getCanvas } from 'src/utils/mapUtils';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { encodeLatLong, pyround } from 'src/utils/useful_functions';
import { parseCoords } from '../SiteModePanel';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';
import Preferences from 'src/pages/user/Preferences';
import { useLocation } from 'react-router-dom';

const DEFAULT_AFC_FORM_VALUES = {
  coordinates: '',
  antennaHeight: 10,
  product: 'PMP 450v 4x4 AP',
  horizontalDistance: 0,
  verticalDistance: 0,
};

const STATE_READY = 'STATE_READY';
const STATE_BUSY = 'STATE_BUSY';
const STATE_DIRTY = 'STATE_DIRTY';
const STATE_ERROR = 'STATE_ERROR';

const getCoordsString = (lat, lng, latLngFormat) => {
  return `${encodeLatLong(lat, latLngFormat, true)}, ${encodeLatLong(
    lng,
    latLngFormat,
    false
  )}`;
};

const getUrl = (
  projectId,
  lat,
  lng,
  antennaHeight,
  product,
  majorAxis,
  minorAxis,
  verticalUncertainity
) => {
  const params = [
    pyround(lat, 5),
    pyround(lng, 5),
    antennaHeight,
    product,
    majorAxis,
    minorAxis,
    verticalUncertainity,
  ];
  const afcParams = params.join('/');

  return projectId
    ? `project/afc/${afcParams}?project_id=${projectId}`
    : `project/afc/${afcParams}`;
};

const getErrorMessage = (requestError, afcResponse, formatMessage) => {
  if (requestError) {
    return formatMessage(messages.afcChartErrorMessage);
  }

  const errorCode = get(
    afcResponse,
    'availableSpectrumInquiryResponses.0.response.responseCode'
  );
  if (errorCode == 103) {
    return formatMessage(messages.afcInvalidLocationError);
  }
  return '';
};

function AFCPanel() {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [prefs, projectId] = useSelector((state) => [
    state.mainFrame.prefs,
    state.mainFrame.projectId,
  ]);
  const afcCoords = useSelector((state) => state.map.afcCoords);
  const [showPrefs, setShowPrefs] = useState(false);

  const formMethods = useForm({ defaultValues: DEFAULT_AFC_FORM_VALUES });

  const isAfcMode = location.pathname === '/afc';

  const [antennaHeight, product, horizontalDistance, verticalDistance] =
    formMethods.watch([
      'antennaHeight',
      'product',
      'horizontalDistance',
      'verticalDistance',
    ]);

  const afcProductConfigs = window.AFC_PRODUCT_CONFIGS;
  if (!afcProductConfigs) {
    return null;
  }

  const productSettings = afcProductConfigs[product];

  if (!productSettings) {
    return null;
  }
  let majorAxis = productSettings.majorAxis;
  let minorAxis = productSettings.minorAxis;
  let verticalUncertainity = productSettings.verticalUncertainity;
  if (productSettings.externalAntenna) {
    majorAxis += horizontalDistance;
    minorAxis += horizontalDistance;
    verticalUncertainity += verticalDistance;
  }

  const queryKey = [projectId, afcCoords.lat, afcCoords.lng];

  const {
    data: afcResponse,
    isFetching,
    error: requestError,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      formMethods.reset(undefined, { keepValues: true });
      return await getWithAuth(
        getUrl(
          projectId,
          afcCoords.lat,
          afcCoords.lng,
          antennaHeight,
          product,
          majorAxis,
          minorAxis,
          verticalUncertainity
        )
      );
    },
    initialData: null,
    retry: false,
    enabled: true,
  });
  const qc = useQueryClient();

  useEffect(() => {
    formMethods.setValue(
      'coordinates',
      getCoordsString(afcCoords.lat, afcCoords.lng, prefs.latLngFormat)
    );
  }, [afcCoords, prefs.latLngFormat]);

  if (showPrefs) {
    return <Preferences onClose={() => setShowPrefs(false)} />;
  }

  const onSubmit = (afcInputs) => {
    const { lat, lng } = parseCoords(afcInputs.coordinates);
    if (afcCoords) {
      qc.removeQueries({ queryKey });
    }
    formMethods.reset(undefined, { keepValues: true });

    const canvas = getCanvas();
    canvas.map.fitBounds([
      [lat, lng],
      [lat, lng],
    ]);
    canvas.map.setZoom(15);
    canvas.setAfcMarkerOnMap(lat, lng);
  };

  const errorMessage = getErrorMessage(
    requestError,
    afcResponse,
    formatMessage
  );

  let resultState = STATE_READY;
  if (errorMessage) {
    resultState = STATE_ERROR;
  } else if (isFetching) {
    resultState = STATE_BUSY;
  } else if (formMethods.formState.isDirty) {
    resultState = STATE_DIRTY;
  }

  return (
    <div id="afc-mode">
      <Menu inverted>
        <Header as="h3" inverted>
          {formatMessage(messages.afcTitle)}
        </Header>
        {isAfcMode ? (
          <Menu.Item position="right">
            <Button
              onClick={() => setShowPrefs(true)}
              icon="options"
              title="Preferences"
              label="Preferences"
              labelPosition="left"
              style={{ cursor: 'pointer' }}
            />
          </Menu.Item>
        ) : (
          <Menu.Item position="right">
            <Icon
              onClick={() => getCanvas().resetState()}
              name="close"
              title={formatMessage(messages.closeDialog)}
              style={{ cursor: 'pointer' }}
            />
          </Menu.Item>
        )}
      </Menu>

      <FormProvider {...formMethods}>
        <Form size="large" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <AFCModePanel></AFCModePanel>
          <ResultPanel
            resultState={resultState}
            errorMessage={errorMessage}
            product={product}
            afcResponse={afcResponse}
          />
        </Form>
      </FormProvider>
    </div>
  );
}

const ResultPanel = ({ resultState, errorMessage, product, afcResponse }) => {
  const minheightStyle = { style: { minHeight: '223px' } };
  switch (resultState) {
    case STATE_DIRTY:
      return (
        <div className="afc-chart-message" {...minheightStyle}>
          <Message visible>Click submit to fetch the results</Message>
        </div>
      );
    case STATE_BUSY:
      return (
        <div className="afc-chart-message" {...minheightStyle}>
          <Loader active inline />
        </div>
      );
    case STATE_ERROR:
      return (
        <div className="afc-chart-message" {...minheightStyle}>
          <Message error visible>
            {errorMessage}
          </Message>
        </div>
      );
    case STATE_READY:
      if (afcResponse) {
        return <AfcResultsPanel product={product} afcResponse={afcResponse} />;
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default AFCPanel;
