import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import PerformanceDetails from 'src/pages/performance/PerformanceDetails';
import { PerformanceDetailsProps } from 'src/pages/ptp/PerformanceDetailsTabs';
import { syncPMPTabs } from '../pmp.reducer';

function SMPerformanceDetailsTabs(props: PerformanceDetailsProps) {
  const { details, localEndName, remoteEndName, disabled, kind, needsRefresh } =
    props;
  const dispatch = useDispatch();
  let tabIndex = useSelector((state: any) => state.pmp.tabIndexes.performance);
  if (details.length === 1) {
    return (
      <PerformanceDetails
        perfChart={details[0].chart_data}
        common={details[0].common}
        local={details[0].local}
        remote={details[0].remote}
        localEndName={localEndName}
        remoteEndName={remoteEndName}
        kind={kind}
        disabled={disabled}
        needsRefresh={needsRefresh}
      />
    );
  } else {
    let panes = details.map((pathDetails, i) => {
      return {
        menuItem: `Component Carrier ${i + 1}`,
        render: () => (
          <Tab.Pane key={i}>
            <PerformanceDetails
              perfChart={pathDetails.chart_data}
              common={pathDetails.common}
              local={pathDetails.local}
              remote={pathDetails.remote}
              localEndName={localEndName}
              remoteEndName={remoteEndName}
              kind={kind}
              disabled={disabled}
              needsRefresh={needsRefresh}
            />
          </Tab.Pane>
        ),
      };
    });
    if (tabIndex >= panes.length) {
      tabIndex = 0;
      dispatch(syncPMPTabs({ key: 'performance', value: 0 }));
    }
    return (
      <Tab
        panes={panes}
        defaultTabIndex={0}
        activeIndex={tabIndex}
        onTabChange={(_, data) => {
          dispatch(
            syncPMPTabs({ key: 'performance', value: data.activeIndex })
          );
        }}
      />
    );
  }
}

export default SMPerformanceDetailsTabs;
