import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Input } from 'semantic-ui-react';
import {
  debounce,
  isValidNumber,
  preventNonNumericalInput,
} from 'src/utils/useful_functions';

const NumberEditor = forwardRef((props: any, ref) => {
  const changeHandler = (event, data) => {
    const { value } = data;
    if (isValidNumber(value, min, max, precision)) {
      setError(false);
      setValue(value);
    } else {
      setError(true);
    }
  };
  const debounceChangeHandler = useCallback(debounce(changeHandler, 500), []);
  const { min, max, step = 1, precision, formatValue, reset = null } = props;

  const getFinalValue = (value) => {
    let initValue = value;
    if (formatValue) {
      initValue = formatValue(value);
    }
    return parseFloat(initValue).toFixed(precision);
  };

  const [value, setValue] = useState(getFinalValue(props.value));
  const [error, setError] = useState(false);
  const refInput = useRef(null);
  const initialValue = useRef(props.value);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        if (error) {
          return initialValue.current;
        }
        // this simple editor doubles any value entered into the input
        const returnValue = value != '' ? value : initialValue.current;
        //return getFinalValue(returnValue);
        return returnValue;
      },
      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      /* isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        return value > 1000;
      }, */
    };
  });

  let style;
  if (reset == null) {
    style = { width: '100%' };
  } else {
    style = { flexGrow: 1 };
  }

  const ctrl = (
    <Input
      type="number"
      style={style}
      ref={refInput}
      error={error}
      onClick={(e, data) => {
        e.target.select();
      }}
      value={value}
      min={min}
      step={step}
      max={max}
      onKeyPress={preventNonNumericalInput}
      onChange={(e, data) => {
        setValue(data.value);
        debounceChangeHandler(e, data);
      }}
    />
  );

  if (reset == null) {
    return ctrl;
  } else {
    let resetTooltip = "Reset to default quantity";
    if (props.data.hasOwnProperty("original_quantity")) {
      resetTooltip += ` (${props.data.original_quantity})`;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {ctrl}
        <Button
          onClick={() => {
            reset({ node: props.node, data: props.data });
            props.api.stopEditing(true);
          }}
          icon="redo"
          title={resetTooltip}
          style={{ margin: 0 }}
        />
      </div>
    );
  }
});

export default NumberEditor;
