import React from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import dropWhile from 'lodash/dropWhile';
import dropRightWhile from 'lodash/dropRightWhile';
import sortBy from 'lodash/sortBy';
import { Controller, useFormContext } from 'react-hook-form';
import LimitControl from './LimitControl';
import {
  type PTPChoices,
  type PTPFormPanel,
  type PTPShowProps,
  fieldIsShared
} from './utils';
import { useLinkKind } from './hooks';
import type { PTPPath } from './ptp-link-type';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { SpatialDiversityField } from 'src/components/controls/rhf/SpatialDiversityField';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Label, Tab, Divider, MenuItem } from 'semantic-ui-react';
import { CableChoicesField } from 'src/components/controls/rhf/CableChoicesField';
import TxFrequencyField from './TxFrequencyField';
import { syncPtpTabs } from './ptp.reducer';
import { store } from 'src/store';
import { openAntennaModal } from '../antennas/antenna.reducer';
import { CUSTOM_ANTENNA_OTHER } from 'src/app.constants';
import { splitBy, joinObjArr } from 'src/utils/useful_functions';

function complexLinkAttrs(field, isComplex, lk, lkKind, pathIndex, shared) {
  const attrs = [
    'getter',
    'valueGetter',
    'maxValueGetter',
    'checkboxGetter',
    'feederCalculateGetter',
    'feederTypeGetter',
    'feederLengthGetter',
    'calculatedCableLossGetter',
  ];

  if (!isComplex || field.lk == null || !field.lk.hasOwnProperty(lkKind) || shared) {
    return attrs.map((attr) => {
      if (!field.hasOwnProperty(attr) && field.componentProps?.hasOwnProperty(attr)) {
        return field.componentProps[attr];
      }
      return field[attr];
    });
  }

  return attrs.map((attr) => {
    if (field.lk[lkKind].hasOwnProperty(attr)) {
      if (
        field.lk[lkKind].hasOwnProperty('separate') &&
        !field.lk[lkKind].separate.includes(lk)
      ) {
        return field[attr];
      }

      if (isFunction(field.lk[lkKind][attr])) {
        return field.lk[lkKind][attr];
      }

      return field.lk[lkKind][attr][pathIndex];
    }
    return field[attr];
  });
}

type Props = {
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  choices: PTPChoices;
  refreshChoices: any;
  panel: PTPFormPanel<PTPShowProps>;
  path: PTPPath;
  tabRow: number;
};

function EndField(props) {
  const {
    modified,
    setModified,
    choices,
    panel,
    path,
    field,
    pathIndex,
    endName,
    shared,
    canShowDivider,
  } = props;

  const { control, setValue, getValues } = useFormContext();
  const { lk, kind: lkKind, isComplex } = useLinkKind();

  let columnStyle;
  if (isComplex) {
    const colPrefix = endName === 'local' ? 'l' : 'r';
    if (field.gridArea != null) {
      columnStyle = { gridArea: `${colPrefix}_${field.gridArea}` };
    } else {
      columnStyle = { gridArea: `${colPrefix}_main` };
    }
  } else {
    columnStyle = { gridColumn: endName === 'local' ? 1 : 2 };
  }

  if (field.label === 'DIVIDER') {
    let showDivider;
    if (field.show != null) {
      showDivider = field.show({ path, choices, formGetter: getValues });
    } else {
      showDivider = true;
    }

    if (canShowDivider !== false && showDivider) {
      return <Divider style={columnStyle} className="ptp-end-grid-divider" />;
    } else {
      return <div style={columnStyle}></div>;
    }
  }

  const { id } = useParams();

  const heightUnits = useSelector(
    (state: any) => state.mainFrame.prefs.heightUnits
  );
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  const Component = field.component;
  const {
    getter: fieldGetter,
    label,
    units,
    precision,
    componentProps,
    show,
    editable,
    info,
    warning,
    warningProps,
    afterOnChange,
    valueGetter: fieldValueGetter,
    checkboxGetter: fieldCheckboxGetter,
  } = field;

  let displayLabel = label;
  if (isFunction(label)) {
    displayLabel = label({
      path,
      choices,
      formGetter: getValues,
      refreshChoices: props.refreshChoices,
      formSetter: setValue,
    });
  }

  const display =
    // note: choices is the full object here including local
    // and remote data
    show != null ? show({ path, choices, formGetter: getValues, pathIndex }) : true;

  if (!display) {
    return <div style={columnStyle}></div>;
  }

  // TODO there is probably a better way to do this
  const [
    preGetter,
    valueGetter,
    maxValueGetter,
    checkboxGetter,
    feederCalculateGetter,
    feederTypeGetter,
    feederLengthGetter,
    calculatedCableLossGetter,
  ] = complexLinkAttrs(field, isComplex, lk, lkKind, pathIndex, shared);

  let getter;
  if (isFunction(preGetter)) {
    getter = preGetter({ lk, pathIndex, formGetter: getValues });
  } else {
    getter = preGetter;
  }

  let cprops: Record<string, any>;
  if (isFunction(componentProps)) {
    cprops = componentProps({
      path,
      choices,
      formGetter: getValues,
      refreshChoices: props.refreshChoices,
      formSetter: setValue,
    });
  } else {
    cprops = componentProps;
  }

  let message: string = null;
  let messageColor: 'yellow' | 'red' = 'red';
  if (warning != null) {
    const warn = warning({ path, choices, formGetter: getValues });
    if (typeof warn === 'string') {
      message = warn;
    }
  }

  // try to set an info message if warning hasn't been set
  if (message == null && info != null) {
    message = info({ path, choices, formGetter: getValues });
    messageColor = 'yellow';
  }

  let extraWarningProps = {};
  if (warningProps != null) {
    extraWarningProps = warningProps({ modified });
  }

  const warnComponent =
    message != null && message ? (
      <Label
        color={messageColor}
        basic
        pointing
        style={{ marginTop: 0, marginBottom: '1rem', width: '100%' }}
        {...extraWarningProps}
      >
        {message}
      </Label>
    ) : null;

  // TODO improve the way we pass props to these subcomponents
  let displayComponent: any;
  if (
    Component === LimitControl ||
    Component === SpatialDiversityField ||
    Component === CableChoicesField
  ) {
    displayComponent = (
      <Component
        key={getter}
        {...props}
        {...field}
        {...cprops}
        getter={getter}
        valueGetter={valueGetter}
        checkboxGetter={checkboxGetter}
        maxValueGetter={maxValueGetter}
        feederCalculateGetter={feederCalculateGetter}
        feederTypeGetter={feederTypeGetter}
        feederLengthGetter={feederLengthGetter}
        calculatedCableLossGetter={calculatedCableLossGetter}
        pathIndex={pathIndex}
        units={field.usePrefs != null ? prefs[field.usePrefs] : field.units}
        data-testid="test1"
        disabled={!permissionWrite}
      />
    );
  } else if (Component === TxFrequencyField) {
    displayComponent = (
      <Component
        key={getter}
        {...cprops}
        getter={getter}
        setModified={setModified}
        pathIndex={pathIndex}
      />
    );
  } else if (Component === GenericScaledField) {
    displayComponent = (
      <Component
        key={getter}
        {...field}
        {...cprops}
        getter={getter}
        units={
          field.useHeightPrefs
            ? heightUnits
            : field.usePrefs != null
              ? prefs[field.usePrefs]
              : field.units
        }
        data-testid="test2"
        defaultValue={get(path, getter)}
        watch={id}
        setModified={setModified}
        disabled={!permissionWrite || (editable != null ? !editable : false)}
        recalc={field.reCalculate && modified}
      />
    );
  } else if (display) {
    displayComponent = (
      <Controller
        key={field.getter}
        control={control}
        name={getter}
        defaultValue={get(path, getter)}
        render={({ field: { ref, onChange, ...rest } }) => {
          return (
            <Component
              label={displayLabel}
              attrName={displayLabel}
              units={units}
              precision={precision}
              pathIndex={pathIndex}
              {...cprops}
              {...rest}
              refresh={() => props.refreshChoices({ field })}
              setModified={setModified}
              pathIndex={pathIndex}
              onChange={(_, data) => {
                if (data.value === CUSTOM_ANTENNA_OTHER.id) {
                  store.dispatch(openAntennaModal({ endName: endName }));
                } else {
                  setModified(true);
                  const value =
                    data.checked != null ? data.checked : data.value;
                  onChange(value);
                  if (afterOnChange != null) {
                    afterOnChange(value, {
                      getValues,
                      setValue,
                    });
                  }
                  if (field.refreshesChoices) {
                    props.refreshChoices({ field });
                  }
                }
              }}
            />
          );
        }}
      />
    );
  } else {
    displayComponent = null;
  }

  return (
    <div
      className={displayComponent != null ? 'has-content' : null}
      style={columnStyle}
    >
      {displayComponent}
      {warnComponent}
    </div>
  );
}

function orderFields(path, choices, getValues, fields) {
  return (pathIndex) => {
    const parts = splitBy(fields, (field) => field.label === 'DIVIDER');
    const sortedParts = parts.map((part) =>
      sortBy(part, (field) => {
        const { show } = field;
        if (show != null) {
          return !show({ path, choices, formGetter: getValues, pathIndex });
        } else {
          return false;
        }
      })
    );
    return joinObjArr(
      sortedParts,
      // keep the original dividers in case they have any extra
      // properties we need to retain
      fields.filter((field) => field.label === 'DIVIDER'),
      { label: 'DIVIDER' },
    );
  }
}

function PTPEndPanel(props: Props) {
  const { lk, kind: lkKind, pathNames, endPanelNames, isComplex } = useLinkKind();

  const dispatch = useDispatch();
  const tabIndex = useSelector((state) => state.ptp.tabIndexes.ends);
  const { getValues } = useFormContext();

  const orderedFields = orderFields(
    props.path,
    props.choices,
    getValues,
    props.panel.fields,
  );

  const colPrefix = props.endName === 'local' ? 'l' : 'r';

  if (!isComplex) {
    return (
      <>
        {orderedFields(0).map((field) => (
          <EndField
            key={field.attrName}
            field={field}
            pathIndex={0}
            shared={false}
            {...props}
          />
        ))}
      </>
    );
  }

  const sharedFields = (pathIndex) => {
    return dropRightWhile(
      orderedFields(pathIndex).filter(
        (field) => fieldIsShared(lk, field, props.choices, props.endName)
      ),
      (field) => field.label === 'DIVIDER'
    );
  }
  const pathFields = (pathIndex) => {
    return dropWhile(
      orderedFields(pathIndex).filter(
        (field) => !fieldIsShared(lk, field, props.choices, props.endName)
      ),
      (field) => field.label === 'DIVIDER'
    );
  }

  const panes = endPanelNames[props.endName].map(([name, i]) => {
    let anyShown = false;
    return {
      menuItem: (
        <MenuItem key={name}>{name}</MenuItem>
      ),
      pane: (
        <Tab.Pane key={`tab_${name}`}>
          {pathFields(i).map((field, j) => {
            const renderedComponent = (
              <EndField
                key={`${field.label}_${j}`}
                field={field}
                pathIndex={i}
                shared={false}
                canShowDivider={anyShown}
                {...props}
              />
            );

            // hack so that if we haven't rendered any controls yet,
            // we won't render a divider with nothing above it
            anyShown = anyShown || (field.show != null ? (
              field.show({
                path: props.path,
                choices: props.choices,
                formGetter: getValues,
                pathIndex: i,
              })
            ) : true);

            return renderedComponent;
          })}
        </Tab.Pane>
      ),
    };
  });

  let tabIdx;
  if (tabIndex >= pathNames.length) {
    tabIdx = 0;
  } else {
    tabIdx = tabIndex;
  }

  const tabStyle = {
    gridArea: `${colPrefix}_tabs`,
  };

  const fields = sharedFields(0);
  const renderSharedField = (field) => (
    <EndField key={field.getter} field={field} shared={true} {...props} />
  );

  return (
    <>
      <div className='shared-area' style={{ gridArea: `${colPrefix}_antenna` }}>
        {fields.filter((field) => field.gridArea === 'antenna').map(renderSharedField)}
      </div>

      <div className='shared-area' style={{ gridArea: `${colPrefix}_main` }}>
        {fields.filter((field) => field.gridArea == null).map(renderSharedField)}
      </div>

      <Tab
        style={{ ...tabStyle, paddingTop: '1em' }}
        panes={panes}
        defaultTabIndex={0}
        renderActiveOnly={false}
        activeIndex={tabIdx}
        onTabChange={(_, data) => {
          dispatch(syncPtpTabs({ key: 'ends', value: data.activeIndex }));
        }}
      />
    </>
  );
}

export default PTPEndPanel;
