import React, { useEffect, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Message, Modal, ModalContent } from 'semantic-ui-react';
import CSVFileSelection from './CSVFileSelection';
import CSVSiteValidation from './CSVSiteValidation';
import SiteCSVWizard from './SiteCSVWizard';
import additionalMessages from '../../messages';
import KMLSiteData from './KMLSiteData';
import SiteFileData from './SiteFileData';

type CSVFileImportModalPropsType = {
  handleClose: Function;
  showModal: boolean;
  groupKind: string;
} & WrappedComponentProps;

function CSVFileImportModal({
  showModal,
  handleClose,
  groupKind,
  intl,
}: CSVFileImportModalPropsType) {
  const { formatMessage } = intl;
  const [showImportModal, setShowImportModal] = useState(showModal);
  const [activeStep, setActiveStep] = useState(1);
  const [fileData, setFileData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const nextStepHandler = (data: any, fileName) => {
    setActiveStep((prevState) => (prevState += 1));
    if (data) {
      setFileData(data);
    }
    if (fileName) {
      setFileName(fileName);
    }
  };

  const prevStepHandler = () => {
    setActiveStep((prevState) => (prevState -= 1));
  };

  const closeImportDialogue = () => {
    setFileData([]);
    setFileName('');
    setActiveStep(1);
    handleClose();
    setShowImportModal(false);
  };

  useEffect(() => {
    setShowImportModal(showModal);
  }, [showModal]);

  const modalTitle =
    groupKind === 'network_sites'
      ? formatMessage(additionalMessages.importNetworkSites)
      : formatMessage(additionalMessages.importSubscriberSites);

  return (
    <Modal size="large" open={showImportModal}>
      <Modal.Header>
        {modalTitle}
        <Button
          circular
          icon="close"
          title="Close"
          onClick={() => closeImportDialogue()}
          floated="right"
        />
      </Modal.Header>
      <ModalContent>
        <SiteCSVWizard step={activeStep} groupKind={groupKind} />
      </ModalContent>
      <Modal.Actions>
        <CSVFileSelection
          customClassName={activeStep === 1 ? '' : 'no-display'}
          handleNextStep={nextStepHandler}
          groupKind={groupKind}
        ></CSVFileSelection>
        {activeStep === 2 && (
          <SiteFileData
            fileName={fileName}
            data={fileData}
            groupKind={groupKind}
            handlePrevStep={prevStepHandler}
            closeFunc={() => closeImportDialogue()}
          ></SiteFileData>
        )}
        <Button onClick={() => closeImportDialogue()}>
          {formatMessage(additionalMessages.cancel)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default injectIntl(CSVFileImportModal);
