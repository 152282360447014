import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  results: [],
  totalSites: null,
  complete: false,
  calculating: false,
  workerError: false,
  hideCreateButton: false,
  created: null,
  ableToConnect: 0,
  outOfRange: 0,
  notMeetingRequirements: 0,
};

const bestServerSlice = createSlice({
  name: 'best_server',
  initialState,
  reducers: {
    addResults: (state, action) => {
      for (const data of action.payload.objects) {
        if (data.status === 'NMR') {
          state.notMeetingRequirements += 1;
        } else if (data.status === 'OOR') {
          state.outOfRange += 1;
        } else {
          state.ableToConnect += 1;
        }
        state.results.push(data);
      }
    },
    addOorResults: (state, action) => {
      const { objects } = action.payload;
      state.outOfRange = objects.length;
      state.results = [...state.results, ...objects];
    },
    setResults: (state, action) => {
      state.ableToConnect = 0;
      state.outOfRange = 0;
      state.notMeetingRequirements = 0;

      const results = action.payload;
      for (const result of results) {
        if (result.status === 'NMR') {
          state.notMeetingRequirements += 1;
        } else if (result.status === 'OOR') {
          state.outOfRange += 1;
        } else if (!state.hideCreateButton) {
          state.ableToConnect += 1;
        }
      }

      state.results = results;
      state.totalSites = null;

      if (results.length === 0) {
        state.complete = false;
      }
    },
    settingsChanged: (state) => {
      state.complete = false;
    },
    selectNDForResult: (state, action) => {
      const { row, value } = action.payload;
      state.results[row].status = value;
    },
    startCalculation: (state) => {
      state.calculating = true;
    },
    stopCalculation: (state) => {
      state.calculating = false;
    },
    startCreatingLinks: (state, action) => {
      state.created = 0;
      state.hideCreateButton = action.payload;
      state.totalSites = null;
    },
    incLinksCreatedCount: (state, action) => {
      const n = action.payload;
      state.created += n;
      state.ableToConnect -= n;
    },
    calculationComplete: (state, action) => {
      state.calculating = false;
      if (action.payload != null) {
        state.complete = action.payload.complete ?? true;
      } else {
        state.complete = true;
      }
      state.totalSites = null;
    },
    creatingComplete: (state) => {
      state.created = null;
      state.ableToConnect = 0;
    },
    resetResults: (state) => {
      Object.assign(state, initialState);
    },
    setTotalSites: (state, action) => {
      state.totalSites = action.payload;
    },
    setWorkerError: (state, action) => {
      if (action.payload) {
        state.calculating = false;
        state.complete = true;
        state.totalSites = null;
      }
      state.workerError = action.payload;
    },
  },
});

export const {
  addResults,
  addOorResults,
  setResults,
  settingsChanged,
  selectNDForResult,
  startCalculation,
  stopCalculation,
  startCreatingLinks,
  incLinksCreatedCount,
  calculationComplete,
  creatingComplete,
  resetResults,
  setTotalSites,
  setWorkerError,
} = bestServerSlice.actions;
export default bestServerSlice.reducer;
