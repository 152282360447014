import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { get } from 'lodash';
import { Dimmer, Form, Loader, Message } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import CreateAccessPointModalFooter from './CreateAccessPointModalFooter';
import { store } from '../../../store';
import { addEquipmentConfig } from '../../equipment/equipment.reducer';
import { sortObjectsByKey } from 'src/utils/useful_functions';

function TemplateType({ customClassName, handleNextStep, closeHandler, projectId }) {
  const [type, setType] = useState('new');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { equipmentConfigs } = useSelector(
    (state: RootStateOrAny) => state.equipment
  );
  const { accessPoints } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    get(equipmentConfigs, '0.id', null)
  );

  let templateOptions = equipmentConfigs.map((config) => {
    const { id, name } = config;
    return {
      key: id,
      text: name,
      value: id,
    };
  });

  templateOptions = sortObjectsByKey(templateOptions, 'text');
  const createNewTemplate = () => {
    setLoading(true);
    postWithAuth(`project/${projectId}/access_point/template`, {
      product: 'PMP 450i',
      band: '5.8 GHz',
      regulation: 'Other',
    })
      .then((response) => {
        const templateName = `5.8 GHz / PMP 450i / Other`;
        store.dispatch(
          addEquipmentConfig({ id: response.id, name: templateName })
        );
        setLoading(false);
        setSelectedTemplate(response.id);
        handleNextStep({
          templateId: response.id,
          templateName: templateName,
        });
        console.log('create template res', response);
      })
      .catch((err) => {
        setError(get(err, 'detail', 'Error in creating the template'));
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }
  return (
    <div
      className={customClassName}
      style={{ marginLeft: '25vw', height: '22vh' }}
    >
      {error && (
        <Message negative attached>
          <p>{error}</p>
        </Message>
      )}
      <Form>
        <Form.Radio
          label="New equipment configuration"
          value="new"
          checked={type === 'new'}
          onChange={() => {
            setType('new');
          }}
        ></Form.Radio>
        {equipmentConfigs.length > 0 && (
          <Form.Radio
            label="Use previous configuration"
            value="prevConfig"
            checked={type === 'prevConfig'}
            disabled={!equipmentConfigs.length}
            title={
              !equipmentConfigs.length
                ? 'Please create atleast one template to enable'
                : ''
            }
            onChange={() => {
              setType('prevConfig');
            }}
          ></Form.Radio>
        )}
        {equipmentConfigs.length > 0 && type === 'prevConfig' && (
          <Form.Select
            width={5}
            defaultValue={selectedTemplate}
            label="Select template"
            onChange={(e, { value }) => {
              setSelectedTemplate(value);
            }}
            options={templateOptions}
          ></Form.Select>
        )}
        <Form.Radio
          label="Configure from existing end"
          value="prevEndConfig"
          checked={type === 'prevEndConfig'}
          disabled={!accessPoints.features.length}
          onChange={() => {
            setType('prevEndConfig');
          }}
        ></Form.Radio>
        <CreateAccessPointModalFooter
          handleNextStep={() => {
            if (type === 'new') {
              createNewTemplate();
            } else if (type === 'prevConfig') {
              handleNextStep({ templateId: selectedTemplate });
            } else {
              handleNextStep({ templateId: null });
            }
          }}
          closeHandler={closeHandler}
        ></CreateAccessPointModalFooter>
      </Form>
    </div>
  );
}

export default TemplateType;
