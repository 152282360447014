import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Message } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { store } from '../../store';
import additionalMessages from '../../messages';
import { postFileWithAuth } from '../../api';
import { uiSet, loadProject } from '../mainframe/mainframe.reducer';
import { clearSocketMessages } from '../../reducers/socket_reducer';
import { useSelector } from 'react-redux';

const DEFAULT_IMPORT_STATE = {
  busy: false,
  importFile: null,
  message: null,
};

const ImportProjectDialog = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const importRef = React.createRef();

  const [importState, setImportState] = useState(DEFAULT_IMPORT_STATE);
  const { busy } = importState;
  const show = useSelector((state) => state.mainFrame.showLPImportDialog);
  const [projectId, status, action, message] = useSelector((state) => [
    state.sockets.project_id,
    state.sockets.status,
    state.sockets.action,
    state.sockets.message,
  ]);

  // Handle the socket messages that appear when the worker
  // imports the project
  useEffect(() => {
    if (status === 'success' && action === 'import') {
      store.dispatch(clearSocketMessages());
      store.dispatch(uiSet({ showLPImportDialog: false }));
      store.dispatch(
        loadProject({
          projectId: projectId,
        })
      );
      setImportState(DEFAULT_IMPORT_STATE);
      navigate('/');
    } else if (status === 'busy') {
      setImportState({
        ...importState,
        busy: true,
        message: message,
      });
    } else if (status === 'failed' && action === 'import') {
      const displayMessage =
        message ?? formatMessage(additionalMessages.fileError);
      store.dispatch(clearSocketMessages());
      setImportState({
        ...importState,
        busy: false,
        message: displayMessage,
      });
    }
  }, [projectId, status, action, message]);

  const doImport = () => {
    if (importState.importFile) {
      setImportState({ ...importState, busy: true });
      postFileWithAuth('projects/linkplanner', importState.importFile)
        .then((res) => {
          // TODO: Check for error messages
          // Leave busy running until the task is complete
        })
        .catch((e) => {
          if (e === 'Invalid file')
            setImportState({
              ...importState,
              busy: false,
              message: formatMessage(additionalMessages.invalidFileError),
            });
          else
            setImportState({
              ...importState,
              busy: false,
              message: formatMessage(additionalMessages.fileError),
            });
        });
    }
  };

  const onClose = () => {
    setImportState(DEFAULT_IMPORT_STATE);
    store.dispatch(clearSocketMessages());
    store.dispatch(uiSet({ showLPImportDialog: false }));
  };

  const onOpen = () => {
    setImportState(DEFAULT_IMPORT_STATE);
    store.dispatch(clearSocketMessages());
  };

  const onImportFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const body = new FormData();
    body.append('file', file);
    body.append('filename', file.name);
    setImportState({ ...importState, importFile: body, message: null });
  };

  const getWarningMessage = (formatMessage) => {
    const msg = importState.message;
    if (msg != null) {
      return msg;
    } else {
      return formatMessage(additionalMessages.fileError);
    }
  };

  const okButton = busy ? (
    <Button icon="sync" color="blue" loading />
  ) : (
    <Button
      onClick={() => doImport()}
      color="blue"
      disabled={!importState.importFile || busy || !!message}
    >
      {formatMessage(additionalMessages.ok)}
    </Button>
  );

  return (
    <Modal
      open={show}
      onClose={() => onClose()}
      onOpen={() => onOpen()}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {formatMessage(additionalMessages.importLINKPlannerProject)}
        <Button
          circular
          icon="close"
          title="Close"
          floated="right"
          onClick={() => onClose()}
        />
      </Modal.Header>
      <Modal.Content>
        <Button
          content={formatMessage(additionalMessages.selectLPFile)}
          labelPosition="left"
          icon={{ className: 'linkplanner-icon-coloured' }}
          onClick={() => importRef.current.click()}
          disabled={busy}
        />
        <input
          ref={importRef}
          type="file"
          accept=".ptpprj,.lpp"
          hidden
          onChange={onImportFileChange}
        />
        {importState.importFile && importState.importFile.get('filename')}

        {importState.message ? (
          <Message
            error={!busy}
            onDismiss={
              busy
                ? null
                : () => {
                    setImportState({ ...importState, message: null });
                  }
            }
            header={
              busy
                ? formatMessage(additionalMessages.importProgress)
                : formatMessage(additionalMessages.importError)
            }
            content={
              busy ? importState.message : getWarningMessage(formatMessage)
            }
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        {busy ? null : (
          <Button onClick={() => onClose()}>
            {formatMessage(additionalMessages.cancel)}
          </Button>
        )}
        {okButton}
      </Modal.Actions>
    </Modal>
  );
};

export default ImportProjectDialog;
