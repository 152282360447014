import React from 'react';
import { Menu, Popup, Segment, Tab } from 'semantic-ui-react';
import BaseSMPerformanceSummary from './BaseSMPerformanceSummary';
import { WarningTab } from 'src/pages/ptp/PTPPerformanceSummary';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import messages from 'src/messages';
import { useDispatch, useSelector } from 'react-redux';
import { syncPMPTabs } from '../pmp.reducer';

const warningsExists = (warnings): boolean => {
  return warnings && Object.values(warnings).some((warning) => warning);
};

function SMPerformanceSummary({
  modified,
  setModified,
  sm,
  local,
  remote,
  results,
  choices,
  warnings,
}) {
  const { summary, warnings: summaryWarnings } = results;
  let perfSummary;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  let tabIndex = useSelector((state: any) => state.pmp.tabIndexes.performance);

  const { getValues } = useFormContext();
  const componentCarrierQty = getValues('radio_qty');

  if (componentCarrierQty == 1) {
    perfSummary = (
      <BaseSMPerformanceSummary
        setModified={setModified}
        sm={sm}
        local={local}
        remote={remote}
        summary={results['summary'][0]}
        warnings={results['warnings'][0]}
        choices={choices}
        pathIndex={0}
      ></BaseSMPerformanceSummary>
    );
  } else {
    let panes = summary.map((ccSummary, i) => {
      const anyWarning = warningsExists(summaryWarnings[i]);
      return {
        menuItem: (
          <Menu.Item>
            {anyWarning ? (
              <WarningTab>{`Component Carrier ${i + 1}`}</WarningTab>
            ) : (
              `Component Carrier ${i + 1}`
            )}
          </Menu.Item>
        ),
        render() {
          return (
            <Tab.Pane key={i}>
              <BaseSMPerformanceSummary
                setModified={setModified}
                sm={sm}
                local={local}
                remote={remote}
                summary={ccSummary}
                warnings={summaryWarnings[i] ?? {}}
                choices={choices}
                pathIndex={i}
              />
            </Tab.Pane>
          );
        },
      };
    });
    if (panes.length < componentCarrierQty) {
      panes.push(
        ...[...Array(componentCarrierQty - panes.length).keys()].map((i) => {
          return {
            menuItem: (
              <Menu.Item disabled>
                <Popup
                  trigger={<p>{`Component Carrier 2`}</p>}
                  content={`${formatMessage(
                    messages.save
                  )} the current settings to view this tab`}
                />
              </Menu.Item>
            ),
          };
        })
      );
    }
    if (tabIndex >= panes.length) {
      tabIndex = 0;
      dispatch(syncPMPTabs({ key: 'performance', value: 0 }));
    }
    perfSummary = (
      <Tab
        panes={panes}
        defaultTabIndex={0}
        activeIndex={tabIndex}
        onTabChange={(_, data) => {
          dispatch(
            syncPMPTabs({ key: 'performance', value: data.activeIndex })
          );
        }}
      />
    );
  }

  let worstEarthWarning: string = null;
  if (warnings != null && warnings.worst_earth?.length > 0) {
    worstEarthWarning = warnings.worst_earth;
  }

  return (
    <>
      {modified && (
        <Segment inverted className="update-required">
          Apply your changes to update the performance summary
        </Segment>
      )}
      {perfSummary}
      {worstEarthWarning != null ? (
        <Segment inverted color="red" tertiary>
          {worstEarthWarning}
        </Segment>
      ) : null}{' '}
    </>
  );
}

export default SMPerformanceSummary;
