import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import '../nd/nd.css';
import EquipmentTemplateForm from './EquipmentTemplateForm';
import useNDData from '../nd/useNDData';

type props = {
  templateId: string;
  handlePrevStep: Function;
  handleNextStep: Function;
  closeHandler: Function;
};

function EquipmentTemplatePanel({
  templateId,
  handlePrevStep,
  handleNextStep,
  closeHandler,
}: props) {
  const { formatMessage } = useIntl();
  const projectId = useSelector((state) => state.mainFrame.projectId);
  const { apOrTemplate, equipmentChoices } = useNDData(
    projectId,
    true,
    templateId
  );
  if (apOrTemplate != null && equipmentChoices != null) {
    return (
      <EquipmentTemplateForm
        projectId={projectId}
        choices={equipmentChoices}
        template={apOrTemplate}
        formatMessage={formatMessage}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        closeHandler={closeHandler}
      ></EquipmentTemplateForm>
    );
  }
  return <Loader active />;
}

export default EquipmentTemplatePanel;
