import { GenericScaledField } from '../../../../components/controls/rhf/GenericScaledField';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { PMPFormPanel } from '../../nd/utils';
import {
  band,
  cnReachPowerConfig,
  cnReachUserPowerConfig,
  product,
  regulation,
  smRangeBaseConfig,
} from '../../config-utils';
import { syncFormState } from '../utils';
import { antenna } from './PMP450ViewConfig';
import NDPowerPanel from '../../nd/NDPowerPanel';
import { getSMEIRPConfig, getSMInterferenceFields } from './config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  fields: [
    band,
    product,
    regulation,
    {
      attrName: 'io_connectivity',
      getter: 'radios.0.equipment.io_connectivity',
    },
    {
      attrName: 'sync_input',
      getter: 'radios.0.equipment.sync_input',
    },
  ],
};

const productPanel: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'sm_product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'band_setting',
      getter: 'radios.0.equipment.band_setting',
    },
    {
      attrName: 'tr_spacing',
      getter: 'radios.0.equipment.tr_spacing',
    },
    {
      attrName: 'bandwidth',
      getter: 'radios.0.equipment.bandwidth',
    },
    {
      attrName: 'modulation_type',
      getter: 'radios.0.equipment.modulation_type',
    },
    {
      ...smRangeBaseConfig,
      editable: false,
    },
    {
      attrName: 'sm_modulation_mode',
      getter: 'radios.0.equipment.sm_modulation_mode',
    },
    {
      attrName: 'hop_pattern',
      getter: 'radios.0.equipment.hop_pattern',
    },
    {
      attrName: 'mms_hop_offset',
      getter: 'radios.0.equipment.mms_hop_offset',
    },
    {
      attrName: 'polarization',
      getter: 'radios.0.equipment.polarization',
    },
    {
      label: 'SM Max Payload Bytes',
      attrName: 'sm_max_payload_bytes',
      getter: 'radios.0.equipment.sm_max_payload_bytes',
      component: GenericScaledField,
      editable: false,
      units: 'Bytes',
    },
    {
      label: 'Hi',
      attrName: 'hi',
      getter: 'radios.0.equipment.hi',
    },
  ],
};

export const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  component: NDPowerPanel,
  fields: [
    getSMEIRPConfig(),
    cnReachPowerConfig,
    cnReachUserPowerConfig,
    getSMInterferenceFields(),
  ],
};

export const cnReachViewConfig = {
  syncFormState,
  equipment,
  product: productPanel,
  antenna,
  power,
};
