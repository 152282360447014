import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWithAuth } from '../../api';

const initialState = {
  selectedItems: [],
  listErrorMessage: null,
  graph: { aps: null, paths: null },
};

export const fetchSiteGraph = createAsyncThunk(
  'sites/fetchSiteGraph',
  async ({ projectId, siteId }) => {
    return await getWithAuth(`project/${projectId}/site/${siteId}/graph`);
  }
);

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setSiteErrorMessage: (state, action) => {
      state.listErrorMessage = action.payload;
    },
  },
  extraReducers: {
    [fetchSiteGraph.fulfilled]: (state, action) => {
      Object.assign(state.graph, action.payload);
    },
  },
});

export const { setSelectedItems, setSiteErrorMessage } = sitesSlice.actions;
export default sitesSlice.reducer;
