import { useState, useEffect } from 'react';

export default function useDebounce(value, delay) {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    // Whenever the useEffect is recalled clearTimeout
    // clears the previous setTimeout.. so when user stops
    // typing useEffect doesnt call the latest value
    // will get updated in state
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debounceValue;
}
