import React from 'react';
import { Loader } from 'semantic-ui-react';
import PerformanceChart from 'src/components/PerformanceChart/PerformanceChart';

type PerformanceChartsProps = {
  kind: string;
  perfChart: any; // TODO: Update this
  localEndName?: string;
  remoteEndName?: string;
};

const PerformanceCharts = ({
  kind,
  perfChart,
  localEndName,
  remoteEndName,
}: PerformanceChartsProps) => {
  if (!perfChart) return <Loader active inline />;

  return (
    <div className="performance-charts">
      <div className="chart-item">
        <PerformanceChart
          apiData={perfChart.local}
          title={localEndName}
          type="local"
          kind={kind}
        />
      </div>
      <div className="chart-item">
        <PerformanceChart
          apiData={perfChart.remote}
          title={remoteEndName}
          type="remote"
          kind={kind}
        />
      </div>
    </div>
  );
};

export default PerformanceCharts;
