import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWithAuth } from '../../api';

const initialState = {
  selectedItems: [],
  listErrorMessage: null,
  modified: {},
  possibleRecalc: {},
  graph: { aps: null, paths: null },
};

export const fetchMeshGraph = createAsyncThunk(
  'mesh/fetchMeshGraph',
  async ({ projectId, meshId }) => {
    return await getWithAuth(`project/${projectId}/mesh_link/${meshId}/graph`);
  }
);

const MeshSlice = createSlice({
  name: 'mesh',
  initialState,
  reducers: {
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setSiteErrorMessage: (state, action) => {
      state.listErrorMessage = action.payload;
    },
    setModified: (state, action) => {
      const { id, value } = action.payload;
      state.modified[id] = value;
    },
    setPossibleRecalc: (state, action) => {
      const { id, value } = action.payload;
      state.possibleRecalc[id] = value;
    },
  },
  extraReducers: {
    [fetchMeshGraph.fulfilled]: (state, action) => {
      Object.assign(state.graph, action.payload);
    },
  },
});

export const {
  setSelectedItems,
  setSiteErrorMessage,
  setModified,
  setPossibleRecalc,
} = MeshSlice.actions;
export default MeshSlice.reducer;
