import React from 'react';
import { Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';

function FormCheckbox(props) {
  const { disabled, ...rest } = props;
  const { permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  return (
    <Form.Checkbox
      disabled={disabled && !permissionWrite}
      {...rest}
    ></Form.Checkbox>
  );
}

export default FormCheckbox;
