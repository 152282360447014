import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Form, Label } from 'semantic-ui-react';

function FormSelect(props) {
  const { permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const {
    attrName,
    choices,
    error,
    onChange,
    values,
    panels,
    className,
    value,
    label,
    disabled,
    warning,
    modified,
    width,
    placeholder,
  } = props;

  if (choices && choices.length === 1) {
    return (
      <Form.Field className={className}>
        <label>{label}</label>
        <div>{choices[0]['text']}</div>
        {warning != null && warning !== '' && (
          <Label pointing prompt>
            {warning}
          </Label>
        )}
      </Form.Field>
    );
  } else {
    return (
      <Form.Field>
        <Form.Select
          className={className}
          label={label}
          name={attrName}
          options={choices}
          error={error}
          disabled={disabled || !permissionWrite}
          onChange={(e, data) => {
            if (data) {
              data['values'] = values;
              data['panels'] = panels;
            }
            onChange(e, data);
          }}
          //{...rest}
          value={value}
          width={width}
          placeholder={placeholder}
        ></Form.Select>
        {warning != null && warning !== '' && (
          <Label pointing prompt={!modified} basic={modified} color="blue">
            {warning}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default FormSelect;
