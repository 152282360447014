import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NDForm from './NDForm';
import { Loader, Segment } from 'semantic-ui-react';
import useNDData from './useNDData';
import './nd.css';
import { RootStateOrAny } from 'src/store';

function NDPanel({ intl }) {
  const { formatMessage } = intl;
  const { id } = useParams();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { apOrTemplate, equipmentChoices, ndIsError } = useNDData(projectId, false, id);

  if (ndIsError) {
    return (
      <Segment inverted color="red">
        Unable to load the network device
      </Segment>
    );
  }

  if (apOrTemplate != null && equipmentChoices != null) {
    return (
      <NDForm
        id={id}
        projectId={projectId}
        choices={equipmentChoices}
        ap={apOrTemplate}
        formatMessage={formatMessage}
      ></NDForm>
    );
  }
  return <Loader active />;
}

export default injectIntl(NDPanel);
