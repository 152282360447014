import { get } from 'lodash';
import React from 'react';
import StoredAccordion from 'src/components/StoredAccordion';
import WarningsPanel from 'src/pages/equipment/common/WarningsPanel';

function warningContent(obj) {
  const warnings = [
    get(obj, 'radios[0].warnings.datasets.0'),
    get(obj, 'radios[1].warnings.datasets.0'),
    ...Object.values(get(obj, 'warnings', {}) ?? {}),
  ].filter((w) => w);
  return warnings;
}

function WarningAccordion({ obj }) {
  const warnings = warningContent(obj);

  if (warnings.length === 0) {
    return <></>;
  }

  return (
    <StoredAccordion
      name="ptp_warnings_panel"
      title="Warnings"
      className="custom-error"
      headerClass="custom-error"
    >
      <WarningsPanel warning={warnings} />
    </StoredAccordion>
  );
}

export default WarningAccordion;
