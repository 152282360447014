import * as React from 'react';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import { TOAST_AUTOCLOSE } from './TerragraphPanel';

const STARTED = 'started';
const FAILED = 'failed';
const COMPLETED = 'completed';
const ERROR = 'error';
const SUCCESS = 'success';
const READY = 'ready';
const CREATED = 'created';
const UPDATED = 'updated';

export const TP_STATUS_MESSAGES = {
  created: 'Analysis configuration created. Click Run to start processing.',
  updated: 'Analysis configuration updated. Click Run to start processing.',
  ready: 'Queuing analysis...',
  started: 'Optimizing network...',
  failed: 'Optimizing network failed',
  completed: 'Optimization complete',
  error: 'Error optimizing network',
  success: 'Success',
};

export function showTerragraphNotifications(anpJobStatus, message = null) {
  switch (anpJobStatus) {
    case STARTED:
    case READY:
    case COMPLETED:
    case CREATED:
    case UPDATED:
    case SUCCESS: {
      toast(<Message success>{TP_STATUS_MESSAGES[anpJobStatus]}</Message>, {
        autoClose: TOAST_AUTOCLOSE,
      });
      break;
    }
    case FAILED:
    case ERROR: {
      toast(
        <Message error>{message || TP_STATUS_MESSAGES[anpJobStatus]}</Message>,
        {
          autoClose: TOAST_AUTOCLOSE,
        }
      );
      break;
    }
  }
}
