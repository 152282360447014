import React from 'react';
import { Segment } from 'semantic-ui-react';

function WarningsPanel({ warning }) {
  if (!Array.isArray(warning)) {
    warning = [warning];
  }
  const uniqueWarnings = [...new Set(warning)];
  const warningString = uniqueWarnings.join('\n\n');
  return (
    <>
      <Segment
        key={`warnings-panel`}
        inverted
        color="red"
        tertiary
        style={{ whiteSpace: 'pre-wrap', fontSize: '13px' }}
      >
        {warningString}
      </Segment>
    </>
  );
}

export default WarningsPanel;
