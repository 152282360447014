import React from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox, Table, Button } from 'semantic-ui-react';
import { setLocalJSON } from 'src/utils/useful_functions';
import { sortByBandOrder } from './AntennaPanel';
import { VALID_PMP_BANDS, VALID_PTP_BANDS } from 'src/model/BandDefinition';

const BandSelection = ({ selectedBands, setSelectedBands, kind }) => {
  let orderBandList = VALID_PMP_BANDS;
  if (kind === 'ptp') {
    orderBandList = VALID_PTP_BANDS;
  }

  // Function to save data to local storage
  const saveToLocalStorage = (key, value) => {
    const sortedValues = sortByBandOrder(value, orderBandList);
    setSelectedBands(sortedValues);
    setLocalJSON(key, value);
  };

  // Update data and save it to local storage
  const updateData = (label, checkedStatus) => {
    const updatedSelectedBands = [...selectedBands];
    const index = updatedSelectedBands.indexOf(label);
    if (index > -1 && !checkedStatus) {
      updatedSelectedBands.splice(index, 1);
    } else {
      updatedSelectedBands.push(label);
    }
    saveToLocalStorage(`cn.lp.antennas.${kind}`, updatedSelectedBands);
  };

  const handleCheckboxChange = (e, { checked, label }) => {
    updateData(label, checked);
  };

  let selectMsg = 'Select all';
  let selectIcon = 'square';

  if (selectedBands.length === orderBandList.length) {
    selectMsg = 'Select none';
    selectIcon = 'square outline';
  } else if (!selectedBands.length) {
    selectMsg = 'Select all';
    selectIcon = 'check square outline';
  }

  const handleSelectAllOrNone = () => {
    if (selectedBands.length === orderBandList.length) {
      saveToLocalStorage(`cn.lp.antennas.${kind}`, []);
    } else {
      saveToLocalStorage(`cn.lp.antennas.${kind}`, [...orderBandList]);
    }
  };

  return (
    <>
      <h4>Band Selection</h4>
      <Button
        basic
        label={selectMsg}
        icon={selectIcon}
        title={selectMsg}
        onClick={handleSelectAllOrNone}
      />
      <Table compact size="small">
        <Table.Body className="bandSelection">
          {orderBandList.map((band) => (
            <Table.Row style={{ width: '100%' }} key={band}>
              <Table.Cell style={{ borderTop: '0' }}>
                <Checkbox
                  checked={selectedBands.includes(band)}
                  onChange={handleCheckboxChange}
                  label={band}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default injectIntl(BandSelection);
