import { ClutterTypes } from '../../utils/types/commonTypes';

export class ProfilePoint {
  range: number;
  height: number;
  obstruction: number;
  clutter: string;
  clutterHeight: number = 0;
  worstEarth: number;
  antennaHeight: number = 0;
  index: number;
  plotClutter: boolean;
  radioHeight: number; // sum of ground,curvature
  totalHeight: number; // sum of ground,clutter(if clutter enabled),obstruction
  clutterInfo: { key: ClutterTypes; details: [string, number] };
  constructor(
    range: number,
    height: number,
    clutter: string,
    obstruction: number = 0,
    index: number,
    plotClutter: boolean,
    worstEarth: number,
    curvature: number,
    clutterInfo: { key: ClutterTypes; details: [string, number] }
  ) {
    this.range = range;
    this.height = height;
    this.clutter = clutter;
    this.obstruction = obstruction;
    this.plotClutter = plotClutter;
    this.worstEarth = worstEarth;
    this.clutterInfo = clutterInfo;
    if (this.plotClutter) {
      const clutterHeight = this.clutterInfo.details[1];
      this.clutterHeight = clutterHeight;
    }
    this.radioHeight = this.height + curvature;
    this.totalHeight = this.radioHeight + this.obstruction + this.clutterHeight;
    this.index = index;
  }
}
