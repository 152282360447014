import { EARTHRADIUS } from '../app.constants';

/*
    r is path length
    d is range at given point
    returns the apparent height of the sea at range d on a path of range r
    with earth curvature ke
    ITU-R P.526 V10 equation 45a
*/
export const radioHeight = (
  pathLength: number,
  range: number,
  ke = 4 / 3,
  rad = EARTHRADIUS
) => {
  const re = ke * rad;
  const dan = range;
  const dnb = pathLength - range;
  return (dan * dnb) / (2 * re);
};

export const calcFresnelRadius = (
  frequencyGHz: number,
  range: number,
  pathLength: number
) => {
  const frequencyHz = frequencyGHz * 1e9;
  const sqrtSpeedOfLight = 17314.5;
  const n = 1;
  return (
    sqrtSpeedOfLight *
      Math.sqrt(
        (n * range * (pathLength - range)) /
          frequencyHz /
          (range + (pathLength - range))
      ) +
    0.0000001
  );
};

export const losHeight = (
  range: number,
  pathLength: number,
  localHeight: number,
  remoteHeight: number
): number => {
  return (
    (localHeight * (pathLength - range) + remoteHeight * range) / pathLength
  );
};
