import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Accordion, Button, Form, Icon } from 'semantic-ui-react';
import SitePropertiesAccordion from './SitePropertiesAccordion';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';
import { postWithAuth } from 'src/api';
import { store } from 'src/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { runWithConfirmation } from 'src/utils/useful_functions';
import messages from 'src/messages';
import { fetchSites } from '../mainframe/mainframe.reducer';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import PTPLinksTableAccordion from './networksites/PTPLinksTableAccordion';
import NetworkSiteToolbar from './networksites/NetworkSiteToolbar';
import NDTableAccordion from './networksites/NDTableAccordion';
import SitePerformanceSummaryAccordion from './networksites/SitePerformanceSummaryAccordion';
import PMPLinksTableAccordion from './subscribersites/PMPLinksTableAccordion';

// Delete site handler
const deleteSite = (projectId, id, kind, path, formatMessage, navigate) => {
  runWithConfirmation({
    header: formatMessage(messages.deleteSites),
    message: formatMessage(messages.confirm),
    size: 'mini',
    onConfirm: async () => {
      try {
        await postWithAuth(
          `project/${projectId}/sites`,
          { kind, ids: [id] },
          'DELETE'
        );
        store.dispatch(fetchSites(projectId));
        navigate(path);
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
  });
};

const CommonSiteToolbar = ({ id, kind, site, path, graph, modified }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [hasTowerSwitch, setHasTowerSwitch] = useState(site.has_tower_switch);
  const [permissionWrite, projectId] = useSelector((state) => [
    state.mainFrame.permissionWrite,
    state.mainFrame.projectId,
  ]);

  return (
    <Form.Group>
      {permissionWrite && (
        <>
          <Button
            icon
            basic
            color="red"
            title="Delete"
            onClick={() =>
              deleteSite(projectId, id, kind, path, formatMessage, navigate)
            }
            style={{ margin: '0' }}
          >
            <Icon name="trash alternate" />
          </Button>
          {site.is_network_site && <ToolbarSeparator />}
        </>
      )}
      {site.is_network_site && (
        <NetworkSiteToolbar
          hideButton={modified}
          hasTowerSwitch={hasTowerSwitch}
          setHasTowerSwitch={setHasTowerSwitch}
          graph={graph}
          siteId={id}
          currentSite={site}
          projectId={projectId}
        />
      )}
    </Form.Group>
  );
};

function SiteForm({ projectId, site, id, kind, graph }) {
  // Hooks and State
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const qc = useQueryClient();
  const [modified, setModified] = useState(false);
  const projectName = useSelector((state) => state.mainFrame.projectName);
  const formMethods = useForm({ defaultValues: site });
  const { handleSubmit, reset, formState, getValues } = formMethods;
  const { isSubmitting, errors } = formState;

  const path = pathname.includes('network_site')
    ? '/network_sites'
    : '/subscriber_sites';

  // Clear React Query cache on unmount
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({ queryKey: [projectId, 'site', id] });
    };
  }, [projectId, id, reset]);

  // Clear cache on navigation
  const clearCache = () => {
    reset();
    qc.invalidateQueries({ queryKey: [projectId, 'site', id] });
  };

  // Form submit handler
  const onSubmit = async (formData) => {
    try {
      await postWithAuth(`project/${projectId}/site/${id}`, formData, 'PATCH');
      setModified(false);
    } catch (error) {
      console.error(error);
    }
  };

  const name = getValues('name');

  // Render
  return (
    <div className="network-site-panel">
      <FormProvider {...formMethods}>
        <Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <PanelHeading
            title={name}
            apply={
              <Form.Button
                className="save"
                disabled={!modified || isSubmitting || !isEmpty(errors)}
                compact
                primary
                type="submit"
              >
                {formatMessage(messages.apply)}
              </Form.Button>
            }
            toolbar={
              <CommonSiteToolbar
                id={id}
                kind={kind}
                site={site}
                path={path}
                graph={graph}
                modified={modified}
              />
            }
          />
          <div
            className="main-panel"
            style={{
              display: 'flex',
              columnGap: '6px',
            }}
          >
            <Accordion exclusive={false} fluid className="site-details-panels">
              <SitePropertiesAccordion setModified={setModified} site={site} />

              {site.is_network_site ? (
                <>
                  <PTPLinksTableAccordion
                    projectName={projectName}
                    paths={graph.paths}
                    siteName={site.name}
                    siteId={site.id}
                  ></PTPLinksTableAccordion>

                  <NDTableAccordion
                    projectName={projectName}
                    aps={graph.aps}
                    siteName={site.name}
                  ></NDTableAccordion>

                  <SitePerformanceSummaryAccordion
                    projectId={projectId}
                    id={id}
                  />
                </>
              ) : (
                <PMPLinksTableAccordion
                  projectName={projectName}
                  paths={graph.paths}
                  siteName={site.name}
                ></PMPLinksTableAccordion>
              )}
            </Accordion>
          </div>
        </Form>
      </FormProvider>

      <RouteLeavingGuard
        when={modified}
        shouldBlockNavigation={() => modified}
        yes="Yes"
        no="No"
        title={`Site: ${site.name}`}
        content={formatMessage(messages.warning)}
        callback={clearCache}
      />
    </div>
  );
}

export default SiteForm;
