import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import additionalMessages from '../messages';

/*
 * Creates a title bar for a panel with a close button
 * The actual title and toolbar/actions are created elsewhere.
 */
export const PanelHeading = ({
  title = null,
  toolbar = null,
  apply = null,
}) => {
  const [loggedIn, projectId] = useSelector((state) => [
    state.mainFrame.loggedIn,
    state.mainFrame.projectId,
  ]);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  if (loggedIn && projectId) {
    if (title || toolbar) {
      return (
        <div className="panel-heading">
          <div className="toolbar-wrapper">
            {apply && <div className="apply">{apply}</div>}
            <div className="toolbar">{toolbar}</div>
          </div>
          <Header className="title">{title}</Header>
          <div onClick={() => navigate('/')} className="close-button">
            <Icon
              name="close"
              title={formatMessage(additionalMessages.close)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={() => navigate('/')} className="close-button-fixed">
          <Icon name="close" title={formatMessage(additionalMessages.close)} />
        </div>
      );
    }
  }
};

/*
 * Creates a title bar for a panel with a close button with 2 column layout
 * Currently used for the Terragraph Panel mess.
 */
export const PanelHeading2Col = ({ left = null, right = null }) => {
  const [loggedIn, projectId] = useSelector((state) => [
    state.mainFrame.loggedIn,
    state.mainFrame.projectId,
  ]);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  if (loggedIn && projectId) {
    return (
      <div className="panel-heading2">
        <div className="left">{left}</div>
        <div className="right">{right}</div>
        <div onClick={() => navigate('/')} className="close-button">
          <Icon name="close" title={formatMessage(additionalMessages.close)} />
        </div>
      </div>
    );
  }
};

export const ToolbarSeparator = () => {
  return <span className="separator">&nbsp;</span>;
};
