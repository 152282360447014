import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Label } from 'semantic-ui-react';
import {
  anyToMeter,
  decodeLatLong,
  encodeLatLong,
  formatMacAddress,
  isLatLng,
  isMacAddress,
  meterToAny,
} from 'src/utils/useful_functions';
import get from 'lodash/get';

export type ScaleMethod = 'store' | 'show';

export function scaleValue(
  value,
  units,
  method: ScaleMethod,
  calculateUnits = false,
  extraProps
): number | string {
  if (isMacAddress(units)) {
    if (method === 'store') {
      return value.replace(/[:\-.]/gi, '');
    } else {
      return formatMacAddress(value, units);
    }
  }

  if (isLatLng(units)) {
    if (method === 'store') {
      return decodeLatLong(value, extraProps.isLat)[0];
    } else {
      return encodeLatLong(value, units, extraProps.isLat);
    }
  }
  if (units === 'msn') {
    if (method === 'store') {
      return value?.toString();
    }
  }
  if (value != null && typeof value === 'string') {
    if (value.length === 0) {
      return '';
    } else {
      value = parseFloat(value);
    }
  }

  if (units == null) {
    return value;
  }

  // when storing, convert back to Mbps
  // when showing, convert to unit
  if (units === 'Gbps') {
    if (method === 'store') {
      return value * 1000;
    } else {
      if (calculateUnits) {
        let val = value / 1000;
        return val < 0.1 ? value / 10 : val;
      }
      return value / 1000;
    }
  } else if (units === 'kbps') {
    if (method === 'store') {
      return value / 1000;
    } else {
      return value * 1000;
    }
  } else if (units === 'ft') {
    if (method === 'store') {
      return anyToMeter(value, units, 2);
    } else {
      return meterToAny(value, units, 2);
    }
  }

  return value;
}

/// Scale the number and then fix it to a given precision
export function scaleAndFix(
  value,
  units?: string,
  precision?: number,
  method?: ScaleMethod,
  calculateUnits?: boolean,
  extraProps?: Record<string, any>
): string {
  if (value == null) {
    return '---';
  }

  if (units == null) {
    if (!isNaN(Number(value))) {
      return (value as number).toFixed(precision);
    } else {
      return value;
    }
  }

  if (units === 'msn') {
    return value;
  }

  if (isMacAddress(units)) {
    return formatMacAddress(value, units);
  }

  if (isLatLng(units)) {
    return encodeLatLong(value, units, extraProps.isLat);
  }
  const scaled = scaleValue(value, units, method, calculateUnits, extraProps);

  if (typeof scaled === 'string' && scaled.length === 0) {
    return scaled;
  }

  if (precision != null) {
    return (scaled as number).toFixed(precision);
  } else {
    return scaled.toString();
  }
}
