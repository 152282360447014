// This extends Leaflet with the Bing layer extensions
import bing from 'leaflet-bing-layer';

// Note: this key is set in the set_runtime.py build script.
const k = 'Ahk8JQuyCdUBV1pXjPdBfWoVJ1pk5R5Dn5p5yOTRVmERiZW-TO51h15SeDlz_zNi';

let _baseMaps = {};

// TODO: Get zoom, attribution, etc
const bingLayers = [
  { displayLabel: 'Map', type: 'Road' },
  { displayLabel: 'Satellite', type: 'AerialWithLabels' },
  { displayLabel: 'Dark Gray', type: 'CanvasDark' },
  { displayLabel: 'Light Gray', type: 'CanvasGray' },
];

for (let i = 0; i < bingLayers.length; i++) {
  const layer = bingLayers[i];
  const mapLayer = L.tileLayer.bing({
    bingMapsKey: k,
    imagerySet: layer.type,
    displayLabel: layer.displayLabel,
    // All of the Bing layers currently
    // have a max zoom of 18. This hack
    // allows the user to zoom in more
    // and scales the underlying image
    maxNativeZoom: 18,
    maxZoom: 21,
  });
  _baseMaps[layer.displayLabel] = mapLayer;
}

export const baseMaps = _baseMaps;
