import { availabilityAsTimeString } from 'src/utils/useful_functions';

export function getMeshEndSummary(name: string) {
  return {
    fields: [
      {
        label: 'Operational Power',
        getter: `summary.${name}.operational_receive_power`,
        isDisplay: true,
        warning: ({ path }) => {
          return path.summary[name].receive_level_warning?.length > 0;
        },
        tooltip: () => {
          return 'Operational Power is the receive level of a single patch';
        },
        recalc: true,
      },
      {
        getter: `summary.${name}.receive_level_warning`,
        isDisplay: true,
        warning: () => true,
        show: ({ path }) => {
          return path.summary[name].receive_level_warning?.length > 0;
        },
      },
      {
        label: 'Mean IP Predicted',
        getter: `summary.${name}.mean_data_rate_predicted`,
        units: 'Gbps',
        precision: 3,
        warning: ({ path }) => {
          return (
            path.summary[name].mean_data_rate_predicted <
            path.summary[name].mean_data_rate_requirement
          );
        },
        recalc: true,
      },
      {
        label: 'Mean IP Required',
        getter: `summary.${name}.mean_data_rate_requirement`,
        editable: true,
        units: 'Gbps',
        min: 0,
        precision: 2,
      },
      {
        label: '% of Required IP',
        getter: `summary.${name}.percentage_of_required_data_rate`,
        units: '%',
        precision: 0,
        warning: ({ path }) => {
          return path.summary[name].percentage_of_required_data_rate < 100;
        },
        recalc: true,
      },
      {
        label: 'Min IP Required',
        getter: `summary.${name}.minimum_data_rate_requirement`,
        editable: true,
        units: 'Gbps',
        min: 0,
        precision: 2,
      },
      {
        label: 'Min IP Availability Required',
        getter: `summary.${name}.minimum_reliability_requirement`,
        editable: true,
        units: '%',
        precision: 4,
        min: 0,
        max: 100,
        tooltip: ({ path }) => {
          return `Unavailable for ${availabilityAsTimeString(
            path.summary[name].minimum_reliability_requirement
          )}`;
        },
      },
      {
        label: 'Min IP Availability Predicted',
        getter: `summary.${name}.throughput_reliability`,
        units: '%',
        precision: 4,
        tooltip: ({ path }) => {
          return `Unavailable for ${availabilityAsTimeString(
            path.summary[name].throughput_reliability
          )}`;
        },
        warning: ({ path }) => {
          return (
            path.summary[name].throughput_reliability <
            path.summary[name].minimum_reliability_requirement
          );
        },
        recalc: true,
      },
    ],
  };
}

export const meshLinkSummary = {
  fields: [
    {
      label: 'Lowest Mode Availability',
      getter: 'summary.link.system_reliability',
      units: '%',
      precision: 4,
      popup: 'summary.availability_report',
    },
    {
      label: 'System Gain Margin',
      getter: 'summary.link.system_fade_margin',
      units: 'dB',
      precision: 2,
    },
    {
      label: 'Free Space Path Loss',
      getter: 'summary.link.free_space_path_loss',
      units: 'dB',
      precision: 2,
    },
    {
      label: 'Gaseous Absorption Loss',
      getter: 'summary.link.mean_atmospheric_loss',
      units: 'dB',
      precision: 2,
    },
    {
      label: 'Excess Path Loss',
      getter: 'summary.link.excess_path_loss',
      units: 'dB',
      precision: 2,
    },
    {
      label: 'Total Path Loss',
      getter: 'summary.link.total_path_loss',
      units: 'dB',
      precision: 2,
    },
  ],
};
