import React from 'react';
import CSVSiteValidation from './CSVSiteValidation';
import KMLSiteData from './KMLSiteData';

function SiteFileData(props) {
  const {
    fileName,
    data,
    handlePrevStep,
    closeFunc,
    groupKind = 'network_sites',
  } = props;
  if (fileName.includes('.kml') || fileName.includes('.kmz')) {
    return (
      <KMLSiteData
        data={data}
        groupKind={groupKind}
        handlePrevStep={handlePrevStep}
        closeFunc={closeFunc}
      ></KMLSiteData>
    );
  } else {
    return (
      <CSVSiteValidation
        skipFirstRow={true}
        data={data}
        groupKind={groupKind}
        handlePrevStep={handlePrevStep}
        closeFunc={closeFunc}
      ></CSVSiteValidation>
    );
  }
}

export default SiteFileData;
