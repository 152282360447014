import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import TableComponent from '../../../../components/controls/TableComponent';
import { adjustPrecision } from '../../../../utils/useful_functions';
import messages from '../../../../messages';
import { MAX_MUX_GAIN } from '../../../../app.constants';

export type FloodTestGroupingType = {
  tableLayout: Function;
  upLink: any[];
  downLink: any[];
  upLinkMaxMuxGain: number | null;
  downLinkMaxMuxGain: number | null;
} & WrappedComponentProps;

function FloodTestGrouping(props: FloodTestGroupingType) {
  const { intl, tableLayout } = props;
  const { formatMessage } = intl;
  const hasUplink = props.upLink !== null;
  const order = hasUplink ? ['downLink', 'upLink'] : ['downLink'];
  const titles = {
    downLink: formatMessage(messages.floodTestGroupDownLink),
    upLink: formatMessage(messages.floodTestGroupUpLink),
  };
  return (
    <Grid stackable columns={hasUplink ? 2 : 1}>
      {order.map((element, index) => {
        const attrs = ['group_size', 'distribution'];
        const header = ['Group Size', '% Distribution'];
        const rows = tableLayout(attrs, props[element]);
        const muxGain = props[`${element}MaxMuxGain`];
        return (
          <Grid.Column>
            <Header as="h5" textAlign="center">
              {titles[element]}
            </Header>
            <TableComponent
              padded={false}
              size="small"
              compact
              header={header}
              rows={rows}
            ></TableComponent>
            {muxGain && (
              <p>
                {formatMessage(messages.floodTestMaxMuxGain)}:{' '}
                {adjustPrecision(muxGain, MAX_MUX_GAIN)}
              </p>
            )}
          </Grid.Column>
        );
      })}
    </Grid>
  );
}

export default injectIntl(FloodTestGrouping);
