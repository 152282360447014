import React, { useEffect, useState } from 'react';
import {
  Message,
  Segment,
  Input,
  Form,
  Table,
  Popup,
  Button,
  Loader,
  Icon,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import additionalMessages from '../../messages';
import { crudRead } from '../../actions';
import { store } from '../../store';
import {
  savePermissions,
  getProject,
  updatePermissions,
  deletePermissions,
} from '../../api';
import { batch, useSelector } from 'react-redux';
import {
  getProjectPermissions,
  setProjectModified,
} from '../mainframe/mainframe.reducer';

const ALLOW_PERMISSION_EDIT = false;

const PermissionsPanel = (props) => {
  const { formatMessage } = props.intl;
  const { projectId, projectPermissions, email, permissionAdmin } = useSelector(
    (state) => state.mainFrame
  );
  const [error, setError] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const permissionOptions = [
    {
      key: 'read',
      value: 1,
      text: formatMessage(additionalMessages.read),
    },
    {
      key: 'readwrite',
      value: 2,
      text: formatMessage(additionalMessages.readwrite),
    },
    {
      key: 'admin',
      value: 3,
      text: formatMessage(additionalMessages.admin),
    },
  ];

  const permissionStrings = {
    1: 'read',
    2: 'readwrite',
    3: 'admin',
  };

  const handlePermissionChange = (e, o) => {
    const { userId, value } = o;
    setError(false);
    setErrorMessage(null);
    updatePermissions({ projectId }, { user_id: userId, permission: value })
      .then(() => {
        batch(() => {
          store.dispatch(crudRead({ api: getProject, projectId }));
          store.dispatch(getProjectPermissions(projectId));
          store.dispatch(setProjectModified(true));
        });
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setErrorMessage(err.detail);
      });
  };

  const addUser = (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage(null);
    // read permission === 1
    savePermissions({ projectId }, { email: newUserEmail, permission: 1 })
      .then(() => {
        setNewUserEmail('');
        batch(() => {
          store.dispatch(crudRead({ api: getProject, projectId }));
          store.dispatch(getProjectPermissions(projectId));
          store.dispatch(setProjectModified(true));
        });
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setErrorMessage(err.detail);
      });
  };

  const removeUser = (userId) => {
    return () => {
      setError(false);
      setErrorMessage(null);
      deletePermissions({ projectId }, { user_id: userId })
        .then(() => {
          batch(() => {
            store.dispatch(crudRead({ api: getProject, projectId }));
            store.dispatch(getProjectPermissions(projectId));
            store.dispatch(setProjectModified(true));
          });
        })
        .catch((err) => {
          console.error(err);
          setError(true);
          setErrorMessage(err);
        });
    };
  };

  useEffect(() => {
    store.dispatch(getProjectPermissions(projectId));
  }, [projectId]);

  if (!projectPermissions) {
    return <Loader />;
  }

  let userRows = [];
  for (const { user, permission } of projectPermissions) {
    const isCurrentUser = user.email === email;
    let permissionCell = formatMessage(
      additionalMessages[permissionStrings[permission]]
    );
    // Current user can't drop their own permissions on the project
    if (ALLOW_PERMISSION_EDIT && permissionAdmin && !isCurrentUser) {
      permissionCell = (
        <Form.Dropdown
          userId={user.id}
          options={permissionOptions}
          defaultValue={permission}
          onChange={handlePermissionChange}
        />
      );
    }

    userRows.push(
      <Table.Row key={user.id}>
        <Table.Cell data-testid="userName">
          <Popup
            content={user.email}
            size="mini"
            trigger={
              <span>
                {user.first_name} {user.last_name}
              </span>
            }
          />
        </Table.Cell>
        <Table.Cell collapsing data-testid="permissionCell">
          {permissionCell}
        </Table.Cell>
        <Table.Cell collapsing data-testid="trashIcon">
          {permissionAdmin ? (
            <Button
              basic
              icon="trash alternate"
              title={formatMessage(additionalMessages.delete)}
              onClick={removeUser(user.id)}
              disabled={isCurrentUser}
            />
          ) : null}
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Segment basic>
      <Table unstackable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell data-testid="user">
              {formatMessage(additionalMessages.user)}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2} data-testid="permissions">
              {formatMessage(additionalMessages.permissions)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{userRows}</Table.Body>
      </Table>

      {permissionAdmin ? (
        <div className="ui equal width form">
          <Form.Group>
            <Form.Field
              name="newUserEmail"
              control={Input}
              placeholder={formatMessage(additionalMessages.userEmail)}
              onChange={(_, { value }) => {
                setNewUserEmail(value);
                if (!value || !email) {
                  return false;
                } else if (value === email) {
                  setErrorMessage(
                    formatMessage(additionalMessages.userAlreadyExists)
                  );
                } else {
                  setErrorMessage(null);
                }
              }}
              value={newUserEmail}
              data-testid="newUserEmail"
            />
            <Button
              onClick={addUser}
              icon="user plus"
              disabled={newUserEmail.indexOf('@') < 0 || newUserEmail === email}
              title={formatMessage(additionalMessages.addUser)}
              data-testid="submitNewUser"
            />
          </Form.Group>
          <Message
            error
            visible={errorMessage != null}
            onDismiss={() => {
              setError(false);
              setErrorMessage(null);
            }}
            header={formatMessage(additionalMessages.addUserErrorHeader)}
            content={
              errorMessage ||
              formatMessage(additionalMessages.addUserErrorContent)
            }
          />
          <Message info visible compact>
            <div>
              Users must have logged into LINKPlanner before they can be added
              to the project.
            </div>
            <br />
            <div>
              After adding a user, you must Projects -&gt; Save
              <Icon name="save" color="red" style={{ marginLeft: '0.25rem' }} />
              before the user can see the project in their list. Read-only users
              can only see the saved state of the project, so any in-work
              changes are not shown to read-only users.
            </div>
          </Message>
        </div>
      ) : null}
    </Segment>
  );
};

export default injectIntl(PermissionsPanel);
