import React from 'react';
import { injectIntl } from 'react-intl';
import WarningsPanel from '../equipment/common/WarningsPanel';
import StoredAccordion from 'src/components/StoredAccordion';
import isArray from 'lodash/isArray';
import type { PTPPath } from './ptp-link-type';

type WarningEntry = [string, string | string[] | object];

function warningContent(path: PTPPath) {
  let result = [];
  if (path.warnings != null) {
    Object.entries(path.warnings).forEach(
      ([key, warning]: WarningEntry) => {
        if (warning && typeof warning === 'object' && !Array.isArray(warning)) {
          // ignore objects like local and remote - they are used
          // elsewhere in the ui
          return;
        } else if (key === 'worst_earth') {
          // worst earth warning should appear elsewhere in the ui
          return;
        } else if (isArray(warning)) {
          // flatten multiple warnings
          result.push(...warning);
        } else if (
          typeof warning === 'string' &&
          (warning as string).length > 0
        ) {
          result.push(warning);
        }
      }
    );
  }
  return result;
}

function PTPWarningsPanel({ path }: any) {
  const warnings = warningContent(path);

  if (warnings.length === 0) {
    return <></>;
  }

  return (
    <StoredAccordion
      name="ptp_warnings_panel"
      title="Warnings"
      className="custom-error"
    >
      <WarningsPanel warning={warnings} />
    </StoredAccordion>
  );
}

export default injectIntl(PTPWarningsPanel);
