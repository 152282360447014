import React from 'react';

function Mail({ email, subject = '', body = '', children }) {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  const href = `mailto:${email}${params}`.slice(0, 1500); // href character limit
  console.log('href length', href.length);
  return (
    <span className="m-2">
      <a href={href}>{children}</a>
    </span>
  );
}

export default Mail;
