import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import EquipmentSummaryPanel from './EquipmentSummaryPanel';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function EquipmentSummary({ equipmentSummary, currentMesh }: any) {
  const { meshId } = useParams();
  const possibleRecalcRdx = useSelector(
    (state: any) => state.mesh.possibleRecalc
  );
  const possibleRecalc = possibleRecalcRdx[meshId] ?? false;
  const className = possibleRecalc ? 'update-required' : null;
  const color = possibleRecalc ? null : 'red';

  return (
    <>
      <Grid stackable columns={2}>
        <Grid.Column style={{ paddingRight: '0.5rem' }}>
          <EquipmentSummaryPanel
            currentMesh={currentMesh}
            panelData={equipmentSummary.values.local}
            title={equipmentSummary.localName}
            endName="local"
          ></EquipmentSummaryPanel>
        </Grid.Column>
        <Grid.Column style={{ paddingLeft: '0.5rem' }}>
          <EquipmentSummaryPanel
            currentMesh={currentMesh}
            panelData={equipmentSummary.values.remote}
            title={equipmentSummary.remoteName}
            endName="remote"
          ></EquipmentSummaryPanel>
        </Grid.Column>
      </Grid>
      {equipmentSummary.warnings.eq_summary ? (
        <Segment inverted className={className} color={color}>
          {equipmentSummary.warnings.eq_summary}
        </Segment>
      ) : null}
    </>
  );
}

export default injectIntl(EquipmentSummary);
