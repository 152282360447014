import React from 'react';
import { Link } from 'react-router-dom';
import { getCellStyle } from 'src/utils/useful_functions';

const LinkRenderer = (props) => {
  let extraProps = {};
  let newLink = props.getHref(props.data);
  if (props.data.strokeColor) {
    extraProps = { style: { color: props.data.strokeColor } };
  }
  return <Link to={newLink} {...extraProps}>{props.value}</Link>;
};

export default LinkRenderer;

export const CallbackLinkRenderer = (props) => {
  let callback = props.callback(props.data);
  return (
    // eslint-disable-next-line
    <a onClick={callback} style={getCellStyle(props)}>
      {props.value}
    </a>
  );
};
