import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { bandwidthChangehandler } from './configs/config-utils';
import { ComponentCarrierType } from './ComponentCarrier';

function ComponentCarrierBandwidth(props: ComponentCarrierType) {
  const { index, choices, setModified, refreshChoices } = props;
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { control, getValues, setValue } = useFormContext();
  const { equipment } = choices;
  const bandwidthChoiceObj = equipment['bandwidth'];
  let ctrl: any = null;
  const carrierQty = getValues('radios.0.equipment.carrier_qty');
  const radioQty = getValues('radio_qty');
  if (index == 0) {
    ctrl = (
      <Controller
        control={control}
        name={`radios.${index}.equipment.bandwidth`}
        render={({ field: { ref, onChange, ...rest } }) => (
          <>
            <Form.Field>
              <Form.Select
                style={{ minWidth: '6em' }}
                width={1}
                options={bandwidthChoiceObj.choices}
                {...rest}
                disabled={!permissionWrite}
                onChange={(e, data) => {
                  const newValue = data.value;
                  onChange(newValue);
                  setModified(true);
                  if (carrierQty > 1 || radioQty > 1) {
                    setValue(`radios.1.equipment.bandwidth`, newValue, {
                      shouldValidate: true,
                    });
                  }
                  refreshChoices({ field: { attr: 'bandwidth' } });
                  bandwidthChangehandler(
                    newValue,
                    { getValues, setValue },
                    choices,
                    0
                  );
                  if (carrierQty > 1 || radioQty > 1) {
                    bandwidthChangehandler(
                      newValue,
                      { getValues, setValue },
                      choices,
                      1
                    );
                  }
                }}
              />
            </Form.Field>
          </>
        )}
      />
    );
  } else {
    ctrl = <div>{getValues('radios.0.equipment.bandwidth')}</div>;
  }
  return ctrl;
}

export default ComponentCarrierBandwidth;
