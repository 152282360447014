import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Header, Message } from 'semantic-ui-react';
import { getWithAuth } from 'src/api';
import { GREY_COLOR } from 'src/app.constants';
import { BomGrid } from 'src/components/BomModal';
import CustomAccordion from 'src/components/controls/CustomAccordion';
import additionalMessages from '../../messages';
import { PanelHeading } from 'src/components/PanelHeading';

function BomPanel(props) {
  const { formatMessage } = props.intl;
  const { projectId, projectName, accessPoints } = useSelector(
    (state) => state.mainFrame
  );
  const anyDirtyAPs = accessPoints?.features.some(
    (f) => f.properties.strokeColor === GREY_COLOR
  );
  const { created } = useSelector((state) => state?.bestServer);
  const isCreatingLinks = created !== null;
  const [bom, setBom] = useState(null);
  const [smBom, setSmBom] = useState(null);
  const [ptpBom, setPtpBom] = useState(null);
  const [switchNetworkBom, setSwitchNetworkBom] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadBom = () => {
    setLoading(true);
    getWithAuth(`project/${projectId}/pmp_bom`).then((res) => {
      setBom(res.bom);
      setSmBom(res.sm_bom);
      setPtpBom(res.ptp_bom);
      setSwitchNetworkBom(res.switch_nw_bom);
      setLoading(false);
    });
  };

  const accordions = [
    {
      key: 'PTPBom',
      titleProps: {
        content: (
          <FormattedMessage id="common.ptpBom" defaultMessage="PTP BOM" />
        ),
      },
      contentProps: {
        content: (
          <BomGrid
            accordionTitle="ptp_bom"
            rowData={ptpBom}
            autoLoad={false}
            editable={false}
          />
        ),
      },
    },
    {
      key: 'SwitchNetworkBom',
      titleProps: {
        content: (
          <FormattedMessage
            id="common.switchNetworkBom"
            defaultMessage="Switch Network BOM"
          />
        ),
      },
      contentProps: {
        content: (
          <BomGrid
            accordionTitle="switch_network_bom"
            rowData={switchNetworkBom}
            autoLoad={false}
            editable={false}
          />
        ),
      },
    },
    {
      key: 'PMPNetworkBom',
      titleProps: {
        content: (
          <FormattedMessage
            id="common.pmpNetworkBom"
            defaultMessage="PMP Network BOM"
          />
        ),
      },
      contentProps: {
        content: (
          <BomGrid
            accordionTitle="pmp_network_bom"
            rowData={bom}
            autoLoad={false}
            editable={false}
          />
        ),
      },
    },
    {
      key: 'PMPSubscribersBom',
      titleProps: {
        content: (
          <FormattedMessage
            id="common.pmpSubscribersBom"
            defaultMessage="PMP Subscriber Modules BOM"
          />
        ),
      },
      contentProps: {
        content: (
          <BomGrid
            accordionTitle="pmp_subscriber_modules_bom"
            rowData={smBom}
            autoLoad={false}
            editable={false}
          />
        ),
      },
    },
  ];

  return (
    <div
      className="panel-with-heading"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: '1rem',
      }}
    >
      <PanelHeading
        title={
          <>
            {formatMessage(additionalMessages.bom)} for {projectName}
          </>
        }
        toolbar={
          <Button
            color="blue"
            onClick={loadBom}
            loading={loading}
            disabled={anyDirtyAPs || isCreatingLinks || loading}
          >
            Load Project BOM
          </Button>
        }
      />
      {(anyDirtyAPs || isCreatingLinks) && (
        <Message
          warning
          style={{ margin: '0', flexShrink: '0', flexBasis: '3rem' }}
        >
          <Message.Header>Calculating. Please wait...</Message.Header>
        </Message>
      )}

      <div
        className="main-panel"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <CustomAccordion
          accordions={accordions}
          accordionProps={{
            style: { height: '100%', overflowY: 'auto', padding: '0.8rem' },
          }}
        />
      </div>
    </div>
  );
}

export default injectIntl(BomPanel);
