import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Container, Button, Segment, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import about from '../About';
import config from '../config';
import Footer from './Footer';
import socket from '../sockets';
import { convertObjectKeys, toCamel } from '../utils/change-case';
import { store } from '../store';
import { userLogin } from '../pages/mainframe/mainframe.reducer';

function LoginPanel(props) {
  const { loggedIn } = useSelector((state) => state.mainFrame);
  const [params, _] = useSearchParams();
  const afc = params.get('afc');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [possibleSession, setPossibleSession] = useState(true);
  // safety measure in case the logic ends up spamming the login endpoint
  // (seen once in development - might be ephemeral, better to be safe)
  const [loginAttempts, setLoginAttempts] = useState(0);

  if (
    // attempt login if:
    // we haven't tried yet
    loginAttempts < 1 &&
    // we don't know whether a session currently exists...
    !loggingIn &&
    possibleSession &&
    // ...and the user is not logged in or the user is logged in without a socket connection
    ((!loggedIn && !loginError) || (loggedIn && socket.token == null))
  ) {
    setLoginAttempts(1);
    setLoggingIn(true);
    fetch(`${config.apiURL}/user/info`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'include',
    })
      .then(async (response) => {
        if (response.status === 200) {
          const result = await response.json();
          const user = convertObjectKeys(result.user, toCamel);
          user.prefs = convertObjectKeys(user.prefs, toCamel);
          if (user?.id !== null && result.token !== null) {
            store.dispatch(userLogin({ ...user }));

            if (process.env.NODE_ENV === 'development') {
              window.lp_socket = socket;
            }

            socket.connect(result.token);
            socket.enterUserRoom(user.id);
          }
        } else if (response.status === 401) {
          // the initial auto login attempt has indicated that no prior session exists
          setPossibleSession(false);
        } else {
          setLoginError(true);
        }
        setLoggingIn(false);
      })
      .catch((err) => {
        console.error(err);
        setLoginError(true);
        setLoggingIn(false);
      });
  }

  if (loggedIn) {
    if (afc) {
      return <Navigate to="/afc" />;
    } else {
      return <Navigate to="/projects" />;
    }
  }

  let content;
  if (loggingIn) {
    content = <Loader active data-testid="loader" />;
  } else {
    content = (
      <Button
        primary
        as="a"
        href={`${config.apiURL}/saml/login`}
        data-testid="cambium_single_sign_on"
      >
        Sign In
      </Button>
    );
  }

  return (
    <>
      <img src="/assets/login_banner.png" alt="Network image" width="100%" />
      <Container
        style={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
        }}
      >
        <Container>
          <Grid
            columns={2}
            stackable
            celled="internally"
            relaxed="very"
            padded="vertically"
          >
            <Grid.Row
              verticalAlign="middle"
              style={{ backgroundColor: '#f9f9f9' }}
            >
              <Grid.Column>
                <Segment vertical size="large">
                  <p>
                    LINKPlanner allows you to model "what if" scenarios - based
                    on geography, distance, antenna height, transmit power, and
                    other factors – to optimize system performance before
                    purchase. Quickly design networks using Cambium Networks'
                    family of products, for optimal deployment and cost
                    effectiveness with ease.
                  </p>
                  <p>
                    LINKPlanner is a free, user-friendly link-design tool.
                    LINKPlanner leads the industry as the most trusted and
                    intuitive RF link planning tool, with tens of thousands of
                    links deployed successfully worldwide.
                  </p>
                </Segment>
                <Segment vertical size="large">
                  If you have any questions about the LINKPlanner or for
                  additional support please contact{' '}
                  {/* note that we have to hard-code the email address in here */}
                  <a href={`mailto:${about.email}`}>{about.email}</a>.
                </Segment>
                <Segment vertical size="large">
                  Share your expertise or request additional help from other
                  LINKPlanner users on the{' '}
                  <a
                    href="http://community.cambiumnetworks.com/t5/LINK-Planner/bd-p/forums_management_link_planner"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cambium Community Forum
                  </a>
                  .
                </Segment>
              </Grid.Column>

              <Grid.Column textAlign="center">
                {loginError && (
                  <Segment inverted color="red" data-testid="error">
                    An error occurred while logging in
                  </Segment>
                )}
                {content}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Footer></Footer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default injectIntl(LoginPanel);
