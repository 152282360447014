import React from 'react';
import produce from 'immer';
import { Form, Grid, Label, Segment } from 'semantic-ui-react';
import SMProductPreferencesPanel from './SMProductPrefsPanel';
import { useSelector } from 'react-redux';
import {
  getValueWithPrecisionAsString,
  validate,
} from '../../components/controls/NumberControlSemantic';

function ProductFamilyCtrl(props) {
  const { family, state, setState, permissionWrite } = props;
  const { choices, values } = state.productFamilies;

  const disabledProps = (condition) => {
    if (condition) {
      return { disabled: true, style: { opacity: 0.5 } };
    }
    return {};
  };

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <h4>{family}</h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={values[family].targetSmMode ? 2 : 1}>
        {values[family].targetSmMode && (
          <Grid.Column>
            <div className="field">
              <label>Target SM Mode</label>
              <select
                value={values[family].targetSmMode}
                {...disabledProps(!permissionWrite)}
                onChange={(e) => {
                  setState(
                    produce(state, (draft) => {
                      draft.productFamilies.values[family].targetSmMode =
                        e.target.value;
                    })
                  );
                }}
              >
                {choices[family].map((choice) => (
                  <option
                    value={choice.value}
                    key={`${family}-${choice.value}`}
                  >
                    {choice.text}
                  </option>
                ))}
              </select>
            </div>
          </Grid.Column>
        )}
        <Grid.Column>
          <div className="field">
            <Form.Input
              type="number"
              step={0.1}
              onClick={(e, data) => {
                e.target.select();
              }}
              label="Target SM Fade Margin"
              value={values[family].targetSmFadeMargin || 0}
              {...disabledProps(!permissionWrite)}
              onChange={(_, { value }) => {
                const valid = validate(value, 0, 99, 'decimal');
                let finalValue = 0;
                if (valid) {
                  finalValue = getValueWithPrecisionAsString(
                    parseFloat(value),
                    'decimal',
                    1,
                    value
                  );
                }
                setState(
                  produce(state, (draft) => {
                    draft.productFamilies.values[family].targetSmFadeMargin =
                      finalValue;
                  })
                );
              }}
            >
              <input />
              <Label>dB</Label>
            </Form.Input>
          </div>
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default function ProductFamilyPanel(props) {
  const { state, setState } = props;
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );

  let ctrls = null;
  if (state.productFamilies.choices) {
    ctrls = Object.keys(state.productFamilies.values)
      .sort()
      .map((family) => (
        <React.Fragment key={`ProductFamilyPanel-${family}`}>
          <ProductFamilyCtrl
            family={family}
            state={state}
            setState={setState}
            key={`ctrl-${family}`}
            permissionWrite={permissionWrite}
          />
          <Grid.Row columns={1}>
            <Grid.Column>
              <SMProductPreferencesPanel
                state={state}
                setState={setState}
                family={family}
                permissionWrite={permissionWrite}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      ));
  }

  return (
    <Segment basic loading={ctrls == null}>
      <Grid>{ctrls}</Grid>
    </Segment>
  );
}
