import React, { useState } from 'react';
import { Loader, Tab } from 'semantic-ui-react';
import { ResponsiveLine } from '@nivo/line';
import { scaleLinear } from 'd3-scale';
import { cleanAfcData, getXYValues, hittest } from './processAFC';
import { pyround } from 'src/utils/useful_functions';
import { useSelector } from 'react-redux';
import { getXPosition } from 'src/utils/chartUtils';

const xUnits = 'MHz';
const yUnits = 'dBm';

const MARGIN = { top: 5, right: 20, bottom: 50, left: 60 };

const initHighlightPosition = {
  x: 0,
  y: 0,
};

/*
 * JS is so bad it can't even modulo negative numbers
 */
const correctMod = (v, m) => {
  return ((v % m) + m) % m;
};

const AfcChart = ({ afcResponse, bandwidth, productConfig }) => {
  const sidebarResizing = useSelector(
    (state) => state.mainFrame.sidebarResizing
  );

  const [coords, setCoords] = useState(initHighlightPosition);
  const [centreLineData, setCentreLine] = useState([]);
  const [highlightData, setHighlight] = useState([]);

  if (sidebarResizing) {
    return (
      <div
        className="afc-chart"
        style={{ height: '190px', border: 'thin solid #dedede' }}
      >
        &nbsp;
      </div>
    );
  }

  const freqMin = 5925;
  const freqMax = productConfig.maxFrequency || 6875;

  const eirpRanges = cleanAfcData(afcResponse, bandwidth, freqMax);
  const minEirp = pyround(Math.min(...eirpRanges.map((r) => r.eirp)), 0);
  const yMin = Math.min(0, minEirp - correctMod(minEirp, 6));

  const xyData = getXYValues(eirpRanges, yMin);

  const yMax = 40;
  const freqOffset = 50;
  const xAxisTickValues = [freqMin];
  const roundedMin = freqMin + (freqMin % freqOffset);
  const roundedMax = freqMax - (freqMax % freqOffset);
  for (let i = roundedMin; i <= roundedMax; i = i + freqOffset) {
    xAxisTickValues.push(i);
  }
  xAxisTickValues.push(freqMax);
  const yAxisTickValues = [yMin];
  for (let i = yAxisTickValues[0] + 6; i <= 36; i += 6) {
    yAxisTickValues.push(i);
  }

  return (
    <>
      <div
        className="afc-chart"
        style={{ height: '190px', border: 'thin solid #dedede' }}
      >
        <ResponsiveLine
          data={[
            { id: 'afc', data: xyData },
            { id: 'highlight', data: highlightData },
            { id: 'centreLine', data: centreLineData },
          ]}
          colors={['rgb(145, 207, 255)', 'rgb(86, 133, 214)', '#220066']}
          id="afcChart"
          useMesh={true}
          innerPadding={1}
          axisTop={null}
          enableArea={true}
          axisRight={null}
          areaBaselineValue={yMin}
          yScale={{
            type: 'linear',
            min: yMin,
            max: yMax,
          }}
          xScale={{
            type: 'linear',
            min: freqMin,
            max: freqMax,
          }}
          enableGridY={true}
          onMouseMove={(event: any, e) => {
            const { target } = e;
            const width = target.getAttribute('width');
            const xPosition = getXPosition(e);
            const xScale = scaleLinear()
              .domain([0, width])
              .range([freqMin, freqMax]);
            const currentFreq = xScale(xPosition);
            const eirp = hittest(
              currentFreq - bandwidth / 2,
              currentFreq + bandwidth / 2,
              eirpRanges
            );

            setCoords({
              x: currentFreq,
              y: eirp || yMin,
            });
            setCentreLine([
              { x: currentFreq, y: yMin },
              { x: currentFreq, y: eirp },
            ]);
            setHighlight([
              { x: currentFreq - bandwidth / 2, y: yMin },
              { x: currentFreq - bandwidth / 2, y: eirp },
              { x: currentFreq + bandwidth / 2, y: eirp },
              { x: currentFreq + bandwidth / 2, y: yMin },
            ]);
          }}
          onMouseLeave={() => {
            setCoords(initHighlightPosition);
            setCentreLine([]);
            setHighlight([]);
          }}
          animate={false}
          layers={['grid', 'axes', 'legends', 'lines', 'areas', 'mesh']}
          gridXValues={xAxisTickValues}
          gridYValues={yAxisTickValues}
          axisBottom={{
            tickRotation: 0,
            tickValues: xAxisTickValues,
            legend: 'Frequency (MHz)',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickRotation: 0,
            tickValues: yAxisTickValues,
            legend: 'Max EIRP (dBm)',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          margin={MARGIN}
          borderWidth={2}
          borderColor={'rgb(86, 133, 214)'}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 80,
              itemsSpacing: 0,
              itemWidth: 140,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'right-to-left',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'square',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
          tooltip={(e) => {
            const currentFreq = coords.x;
            const currentEirp = coords.y;
            const minYvalue: any = 5;
            const maxXvalue: any = freqMax - 150;
            // Calculating the top position based on minYvalue
            const topPosition =
              e.point.data.y <= minYvalue ? '-195px' : '-95px';

            // Calculating the right position based on maxXvalue
            const rightPosition = e.point.data.x >= maxXvalue ? '0' : '';
            const style: any = {
              top: topPosition,
              right: rightPosition,
            };

            if (currentEirp === 0) {
              return <></>;
            } else {
              return (
                <div style={style} className="afc-chart-tooltip">
                  <div>
                    Max EIRP: {currentEirp.toFixed(1)} {' ' + yUnits}
                  </div>
                  <div>
                    Center Frequency: {Math.round(currentFreq)} {' ' + xUnits}
                  </div>
                  <div>
                    Frequency Range: {Math.round(currentFreq - bandwidth / 2)}{' '}
                    {' ' + xUnits} to {Math.round(currentFreq + bandwidth / 2)}{' '}
                    {' ' + xUnits}
                  </div>
                </div>
              );
            }
          }}
        ></ResponsiveLine>
      </div>
    </>
  );
};

const createAfcPanes = (afcProduct: string, afcResponse: any) => {
  if (
    !window?.AFC_PRODUCT_CONFIGS ||
    !afcProduct in window.AFC_PRODUCT_CONFIGS
  ) {
    return <Loader active />;
  }

  const productConfig = window?.AFC_PRODUCT_CONFIGS[afcProduct] ?? {};
  const afcSupportedBandwidths = productConfig?.supportedBandwidths ?? [];

  return afcSupportedBandwidths.map((bandwidth) => ({
    menuItem: {
      id: `${bandwidth}Mhz`,
      content: `${bandwidth} MHz`,
      key: `${afcProduct} - ${bandwidth}Mhz`,
    },
    render: () => (
      <AfcChart
        afcResponse={afcResponse}
        productConfig={productConfig}
        bandwidth={bandwidth}
        key={`${afcProduct} - ${bandwidth}Mhz`}
      />
    ),
  }));
};

const AfcCharts = ({ product, afcResponse }) => {
  const panes = createAfcPanes(product, afcResponse);

  return (
    <Tab
      menu={{ pointing: true }}
      renderActiveOnly
      panes={panes}
      defaultActiveIndex={panes.length - 1}
    />
  );
};

const AfcResultsPanel = ({ product, afcResponse }) => {
  return <AfcCharts product={product} afcResponse={afcResponse} />;
};

export default AfcResultsPanel;
