import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Segment, Header, Loader } from 'semantic-ui-react';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import { has60GHz, runWithConfirmation } from '../../utils/useful_functions';
import { store } from '../../store';
import { postWithAuth } from 'src/api';
import { getMeshLinksColumns } from './mesh_links_columns';
import additionalMessages from '../../messages';
import {
  uiConfirmAction,
  setSelectedMeshLinks,
  panelNeedsRefresh,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import MeshLinkDialog from './MeshLinkDialog';
import debounce from 'lodash/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';
import { useIsMount } from 'src/hooks/useIsMount';

function MeshListPanel(props) {
  const { formatMessage } = props.intl;
  const isMount = useIsMount();
  const [showAttachML, setShowAttachML] = useState(false);
  const {
    projectId,
    projectName,
    meshLinks,
    meshLinksCount,
    accessPoints,
    permissionWrite,
    prefs,
    bulkEditSelectedRows,
  } = useSelector((state) => state.mainFrame);
  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.meshLinksPanel
  );

  const navigate = useNavigate();
  const location = useLocation();

  const tableRef = useRef();
  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  useEffect(() => {
    if (!isMount) {
      tableRef.current?.refresh();
    }
  }, [meshLinksCount]);

  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
      store.dispatch(
        panelNeedsRefresh({ panels: ['meshLinksPanel'], status: false })
      );
    }
  }, [needsRefresh]);

  let items = [];
  if (meshLinks) {
    items = meshLinks.features.map((link) => {
      return link.properties;
    });
  }

  const enableAdd = permissionWrite && has60GHz(accessPoints);

  let deleteAction = {
    onClick: (event, data, { gridApi }) => {
      const selectedRows = gridApi.getSelectedRows();
      const selectedSiteIds = selectedRows.map((row) => row.id);
      deleteHandler(
        formatMessage,
        projectId,
        selectedSiteIds,
        tableRef,
        gridApi
      );
    },
    disabled: true,
  };

  const actions = [
    {
      icon: 'add',
      label: formatMessage(additionalMessages.add),
      onClick: (event, data, params) => {
        setShowAttachML(true);
      },
      disabled: !enableAdd,
      id: 'add',
    },
    { ...deleteAction, ...DELETE_ACTION_TOOLBAR },
  ];

  const defaultColumns = getMeshLinksColumns(prefs, projectId).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );

  const dropdownOptionsData = [];
  for (const { id, name } of items) {
    dropdownOptionsData.push({
      key: id,
      value: id,
      flag: name,
      text: name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  const tableView = (
    <Segment basic style={{ width: '100%' }}>
      {bulkEditSelectedRows.length > 0 && (
        <Header as="h3">
          <Loader active inline size="tiny" />{' '}
          {formatMessage(additionalMessages.calculatingResults)}
        </Header>
      )}
      <div style={{ position: 'relative' }}>
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        <LPGrid
          url={`project/${projectId}/mesh_links`}
          gridRef={tableRef}
          actions={actions}
          callback={(data) => {
            tableRef.current?.api.setRowData(data);
          }}
          addStatusColor={true}
          defaultCsvExportParams={{ fileName: `${projectName}_mesh_links.csv` }}
          columnDefs={defaultColumns}
          enableBrowserTooltips
          getRowStyle={(params) => {
            if (!!params.data.strokeColor) {
              return { color: params.data.strokeColor };
            }
          }}
          table_id="mesh_links_table"
          isTableColConfigure={true}
          getRowNodeId={(data) => {
            return data.id;
          }}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(
              additionalMessages.dropdownPlaceHolderTxt
            ),
            helpText: formatMessage(additionalMessages.copyFromHelp, {
              kind: 'link',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows.map((row) => row.id);
              const smId = data.value;
              const selectedSMName = data.options.find(
                (e) => e.value === data.value
              );
              const qtyAndKind =
                rows.length === 1
                  ? 'selected link'
                  : `${rows.length} selected links`;
              runWithConfirmation({
                message: `${formatMessage(
                  additionalMessages.copyFromConfirmation,
                  { qtyAndKind }
                )} ${selectedSMName.text}`,
                onConfirm: () => {
                  const payload = {
                    ids,
                    copy_id: smId,
                    data: {},
                    bulk: 'copy',
                  };
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  postWithAuth(
                    `project/${projectId}/mesh_links/bulk`,
                    payload,
                    'PATCH'
                  )
                    .then((res) => {
                      //tableRefreshCb();
                    })
                    .catch((err) => {
                      console.log('Bulk update failed', err);
                    });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </div>
    </Segment>
  );

  const deleteHandler = (
    formatMessage,
    projectId,
    selectedPMPItems,
    tableRef,
    gridApi
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteMeshLinks),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          gridApi?.showLoadingOverlay();
          // If any of the ids are for the currently displayed object
          // then we need to navigate away from the page as soon as the object
          // has been removed
          const requiresNavigate = selectedPMPItems.some((id) =>
            location.pathname.includes(id)
          );
          postWithAuth(
            `project/${projectId}/mesh_links`,
            selectedPMPItems,
            'DELETE'
          )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              if (!gridApi) store.dispatch(setSelectedMeshLinks([]));
              if (requiresNavigate) {
                navigate('/mesh');
              }
            })
            .catch((err) => {
              //TODO display error
              console.log('Error', err);
            })
            .finally(() => {
              gridApi?.hideOverlay();
            });
        },
      })
    );
  };

  return (
    <>
      {showAttachML && (
        <MeshLinkDialog
          intl={props.intl}
          onClose={() => setShowAttachML(false)}
        ></MeshLinkDialog>
      )}
      <Header>
        <FormattedMessage id="common.meshLinks" defaultMessage="Mesh Links" />
        {` (${meshLinksCount})`}
      </Header>
      {tableView}
    </>
  );
}

export default injectIntl(MeshListPanel);
