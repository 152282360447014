import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useCSVDownloader } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { Button, Icon, Radio, Table } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import messages from 'src/messages';
import { RootStateOrAny } from 'src/store';

function processResultsTable(results) {
  const table = [];
  let maxSmQty = 0;

  results.forEach((row) => {
    const [tilt, throughput, units, smqty] = row;
    const entry = {
      Tilt: `${tilt.toFixed(1)}\u00b0`,
      'SM Qty': smqty,
      Throughput: `${throughput.toFixed(3)} ${units}`,
    };
    maxSmQty = Math.max(maxSmQty, smqty);
    table.push(entry);
  });

  // Get the max throughput from the rows which have the max SM count
  const throughputs = results.map((row) => {
    const [tilt, throughput, units, smqty] = row;
    if (smqty === maxSmQty) {
      return throughput;
    } else {
      return -9999;
    }
  });
  const maxThroughput = Math.max(...throughputs);

  const matching = results.filter((row) => {
    const [tilt, throughput, units, smqty] = row;
    return smqty === maxSmQty && throughput === maxThroughput;
  });

  // get the mid point of the matching values
  const bestAngle = matching[Math.floor(matching.length / 2)][0];

  return [table, `${bestAngle.toFixed(1)}\u00b0`];
}

function TiltOutputPanel(props) {
  const testResults = useSelector(
    (state: RootStateOrAny) => state.calcTilt.results
  );
  if (!testResults || testResults.length === 0) {
    return null;
  }

  const { id } = useParams();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { setValues, values, panels, intl, applyTiltCallback } = props;
  const { formatMessage } = intl;
  const [resultDict, bestTilt] = processResultsTable(testResults);
  const headers = Object.keys(resultDict[0]);
  const [selectedTilt, setSelectedTilt] = useState(bestTilt);
  const handleRadioButton = (value) => {
    setSelectedTilt(value);
  };
  const CSVDownloader = useCSVDownloader().CSVDownloader;

  const ndName = values.name;

  const stickyHeader = {
    position: 'sticky',
    top: '0',
    zIndex: '1',
  };

  //Logic to scroll the view to best tilt
  const parentDivElement = useRef(null);

  useEffect(() => {
    const tiltElement = document.getElementsByName('bestTilt')[0];
    tiltElement && tiltElement.scrollIntoView();
    const tiltElementRect = tiltElement.getBoundingClientRect();
    const parentDivRect = parentDivElement.current.getBoundingClientRect();
    if (tiltElementRect.y < parentDivRect.y + parentDivRect.height / 2) {
      parentDivElement.current.scrollBy(0, -100);
    }
  }, [bestTilt]);

  function applyTilt() {
    applyTiltCallback(selectedTilt, props.closeHandler);
  }

  return (
    <>
      <div
        style={{
          paddingTop: '2%',
          paddingBottom: '2%',
        }}
      >
        <CSVDownloader
          filename={`${ndName}-Calc-Tilt`}
          bom={true}
          config={{
            delimiter: ',',
          }}
          data={resultDict}
        >
          <Button icon style={{ marginBottom: '4px' }} labelPosition="left">
            <Icon name="cloud download" />
            {formatMessage(messages.downloadCSV)}
          </Button>
        </CSVDownloader>
        <div
          style={{
            overflowY: 'auto',
            maxHeight: '400px',
          }}
          ref={parentDivElement}
        >
          <Table
            celled
            selectable
            compact
            size="small"
            className="ag-theme-alpine"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={stickyHeader} />
                {headers.map((header, index) => (
                  <Table.HeaderCell style={stickyHeader} key={index}>
                    {header}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {resultDict.map((item, idx) => (
                <Table.Row
                  key={item.Tilt}
                  className={idx % 2 !== 0 ? 'ag-row-odd' : 'ag-row-even'}
                >
                  <Table.Cell width={1}>
                    <Radio
                      name={
                        bestTilt === item.Tilt ? 'bestTilt' : 'selectedTilt'
                      }
                      value={item.Tilt}
                      checked={selectedTilt === item.Tilt}
                      onClick={() => handleRadioButton(item.Tilt)}
                      defaultChecked={bestTilt === item.Tilt}
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>{item.Tilt}</Table.Cell>
                  <Table.Cell width={1}>{item['SM Qty']}</Table.Cell>
                  <Table.Cell width={3}>{item.Throughput}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <Button color="blue" onClick={applyTilt}>
        {formatMessage(messages.applyCalcTilt)}
      </Button>
    </>
  );
}
export default injectIntl(TiltOutputPanel);
