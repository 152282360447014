import { postWithAuth } from 'src/api';
import {
  ANTENNA_TYPES,
  DIAMETER_UNITS,
  POLARIZATION_OPTIONS,
} from 'src/app.constants';
import {
  VALID_PTP_CUSTOM_ANTENNA_BANDS,
  VALID_SM_CUSTOM_ANTENNA_BANDS,
} from 'src/model/BandDefinition';

export const defaultValues: AntennaModal = {
  manufacturer: '',
  diameter: null,
  diameter_units: 'feet',
  description: '',
  antenna_type: 'Other',
  part_number: '',
  beamwidth: 0.0,
  gains: {},
  polarization: 'Dual',
};

export const PTP_ANTENNA = 'ptp';
export const SM_ANTENNA = 'sm';

export const getAntennaFormBands = (kind): string[] => {
  if (kind === 'ptp') {
    return VALID_PTP_CUSTOM_ANTENNA_BANDS;
  } else {
    return VALID_SM_CUSTOM_ANTENNA_BANDS;
  }
};

export type AntennaModal = {
  manufacturer: string;
  diameter: any;
  diameter_units: (typeof DIAMETER_UNITS)[number];
  description: string;
  antenna_type: (typeof ANTENNA_TYPES)[number];
  part_number: string;
  polarization: (typeof POLARIZATION_OPTIONS)[number];
  gains: {};
  beamwidth?: number;
};

function getPayload(formData, kind) {
  const payload: AntennaModal = {
    manufacturer: formData.manufacturer,
    description: formData.description,
    part_number: formData.part_number,
    diameter: formData.diameter,
    diameter_units: formData.diameter_units,
    antenna_type: formData.antenna_type,
    polarization: formData.polarization,
    gains: formData.gains,
  };

  if (kind === SM_ANTENNA) {
    payload['beamwidth'] = Number(formData.beamwidth);
  }

  return payload;
}

function getUrl(kind, projectId, antennaId) {
  let url = '';
  if (antennaId) {
    if (kind === PTP_ANTENNA) {
      url = `project/${projectId}/antennas/ptp/${antennaId}`;
    } else {
      url = `project/${projectId}/antennas/subscribers/${antennaId}`;
    }
  } else {
    if (kind === PTP_ANTENNA) {
      url = `project/${projectId}/antennas/ptp`;
    } else {
      url = `project/${projectId}/antennas/subscriber`;
    }
  }
  return url;
}

export function createCustomAntenna(
  projectId,
  formData: AntennaModal,
  kind: 'ptp' | 'sm'
) {
  const url = getUrl(kind, projectId, null);
  const payload = getPayload(formData, kind);
  return postWithAuth(url, payload, 'POST');
}

export function updateCustomAntenna(
  projectId,
  antennaId,
  formData: AntennaModal,
  kind: 'ptp' | 'sm'
) {
  const url = getUrl(kind, projectId, antennaId);
  const payload = getPayload(formData, kind);
  return postWithAuth(url, payload, 'PATCH');
}

export function deleteCustomAntenna(projectId, antennaId, kind) {
  const url = getUrl(kind, projectId, antennaId);
  return postWithAuth(url, '{}', 'DELETE');
}
