import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Segment, Loader, Message } from 'semantic-ui-react';
import { runWithConfirmation } from '../../utils/useful_functions';
import additionalMessages from '../../messages';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import { store } from '../../store';
import { fetchAccessPoints } from './pmp.reducer';
import {
  uiConfirmAction,
  panelNeedsRefresh,
  bulkUpdateRowRemove,
  setBulkEditSelectedRows,
} from '../mainframe/mainframe.reducer';
import { postWithAuth, getWithAuth } from 'src/api';
import CreateAccessPointModal from './create-access-points/CreateAccessPointModal';
import messages from '../../messages';
import debounce from 'lodash/debounce';
import {
  getAccessPointColumns,
  getAdditionalAccessPointColumns,
} from './pmp_nd_columns';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';
import { useIsMount } from 'src/hooks/useIsMount';

const AccessPointsPanel = (props) => {
  const { formatMessage } = props.intl;
  const isMount = useIsMount();
  const accessPoints = useSelector(
    (state) =>
      state.mainFrame.accessPoints?.features.map((i) => i.properties) || []
  );
  const {
    projectId,
    projectName,
    permissionWrite,
    networkSites,
    networkSiteCount,
    accessPointsCount,
    bulkEditSelectedRows,
    userLimits,
  } = useSelector((state) => state.mainFrame);
  const tableRef = useRef();
  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.pmpNDPanel
  );

  useEffect(() => {
    if (!isMount) {
      tableRefreshCb();
    }
  }, [accessPointsCount]);

  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
      store.dispatch(
        panelNeedsRefresh({ panels: ['pmpNDPanel'], status: false })
      );
    }
  }, [needsRefresh]);

  useEffect(() => {
    if (bulkEditSelectedRows.length) {
      bulkEditSelectedRows.forEach((data) => {
        if (data.updated) {
          tableRef.current?.api
            ?.getRowNode(data.id)
            ?.setData(JSON.parse(JSON.stringify(data)));
          store.dispatch(bulkUpdateRowRemove(data));
        }
      });
    }
  }, [bulkEditSelectedRows]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  let addWarning = '';
  if (accessPointsCount >= userLimits?.pmp_network_devices) {
    addWarning = formatMessage(messages.maximumNumberError, {
      entityName: 'PMP Network Device',
      limit: userLimits?.pmp_network_devices,
    });
  }
  const enableAdd =
    permissionWrite && addWarning === '' && networkSiteCount > 0;

  const deleteSelection = (
    formatMessage,
    projectId,
    selectedItems,
    tableRef
  ) => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(additionalMessages.deleteAccessPoints),
        message: formatMessage(additionalMessages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(
            `project/${projectId}/access_points`,
            selectedItems,
            'DELETE'
          )
            .then((res) => {
              if (tableRef) {
                tableRef.current?.refresh();
              }
              if (res) {
                store.dispatch(fetchAccessPoints(projectId));
                setSelectedItems([]);
              } else {
                console.warning('Failed to delete access points');
              }
            })
            .catch(console.error);
        },
      })
    );
  };

  const actions = [
    {
      icon: 'add',
      label: formatMessage(messages.add),
      onClick: (event, data, params) => {
        setShowCreateModal(true);
      },
      disabled: !enableAdd,
      id: 'add',
    },
    {
      icon: 'trash alternate',
      label: formatMessage(messages.delete),
      onClick: (event, data, params) => {
        const selectedRows = params.gridApi.getSelectedRows();
        const selectedSiteIds = selectedRows
          .filter((row) => row.radio_number === null || row.radio_number === 1)
          .map((row) => row.id);
        deleteSelection(formatMessage, projectId, selectedSiteIds, tableRef);
      },
      disabled: true,
      ...DELETE_ACTION_TOOLBAR,
    },
  ];

  const defaultColumns = getAccessPointColumns(projectId).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );

  const additionalColumns = getAdditionalAccessPointColumns(projectId);
  const dropdownOptionsData = [];
  for (const { id, name } of accessPoints) {
    dropdownOptionsData.push({
      key: id,
      value: id,
      flag: id,
      text: name,
    });
  }
  dropdownOptionsData?.sort((a, b) => (a.text > b.text ? 1 : -1));
  const tableView = (
    <Segment basic style={{ width: '100%' }}>
      {bulkEditSelectedRows.length > 0 && (
        <Header as="h3">
          <Loader active inline size="tiny" />{' '}
          {formatMessage(messages.calculatingResults)}
        </Header>
      )}
      <div style={{ position: 'relative' }}>
        <div
          className="overlay"
          style={{
            display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
          }}
        ></div>
        {addWarning && <Message warning>{addWarning}</Message>}
        <LPGrid
          url={`project/${projectId}/access_points`}
          gridRef={tableRef}
          addStatusColor={true}
          actions={actions}
          //   refreshOn={needsRefresh}
          defaultCsvExportParams={{
            fileName: `${projectName}_pmp_network_devices.csv`,
          }}
          getRowNodeId={(data) => {
            return `${data.id}-${data.radio_number}`;
          }}
          columnDefs={[...defaultColumns, ...additionalColumns]}
          enableBrowserTooltips
          table_id="pmp_nd_table"
          isTableColConfigure={true}
          dropdownConfig={{
            optionsData: dropdownOptionsData,
            placeHolderTxt: formatMessage(
              additionalMessages.dropdownPlaceHolderTxt
            ),
            helpText: formatMessage(additionalMessages.copyFromHelp, {
              kind: 'PMP network device',
            }),
            changeHandler: (event, data, gridApi, setDropdownValue) => {
              const rows = gridApi.getSelectedRows();
              const ids = rows
                .filter(
                  (row) => row.radio_number === null || row.radio_number === 1
                )
                .map((row) => row.id);
              if (ids.length < 1) {
                return;
              }
              const apId = data.value;
              const selectedNDName = data.options.find(
                (e) => e.value === data.value
              );

              const qtyAndKind =
                rows.length === 1
                  ? 'selected PMP network device'
                  : `${rows.length} selected PMP network devices`;
              runWithConfirmation({
                message: `${formatMessage(
                  additionalMessages.copyFromConfirmation,
                  { qtyAndKind }
                )} ${selectedNDName.text}`,
                onConfirm: () => {
                  const url = `project/${projectId}/access_point/${apId}`;
                  rows.forEach((row) => {
                    row.updated = false;
                  });
                  store.dispatch(
                    setBulkEditSelectedRows(JSON.parse(JSON.stringify(rows)))
                  );
                  getWithAuth(url)
                    .then((apData) => {
                      const payload = {
                        ids,
                        data: apData,
                        bulk: 'copy',
                      };
                      postWithAuth(
                        `project/${projectId}/access_points/bulk`,
                        payload,
                        'PATCH'
                      )
                        .then((res) => {
                          //tableRefreshCb();
                        })
                        .catch((err) => {
                          console.log('Bulk update failed', err);
                        });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                },
                onCancel: () => {
                  setDropdownValue(null);
                },
              });
            },
          }}
        ></LPGrid>
      </div>
    </Segment>
  );

  return (
    <>
      {showCreateModal && (
        <CreateAccessPointModal
          showModal={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          projectId={projectId}
          intl={props.intl}
        />
      )}
      <Header>
        <FormattedMessage
          id="common.accessPoints"
          defaultMessage="PMP Network Devices"
        />
        {` (${accessPointsCount})`}
      </Header>
      {tableView}
    </>
  );
};

export default injectIntl(AccessPointsPanel);
