import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { postWithAuth, getWithAuth } from 'src/api';
import { store } from 'src/store';
import { uiConfirmAction } from '../../mainframe/mainframe.reducer';
import messages from 'src/messages';
import { useIntl } from 'react-intl';
import NetworkSiteBomModal from './NetworkSiteBomModal';
import { downloadReport } from 'src/utils/useful_functions';
const plusOffset = {
  position: 'relative',
  left: '8px',
  top: '-4px',
};

const iconMargin = {
  marginLeft: '6px',
  marginRight: '6px',
  marginTop: '-1px',
  marginBottom: '-1px',
};

function NetworkSiteToolbar(props) {
  const {
    hideButton,
    hasTowerSwitch,
    setHasTowerSwitch,
    graph,
    siteId,
    currentSite,
    projectId,
  } = props;
  const { formatMessage } = useIntl();
  const permissionWrite = useSelector(
    (state) => state.mainFrame.permissionWrite
  );
  const [installReportLoading, setInstallReportLoading] = useState(false);
  const [salesReportLoading, setSalesReportLoading] = useState(false);
  const [listViewShowTowerSwitch, setListViewShowTowerSwitch] = useState({
    status: false,
    state: '',
  });

  return (
    <>
      {listViewShowTowerSwitch.status && (
        <NetworkSiteBomModal
          objId={siteId}
          kind="network_site"
          url={`project/${projectId}/site/${siteId}/bom`}
          tsurl={`project/${projectId}/site/${siteId}/tower_switch`}
          listViewShowTowerSwitch={listViewShowTowerSwitch}
          listViewResetShowTowerSwitch={(value) => {
            setListViewShowTowerSwitch({ status: value, state: '' });
            setHasTowerSwitch(true);
          }}
        />
      )}

      {hasTowerSwitch ? (
        <Button
          icon
          basic
          compact
          title="Edit the tower switch configuration"
          disabled={!permissionWrite}
          onClick={(event, data) => {
            setListViewShowTowerSwitch({ status: true, state: 'edit' });
          }}
        >
          <Icon.Group size="large" style={iconMargin}>
            <Icon name="pencil" size="tiny" style={plusOffset} color="blue" />
            <Icon className="tower-switch-icon" />
          </Icon.Group>
        </Button>
      ) : (
        <Button
          icon
          basic
          compact
          title="Add a tower switch to this site"
          disabled={!permissionWrite}
          onClick={(event, data) => {
            setListViewShowTowerSwitch({ status: true, state: 'add' });
          }}
        >
          <Icon.Group size="large" style={iconMargin}>
            <Icon name="plus" size="tiny" style={plusOffset} color="blue" />
            <Icon className="tower-switch-icon" />
          </Icon.Group>
        </Button>
      )}
      <Button
        icon
        basic
        compact
        title="Delete the tower switch from this site"
        onClick={(event, data) => {
          store.dispatch(
            uiConfirmAction({
              header: 'Delete Tower Switch',
              message: 'Are you sure?',
              size: 'mini',
              onConfirm: () => {
                postWithAuth(
                  `project/${projectId}/site/${currentSite.id}/tower_switch`,
                  {},
                  'DELETE'
                )
                  .then(() => {
                    setListViewShowTowerSwitch({
                      status: false,
                      state: 'delete',
                    });
                    setHasTowerSwitch(false);
                  })
                  .catch((err) => {});
              },
            })
          );
        }}
        disabled={!hasTowerSwitch || !permissionWrite}
      >
        <Icon.Group size="large" style={iconMargin}>
          <Icon name="delete" size="tiny" style={plusOffset} color="blue" />
          <Icon className="tower-switch-icon" />
        </Icon.Group>
      </Button>
      <NetworkSiteBomModal
        objId={siteId}
        kind="network_site"
        url={`project/${projectId}/site/${siteId}/bom`}
        disabled={hideButton}
        name={currentSite?.name}
      />
      <Button
        icon
        basic
        compact
        loading={salesReportLoading}
        title={`PMP ${formatMessage(messages.salesReport)}`}
        disabled={graph.aps?.length === 0 || hideButton}
        onClick={() => {
          setSalesReportLoading(true);
          getWithAuth(`project/${projectId}/reports/site/${siteId}/proposal`)
            .then((repId) => {
              downloadReport(
                getWithAuth,
                projectId,
                repId,
                `Site_${currentSite?.name}_PMP_Proposal_Report`
              ).finally(() => {
                setSalesReportLoading(false);
              });
            })
            .catch(() => {
              setSalesReportLoading(false);
            });
        }}
      >
        <Icon className="proposal-report-icon" size="large" />
      </Button>
      <Button
        icon
        basic
        compact
        loading={installReportLoading}
        title={`PMP ${formatMessage(messages.installationReport)}`}
        disabled={graph.aps?.length === 0 || hideButton}
        onClick={() => {
          setInstallReportLoading(true);
          getWithAuth(
            `project/${projectId}/reports/site/${siteId}/installation`
          )
            .then((repId) => {
              downloadReport(
                getWithAuth,
                projectId,
                repId,
                `Site_${currentSite?.name}_PMP_Installation_Report`
              ).finally(() => {
                setInstallReportLoading(false);
              });
            })
            .catch(() => {
              setInstallReportLoading(false);
            });
        }}
      >
        <Icon className="installation-report-icon" size="large" />
      </Button>
    </>
  );
}

export default NetworkSiteToolbar;
