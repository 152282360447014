import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { range } from 'lodash';
import { injectIntl } from 'react-intl';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { anyToMeter } from 'src/utils/useful_functions';
import { updatePoints } from './profile.reducer';
import { interpolate } from './ProfileEditor';
import messages from 'src/messages';

const toRangeStr = (rng) => {
  return Math.round(rng).toFixed(3);
};

const INTERVAL_CHOICES = range(1, 16).map((num) => {
  const numAsRange = (num / 1000).toFixed(3);
  return { key: numAsRange, text: numAsRange, value: numAsRange };
});

const addPointsAtIntervalHandler = (
  stepIntervalRange: string,
  ranges: number[],
  heights: number[],
  clutter_types: string[],
  obstructions: number[],
  rangeUnits
) => {
  const endIdx = ranges.length - 1;
  const endRange = ranges[endIdx];
  const stepInMeters = anyToMeter(Number(stepIntervalRange), rangeUnits, 3);
  // Display range values to 3 decimal places
  const currentRanges = ranges.map(toRangeStr);
  const newRanges = [];
  const newHeights = [];
  const newClutterTypes = [];
  const newObstructions = [];
  let previousIdx = 0;
  let currentRange = 0;
  let nextIdx = 0;

  while (currentRange < endRange) {
    // use 3 decimal places to check to see if the point already exists
    // if the point is exact match with currentrange or the point is less than the currentrange
    const rangeStr = toRangeStr(currentRange);
    if (
      currentRanges.includes(rangeStr) ||
      ranges[nextIdx] - currentRange < 0
    ) {
      // If a point already exists at this range then
      // use the existing point rather than creating
      // a new point at this position
      newRanges.push(ranges[nextIdx]);
      newHeights.push(heights[nextIdx]);
      newClutterTypes.push(clutter_types[nextIdx]);
      newObstructions.push(obstructions[nextIdx]);
      previousIdx = nextIdx;
      nextIdx += 1;
      //if the exact point matches then need to skip below statements to execute
      if (currentRanges.includes(rangeStr)) {
        currentRange += stepInMeters;
        continue;
      }
      // checking  is next range value is still less thank the currentrange then insert range value into newrange array till it greaterthan or equals to
      if (ranges[nextIdx] - currentRange < 0) {
        continue;
      }
    }
    newRanges.push(currentRange);
    // interpolate between the values for terrain heights
    newHeights.push(
      interpolate(
        ranges[previousIdx],
        heights[previousIdx],
        ranges[nextIdx],
        heights[nextIdx],
        currentRange
      )
    );
    // if it is closer to the previous point then use the clutter
    // and obstruction from the previous point otherwise use
    // the values from the next point
    if (currentRange - ranges[previousIdx] <= ranges[nextIdx] - currentRange) {
      newClutterTypes.push(clutter_types[previousIdx]);
      newObstructions.push(obstructions[previousIdx]);
    } else {
      newClutterTypes.push(clutter_types[nextIdx]);
      newObstructions.push(obstructions[nextIdx]);
    }
    currentRange += stepInMeters;
  }
  // pushing rest/left out points
  while (nextIdx <= endIdx) {
    newRanges.push(ranges[nextIdx]);
    newHeights.push(heights[nextIdx]);
    newClutterTypes.push(clutter_types[nextIdx]);
    newObstructions.push(obstructions[nextIdx]);
    nextIdx += 1;
  }

  return {
    ranges: newRanges,
    heights: newHeights,
    clutter_types: newClutterTypes,
    obstructions: newObstructions,
  };
};

type AddNewPointsAtIntervalProps = {
  setUpdated: Function;
  intl: any;
  addPtsAtInrModalOpen: boolean;
  setAddPtsAtInrModalOpen: Function;
};

function AddNewPointsAtInterval({
  setUpdated,
  intl,
  addPtsAtInrModalOpen,
  setAddPtsAtInrModalOpen,
}: AddNewPointsAtIntervalProps) {
  const { prefs } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const profile = useSelector((state: RootStateOrAny) => state.profile);
  const [stepInterval, setStepInterval] = useState();

  const { rangeUnits } = prefs;
  const { formatMessage } = intl;

  const dispatch = useDispatch();

  useEffect(() => {
    if (addPtsAtInrModalOpen) {
      setStepInterval(INTERVAL_CHOICES[0].value);
    }
  }, [addPtsAtInrModalOpen]);

  const onClickOK = () => {
    const { ranges, heights, clutter_types, obstructions } =
      addPointsAtIntervalHandler(
        stepInterval,
        profile.ranges,
        profile.heights,
        profile.clutter_types,
        profile.obstructions,
        rangeUnits
      );
    dispatch(
      updatePoints({
        ...profile,
        ranges,
        heights,
        clutter_types,
        obstructions,
      })
    );
    setUpdated({ status: true });
    onCloseHandler();
  };

  const onCloseHandler = () => {
    setAddPtsAtInrModalOpen(false)
  };

  return (
    <>
      <Modal
        open={addPtsAtInrModalOpen}
        onClose={onCloseHandler}
        style={{ width: '20em' }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="pointsAtInterval"
      >
        <Modal.Header>
          Points at Interval
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={onCloseHandler}
          />
        </Modal.Header>
        <Modal.Content>
          <div
            style={{ marginBottom: '10px' }}
          >{`Point Interval (${rangeUnits}.)`}</div>
          <Dropdown
            placeholder="Select an interval"
            fluid
            selection
            options={INTERVAL_CHOICES}
            value={stepInterval}
            onChange={(e, { value }) => setStepInterval(value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseHandler}>
            {formatMessage(messages.cancel)}
          </Button>
          <Button color="blue" disabled={false} onClick={onClickOK}>
            {formatMessage(messages.ok)}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default injectIntl(AddNewPointsAtInterval);
