import React, { Component } from 'react';
import {
  Input,
  TextArea,
  Form,
  Accordion,
  Loader,
  Select,
  Button,
} from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { crudSaveChanges } from '../../actions';
import { store } from '../../store';
import additionalMessages from '../../messages';
import { uiSet } from '../mainframe/mainframe.reducer';
import PermissionsPanel from './PermissionsPanel';
import messages from '../../messages';
import DisplayClutterProperties from './DisplayClutterProperties';
import { convertObjectKeys, toSnake } from 'src/utils/change-case';
import { PREDICTION_MODEL_OPTIONS } from 'src/app.constants';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import { DESCRIPTION_MAX_LENGTH } from 'src/app.constants';
import StoredAccordion from 'src/components/StoredAccordion';
import { PanelHeading } from 'src/components/PanelHeading';

/**
 * Project panel
 */
class ProjectPanelContainer extends Component {
  state = {
    modified: false,
    initialFormValues: null,
    activePanels: [0],
    reportLoading: false,
  };

  componentDidMount() {
    // Store initial form values when the component mounts
    // which will be used to reset the form
    if (!this.state.modified) {
      const { generalProjectProps, useClutter, predictionModel } = this.props;

      this.setState({
        initialFormValues: {
          useClutter,
          predictionModel,
          generalProjectProps,
        },
      });
    }
  }

  onBlur = (e) => {
    //this.saveChange();
  };

  onKey = (e) => {
    if (e.key === 'Enter') {
      //this.saveChange();
    }
  };

  saveChange = () => {
    if (this.state.modified) {
      store.dispatch(
        crudSaveChanges({
          kind: 'project',
          options: { projectId: this.props.projectId },
          data: {
            data: convertObjectKeys(this.props.generalProjectProps, toSnake),
            use_clutter: this.props.useClutter,
            prediction_model: this.props.predictionModel,
          },
        })
      );
      this.setState({ modified: false });
    }
  };

  onChange = (e, { name, value, checked }) => {
    if (!this.props.permissionWrite) {
      return;
    }
    const generalProperties = [
      'customerName',
      'companyName',
      'address',
      'phone',
      'cellPhone',
      'email',
      'description',
    ];
    if (generalProperties.includes(name)) {
      store.dispatch(
        uiSet({
          generalProjectProps: {
            ...this.props.generalProjectProps,
            ...{
              [name]: value ? value : checked,
            },
          },
        })
      );
    } else {
      store.dispatch(
        uiSet({
          [name]: value ? value : checked,
        })
      );
    }
    this.setState({ modified: true });
  };

  resetForm = () => {
    if (!this.props.permissionWrite || !this.state.modified) {
      return;
    }

    const { generalProjectProps, useClutter, predictionModel } =
      this.state.initialFormValues;

    store.dispatch(
      uiSet({
        generalProjectProps: convertObjectKeys(
          {
            ...this.props.generalProjectProps,
            ...generalProjectProps,
          },
          toSnake
        ),
        useClutter,
        predictionModel,
      })
    );

    this.setState({ modified: false });
  };

  render = () => {
    const props = this.props;
    const { projectName, useClutter, predictionModel, generalProjectProps } =
      props;
    const { activePanels } = this.state;
    // we can add check for any property(useClutter, generalProjectProps) to show loader
    if (!predictionModel) {
      return <Loader active inline />;
    }

    const {
      customerName,
      companyName,
      address,
      email,
      phone,
      cellPhone,
      description,
    } = generalProjectProps;
    const { formatMessage } = props.intl;

    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PanelHeading
          title={
            <>
              <FormattedMessage id="common.project" defaultMessage="Project" />:{' '}
              {projectName}
            </>
          }
          toolbar={
            <Button
              compact
              color="blue"
              data-testid="saveProjectDetails"
              disabled={!this.state.modified}
              onClick={this.saveChange}
              accesskey="a"
            >
              {formatMessage(additionalMessages.apply)}
            </Button>
          }
        />
        <Form style={{ flex: '1 1 auto', overflowY: 'auto', maxWidth: '40vw' }}>
          <Accordion exclusive={false} fluid className="project-details-panels">
            <StoredAccordion
              name="general properties"
              title={
                <FormattedMessage
                  id="common.generalProperties"
                  defaultMessage="General Properties"
                />
              }
            >
              <Form.Group>
                <Form.Field
                  name="customerName"
                  control={Input}
                  label={formatMessage(additionalMessages.customerName)}
                  value={customerName ? customerName : ''}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyPress={this.onKey}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  minLength={1}
                  maxLength={60}
                />

                <Form.Field
                  name="companyName"
                  control={Input}
                  label={formatMessage(additionalMessages.companyName)}
                  value={companyName ? companyName : ''}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyPress={this.onKey}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  minLength={1}
                  maxLength={60}
                />
              </Form.Group>
              <Form.Field
                name="address"
                control={TextArea}
                label={formatMessage(additionalMessages.address)}
                value={address ? address : ''}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onKeyPress={this.onKey}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
              />
              <Form.Group>
                <Form.Field
                  name="email"
                  control={Input}
                  label={formatMessage(additionalMessages.email)}
                  value={email ? email : ''}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyPress={this.onKey}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  minLength={1}
                  maxLength={60}
                />
                <Form.Field
                  name="phone"
                  control={Input}
                  label={formatMessage(additionalMessages.phone)}
                  value={phone ? phone : ''}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyPress={this.onKey}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  minLength={1}
                  maxLength={60}
                />
                <Form.Field
                  name="cellPhone"
                  control={Input}
                  label={formatMessage(additionalMessages.cellPhone)}
                  value={cellPhone ? cellPhone : ''}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyPress={this.onKey}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  minLength={1}
                  maxLength={60}
                />
              </Form.Group>

              <Form.Field
                name="description"
                control={TextArea}
                label={formatMessage(additionalMessages.description)}
                value={description ? description : ''}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onKeyPress={this.onKey}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                maxLength={DESCRIPTION_MAX_LENGTH}
              />
            </StoredAccordion>
            <StoredAccordion
              name="project properties"
              title={
                <FormattedMessage
                  id="projectDetails.projectProperties"
                  defaultMessage="Project Properties"
                />
              }
            >
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  name="predictionModel"
                  value={predictionModel}
                  disabled={!this.props.permissionWrite}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  label={formatMessage(additionalMessages.predictionModel)}
                  options={PREDICTION_MODEL_OPTIONS}
                  placeholder={formatMessage(
                    additionalMessages.predictionModel
                  )}
                />
                <Form.Checkbox
                  name="useClutter"
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  checked={useClutter}
                  style={{ marginTop: '22px' }}
                  label={formatMessage(additionalMessages.useClutter)}
                />
              </Form.Group>
            </StoredAccordion>
            {useClutter && (
              <>
                <StoredAccordion
                  name="clutter"
                  title={formatMessage(messages.clutterHeights)}
                >
                  <DisplayClutterProperties></DisplayClutterProperties>
                </StoredAccordion>
              </>
            )}
            <StoredAccordion
              name="permissions"
              title={
                <FormattedMessage
                  id="common.permissions"
                  defaultMessage="Permissions"
                />
              }
            >
              <PermissionsPanel />
            </StoredAccordion>
          </Accordion>
        </Form>
        <RouteLeavingGuard
          when={this.state.modified}
          shouldBlockNavigation={() => {
            if (this.state.modified) {
              return true;
            }
            return false;
          }}
          yes="Yes"
          no="No"
          title={`${formatMessage(additionalMessages.project)}: ${projectName}`}
          content={formatMessage(additionalMessages.warning)}
          callback={this.resetForm}
        />
      </div>
    );
  };
}

const ProjectPanel = connect((state) => {
  return {
    projectName: state.mainFrame.projectName,
    useClutter: state.mainFrame.useClutter,
    predictionModel: state.mainFrame.predictionModel,
    generalProjectProps: state.mainFrame.generalProjectProps,
    projectId: state.mainFrame.projectId,
    permissionWrite: state.mainFrame.permissionWrite,
  };
})(ProjectPanelContainer);

export default injectIntl(ProjectPanel);
