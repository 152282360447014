import { featureCollection } from '@turf/helpers';
import { feature as turfFeature } from '@turf/helpers';
import geojsonvt from 'geojson-vt';

/**
 * Data layers for each object type.
 * The order of this list controls the Z order
 * of the features in the map
 */
export const FEATURE_LAYERS = [
  'access_point',
  'pmp_link',
  'ptp_link',
  'mesh_link',
  'subscriber_site',
  'network_site',
  'viewshed',
];

// The user can overload the default items per
// tile by setting "cn.lp.MAP_ITEMS_PER_TILE" in localStorage
export const MAP_ITEMS_PER_TILE =
  parseInt(localStorage.getItem('cn.lp.MAP_ITEMS_PER_TILE'), 10) || 9999;
// Allow the user to expand the items in a cluster
// item if the cluster count <= limit
export const MAP_CLUSTER_EXPAND_LIMIT =
  parseInt(localStorage.getItem('cn.lp.MAP_CLUSTER_EXPAND_LIMIT'), 10) || 500;
// Zoom level used to control when the network site labels appear
export const MAP_LABEL_ZOOM =
  parseInt(localStorage.getItem('cn.lp.MAP_LABEL_ZOOM'), 10) || 12;

/**
 * Return featureCollection for the tile
 *
 * If the number of features on the tile is greater than
 * the MAP_ITEMS_PER_TILE setting then the features
 * will be clustered.
 */
export const clusterFeatures = (features, zoom) => {
  const count = features.length;
  if (count > 0) {
    const kind = features[0].properties.kind;
    let featureArray = features;
    if (count > MAP_ITEMS_PER_TILE && zoom < 16) {
      // Use cluster
      const massCenter = centerOfMass(features);
      //   const massCenter = turfFeature({
      //     type: 'Point',
      //     coordinates: features[0].geometry.coordinates,
      //   });
      massCenter.properties.count = count;
      massCenter.properties.kind = kind;
      massCenter.properties.collapsedFeatures = features;
      // Force the cluster to have an ID
      // so that we can remove it when the user
      // zooms
      massCenter.id = `${kind}-${count}-${zoom}`;
      featureArray = [massCenter];
    }

    return featureArray;
  }
};

/**
 * Return the tile index and feature object that is used for the map canvas.
 *
 * The tile index and features object are used
 * to calculate the visible objects for each
 * map tile.
 */
export const createTileIndex = (
  networkSites,
  subscriberSites,
  ptpLinks,
  accessPoints,
  meshLinks,
  pmpLinks
) => {
  let tileIndex = null;
  let features = {};
  if (networkSites || subscriberSites) {
    const allFeatures = featureCollection(
      networkSites.features.concat(
        subscriberSites.features,
        ptpLinks.features,
        pmpLinks.features,
        meshLinks.features,
        accessPoints.features
      )
    );
    tileIndex = geojsonvt(allFeatures, {
      maxZoom: 21,
    });
    allFeatures.features.forEach(function (e) {
      features[e.id] = e;
    });
  }
  return [tileIndex, features];
};

/**
 * Calculate the center of the place feature array
 *
 * @param {feature[]} features  - Array of GeoJSON point features
 *
 * This is to replace the @turf/center-of-mass dependency
 * which has a bug in one of its dependencies, concaveman.
 * The bug is fixed in
 * "concaveman": "git+https://github.com/grassick/concaveman.git#05682dcd7c21ef09f391e814d0f5df7cfbc3bbae"
 * but if we do this ourselves then we can reduce a bunch of
 * unnecessary code.
 *
 * This does not work with empty arrays.
 */
export const centerOfMass = (features) => {
  // Assumes that the coordinates do not cross the date line since
  // all of the features are on a single tile.

  // TODO: Update to work with APs and Links
  let latSum = 0;
  let lngSum = 0;
  const numCoordinates = features.length;
  features.forEach((f) => {
    let lng = 0;
    let lat = 0;
    if (f.geometry.type === 'LineString') {
      // Link
      [lng, lat] = centerPoint(f.geometry.coordinates);
    } else if (f.geometry.coordinates.length === 2) {
      // site
      [lng, lat] = f.geometry.coordinates;
    } else {
      // Access Point
      [lng, lat] = centerPoint(f.geometry.coordinates[0]);
    }
    latSum += lat;
    lngSum += lng;
  });
  const newLat = latSum / numCoordinates;
  const newLng = (lngSum / numCoordinates) % 360;
  return turfFeature({
    type: 'Point',
    coordinates: [newLng, newLat],
  });
};

/**
 * Return the [lng, lat] center point for an array of coordinates.
 *
 * coordinates {[lng, lat]}  - Array of coordinates for a feature polygon.
 */
const centerPoint = (coordinates) => {
  let latSum = 0;
  let lngSum = 0;
  const numCoordinates = coordinates.length;
  coordinates.forEach((point) => {
    const [lng, lat] = point;
    latSum += lat;
    lngSum += lng;
  });
  const newLat = latSum / numCoordinates;
  const newLng = (lngSum / numCoordinates) % 360;

  return [newLng, newLat];
};
