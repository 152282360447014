import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { filterAndSortAntennas } from 'src/pages/antennas/antenna-utils';
import { meterToAny, range, round } from 'src/utils/useful_functions';
import LimitControl from '../LimitControl';
import {
  PTPChoices,
  PTPFormField,
  PTPParams,
  PTPShowProps,
  defaultLkShared,
  isChoicesComplex,
} from '../utils';
import AntennaSelection from 'src/pages/equipment/common/AntennaSelection';
import { isNumber } from 'lodash';
import {
  getAntennasFromChoices,
  getEquipmentFromChoices,
  getEndFromChoices,
} from '../utils';
import { store } from 'src/store';
import { CUSTOM_ANTENNA_OTHER } from 'src/app.constants';
import {
  reset,
  setAntennaPolarities,
} from 'src/pages/antennas/antenna.reducer';

export function genericLkRadioGetters(endName: string, n: number, keyedPaths: Record<string, string>) {
  return Object.fromEntries(Object.entries(keyedPaths).map(([attr, path]) => (
    [attr, range(n).map((i) => `${endName}.radios.${i}.${path}`)]
  )));
}

export function genericLkAntennaGetters(endName, n, keyedPaths) {
  return Object.fromEntries(Object.entries(keyedPaths).map(([attr, path]) => (
    [attr, range(n).map((i) => `${endName}.radios.${i}.antennas.0.${path}`)]
  )));
}

function genericHotStandbySync(form, getter, setter, attr) {
  return (endName, to, from_) => {
    setter(
      form,
      `${endName}.radios.${to}.antennas.0.${attr}`,
      getter(form, `${endName}.radios.${from_}.antennas.0.${attr}`),
    );
  };
}

type ExtraAntennaOptions = {
  label: string;
  show: any;
  getter: string;
};

export function genericAntennaControl(endName: string, extraOpts?: ExtraAntennaOptions) {
  let label;
  let show;
  let getter;
  let key;

  if (extraOpts) {
    label = extraOpts.label;
    show = extraOpts.show;
    getter = extraOpts.getter;
    // use the label otherwise it wont rerender the first antenna correctly
    key = `_${label}`;
  } else {
    label = 'Antenna';
    getter = `${endName}.radios.0.antennas.0.lp_antenna_id`;
    key = '';
    // no 'show' required
  }

  return {
    attrName: `${endName}_antenna${key}`,
    getter,
    refreshesChoices: true,
    gridArea: 'antenna',
    component: AntennaSelection,
    componentProps: ({ choices, formGetter }) => {
      // Copy the antenna array so that we
      // don't modify the original array if we insert
      // the custom antenna "Other..." option
      const antennas = [...getAntennasFromChoices(choices, endName)];
      const supported_polarities = getEndFromChoices(
        choices,
        endName
      ).supported_polarities;
      let end = isChoicesComplex(choices)
        ? choices.paths[0][endName].end
        : choices[endName].end;

      if (end.allow_custom_antennas) {
        antennas.push(CUSTOM_ANTENNA_OTHER);
        store.dispatch(
          setAntennaPolarities({
            polarities: supported_polarities,
          })
        );
      }

      const endChoices = getEndFromChoices(choices, endName);
      return {
        choices: antennas,
        kind: 'ptp',
        cssClass: endName === 'remote' ? 'right-align' : '',
        value: formGetter(getter),
        antennaProtection: endChoices.antenna_protection,
        antennaProtectionChoices: endChoices.antenna_protection_choices,
        antennaProtectionLabels: endChoices.antenna_protection_labels,
        protectionGetter: `${endName}.radios.0.antennas.0.config.antenna_protection`,
      };
    },
    label,
    show,
    nextValue: makeAntennaNextValue(endName),
    nextValueExtra(newChoices, getValues, setValue) {
      const end = getEndFromChoices(newChoices, endName);
      const getter = `${endName}.radios.0.antennas.0.config.antenna_protection`;
      const currentProt = getValues(getter);
      if (
        !end.antenna_protection_choices?.includes(currentProt)
        && end.antenna_protection != null
      ) {
        setValue(getter, end.antenna_protection, { shouldDirty: true });
      }
    },
    warning({ path }: PTPParams): string {
      const warning = path.warnings[endName]?.antenna;
      if (warning != null && warning.kind === 'warning') {
        return warning.message;
      }
      return null;
    },
    warningProps({ modified }: any): any {
      if (modified) {
        return { basic: true, color: 'blue' };
      }
      return {};
    },
    info({ path }: PTPParams): string {
      const info = path.warnings[endName]?.antenna;
      if (info != null && info.kind === 'info') {
        return info.message;
      }
      return null;
    },
    lk: {
      shared: defaultLkShared,
      "unlicensed_1plus1": {
        getter({ lk, pathIndex, formGetter }) {
          const prot = formGetter(
            `${endName}.radios.0.antennas.0.config.antenna_protection`
          );

          if (lk === 'unlicensed_1plus1' && prot !== 'Redundant Antennas') {
            return `${endName}.radios.0.antennas.0.lp_antenna_id`;
          }

          return `${endName}.radios.${pathIndex}.antennas.0.lp_antenna_id`;
        },
        finalSync(form, getter, setter) {
          const sync = genericHotStandbySync(
            form, getter, setter, 'config.antenna_protection',
          );
          sync('local', 1, 0);
          sync('remote', 1, 0);
        },
      },
      "2plus0": {
        finalSync(form, getter, setter) {
          const sync = genericHotStandbySync(
            form, getter, setter, 'config.antenna_protection',
          );
          sync('local', 1, 0);
          sync('remote', 1, 0);
        },
      },
      "1plus1": {
        getter({ lk, pathIndex, formGetter }) {
          const prot = formGetter(
            `${endName}.radios.0.antennas.0.config.antenna_protection`
          );

          if (prot !== 'Spatial Diversity') {
            return `${endName}.radios.0.antennas.0.lp_antenna_id`;
          }

          return `${endName}.radios.${pathIndex}.antennas.0.lp_antenna_id`;
        },
        finalSync(form, getter, setter) {
          const sync = genericHotStandbySync(
            form, getter, setter, 'config.antenna_protection',
          );

          sync('local', 1, 0);
          sync('local', 2, 0);
          sync('local', 3, 0);
          sync('remote', 1, 0);
          sync('remote', 2, 0);
          sync('remote', 3, 0);
        },
      },
    },
  };
}

function hotStandbyAntennaHeightGetter(endName) {
  return ({ lk, pathIndex, formGetter }) => {
    const prot = formGetter(
      `${endName}.radios.0.antennas.0.config.antenna_protection`
    );

    if (lk === 'hot_standby' && prot !== 'Spatial Diversity') {
      return `${endName}.radios.0.antennas.0.height`;
    }

    if (lk === 'unlicensed_1plus1' && prot === 'Common Antenna - Symmetric Coupling') {
      return `${endName}.radios.0.antennas.0.height`;
    }

    return `${endName}.radios.${pathIndex}.antennas.0.height`;
  };
}

export function genericAntennaHeight(endName: string, extraOpts?: any) {
  let show;
  let getter;
  let key;
  let nextValue;

  if (extraOpts) {
    show = extraOpts.show;
    getter = extraOpts.getter;
    key = '_diverse';
    nextValue = (currentValue, newChoices) => {
      if (currentValue == null) {
        // link kind change: 2+X -> 2+X SD
        const end = getEndFromChoices(newChoices, endName);
        if (end == null) {
          // just in case, have a default
          return 10;
        }
        return end.default_diverse_antenna_height;
      }
    };
  } else {
    // no 'show' required
    getter = `${endName}.radios.0.antennas.0.height`;
    key = '';
    nextValue = null;
  }

  return {
    label: 'Antenna Height',
    attrName: `${endName}_antennaHeight${key}`,
    getter,
    show,
    component: GenericScaledField,
    precision: 1,
    min: 0,
    max: 3000,
    useHeightPrefs: true,
    nextValue,
    gridArea: 'antenna',
    lk: {
      shared: ({ lk, choices, endName }) => {
        const end = getEndFromChoices(choices, endName);
        const protection = end?.antenna_protection;

        if (
          lk === 'unlicensed_2plus0'
          && (protection === 'Spatial Diversity' || protection === 'Redundant Antennas')
        ) {
          return false;
        }

        return defaultLkShared({ lk });
      },
      "1plus1": {
        getter: hotStandbyAntennaHeightGetter(endName),
        finalSync(form, getter, setter) {
          const sync = genericHotStandbySync(form, getter, setter, 'height');
          sync('local', 1, 0);
          sync('local', 3, 2);
          sync('remote', 2, 0);
          sync('remote', 3, 1);
        },
      },
      "unlicensed_1plus1": {
        getter: hotStandbyAntennaHeightGetter(endName),
      },
      "2plus0": genericLkAntennaGetters(endName, 2, {
        getter: 'height',
      }),
    },
    componentProps: ({
      path,
      formGetter,
      formSetter,
      refreshChoices,
    }: PTPShowProps) => {
      if (path == null) {
        return {};
      }

      const siteMaxHeight = path[endName]?.site?.maximum_height;

      let result: any = {
        unitExtraTooltip: ({ units }) => {
          let height: number;
          if (units !== 'm') {
            height = meterToAny(siteMaxHeight, units, 1, false);
          } else {
            height = siteMaxHeight;
          }
          return ` (Max height at site is ${height.toFixed(1)}${units})`;
        },
        onAcceptChange: (evt) => {
          const value = formGetter(`${endName}.radios.0.antennas.0.height`);
          if (value === '' || !isNumber(value)) {
            // This doesn't refresh the value in the UI so the
            // user still has to fix the height before saving.
            // It does allow refreshChoices to return without errors.
            formSetter(`${endName}.radios.0.antennas.0.height`, 0, {
              shouldDirty: true,
            });
          }
          refreshChoices({ field: { attrName: `${endName}_antennaHeight` } });
        },
      };

      let formHeight = formGetter(getter);
      if (typeof formHeight === 'string') {
        formHeight = parseFloat(formHeight);
      }

      if (siteMaxHeight != null && formHeight > siteMaxHeight) {
        result.textColor = 'red';
      }

      return result;
    },
  };
}

function maxEirpGetters_1plus1(endName) {
  if (endName === 'local') {
    return {
      maxValueGetter: [
        `${endName}.radios.0.power.eirp`,
        `${endName}.radios.0.power.eirp`,
        `${endName}.radios.2.power.eirp`,
        `${endName}.radios.2.power.eirp`,
      ],
      checkboxGetter: [
        `${endName}.radios.0.power.use_user_eirp`,
        `${endName}.radios.0.power.use_user_eirp`,
        `${endName}.radios.2.power.use_user_eirp`,
        `${endName}.radios.2.power.use_user_eirp`,
      ],
      valueGetter: [
        `${endName}.radios.0.power.user_eirp`,
        `${endName}.radios.0.power.user_eirp`,
        `${endName}.radios.2.power.user_eirp`,
        `${endName}.radios.2.power.user_eirp`,
      ],
    };
  } else {
    return {
      maxValueGetter: [
        `${endName}.radios.0.power.eirp`,
        `${endName}.radios.1.power.eirp`,
        `${endName}.radios.0.power.eirp`,
        `${endName}.radios.1.power.eirp`,
      ],
      checkboxGetter: [
        `${endName}.radios.0.power.use_user_eirp`,
        `${endName}.radios.1.power.use_user_eirp`,
        `${endName}.radios.0.power.use_user_eirp`,
        `${endName}.radios.1.power.use_user_eirp`,
      ],
      valueGetter: [
        `${endName}.radios.0.power.user_eirp`,
        `${endName}.radios.1.power.user_eirp`,
        `${endName}.radios.0.power.user_eirp`,
        `${endName}.radios.1.power.user_eirp`,
      ],
    };
  }
}

export function genericMaxEirp(endName: string) {
  return {
    label: 'User Limit',
    maxValueLabel: 'Maximum EIRP',
    attrName: `${endName}_eirp_limit`,
    component: LimitControl,
    maxValueGetter: `${endName}.radios.0.power.eirp`,
    maxValueFormatter: (value) => round(parseFloat(value), 1),
    checkboxGetter: `${endName}.radios.0.power.use_user_eirp`,
    valueGetter: `${endName}.radios.0.power.user_eirp`,
    units: 'dBm',
    precision: 1,
    min: -10,
    max: 90,
    reCalculate: true,
    lk: {
      shared: false,
      'unlicensed_1plus1': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.eirp',
        checkboxGetter: 'power.use_user_eirp',
        valueGetter: 'power.user_eirp',
      }),
      '2plus0': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.eirp',
        checkboxGetter: 'power.use_user_eirp',
        valueGetter: 'power.user_eirp',
      }),
      '2plus2': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.eirp',
        checkboxGetter: 'power.use_user_eirp',
        valueGetter: 'power.user_eirp',
      }),
      '4plus0': genericLkRadioGetters(endName, 4, {
        maxValueGetter: 'power.eirp',
        checkboxGetter: 'power.use_user_eirp',
        valueGetter: 'power.user_eirp',
      }),
      '1plus1': {
        ...maxEirpGetters_1plus1(endName),
        finalSync(form, getter, setter) {
          const sync = (endName, attr, to, from_) => {
            setter(
              form,
              `${endName}.radios.${to}.${attr}`,
              getter(form, `${endName}.radios.${from_}.power.${attr}`),
            );
          };

          sync('local', 'use_user_eirp', 1, 0);
          sync('local', 'user_eirp', 1, 0);
          sync('local', 'use_user_eirp', 3, 2);
          sync('local', 'user_eirp', 3, 2);
          sync('remote', 'use_user_eirp', 2, 0);
          sync('remote', 'user_eirp', 2, 0);
          sync('remote', 'use_user_eirp', 3, 1);
          sync('remote', 'user_eirp', 3, 1);
        },
      },
    },
    warning({ path, choices }: PTPParams): string {
      if (choices) {
        const end = getEndFromChoices(choices, endName);
        if (end?.hasOwnProperty('diverse_eirp_warning')) {
          return end.diverse_eirp_warning;
        }
        if (end?.hasOwnProperty('eirp_warning')) {
          return end.eirp_warning;
        }
      }
      return path[endName]?.radios[0].warnings.eirp;
    },
    warningProps({ modified }: any): any {
      if (modified) {
        return { basic: true, color: 'blue' };
      }
      return {};
    },
  };
}

function maxPowerGetters_1plus1(endName) {
  if (endName === 'local') {
    return {
      maxValueGetter: [
        `${endName}.radios.0.power.power`,
        `${endName}.radios.0.power.power`,
        `${endName}.radios.2.power.power`,
        `${endName}.radios.2.power.power`,
      ],
      checkboxGetter: [
        `${endName}.radios.0.power.use_user_power`,
        `${endName}.radios.0.power.use_user_power`,
        `${endName}.radios.2.power.use_user_power`,
        `${endName}.radios.2.power.use_user_power`,
      ],
      valueGetter: [
        `${endName}.radios.0.power.user_power`,
        `${endName}.radios.0.power.user_power`,
        `${endName}.radios.2.power.user_power`,
        `${endName}.radios.2.power.user_power`,
      ],
    };
  } else {
    return {
      maxValueGetter: [
        `${endName}.radios.0.power.power`,
        `${endName}.radios.1.power.power`,
        `${endName}.radios.0.power.power`,
        `${endName}.radios.1.power.power`,
      ],
      checkboxGetter: [
        `${endName}.radios.0.power.use_user_power`,
        `${endName}.radios.1.power.use_user_power`,
        `${endName}.radios.0.power.use_user_power`,
        `${endName}.radios.1.power.use_user_power`,
      ],
      valueGetter: [
        `${endName}.radios.0.power.user_power`,
        `${endName}.radios.1.power.user_power`,
        `${endName}.radios.0.power.user_power`,
        `${endName}.radios.1.power.user_power`,
      ],
    };
  }
}

export function genericMaxPower(endName: string) {
  return {
    label: 'User Limit',
    maxValueLabel: 'Maximum Power',
    component: LimitControl,
    attrName: `${endName}_power_limit`,
    maxValueGetter: `${endName}.radios.0.power.power`,
    maxValueFormatter: (value) => round(parseFloat(value), 1),
    units: 'dBm',
    precision: 1,
    checkboxGetter: `${endName}.radios.0.power.use_user_power`,
    valueGetter: `${endName}.radios.0.power.user_power`,
    min: -30,
    max: 40,
    reCalculate: true,
    lk: {
      shared: false,
      'unlicensed_1plus1': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.power',
        checkboxGetter: 'power.use_user_power',
        valueGetter: 'power.user_power',
      }),
      '2plus0': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.power',
        checkboxGetter: 'power.use_user_power',
        valueGetter: 'power.user_power',
      }),
      '2plus2': genericLkRadioGetters(endName, 2, {
        maxValueGetter: 'power.power',
        checkboxGetter: 'power.use_user_power',
        valueGetter: 'power.user_power',
      }),
      '4plus0': genericLkRadioGetters(endName, 4, {
        maxValueGetter: 'power.power',
        checkboxGetter: 'power.use_user_power',
        valueGetter: 'power.user_power',
      }),
      '1plus1': {
        ...maxPowerGetters_1plus1(endName),
        finalSync(form, getter, setter) {
          const sync = (endName, attr, to, from_) => {
            setter(
              form,
              `${endName}.radios.${to}.${attr}`,
              getter(form, `${endName}.radios.${from_}.power.${attr}`),
            );
          };

          sync('local', 'use_user_power', 1, 0);
          sync('local', 'user_power', 1, 0);
          sync('local', 'use_user_power', 3, 2);
          sync('local', 'user_power', 3, 2);
          sync('remote', 'use_user_power', 2, 0);
          sync('remote', 'user_power', 2, 0);
          sync('remote', 'use_user_power', 3, 1);
          sync('remote', 'user_power', 3, 1);
        },
      },
    },
  };
}

export function genericInterference(endName: string) {
  return {
    label: 'Interference',
    component: LimitControl,
    attrName: `${endName}_interference_limit`,
    units: 'dBm',
    precision: 1,
    checkboxGetter: `${endName}.radios.0.power.use_noise`,
    valueGetter: `${endName}.radios.0.power.noise`,
    min: -144,
    max: -40,
    lk: {
      shared: false,
      '2plus0': genericLkRadioGetters(endName, 2, {
        checkboxGetter: 'power.use_noise',
        valueGetter: 'power.noise',
      }),
      '2plus2': genericLkRadioGetters(endName, 2, {
        checkboxGetter: 'power.use_noise',
        valueGetter: 'power.noise',
      }),
      '4plus0': genericLkRadioGetters(endName, 4, {
        checkboxGetter: 'power.use_noise',
        valueGetter: 'power.noise',
      }),
      'unlicensed_1plus1': {
        checkboxGetter: [
          // NOTE these are intentionally the same
          `${endName}.radios.0.power.use_noise`,
          `${endName}.radios.0.power.use_noise`,
        ],
        valueGetter: [
          // NOTE these are intentionally the same
          `${endName}.radios.0.power.noise`,
          `${endName}.radios.0.power.noise`,
        ],
      },
    },
    componentProps: {
      valueTooltip: ({ choices }) => {
        if (choices == null) {
          return null;
        }

        const bw = getEquipmentFromChoices(choices).bandwidth.choices.find(
          (c) => c.value === getEquipmentFromChoices(choices).bandwidth.value
        );
        if (bw) {
          return ` in ${bw.text} channel`;
        }
        return null;
      },
    },
  };
}

function macAddress_1plus1(endName) {
  if (endName === 'local') {
    return {
      getter: [
        `${endName}.radios.0.mac_address`,
        `${endName}.radios.0.mac_address`,
        `${endName}.radios.2.mac_address`,
        `${endName}.radios.2.mac_address`,
      ],
    };
  } else {
    return {
      getter: [
        `${endName}.radios.0.mac_address`,
        `${endName}.radios.1.mac_address`,
        `${endName}.radios.0.mac_address`,
        `${endName}.radios.1.mac_address`,
      ],
    };
  }
}

export function genericMacAddress(endName: string) {
  return {
    label: 'MAC Address',
    attrName: `${endName}_mac_address`,
    getter: `${endName}.radios.0.mac_address`,
    component: GenericScaledField,
    usePrefs: 'macAddressFormat',
    lk: {
      shared: false,
      'unlicensed_1plus1': genericLkRadioGetters(endName, 2, {
        getter: 'mac_address',
      }),
      '2plus0': genericLkRadioGetters(endName, 2, {
        getter: 'mac_address',
      }),
      '2plus2': genericLkRadioGetters(endName, 2, {
        getter: 'mac_address',
      }),
      '4plus0': genericLkRadioGetters(endName, 4, {
        getter: 'mac_address',
      }),
      '1plus1': {
        ...macAddress_1plus1(endName),
        finalSync(form, getter, setter) {
          setter(
            form, 'local.radios.1.mac_address', getter(form, 'local.radios.0.mac_address'),
          );
          setter(
            form, 'local.radios.3.mac_address', getter(form, 'local.radios.2.mac_address'),
          );
          setter(
            form, 'remote.radios.2.mac_address', getter(form, 'remote.radios.0.mac_address'),
          );
          setter(
            form, 'remote.radios.3.mac_address', getter(form, 'remote.radios.1.mac_address'),
          );
        },
      },
    },
  }
}

export function makeAntennaNextValue(endName: string) {
  return (currentValue: string, newChoices: PTPChoices) => {
    const antennaChoices = filterAndSortAntennas(
      getAntennasFromChoices(newChoices, endName)
    );
    const choiceIds = antennaChoices.map((a) => a.id);
    const payload = store.getState().antenna.antennaPayload;
    if (
      payload != null &&
      payload[endName] &&
      choiceIds.includes(payload[endName].id)
    ) {
      // we need to reset the csutom antenna data in redux after selecting the
      // antenna
      store.dispatch(reset());
      return payload[endName].id;
    }
    if (choiceIds.includes(currentValue)) {
      return currentValue;
    } else {
      const defaultAntenna = antennaChoices.filter((a) => a.data.is_default);
      if (defaultAntenna.length > 0) {
        return defaultAntenna[0].id;
      }
      return choiceIds[0];
    }
  };
}
