import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Header, Message, Modal } from 'semantic-ui-react';
import Mail from './Mail';
import about from '../../About';
import messages from 'src/messages';

interface IErrorBoundaryProps extends WrappedComponentProps {
  msg: string;
  apiVersion: string;
  userId: string;
  projectId: string;
}

interface IErrorBoundaryState {
  error: any;
  modalOpen: boolean;
  stackTrace: any;
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      modalOpen: false,
      stackTrace: null,
    };
  }

  componentDidMount() {
    window.onerror = (msg, source, lineNo, columnNo, error) => {
      const errorMsg = [
        `Message: ${msg}`,
        `URL:  ${source}`,
        `Line:  ${lineNo}`,
        `Column: ${columnNo}`,
      ].join(' - ');
      if (error?.stack) {
        console.error(errorMsg);
        console.error(error);
        this.setState({ error: errorMsg, stackTrace: error.stack });
      }
    };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      stackTrace: errorInfo['componentStack'],
      modalOpen: true,
    });
    // we can issue a backend call and log the errors
  }

  render() {
    if (this.state.error) {
      const { formatMessage } = this.props.intl;
      const body = `
      UserAgent: ${navigator.userAgent}
      User Id: ${this.props.userId}
      Project Id: ${this.props.projectId}
      Error: ${this.state.error}
      StackTrace: ${this.state.stackTrace}`;
      // You can render any custom fallback UI
      return (
        <Modal open={this.state.modalOpen}>
          <Modal.Header as={Message} negative>
            <Header>{formatMessage(messages.error)}</Header>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Message negative>
                <Message.Header>
                  {formatMessage(messages.unexpectedError)}
                </Message.Header>
              </Message>
              <span style={{ paddingTop: '1 rem' }}>
                <details style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                  <summary style={{ cursor: 'pointer' }}>
                    {formatMessage(messages.details)}
                  </summary>
                  <pre>{body}</pre>
                </details>
              </span>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Mail
              email={about.email}
              subject={`Bug in ${about.name}`}
              body={body}
            >
              Report this bug
            </Mail>
            |
            <span className="m-2">
              {
                // eslint-disable-next-line
                <a onClick={() => document.location.reload()}>
                  Refresh this page
                </a>
              }
            </span>
            or
            <span className="m-2">
              <a href="/">Return to the home page</a>
            </span>
          </Modal.Actions>
        </Modal>
      );
    }

    return this.props.children;
  }
}
const ErrorBoundaryComponent = injectIntl(
  connect((state: any) => {
    return {
      apiVersion: state.mainFrame.apiVersion,
      userId: state.mainFrame.userId,
      projectId: state.mainFrame.projectId,
    };
  })(ErrorBoundary)
);

export default ErrorBoundaryComponent;
