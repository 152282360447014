import { createSlice } from '@reduxjs/toolkit';

// If these change then be sure to update
// the backend view
export const DEFAULT_AFC_COORDS = { lat: 39.828175, lng: -98.5795 };

export const DEFAULT_SITE_NAME_TEMPLATE = {
  NETWORK: 'Network Site ###',
  SUBSCRIBER: 'Subscriber Site ###',
};

const initialState = {
  userCoords: '',
  userName: '',
  // default height in metres
  defaultHeight: 10,
  afcCoords: null,
  useSiteMaxHeight: true,
  apScale: 100,
  mapSiteName: '',
  networkSiteNameTemplate: DEFAULT_SITE_NAME_TEMPLATE.NETWORK,
  subscriberSiteNameTemplate: DEFAULT_SITE_NAME_TEMPLATE.SUBSCRIBER,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setUserCoordinates: (state, action) => {
      state.userCoords = action.payload;
    },
    setAfcCoordinates: (state, action) => {
      state.afcCoords = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setDefaultHeight: (state, action) => {
      state.defaultHeight = action.payload;
    },
    setUseMaxHeight: (state, action) => {
      state.useSiteMaxHeight = action.payload;
    },
    setApScale: (state, action) => {
      state.apScale = action.payload;
    },
    setMapSiteName: (state, action) => {
      state.mapSiteName = action.payload;
    },
    setSiteNameTemplate: (state, action) => {
      const { value, isNetwork } = action.payload;
      if (isNetwork) {
        state.networkSiteNameTemplate = value;
      } else {
        state.subscriberSiteNameTemplate = value;
      }
    },
  },
});

export const {
  setUserCoordinates,
  setAfcCoordinates,
  setUserName,
  setDefaultHeight,
  setUseMaxHeight,
  setApScale,
  setMapSiteName,
  setSiteNameTemplate,
} = mapSlice.actions;
export default mapSlice.reducer;
