import React from 'react';
import { useIntl } from 'react-intl';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  uiConfirmCancel,
  uiConfirmOk,
} from '../pages/mainframe/mainframe.reducer';
import messages from '../messages';

/*
 * Confirmation dialog
 *
 * To show the dialog:
 * dispatch mainFrame.actions.uiConfirmAction.
 * The payload can contain a "message"
 * and optional actions for cancel or OK
 */
export const ConfirmActionContainer = (props) => {
  const {
    open,
    size,
    header,
    message,
    onCancel,
    onConfirm,
    cancelButton,
    confirmButton,
  } = props;
  const { formatMessage } = useIntl();
  return (
    <Modal
      open={open}
      size={size}
      onClose={onCancel}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {header ?? formatMessage(messages.confirm)}
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={onCancel}
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>
          {cancelButton || formatMessage(messages.cancel)}
        </Button>
        <Button className="ui blue button" onClick={onConfirm}>
          {confirmButton || formatMessage(messages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const ConfirmAction = connect(
  (state, ownProps) => {
    // console.log('CON', state, ownProps);
    const open = state.mainFrame.confirm;
    let payload = state.mainFrame.confirmPayload;
    return {
      ...ownProps,
      ...payload,
      open,
      payload: payload,
    };
  },
  (dispatch, ownProps) => {
    return {
      dispatch: dispatch,
      onCancel: (e) => {
        dispatch(uiConfirmCancel());
      },
      onConfirm: (e) => {
        dispatch(uiConfirmOk());
      },
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    // Allow for secondary cancel/confirm events after clearing
    // the state of the confirm dialog.
    const newOnCancel = (e) => {
      dispatchProps.onCancel(e);
      if (stateProps.payload.onCancel) {
        //     const cancelProps = stateProps.payload.onCancel;
        //     const { dispatchEvent, payload } = cancelProps;
        //     dispatchProps.dispatch({ type: dispatchEvent, payload: payload });
        // using callback rather than dispatching event
        stateProps.payload.onCancel();
      }
    };
    const newOnConfirm = (e) => {
      dispatchProps.onConfirm(e);
      if (stateProps.payload.onConfirm) {
        //   const confirmProps = stateProps.payload.onConfirm;
        //   const { dispatchEvent, payload } = confirmProps;
        //   dispatchProps.dispatch({ type: dispatchEvent, payload: payload });
        // using callback rather than dispatching event
        stateProps.payload.onConfirm();
      }
    };
    return {
      ...stateProps,
      onCancel: newOnCancel,
      onConfirm: newOnConfirm,
    };
  }
)(ConfirmActionContainer);

export default ConfirmAction;
