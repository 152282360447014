import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import { Form, Grid, Message, Popup } from 'semantic-ui-react';
import CustomAccordion from 'src/components/controls/CustomAccordion';
import { EquipmentConfig, NetworkPlan } from './terragraph.modal';
import { handleNumbers } from './terragraph.service';
import messages from 'src/messages';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { get } from 'lodash';

function getMinOfCn(equipmentConfigFormData, financialsFormData) {
  let cns = [];
  if (equipmentConfigFormData.cnv3000) {
    cns.push(financialsFormData.V3000);
  }
  if (equipmentConfigFormData.cnv2000) {
    cns.push(financialsFormData.V2000);
  }
  if (equipmentConfigFormData.cnv1000) {
    cns.push(financialsFormData.V1000);
  }
  return Math.min(...cns);
}

function FinancialsStep(props: {
  equipmentConfigFormData: EquipmentConfig;
  financialsFormData: any;
  networkPlanData: NetworkPlan;
  financialsFormDataChange: Function;
  intl: any;
  setValidate: Function;
  editCase: any;
}) {
  const {
    equipmentConfigFormData,
    financialsFormDataChange,
    financialsFormData,
    networkPlanData,
    intl,
    setValidate,
    editCase,
  } = props;

  const { formatMessage } = intl;

  const { subscriberSiteCount, networkSites } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  let dnCount = 0,
    popCount = 0;

  networkSites.features.forEach((element) => {
    const nodeType = get(element, 'properties.node_type', '');
    if (nodeType === 'DN') {
      dnCount += 1;
    }
    if (nodeType === 'POP') {
      popCount += 1;
    }
  });

  const minCostofCns = getMinOfCn(equipmentConfigFormData, financialsFormData);
  const { networkPlan } = networkPlanData;
  let recommendedBudget, minBudget, maxBudget;
  if (networkPlan === 'Mesh') {
    recommendedBudget = (dnCount + popCount) * financialsFormData.V5000;
    minBudget = 0;
    maxBudget = Number.MAX_SAFE_INTEGER;
  } else {
    recommendedBudget =
      Math.ceil(subscriberSiteCount / 18) * financialsFormData.V5000 +
      subscriberSiteCount * minCostofCns;
    minBudget =
      Math.floor(subscriberSiteCount / 20) * financialsFormData.V5000 +
      subscriberSiteCount * minCostofCns;
    maxBudget =
      Math.ceil(subscriberSiteCount / 10) * financialsFormData.V5000 +
      subscriberSiteCount * minCostofCns;
  }

  const {
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...financialsFormData,
      budget: editCase.value ? financialsFormData.budget : recommendedBudget,
    },
  });

  const onFormValueChange = () => {
    validateForm();
  };

  const validateForm = () => {
    financialsFormDataChange(getValues());
    let values: any = Object.keys(getValues());
    // Using Set timeout to asynchronously call the validation after all the values are set in form.
    setTimeout(() => {
      trigger(values).then((el) => {
        setValidate(el);
      });
    }, 0);
  };

  useEffect(() => {
    validateForm();
  }, []);

  const currentBudget = getValues('budget');
  let budgetWarning = '';
  if (currentBudget < minBudget) {
    budgetWarning = formatMessage(messages.minBudgetValueError);
  } else if (currentBudget > maxBudget) {
    budgetWarning = formatMessage(messages.maxBudgetValueError);
  }
  const budgetTitle =
    networkPlan === 'Mesh'
      ? 'Budget should be greater-than or equal to zero'
      : `Budget should be between ${minBudget} and ${maxBudget}`;

  const accordions = [
    {
      key: 'financial',
      titleProps: {
        content: `Financial`,
      },
      contentProps: {
        content: (
          <Form onChange={onFormValueChange}>
            <Popup
              content={formatMessage(messages.equipmentCapExHelpText)}
              position="right center"
              trigger={
                <label>
                  {formatMessage(messages.equipmentCapEx)} (
                  <small>Default Values are in USD</small>)
                </label>
              }
            />

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <label>{formatMessage(messages.popDn)}</label>
                  <Form.Field>
                    <Grid verticalAlign="bottom" columns={2}>
                      <Grid.Column>
                        <Controller
                          name={equipmentConfigFormData.popDn}
                          control={control}
                          rules={{
                            min: {
                              value: 0,
                              message: formatMessage(messages.minValueError, {
                                value: 0,
                              }),
                            },
                            required: {
                              value: true,
                              message: formatMessage(
                                messages.requiredFieldError,
                                { fieldName: equipmentConfigFormData.popDn }
                              ),
                            },
                          }}
                          render={({ field: { ref, onChange, ...rest } }) => (
                            <Form.Input
                              {...rest}
                              type="number"
                              min={0}
                              onChange={(e) => {
                                onChange(Number(e.target.value));
                                handleNumbers(e, false, 10, false);
                              }}
                              onKeyPress={(e) => {
                                handleNumbers(e, false, 10, false);
                              }}
                              label={equipmentConfigFormData.popDn}
                              error={
                                errors[equipmentConfigFormData.popDn] && {
                                  content:
                                    errors[equipmentConfigFormData.popDn]
                                      .message,
                                }
                              }
                            />
                          )}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                  <Form.Field>
                    <Grid verticalAlign="bottom" columns={2}>
                      <Grid.Column className="field">
                        <Popup
                          content={formatMessage(messages.budgetHelpText)}
                          position="top center"
                          trigger={<label>Budget</label>}
                        />
                        <Controller
                          name={'budget'}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: formatMessage(
                                messages.requiredFieldError,
                                { fieldName: 'budget' }
                              ),
                            },
                          }}
                          defaultValue={recommendedBudget}
                          render={({ field: { ref, onChange, ...rest } }) => (
                            <>
                              <Form.Input
                                {...rest}
                                type="number"
                                title={budgetTitle}
                                min={0}
                                onChange={(e) => {
                                  onChange(Number(e.target.value));
                                  handleNumbers(e, false, 10, false);
                                }}
                                onKeyPress={(e) => {
                                  handleNumbers(e, false, 10, false);
                                }}
                                error={
                                  errors['budget'] && {
                                    content: errors['budget'].message,
                                  }
                                }
                              />
                              {budgetWarning ? (
                                <Message visible warning size="small">
                                  {budgetWarning}
                                </Message>
                              ) : null}
                              <small>
                                Recommended Budget is {recommendedBudget}
                              </small>
                            </>
                          )}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form.Field>
                </Grid.Column>
                {networkPlanData.networkPlan === 'Distribution' ? (
                  <Grid.Column>
                    <label>{formatMessage(messages.cn)}</label>
                    {equipmentConfigFormData.cnv1000 ? (
                      <Form.Field>
                        <Grid verticalAlign="bottom" columns={2}>
                          <Grid.Column>
                            <Controller
                              name={'V1000'}
                              control={control}
                              rules={{
                                min: {
                                  value: 0,
                                  message: formatMessage(
                                    messages.minValueError,
                                    { value: 0 }
                                  ),
                                },
                                required: {
                                  value: true,
                                  message: formatMessage(
                                    messages.requiredFieldError,
                                    { fieldName: 'V1000' }
                                  ),
                                },
                              }}
                              render={({
                                field: { ref, onChange, ...rest },
                              }) => (
                                <Form.Input
                                  {...rest}
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    onChange(Number(e.target.value));
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  onKeyPress={(e) => {
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  error={
                                    errors['V1000'] && {
                                      content: errors['V1000'].message,
                                    }
                                  }
                                  label={'V1000'}
                                />
                              )}
                            />
                          </Grid.Column>
                          {/* <Grid.Column>
                                                <div className='ui label m-1 mb-1'>{formatMessage(messages.usd)}</div>
                                            </Grid.Column> */}
                        </Grid>
                      </Form.Field>
                    ) : (
                      ''
                    )}
                    {equipmentConfigFormData.cnv2000 ? (
                      <Form.Field>
                        <Grid verticalAlign="bottom" columns={2}>
                          <Grid.Column>
                            <Controller
                              name={'V2000'}
                              control={control}
                              rules={{
                                min: {
                                  value: 0,
                                  message: formatMessage(
                                    messages.minValueError,
                                    { value: 0 }
                                  ),
                                },
                                required: {
                                  value: true,
                                  message: formatMessage(
                                    messages.requiredFieldError,
                                    { fieldName: 'V2000' }
                                  ),
                                },
                              }}
                              render={({
                                field: { ref, onChange, ...rest },
                              }) => (
                                <Form.Input
                                  {...rest}
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    onChange(Number(e.target.value));
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  onKeyPress={(e) => {
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  error={
                                    errors['V2000'] && {
                                      content: errors['V2000'].message,
                                    }
                                  }
                                  label={'V2000'}
                                />
                              )}
                            />
                          </Grid.Column>
                          {/* <Grid.Column>
                                                <div className='ui label m-1 mb-1'>{formatMessage(messages.usd)}</div>
                                            </Grid.Column> */}
                        </Grid>
                      </Form.Field>
                    ) : (
                      ''
                    )}
                    {equipmentConfigFormData.cnv3000 ? (
                      <Form.Field>
                        <Grid verticalAlign="bottom" columns={2}>
                          <Grid.Column>
                            <Controller
                              name={'V3000'}
                              control={control}
                              rules={{
                                min: {
                                  value: 0,
                                  message: formatMessage(
                                    messages.minValueError,
                                    { value: 0 }
                                  ),
                                },
                                required: {
                                  value: true,
                                  message: formatMessage(
                                    messages.requiredFieldError,
                                    { fieldName: 'V3000' }
                                  ),
                                },
                              }}
                              render={({
                                field: { ref, onChange, ...rest },
                              }) => (
                                <Form.Input
                                  {...rest}
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    onChange(Number(e.target.value));
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  onKeyPress={(e) => {
                                    handleNumbers(e, false, 10, false);
                                  }}
                                  error={
                                    errors['V3000'] && {
                                      content: errors['V3000'].message,
                                    }
                                  }
                                  label={'V3000'}
                                />
                              )}
                            />
                          </Grid.Column>
                          {/* <Grid.Column>
                                                <div className='ui label m-1 mb-1'>{formatMessage(messages.usd)}</div>
                                            </Grid.Column> */}
                        </Grid>
                      </Form.Field>
                    ) : (
                      ''
                    )}
                  </Grid.Column>
                ) : (
                  ''
                )}
              </Grid.Row>
            </Grid>
          </Form>
        ),
      },
    },
  ];

  return (
    <div>
      <Grid centered columns={2}>
        <Grid.Column>
          <CustomAccordion accordions={accordions}></CustomAccordion>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default injectIntl(FinancialsStep);
