import { Form } from 'semantic-ui-react';
import NDAntennaPanel from '../NDAntennaPanel';
import NDPowerPanel from '../NDPowerPanel';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import NDEquipmentPanel from '../NDEquipmentPanel';
import { PMPFormPanel, syncFormState } from '../utils';
import {
  antennaHeightND,
  antennaSelection,
  cableLoss,
  equipmentPanelFields,
  modeledBeamwidth,
  smRangeFields,
  ndInterference,
  smDefaultInterference,
  bandwidth,
  azimuth,
  tilt,
} from './config-utils';
import {
  cableChoices,
  cableLength,
  calculateLoss,
  cnReachPowerConfig,
  cnReachUserPowerConfig,
  getEIRPConfig,
} from '../../config-utils';

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'nd_equipment',
  component: NDEquipmentPanel,
  fields: [
    ...equipmentPanelFields,
    {
      attrName: 'io_connectivity',
      getter: 'radios.0.equipment.io_connectivity',
    },
    {
      attrName: 'sync_input',
      getter: 'radios.0.equipment.sync_input',
    },
  ],
};

const product: PMPFormPanel = {
  kind: 'product',
  title: 'Product Configuration',
  name: 'nd_Product',
  component: NDEquipmentPanel,
  fields: [
    {
      attrName: 'band_setting',
      getter: 'radios.0.equipment.band_setting',
      refreshesChoices: true,
    },
    {
      attrName: 'tr_spacing',
      getter: 'radios.0.equipment.tr_spacing',
      refreshesChoices: true,
    },
    bandwidth,
    {
      attrName: 'modulation_type',
      getter: 'radios.0.equipment.modulation_type',
      refreshesChoices: true,
    },
    ...smRangeFields,
    {
      attrName: 'sm_modulation_mode',
      getter: 'radios.0.equipment.sm_modulation_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'sm_max_mod_mode',
      getter: 'radios.0.equipment.sm_max_mod_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'sm_min_mod_mode',
      getter: 'radios.0.equipment.sm_min_mod_mode',
      refreshesChoices: true,
    },
    {
      attrName: 'ap_modulation_mode',
      getter: 'radios.0.equipment.ap_modulation_mode',
    },
    {
      attrName: 'hop_pattern',
      getter: 'radios.0.equipment.hop_pattern',
    },
    {
      attrName: 'mms_hop_offset',
      getter: 'radios.0.equipment.mms_hop_offset',
    },
    {
      attrName: 'ap_max_payload_bytes',
      getter: 'radios.0.equipment.ap_max_payload_bytes',
      component: GenericScaledField,
      min: 64,
      max: 1600,
      units: 'Bytes',
    },
    {
      attrName: 'sm_max_payload_bytes',
      getter: 'radios.0.equipment.sm_max_payload_bytes',
      component: GenericScaledField,
      min: 64,
      max: 1600,
      units: 'Bytes',
    },
    {
      attrName: 'hi',
      getter: 'radios.0.equipment.hi',
    },
  ],
};

const antenna: PMPFormPanel = {
  kind: 'antenna',
  title: 'Antenna Configuration',
  name: 'nd_antenna',
  component: NDAntennaPanel,
  fields: [
    antennaSelection,
    modeledBeamwidth,
    antennaHeightND,
    azimuth,
    tilt,
    calculateLoss,
    cableChoices,
    cableLength,
    cableLoss,
  ],
};

const power: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'nd_power',
  component: NDPowerPanel,
  fields: [
    getEIRPConfig(),
    cnReachPowerConfig,
    cnReachUserPowerConfig,
    ndInterference,
    smDefaultInterference,
  ],
};

export const cnReachViewConfig = {
  syncFormState,
  equipment,
  product,
  antenna,
  power,
};
