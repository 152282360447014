import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getWithAuth } from 'src/api';
import messages from 'src/messages';
import TowerSwitchEquipment from 'src/pages/towerswitch/TowerSwitchEquipment';
import BomModal, { BomGrid } from 'src/components/BomModal';
import { RootStateOrAny } from 'src/store';

const tsBomItemsBulkUpdateUrlConfig = (projectId, selectedSiteIds) => {
  if (selectedSiteIds.length) {
    return {
      url: `project/${projectId}/sites/tower_switch/bom`,
      postObj: { site_ids: selectedSiteIds },
    };
  } else {
    return {};
  }
};

const bulkAddDeleteTSExtrasUrlConfig = (projectId, selectedSiteIds) => {
  if (selectedSiteIds.length) {
    return {
      url: `project/${projectId}/sites/tower_switch/bom/extras`,
      postObj: { site_ids: selectedSiteIds },
    };
  } else {
    return {};
  }
};
function NetworkSiteBomModal(props) {
  const {
    tsurl = '',
    url,
    listViewShowTowerSwitch,
    tableViewShowTowerSwitch,
    listViewResetShowTowerSwitch,
    tableViewResetShowTowerSwitch,
    selectedSiteIds = [],
    ...restProps
  } = props;
  const { formatMessage } = props.intl;
  const [towerSwitch, setTowerSwitch] = useState(null);
  const [siteBomRefresh, setSiteBomRefresh] = useState(null);
  const [showTowerSwitch, setShowTowerSwitch] = useState(false);
  const [addTS, setAddTS] = useState(false);
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);

  useEffect(() => {
    if (listViewShowTowerSwitch?.status || tableViewShowTowerSwitch)
      setShowTowerSwitch(true);
  }, [listViewShowTowerSwitch, tableViewShowTowerSwitch]);

  useEffect(() => {
    if (tsurl !== '' || siteBomRefresh) {
      getWithAuth(tsurl || siteBomRefresh.tsurl).then((res) => {
        setTowerSwitch(res.detail !== 'Item not found' ? res : null);
        if (
          (tableViewShowTowerSwitch || listViewShowTowerSwitch?.status) &&
          res.detail === 'Item not found'
        )
          setAddTS(true);
      });
    }
  }, [tsurl, siteBomRefresh]);

  if (
    (tableViewShowTowerSwitch || listViewShowTowerSwitch) &&
    !towerSwitch &&
    !addTS
  )
    return null;

  return showTowerSwitch ? (
    <BomModal
      {...restProps}
      url={towerSwitch?.id ? `${tsurl}/${towerSwitch.id}/bom` : ''} // need this condition for table view of add tower switch in TowerSwitchEquipment component towerSwitch value will set after post call.
      updateItemsUrlConfig={tsBomItemsBulkUpdateUrlConfig(
        projectId,
        selectedSiteIds
      )}
      addExtrasUrlConfig={bulkAddDeleteTSExtrasUrlConfig(
        projectId,
        selectedSiteIds
      )}
      deleteExtrasUrlConfig={bulkAddDeleteTSExtrasUrlConfig(
        projectId,
        selectedSiteIds
      )}
      setShowTowerSwitch={
        tableViewShowTowerSwitch
          ? tableViewResetShowTowerSwitch
          : listViewResetShowTowerSwitch
      }
      showTowerSwitch={showTowerSwitch}
      towerSwitch={towerSwitch}
    >
      <div className="towerSwitchEquipment">
        <h4>{formatMessage(messages.towerSwitchEquipment)}</h4>
        <TowerSwitchEquipment
          siteId={props.objId}
          towerSwitch={towerSwitch}
          selectedSiteIds={selectedSiteIds}
          setTowerSwitch={setTowerSwitch}
          {...(listViewShowTowerSwitch && {
            listViewShowTowerSwitch: listViewShowTowerSwitch,
          })}
          {...(tableViewShowTowerSwitch && {
            tableViewShowTowerSwitch: tableViewShowTowerSwitch,
          })}
        />
      </div>
    </BomModal>
  ) : (
    <BomModal
      {...restProps}
      url={url}
      setBomRefresh={setSiteBomRefresh}
      bomRefresh={siteBomRefresh}
    >
      <div style={{ marginBottom: '20px' }}>
        <h4>Tower Switch BOM</h4>
        <BomGrid
          objId={props.objId}
          rowData={towerSwitch?.bom ?? []}
          editable={false}
          accordionTitle="Tower Switch BOM"
        />
      </div>
    </BomModal>
  );
}

export default injectIntl(NetworkSiteBomModal);
