import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import type { PTPPath } from './ptp-link-type';
import ptp450PanelConfig from './configs/PTP450ViewConfig';
import PTPEndPanel from './PTPEndPanel';
import { fieldIsShared, type PTPChoices } from './utils';
import { useLinkKind } from './hooks';
import { useFormContext } from 'react-hook-form';

type EndPanel = typeof ptp450PanelConfig.ends;

type Props = {
  title: string;
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  choices: PTPChoices;
  refreshChoices: any;
  path: PTPPath;
  panel: EndPanel;
};

type CheckDisplayAttrs = {
  choices: any;
  lk: any;
};

function countDisplayedFields(fields, formAttrs: CheckDisplayAttrs, endName) {
  const { choices, lk } = formAttrs;

  return fields.filter((field) => {
    return fieldIsShared(lk, field, choices, endName);
  }).length;
}

function PTPEndsPanel({
  title,
  modified,
  setModified,
  choices,
  refreshChoices,
  panel,
  path,
}: Props) {
  const { lk, pathNames, isComplex } = useLinkKind();

  const checkDisplayAttrs = { choices, lk };
  const localFieldsDisplayed = countDisplayedFields(
    panel.local.fields, checkDisplayAttrs, 'local',
  );
  const remoteFieldsDisplayed = countDisplayedFields(
    panel.remote.fields, checkDisplayAttrs, 'remote',
  );
  const tabRow = Math.max(localFieldsDisplayed, remoteFieldsDisplayed);

  if (choices.hasOwnProperty('paths') && choices.paths.length !== pathNames.length) {
    return (
      <StoredAccordion
        name="ptp_ends_panel"
        title={title}
        contentProps={{ loading: true, style: { height: '500px' } }}
      >
        {/*
          * Intermediate loading state whilst link kind changes
          * Not ideal, but prevents having to use lots of null coalescing
          * in end components / controls.
        */}
      </StoredAccordion>
    );
  }

  let localHeaderStyle;
  let remoteHeaderStyle;
  if (isComplex) {
    localHeaderStyle = { gridArea: 'l_header' };
    remoteHeaderStyle = { gridArea: 'r_header' };
  }

  return (
    <StoredAccordion name="ptp_ends_panel" title={title}>
      <div className={`ptp-end-grid ${isComplex ? 'ptp-complex-grid' : 'ptp-simple-grid'}`}>
        <Header style={localHeaderStyle}>{path.local.site.name}</Header>
        <Header style={remoteHeaderStyle}>{path.remote.site.name}</Header>

        <PTPEndPanel
          modified={modified}
          setModified={setModified}
          choices={choices}
          refreshChoices={refreshChoices}
          panel={panel.local}
          path={path}
          endName='local'
          tabRow={tabRow}
        />
        <PTPEndPanel
          modified={modified}
          setModified={setModified}
          choices={choices}
          refreshChoices={refreshChoices}
          panel={panel.remote}
          path={path}
          endName='remote'
          tabRow={tabRow}
        />
      </div>
    </StoredAccordion>
  );
}

export default PTPEndsPanel;
