import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal, ModalContent } from 'semantic-ui-react';
import CreateAccessPoint from './CreateAccessPoint';
import CreateAccessPointWizard from './CreateAccessPointWizard';
import TemplateType from './TemplateType';
import ViewTemplate from './ViewTemplate';

function CreateAccessPointModal({ showModal, handleClose, projectId, intl }) {
  const [activeStep, setActiveStep] = useState(1);
  const template = useRef(null);
  const ap = useRef(null);
  const configName = useRef(null);
  const nextStepHandler = (data) => {
    if (data) {
      const { templateId, apId, apName, templateName } = data;
      template.current = templateId;
      ap.current = apId;
      if (apName) {
        configName.current = apName;
      }
      if (templateName) {
        configName.current = templateName;
      }
    }
    setActiveStep((prevState) => (prevState += 1));
  };

  const prevStepHandler = () => {
    setActiveStep((prevState) => (prevState -= 1));
  };

  const closeImportDialogue = () => {
    setActiveStep(1);
    handleClose();
  };

  return (
    <Modal size="large" open={showModal}>
      <Modal.Header>
        {'Create'}
        <Button
          circular
          icon="close"
          title="Close"
          onClick={closeImportDialogue}
          floated="right"
        />
      </Modal.Header>
      <ModalContent>
        <CreateAccessPointWizard step={activeStep}></CreateAccessPointWizard>
        <TemplateType
          customClassName={activeStep === 1 ? '' : 'no-display'}
          handleNextStep={nextStepHandler}
          closeHandler={closeImportDialogue}
          projectId={projectId}
        ></TemplateType>
        {activeStep === 2 ? (
          <ViewTemplate
            template={template.current}
            handlePrevStep={prevStepHandler}
            handleNextStep={nextStepHandler}
            closeHandler={closeImportDialogue}
            customClassName={activeStep === 2 ? '' : 'no-display'}
          ></ViewTemplate>
        ) : null}
        {activeStep === 3 ? (
          <CreateAccessPoint
            configTypeLabel={
              template.current != null ? 'PMP Equipment' : 'PMP Network Device'
            }
            configTypeId={template.current || ap.current}
            configName={configName.current}
            handlePrevStep={prevStepHandler}
            closeFunc={closeImportDialogue}
          ></CreateAccessPoint>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

export default injectIntl(CreateAccessPointModal);
