import { createSlice } from '@reduxjs/toolkit';
import { POLARIZATION_OPTIONS } from 'src/app.constants';

const initialState = {
  modalOpen: false,
  antennaPayload: null,
  polarities: POLARIZATION_OPTIONS,
};

const antennaSlice = createSlice({
  name: 'antenna',
  initialState,
  reducers: {
    openAntennaModal: (state, action) => {
      state.modalOpen = true;
      state.antennaPayload = action.payload;
    },
    closeAntennaModal: (state, action) => {
      state.modalOpen = false;
      state.antennaPayload = action.payload;
    },
    setAntennaPolarities: (state, action) => {
      state.polarities = action.payload.polarities;
    },
    reset: (state) => {
      state.antennaPayload = null;
    },
  },
});

export const {
  openAntennaModal,
  closeAntennaModal,
  setAntennaPolarities,
  reset,
} = antennaSlice.actions;
export default antennaSlice.reducer;
