import {
  compareNumbers,
  compareUnavailability,
  meterToAny,
} from 'src/utils/useful_functions';

import _, { get } from 'lodash';
import { store } from 'src/store';
import { METER_TO_FEET } from 'src/app.constants';

const tooltip = ({ data }) => {
  return data.warnings?.hover;
};

export const commonColumn = {
  tooltipValueGetter: tooltip,
};

export const defaultColumn = {
  ...commonColumn,
  minWidth: 120,
  sortable: true,
};

export const numberColumn = {
  ...defaultColumn,
  comparator: compareNumbers,
};

export const heightColumn = {
  ...numberColumn,
  cellRenderer: 'heightRenderer',
  cellClass: ['number-cell'],
  cellRendererParams: {
    precision: 1,
  },
};

export const makeHeightColumn = (field, headerName) => {
  return {
    ...heightColumn,
    field: field,
    headerName: headerName,
    headerValueGetter: ({ context }) => {
      return `${headerName} (${context.prefs.heightUnits})`;
    },
    filterParams: {
      valueGetter: (params) => {
        if (store.getState().mainFrame.prefs.heightUnits === 'ft')
          return Number(_.get(params.data, field) * METER_TO_FEET).toFixed(1);
        else return Number(_.get(params.data, field)).toFixed(1);
      },
    },
    getQuickFilterText: ({ context, data }) => {
      if (context.prefs.heightUnits === 'ft')
        return Number(_.get(data, field) * METER_TO_FEET).toFixed(1);
      else return Number(_.get(data, field)).toFixed(1);
    },
  };
};

export const reliabilityColumn = {
  ...numberColumn,
  cellRenderer: 'fixedRenderer',
  cellEditor: 'numberEditor',
  cellClass: ['number-cell'],
  valueGetter: (params) => valueGetter(params, 4),
  cellEditorParams: {
    min: 0,
    precision: 4,
    max: 100,
  },
};

export const throughputColumn = {
  ...numberColumn,
  cellRenderer: 'fixedRenderer',
  cellEditor: 'numberEditor',
  cellClass: ['number-cell'],
  cellEditorParams: {
    precision: 3,
    min: 0,
    max: Infinity,
  },
  valueGetter: (params) => valueGetter(params, 3),
};

export const unavailabilityColumn = {
  ...defaultColumn,
  comparator: compareUnavailability,
};

export const valueGetter = (params, precision?, nullValue?) => {
  let field: string = get(params, 'column.userProvidedColDef.field');
  let n = get(params, 'data.' + field, '');
  if (typeof n === 'boolean') {
    n = n + '';
    n = n === 'true' ? 'Yes' : 'No';
  }
  let numericalValue;
  if (typeof n === 'string') {
    numericalValue = Number(Number(n).toFixed(precision || 0));
  } else {
    numericalValue = parseFloat(n).toFixed(precision || 0);
  }

  if (!n || n === '') {
    return nullValue !== undefined ? nullValue : 'N/A';
  }

  if (isNaN(numericalValue) === false) {
    return numericalValue;
  }
  return n;
};

/*
 * Parse the Clutter Type string and convert the height to the display units
 */
export const clutterTypeGetter = (params) => {
  const field = params.colDef.field;
  const value = get(params.data, field);
  if (!value) {
    return '';
  }
  // get the height from the value and convert it to the
  // preferred units. The value from the backend is always in metres
  const [clutterType, heightStr] = value.split('(');
  const heightMetres = parseFloat(heightStr);
  const heightDisplayUnits = meterToAny(
    heightMetres,
    params.context.prefs.heightUnits,
    0,
    false
  );
  return `${clutterType} (${heightDisplayUnits.toFixed(1)} ${
    params.context.prefs.heightUnits
  })`;
};
