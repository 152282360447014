import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Image,
  Popup,
  PopupContent,
} from 'semantic-ui-react';
import networkSiteImage from './../../images/help/tower_image.png';
import subscriberSiteImage from './../../images/help/subscriber_site.png';
import ptpLinkImage from './../../images/help/ptp_link.png';
import networkDeviceImage from './../../images/help/network_device.png';
import meshNetworkImage from './../../images/help/mesh_network.png';
import pmpLinkImage from './../../images/help/pmp_link.jpg';
import viewshedImage from './../../images/help/viewshed.jpg';
import mapFilter from './../../images/help/map_filter.png';
import { useIntl } from 'react-intl';
import messages from 'src/messages';
import { uiToggleHelpPopups } from '../mainframe/mainframe.reducer';

const HELP_DETAILS = {
  network_site: {
    imageSrc: networkSiteImage,
    title: messages.networkSites,
    content: messages.networkSitesHelp,
  },
  subscriber_site: {
    imageSrc: subscriberSiteImage,
    title: messages.subscriberSites,
    content: messages.subscriberSitesHelp,
  },
  ptp: {
    imageSrc: ptpLinkImage,
    title: messages.ptpLinks,
    content: messages.ptpLinksHelp,
  },
  access_point: {
    imageSrc: networkDeviceImage,
    title: messages.networkDevices,
    content: messages.networkDevicesHelp,
  },
  mesh: {
    imageSrc: meshNetworkImage,
    title: messages.meshLinks,
    content: messages.meshLinksHelp,
  },
  pmp: {
    imageSrc: pmpLinkImage,
    title: messages.pmpLinks,
    content: messages.pmpLinksHelp,
  },
  viewshed: {
    imageSrc: viewshedImage,
    title: messages.viewsheds,
    content: messages.viewshedHelp,
  },
  map_filter: {
    imageSrc: mapFilter,
    title: messages.mapViewFilter,
    content: messages.mapFilterHelp,
    position: 'right center',
  },
};

const MapIconPopup = (props) => {
  const { trigger, kind } = props;
  const { formatMessage } = useIntl();
  const showHelp = useSelector((state) => state.mainFrame.showHelp);
  const dispatch = useDispatch();

  const helpData = HELP_DETAILS[kind];
  const imageSrc = helpData.imageSrc;
  const title = formatMessage(helpData.title);
  const content = formatMessage(helpData.content);

  return showHelp ? (
    <Popup
      className="map-items-help-text"
      position={helpData.position || 'left center'}
      trigger={trigger}
      offset={[50, 0]}
      basic
      on="hover"
      hoverable
    >
      <PopupContent>
        <Card>
          <Image src={imageSrc} />
          <CardContent>
            <CardHeader>{title}</CardHeader>
            <CardDescription
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <div>{content}</div>
              <Button
                onClick={() => {
                  dispatch(uiToggleHelpPopups());
                }}
              >
                {formatMessage(messages.hideHelp)}
              </Button>
            </CardDescription>
          </CardContent>
        </Card>
      </PopupContent>
    </Popup>
  ) : (
    trigger
  );
};

export default MapIconPopup;
