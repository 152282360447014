import L from 'leaflet';
import eskape from 'eskape';
import { store } from '../../store';
import { saveSite } from '../../api';
import {
  cancelPopup,
  confirmPopup,
  editObject,
  deleteObject,
  createNetworkSiteAndAP,
  getLayers,
  getCanvas,
} from '../../utils/mapUtils';
import { showPopupMenu } from './contextMenu';
import { onmouseover, onmouseout, clickHandler } from './featureEvents';
import { setDirtyObjects, updateObjects } from '../mainframe/mainframe.reducer';
import { encodeLatLong } from 'src/utils/useful_functions';
import { states } from './leafletCanvas';
// import { NETWORK_PANE } from 'src/app.constants';

export const styleSite = (
  feature,
  defaultStroke,
  defaultFill,
  isSelected = false
) => {
  let scale = 3;
  if (
    feature.properties.kind === 'network_site' &&
    feature.properties.node_type === 'POP'
  ) {
    scale = 7;
  } else if (feature.properties.kind === 'network_site') {
    scale = 5;
  }

  const count = feature.properties.count;
  if (count) {
    scale = 12 + Math.log(count);
  }
  const weight = isSelected ? 3 : 1;
  const strokeColor = feature.properties.strokeColor || defaultStroke;
  const fillColor = feature.properties.fillColor || defaultFill;
  const fillOpacity = feature.properties.fillOpacity || 1;
  return {
    fillColor: fillColor,
    fillOpacity: fillOpacity,
    radius: scale,
    color: strokeColor,
    weight: weight,
    // pane: NETWORK_PANE,
  };
};

/**
 * Post the site changes to the backend
 *
 * @param {Object} site  - Leaflet site layer object
 */
const updateSite = (site, initialCoords) => {
  const { lat, lng } = site.getLatLng();
  const siteFeature = site.feature;

  /**
   * Reset the site
   * @callback resetCallback
   */
  const resetCallback = () => {
    site.setLatLng(initialCoords);
  };

  /**
   * Update the site with the new coordinates.
   * Any links or APs attached to the site will
   * be updated.
   * @callback applyCallback
   */
  const applyCallback = () => {
    saveSite(
      {
        id: siteFeature.properties.id,
        projectId: store.getState().mainFrame.projectId,
      },
      {
        name: siteFeature.properties.name,
        latitude: lat,
        longitude: lng,
      }
    ).then((geom) => {
      if (geom?.type === 'CANCEL') {
        console.error('Update cancelled. Check for embargo zone.');
        resetCallback();
      } else {
        store.dispatch(updateObjects({ objects: geom }));
        store.dispatch(setDirtyObjects({ value: true, objects: geom }));
      }
    });
  };
  confirmPopup(site, resetCallback, applyCallback);
};

class SiteDragging {
  #site = null;
  #map = null;
  #hasMoved = false;
  #initialCoords = null;
  #initialLabel = '';

  /**
   * Singleton to be used for dragging a site
   *
   * @constructor
   */
  constructor() {
    const instance = this.constructor.instance;
    if (instance) {
      return instance;
    }
    this.constructor.instance = this;
  }

  initialise = (site, map) => {
    // clean up first
    this.destroy();
    this.#site = site;
    this.#map = map;
    if (site) {
      this.#initialCoords = site.getLatLng();
      this.#initialLabel = site.getTooltip().getContent();
    }
    this.#hasMoved = false;
    map.dragging.disable();
    if (map) {
      map.on('mousemove', this.#drag);
      //   map.on('mouseout', this.#mouseout);
    }
    if (site) {
      site.on('mouseup', this.destroy);
    }
  };

  destroy = () => {
    if (this.#map) {
      this.#map.off('mousemove', this.#drag);
      //   this.#map.off('mouseout', this.#mouseout);
      this.#map.dragging.enable();
    }
    if (this.#site) {
      const site = this.#site;
      site.off('mouseup', this.destroy);
      if (this.#hasMoved) {
        updateSite(site, this.#initialCoords);
      } else {
        site.setLatLng(this.#initialCoords);
      }
    }
    this.#map = null;
    this.#initialCoords = null;
    if (this.#initialLabel && this.#site) {
      this.#site.setTooltipContent(this.#initialLabel);
    }
    this.#initialLabel = null;
    this.#site = null;
  };

  #drag = (event) => {
    const { prefs } = store.getState().mainFrame;
    if (this.#initialCoords) {
      const distanceMoved = event.latlng.distanceTo(this.#initialCoords);
      const zoom = event.target.getZoom();
      this.#hasMoved = zoom >= 17 || distanceMoved > 10;
    } else {
      this.#hasMoved = true;
    }
    const latLng = event.latlng;
    this.#site.setLatLng(latLng);
    const label = `${encodeLatLong(
      latLng.lat,
      prefs.latLngFormat,
      true
    )}, ${encodeLatLong(latLng.lng, prefs.latLngFormat, false)}`;
    this.#site.setTooltipContent(label);
    this.#site.openTooltip(latLng);
  };

  #mouseout = (event) => {
    this.destroy();
  };
}

/**
 * Site feature mousedown event handler
 */
const onSiteMouseDown = (event) => {
  const { button } = event.originalEvent;
  if (button === 0) {
    const { target } = event;
    // not sure if it is cleaner to use getCanvas() here
    // or target._map both seem to be a hack.
    const canvas = getCanvas();
    if (canvas.state !== states.MAP_SELECT) {
      return;
    }
    cancelPopup();
    new SiteDragging().initialise(target, canvas.map);
  }
};

/**
 * Bind events to the site layer and add the label
 *
 * @param {Object} feature   - GeoJSON feature instance
 * @param {Object} layer     - Leaflet GeoJSON layer instance
 */
export const siteFeatureEvents = (feature, layer) => {
  let events = {};
  if (!feature.properties.count) {
    events = {
      mouseout: onmouseout,
      mouseover: onmouseover,
      click: clickHandler,
    };
    if (store.getState().mainFrame.permissionWrite) {
      events = {
        ...events,
        contextmenu: onRightClickSite,
        mousedown: onSiteMouseDown,
      };
    }
  }
  layer.on(events);

  // Add the marker labels to the layer
  const {
    subscriber_site_label,
    network_site_label,
    network_site,
    subscriber_site,
  } = getLayers();
  let permanent = false;
  if (
    (network_site &&
      network_site_label &&
      feature.properties.kind === 'network_site') ||
    (subscriber_site &&
      subscriber_site_label &&
      feature.properties.kind === 'subscriber_site')
  )
    permanent = true;
  let direction = 'top';
  let label = eskape`${feature.properties.name}`;
  let className = 'siteLabel';
  if (feature.properties.count) {
    // clustered site marker
    permanent = true;
    direction = 'center';
    label = feature.properties.count;
    className = 'clusterLabel';
  }

  layer.bindTooltip(`${label}`, {
    interactive: false,
    opacity: 0.9,
    permanent,
    direction,
    className,
  });
};

const onRightClickSite = (event) => {
  // Call the standard click handler to set the current selection
  clickHandler(event);

  /** Callback function when an item is selected in the menu
   *
   * @callback onClickCallback
   */
  const feature = event.target.feature;
  const { kind, id } = feature.properties;
  const [lng, lat] = feature.geometry.coordinates;

  const onclick = (key) => {
    const func = {
      edit: editObject,
      delete: deleteObject,
      createAP: (kind, id) => createNetworkSiteAndAP(lat, lng, id),
    }[key];

    if (func) {
      func(kind, id);
    }
  };

  let menuItems = [
    { key: 'edit', content: 'Edit' },
    { key: 'delete', content: 'Delete' },
  ];
  if (kind === 'network_site') {
    menuItems = [
      { key: 'edit', content: 'Edit' },
      { key: 'createAP', content: 'Create PMP network end' },
      { key: 'delete', content: 'Delete' },
    ];
  }
  // Display the menu
  showPopupMenu(event, menuItems, onclick);
};

/*
 * Create a new marker class for the POP sites
 */
L.popMarker = L.CircleMarker.extend({
  _updatePath() {
    // We want a diamond rather than a circle.
    // Usually the method is called on the "_renderer",
    // but we are hacking that to run in the marker
    const renderer = this._renderer;
    const layer = this;

    if (!renderer._drawing || layer._empty()) {
      return;
    }

    const p = layer._point,
      ctx = renderer._ctx,
      r = Math.max(Math.round(layer._radius), 1),
      s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;

    if (s !== 1) {
      ctx.save();
      ctx.scale(1, s);
    }

    ctx.beginPath();
    ctx.moveTo(p.x, p.y + r);
    ctx.lineTo(p.x + r, p.y);
    ctx.lineTo(p.x, p.y - r);
    ctx.lineTo(p.x - r, p.y);
    ctx.closePath();
    if (s !== 1) {
      ctx.restore();
    }

    renderer._fillStroke(ctx, layer);
  },
});

/**
 * Return the formatting style for the network site markers.
 */
export const networkSiteMarker = (feature, latlng) => {
  if (feature.properties?.node_type === 'POP') {
    return new L.popMarker(latlng, styleSite(feature, '#ff0000', '#ffffff'));
  } else {
    return new L.circleMarker(latlng, styleSite(feature, '#000000', '#ff0000'));
  }
};

/**
 * Return the formatting style for the subscriber site markers.
 */
export const subscriberSiteMarker = (feature, latlng) => {
  return new L.circleMarker(latlng, styleSite(feature, '#000000', '#55a4f3'));
};
