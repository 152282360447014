import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, InputProps } from 'semantic-ui-react';
import { useFormikContext } from 'formik';
import additionalMessages from '../../messages';
import { decodeLatLong, encodeLatLong } from '../../utils/useful_functions';
import SemanticField from './SemanticField';

//Extending the input props from semantic
export type LatLngPropType = InputProps & {
  intl: any;
  isLat: boolean;
  toFormat: number;
};

function LatLngControl(props: LatLngPropType) {
  const { values, setFieldValue } = useFormikContext();
  const {
    intl,
    isLat = true,
    toFormat,
    name,
    label,
    onChange,
    controlValue,
    ...rest
  } = props;
  const { formatMessage } = intl;
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const [value, isValid] = decodeLatLong(values[name], isLat);
    if (isValid) {
      setTimeout(
        () => setFieldValue(name, encodeLatLong(value, toFormat, isLat)),
        0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toFormat, isLat]);

  useEffect(() => {
    if (!isFirstRun.current) {
      setTimeout(
        () => setFieldValue(name, encodeLatLong(controlValue, toFormat, isLat)),
        0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlValue]);

  return (
    <SemanticField
      component={Form.Input}
      showErrorsInline={false}
      onChange={(e, data) => {
        const originalVal = decodeLatLong(data['value'], isLat);
        data['originalVal'] = originalVal;
        onChange(e, data);
      }}
      validate={(value: string) => {
        let error;
        const isValid = decodeLatLong(value, isLat)[1];
        if (!isValid) {
          let name = formatMessage(additionalMessages.latitude);
          if (!isLat) {
            name = formatMessage(additionalMessages.longitude);
          }
          error = formatMessage(additionalMessages.validFieldError, {
            fieldName: name,
          });
        }
        return error;
      }}
      label={
        !label
          ? formatMessage(
              isLat ? additionalMessages.latitude : additionalMessages.longitude
            )
          : label
      }
      {...rest}
      name={name}
    ></SemanticField>
  );
}

export default injectIntl(LatLngControl);
