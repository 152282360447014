import { styleAccessPoint } from './apFeatures';
import { getLinkStyler } from './linkFeatures';
import { styleSite } from './siteFeatures';

const getFeatureStyle = (feature, isSelected) => {
  const kind = feature?.properties?.kind;

  if (kind === 'network_site' && feature.properties.node_type === 'POP') {
    // POP sites use a different style to other network sites
    return styleSite(feature, '#ff0000', '#ffffff', isSelected);
  }

  return {
    network_site: styleSite(feature, '#000000', '#ff0000', isSelected),
    subscriber_site: styleSite(feature, '#000000', '#55a4f3', isSelected),
    access_point: styleAccessPoint(feature, isSelected),
    ptp_link: getLinkStyler('#88DD88', 3)(feature, isSelected),
    mesh_link: getLinkStyler('#1550bd', 3)(feature, isSelected),
    pmp_link: getLinkStyler('#55a4f3', 1.5)(feature, isSelected),
  }[kind];
};

export default getFeatureStyle;
