import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Tab } from 'semantic-ui-react';
import BandSelection from './BandSelection';
import AntennasTable from './AntennasTable';
import { useParams } from 'react-router-dom';
import './CustomAntennas.css';
import { getLocalJSON } from 'src/utils/useful_functions';
import { VALID_PMP_BANDS, VALID_PTP_BANDS } from 'src/model/BandDefinition';

export const antennaTypes = {
  ptp: 'ptp',
  network_device: 'nd',
  subscriber: 'sm',
};

export function sortByBandOrder(selectedValues, orderArray) {
  // Create a map to store the index of each element in the orderArray
  const orderMap = new Map();
  orderArray.forEach((value, index) => {
    orderMap.set(value, index);
  });

  // Custom sorting function based on the orderArray
  selectedValues.sort((a, b) => {
    const indexA = orderMap.get(a);
    const indexB = orderMap.get(b);

    // Compare indices to determine the order
    return indexA - indexB;
  });

  return selectedValues;
}

const AntennaPanel = () => {
  const { antennaType } = useParams();
  const [selectedBands, setSelectedBands] = useState([]);

  let orderBandList = VALID_PMP_BANDS;
  if (antennaType === 'ptp') {
    orderBandList = VALID_PTP_BANDS;
  }

  useEffect(() => {
    // Load data from local storage when the component mounts
    const savedData = getLocalJSON(
      `cn.lp.antennas.${antennaTypes[antennaType]}`,
      []
    );
    const sortedValues = sortByBandOrder(savedData, orderBandList);
    setSelectedBands(sortedValues);
  }, [antennaType]);
  return (
    <Tab.Pane>
      <Grid celled>
        <Grid.Row className="antennaPanel">
          <Grid.Column width={2}>
            <BandSelection
              kind={antennaTypes[antennaType]}
              selectedBands={selectedBands}
              setSelectedBands={setSelectedBands}
            />
          </Grid.Column>
          <Grid.Column width={14}>
            <AntennasTable
              kind={antennaTypes[antennaType]}
              selectedBands={selectedBands}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default injectIntl(AntennaPanel);
