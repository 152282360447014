import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Header, Label, Segment } from 'semantic-ui-react';
import messages from 'src/messages';
import { formatByUnits } from 'src/utils/useful_functions';

function SMPerformanceSummaryLink({ link }) {
  const { formatMessage } = useIntl();
  return (
    <div className="performance-link-summary">
      <Header as="h5" textAlign="center">
        {formatMessage(messages.linkSummary)}
      </Header>
      <div className="grid">
        {[...Object.keys(link)].map((key) => {
          const { label, value, units } = link[key];
          return (
            <Fragment key={label}>
              <Label basic className="no-border">
                {label}
              </Label>
              <div className="value">
                {formatByUnits(value, units)}
              </div>
              <div className="units">{units}</div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default SMPerformanceSummaryLink;
