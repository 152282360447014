import L from 'leaflet';
import { store } from '../../store';
import destination from '@turf/destination';
import distance from '@turf/distance';
import { featureCollection, point, bearingToAzimuth } from '@turf/helpers';
import bearing from '@turf/bearing';
import {
  km_to_height,
  shortHeightUnits,
  convertRange,
  round,
} from '../../utils/useful_functions';
import { cancelPopup, confirmPopup, getCanvas } from '../../utils/mapUtils';
import { postWithAuth } from '../../api';
import { setDirtyObject, updateObjects } from '../mainframe/mainframe.reducer';
import { batch } from 'react-redux';
import { METER_TO_FEET } from '../../app.constants';

// Max AP offset distance in metres
const MAX_OFFSET = 100;

function getSmRange(handle) {
  const { units, centreLatLng } = handle.feature.properties;
  const origin = L.latLng(centreLatLng);
  return convertRange(origin.distanceTo(handle.getLatLng()) / 1000, units);
}

function getApAzimuth(handle) {
  const { centreLatLng } = handle.feature.properties;
  const centrePoint = point(reverseCoords(centreLatLng));
  const { lat, lng } = handle.getLatLng();
  const newPoint = point([lng, lat]);
  return round(bearingToAzimuth(bearing(centrePoint, newPoint)));
}

function getBeamwidth(handle) {
  const { azimuth } = handle.feature.properties;
  const halfBeamwidth =
    ((((azimuth - getApAzimuth(handle)) % 360) + 540) % 360) - 180;
  return Math.abs(halfBeamwidth) * 2;
}

function getOffsetLocation(handle) {
  const { lat, lng } = handle.getLatLng();
  const { centreLatLng } = handle.feature.properties;
  const [origLat, origLng] = centreLatLng;
  if (
    Math.abs(lat - origLat) > 0.000001 ||
    Math.abs(lng - origLng) > 0.000001
  ) {
    // the centre point has moved
    return { latitude: lat, longitude: lng };
  } else {
    // keep the original point so return nothing
    return {};
  }
}

/**
 * Post the AP changes to the backend
 *
 * @param {Object} handle  - Leaflet AP handle layer object
 */
const updateAP = (
  handle,
  initialCoords,
  otherHandle,
  otherHandleInitialCoords,
  draggingLinesCallback
) => {
  /**
   * Revert the AP properties back to the original settings
   * @callback resetCallback
   */
  const resetCallback = () => {
    draggingLinesCallback();

    // reset to the original state
    handle.setLatLng(initialCoords);
    if (otherHandleInitialCoords) {
      if (otherHandle) {
        otherHandle.setLatLng(otherHandleInitialCoords);
      }
    }
  };

  /**
   * Update the AP with the new properties
   * @callback applyCallback
   */
  const applyCallback = () => {
    const canvas = getCanvas();
    const { projectId } = store.getState().mainFrame;
    const apFeature = canvas.selection;
    const { id } = apFeature.properties;

    draggingLinesCallback();

    let payload;
    const { kind } = handle.feature.properties;

    if (kind === 'centre') {
      payload = {
        ...getOffsetLocation(handle),
      };
    } else if (kind === 'azimuth') {
      payload = {
        sm_range: getSmRange(handle),
        azimuth: getApAzimuth(handle),
      };
    } else {
      // "kind" === "beamwidth"
      payload = {
        beamwidth: getBeamwidth(handle),
      };
    }

    postWithAuth(
      `project/${projectId}/access_point/${id}/geom`,
      payload,
      'PATCH'
    ).then((res) => {
      if (!res) {
        console.error('AP geom update failed');
        return;
      }

      batch(() => {
        store.dispatch(updateObjects({ objects: res.geom }));
        store.dispatch(
          setDirtyObject({ id, kind: 'access_point', value: true })
        );
      });
    });
  };

  confirmPopup(handle, resetCallback, applyCallback);
};

/**
 * Return the formatting style for the AP handles.
 */
const handleMarker = (feature, latlng) => {
  return new L.circleMarker(latlng, {
    fillColor: '#d9eefd',
    fillOpacity: 1,
    radius: 6,
    color: '#0058b7',
    weight: 1,
  });
};

const getRadialPoints = (
  ap_lat,
  ap_lng,
  lat,
  lng,
  azimuth,
  smRange,
  rangeUnits,
  beamwidth,
  max_beamwidth
) => {
  if (rangeUnits === 'km') {
    rangeUnits = 'kilometers';
  }
  const centreLatLng = ap_lat && ap_lng ? [ap_lat, ap_lng] : [lat, lng];
  const centreLngLat = reverseCoords(centreLatLng);
  let offset_km = 0;
  if (ap_lat && ap_lng) {
    offset_km = distance(point(centreLngLat), point([lng, lat]));
  }

  const offset = km_to_height(offset_km);
  const halfBeamwidth = beamwidth / 2;
  const minBw = azimuth - halfBeamwidth;
  const maxBw = azimuth + halfBeamwidth;
  const startAngle = minBw % 360;
  const endAngle = maxBw % 360;
  const options = { units: rangeUnits };
  const coreProps = {
    azimuth: azimuth,
    beamwidth: beamwidth,
    centreLatLng: centreLatLng,
    max_beamwidth: max_beamwidth,
    offset: offset,
    range: smRange,
    siteLatLng: [lat, lng],
    units: rangeUnits,
  };
  const cp = point(centreLngLat, {
    kind: 'centre',
    name: 'cp',
    ...coreProps,
  });
  const c1 = destination(cp, smRange, startAngle, options);
  c1.properties = {
    ...c1.properties,
    kind: 'beamwidth',
    name: 'c1',
    ...coreProps,
  };
  const mp = destination(cp, smRange, azimuth, options);
  mp.properties = {
    ...mp.properties,
    kind: 'azimuth',
    name: 'mp',
    ...coreProps,
  };
  const c2 = destination(cp, smRange, endAngle, options);
  c2.properties = {
    ...c2.properties,
    kind: 'beamwidth',
    name: 'c2',
    ...coreProps,
  };
  return [cp, c1, mp, c2];
};

const getHandleLabel = (props) => {
  if (props.kind === 'azimuth') {
    const shortUnits = { kilometers: 'km', miles: 'mi.' }[props.units];
    return `Range: ${
      props.range
    } ${shortUnits}<br />Azimuth: ${props.azimuth.toFixed(0)}&#176;`;
  } else if (props.kind === 'beamwidth') {
    return `Beamwidth: ${props.beamwidth.toFixed(0)}&#176;`;
  } else if (props.kind === 'centre') {
    return `Offset: ${props.offset.toFixed(1)} ${shortHeightUnits()}`;
  }
  return '';
};

/**
 * Base class to handle drawing dashed azimuth/beamwidth lines
 */
class DashedLines {
  #map = null;
  #singleDash = null;
  #beamwidthDashC1 = null;
  #beamwidthDashC2 = null;

  constructor(map) {
    this.#map = map;
  }

  destroy = () => {
    if (this.#singleDash) {
      this.#map.removeLayer(this.#singleDash);
      this.#singleDash = null;
    }

    if (this.#beamwidthDashC1) {
      this.#map.removeLayer(this.#beamwidthDashC1);
      this.#beamwidthDashC1 = null;
    }

    if (this.#beamwidthDashC2) {
      this.#map.removeLayer(this.#beamwidthDashC2);
      this.#beamwidthDashC2 = null;
    }

    this.#map = null;
  };

  /**
   * Draw/update the single azimuth/offset dashed line
   */
  drawCentreLine = (centreLatLng, handleLatLng) => {
    if (this.#singleDash) {
      this.#singleDash.setLatLngs([centreLatLng, handleLatLng]);
    } else {
      this.#singleDash = drawDashedLine(centreLatLng, handleLatLng);

      this.#map.addLayer(this.#singleDash);
      this.#singleDash.bringToBack();
    }
  };

  /**
   * Draw/update the dashed beawwidth lines
   */
  drawBeamwidthLines = (centreLatLng, c1LatLng, c2LatLng) => {
    if (this.#beamwidthDashC1) {
      this.#beamwidthDashC1.setLatLngs([centreLatLng, c1LatLng]);
      this.#beamwidthDashC2.setLatLngs([centreLatLng, c2LatLng]);
    } else {
      this.#beamwidthDashC1 = drawDashedLine(centreLatLng, c1LatLng);
      this.#beamwidthDashC2 = drawDashedLine(centreLatLng, c2LatLng);
      this.#map.addLayer(this.#beamwidthDashC1);
      this.#map.addLayer(this.#beamwidthDashC2);
      this.#beamwidthDashC1.bringToBack();
      this.#beamwidthDashC2.bringToBack();
    }
  };
}

/**
 * Singleton class to handle marker and label updates during drag operations.
 */
export class HandleDragging {
  isDragging = false;
  #handleFeature = null;
  #map = null;
  #hasMoved = false;
  // Start coordinates for the marker
  #initialCoords = null;
  // Coordinates for the other beamwidth handle, if applicable
  // used to reset the other marker if the edit is cancelled
  #otherHandleInitialCoords = null;
  // The initial properties of the drag handle
  #initialProps = null;
  // The modified properties of the handle
  // after dragging.
  #modifiedProps = null;
  // Polyline overlays. Used to
  // show the change in value.
  #lines = null;

  /**
   * Singleton to be used for dragging an AP handle
   *
   * @constructor
   */
  constructor() {
    const instance = this.constructor.instance;
    if (instance) {
      return instance;
    }
    this.constructor.instance = this;
  }

  initialise = (handleFeature, map) => {
    // clean up first
    this.destroy();
    this.#handleFeature = handleFeature;
    this.#map = map;
    this.#lines = new DashedLines(map);
    if (handleFeature) {
      this.#initialCoords = handleFeature.getLatLng();
      this.#initialProps = handleFeature.feature.properties;
    }
    this.#hasMoved = false;
    map.dragging.disable();
    if (map) {
      map.on('mousemove', this.#drag);
      map.on('mouseup', this.#mouseup);
    }
    if (handleFeature) {
      handleFeature.on('mouseup', this.#mouseup);
    }
  };

  destroy = () => {
    this.isDragging = false;

    const lineCallback = () => {
      if (this.#lines) {
        this.#lines.destroy();
        this.#lines = null;
      }
    };

    if (this.#map) {
      this.#map.off('mousemove', (e) => {
        this.#drag(e);
        lineCallback();
      });
      //   this.#map.off('mouseout', this.#mouseout);
      this.#map.off('mouseup', (e) => {
        this.#mouseup(e);
        lineCallback();
      });
      this.#map.dragging.enable();
    }

    if (this.#handleFeature) {
      const handleFeature = this.#handleFeature;
      handleFeature.closePopup();
      handleFeature.off('mouseup', this.#mouseup);

      if (this.#hasMoved) {
        const otherHandle = getCanvas().getOppositeHandle(
          this.#initialProps.name
        );
        updateAP(
          handleFeature,
          this.#initialCoords,
          otherHandle,
          this.#otherHandleInitialCoords,
          lineCallback
        );
      }
    }
    this.#map = null;
    this.#initialCoords = null;
    this.#otherHandleInitialCoords = null;
    this.#initialProps = null;
    this.#modifiedProps = null;
    this.#handleFeature = null;
  };

  #mouseup = (event) => {
    this.destroy();
  };

  #drag = (event) => {
    if (this.#handleFeature) {
      this.#hasMoved = true;
      let label = '';
      let newLatLng = event.latlng;
      const props = this.#handleFeature.feature.properties;
      const { kind, centreLatLng } = props;
      const centreLngLat = reverseCoords(centreLatLng);
      const { latlng } = event;
      if (kind === 'azimuth') {
        label = this.#updateAzimuthLabel(latlng, props, centreLngLat, label);
      } else if (props.kind === 'beamwidth') {
        ({ newLatLng, label } = this.#updateBeamwidthLabel(
          props,
          centreLngLat,
          latlng,
          newLatLng
        ));
      } else if (props.kind === 'centre') {
        ({ newLatLng, label } = this.#updateOffsetLabel(props, latlng));
      }
      this.#handleFeature.setTooltipContent(label);
      this.#handleFeature.openTooltip(newLatLng);
      this.#handleFeature.setLatLng(newLatLng);
    }
  };

  /**
   * Update the azimuth label during dragging
   *
   * Ensure that the AP stays inside the max range
   */
  #updateAzimuthLabel = (latlng, props, centreLngLat, label) => {
    // TODO: Prevent the user from dragging outside the max range
    const distanceMoved_m = latlng.distanceTo(props.centreLatLng);
    const newRangeInUnits = convertRange(distanceMoved_m / 1000, props.units);
    const centrePoint = point(centreLngLat);
    const newPoint = point([latlng.lng, latlng.lat]);
    const newAzimuth =
      round(bearingToAzimuth(bearing(centrePoint, newPoint))) % 360;
    label = getHandleLabel({
      ...props,
      range: newRangeInUnits,
      azimuth: newAzimuth,
    });
    // Draw/update the dashed azimuth centre line
    this.#lines.drawCentreLine(props.centreLatLng, latlng);

    // Show beamwidth lines
    const beamwidth = props.beamwidth;
    const halfBeamwidth = beamwidth / 2;
    const c1LngLat = destination(
      centrePoint,
      newRangeInUnits,
      (newAzimuth - halfBeamwidth) % 360,
      { units: props.units }
    ).geometry.coordinates;
    const c2LngLat = destination(
      centrePoint,
      newRangeInUnits,
      (newAzimuth + halfBeamwidth) % 360,
      { units: props.units }
    ).geometry.coordinates;
    this.#lines.drawBeamwidthLines(
      props.centreLatLng,
      reverseCoords(c1LngLat),
      reverseCoords(c2LngLat)
    );

    this.#modifiedProps = {
      ...this.#initialProps,
      azimuth: newAzimuth,
      range: newRangeInUnits,
    };
    return label;
  };

  /**
   * Update the beamwidth label during dragging
   *
   * Ensure that the beamwidth value is always valid for the AP
   * and update the opposite beamwidth marker during the move.
   */
  #updateBeamwidthLabel = (props, centreLngLat, latlng, newLatLng) => {
    const { azimuth, max_beamwidth, range, units, name } = props;
    const halfMaxBeamwidth = max_beamwidth ? max_beamwidth / 2 : 180;
    const centrePoint = point(centreLngLat);
    let newBearing = round(
      bearingToAzimuth(
        bearing(point(centreLngLat), point([latlng.lng, latlng.lat]))
      )
    );
    let halfBeamwidth = ((((azimuth - newBearing) % 360) + 540) % 360) - 180;
    //   destination;
    if (Math.abs(halfBeamwidth) > halfMaxBeamwidth) {
      // Reduce the beamwidth to the max allowed value
      halfBeamwidth = halfBeamwidth < 0 ? -halfMaxBeamwidth : halfMaxBeamwidth;
    } else if (Math.abs(halfBeamwidth) < 2.5) {
      // Prevent the beamwidth from being < 5 degrees
      halfBeamwidth = halfMaxBeamwidth < 0 ? -2.5 : 2.5;
    }
    // Recalulate the bearing and then the position
    // using the corrected range / beamwidth values
    newBearing = (azimuth - halfBeamwidth) % 360;
    const newLngLat = destination(centrePoint, range, newBearing, {
      units,
    }).geometry.coordinates;
    newLatLng = reverseCoords(newLngLat);
    const newBeamwidth = Math.abs(halfBeamwidth) * 2;
    const label = getHandleLabel({
      ...props,
      beamwidth: newBeamwidth,
    });
    const otherLngLat = destination(
      centrePoint,
      range,
      (azimuth + halfBeamwidth) % 360,
      { units }
    ).geometry.coordinates;

    // Get the other beamwidth marker
    const otherHandle = getCanvas().getOppositeHandle(name);
    if (!this.#otherHandleInitialCoords) {
      this.#otherHandleInitialCoords = otherHandle.getLatLng();
    }
    if (otherHandle) {
      otherHandle.setLatLng(reverseCoords(otherLngLat));
    }

    this.#lines.drawBeamwidthLines(
      props.centreLatLng,
      newLatLng,
      reverseCoords(otherLngLat)
    );

    this.#modifiedProps = {
      ...this.#initialProps,
      beamwidth: newBeamwidth,
    };

    return { newLatLng, label };
  };

  #updateOffsetLabel = (props, latlng) => {
    const { siteLatLng } = props;
    const units = shortHeightUnits();
    // Restrict to 100 m
    const distanceMoved_m = Math.min(MAX_OFFSET, latlng.distanceTo(siteLatLng));
    const newRangeInUnits =
      units === 'm' ? distanceMoved_m : distanceMoved_m * METER_TO_FEET;
    const centrePoint = point(reverseCoords(siteLatLng));
    const currentPoint = point([latlng.lng, latlng.lat]);
    const azimuth = bearingToAzimuth(bearing(centrePoint, currentPoint));
    // fix the coords so that they are <= 100m from the site
    let newLngLat = [latlng.lng, latlng.lat];
    if (distanceMoved_m === 100) {
      newLngLat = destination(centrePoint, distanceMoved_m / 1000, azimuth, {
        units: 'kilometers',
      }).geometry.coordinates;
    }
    const newLatLng = reverseCoords(newLngLat);
    const label = getHandleLabel({
      ...props,
      offset: newRangeInUnits,
    });

    // Draw/update the dashed centre line
    this.#lines.drawCentreLine(siteLatLng, newLatLng);
    this.#modifiedProps = {
      ...this.#initialProps,
      offset: distanceMoved_m,
      ap_lat: newLatLng[0],
      ap_lng: newLatLng[1],
    };

    return { newLatLng, label };
  };
}

/**
 * drag handle mousedown event handler
 */
const onmousedown = (event) => {
  const { button } = event.originalEvent;
  if (button === 0) {
    const { target } = event;
    // not sure if it is cleaner to use getCanvas() here
    // or target._map both seem to be a hack.
    const map = target._map;
    cancelPopup();
    new OverHandle().initialise(null, null);
    const dragger = new HandleDragging();
    dragger.initialise(target, map);
    dragger.isDragging = true;
  }
};

/**
 * Singleton class to paint the dashed lines when the mouse is over a drag handle
 */
class OverHandle {
  #lines = null;

  /**
   * Singleton to be used for when the cursor is over an AP handle
   *
   * @constructor
   */
  constructor() {
    const instance = this.constructor.instance;
    if (instance) {
      return instance;
    }
    this.constructor.instance = this;
  }

  initialise = (handleFeature, map) => {
    // clean up first
    this.destroy();
    this.#lines = new DashedLines(map);

    if (handleFeature) {
      const props = handleFeature.feature.properties;
      const { kind, centreLatLng, siteLatLng } = props;
      const { lat, lng } = handleFeature.getLatLng();
      if (kind === 'azimuth') {
        this.#lines.drawCentreLine(centreLatLng, [lat, lng]);
      } else if (props.kind === 'centre') {
        this.#lines.drawCentreLine(siteLatLng, [lat, lng]);
      }
      if (kind === 'azimuth' || props.kind === 'beamwidth') {
        const { azimuth, beamwidth, range, units } = props;
        const halfBeamwidth = beamwidth / 2;
        const centrePoint = point(reverseCoords(centreLatLng));
        const c1LngLat = destination(
          centrePoint,
          range,
          (azimuth - halfBeamwidth) % 360,
          { units: units }
        ).geometry.coordinates;
        const c2LngLat = destination(
          centrePoint,
          range,
          (azimuth + halfBeamwidth) % 360,
          { units: units }
        ).geometry.coordinates;
        this.#lines.drawBeamwidthLines(
          centreLatLng,
          reverseCoords(c1LngLat),
          reverseCoords(c2LngLat)
        );
      }
    }
  };

  destroy = () => {
    if (this.#lines) {
      this.#lines.destroy();
      this.#lines = null;
    }
  };
}

/**
 * drag handle mouseoverevent handler
 */
const onmouseover = (event) => {
  const dragger = new HandleDragging();
  if (!dragger.isDragging) {
    const { target } = event;
    // not sure if it is cleaner to use getCanvas() here
    // or target._map both seem to be a hack.
    const map = target._map;
    new OverHandle().initialise(target, map);
  }
};

/**
 * Bind events to the AP drag handles
 *
 * @param {Object} feature   - GeoJSON feature instance
 * @param {Object} layer     - Leaflet GeoJSON layer instance
 */
const dragHandleEvents = (feature, layer) => {
  let events = {};
  if (!feature.properties.count) {
    events = {
      mouseover: onmouseover,
      mouseout: (e) => new OverHandle().initialise(null, null),
    };
    if (store.getState().mainFrame.permissionWrite) {
      events = {
        ...events,
        mousedown: onmousedown,
      };
    }
  }
  layer.on(events);

  let permanent = false;
  let direction = 'top';
  const props = feature.properties;

  layer.bindTooltip(`${getHandleLabel(props)}`, {
    interactive: false,
    opacity: 0.9,
    permanent,
    direction,
    className: 'apHandleLabel',
  });
};

export function createAPDragHandles(ap) {
  const { latitude, longitude, radios } = ap.properties;
  const radio = radios[0];
  const antenna = radio.antennas[0];
  const { range_units, sm_range } = radio;
  const { azimuth, beamwidth, max_beamwidth } = antenna;
  const ap_lat = antenna.latitude;
  const ap_lng = antenna.longitude;

  // TODO: Get this from the AP config

  const points = getRadialPoints(
    ap_lat,
    ap_lng,
    latitude,
    longitude,
    azimuth * 1,
    sm_range * 1,
    range_units,
    beamwidth * 1,
    max_beamwidth
  );
  const pointLayer = new L.geoJSON(featureCollection(points), {
    pointToLayer: handleMarker,
    onEachFeature: dragHandleEvents,
  });
  //   ap.dragHandleLayer = pointLayer;
  return pointLayer;
}

/**
 * Draw a dashed Leaflet polyline
 */
const drawDashedLine = (p1, p2) => {
  const line = new L.polyline([p1, p2], {
    color: '#6da5ff',
    dashArray: '5, 5',
    dashOffset: '0',
    strokeOpacity: 0,
  });
  return line;
};

/**
 * Reverse a coord array containing 2 points.
 *
 * Convert lat, lng to lng, lat or lng, lat to lat, lng
 */
const reverseCoords = (coords) => {
  return [coords[1], coords[0]];
};
