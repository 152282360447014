/*
 * V5000 is the only valid AP product at the moment,
 * but build in the capability to easily add other
 * products in the future.
 */
export const maxEirpValues = (
  product: string,
  countryName: string,
  channel: string
): number => {
  return Products[product].limitEirp(channel, countryName);
};

type Country = {
  name: string;
  maxApEirp: number | null;
  maxSmEirp: number | null;
};

export const Countries: { [key: string]: Country } = {
  australia: { name: 'Australia', maxApEirp: 64, maxSmEirp: 64 },
  etsi: { name: 'ETSI', maxApEirp: 55, maxSmEirp: 55 },
  israel: { name: 'Israel', maxApEirp: 55, maxSmEirp: 55 },
  fcc: { name: 'FCC', maxApEirp: 64, maxSmEirp: 64 },
  japan: { name: 'Japan', maxApEirp: 57, maxSmEirp: 57 },
  malaysia: { name: 'Malaysia', maxApEirp: 40, maxSmEirp: 40 },
  other: { name: 'Other', maxApEirp: null, maxSmEirp: null },
  singapore: { name: 'Singapore', maxApEirp: 40, maxSmEirp: 40 },
};

interface cnWaveProduct {
  name: string;
  defaultEirp: number;
  isApProduct: boolean;
  limitEirp: (channel: string, countryName: string) => number;
}

class V5000 implements cnWaveProduct {
  name = 'V5000';
  defaultEirp = 38.1;
  isApProduct = true;
  limitEirp(channel: string, countryName: string): number {
    const country = Countries[countryName];
    let limit = Math.min(
      this.defaultEirp,
      country.maxApEirp || Number.MAX_SAFE_INTEGER
    );
    if (countryName === 'etsi' || countryName == 'israel') {
      if (channel === 'channel4') {
        limit = Math.min(limit, 34.5);
      } else {
        limit = Math.min(limit, 37.5);
      }
    }
    return limit;
  }
}

const Products: { [key: string]: cnWaveProduct } = {
  V5000: new V5000(),
};
