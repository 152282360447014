import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Header, Icon, Popup, Table } from 'semantic-ui-react';
import { ERROR_COLOR, WARNING_COLOR } from 'src/app.constants';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import messages from 'src/messages';
import { formatByUnits, makeChoices } from 'src/utils/useful_functions';

function getWarning(field, end, warnings) {
  const attr = field.attr;
  let warningData = {
    msg: '',
    icon: '',
    color: '',
  };
  const apOrSm = end === 'local' ? 'ap' : 'sm';
  if (
    attr === 'predicted_receive_power' &&
    warnings[`${apOrSm}_receive_level`] != ''
  ) {
    warningData.msg = warnings[`${apOrSm}_receive_level`];
    warningData.icon = warnings[`${apOrSm}_receive_level_is_error`]
      ? 'warning sign'
      : 'warning circle';
    warningData.color = warnings[`${apOrSm}_receive_level_is_error`]
      ? ERROR_COLOR
      : WARNING_COLOR;
  } else if (
    attr === 'max_usable_mode' &&
    warnings[`${apOrSm}_modulation_mode`] != ''
  ) {
    warningData.color = ERROR_COLOR;
  } else if (attr === 'reliability' && !field.valid) {
    warningData.color = ERROR_COLOR;
  }

  return warningData;
}

function SMPerformanceField({
  field,
  setModified,
  end,
  warning,
  choices,
  pathIndex,
}) {
  const { attr, read_only, value, units, valid, tooltip } = field;
  const warningRef = useRef(null);
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { summary } = choices;
  const getter = `results.summary.${pathIndex}.${end}.${attr}.value`;
  const { control } = useFormContext();

  if (field.choices != null) {
    let finalChoices = makeChoices(field.choices);
    if (attr === 'mod_mode_required') {
      const apOrSm = end === 'local' ? 'ap' : 'sm';
      finalChoices = summary[`${attr}_${apOrSm}`]['choices'];
    }
    return finalChoices.length > 1 ? (
      <Controller
        control={control}
        key={getter}
        name={getter}
        render={({ field: { ref, onChange, ...rest } }) => (
          <>
            <Form.Select
              style={{ textAlign: 'right' }}
              options={finalChoices}
              {...rest}
              disabled={!permissionWrite}
              onChange={(e, data) => {
                setModified(true);
                onChange(data.value);
              }}
            ></Form.Select>
          </>
        )}
      />
    ) : (
      <p style={{ textAlign: 'right' }}>{formatByUnits(value, units)}</p>
    );
  } else if (!read_only) {
    return (
      <GenericScaledField
        getter={getter}
        units={units}
        className={'right-align'}
        precision={4}
        min={0}
        max={100}
        defaultValue={formatByUnits(value, units)}
        setModified={setModified}
        disabled={!permissionWrite}
      ></GenericScaledField>
    );
  } else {
    const warningElement: JSX.Element = (
      <p title={tooltip} style={{ color: warning.color }}>
        {formatByUnits(value, units)} {units}
      </p>
    );
    return (
      <div className="d-flex" style={{ flexDirection: 'row-reverse' }}>
        {warning.icon != '' && (
          <Popup
            style={{ color: warning.color }}
            content={warning.msg}
            trigger={
              <span style={{ marginLeft: '6px' }}>
                <Icon name={warning.icon}></Icon>
              </span>
            }
          ></Popup>
        )}
        {warningElement}
      </div>
    );
  }
}

function SMPerformanceSummaryEnds({
  localSiteName,
  remoteSiteName,
  setModified,
  local,
  remote,
  warnings,
  choices,
  pathIndex,
}) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Header as="h5" textAlign="center">
        {formatMessage(messages.performanceToDevices)}
      </Header>
      <Table definition compact verticalAlign="middle">
        <Table.Header style={{ textAlign: 'center' }}>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>
              Performance to ND
              <br /> {localSiteName}
            </Table.HeaderCell>
            <Table.HeaderCell>
              Performance to SM
              <br />
              {remoteSiteName}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {[...Object.keys(local)].map((attr) => {
            const fieldL = local[attr];
            const fieldR = remote[attr];
            return (
              <Table.Row key={`summary-pmp-${attr}`}>
                <Table.Cell width={6} textAlign="right">
                  {fieldL.label}
                </Table.Cell>
                <Table.Cell width={5}>
                  <SMPerformanceField
                    setModified={setModified}
                    field={fieldL}
                    end={'local'}
                    choices={choices}
                    warning={getWarning(fieldL, 'local', warnings)}
                    pathIndex={pathIndex}
                  ></SMPerformanceField>
                </Table.Cell>
                <Table.Cell width={5}>
                  <SMPerformanceField
                    setModified={setModified}
                    field={fieldR}
                    end={'remote'}
                    choices={choices}
                    warning={getWarning(fieldR, 'remote', warnings)}
                    pathIndex={pathIndex}
                  ></SMPerformanceField>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

export default SMPerformanceSummaryEnds;
