import React from 'react';
import { ObjectLiteral } from 'src/utils/types/commonTypes';
import { Entities } from './PerformanceDetails';
import { Grid, Header } from 'semantic-ui-react';
import TableComponent from 'src/components/controls/TableComponent';

const commonHeaders = {
  MCS: 1,
  Mode: 2,
  'Data Rate (Kbps)': 3,
  'MIMO Type': 4,
  Multiplier: 5,
  'Code Rate': 6,
  Payloads: 7,
  'Aggregate Max Data Rate for 1 SM (Gbps)': 8,
  'Aggregate Max Data Rate for 1 SM (Mbps)': 8,
  'Aggregate Max Data Rate for 1 SM (kbps)': 8, //TODO this label varies for PTP 700 product
  // so component need to accept the product family and change accordingly
};

const commonHeadersPTP = {
  MCS: 1,
  Mode: 2,
  'Code Rate': 3,
  'Data Rate (kbps)': 4,
  'MIMO Type': 5,
  Multiplier: 6,
  Profile: 7,
  Payloads: 8,
  'Max Aggregate IP Throughput (Gbps)': 9,
  'Max Aggregate IP Throughput (Mbps)': 9,
  'Max Aggregate IP Throughput (kbps)': 9,
  'STM-1 Carried': 10,
  'E1/T1 Carried': 11,
  'E1 Carried': 12,
  'T1 Carried': 12,
};

const endHeaders = {
  MCS: 1,
  Mode: 2,
  'MIMO Type': 3,
  Multiplier: 4,
  'Max Data Rate for 1 SM (kbps)': 5,
  'Max Data Rate for 1 SM (Gbps)': 5,
  'Max Data Rate for 1 SM (Mbps)': 5, //TODO this label varies for PTP 700 product
  'Max IP Throughput (kbps)': 5,
  'Max IP Throughput (Gbps)': 5,
  'Max IP Throughput (Mbps)': 5, //TODO this label varies for PTP 700 product
  // so component need to accept the product family and change accordingly
  'Fade Margin (dB)': 6,
  'Mode Availability (%)': 7,
  'Receive Time in Mode (%)': 8,
};

const endHeadersPTP = {
  'Max IP Throughput (Gbps)': 1,
  'Max IP Throughput (Mbps)': 1,
  'Max IP Throughput (kbps)': 1,
  'Fade Margin (dB)': 2,
  'Mode Availability (%)': 3,
  'Receive Time in Mode (%)': 4,
};

export type PerformanceDetailsTablesType = {
  entities: Entities;
  kind: string;
  titles: string[];
};

const perfDetailsToTable = (
  rawData: ObjectLiteral,
  type: string,
  kind: string
): [string[], string[][]] => {
  let header: string[] = []; //preferredHeaderOrder;
  let rows: string[][] = [];
  // the key for the rawData could be the mod mode string
  const rawDataValues = Object.values(rawData);

  const commonHeadersCmp = kind === 'ptp' ? commonHeadersPTP : commonHeaders;
  const endHeadersCmp = kind === 'ptp' ? endHeadersPTP : endHeaders;

  if (rawDataValues.length) {
    header = Object.keys(rawDataValues[0]);
    // the values should contain an "index", which can be used to sort the data
    // but we don't want that in the headers
    header = header.filter((v) => v.toLowerCase() !== 'index');
    header.sort((a, b) => {
      if (type === 'common') {
        return commonHeadersCmp[a] - commonHeadersCmp[b];
      } else {
        return endHeadersCmp[a] - endHeadersCmp[b];
      }
    });
    // sort the data values using the index
    rawDataValues.sort((a, b) => b.index - a.index);
    // extract the row values, ignoring any "index" entries in the row
    rows = rawDataValues.map((obj: ObjectLiteral) => header.map((k) => obj[k]));
  }

  //   console.log(rows);
  //   rows.sort((a, b) => a.index - b.index);
  return [header, rows];
};

export const PerformanceDetailsTables = ({
  entities,
  kind,
  titles,
}: PerformanceDetailsTablesType) => {
  return (
    <Grid stackable columns={'equal'} style={{ columnGap: '1rem' }}>
      {entities.map((element, index) => {
        let type = 'ends';
        if (entities.length === 3 && index === 0) {
          type = 'common';
        }
        const [header, rows] = perfDetailsToTable(element, type, kind);
        return (
          <Grid.Column key={index}>
            <Header as="h5" textAlign="center">
              {titles[index]}
            </Header>
            <TableComponent
              headerProps={{
                className: 'performance-details header-height',
              }}
              padded={false}
              size="small"
              compact
              header={header}
              rows={rows}
            ></TableComponent>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};
