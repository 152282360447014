import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Grid, Loader, Segment } from 'semantic-ui-react';
import { getWithAuth } from 'src/api';
import StoredAccordion from 'src/components/StoredAccordion';

const SitePerformanceSummaryAccordion = (props) => {
  const { projectId, id } = props;
  const { data: perfSummary, isError: perfIsError } = useQuery(
    [projectId, 'site', id, 'perfSummary'],
    async () => {
      const perfSummary = await getWithAuth(
        `project/${projectId}/site/${id}/performancesummary`
      );
      return perfSummary != null
        ? Object.entries(perfSummary)
        : Object.entries({ 'No Network Devices': '' });
    }
  );

  if (perfIsError) {
    return (
      <Segment inverted color="red">
        An error occurred while fetching the site performance details.
      </Segment>
    );
  }

  return perfSummary != null ? (
    <StoredAccordion title="PMP Performance Summary">
      <Grid className="performance-summary-grid">
        {perfSummary.map((SitePerSumRow, i) => {
          return (
            <Grid.Row
              columns={2}
              className="performance-summary-grid-row"
              key={i}
            >
              {SitePerSumRow.map((SitePerSumCol, i) => {
                return <Grid.Column key={i}>{SitePerSumCol}</Grid.Column>;
              })}
            </Grid.Row>
          );
        })}
      </Grid>
    </StoredAccordion>
  ) : (
    <Loader active inline="centered" />
  );
};

export default SitePerformanceSummaryAccordion;
