import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { chunk, get } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  meterToAny,
  fixedPrecision,
  runWithConfirmation,
} from '../../utils/useful_functions';
import { CLUTTER_LABELS, CLUTTER_PROPS_COL_PER_ROW } from '../../app.constants';
import messages from '../../messages';
import EditClutterProperties from './EditClutterProperties';
import { postWithAuth } from '../../api';
import { store } from '../../store';
import { uiSet } from '../mainframe/mainframe.reducer';
import { mergeClutterDetails } from '../../pages/project/EditClutterProperties';

function DisplayClutterProperties(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  const [showEdit, setShowEdit] = useState(false);
  const { prefs, clutterDetails, permissionWrite, projectId } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const chunkedValues = chunk(clutterDetails, CLUTTER_PROPS_COL_PER_ROW);

  const closeEditHandler = () => {
    setShowEdit(false);
  };

  const restoreDefault = () => {
    runWithConfirmation({
      header: `${formatMessage(messages.restoreAlertTitle)}`,
      message: `${formatMessage(messages.restoreAlertMsg)}`,
      onConfirm: () => {
        const initialValues = {};

        clutterDetails.forEach((obj: any) => {
          const { key, details } = obj;
          const [color, height] = details;
          initialValues[`${key}_0`] = color;
          initialValues[`${key}_1`] = height;
        });
        postWithAuth(
          `project/${projectId}`,
          { clutter: initialValues },
          'PATCH'
        )
          .then((res) => {
            store.dispatch(
              uiSet({ clutterDetails: mergeClutterDetails(initialValues) })
            );
          })
          .catch((err) => {
            console.log(get(err, 'details.0.msg', 'Unable to update'));
          });
      },
    });
  };

  return (
    <>
      {showEdit && (
        <EditClutterProperties
          onCloseHandler={closeEditHandler}
        ></EditClutterProperties>
      )}
      <Grid style={{ maxWidth: '40vw' }}>
        <Grid.Row>
          <Grid.Column>
            <Button
              icon="edit"
              title={formatMessage(messages.edit)}
              content={formatMessage(messages.edit)}
              disabled={!permissionWrite}
              onClick={() => setShowEdit(true)}
            />
            <Button
              icon="undo"
              title={formatMessage(messages.restore)}
              content={formatMessage(messages.restore)}
              disabled={!permissionWrite}
              onClick={() => restoreDefault()}
            />
          </Grid.Column>
        </Grid.Row>
        {chunkedValues.map((row, index) => (
          <Grid.Row
            columns={CLUTTER_PROPS_COL_PER_ROW}
            width="equal"
            className="custom-row"
            key={index}
          >
            {row.map((column) => {
              const { key, details } = column as any;
              const [color, height] = details;
              return (
                CLUTTER_LABELS[key] !== 'Unclassified' && (
                  <Grid.Column key={key}>
                    <Icon size="large" name="square" style={{ color: color }} />
                    <span>
                      {' '}
                      {fixedPrecision(
                        meterToAny(height, prefs.heightUnits),
                        1
                      )}{' '}
                      {prefs.heightUnits}
                    </span>{' '}
                    <span>{CLUTTER_LABELS[key]}</span>
                  </Grid.Column>
                )
              );
            })}
          </Grid.Row>
        ))}
      </Grid>
    </>
  );
}

export default injectIntl(DisplayClutterProperties);
