import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon, Label, Menu } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import messages from 'src/messages';
import { store } from 'src/store';
import { uiConfirmAction } from './mainframe.reducer';
import { shallowEqual, useSelector } from 'react-redux';
import { getCanvas } from 'src/utils/mapUtils';
import { filterItems } from 'src/workers/filter.utils';
import { FilterContext } from './MainMenu';
import { startCase } from 'lodash';
import LocalStorageUtils from 'src/utils/LocalStorageUtils';
import { makePlural } from 'src/utils/useful_functions';

export const LIST_ITEM_FONT_COLOR = 'rgba(0, 0, 0, 0.87)';
export const SELECTED_LIST_ITEM_BG_COLOR = '#eaeced';

// In pmp link case id in url and id to endpoint are different so to handle that
// case we are sending extra parameter (idInUrl)
export const deleteHandler = (
  kind,
  projectId,
  id,
  formatMessage,
  navigate,
  idInUrl?
) => {
  let url = '',
    postObj,
    header;
  if (kind === 'network_site' || kind === 'subscriber_site') {
    url = `project/${projectId}/sites`;
    header = formatMessage(messages.deleteSites);
    postObj = {
      kind: kind,
      ids: [id],
    };
  } else if (kind === 'pmp_link') {
    url = `project/${projectId}/pmp_links`;
    header = formatMessage(messages.deletePMPLinks);
    postObj = [id];
  } else if (kind === 'mesh_link') {
    url = `project/${projectId}/mesh_links`;
    header = formatMessage(messages.deleteMeshLinks);
    postObj = [id];
  } else if (kind === 'access_point') {
    url = `project/${projectId}/access_points`;
    header = formatMessage(messages.deleteAccessPoints);
    postObj = [id];
  } else if (kind === 'ptp_link') {
    header = formatMessage(messages.deletePTPLinks);
    url = `project/${projectId}/ptp`;
    postObj = [id];
  }
  store.dispatch(
    uiConfirmAction({
      header,
      message: formatMessage(messages.confirm),
      size: 'mini',
      onConfirm: () => {
        postWithAuth(url, postObj, 'DELETE')
          .then(() => {
            if (location.pathname.includes(idInUrl ?? id)) {
              navigate('/');
            }
          })
          .catch(() => {
            console.error(`failed to delete ${kind}, ${id}`);
          });
      },
    })
  );
};

/*
 * Center the map on the item in the list
 */
export const centerMap = (item) => {
  getCanvas().centerOn(item);
};

function MenuHeader({
  expandSidebar,
  count,
  showList,
  setShowList,
  label,
  title,
  headerIconClass,
  path,
  kind,
}) {
  return (
    <div className="menu-header">
      {expandSidebar && (
        <Icon
          name={showList ? 'chevron down' : 'chevron right'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowList(!showList);
          }}
        />
      )}
      <Link to={path} title={title}>
        <Icon
          className={`${headerIconClass}`}
          style={{ marginRight: '10px' }}
        />
        {label}
        {expandSidebar && <Label className="float-right">{count}</Label>}
      </Link>
    </div>
  );
}

function MenuListItem({ item, kind, path, listIconClass }) {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [projectId, permissionWrite] = useSelector(
    (state) => [state.mainFrame.projectId, state.mainFrame.permissionWrite],
    shallowEqual
  );

  const idKey = kind === 'pmp_link' ? 'sm_id' : 'id';
  const { name, strokeColor, warnings } = item;

  let className = null;
  if (location.pathname.endsWith(item[idKey])) {
    className = 'menu-item-active';
  }

  let listIconClassDervied = listIconClass;
  if (typeof listIconClass === 'function') {
    listIconClassDervied = listIconClass(item);
  }

  const id = item[idKey];
  let title = null;
  if (warnings?.hover) {
    title = warnings.hover;
  }

  return (
    <div className="menu-item" key={id} title={title}>
      <Icon
        className={`${listIconClassDervied}`}
        title={formatMessage(messages.selectItem)}
      />

      <Link
        to={`/${path}/${id}`}
        className={className}
        style={{
          color: strokeColor || LIST_ITEM_FONT_COLOR,
          flexGrow: 1,
        }}
      >
        {kind === 'pmp_link' ? name.split(' to ')[1] : name}
      </Link>

      <div className="menu-ctrls">
        <Button
          icon="map marker alternate"
          style={{ padding: 0 }}
          onClick={() => {
            navigate('/');
            centerMap(item);
          }}
          title={formatMessage(messages.showInMap)}
        />
        {permissionWrite && (
          <Button
            icon="trash alternate"
            onClick={() => {
              deleteHandler(
                kind,
                projectId,
                item['id'],
                formatMessage,
                navigate
              );
            }}
            style={{ padding: 0 }}
            title={formatMessage(messages.delete)}
          ></Button>
        )}
      </div>
    </div>
  );
}

function MenuList({
  showList,
  expandSidebar,
  listItems,
  path,
  kind,
  listIconClass,
}) {
  return showList && expandSidebar ? (
    <Menu.Menu
      style={{
        backgroundColor: 'white',
        borderBottom: '1px rgba(34, 36, 38, 0.1) solid',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    >
      {listItems.map((item) => (
        <MenuListItem
          key={item.id}
          item={item}
          kind={kind}
          path={path}
          listIconClass={listIconClass}
        />
      ))}
    </Menu.Menu>
  ) : null;
}

type MenuItemProps = {
  expandSidebar: boolean;
  listItems: [];
  kind: string;
  // path is used in construction of the
  // list item url and backbutton
  path: string;
  headerIconClass: string;
  listIconClass?: string | Function;
  label: React.ReactElement;
  title: string;
};

function MenuItem({
  expandSidebar,
  listItems,
  kind,
  path,
  headerIconClass,
  listIconClass,
  label,
  title,
}: MenuItemProps) {
  const filterText = useContext(FilterContext);
  const location = useLocation();
  const [projectRefId, filterOnMapBounds, mapBounds] = useSelector(
    (state) => [
      state.mainFrame.projectRefId,
      state.mainFrame.filterOnMapBounds,
      state.mainFrame.mapBounds,
    ],
    shallowEqual
  );
  const [showList, setShowList] = useState(false);
  let finalList = listItems.map((item) => {
    const properties = item.properties;
    return { ...properties };
  });
  const totalItems = listItems.length;

  if (filterOnMapBounds || filterText != '') {
    finalList = filterItems(
      finalList,
      filterText,
      filterOnMapBounds ? mapBounds : null,
      Object.keys(getCanvas().drawnFeatures)
    );
  }

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timeStamp: new Date().getTime(),
    });
    setShowList(expandedItems[path] ?? false);
  }, [projectRefId, path]);

  useEffect(() => {
    const localStorageMenuKey = `cn.lp.menu_${projectRefId}`;
    const expandedItems = LocalStorageUtils.getItem(localStorageMenuKey, {
      timeStamp: new Date().getTime(),
    });
    if (showList) {
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        [path]: showList,
        timeStamp: new Date().getTime(),
      });
    } else {
      delete expandedItems[path];
      LocalStorageUtils.setItem(localStorageMenuKey, {
        ...expandedItems,
        timeStamp: new Date().getTime(),
      });
    }
  }, [path, showList]);

  const singleKind =
    kind == 'ptp_link' ? 'PTP Link' : startCase(kind.replace('_', ' '));
  const pluralKind = singleKind + 's';
  const menuTitle = `${totalItems} ${makePlural(
    totalItems,
    singleKind,
    pluralKind
  )}`;

  const isActive = location.pathname.startsWith(`/${path}`);
  const isOnlyTopActive = location.pathname.endsWith(`/${path}`);

  return (
    <>
      <div className="menu-wrapper">
        {expandSidebar && (
          <div
            className="menu-dropdown"
            onClick={() => {
              setShowList((show) => !show);
            }}
          >
            <Icon name={showList ? 'chevron down' : 'chevron right'} />
          </div>
        )}

        <Menu.Item
          title={menuTitle}
          name={kind}
          active={isActive}
          className="menu-header"
          as={Link}
          to={isOnlyTopActive ? '/' : path}
          style={{
            flexGrow: '1',
            placeContent: expandSidebar ? null : 'center',
          }}
        >
          <div>
            <Icon className={`${headerIconClass}`} />
          </div>
          {expandSidebar && <span>{label}</span>}
          {expandSidebar && (
            <div>
              <Label className="float-right">{finalList.length}</Label>
            </div>
          )}
        </Menu.Item>
      </div>

      <MenuList
        showList={showList}
        expandSidebar={expandSidebar}
        listItems={finalList}
        kind={kind}
        path={path}
        listIconClass={listIconClass != null ? listIconClass : headerIconClass}
      />
    </>
  );
}

export default MenuItem;
