import React, { useState, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown, Loader, Popup } from 'semantic-ui-react';
import {
  exportKmz,
  exportProject,
  downloadReport,
  create60GHzReport,
} from './reportHelpers';
import { RootStateOrAny, store } from '../../store';
import { useQuery } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';
import googleEarthIcon from '../../../src/images/Google_Earth_icon.svg';
import linkPlannerIcon from '../../../src/images/linkplanner-coloured.svg';
import cnMaestroIcon from '../../../src/images/menu/cnMaestroLogo.png';
import { setStatusRefetch } from './headermenu.reducer';

const ReportMenuContext = createContext(null);

type Status = '' | 'in_progress' | 'error' | 'ready';

type Report = {
  id: string;
  object_kind: string;
  report_kind: string;
  status: Status;
};

export const ExportMenu = (props) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);

  const { data, refetch } = useQuery({
    queryKey: ['ReportMenuEnabled', projectId],
    queryFn: async () => {
      return await getWithAuth(`project/${projectId}/reports/enabled`);
    },
    retry: false,
  });

  return (
    <ReportMenuContext.Provider value={setOpen}>
      <Dropdown
        title=""
        text={formatMessage({
          id: 'export.export',
          defaultMessage: 'Export',
        })}
        onMouseDown={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        basic
        floating
        labeled
        className="mr-2"
        icon={{ name: '' }}
        onOpen={() => refetch()}
      >
        <Dropdown.Menu>
          {open ? (
            <ExportMenuList {...props} enable60Ghz={data?.cnwave_cnm} />
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </ReportMenuContext.Provider>
  );
};

const ExportMenuList = (props) => {
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { statusRefetch } = useSelector(
    (state: RootStateOrAny) => state.headerMenu.export
  );
  const query = useQuery(
    ['ReportMenu', projectId],
    async () => {
      return await getWithAuth(`project/${projectId}/reports/available`);
    },
    { retry: false }
  );

  if (query.isLoading) {
    return <Loader active />;
  }

  const availableReports: Report[] = query.data;

  const getStatus = (reportKind) => {
    for (let idx in availableReports) {
      const report = availableReports[idx];
      if (
        report.report_kind === reportKind ||
        report.object_kind == reportKind
      ) {
        return { status: report.status, reportId: report.id };
      }
    }
    return '';
  };

  if (statusRefetch) {
    query.refetch();
    store.dispatch(setStatusRefetch(false));
  }

  return (
    <>
      <ExportProject report={getStatus('lpp')} {...props} />
      <GoogleEarthExport report={getStatus('kmz')} {...props} />
      <Dropdown
        item
        simple
        direction="left"
        className="sixtyghz-reports-menu"
        trigger={
          <span>
            <img
              src={cnMaestroIcon}
              alt="cnMaestro Icon"
              style={{ height: '13px', marginRight: '5px' }}
            />
            60GHz cnWave cnMaestro Export
          </span>
        }
      >
        <Dropdown.Menu>
          <CnmNodesExportProject
            report={getStatus('nodes')}
            disabled={!props.enable60Ghz}
            {...props}
          />
          <CnmLinksExportProject
            report={getStatus('links')}
            disabled={!props.enable60Ghz}
            {...props}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const ExportMenuItem = ({
  status,
  progressTitle,
  progressLabel,
  downloadTitle,
  downloadLabel,
  createTitle,
  createLabel,
  downloadFunction,
  createFunction,
  image,
  disabled = false,
  reportKind = null,
  reportId = null,
  popupText = '',
}) => {
  if (status === 'in_progress') {
    return (
      <InProgress
        title={progressTitle}
        label={progressLabel}
        disabled={disabled}
      />
    );
  } else if (status === 'ready') {
    return (
      <ExportDownload
        title={downloadTitle}
        label={downloadLabel}
        clickFunction={downloadFunction}
        disabled={disabled}
        reportKind={reportKind}
        reportId={reportId}
      />
    );
  } else {
    return (
      <CreateExport
        title={createTitle}
        label={createLabel}
        clickFunction={createFunction}
        image={image}
        disabled={disabled}
        reportKind={reportKind}
        popupText={popupText}
      />
    );
  }
};

const InProgress = ({ title, label, disabled }) => {
  return (
    <Dropdown.Item
      title={title}
      icon={{ name: 'spinner', loading: true }}
      text={label}
      disabled={disabled}
    />
  );
};

const ExportDownload = ({
  title,
  label,
  clickFunction,
  disabled,
  reportKind,
  reportId,
}) => {
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const setOpen = useContext(ReportMenuContext);
  return (
    <Dropdown.Item
      title={title}
      icon={{
        name: 'download',
      }}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
        if (reportKind == 'nodes' || reportKind == 'links') {
          let kindText =
            reportKind == 'nodes' ? 'cnMaestroNodes' : 'cnMaestroLinks';
          clickFunction(
            projectId,
            reportId,
            `${projectName}_${kindText}`,
            'csv'
          );
        } else {
          clickFunction(projectId, reportId, projectName, reportKind);
        }
      }}
      text={label}
    />
  );
};

const CreateExport = ({
  title,
  label,
  clickFunction,
  image,
  disabled,
  reportKind,
  popupText = '',
}) => {
  const { formatMessage } = useIntl();
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const setOpen = useContext(ReportMenuContext);
  const menuItem = (
    <Dropdown.Item
      onClick={() => {
        setOpen(false);
        if (reportKind == 'nodes' || reportKind == 'links') {
          clickFunction(projectId, projectName, reportKind);
        } else {
          clickFunction(projectId, projectName, formatMessage);
        }
      }}
      title={title}
      style={{ display: 'flex', minWidth: '220px' }}
      text={label}
      image={image}
      disabled={disabled}
    ></Dropdown.Item>
  );

  if (popupText) {
    return (
      <Popup
        className="report-menu-item-popup"
        position="right center"
        trigger={menuItem}
      >
        {popupText}
      </Popup>
    );
  } else {
    return menuItem;
  }
};

const ExportProject = ({ report }) => {
  const status = report.status;
  const reportId = report.reportId;
  const { formatMessage } = useIntl();
  const progressTitle = formatMessage({
    id: 'export.lpprogressTitle',
    defaultMessage: 'Project export in progress',
  });
  const progresslabel = formatMessage({
    id: 'export.kmzprogresslabel',
    defaultMessage: 'Processing LINKPlanner v5 File',
  });
  const downloadTitle = formatMessage({
    id: 'export.lpDownloadTitle',
    defaultMessage: 'Download the LINKPlanner v5 project file',
  });
  const downloadLabel = formatMessage({
    id: 'export.lpDownloadLabel',
    defaultMessage: 'Download Project File',
  });
  const createTitle = formatMessage({
    id: 'export.lpCreateTitle',
    defaultMessage: 'Export as a LINKPlanner v5 project file',
  });
  const createLabel = formatMessage({
    id: 'export.lpCreateLabel',
    defaultMessage: 'Export LINKPlanner Project File',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progressTitle}
      progressLabel={progresslabel}
      downloadTitle={downloadTitle}
      downloadLabel={downloadLabel}
      createTitle={createTitle}
      createLabel={createLabel}
      downloadFunction={downloadReport}
      createFunction={exportProject}
      reportId={reportId}
      reportKind={'lpp'}
      image={{
        src: linkPlannerIcon,
        style: { height: '13px' },
      }}
    />
  );
};

const GoogleEarthExport = ({ report }) => {
  const status = report.status;
  const reportId = report.reportId;
  const { formatMessage } = useIntl();
  const progressTitle = formatMessage({
    id: 'export.kmzprogressTitle',
    defaultMessage: 'Google Earth™ export in progress',
  });
  const progresslabel = formatMessage({
    id: 'export.kmzprogresslabel',
    defaultMessage: 'Processing Google Earth™ File',
  });
  const downloadTitle = formatMessage({
    id: 'export.kmzDownloadTitle',
    defaultMessage: 'Download the Google Earth™ KMZ file',
  });
  const downloadLabel = formatMessage({
    id: 'export.kmzDownloadLabel',
    defaultMessage: 'Download Google Earth™ File',
  });
  const createTitle = formatMessage({
    id: 'export.kmzCreateTitle',
    defaultMessage: 'Create a Google Earth™ KMZ file from the project',
  });
  const createLabel = formatMessage({
    id: 'export.kmzCreateLabel',
    defaultMessage: 'Export to Google Earth™',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progressTitle}
      progressLabel={progresslabel}
      downloadTitle={downloadTitle}
      downloadLabel={downloadLabel}
      createTitle={createTitle}
      createLabel={createLabel}
      downloadFunction={downloadReport}
      createFunction={exportKmz}
      reportKind={'kmz'}
      reportId={reportId}
      image={{
        src: googleEarthIcon,
        style: { height: '13px' },
      }}
    />
  );
};

const CnmNodesExportProject = ({ report, disabled }) => {
  const status = report.status;
  const reportId = report.reportId;
  const { formatMessage } = useIntl();
  const downloadcnmNodeTitle = formatMessage({
    id: 'export.cnmNodeDownloadTitle',
    defaultMessage: 'Download 60GHz cnWave cnMaestro nodes',
  });
  const downloadcnmNodeLabel = formatMessage({
    id: 'export.cnmNodeDownloadLabel',
    defaultMessage: 'Download 60GHz nodes export',
  });
  const createcnmNodeTitle = formatMessage({
    id: 'export.cnmNodeCreateTitle',
    defaultMessage: 'Export 60GHz cnWave cnMaestro nodes',
  });
  const createcnmNodeLabel = formatMessage({
    id: 'export.cnmNodeCreateLabel',
    defaultMessage: 'Nodes',
  });
  const progresscnmNodeTitle = formatMessage({
    id: 'export.cnmNodeProgressTitle',
    defaultMessage: '60GHz cnWave cnMaestro nodes export in progress',
  });
  const progresscnmNodeLabel = formatMessage({
    id: 'export.cnmNodeProgressLabel',
    defaultMessage: 'Processing 60GHz nodes export',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progresscnmNodeTitle}
      progressLabel={progresscnmNodeLabel}
      downloadTitle={downloadcnmNodeTitle}
      downloadLabel={downloadcnmNodeLabel}
      createTitle={createcnmNodeTitle}
      createLabel={createcnmNodeLabel}
      downloadFunction={downloadReport}
      createFunction={create60GHzReport}
      disabled={disabled}
      reportKind={'nodes'}
      reportId={reportId}
      image={{
        src: cnMaestroIcon,
        style: { height: '13px' },
      }}
      popupText="The CSV file will only contain nodes that have a valid MAC Address and
      MSN."
    />
  );
};

const CnmLinksExportProject = ({ report, disabled }) => {
  const status = report.status;
  const reportId = report.reportId;
  const { formatMessage } = useIntl();
  const downloadcnmLinkTitle = formatMessage({
    id: 'export.cnmLinkDownloadTitle',
    defaultMessage: 'Download 60GHz cnWave cnMaestro links',
  });
  const downloadcnmLinkLabel = formatMessage({
    id: 'export.cnmLinkDownloadLabel',
    defaultMessage: 'Download 60GHz links export',
  });
  const createcnmLinkTitle = formatMessage({
    id: 'export.cnmLinkCreateTitle',
    defaultMessage: 'Export 60GHz cnWave cnMaestro links',
  });
  const createcnmLinkLabel = formatMessage({
    id: 'export.cnmLinkCreateLabel',
    defaultMessage: 'Links',
  });
  const progresscnmLinkLabel = formatMessage({
    id: 'export.cnmLinkProgressLabel',
    defaultMessage: 'Processing 60GHz links export',
  });
  const progresscnmLinkTitle = formatMessage({
    id: 'export.cnmLinkProgressTitle',
    defaultMessage: '60GHz cnWave cnMaestro links export in progress',
  });

  return (
    <ExportMenuItem
      status={status}
      progressTitle={progresscnmLinkTitle}
      progressLabel={progresscnmLinkLabel}
      downloadTitle={downloadcnmLinkTitle}
      downloadLabel={downloadcnmLinkLabel}
      createTitle={createcnmLinkTitle}
      createLabel={createcnmLinkLabel}
      downloadFunction={downloadReport}
      createFunction={create60GHzReport}
      disabled={disabled}
      reportKind={'links'}
      reportId={reportId}
      image={{
        src: cnMaestroIcon,
        style: { height: '13px' },
      }}
      popupText="The CSV file will only contain links that have a valid MAC Address and
      MSN."
    />
  );
};
