import PTPEquipmentPanel from '../PTPEquipmentPanel';
import {
  PTPFormPanel,
  PTPPanelConfigImpl,
  PTPShowProps,
  syncFormState,
  getEquipmentFromChoices,
} from '../utils';
import {
  genericEndPerformanceSummary,
  genericLinkSummary,
  genericEndConfig,
  remoteProductNextValue,
} from './config-utils';

const equipment: PTPFormPanel<PTPShowProps> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'remote_product',
      getter: 'remote.radios.0.equipment.product',
      nextValue: remoteProductNextValue,
      show({ choices }: PTPShowProps) {
        const eqChoices = getEquipmentFromChoices(choices);
        const band = eqChoices?.band?.value;
        const remoteProduct = eqChoices?.remote_product;
        if (
          band != null &&
          remoteProduct != null &&
          (band === '3.5 GHz' || band === '3.6 GHz') &&
          remoteProduct.choices.length === 1
        ) {
          return false;
        }
        return true;
      },
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
    },
    {
      attrName: 'aes_encryption',
      getter: 'local.radios.0.equipment.aes_encryption',
    },
  ],
};

const config: PTPFormPanel<any> = {
  kind: 'config',
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'color_code',
      getter: 'local.radios.0.equipment.color_code',
    },
    {
      attrName: 'frame_period',
      getter: 'local.radios.0.equipment.frame_period',
      units: 'ms',
    },
    {
      attrName: 'downlink_data',
      getter: 'local.radios.0.equipment.downlink_data',
    },
    {
      label: 'MIMO Rate Adapt',
      attrName: 'dual_payload',
      getter: 'local.radios.0.equipment.dual_payload',
    },
    {
      attrName: 'max_multiplier',
      getter: 'local.radios.0.equipment.max_multiplier',
    },
    {
      attrName: 'min_multiplier',
      getter: 'local.radios.0.equipment.min_multiplier',
    },
    {
      attrName: 'master',
      getter: 'local.radios.0.equipment.master',
    },
  ],
};

const ptp450PanelConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config],
  performance: {
    summary: {
      link: [genericLinkSummary()],
      local: genericEndPerformanceSummary('local'),
      remote: genericEndPerformanceSummary('remote'),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: genericEndConfig('local'),
    remote: genericEndConfig('remote'),
  },
};

export default ptp450PanelConfig;
