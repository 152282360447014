const YesNoNaRenderer = ({ value }) => {
  if (value) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  } else {
    return 'N/A';
  }
};

export default YesNoNaRenderer;
