import React from 'react';
import { get } from 'lodash';
import { Form } from 'semantic-ui-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import type { PTPChoices } from 'src/pages/ptp/utils';

type Props = {
  choices: PTPChoices; // Change type if PMP requires this component
  label: string;
  getter: string;
  endName: string;
  diversitySpacingGetter: string;
  defaultDiversitySpacing: number;
  reflectionMitigationGetter: string;
  path: any;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  min: number;
  max: number;
  units: string;
  precision: number;
};

export function SpatialDiversityField(props: Props) {
  const { control } = useFormContext();

  const defaultChecked = get(props.path, props.getter);
  const diversityRequired = props.choices[props.endName].end.diversity_required;

  const watchChecked = useWatch({
    name: props.getter,
    control,
    defaultValue: defaultChecked,
  });
  const checked = diversityRequired || watchChecked;

  let className = null;
  if (!checked) {
    className = 'fields';
  }

  return (
    <div className={className}>
      <Form.Field>
        <Controller
          control={control}
          name={props.getter}
          defaultValue={defaultChecked}
          render={({ field: { ref, onChange, value, ...rest } }) => {
            return (
              <Form.Checkbox
                label={props.label}
                onChange={(e, { checked }) => {
                  props.setModified(true);
                  onChange(checked);
                }}
                checked={diversityRequired || value}
                disabled={diversityRequired || props.disabled}
                {...rest}
              />
            );
          }}
        />
      </Form.Field>
      {checked ? (
        <Form.Group>
          <Form.Field>
            <GenericScaledField
              label="Diversity Spacing"
              getter={props.diversitySpacingGetter}
              defaultValue={get(
                props.path,
                props.diversitySpacingGetter,
                props.defaultDiversitySpacing
              )}
              units={props.units}
              min={props.min}
              max={props.max}
              precision={props.precision}
              choices={props.choices}
              setModified={props.setModified}
              disabled={props.disabled}
            />
          </Form.Field>
          <Form.Field>
            <label>&nbsp; {/* workaround ctrl positioning */}</label>
            <Controller
              control={control}
              name={props.reflectionMitigationGetter}
              defaultValue={get(props.path, props.reflectionMitigationGetter)}
              render={({ field: { ref, onChange, value, ...rest } }) => {
                return (
                  <Form.Checkbox
                    label="Reflection Mitigation"
                    onChange={(e, { checked }) => {
                      props.setModified(true);
                      onChange(checked);
                    }}
                    checked={value}
                    disabled={props.disabled}
                    style={{ paddingTop: '0.3rem' }}
                    {...rest}
                  />
                );
              }}
            />
          </Form.Field>
        </Form.Group>
      ) : null}
    </div>
  );
}
