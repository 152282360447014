import L from 'leaflet';
import eskape from 'eskape';
import { store } from '../../store';
import { deleteObject, editObject } from '../../utils/mapUtils';
import { showPopupMenu } from './contextMenu';
import { clickHandler, onmouseover, onmouseout } from './featureEvents';
// import { NETWORK_PANE } from 'src/app.constants';

/**
 * Bind events to the PTP/PMP link layer and add the label
 *
 * @param {Object} feature   - GeoJSON feature instance
 * @param {Object} layer     - Leaflet GeoJSON layer instance
 */
export const linkEvents = function (feature, layer) {
  let events = {};
  if (!feature.properties.count) {
    events = {
      click: clickHandler,
      mouseover: onmouseover,
      mouseout: onmouseout,
    };
    if (store.getState().mainFrame.permissionWrite) {
      events = {
        ...events,
        contextmenu: onRightClickLink,
      };
    }
  }
  layer.on(events);

  // Add the marker labels to the layer
  let permanent = false;
  let direction = 'top';
  let label = eskape`${feature.properties.name}`;
  let className = 'siteLabel';
  if (feature.properties.count) {
    // clustered site marker
    permanent = true;
    direction = 'center';
    label = feature.properties.count;
    className = 'clusterLabel';
  }

  layer.bindTooltip(`${label}`, {
    interactive: false,
    opacity: 0.9,
    permanent,
    direction,
    className,
  });
};

const onRightClickLink = (event) => {
  // Call the standard click handler to set the current selection
  clickHandler(event);

  /** Callback function when an item is selected in the menu
   *
   * @callback onClickCallback
   */
  const onclick = (key) => {
    // As of now we have only pmp link so we are extracting the
    // sm_id property, in future if we add ptp links then we can
    // change the key appropriately.
    const { kind, id, sm_id, name } = event.target.feature.properties;
    const objId = kind === 'pmp_link' ? sm_id : id;
    if (key === 'edit') {
      editObject(kind, objId);
    } else if (key === 'delete') {
      deleteObject(kind, objId);
    }
  };
  // Display the menu
  showPopupMenu(
    event,
    [
      { key: 'edit', content: 'Edit' },
      { key: 'delete', content: 'Delete' },
    ],
    onclick
  );
};

/**
 * Return the formatting style for the link.
 */
export const getLinkStyler = (colour, strokeWeight) => {
  return (feature, isSelected = false) => {
    const weight = isSelected ? strokeWeight + 3 : strokeWeight;
    const fillOpacity = isSelected ? 0.8 : 0.2;
    if (!feature.properties.count) {
      return {
        color: feature.properties.strokeColor || colour,
        weight: weight,
        fillOpacity: fillOpacity,
        fillColor: feature.properties.strokeColor || colour,
        // pane: NETWORK_PANE,
      };
    }
  };
};

// The SVG colours are restricted to named colours only.
// #f4a460 does work as the fill in some browsers, but
// it may be safer to use the colours from the SVG spec.
const ptpLinkMarkerSVG = `
<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'>
<path d='M 0 30, 30 0' fill='none' stroke='#88DD88' stroke-linecap="round" stroke-width="5" />
</svg>`;
const ptpLinkClusterIcon = encodeURI(
  'data:image/svg+xml,' + ptpLinkMarkerSVG
).replace('#', '%23');

/**
 * Return the formatting style for the PTP link cluster markers.
 */
export const ptpLinkClusterMarker = (feature, latlng) => {
  if (feature.properties.count) {
    const count = feature.properties.count;
    const xscale = 30 + 3 * Math.log(count);
    const yscale = 30 + 3 * Math.log(count);
    const icon = new L.icon({
      iconUrl: ptpLinkClusterIcon,
      iconSize: [xscale, yscale],
      iconAnchor: [xscale / 2, yscale / 2],
    });
    return L.marker(latlng, { icon: icon });
  }
};

// The SVG colours are restricted to named colours only.
// #f4a460 does work as the fill in some browsers, but
// it may be safer to use the colours from the SVG spec.
const pmpLinkMarkerSVG = `
<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'>
<path d='M 0 15, 30 0, M 0 15, 30 30' fill='none' stroke='#55a4f3' stroke-linecap="round" stroke-width="3" />
</svg>`;
const pmpLinkClusterIcon = encodeURI(
  'data:image/svg+xml,' + pmpLinkMarkerSVG
).replace('#', '%23');

/**
 * Return the formatting style for the PMP link cluster markers.
 */
export const pmpLinkClusterMarker = (feature, latlng) => {
  if (feature.properties.count) {
    const count = feature.properties.count;
    const xscale = 30 + 3 * Math.log(count);
    const yscale = 30 + 3 * Math.log(count);
    const icon = new L.icon({
      iconUrl: pmpLinkClusterIcon,
      iconSize: [xscale, yscale],
      iconAnchor: [xscale / 2, yscale / 2],
    });
    return L.marker(latlng, { icon: icon });
  }
};
