import React from 'react';
import LicensedReflectionPanel from './LicensedReflectionPanel';
import UnlicensedReflectionPanel from './UnlicensedReflectionPanel';
import { txFrequencySupportedDevices } from 'src/pages/ptp/utils';
import { injectIntl } from 'react-intl';
import { Profile } from './Profile';
import { ReflectionLine, calculateReflectionLine } from 'src/model/Reflection';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GenericScaledField } from '../controls/rhf/GenericScaledField';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, Form } from 'semantic-ui-react';
import { meterToAny } from 'src/utils/useful_functions';
import { RootStateOrAny } from 'src/store';

type reflectionPanelProps = {
  path: any;
  product: string;
  reflectionLine: ReflectionLine;
  setModified: Function;
};

export const HeightASL = ({ path, setModified }) => {
  const { heightUnits } = useSelector(
    (state: RootStateOrAny) => state.mainFrame.prefs
  );
  const { id } = useParams();

  return (
    <GenericScaledField
      label="Reflection Surface Height (ASL):"
      getter={'reflection.height_asl'}
      defaultValue={path.reflection.height_asl}
      min={-200}
      max={10000}
      units={heightUnits}
      precision={0}
      watch={id}
      setModified={setModified}
    />
  );
};

export const Multiplier = ({ path, setModified }) => {
  const { control } = useFormContext();
  const multiplierList = [1, 3, 5, 7, 9].map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

  return (
    <Form.Field>
      <label htmlFor="multiplier">Multiplier</label>
      <Controller
        name="reflection.multiplier"
        control={control}
        defaultValue={path.reflection.multiplier}
        render={({ field }) => (
          <Dropdown
            className="multiplier"
            data-testid="multiplier"
            placeholder="Select Multiplier"
            selection
            options={multiplierList}
            value={field.value}
            onChange={(e, { value }) => {
              field.onChange(value);
              setModified(true);
            }}
          />
        )}
      />
    </Form.Field>
  );
};

export const calculateDiversity = (
  heightASL,
  localFrequency,
  remoteFrequency,
  localAntennasHeight,
  remoteAntennasHeight,
  profileData,
  clutterDetails
) => {
  const {
    heights,
    ranges,
    clutter_types: clutterTypes,
    obstructions,
    use_clutter,
  } = profileData || {};
  const chartProfile = new Profile({
    heights,
    ranges,
    clutterTypes,
    obstructions,
    use_clutter,
    localHeight: localAntennasHeight,
    remoteHeight: remoteAntennasHeight,
    frequencyGHz: localFrequency / 1000,
    clutterDetails,
  });

  const reflectionLine = calculateReflectionLine(
    chartProfile,
    heightASL,
    localFrequency / 1000,
    remoteFrequency / 1000
  );

  return reflectionLine;
};

export const getCalcSpacing = (value, multiplier, heightUnits) => {
  return (meterToAny(value, heightUnits, 2, false) * multiplier).toFixed(2);
};

export const getCalcOptimumDiversity = (value, heightUnits) => {
  return meterToAny(value, heightUnits, 2, false).toFixed(2);
};

const ReflectionPanel = ({
  product,
  path,
  reflectionLine,
  setModified,
}: reflectionPanelProps) => {
  const childProps = {
    path,
    reflectionLine: reflectionLine ?? {
      optimumDiversityLocal: '',
      optimumDiversityRemote: '',
    },
    setModified,
  };

  if (txFrequencySupportedDevices.includes(product)) {
    return <LicensedReflectionPanel {...childProps} />;
  } else {
    return <UnlicensedReflectionPanel {...childProps} />;
  }
};

export default ReflectionPanel;
