import { OperatingConditions } from 'src/components/OperatingConditions';
import PTPEquipmentPanel from '../PTPEquipmentPanel';
import {
  PTPChoices,
  PTPFormPanel,
  PTPPanelConfigImpl,
  PTPShowProps,
  syncFormState,
  getEndFromChoices,
} from '../utils';
import {
  genericEndPerformanceSummary,
  genericLinkSummary,
  genericEndConfig,
  complexPolarizationConfig,
} from './config-utils';
import additionalMessages from 'src/messages';

const equipment: PTPFormPanel<PTPShowProps> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
    },
    {
      attrName: 'link_protection',
      getter: 'local.radios.0.equipment.link_protection',
      afterOnChange(newValue, { getValues, setValue }) {
        // reset frequencies whenever link kind changes
        const radios = getValues('local.radios');
        for (let i = 0; i < radios.length; i++) {
          // hack: copy across paths to prevent frequency endpoint failing
          // (changing from 4+0 to 1+1 didn't work - not sure why it works
          // for other link kinds)
          setValue(`local.radios.${i}.equipment.link_protection`, newValue);
          setValue(`remote.radios.${i}.equipment.link_protection`, newValue);
          // reset freqs
          setValue(`local.radios.${i}.frequency.tx_frequency`, null, {
            shouldDirty: true,
          });
          setValue(`remote.radios.${i}.frequency.tx_frequency`, null, {
            shouldDirty: true,
          });
        }
      },
    },
    {
      attrName: 'remote_mount',
      getter: 'local.radios.0.equipment.remote_mount',
    },
    {
      attrName: 'ethernet_configuration',
      getter: 'local.radios.0.equipment.ethernet_configuration',
    },
  ],
};

function getSummaryOperatingConditions(): PTPFormPanel<any> {
  return {
    name: 'summary_operating_conditions',
    title: additionalMessages.operatingConditions,
    show: ({ choices }: { choices: PTPChoices }) => {
      return getEndFromChoices(choices, "local").supports_frame_size ?? false;
    },
    component: OperatingConditions,
    fields: [
      {
        label: 'Frame Size',
        getter: 'summary.0.link.frame_size',
        choicesGetter: 'local.end.frame_size_choices',
        valueGetter: 'local.end.default_frame_size',
        units: 'Bytes',
        lk: {
          choicesGetter: 'paths.0.local.end.frame_size_choices',
          valueGetter: 'paths.0.local.end.default_frame_size',
        },
        nextValue(currentValue: string, newChoices: PTPChoices) {
          const endChoices = getEndFromChoices(newChoices, "local");
          const choices = endChoices.frame_size_choices;
          if (choices != null) {
            if (!choices.includes(currentValue)) {
              return endChoices.default_frame_size;
            }
          }
          return null;
        },
      },
    ],
  };
}

const config: PTPFormPanel<any> = {
  kind: 'config',
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'tr_spacing',
      getter: 'local.radios.0.equipment.tr_spacing',
    },
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'modulation_mode',
      getter: 'local.radios.0.equipment.modulation_mode',
      units: 'ms',
    },
    {
      attrName: 'acmb',
      getter: 'local.radios.0.equipment.acmb',
    },
    {
      attrName: 'max_mod_mode',
      getter: 'local.radios.0.equipment.max_mod_mode',
    },
    {
      attrName: 'min_mod_mode',
      getter: 'local.radios.0.equipment.min_mod_mode',
    },
    complexPolarizationConfig(),
    {
      attrName: 'atpc',
      getter: 'local.radios.0.equipment.atpc',
    },
    {
      attrName: 'hi',
      getter: 'local.radios.0.equipment.hi',
    },
  ],
};

const ptp850PanelConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config],
  performance: {
    summary: {
      link: [
        getSummaryOperatingConditions(),
        genericLinkSummary({
          showAggregateThroughput: true,
          showMinPayloadCapacity: true,
          popupAttr: 'summary.@.link.availability_report',
        }),
      ],
      local: genericEndPerformanceSummary('local'),
      remote: genericEndPerformanceSummary('remote'),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: genericEndConfig('local', {
      powerSource: true,
      ethernetCable: true,
    }),
    remote: genericEndConfig('remote', {
      powerSource: true,
      ethernetCable: true,
    }),
  },
};

export default ptp850PanelConfig;
