import React from 'react';
import { Field } from 'formik';
import { Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const SemanticField = ({ component, ...fieldProps }) => {
  const { permissionWrite } = useSelector((state) => state.mainFrame);
  const handleNumberValidations = (e, rest, data, setFieldValue) => {
    const { precision } = rest;
    const value = data?.value !== undefined ? data?.value : rest?.value;
    const decimalLength = getNumber(value, true)?.length;
    if (decimalLength && decimalLength > precision) {
      let dec = '';
      Array.from({ length: precision }, (x, i) => {
        return (dec = dec + getNumber(value, true)[i]);
      });
      setFieldValue(fieldProps.name, getNumber(value, false) + '.' + dec);
    }
  };
  const getNumber = (value, isDecimal) => {
    return (
      value.toString().includes('.') &&
      value.toString().split('.')[isDecimal ? 1 : 0]
    );
  };
  const {
    showErrorsInline,
    disabled,
    onChange: semanticOnChange,
    onBlur: semanticOnBlur,
    //validate,
    className,
    checkPermissions = true,
    ...rest
  } = fieldProps;
  return (
    <Field {...rest}>
      {({
        field: { value, onBlur, ...field },
        form: { setFieldValue, submitCount, touched, errors, handleBlur },
        ...props
      }) => {
        return React.createElement(component, {
          ...rest,
          ...field,
          ...props,
          disabled: disabled || (checkPermissions && !permissionWrite),
          className: `${className} ${
            errors && errors[field.name] && errors[field.name].length
              ? 'error'
              : ''
          }`,
          ...(component === Form.Radio || component === Form.Checkbox
            ? {
                checked:
                  component === Form.Radio ? fieldProps.value === value : value,
              }
            : {
                value: value !== null ? value : '',
              }),

          ...((submitCount >= 1 || touched[field.name]) && errors[field.name]
            ? {
                error: !showErrorsInline
                  ? true
                  : {
                      // content: errors[field.name],
                    },
              }
            : {}),
          onChange: (e, data) => {
            if (semanticOnChange) {
              semanticOnChange(e, data);
            }
            if (data) {
              const { value: newValue, checked } = data;
              if (component !== Form.Radio && checked != null) {
                setFieldValue(fieldProps.name, checked);
              } else {
                setFieldValue(fieldProps.name, newValue);
              }
            }
            if (rest.precision) {
              handleNumberValidations(e, rest, data, setFieldValue);
            }
          },
          onBlur: (e, data) => {
            if (semanticOnBlur) {
              semanticOnBlur(e, data);
              return;
            }
            handleBlur(e, data);
          },
        });
      }}
    </Field>
  );
};

export default SemanticField;
