import React, { useEffect, useState, useMemo } from 'react';
import {
  Dimmer,
  Message,
  Header,
  Button,
  Divider,
  Loader,
  Form,
  Icon,
} from 'semantic-ui-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getWithAuth, postWithAuth } from '../../api';
import additionalMessages from '../../messages';
import { downloadReport } from 'src/utils/useful_functions';
import MeshLinkDetailsPanel from './MeshLinkDetailsPanel';
import { injectIntl } from 'react-intl';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { setModified, setPossibleRecalc } from './mesh.reducer';
import {
  panelNeedsRefresh,
  uiConfirmAction,
} from '../mainframe/mainframe.reducer';
import RouteLeavingGuard from 'src/components/RouteLeavingGuard';
import { isEmpty } from 'lodash';
import { store } from 'src/store';
import messages from '../../messages';
import { PanelHeading, ToolbarSeparator } from 'src/components/PanelHeading';

function checkPossibleRecalc(dirtyFields) {
  const k = Object.keys;

  if (k(dirtyFields).length === 0) {
    return false;
  }

  const allKeys = new Set([
    ...k(dirtyFields?.local ?? []),
    ...k(dirtyFields?.remote ?? []),
    ...k(dirtyFields?.summary?.local ?? []),
    ...k(dirtyFields?.summary?.remote ?? []),
  ]);
  return allKeys.size !== 0;
}

const MeshLinkPanel = (props) => {
  const path = '/mesh';

  const formMethods = useForm();
  const formError = !isEmpty(formMethods.formState.errors);

  const watch = formMethods.watch([
    'local.sector',
    'remote.sector',
    'local.golay',
    'remote.golay',
    'summary.local.mean_data_rate_requirement',
    'summary.local.minimum_data_rate_requirement',
    'summary.local.minimum_reliability_requirement',
    'summary.remote.mean_data_rate_requirement',
    'summary.remote.minimum_data_rate_requirement',
    'summary.remote.minimum_reliability_requirement',
  ]);

  const { meshId } = useParams();
  const { formatMessage } = props.intl;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [projectId, permissionWrite] = useSelector((state) => [
    state.mainFrame.projectId,
    state.mainFrame.permissionWrite,
  ]);
  const { graph } = useSelector((state) => state.sites);
  const needsRefresh = useSelector(
    (state) => state.mainFrame.needsRefresh.meshLinkPanel
  );

  const modifiedRedux = useSelector((state) => state.mesh.modified);
  const modified = modifiedRedux[meshId] ?? false;

  const [currentML, setCurrentML] = useState({});
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [installReportLoading, setInstallReportLoading] = useState(false);
  const [salesReportLoading, setSalesReportLoading] = useState(false);

  const fetchMesh = () => {
    getWithAuth(`project/${projectId}/mesh_link/${meshId}`)
      .then((data) => {
        for (const endName of ['local', 'remote']) {
          const sectorIndex =
            data.summary.equipment_summary[endName].sector - 1;
          const sectorNoise = data[endName].radios[sectorIndex].power.noise;
          const meshNoise = data.summary.equipment_summary[endName].noise;
          data.summary.equipment_summary[endName].noise =
            meshNoise ?? sectorNoise;
        }
        setCurrentML(data);
        formMethods.reset({
          local: data.summary.equipment_summary.local,
          remote: data.summary.equipment_summary.remote,
          summary: {
            local: data.summary.local,
            remote: data.summary.remote,
          },
        });
      })
      .catch((err) => {
        setErrorMessage(err);
      })
      .finally(() => {
        setLoading(false);
        setCalculating(false);
        batch(() => {
          dispatch(setModified({ id: meshId, value: false }));
          dispatch(setPossibleRecalc({ id: meshId, value: false }));
        });
      });
  };

  useEffect(fetchMesh, [projectId, meshId]);

  useEffect(() => {
    if (needsRefresh) {
      fetchMesh();
      dispatch(panelNeedsRefresh({ panels: ['meshLinkPanel'], status: false }));
    }
  }, [needsRefresh]);

  // Memoize the result if we need to recalculate based on the form state
  const { dirtyFields = [] } = formMethods.formState ?? {};
  const possibleRecalc = useMemo(() => {
    return checkPossibleRecalc(dirtyFields);
  }, [watch, dirtyFields]);

  useEffect(() => {
    dispatch(setPossibleRecalc({ id: meshId, value: possibleRecalc }));
  }, [meshId, possibleRecalc]);

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  if (errorMessage != null) {
    return (
      <Message negative>
        <p>
          Error occured while fetching the{' '}
          {graph.aps?.length !== 0
            ? formatMessage(additionalMessages.network_site)
            : formatMessage(additionalMessages.subscriber_site)}{' '}
          details
        </p>
      </Message>
    );
  }

  const onSubmit = (data) => {
    postWithAuth(
      `project/${projectId}/mesh_link/${meshId}/equipment`,
      data,
      'PATCH'
    )
      .then(() => {
        dispatch(setModified({ id: meshId, value: false }));
        setCalculating(true);
      })
      .catch((err) => {});
  };

  const deleteLink = async () => {
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deleteMeshLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          postWithAuth(`project/${projectId}/mesh_links`, [meshId], 'DELETE')
            .then((el) => {
              console.log(el);
              navigate('/mesh');
            })
            .catch(console.error);
        },
      })
    );
  };

  return (
    <div className="flex-1" style={{ height: '100%' }}>
      {currentML?.local?.name ? (
        <>
          <FormProvider {...formMethods}>
            <Form
              onSubmit={formMethods.handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <PanelHeading
                title={
                  <>
                    {' '}
                    Mesh Link:{' '}
                    <Link to={`/aps/${currentML.local.id}`}>
                      {currentML.local.name}
                    </Link>{' '}
                    to{' '}
                    <Link to={`/aps/${currentML.remote.id}`}>
                      {currentML.remote.name}
                    </Link>
                  </>
                }
                apply={
                  <Form.Button
                    compact
                    type="submit"
                    className="save"
                    primary
                    disabled={!modified || formError}
                    accesskey="a"
                  >
                    {formatMessage(additionalMessages.apply)}
                  </Form.Button>
                }
                toolbar={
                  <Form.Group>
                    {permissionWrite && (
                      <>
                        <Button
                          compact
                          basic
                          type="button"
                          title={'Delete'}
                          onClick={() => deleteLink()}
                          icon="trash alternate"
                          color="red"
                        ></Button>
                        <ToolbarSeparator />
                      </>
                    )}

                    <Button
                      icon
                      basic
                      compact
                      loading={salesReportLoading}
                      title={formatMessage(additionalMessages.salesReport)}
                      disabled={
                        graph.aps?.length === 0 || modified || formError
                      }
                      onClick={() => {
                        setSalesReportLoading(true);
                        getWithAuth(
                          `project/${projectId}/reports/mesh_link/${meshId}/proposal`
                        )
                          .then((repId) => {
                            downloadReport(
                              getWithAuth,
                              projectId,
                              repId,
                              `Mesh_${currentML?.local.name}_Proposal_Report`
                            ).finally(() => {
                              setSalesReportLoading(false);
                            });
                          })
                          .catch(() => {
                            setSalesReportLoading(false);
                          });
                      }}
                    >
                      <Icon className="proposal-report-icon" size="large" />
                    </Button>

                    <Button
                      icon
                      basic
                      compact
                      loading={installReportLoading}
                      title={formatMessage(
                        additionalMessages.installationReport
                      )}
                      disabled={
                        graph.aps?.length === 0 || modified || formError
                      }
                      onClick={() => {
                        setInstallReportLoading(true);
                        getWithAuth(
                          `project/${projectId}/reports/mesh_link/${meshId}/installation`
                        )
                          .then((repId) => {
                            downloadReport(
                              getWithAuth,
                              projectId,
                              repId,
                              `Mesh_${currentML?.local.name}_Installation_Report`
                            ).finally(() => {
                              setInstallReportLoading(false);
                            });
                          })
                          .catch(() => {
                            setInstallReportLoading(false);
                          });
                      }}
                    >
                      <Icon className="installation-report-icon" size="large" />
                    </Button>

                    {(calculating || needsRefresh) && (
                      <Header style={{ margin: '0' }}>
                        <Loader active inline size="tiny" />{' '}
                        {formatMessage(additionalMessages.calculatingResults)}
                      </Header>
                    )}
                  </Form.Group>
                }
              />
              <MeshLinkDetailsPanel
                id={meshId}
                currentMesh={currentML}
                loader={loading}
                parentFormSubmitHandler={formMethods.handleSubmit(onSubmit)}
                modified={modified}
              />
            </Form>
          </FormProvider>

          <RouteLeavingGuard
            when={modified}
            shouldBlockNavigation={() => modified}
            yes="Yes"
            no="No"
            title={`Mesh Link: ${currentML.local.name} to ${currentML.remote.name}`}
            content={formatMessage(additionalMessages.warning)}
            callback={() => formMethods.reset()}
          />
        </>
      ) : (
        <Loader active inline />
      )}
    </div>
  );
};

export default injectIntl(MeshLinkPanel);
