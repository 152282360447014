import React from 'react';
import { Header, Message, Segment } from 'semantic-ui-react';

export default function MeshModePanel() {
  return (
    <Segment className="map-mode-panel">
      <Segment basic textAlign="center">
        <Header as="h2">Mesh Link Mode</Header>
      </Segment>
      <Message info>
        <p>
          Select 2 locations to create a new mesh link. Click existing PMP
          network device sectors to choose the locations.
        </p>
      </Message>
    </Segment>
  );
}
