import L from 'leaflet';
import { decode } from '@msgpack/msgpack';
import geojsonvt from 'geojson-vt';
import { featureCollection, point } from '@turf/helpers';
import { getWithAuth, postWithAuth } from 'src/api';
import {
  shortHeightUnits,
  metresToHeightUnits,
  setLocalJSON,
  getLocalJSON,
  encodeLatLong,
} from '../../utils/useful_functions';
import { centerOfMass } from './mapTiles';
import { store } from 'src/store';
// import {
//   TOWER_PANE,
//   TOWER_PANE_ZINDEX,
//   TOWER_ZINDEX_OFFSET,
// } from 'src/app.constants';

const CROWN_CASTLE = 'Crown Castle';

const CROWN_TOWER_ICON = L.icon({
  iconUrl: '/assets/CrownCastleLogo.svg',
  iconSize: [22, 32],
  iconAnchor: [11, 32],
});

const COMPANY_DETAILS = {
  CC: {
    companyName: CROWN_CASTLE,
    email: 'is.vertical@crowncastle.com',
    phone: '844-724-0076',
    icon: CROWN_TOWER_ICON,
    strokeColor: 'black',
    fillColor: '#481776',
    labelStyle: 'cc_tower_count_label',
  },
};

COMPANY_DETAILS[CROWN_CASTLE] = { ...COMPANY_DETAILS.CC };

/**
 * Fetch and unpack tower data file
 */
const getTowerData = (filename, callback) => {
  fetch(filename)
    .then((response) => response.arrayBuffer())
    .then((data) => callback(decode(data)))
    .catch((err) => console.error);
};

/**
 * Callback that is used to create the layer
 * once the tower data has been fetched and unpacked.
 */
const addTowerSitesCallback = (canvas, name) => {
  return (data) => {
    createTowerLayer(canvas, name, data);
  };
};

/**
 * Load the packed tower files from the server
 *
 * The data is unpacked and a tile index is then created.
 * Separate map layers are created for each file.
 * These appear as layer checkboxes in the map layer control.
 */
export const createTowerLayers = (canvas) => {
  Object.values(canvas.towerLayers).map((towerLayer) => {
    const visibleTiles = [...Object.values(towerLayer.drawnTiles)];
    visibleTiles.map((tile) => tile.remove());
    towerLayer.remove();
  });
  canvas.towerLayers = {};

  //   const map = canvas.map;
  //   map.createPane(TOWER_PANE);
  //   map.getPane(TOWER_PANE).style.zIndex = TOWER_PANE_ZINDEX;
  //   map.getPane(TOWER_PANE).style.pointerEvents = 'none';

  getTowerData('/assets/cc.bin', addTowerSitesCallback(canvas, CROWN_CASTLE));
};

/**
 * Create the tile index for the tower layer
 *
 * This is used to find the tower sites that are inside
 * the current map tile. Only the sites in view are displayed.
 * If there are too many sites in the tile then they will be
 * clustered so that the map remains responsive.
 */
const createCacheAndIndex = (data) => {
  let featureCache = {};
  const towerFeatures = data.map((tower) => {
    const [id, lat, lng] = tower;
    const geom = point([lng, lat], { id });
    geom.id = id;
    featureCache[id] = geom;
    return geom;
  });

  const tileIndex = geojsonvt(featureCollection(towerFeatures), {
    maxZoom: 21,
    // debug: 1,
    // indexMaxPoints: 0,
  });
  return [featureCache, tileIndex];
};

/**
 * Define the L.GridLayer that is used to tile the tower locations
 */
const createTowerLayer = (canvas, name, data) => {
  const [featureCache, tileIndex] = createCacheAndIndex(data);
  // Create the GridLayer which requests the visible tiles
  L.GridLayer.TowerLayer = L.GridLayer.extend({
    // Create map tiles on demand
    createTile: (coords) => createTile(coords, canvas, name),
    // Clean up map tiles when they go out of view
    _removeTile: (key) => removeTile(key, canvas, name),
    onAdd: (map) => {
      setLocalJSON(`tower_layer_${name}`, true);
      L.GridLayer.prototype.onAdd.call(towerLayer, map);
    },
    onRemove: (map) => {
      setLocalJSON(`tower_layer_${name}`, false);
      const towerLayer = canvas.towerLayers[name];
      const visibleTiles = [...Object.values(towerLayer.drawnTiles)];
      visibleTiles.map((tile) => tile.remove());
      L.GridLayer.prototype.onRemove.call(towerLayer, map);
    },
    show: (visible) => {
      const towerLayer = canvas.towerLayers[name];
      towerLayer.visible = visible;
      Object.values(towerLayer.drawnTiles).forEach((l) => {
        if (visible) {
          l.addTo(canvas.map);
        } else {
          l.removeFrom(canvas.map);
        }
      });
    },
    updateWhenZooming: false,
    updateWhendle: true,
    tileIndex,
    featureCache,
    visible: true,
    // Used to keep track of the tiles in
    // view so that they can be removed
    drawnTiles: {},
  });

  L.gridLayer.TowerLayer = function (opts) {
    return new L.GridLayer.TowerLayer(opts);
  };

  // Create the new tower layer
  const towerLayer = new L.gridLayer.TowerLayer({
    // zIndex: TOWER_PANE_ZINDEX,
    // pane: TOWER_PANE,
  });

  canvas.towerLayers[name] = towerLayer;

  // Add the tower layer to the layer control when it isn't in AFC mode
  if (location.pathname !== '/afc') {
    canvas.map._ctrlPanel.addOverlay(towerLayer, name);

    // Only display the layer if the user hasn't previously unchecked it
    if (getLocalJSON(`tower_layer_${name}`, true)) {
      towerLayer.addTo(canvas.map);
      towerLayer.bringToBack();
      towerLayer.visible = true;
    } else {
      towerLayer.visible = false;
    }
  }
};

/**
 * Create a new tower tile for the provided coordinates
 */
const createTile = (coords, canvas, name) => {
  const tile = L.DomUtil.create('canvas', 'leaflet-tile');
  const { x, y, z } = coords;
  if (z < 3) {
    return tile;
  }
  const towerLayer = canvas.towerLayers[name];
  const key = towerLayer._tileCoordsToKey(coords);

  if (towerLayer.drawnTiles[key] !== undefined) {
    return tile;
  }
  const towersInTile = towerLayer.tileIndex.getTile(z, x, y);
  if (towersInTile) {
    const featureCount = towersInTile.numFeatures;
    if (featureCount) {
      const cache = towerLayer.featureCache;
      const visibleTowers = towersInTile.features.map(function (f) {
        return cache[f.id];
      });
      const isClustered = featureCount > 20;
      let featureCol;
      let featureEvents = {};
      if (isClustered) {
        featureCol = centerOfMass(visibleTowers);
        featureCol.properties.count = featureCount;
        featureCol.properties.scale = 7 + Math.log(featureCount);
      } else {
        featureCol = featureCollection(visibleTowers);
        featureEvents = { onEachFeature: towerFeatureEvents };
      }
      const tileLayer = new L.geoJSON(featureCol, {
        pointToLayer: getMarker(name),
        ...featureEvents,
      });

      towerLayer.drawnTiles[key] = tileLayer;
      if (towerLayer.visible) {
        canvas.map.addLayer(tileLayer);
      }
    }
  }
  return tile;
};

/**
 * Remove the tower tiles when they go out of view
 */
const removeTile = (key, canvas, name) => {
  const towerLayer = canvas.towerLayers[name];
  const drawnTiles = towerLayer.drawnTiles;

  if (drawnTiles[key]) {
    const tileLayer = drawnTiles[key];
    tileLayer.removeFrom(canvas.map);
    delete drawnTiles[key];
  }
  L.GridLayer.prototype._removeTile.call(towerLayer, key);
};

/**
 * Tower marker styling
 */
const getMarker = (name) => {
  const towerMarker = (feature, latlng) => {
    const companyDetails = COMPANY_DETAILS[name];
    const count = feature.properties?.count;
    if (count) {
      // clustered tower marker
      const scale = feature.properties.scale;
      const fillOpacity = 0.8;
      const style = {
        fillColor: companyDetails.fillColor,
        fillOpacity: fillOpacity,
        radius: scale,
        color: companyDetails.strokeColor,
        weight: 1,
        // pane: TOWER_PANE,
        // zIndexOffset: TOWER_ZINDEX_OFFSET,
        // riseOnHover: true,
      };
      const marker = new L.circleMarker(latlng, style);
      marker.bindTooltip(`${count}`, {
        permanent: true,
        direction: 'center',
        className: companyDetails.labelStyle,
        opacity: 1,
      });

      return marker;
    } else {
      // Single tower marker
      const icon = companyDetails.icon;
      return L.marker(latlng, {
        icon: icon,
        // pane: TOWER_PANE,
        // zIndexOffset: TOWER_ZINDEX_OFFSET,
        // riseOnHover: true,
      });
    }
  };
  return towerMarker;
};

/**
 * Bind events to the tower layer
 *
 * @param {Object} feature   - GeoJSON feature instance
 * @param {Object} tileLayer     - Leaflet GeoJSON layer instance
 */
export const towerFeatureEvents = (feature, tileLayer) => {
  if (!feature.properties?.count) {
    const events = {
      click: clickHandler,
    };
    tileLayer.on(events);
  }
};

/**
 *Tower feature click handler
 */
const clickHandler = (event) => {
  const { target } = event;
  showPopup(target);
};

/**
 * Create the pop-up for an individual tower.
 *
 * The pop-up will not be displayed for clustered towers.
 */
const showPopup = (target) => {
  const { prefs } = store.getState().mainFrame;
  const feature = target.feature;
  const url = `tower/${feature.id}`;

  getWithAuth(url).then((towerData) => {
    const lng = feature.geometry.coordinates[0].toFixed(5);
    const lat = feature.geometry.coordinates[1].toFixed(5);

    // Remove the prefix from the start of the ID
    const towerId = towerData.id.slice(3);
    // Use the prefix to indicate the chosen company
    const companyPrefix = towerData.id.slice(0, 2);
    const { companyName, email, phone } = COMPANY_DETAILS[companyPrefix];

    const { permissionWrite, projectId } = store.getState().mainFrame;

    let createSite = '';
    if (permissionWrite) {
      window.__LP_map_createSite = () => {
        postWithAuth(`project/${projectId}/tower/${towerData.id}/create`, {
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
        });
        target.closePopup();
        if (window.__LP_map_createSite) {
          delete window.__LP_map_createSite;
        }
      };
      createSite = `
        <br/>
        <a onclick="window.__LP_map_createSite && window.__LP_map_createSite()">Create network site here</a>
        `;
    }
    const subject = `Asset: ${towerId} - ${towerData.name}`;
    const description = `
        <div id='tower-popup'><span class='heading'>${companyName}</span>
        <br/>
        <span class='label'>Asset ID:</span> ${towerId}
        <br/>
        <span class='label'>Asset Name:</span> ${towerData.name}
        <br/>
        <span class='label'>Structure Type:</span> ${towerData.structure_type}
        <br/>
        <span class='label'>Height:</span> ${metresToHeightUnits(
          towerData.height,
          shortHeightUnits(),
          1
        )}${
      towerData.rooftop
        ? `
            <div class="ui info message rooftop">
            <b>Note:</b>
            This is the height from the rooftop.
            Please adjust the site maximum height to reflect the 
            building height + this tower height.
            </div>
            `
        : ''
    }
        <br/>
        <span class='label'>Coordinates:</span> ${encodeLatLong(
          lat,
          prefs.latLngFormat,
          true
        )}, ${encodeLatLong(lng, prefs.latLngFormat, false)}
        <br/>
        <span class='label'>Email:</span> <a href='mailto:${email}?subject=${subject}'>${email}</a>
        <br/>
        <span class='label'>Telephone:</span> ${phone}
        ${createSite}
        </div>
        `;
    target.bindPopup(description).openPopup();
  });
};
