import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, Grid, Message, Popup } from 'semantic-ui-react';
import messages from './../../messages';
import CustomAccordion from 'src/components/controls/CustomAccordion';
import {
  Inputs,
  lineofSitesFormRegisterTypes,
  NetworkPlan,
  sitesFormRegisterTypes,
} from './terragraph.modal';
import { injectIntl } from 'react-intl';
import {
  getFiles,
  handleNumbers,
  mcsTypes,
  uploadBoundaryFiles,
  getNumberFieldValidateObject,
  getError,
  setPrecisionWithoutRounding,
} from './terragraph.service';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { anyToMeter, meterToAny } from 'src/utils/useful_functions';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import './InputsStep.css';

function InputsStep(props: {
  inputsFormData: Inputs;
  networkPlanData: NetworkPlan;
  inputsFormDataChange: Function;
  setValidate: Function;
  setLoading: Function;
  intl: any;
  setFileValues: Function;
  boundaryFile: any;
  status: string;
}) {
  const {
    inputsFormData,
    inputsFormDataChange,
    setValidate,
    setLoading,
    networkPlanData,
    intl,
    setFileValues,
    boundaryFile,
    status,
  } = props;

  const { formatMessage } = intl;

  const { heightUnits } = useSelector(
    (state: RootStateOrAny) => state.mainFrame.prefs
  );

  const { projectId, networkSites } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const [uploadedFileName, setUploadedFileName] = useState(
    boundaryFile.file?.name || ''
  );
  useEffect(() => {
    setUploadedFileName(boundaryFile.file?.name || '');
  }, [projectId]);

  const {
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: inputsFormData,
  });

  const onFormValueChange = ($event) => {
    if (get($event, 'target.id', null) !== 'boundryPolygon') {
      inputsFormDataChange(getValues());
      validateForm();
    }
  };

  const validateForm = () => {
    setTimeout(() => {
      let values: any = Object.keys(getValues());
      trigger(values).then((el) => {
        setValidate(el);
      });
    }, 0);
  };
  // When Distribution is selected, the subplan is not cleared.
  const networkSubPlan =
    networkPlanData.networkPlan == 'Mesh' ? networkPlanData.networkSubPlan : '';

  useEffect(() => {
    validateForm();
    getFiles(projectId, networkSubPlan)
      .then((resp: any) => {
        if (resp.site_counts) {
          setSitesCountFromBackend(resp.site_counts || {});
        }
        if (resp.files.length) {
          setFileValues({
            file: { name: resp.files[0] },
            uploaded: true,
            loading: false,
            changed: false,
            warning: resp.warning,
          });
        } else {
          setFileValues({
            file: null,
            uploaded: false,
            loading: false,
            changed: false,
            warning: '',
          });
        }
      })
      .catch((err) => {
        setFileValues({
          file: null,
          uploaded: false,
          loading: false,
          changed: false,
          warning: '',
        });
      });
  }, []);

  const getMaxHeightValue = () => {
    if (heightUnits.toLowerCase() !== 'm') {
      return meterToAny(3000, heightUnits);
    } else {
      return 3000;
    }
  };

  const getMinValueForMaxLos = () => {
    if (heightUnits.toLowerCase() !== 'm') {
      return meterToAny(6, heightUnits, 0);
    } else {
      return 6;
    }
  };

  const getMaxValueForMaxLos = () => {
    if (heightUnits.toLowerCase() !== 'm') {
      return meterToAny(1000, heightUnits, 0);
    } else {
      return 1000;
    }
  };

  const getMinPOPDNSitesCount = () => {
    if (!Number(getValues('pops')) && !Number(getValues('dns'))) return 1;
    return 0;
  };

  const getMinCNSitesCount = () => {
    if (
      networkPlanData.networkPlan === 'Distribution' &&
      !Number(getValues('cns'))
    )
      return 1;
    return 0;
  };

  useEffect(() => {
    setValue('cnHeight', setUnitValues(inputsFormData.cnHeight, 1));
    setValue('dnHeight', setUnitValues(inputsFormData.dnHeight, 1));
    setValue('popHeight', setUnitValues(inputsFormData.popHeight, 1));
    setValue('maxLosDistance', setUnitValues(inputsFormData.maxLosDistance, 0));
    setValue('heightUnits', heightUnits);
    inputsFormDataChange(getValues());
    validateForm();
  }, [heightUnits]);

  const setUnitValues = (value, precision) => {
    if (getValues('heightUnits') !== heightUnits) {
      if (heightUnits.toLowerCase() !== 'm') {
        value = meterToAny(value, heightUnits, precision, false);
        return setPrecisionWithoutRounding(value, precision);
      } else {
        value = anyToMeter(value, getValues('heightUnits'), precision, false);
        return setPrecisionWithoutRounding(value, precision);
      }
    } else {
      return setPrecisionWithoutRounding(value, precision);
    }
  };

  const setSitesCountFromBackend = (sitesCount: {
    POP: any;
    DN: any;
    CN: any;
  }) => {
    setValue('pops', sitesCount.POP || 0);
    setValue('dns', sitesCount.DN || 0);
    setValue('cns', sitesCount.CN || 0);
    // Update the input forms data so that it can be re used in step5
    inputsFormData.pops = sitesCount.POP || 0;
    inputsFormDataChange(getValues());
    validateForm();
  };

  let sites: {
    key: sitesFormRegisterTypes;
    label: string;
    validators: any;
    helpTextKey: string;
  }[] = [
    {
      key: 'pops',
      label: 'POP Sites',
      validators: {
        min: {
          value: getMinPOPDNSitesCount(),
          message: formatMessage(messages.minValueError, {
            value: getMinPOPDNSitesCount(),
          }),
        },
      },
      helpTextKey: 'popSitesHelpText',
    },
    {
      key: 'dns',
      label: 'DN Sites',
      validators: {
        min: {
          value: getMinPOPDNSitesCount(),
          message: formatMessage(messages.minValueError, {
            value: getMinPOPDNSitesCount(),
          }),
        },
      },
      helpTextKey: 'dnSitesHelpText',
    },
    {
      key: 'cns',
      label: 'CN SItes',
      validators: {
        min: {
          value: getMinCNSitesCount(),
          message: formatMessage(messages.minValueError, {
            value: getMinCNSitesCount(),
          }),
        },
      },
      helpTextKey: 'cnSitesHelpText',
    },
  ];

  let lineofSights: {
    key: lineofSitesFormRegisterTypes;
    label: string;
    validators: any;
    hidden: boolean;
    helpTextKey: string;
  }[] = [
    {
      key: 'popHeight',
      label: formatMessage(messages.popHeight),
      validators: {
        min: {
          value: 0,
          message: formatMessage(messages.minValueError, { value: 0 }),
        },
        max: {
          value: getMaxHeightValue(),
          message: formatMessage(messages.maxValueError, {
            value: getMaxHeightValue(),
          }),
        },
        validate: getNumberFieldValidateObject('popHeight'),
      },
      hidden: false,
      helpTextKey: 'popHeightHelpText',
    },
    {
      key: 'dnHeight',
      label: formatMessage(messages.dnHeight),
      validators: {
        min: {
          value: 0,
          message: formatMessage(messages.minValueError, { value: 0 }),
        },
        max: {
          value: getMaxHeightValue(),
          message: formatMessage(messages.maxValueError, {
            value: getMaxHeightValue(),
          }),
        },
        validate: getNumberFieldValidateObject('dnHeight'),
      },
      hidden: false,
      helpTextKey: 'dnHeightHelpText',
    },
    {
      key: 'cnHeight',
      label: formatMessage(messages.cnHeight),
      validators: {
        min: {
          value: 0,
          message: formatMessage(messages.minValueError, { value: 0 }),
        },
        max: {
          value: getMaxHeightValue(),
          message: formatMessage(messages.maxValueError, {
            value: getMaxHeightValue(),
          }),
        },
        validate: getNumberFieldValidateObject('cnHeight'),
      },
      hidden: false,
      helpTextKey: 'cnHeightHelpText',
    },
    {
      key: 'maxLosDistance',
      label: formatMessage(messages.maxLosDistance),
      validators: {
        min: {
          value: getMinValueForMaxLos(),
          message: formatMessage(messages.minValueError, {
            value: getMinValueForMaxLos(),
          }),
        },
        max: {
          value: getMaxValueForMaxLos(),
          message: formatMessage(messages.maxValueError, {
            value: getMaxValueForMaxLos(),
          }),
        },
      },
      hidden: false,
      helpTextKey: 'maxLosHelpText',
    },
    {
      key: 'minMcsOfMeshLink',
      label: formatMessage(messages.minMcsofMeshLink),
      validators: {},
      hidden: false,
      helpTextKey: 'minMcsMeshHelpText',
    },
    {
      key: 'minMcsOfAccessLink',
      label: formatMessage(messages.minMcsofAccessLink),
      validators: {},
      hidden: false,
      helpTextKey: 'minMcsAccessHelpText',
    },
  ];

  if (networkPlanData.networkPlan === 'Mesh') {
    sites = [
      {
        key: 'pops',
        label: 'POP Sites',
        validators: {
          min: {
            value: getMinPOPDNSitesCount(),
            message: formatMessage(messages.minValueError, {
              value: getMinPOPDNSitesCount(),
            }),
          },
        },
        helpTextKey: 'popSitesHelpText',
      },
      {
        key: 'dns',
        label: 'DN Sites',
        validators: {
          min: {
            value: getMinPOPDNSitesCount(),
            message: formatMessage(messages.minValueError, {
              value: getMinPOPDNSitesCount(),
            }),
          },
        },
        helpTextKey: 'dnSitesHelpText',
      },
    ];
    lineofSights.filter((los) => los.key === 'cnHeight')[0].hidden = true;
    lineofSights.filter((los) => los.key === 'minMcsOfAccessLink')[0].hidden =
      true;
  }

  const isValidFile = (fileName) => {
    const fileTypes = ['kml', 'kmz'];
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    return fileTypes.includes(ext);
  };
  const fileChange = (files) => {
    if (files && files[0] && isValidFile(files[0].name)) {
      setFileValues({ file: {}, uploaded: false, loading: true });
      setValidate(false);
      setLoading(true);
      uploadBoundaryFiles(files[0], projectId, networkSubPlan)
        .then((resp) => {
          setFileValues({
            file: boundaryFile.file,
            uploaded: resp.uploaded,
            loading: false,
            changed: true,
            warning: resp.warning,
          });
          if (resp && resp.has_lidar) {
            onFormValueChange({});
            setValidate(true);
            setLoading(false);
            setError('boundryPolygon', {});
            setUploadedFileName(resp.boundary_file);
            setSitesCountFromBackend(resp.site_counts || {});
            setFileValues({
              file: { name: resp.boundary_file },
              uploaded: true,
              loading: false,
              changed: true,
              warning: resp.warning,
            });
          } else {
            setError('boundryPolygon', {
              message:
                resp?.detail ||
                `Lidar data is currently unavailable for this region. 
                 Please email the boundary file to the LINKPlanner team 
                 (linkplanner@cambiumnetworks.com) for more support.`,
            });
            setValidate(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setFileValues({
            file: {},
            uploaded: false,
            loading: false,
            changed: false,
          });
          toast(<Message negative>{err && err.detail}</Message>, {
            autoClose: false,
          });
        });
    } else {
      //setError('boundryPolygon')
      //Display error for invalid files
    }
  };

  const getUnassignedNetworkSites = () => {
    return (
      networkSites?.features.length - (inputsFormData.pops + inputsFormData.dns)
    );
  };

  const accordions = [
    {
      key: 'GIS',
      titleProps: {
        content: `GIS`,
      },
      contentProps: {
        content: (
          <div>
            <Form onChange={onFormValueChange}>
              <Form.Field>
                <Popup
                  content={formatMessage(messages.boundaryPolygonHelpText)}
                  position="right center"
                  trigger={
                    <label style={{ display: 'inline-flex' }}>
                      {formatMessage(messages.boundaryPolygon)}{' '}
                    </label>
                  }
                />
                <Controller
                  name="boundryPolygon"
                  control={control}
                  rules={
                    boundaryFile.uploaded
                      ? {}
                      : { required: { value: true, message: 'Required' } }
                  }
                  render={({ field: { ref, ...rest } }) => (
                    <>
                      <Form.Input
                        {...rest}
                        id="boundryPolygon"
                        icon={boundaryFile.uploaded ? 'check' : 'file'}
                        iconPosition="left"
                        accept={'.kml, .kmz'}
                        type="file"
                        loading={boundaryFile.loading}
                        onChange={async (e, { name, value }) => {
                          const fnameArr = value.split('\\');
                          setUploadedFileName(fnameArr[fnameArr.length - 1]);
                          setValue(name, value);
                          fileChange(e.target.files);
                        }}
                        error={
                          errors.boundryPolygon && {
                            content: errors.boundryPolygon.message,
                          }
                        }
                      />
                      {boundaryFile.uploaded ? (
                        <div>Boundary extracted from: {uploadedFileName}</div>
                      ) : null}
                    </>
                  )}
                />
                <Message
                  warning
                  visible={Boolean(boundaryFile.warning)}
                  content={boundaryFile.warning}
                ></Message>
              </Form.Field>
              {/* <Form.Field>
                <Controller
                  name="loadDsm"
                  control={control}
                  rules={{}}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Form.Checkbox
                      {...rest}
                      checked={Boolean(inputsFormData.loadDsm)}
                      label={loadDsmLabel}
                      onChange={async (e, { name, checked }) => {
                        setValue('loadDsm', checked ? 1 : 0);
                        onFormValueChange(e);
                      }}
                    />
                  )}
                />
              </Form.Field> */}
            </Form>
          </div>
        ),
      },
    },
    {
      key: 'Sites',
      titleProps: {
        content: `Sites`,
      },
      contentProps: {
        content: (
          <Form onChange={onFormValueChange} className="sites">
            <Grid columns={'equal'}>
              <Grid.Column>
                {sites.map((site) => {
                  return (
                    <Grid
                      verticalAlign="middle"
                      key={site.key}
                      columns={'equal'}
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <Popup
                            content={formatMessage(messages[site.helpTextKey])}
                            position="right center"
                            trigger={
                              <label htmlFor={site.key}>{site.label}</label>
                            }
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <Form.Field className="readonly">
                            <Controller
                              name={site.key}
                              control={control}
                              rules={site.validators}
                              render={({ field: { ref, ...rest } }) => (
                                <Form.Input
                                  {...rest}
                                  type="text"
                                  readOnly={true}
                                  transparent={true}
                                  name={site.key}
                                  error={
                                    errors[site.key] && {
                                      content: errors
                                        ? [site.key] && errors[site.key].message
                                        : '',
                                    }
                                  }
                                />
                              )}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  );
                })}
                <Message
                  warning
                  visible={getUnassignedNetworkSites() >= 1}
                  content={formatMessage(messages.unassigned_network_sites, {
                    sitesCount: getUnassignedNetworkSites(),
                    s: getUnassignedNetworkSites() > 1 ? 's' : '',
                    orNotorIsnot:
                      getUnassignedNetworkSites() > 1 ? 'are not' : 'is not',
                    thisOrThese:
                      getUnassignedNetworkSites() > 1 ? 'these' : 'this',
                    theyOrIt:
                      getUnassignedNetworkSites() > 1 ? 'they are' : 'it is',
                  })}
                ></Message>
              </Grid.Column>
              {/* {networkPlanData.networkPlan === 'Distribution' ?
                                <Grid.Column>
                                    <Grid verticalAlign='middle' columns={'equal'}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Field>
                                                    <Controller
                                                        name='cnsCanbeUsedAsDns'
                                                        control={control}
                                                        rules={{}}
                                                        render={({ field: { ref, ...rest } }) => <Form.Checkbox
                                                            {...rest}
                                                            label={formatMessage(messages.cnsCanBeUsedAsDns)}
                                                            checked={Boolean(inputsFormData.cnsCanbeUsedAsDns)}
                                                            onChange={async (e, { name, checked }) => {
                                                                setValue('cnsCanbeUsedAsDns', checked ? 1 : 0);
                                                                onFormValueChange(e)
                                                            }}
                                                        />
                                                        }
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                : ""} */}
            </Grid>
          </Form>
        ),
      },
    },
    {
      key: 'Line of sight',
      titleProps: {
        content: `Line of sight`,
      },
      contentProps: {
        content: (
          <Form onChange={onFormValueChange}>
            <Grid columns={'equal'}>
              <Grid.Column>
                {/* {networkPlanData.networkPlan === 'Distribution' ? */}
                <Grid verticalAlign="middle" columns={'equal'}>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <Controller
                          name={'setHeight'}
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <Popup
                              content={formatMessage(
                                messages.useSiteMaxHeightHelpText
                              )}
                              trigger={
                                <Form.Radio
                                  disabled={boundaryFile.loading}
                                  {...rest}
                                  name={'setHeight'}
                                  checked={
                                    inputsFormData.setHeight ===
                                    'useSiteMaxHeight'
                                  }
                                  label={formatMessage(
                                    messages.useSiteMaxHeight
                                  )}
                                  onChange={async (e, { name, checked }) => {
                                    setValue('setHeight', 'useSiteMaxHeight');
                                    onFormValueChange(e);
                                  }}
                                  error={
                                    errors.setHeight && {
                                      content: errors.setHeight.message,
                                    }
                                  }
                                />
                              }
                            ></Popup>
                          )}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid verticalAlign="middle" columns={'equal'}>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <Controller
                          name={'setHeight'}
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <Popup
                              content={formatMessage(
                                messages.useHeightAboveSurfaceHelpText
                              )}
                              trigger={
                                <Form.Radio
                                  {...rest}
                                  disabled={boundaryFile.loading}
                                  name={'setHeight'}
                                  checked={
                                    inputsFormData.setHeight ===
                                    'useHeightAboveSurface'
                                  }
                                  label={formatMessage(
                                    messages.useHeightAboveSurface
                                  )}
                                  onChange={async (e, { name, checked }) => {
                                    setValue(
                                      'setHeight',
                                      'useHeightAboveSurface'
                                    );
                                    onFormValueChange(e);
                                  }}
                                  error={
                                    errors.setHeight && {
                                      content: errors.setHeight.message,
                                    }
                                  }
                                />
                              }
                            ></Popup>
                          )}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {inputsFormData.setHeight === 'useHeightAboveSurface'
                  ? lineofSights.map((site, inx) => {
                      if (inx < 3) {
                        return (
                          !site.hidden && (
                            <Grid
                              verticalAlign="middle"
                              className={site.hidden ? 'no-display' : ''}
                              key={lineofSights[inx].key}
                              columns={'equal'}
                            >
                              <Grid.Row>
                                <Grid.Column>
                                  <Popup
                                    content={formatMessage(
                                      messages[lineofSights[inx].helpTextKey]
                                    )}
                                    position="right center"
                                    trigger={
                                      <label htmlFor={lineofSights[inx].key}>
                                        {lineofSights[inx].label}
                                      </label>
                                    }
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Form.Field>
                                    <Controller
                                      name={lineofSights[inx].key}
                                      control={control}
                                      rules={lineofSights[inx].validators}
                                      render={({ field }) => (
                                        <Form.Input
                                          readOnly={boundaryFile.loading}
                                          transparent={
                                            boundaryFile.loading ? true : false
                                          }
                                          {...field}
                                          min={
                                            lineofSights[inx].validators.min
                                              .value
                                          }
                                          max={
                                            lineofSights[inx].validators.max
                                              .value
                                          }
                                          type="number"
                                          step="any"
                                          name={site.key}
                                          error={getError(
                                            errors,
                                            lineofSights[inx].key
                                          )}
                                        />
                                      )}
                                    />
                                  </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                  <div className="ui label">
                                    {heightUnits === 'ft' ? 'feet' : 'meters'}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          )
                        );
                      } else {
                        return false;
                      }
                    })
                  : ''}
              </Grid.Column>
              <Grid.Column>
                <Grid
                  verticalAlign="middle"
                  key={lineofSights[3].key}
                  columns={'equal'}
                >
                  <Grid.Row>
                    <Grid.Column>
                      <Popup
                        content={formatMessage(
                          messages[lineofSights[3].helpTextKey]
                        )}
                        position="right center"
                        trigger={
                          <label htmlFor={lineofSights[3].key}>
                            {lineofSights[3].label}
                          </label>
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <Controller
                          name={lineofSights[3].key}
                          control={control}
                          rules={lineofSights[3].validators}
                          render={({ field }) => (
                            <Form.Input
                              {...field}
                              readOnly={boundaryFile.loading}
                              transparent={boundaryFile.loading ? true : false}
                              type="number"
                              name={lineofSights[3].key}
                              onChange={(e) => {
                                field.onChange(Number(e.target.value));
                                handleNumbers(e, false, 0, false);
                              }}
                              min={lineofSights[3].validators.min.value}
                              max={lineofSights[3].validators.max.value}
                              step="any"
                              onKeyPress={(e) => {
                                handleNumbers(e, false, 0, false);
                              }}
                              error={
                                errors[lineofSights[3].key] && {
                                  content: errors
                                    ? [lineofSights[3].key] &&
                                      errors[lineofSights[3].key]?.message
                                    : '',
                                }
                              }
                            />
                          )}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="ui label">
                        {heightUnits === 'ft' ? 'feet' : 'meters'}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid
                  verticalAlign="middle"
                  key={lineofSights[4].key}
                  columns={'equal'}
                >
                  <Grid.Row>
                    <Grid.Column>
                      <Popup
                        content={formatMessage(
                          messages[lineofSights[4].helpTextKey]
                        )}
                        position="right center"
                        trigger={
                          <label htmlFor={lineofSights[4].key}>
                            {lineofSights[4].label}
                          </label>
                        }
                      />
                    </Grid.Column>
                    <Form.Field>
                      <Controller
                        name={'minMcsOfMeshLink'}
                        control={control}
                        rules={lineofSights[4].validators}
                        render={({ field: { ref, ...rest } }) => (
                          <Form.Select
                            {...rest}
                            options={mcsTypes}
                            disabled={boundaryFile.loading}
                            onChange={async (e, { name, value }) => {
                              setValue(name, value);
                              onFormValueChange(e);
                            }}
                            error={
                              errors[lineofSights[4].key] && {
                                content: errors
                                  ? [lineofSights[4].key] &&
                                    errors[lineofSights[4].key].message
                                  : '',
                              }
                            }
                          />
                        )}
                      />
                    </Form.Field>
                  </Grid.Row>
                </Grid>
                {!lineofSights[5].hidden && (
                  <Grid
                    verticalAlign="middle"
                    className={lineofSights[5].hidden ? 'no-display' : ''}
                    columns={'equal'}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Popup
                          content={formatMessage(
                            messages[lineofSights[5].helpTextKey]
                          )}
                          position="right center"
                          trigger={
                            <label htmlFor={lineofSights[5].key}>
                              {lineofSights[5].label}
                            </label>
                          }
                        />
                      </Grid.Column>
                      <Form.Field>
                        <Controller
                          name={'minMcsOfAccessLink'}
                          control={control}
                          rules={lineofSights[5].validators}
                          render={({ field: { ref, ...rest } }) => (
                            <Form.Select
                              {...rest}
                              disabled={boundaryFile.loading}
                              options={mcsTypes}
                              onChange={async (e, { name, value }) => {
                                setValue(name, value);
                                onFormValueChange(e);
                              }}
                              error={
                                errors[lineofSights[5].key] && {
                                  content: errors
                                    ? [lineofSights[5].key] &&
                                      errors[lineofSights[5].key].message
                                    : '',
                                }
                              }
                            />
                          )}
                        />
                      </Form.Field>
                    </Grid.Row>
                  </Grid>
                )}
              </Grid.Column>
            </Grid>
          </Form>
        ),
      },
    },
  ];

  return (
    <>
      <Grid centered columns={2}>
        <Grid.Column>
          <CustomAccordion accordions={accordions}></CustomAccordion>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default injectIntl(InputsStep);
