import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Checkbox, Form, Header } from 'semantic-ui-react';
import { isContiguousSelection } from 'src/utils/useful_functions';

type Props = {
  label: string;
  itemsOrder: string[];
  itemsChecked: Record<string, boolean>;
} & WrappedComponentProps;

const selectedStyle = { background: '#2185D0', color: 'white' };
const unselectedStyle = { background: 'white', color: 'rgba(0, 0, 0, 0.87)' };

function performSwapFormik(order, setFieldValue, idx, direction) {
  let newOrder = order.slice();
  const tmp = newOrder[idx];
  newOrder[idx] = newOrder[idx + direction];
  newOrder[idx + direction] = tmp;
  setFieldValue('bom.order', newOrder);
}

function performSwapGeneric(order, setterFunc, idx, direction) {
  let newOrder = order.slice();
  const tmp = newOrder[idx];
  newOrder[idx] = newOrder[idx + direction];
  newOrder[idx + direction] = tmp;
  setterFunc(newOrder);
}

function partitionSelected(selected: Set<string>, order) {
  return [
    order.filter((x) => selected.has(x)),
    order.filter((x) => !selected.has(x)),
  ];
}

function onUp(selected: Set<any>, order, setFieldValue) {
  return (e) => {
    e.preventDefault();

    if (selected.size === 0) {
      return;
    }

    const idx = order.findIndex((item) => selected.has(item));
    if (selected.size === 1) {
      // when one item is selected, just swap with prior element in the list
      if (idx > 0) {
        performSwapFormik(order, setFieldValue, idx, -1);
      }
    } else {
      // when more than one is selected:
      // if idx is 0, group selection at start of list (sel[0] doesnt move)
      // else group selection and move them starting at idx-1
      let [sel, nonSel]: string[][] = partitionSelected(selected, order);
      if (idx === 0) {
        setFieldValue('bom.order', [...sel, ...nonSel]);
      } else {
        nonSel.splice(idx - 1, 0, ...sel);
        setFieldValue('bom.order', nonSel);
      }
    }
  };
}

function onDown(selected: Set<any>, order, setFieldValue) {
  return (e) => {
    e.preventDefault();

    if (selected.size === 0) {
      return;
    }

    const idx =
      order.length -
      order
        .slice()
        .reverse()
        .findIndex((item) => selected.has(item)) -
      1;

    if (selected.size === 1) {
      if (idx < order.length - 1) {
        performSwapFormik(order, setFieldValue, idx, 1);
      }
    } else {
      let [sel, nonSel]: string[][] = partitionSelected(selected, order);
      if (idx === order.length - 1) {
        setFieldValue('bom.order', [...nonSel, ...sel]);
      } else {
        const nextItem = order[idx + 1];
        const newIdx = nonSel.findIndex((x) => x === nextItem);
        nonSel.splice(newIdx + 1, 0, ...sel);
        setFieldValue('bom.order', nonSel);
      }
    }
  };
}

function OrderableCheckListControl(props: Props) {
  const { values, setFieldValue } = useFormikContext();
  const { order, checked } = (values as any).bom;
  const { label } = props;
  const [selected, setSelected] = useState(new Set());

  const onClick = (item) => (e) => {
    e.preventDefault();
    setSelected(
      new Set(
        order.filter((x) => {
          return (
            (selected.has(x) && x !== item) || (x === item && !selected.has(x))
          );
        })
      )
    );
  };

  const onCheck = (item) => (_, data) => {
    setFieldValue('bom.checked', { ...checked, [item]: data.checked });
  };

  const getStyle = (cond) => {
    if (cond) {
      return selectedStyle;
    } else {
      return unselectedStyle;
    }
  };

  const disableUp =
    selected.size === 0 ||
    (isContiguousSelection(Array.from(selected), order) &&
      selected.has(order[0]));
  const disableDown =
    selected.size === 0 ||
    (isContiguousSelection(Array.from(selected), order) &&
      selected.has(order[order.length - 1]));

  return (
    <div>
      <p>
        Check the items that you wish to use.
        <br />
        Use the arrows to sort the items in order of preference.
      </p>
      <Header as="h4">{label}</Header>

      {/* May be we can enable it later based on flag (eg: showSelectAll)
      <Button.Group>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelected(new Set(order));
          }}
        >
          Select All
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelected(new Set());
          }}
        >
          Clear Selection
        </Button>
      </Button.Group> */}
      <div style={{ display: 'flex' }}>
        <div
          style={{
            height: '200px',
            padding: '0.5rem',
            border: '1px #eaeaea solid',
            overflowY: 'auto',
          }}
          className="flex-1"
        >
          {order &&
            order.map((item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '99%',
                  marginBottom: '3px',
                }}
              >
                <Checkbox checked={checked[item]} onChange={onCheck(item)} />
                <Form.Button
                  onClick={onClick(item)}
                  className="flex-1"
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    marginLeft: '1rem',
                    ...getStyle(selected.has(item)),
                  }}
                >
                  {item}
                </Form.Button>
              </div>
            ))}
        </div>
        <Button.Group vertical>
          <Button
            icon="arrow up"
            onClick={onUp(selected, order, setFieldValue)}
            disabled={disableUp}
          />
          <Button
            icon="arrow down"
            onClick={onDown(selected, order, setFieldValue)}
            disabled={disableDown}
          />
        </Button.Group>
      </div>
    </div>
  );
}

export function onUpGeneric(setterFunc) {
  return (selected, order) => (e) => {
    e.preventDefault();

    if (selected.size === 0) {
      return;
    }

    const idx = order.findIndex((item) => selected.has(item));
    if (selected.size === 1) {
      // when one item is selected, just swap with prior element in the list
      if (idx > 0) {
        performSwapGeneric(order, setterFunc, idx, -1);
      }
    } else {
      // when more than one is selected:
      // if idx is 0, group selection at start of list (sel[0] doesnt move)
      // else group selection and move them starting at idx-1
      let [sel, nonSel]: string[][] = partitionSelected(selected, order);
      if (idx === 0) {
        setterFunc([...sel, ...nonSel]);
      } else {
        nonSel.splice(idx - 1, 0, ...sel);
        setterFunc(nonSel);
      }
    }
  };
}

export function onDownGeneric(setterFunc) {
  return (selected, order) => (e) => {
    e.preventDefault();

    if (selected.size === 0) {
      return;
    }

    const idx =
      order.length -
      order
        .slice()
        .reverse()
        .findIndex((item) => selected.has(item)) -
      1;

    if (selected.size === 1) {
      if (idx < order.length - 1) {
        performSwapGeneric(order, setterFunc, idx, 1);
      }
    } else {
      let [sel, nonSel]: string[][] = partitionSelected(selected, order);
      if (idx === order.length - 1) {
        setterFunc([...nonSel, ...sel]);
      } else {
        const nextItem = order[idx + 1];
        const newIdx = nonSel.findIndex((x) => x === nextItem);
        nonSel.splice(newIdx + 1, 0, ...sel);
        setterFunc(nonSel);
      }
    }
  };
}

type GenericProps = {
  label?: string;
  order: string[];
  displayStrings?: Record<string, string>;
  checked?: string[];
  disabled?: boolean;
  onCheck: (item: string) => (_: any, data: any) => void;
  onUp: (selected: Set<string>, order: string[]) => (e) => void;
  onDown: (selected: Set<string>, order: string[]) => (e) => void;
};

export function GenericOrderableChecklistControl(props: GenericProps) {
  const {
    label,
    order,
    displayStrings,
    checked,
    disabled = false,
    onCheck,
    onUp,
    onDown,
  } = props;
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const onClick = (item: string) => (e) => {
    e.preventDefault();
    setSelected(
      new Set(
        order.filter((x) => {
          return (
            (selected.has(x) && x !== item) || (x === item && !selected.has(x))
          );
        })
      )
    );
  };

  const getStyle = (cond: boolean) => {
    if (cond) {
      return selectedStyle;
    } else {
      return unselectedStyle;
    }
  };

  const disableUp =
    selected.size === 0 || disabled ||
    (isContiguousSelection(Array.from(selected), order) &&
      selected.has(order[0]));
  const disableDown =
    selected.size === 0 || disabled ||
    (isContiguousSelection(Array.from(selected), order) &&
      selected.has(order[order.length - 1]));

  return (
    <div>
      <p>
        Check the items that you wish to use.
        <br />
        Select any items, then use the arrows to sort the items in order of
        preference.
      </p>

      {label && <Header as="h4">{label}</Header>}

      <div style={{ display: 'flex' }}>
        <div
          className="flex-1"
          style={{
            maxHeight: '200px',
            padding: '0.5rem',
            border: '1px #eaeaea solid',
            overflowY: 'auto',
          }}
        >
          {order &&
            order.map((item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '99%',
                  marginBottom: '3px',
                }}
                key={item}
              >
                <Checkbox
                  onChange={onCheck(item)}
                  defaultChecked={checked?.includes(item)}
                  disabled={disabled}
                />
                <Button
                  onClick={onClick(item)}
                  className="flex-1"
                  disabled={disabled}
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    marginLeft: '1rem',
                    ...getStyle(selected.has(item)),
                  }}
                >
                  {displayStrings != null ? displayStrings[item] : item}
                </Button>
              </div>
            ))}
        </div>
        <Button.Group vertical>
          <Button
            icon="arrow up"
            onClick={onUp(selected, order)}
            disabled={disableUp}
          />
          <Button
            icon="arrow down"
            onClick={onDown(selected, order)}
            disabled={disableDown}
          />
        </Button.Group>
      </div>
    </div>
  );
}

export default injectIntl(OrderableCheckListControl);
