import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Dropdown, Label, Input } from 'semantic-ui-react';
import {
  getUnitsLabels,
  isValidNumber,
  preventNonNumericalInput,
} from 'src/utils/useful_functions';
import {
  validate,
  getValueWithPrecisionAsString,
} from '../../components/controls/NumberControlSemantic';
import { DEFAULT_SM_RANGE } from 'src/app.constants';

const RangeUnitsComp = () => {
  const precision = 3;
  const { prefs } = useSelector((state) => state.mainFrame);
  const [rangeValue, setRangeValue] = useState(
    localStorage.getItem('cn.lp.cap.sm_range_value') || DEFAULT_SM_RANGE
  );
  const [rangeUnits, setRangeUnits] = useState(
    localStorage.getItem('cn.lp.cap.sm_range_unit') || prefs.rangeUnits
  );
  const [error, setError] = useState(false);
  useEffect(() => {
    localStorage.setItem(
      'cn.lp.cap.sm_range_value',
      parseFloat(rangeValue).toFixed(precision)
    );
    localStorage.setItem('cn.lp.cap.sm_range_unit', rangeUnits);
    const isValid = validate(
      rangeValue,
      rangeUnitMinMaxValues[rangeUnits].min,
      rangeUnitMinMaxValues[rangeUnits].max,
      'decimal'
    );
    setError(!isValid);
  }, [rangeUnits, rangeValue]);

  const rangeUnitOptions = [
    {
      key: 'km',
      text: 'kilometers',
      value: 'km',
    },
    {
      key: 'mi',
      text: 'miles',
      value: 'mi',
    },
  ];
  const rangeUnitMinMaxValues = {
    km: {
      min: 0.015,
      max: 65,
    },
    mi: {
      min: 0.009,
      max: 40,
    },
  };
  return (
    <>
      <Form.Field>
        <label>
          <FormattedMessage
            id="accessPoints.rangeUnits"
            defaultMessage="rangeUnits"
          />
        </label>
        <Dropdown
          placeholder="Select Range Unit"
          fluid
          selection
          options={rangeUnitOptions}
          onChange={(e, data) => {
            setRangeUnits(data.value);
            if (rangeValue < rangeUnitMinMaxValues[data.value].min)
              setRangeValue(rangeUnitMinMaxValues[data.value].min);
            if (rangeValue > rangeUnitMinMaxValues[data.value].max)
              setRangeValue(rangeUnitMinMaxValues[data.value].max);
          }}
          defaultValue={rangeUnits}
        />
      </Form.Field>
      <Form.Field
        error={error}
        title={`Range ${rangeUnitMinMaxValues[rangeUnits].min}${getUnitsLabels(
          rangeUnits
        )} to ${parseFloat(rangeUnitMinMaxValues[rangeUnits].max).toFixed(
          precision
        )}${getUnitsLabels(rangeUnits)}`}
      >
        <label>
          <FormattedMessage
            id="accessPoints.SMRange"
            defaultMessage="SMRange"
          />
        </label>
        <div style={{ display: 'flex' }}>
          <Input
            type="number"
            style={{ width: '100%' }}
            value={rangeValue}
            onClick={(e, data) => {
              e.target.select();
            }}
            min={rangeUnitMinMaxValues[rangeUnits].min}
            step={0.001}
            onBlur={(e) => {
              if (error) {
                setRangeValue(DEFAULT_SM_RANGE.toFixed(precision));
              }
            }}
            onKeyPress={preventNonNumericalInput}
            max={rangeUnitMinMaxValues[rangeUnits].max}
            onChange={(event, data) => {
              const { value } = data;
              const newValue = parseFloat(value);
              const isValid = validate(
                value,
                rangeUnitMinMaxValues[rangeUnits].min,
                rangeUnitMinMaxValues[rangeUnits].max,
                'decimal'
              );
              if (value === '') {
                setRangeValue(value);
              } else if (isValid) {
                const parsedValue = getValueWithPrecisionAsString(
                  newValue,
                  'decimal',
                  3,
                  value
                );
                setRangeValue(parsedValue);
              } else {
                setRangeValue(value);
              }
              setError(!isValid);
            }}
          />
          <Label>{getUnitsLabels(rangeUnits)}</Label>
        </div>
      </Form.Field>
    </>
  );
};

export default injectIntl(RangeUnitsComp);
