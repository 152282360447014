/*
 * Convert a snake-case string to camel-case.
 *
 * @param {string} snakeStr - The string to convert
 * @return {string} The camel-case version of the input string.
 * @example
 * toCamel("test_attribute_name")
 * //=>
 * "testAttributeName"
 */
export const toCamel = (snakeStr: string): string => {
  if (!snakeStr.includes('_')) {
    return snakeStr;
  }
  return snakeStr
    .toLowerCase()
    .split('_')
    .filter(Boolean)
    .map((str, idx) => {
      if (idx === 0) {
        return str;
      } else {
        return `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
      }
    })
    .join('');
};

/*
 * Convert a camel-case string to snake-case.
 *
 * @param {string} camelStr - The string to convert
 * @param {Regex} [regex=/[A-Z][a-z0-9_]/g] - Matching regex for the replace string
 * @return {string} The snake-case version of the input string.
 * @example
 * toSnake("testAttributeName")
 * //=>
 * "test_attribute_name"
 */
export const toSnake = (
  camelStr: string,
  regex = /[A-Z][a-z0-9_]/g
): string => {
  return camelStr.replace(regex, (c) => `_${c}`).toLowerCase();
};

/*
 * Convert the object key name between snake/camel case using the converter functions.
 *
 * @param {Object} obj - Object to convert
 * @param {Function} converter - String function that will be used to convert the key names
 */
export const convertObjectKeys = (obj: Object, converter: Function): Object => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [converter(k), v])
  );
};

/*
 * Converts the given string to title case
 *
 * @param {str} obj - string to convert
 * eg: linkplanner to Linkplanner
 */
export function capitalizeWord(str): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/*
 * Converts the given sentence to title case
 *
 * @param {str} obj - string to convert
 * eg: hello linkplanner to Hello Linkplanner
 */
export function capitalizeSentence(str): string {
  return str.split(' ').map(capitalizeWord).join(' ');
}
