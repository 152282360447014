import React from 'react';
import { get } from 'lodash';
import { Segment, Tab } from 'semantic-ui-react';
import { parsePerformanceData } from './AccessPointPerformance';
import AccessPointPerformanceSector from './AccessPointPerformanceSector';
import { useFormContext } from 'react-hook-form';

function TabbedAPPerformanceSummary({ data, disabled }) {
  const { warning, summary } = data;
  const { getValues } = useFormContext();
  const product = getValues('radios.0.equipment.product');
  const units = product === 'V5000' ? 'Gbps' : 'Mbps';
  const radioQty = getValues('radio_qty');
  const componentCarrierPerformance = radioQty == 2 && product !== 'V5000';
  const { radio_1, radio_2, aggregate } = summary;

  const aggregateDefault = {
    upLink: { percent: 0, quantity: 0, throughput: 0 },
    downLink: { percent: 0, quantity: 0, throughput: 0 },
    mean: { throughput: 0 },
  };
  let sector1Data: any = {
    aggregates: aggregateDefault,
    data: [],
  };
  let sector2Data: any = {
    aggregates: aggregateDefault,
    data: [],
  };
  let aggregateData: any = {
    aggregates: aggregateDefault,
    data: [],
  };
  if (get(radio_1, 'sms_per_ul_modulation', []).length)
    sector1Data = parsePerformanceData(radio_1);
  if (get(radio_2, 'sms_per_ul_modulation', []).length)
    sector2Data = parsePerformanceData(radio_2);
  if (get(aggregate, 'sms_per_ul_modulation', []).length) {
    aggregateData = parsePerformanceData(aggregate);
  }

  const panes = [
    {
      menuItem: aggregate.label,
      render: () => (
        <Tab.Pane>
          <AccessPointPerformanceSector
            aggregates={aggregateData['aggregates']}
            data={aggregateData['data']}
            warning={warning.aggregate}
            throughputUnits={units}
            componentCarrierPerformance={componentCarrierPerformance}
            aggregateConnectedSMQty={summary.connected_sm_qty}
          ></AccessPointPerformanceSector>
        </Tab.Pane>
      ),
    },
    {
      menuItem: radio_1.label,
      render: () => (
        <Tab.Pane>
          <AccessPointPerformanceSector
            aggregates={sector1Data['aggregates']}
            data={sector1Data['data']}
            warning={warning.radio_1}
            throughputUnits={units}
            componentCarrierPerformance={componentCarrierPerformance}
          ></AccessPointPerformanceSector>
        </Tab.Pane>
      ),
    },
    {
      menuItem: radio_2.label,
      render: () => (
        <Tab.Pane>
          <AccessPointPerformanceSector
            aggregates={sector2Data['aggregates']}
            data={sector2Data['data']}
            warning={warning.radio_2}
            throughputUnits={units}
            componentCarrierPerformance={componentCarrierPerformance}
          ></AccessPointPerformanceSector>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Segment basic disabled={disabled}>
      <Tab panes={panes}></Tab>
    </Segment>
  );
}

export default TabbedAPPerformanceSummary;
