class Transceiver {
  public transceiverType: string;
  public qty1Gbps: number;
  public qty10Gbps: number;
  constructor(transceiverType: string, qty1Gbps: number, qty10Gbps: number) {
    this.transceiverType = transceiverType;
    this.qty1Gbps = qty1Gbps;
    this.qty10Gbps = qty10Gbps;
  }
  public getTransceiverName(
    transceiverType: string,
    appendStr: string
  ): string {
    return transceiverNameMapping[transceiverType] + appendStr;
  }
}

const transceiverNameMapping = {
  Copper: 'copper',
  'Fiber - Long': 'fiber_long',
  'Fiber - Short': 'fiber_short',
};

class CreateTransceivers {
  public list: Transceiver[];
  public ttlCount: number = 0;
  public oneGbpsCount: number = 0;
  public tenGbpsCount: number = 0;
  public warn: string = '';
  public info: string = 'Configure the transceiver quantities as required.';
  constructor(transceiverTypes: string[], transceivers) {
    this.list = transceiverTypes.map(
      (transceiver) =>
        new Transceiver(
          transceiver,
          transceivers[`${transceiverNameMapping[transceiver]}_1gbps`],
          transceivers[`${transceiverNameMapping[transceiver]}_10gbps`]
        )
    );
  }

  public getClassName(qty1Gbps: number, qty10Gbps: number) {
    return this.warn && (qty1Gbps || qty10Gbps) ? 'transWarn' : '';
  }

  public setTotalCount() {
    this.ttlCount = this.list.reduce(
      (total, transceiver) =>
        total + transceiver.qty1Gbps + transceiver.qty10Gbps,
      0
    );
  }
  public setTotal1GbpsCount() {
    this.oneGbpsCount = this.list.reduce(
      (total, transceiver) => total + transceiver.qty1Gbps,
      0
    );
  }
  public setTotal10GbpsCount() {
    this.tenGbpsCount = this.list.reduce(
      (total, transceiver) => total + transceiver.qty10Gbps,
      0
    );
  }
  public warning(fiber_ports: string) {
    let limit = 4;
    if (fiber_ports === '4 SFP+' && this.ttlCount > limit)
      this.warn = `The switch cannot support more than ${limit} transceivers`;
    else if (fiber_ports === '4 SFP+ plus 8 SFP') {
      limit = 12;
      if (this.tenGbpsCount > 4 || this.ttlCount > limit)
        this.warn = `The switch cannot support more than ${limit} transceivers 
                of which 10Gbps transceivers cannot exceed 4`;
      else this.warn = '';
    } else this.warn = '';
  }
}

export default CreateTransceivers;
