import { get } from 'lodash';
import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import messages from 'src/messages';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import LimitControl from '../ptp/LimitControl';
import { PMPPanelProps } from './nd/utils';
import { useSelector } from 'react-redux';

function SubscriberComponentCarrier(props: PMPPanelProps) {
  const { getValues } = useFormContext();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );
  const { ap, setModified, choices, refreshChoices, sm } = props;
  const { formatMessage } = useIntl();
  const radioQty = getValues('radio_qty');

  let componentCarriersArr = Array.from(
    { length: radioQty },
    (val, idx) => idx + 1
  );

  if (radioQty === 1) {
    // if radioqty is 1 user can select the component carrier from dropdown
    const apRadio = getValues('radios.0.antennas.0.sector');
    componentCarriersArr = [apRadio];
  }

  return (
    <Table>
      <Table.Header style={{ fontSize: '0.818em' }}>
        <Table.Row>
          <Table.HeaderCell>
            Component <br></br> Carrier
          </Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(messages.carrier)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(messages.band)}</Table.HeaderCell>
          <Table.HeaderCell>
            {formatMessage(messages.bandwidth)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatMessage(messages.frequency)}
            <br></br>(MHz)
          </Table.HeaderCell>
          <Table.HeaderCell>
            Interference <br></br>(dBm in b/w)
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {componentCarriersArr.map((cc, idx) => {
          // cc refers to carrier number
          // for bandwidth, band, frequency we refer ap, so we need apIndex
          const apRadio = cc - 1;
          const band = get(ap, `radios.${apRadio}.equipment.band`);
          const bandwidth = get(ap, `radios.${apRadio}.equipment.bandwidth`);
          const frequency = get(ap, `radios.${apRadio}.frequency.tx_frequency`);

          const apSMInterferenceEnabled = get(
            ap,
            `radios.${apRadio}.power.use_noise_sm`
          );
          const carrierQty = get(ap, 'radios.0.equipment.carrier_qty');
          const interferenceCBgetter = apSMInterferenceEnabled
            ? `radios.${idx}.power.use_noise_sm`
            : `radios.${idx}.power.use_noise`;
          const interferenceCBProps = {
            disabled: apSMInterferenceEnabled,
            className: 'sm-interference',
          };

          return (
            <Table.Row key={`SM-CC-r${idx}`}>
              <Table.Cell width={2} key={`SM-CC-r${idx}-c1`}>
                {cc}
              </Table.Cell>
              {/**
               * As of now we are supporting only two carriers
               * below check should work, when we are going to
               * support more than 2 we may need to tweak it
               */}
              <Table.Cell width={2} key={`SM-CC-r${idx}-c2`}>
                {`Carrier ${carrierQty > 1 ? cc : 1}`}
              </Table.Cell>
              <Table.Cell width={2} key={`SM-CC-r${idx}-c3`}>
                {band}
              </Table.Cell>
              <Table.Cell width={3} key={`SM-CC-r${idx}-c4`}>
                {bandwidth}
              </Table.Cell>
              <Table.Cell width={2} key={`SM-CC-r${idx}-c5`}>
                {frequency?.toFixed(3) || '---'}
              </Table.Cell>
              <Table.Cell width={3} key={`SM-CC-r${idx}-c6`}>
                <Form.Field className="interference-controls">
                  <LimitControl
                    setModified={setModified}
                    choices={choices}
                    path={sm}
                    attrName="interference"
                    checkboxGetter={interferenceCBgetter}
                    checkboxCompProps={interferenceCBProps}
                    valueGetter={`radios.${idx}.power.noise`}
                    min={-144}
                    defaultValue={({ formGetter }) => {
                      return formGetter(`radios.${idx}.power.noise`);
                    }}
                    precision={1}
                    max={-40}
                    refreshChoices={refreshChoices}
                    componentProps={{
                      watch: getValues([
                        `radios.${idx}.antennas[0].sector`,
                        `radio_qty`,
                      ]),
                    }}
                    disabled={!permissionWrite}
                  ></LimitControl>
                </Form.Field>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default SubscriberComponentCarrier;
