import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'semantic-ui-react';
import FrequencyModal from './FrequencyModal';
import { useSelector } from 'react-redux';

function TxFrequencyField(props) {
  const { index } = props;
  const mounted = useRef(false);
  const { setValue } = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  const [radioQty, band, band1, regulation, bandwidth, freq] = useWatch({
    name: [
      'radio_qty',
      'radios.0.equipment.band',
      'radios.0.equipment.band_carrier_2',
      'radios.0.equipment.regulation',
      'radios.0.equipment.bandwidth',
      `radios.${index}.frequency.tx_frequency`,
    ],
  });

  useEffect(() => {
    if (mounted.current) {
      setValue(`radios.${index}.frequency.tx_frequency`, null, {
        shouldDirty: true,
      });
    }
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [radioQty, band, band1, regulation, bandwidth, index]);

  return (
    <>
      <p>{freq != null ? freq.toFixed(3) : '---'}</p>
      <Button
        color="blue"
        className="ml-1"
        type="button"
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!permissionWrite}
      >
        Select...
      </Button>
      {showModal && (
        <FrequencyModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          {...props}
        ></FrequencyModal>
      )}
    </>
  );
}

export default TxFrequencyField;
