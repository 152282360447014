import React from 'react';
import produce from 'immer';
import { sortBy, remove } from 'lodash';
import { injectIntl } from 'react-intl';
import { Header } from 'semantic-ui-react';
import {
  GenericOrderableChecklistControl,
  onDownGeneric,
  onUpGeneric,
} from '../../components/controls/OrderableCheckListControl';

function sortChoices(order) {
  return (o) => order.indexOf(o.value);
}

function sortSelected(order) {
  return (o) => order.indexOf(o);
}

function displayText(value, text) {
  // add "retired" label on to product display text when necessary
  if (value.includes('retired')) {
    return `${text} (retired)`;
  }
  return text;
}

function ProductChoicesPanel(props) {
  const { setState, state, family, permissionWrite } = props;
  const { choices } = state.smProductPrefs;

  const order = choices[family].map(({ value }) => value);
  const displayStrings = Object.fromEntries(
    choices[family].map(({ text, value }) => [value, displayText(value, text)])
  );

  const onMove = (_order) => {
    setState(
      produce(state, (draft) => {
        // update the choices order
        draft.smProductPrefs.choices[family] = sortBy(
          draft.smProductPrefs.choices[family],
          sortChoices(_order)
        );
        // update the existing selection order based on the new choices order
        draft.smProductPrefs.selected[family] = sortBy(
          draft.smProductPrefs.selected[family],
          sortSelected(
            draft.smProductPrefs.choices[family].map(({ value }) => value)
          )
        );
      })
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      <GenericOrderableChecklistControl
        order={order}
        displayStrings={displayStrings}
        checked={state.smProductPrefs.selected[family]}
        disabled={!permissionWrite}
        onUp={onUpGeneric(onMove)}
        onDown={onDownGeneric(onMove)}
        onCheck={(item) =>
          (_, { checked }) => {
            setState(
              produce(state, (draft) => {
                if (checked) {
                  if (!(family in draft.smProductPrefs.selected)) {
                    draft.smProductPrefs.selected[family] = [];
                  }
                  draft.smProductPrefs.selected[family].push(item);
                  draft.smProductPrefs.selected[family] = sortBy(
                    draft.smProductPrefs.selected[family],
                    sortSelected(order)
                  );
                } else {
                  remove(
                    draft.smProductPrefs.selected[family],
                    (o) => o === item
                  );

                  if (draft.smProductPrefs.selected[family].length === 0) {
                    delete draft.smProductPrefs.selected[family];
                  }
                }
              })
            );
          }}
      />
    </div>
  );
}

function SMProductPreferencesPanel(props) {
  const { state, setState, family, permissionWrite } = props;

  return (
    <>
      <Header as="h4">Subscriber Product Preferences</Header>
      <ProductChoicesPanel
        state={state}
        setState={setState}
        family={family}
        permissionWrite={permissionWrite}
      />
    </>
  );
}

export default injectIntl(SMProductPreferencesPanel);
