import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../store';
import { postWithAuth } from '../../../api';
import {
  Button,
  Form,
  Grid,
  Message,
  Icon,
  Popup,
  Header,
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { Formik, Form as FormFormik, Field } from 'formik';
import { createNumberMask } from 'text-mask-addons';
import additionalMessages from '../../../messages';
import SemanticField from '../../../components/controls/SemanticField';
import { LENGTH_OPTIONS } from '../../../app.constants';
import { trimObject } from '../../../utils/useful_functions';
import SiteSelection from '../../../components/SiteSelection';
import { fetchEquipmentConfigs } from 'src/pages/equipment/equipment.reducer';

const makeLabel = (label, units) => {
  return `${label} (${units})`;
};

// function ConfigControl(props) {
//   const { configs } = props;
//   const { formatMessage } = props.intl;
//   const configOptions = configs.map((config) => {
//     return {
//       key: config.id,
//       text: getName(config),
//       value: config.id,
//     };
//   });

//   return (
//     <>
//       {configs.length > 0 ? (
//         <SemanticField
//           label={formatMessage(additionalMessages.pmpEquipment)}
//           component={Form.Select}
//           options={configOptions}
//           name="configId"
//           // TODO onChange
//         />
//       ) : (
//         <Link
//           to="/pmp_equipment"
//           title={formatMessage(additionalMessages.createTemplate)}
//         >
//           {formatMessage(additionalMessages.createTemplate)}
//         </Link>
//       )}
//     </>
//   );
// }

function getRangeUnitsLabel(formatMessage, unit) {
  return makeLabel(
    formatMessage(additionalMessages.SMRange),
    formatMessage(
      unit === 'km' ? additionalMessages.kilometers : additionalMessages.miles
    )
  );
}

function getRangeHelpText(formatMessage, unit) {
  return formatMessage(
    unit === 'km'
      ? additionalMessages.smRangeInfoForKm
      : additionalMessages.smRangeInfoForMi
  );
}

function CreateAccessPoint(props) {
  const {
    handlePrevStep,
    configTypeLabel,
    configTypeId,
    configName,
    closeFunc,
  } = props;
  const { formatMessage } = props.intl;
  const [createError, setCreateError] = useState(null);

  const { projectId, prefs, userLimits, accessPointsCount } = useSelector(
    (state) => state.mainFrame
  );

  let { networkSites } = useSelector((state) => state.mainFrame);
  networkSites = networkSites.features.map((feature) => feature.properties);

  //const { equipmentConfigs } = useSelector((state) => state.equipment);
  //const configs = equipmentConfigs.slice().sort(nameSort);
  //const selectedConfigId = configs.length ? configs[0].id : null;

  const initialValues = {
    siteIds: [],
    configId: configTypeId,
    apsPerHub: 4,
    azimuth: 0,
    separationAngle: 90,
    rangeUnits: prefs.rangeUnits,
    smRange: 10,
  };

  const textMaskConfig = {
    prefix: '',
    suffix: '',
    integerLimit: 3,
  };

  const ref = useRef(null);

  const [smRangeLabel, setSmRangeLabel] = useState(
    getRangeUnitsLabel(formatMessage, initialValues.rangeUnits)
  );
  const [smRangeHelpText, setSmRangeHelpText] = useState(
    getRangeHelpText(formatMessage, initialValues.rangeUnits)
  );

  const getLimit = () => {
    const pmpNetworkDevicesLimit = userLimits?.pmp_network_devices;
    let values = ref?.current?.values;
    if (parseInt(values?.apsPerHub)) {
      let val =
        (pmpNetworkDevicesLimit - accessPointsCount) /
        parseInt(values.apsPerHub);
      return val < 1 ? 0 : val;
    } else {
      return pmpNetworkDevicesLimit - accessPointsCount;
    }
  };

  const validateSiteIds = (value) => {
    if (!value || !value.length) {
      return formatMessage(additionalMessages.requiredSelectError, {
        fieldName: 'network sites',
      });
    }
    if (getLimit() < value?.length) {
      return formatMessage(additionalMessages.limitExceededError, {
        entityName: 'PMP Network Device',
        limit: userLimits?.pmp_network_devices,
      });
    }
  };

  const validationSchema = (formatMessage) => {
    return Yup.object({
      apsPerHub: Yup.number().required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'APs per hub',
        })
      ),

      rangeUnits: Yup.string().required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'Range units',
        })
      ),

      azimuth: Yup.number().required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'Azimuth',
        })
      ),

      separationAngle: Yup.number().required(
        formatMessage(additionalMessages.requiredFieldError, {
          fieldName: 'Separation angle',
        })
      ),

      smRange: Yup.number(
        formatMessage(additionalMessages.validFieldError, {
          fieldName: 'SM Range',
        })
      )
        .when(['rangeUnits'], (rangeUnits, schema) => {
          return rangeUnits === 'km' ? schema.min(0.015) : schema.min(0.009);
        })
        .required(
          formatMessage(additionalMessages.requiredFieldError, {
            fieldName: 'SM Range',
          })
        ),
    });
  };

  const onCloseHandler = () => {
    closeFunc();
  };

  const onSubmit = (values, { setSubmitting }) => {
    trimObject(values);
    const config = {
      site_ids: values.siteIds,
      template_id: configTypeId,
      aps_per_hub: parseInt(values.apsPerHub),
      azimuth: parseInt(values.azimuth),
      separation_angle: parseInt(values.separationAngle),
      range_units: values.rangeUnits === 'km' ? 'kilometers' : 'miles',
      sm_range: parseFloat(values.smRange),
    };

    postWithAuth(`project/${projectId}/access_points`, config)
      .then(() => {
        setSubmitting(false);
        onCloseHandler();
      })
      .catch((err) => {
        setSubmitting(false);
        setCreateError(err.message ?? err.detail);
      })
      .finally(() => {
        store.dispatch(fetchEquipmentConfigs(projectId));
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(formatMessage)}
        validateOnMount
        onSubmit={onSubmit}
        innerRef={ref}
      >
        {({
          errors,
          touched,
          isValid,
          dirty,
          isSubmitting,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => {
          return (
            <FormFormik className="ui form">
              <Grid stretched>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <SiteSelection
                      sites={networkSites}
                      networkOnly={true}
                      flex={true}
                      validateSiteIds={validateSiteIds}
                    />
                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Header as="h3">PMP Network Device Configuration</Header>
                    <Form.Input
                      label={configTypeLabel}
                      value={configName}
                      className="no-border"
                      disabled={true}
                      id="configId"
                      name="configId"
                    ></Form.Input>

                    <div className="field">
                      <label>Devices Per Site</label>
                      <Field name="apsPerHub">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={createNumberMask({
                              prefix: '',
                              integerLimit: 2,
                            })}
                            type="text"
                            onBlur={(e) => {
                              setFieldValue(
                                'separationAngle',
                                360 / parseInt(e.target.value)
                              );
                              validateSiteIds();
                              handleBlur(e);
                            }}
                          />
                        )}
                      </Field>
                      {errors.apsPerHub ? (
                        <Message className="flex-grow-0" negative>
                          <p>{errors.apsPerHub}</p>
                        </Message>
                      ) : null}
                    </div>

                    <div className="field">
                      <label>
                        {makeLabel(
                          formatMessage(additionalMessages.startingAzimuth),
                          'degrees'
                        )}
                      </label>
                      <Field name="azimuth">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={createNumberMask(textMaskConfig)}
                            type="text"
                          />
                        )}
                      </Field>
                      {errors.azimuth ? (
                        <Message className="flex-grow-0" negative>
                          <p>{errors.azimuth}</p>
                        </Message>
                      ) : null}
                    </div>

                    <div className="field">
                      <label>
                        {makeLabel(
                          formatMessage(additionalMessages.separationAngle),
                          'degrees'
                        )}
                      </label>
                      <Field name="separationAngle">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={createNumberMask(textMaskConfig)}
                            type="text"
                          />
                        )}
                      </Field>
                      {errors.separationAngle ? (
                        <Message className="flex-grow-0" negative>
                          <p>{errors.separationAngle}</p>
                        </Message>
                      ) : null}
                    </div>

                    <SemanticField
                      fluid
                      component={Form.Select}
                      label={formatMessage(additionalMessages.rangeUnits)}
                      options={LENGTH_OPTIONS}
                      name="rangeUnits"
                      placeholder={formatMessage(additionalMessages.length)}
                      onChange={(e, obj) => {
                        setSmRangeLabel(
                          getRangeUnitsLabel(formatMessage, obj.value)
                        );
                        setSmRangeHelpText(
                          getRangeHelpText(formatMessage, obj.value)
                        );
                        handleChange(e, obj);
                      }}
                    />

                    <div className="field">
                      <label>
                        {smRangeLabel}
                        <Popup
                          content={smRangeHelpText}
                          trigger={<Icon name="info" />}
                        />
                      </label>
                      <Field name="smRange">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={createNumberMask({
                              prefix: '',
                              suffix: '',
                              integerLimit: 3,
                              allowDecimal: true,
                              decimalSymbol: '.',
                              decimalLimit: 3,
                            })}
                            type="text"
                          />
                        )}
                      </Field>
                      {errors.smRange && touched.smRange ? (
                        <Message className="flex-grow-0" negative>
                          <p>{errors.smRange}</p>
                        </Message>
                      ) : null}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="modal-actions">
                <Button
                  type="submit"
                  floated="right"
                  disabled={!(isValid && dirty)}
                  loading={isSubmitting}
                  color="blue"
                  style={{ marginBottom: '15px' }}
                >
                  {formatMessage(additionalMessages.ok)}
                </Button>
                <Button
                  floated="right"
                  style={{ marginBottom: '15px' }}
                  onClick={handlePrevStep}
                >
                  Previous
                </Button>
                <Button
                  floated="right"
                  style={{ marginBottom: '15px' }}
                  onClick={onCloseHandler}
                >
                  {formatMessage(additionalMessages.cancel)}
                </Button>
              </div>
            </FormFormik>
          );
        }}
      </Formik>

      {createError && (
        <Message negative attached>
          <Message.Header>
            {formatMessage(additionalMessages.createAPError)}
          </Message.Header>
          <p>{createError}</p>
        </Message>
      )}
    </>
  );
}

export default injectIntl(CreateAccessPoint);
