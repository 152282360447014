import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { get, chunk, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Button, Form, Grid, Message, Modal } from 'semantic-ui-react';
import messages from '../../messages';
import { Formik, Form as FormFormik } from 'formik';
import SemanticField from '../../components/controls/SemanticField';
import { uiSet } from '../mainframe/mainframe.reducer';
import { store } from '../../store';
import { postWithAuth } from '../../api';
import { ClutterDetailsType } from '../../utils/types/commonTypes';
import {
  CLUTTER_LABELS,
  DEFAULT_CLUTTER_DETAILS,
  CLUTTER_PROPS_COL_PER_ROW,
  METER_TO_FEET,
} from '../../app.constants';
import NumberControl from '../../components/controls/NumberControl';

function EditClutterProperties(props) {
  const { onCloseHandler, intl } = props;
  const { prefs, clutterDetails, projectId } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const [open, setOpen] = useState(true);
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [modifiedClutter, setModidfiedClutter] = useState({});
  const { formatMessage } = intl;
  const chunkedValues = chunk(clutterDetails, CLUTTER_PROPS_COL_PER_ROW);
  const initialValues = {};

  clutterDetails.forEach((obj: any) => {
    const { key, details } = obj;
    const [color, height] = details;
    initialValues[`${key}_0`] = color;
    initialValues[`${key}_1`] = height;
  });

  const onSubmit = (values) => {
    const updatedData: ClutterDetailsType | {} = {};
    Object.keys(values).forEach((key) => {
      let value: string = values[key];
      const [type, index] = key.split('_');
      if (!updatedData[type]) {
        updatedData[type] = [];
      }
      if (parseInt(index) === 1) {
        if (modifiedClutter[type] !== undefined)
          updatedData[type][index] = modifiedClutter[type];
        else {
          updatedData[type][index] = initialValues[`${type}_1`];
        }
      } else {
        updatedData[type][index] = value;
      }
    });
    setLoader(true);
    postWithAuth(`project/${projectId}`, { clutter: updatedData }, 'PATCH')
      .then((res) => {
        setLoader(false);
        setOpen(false);
        onCloseHandler();
        store.dispatch(
          uiSet({ clutterDetails: mergeClutterDetails(updatedData) })
        );
      })
      .catch((err) => {
        setLoader(false);
        setError(get(err, 'details.0.msg', 'Unable to update'));
      });
  };

  const handleDismiss = () => {
    setError('');
  };

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      onClose={() => {
        onCloseHandler();
        setOpen(false);
      }}
    >
      <Modal.Header>
        {formatMessage(messages.editClutterData)}
          <Button
            circular
            icon="close"
            title={formatMessage(messages.close)}
            floated="right"
            onClick={() => {
              onCloseHandler();
              setOpen(false);
            }}
          />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ errors }) => {
            return (
              <FormFormik className="ui form">
                <Grid>
                  {chunkedValues.map((row, index) => (
                    <Grid.Row
                      columns={CLUTTER_PROPS_COL_PER_ROW}
                      width="equal"
                      className="custom-row"
                      key={index}
                    >
                      {row.map((column: any) => {
                        const { key } = column;
                        const label = CLUTTER_LABELS[key];
                        return (
                          label !== 'Unclassified' && (
                            <Grid.Column key={key}>
                              <Form.Group>
                                <SemanticField
                                  component={Form.Input}
                                  className="clutter-color"
                                  width={2}
                                  name={`${key}_0`}
                                  type="color"
                                />
                                <NumberControl
                                  width={4}
                                  min={0}
                                  scaleFactor={
                                    prefs.heightUnits === 'ft'
                                      ? METER_TO_FEET
                                      : 1
                                  }
                                  controlValue={initialValues[`${key}_1`]}
                                  onChange={(e, { unScaledVal }) => {
                                    if (
                                      parseFloat(unScaledVal) !==
                                      initialValues[`${key}_1`]
                                    ) {
                                      setModidfiedClutter({
                                        ...modifiedClutter,
                                        [key]: unScaledVal,
                                      });
                                    } else if (modifiedClutter[key] !== null) {
                                      const latestValue = {
                                        ...modifiedClutter,
                                      };
                                      delete latestValue[key];
                                      setModidfiedClutter(latestValue);
                                    }
                                  }}
                                  name={`${key}_1`}
                                />
                                <span style={{ lineHeight: 2.3 }}>
                                  {prefs.heightUnits}&nbsp; {label}
                                </span>
                              </Form.Group>
                            </Grid.Column>
                          )
                        );
                      })}
                    </Grid.Row>
                  ))}
                </Grid>
                <div className="modal-actions">
                  <Button
                    onClick={() => {
                      onCloseHandler();
                      setOpen(false);
                    }}
                  >
                    {formatMessage(messages.cancel)}
                  </Button>
                  <Button
                    type="submit"
                    disabled={error !== '' || !isEmpty(errors)}
                    loading={loader}
                    color="blue"
                  >
                    {formatMessage(messages.ok)}
                  </Button>
                </div>
              </FormFormik>
            );
          }}
        </Formik>
      </Modal.Content>
      {error !== '' && (
        <Message negative onDismiss={handleDismiss} attached>
          <p>{error}</p>
        </Message>
      )}
    </Modal>
  );
}

export default injectIntl(EditClutterProperties);

export function mergeClutterDetails(clutter): ClutterDetailsType {
  if (clutter) {
    const data = JSON.parse(JSON.stringify(DEFAULT_CLUTTER_DETAILS));
    data.forEach((obj) => {
      if (clutter[obj.key]) {
        obj.details[0] = clutter[obj.key][0];
        obj.details[1] = parseFloat(clutter[obj.key][1]);
      }
    });
    return data;
  } else {
    return DEFAULT_CLUTTER_DETAILS;
  }
}
