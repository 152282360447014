import L from 'leaflet';
import eskape from 'eskape';
import { editObject, deleteObject, attachSm } from '../../utils/mapUtils';
import { store } from '../../store';
import { showPopupMenu } from './contextMenu';
import { onmouseover, onmouseout, clickHandler } from './featureEvents';

// The SVG colours are restricted to named colours only.
// #f4a460 does work as the fill in some browsers, but
// it may be safer to use the colours from the SVG spec.
const apMarkerSVG = `
<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'>
<path d='M 15 30, 1 10 C 1 0, 29 0, 29 10 z' fill='#f4a460' stroke='black' stroke-linecap="round" stroke-width="1" />
</svg>`;
const apClusterIcon = encodeURI('data:image/svg+xml,' + apMarkerSVG).replace(
  '#',
  '%23'
);

/**
 * Return the formatting style for the access point cluster markers.
 */
export const apClusterMarker = (feature, latlng) => {
  if (feature.properties.count) {
    const count = feature.properties.count;
    const xscale = 30 + 3 * Math.log(count);
    const yscale = 30 + 3 * Math.log(count);
    const icon = new L.icon({
      iconUrl: apClusterIcon,
      iconSize: [xscale, yscale],
      iconAnchor: [xscale / 2, yscale / 2],
    });
    return L.marker(latlng, { icon: icon });
  }
};

/**
 * Bind events to the access point layer and add the label
 *
 * @param {Object} feature   - GeoJSON feature instance
 * @param {Object} layer     - Leaflet GeoJSON layer instance
 */
export const accessPointEvents = function (feature, layer) {
  let events = {};
  if (!feature.properties.count) {
    events = {
      mouseout: onmouseout,
      mouseover: onmouseover,
      click: clickHandler,
    };
    if (store.getState().mainFrame.permissionWrite) {
      events = {
        ...events,
        contextmenu: onRightClickAP,
      };
    }
  }
  layer.on(events);

  // Add the marker labels to the layer
  let permanent = false;
  let direction = 'top';
  let label = eskape`${feature.properties.name}`;
  let className = 'siteLabel';
  if (feature.properties.count) {
    // clustered site marker
    permanent = true;
    direction = 'center';
    label = feature.properties.count;
    className = 'clusterLabel';
  }

  layer.bindTooltip(`${label}`, {
    interactive: false,
    opacity: 0.9,
    permanent,
    direction,
    className,
  });
};

const onRightClickAP = (event) => {
  // Call the standard click handler to set the current selection
  clickHandler(event);

  /** Callback function when an item is selected in the menu
   *
   * @callback onClickCallback
   */
  const onclick = (key) => {
    const { kind, id, name } = event.target.feature.properties;
    console.log(key, name);
    if (key === 'edit') {
      editObject(kind, id);
    } else if (key === 'delete') {
      deleteObject(kind, id);
    } else if (key === 'attachSm') {
      attachSm(id);
    }
  };
  // Display the menu
  showPopupMenu(
    event,
    [
      { key: 'edit', content: 'Edit' },
      { key: 'delete', content: 'Delete' },
      { key: 'attachSm', content: 'Attach Subscribers' },
      //   { key: 'delete', content: 'Delete' },
      //   { key: 'attach_sm', content: 'Attach SMs' },
    ],
    onclick
  );
};

/**
 * Return the formatting style for the access points.
 */
export const styleAccessPoint = function (feature, isSelected = false) {
  const strokeWeight = isSelected ? 3 : 1;
  const fillOpacity = isSelected ? 0.8 : 0.2;
  if (!feature.properties.count) {
    return {
      color: feature.properties.strokeColor || '#f4a460',
      weight: strokeWeight,
      fillOpacity: fillOpacity,
      fillColor: feature.properties.strokeColor || '#f4a460',
    };
  }
};
