import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Message, FormGroup, Grid } from 'semantic-ui-react';
import { parseCoords } from '../SiteModePanel';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { isEmpty } from 'lodash';
import messages from 'src/messages';
import { useSelector } from 'react-redux';

const AFCModePanel = (props) => {
  const { formatMessage } = useIntl();
  const prefs = useSelector((state) => state.mainFrame.prefs);

  const afcProductConfigs = window?.AFC_PRODUCT_CONFIGS ?? {};

  const productOptions = Object.keys(afcProductConfigs).map((product) => ({
    key: product,
    value: product,
    text: product,
  }));

  const {
    control,
    trigger,
    formState: { isDirty, errors },
  } = useFormContext();

  const product = useWatch({ name: 'product', control });

  return (
    <Grid columns="equal" padded>
      <Grid.Row style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <Grid.Column>
          <FormGroup
            style={{
              marginBottom: 0,
              alignItems: 'center',
              padding: 0,
            }}
          >
            <Controller
              control={control}
              name="coordinates"
              rules={{
                required: true,
                validate: {
                  coordinates: (value) => {
                    const { valid } = parseCoords(value);
                    return valid;
                  },
                },
              }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.Input
                  width={3}
                  error={errors['coordinates'] != null}
                  {...rest}
                  label={'Coordinates'}
                  onChange={(e, { value }) => {
                    onChange(value);
                    // Force validation
                    trigger();
                  }}
                ></Form.Input>
              )}
            />

            <Form.Field width={2} key="antennaHeight">
              <GenericScaledField
                key="antennaHeight"
                defaultValue={0}
                label="Antenna Height"
                min={0}
                max={3000}
                width={10}
                units={prefs['heightUnits']}
                getter="antennaHeight"
                precision={1}
                disabled={false}
                alwaysEnabled={true}
              />
            </Form.Field>
            <Form.Field width={3} key="product">
              <Controller
                control={control}
                name="product"
                render={({ field: { ref, onChange, ...rest } }) => (
                  <>
                    <Form.Select
                      label="Product"
                      {...rest}
                      options={productOptions}
                      onChange={(e, { value }) => {
                        onChange(value);
                      }}
                      placeholder="Product"
                    />
                  </>
                )}
              ></Controller>
            </Form.Field>

            {afcProductConfigs[product].externalAntenna && (
              <>
                <Form.Field width={2}>
                  <GenericScaledField
                    key="horizontalDistance"
                    defaultValue={0}
                    label="Horizontal Distance"
                    min={0}
                    max={afcProductConfigs[product].maxHorizontalDistance}
                    width={10}
                    units={prefs['heightUnits']}
                    getter="horizontalDistance"
                    precision={1}
                    disabled={false}
                    alwaysEnabled={true}
                  ></GenericScaledField>
                </Form.Field>
                <Form.Field width={2}>
                  <GenericScaledField
                    key="verticalDistance"
                    defaultValue={0}
                    label="Vertical Distance"
                    min={0}
                    max={afcProductConfigs[product].maxVerticalDistance}
                    width={10}
                    units={prefs['heightUnits']}
                    getter="verticalDistance"
                    precision={1}
                    disabled={false}
                    alwaysEnabled={true}
                  ></GenericScaledField>
                </Form.Field>
              </>
            )}
            <Form.Button
              color="blue"
              type="submit"
              style={{ marginTop: '2.1em' }}
              width={1}
              disabled={!isEmpty(errors) || !isDirty}
            >
              <FormattedMessage
                id="mapToolbar.submit"
                defaultMessage="Submit"
              />
            </Form.Button>
            <Form.Field width={5} style={{ marginLeft: 'auto' }}>
              <Message info style={{ marginTop: 0 }}>
                {formatMessage(messages.afcModeHelpMessage)}
              </Message>
            </Form.Field>
          </FormGroup>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AFCModePanel;
