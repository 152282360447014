import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Form, Grid, Icon, Message } from 'semantic-ui-react';
import {
  PTP_ANTENNA,
  SM_ANTENNA,
  defaultValues,
  getAntennaFormBands,
} from './antenna.service';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import messages from 'src/messages';
import { GainLimitModal } from './GainLimitModal';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { POLARIZATION_OPTIONS, SINGLE, DUAL } from 'src/app.constants';

interface Props {
  intl: IntlShape;
  kind: 'ptp' | 'sm';
  setFormValid: any;
  formData: any;
  isEdit: boolean;
  mandatoryBand?: string;
}

interface CompRef {
  getValues: () => void;
}



function NumberField({ name, mandatoryBand, control, getValues, setValue, errors }) {
  const _name = name.replace('.', '_');

  let req = {};
  if (name === mandatoryBand) {
    req = {
      required: { value: true, message: 'Required' },
      validate: (v) => {
        return v == '' ? 'Required' : true;
      },
    };
  }

  return (
    <Form.Field>
      <Controller
        name={_name}
        control={control}
        rules={{
          max: { value: 999, message: 'Value can not be greater than 999' },
          min: { value: 0, message: 'Value can not be less than 0' },
          ...req,
        }}
        render={({ field: { ref, value, onChange, ...rest } }) => (
          <Form.Input
            {...rest}
            type="number"
            onChange={(e) => {
              let allGains = getValues('gains');
              const num = Number(e.target.value);
              if (num) {
                allGains[name] = num;
              } else {
                delete allGains[name];
              }
              setValue('gains', allGains);
              onChange(num);
            }}
            min={0}
            max={999}
            step={0.01}
            value={getValues('gains')[name]}
            id={_name}
            title="Range 0 to 999"
            onKeyPress={(e) => {
              var charCode = e.which ? e.which : e.keyCode;
              if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
              ) {
                e.preventDefault();
                return false;
              }
            }}
            error={Boolean(
              errors[_name] && {
                content: errors[_name].message,
              }
            )}
          />
        )}
      />
    </Form.Field>
  );
}

function BandGrid(props) {
  const {
    errors,
    gainsValid,
    antennaFormBands,
    mandatoryBand,
    control,
    getValues,
    setValue,
  } = props;

  return (
    <Grid
      columns={2}
      className="bandsTable"
      style={{
        maxHeight:
          errors['manufacturer'] || errors['description'] || !gainsValid
            ? 180
            : 280,
      }}
    >
      {antennaFormBands.map((el, i) => (
        <Grid.Column key={el}>
          <table>
            <tbody>
              <tr>
                <td>{el}</td>
                <td>
                  <NumberField
                    name={el}
                    mandatoryBand={mandatoryBand}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                  /> dBi
                </td>
              </tr>
            </tbody>
          </table>
        </Grid.Column>
      ))}
    </Grid>
  );
}

const AntennaForm = forwardRef<CompRef, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    getValues,
  }));

  const { polarities } = useSelector((state: any) => state.antenna);
  const [openGainLimitModal, setOpenGainLimitModal] = useState(false);

  const { kind, setFormValid, formData, isEdit, mandatoryBand } = props;
  const { formatMessage } = props.intl;

  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
    trigger,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const allGains = Object.values(watch('gains'));
  const gainsValid = allGains.length && allGains.filter((el) => el !== 0).length;

  const antennaFormBands = getAntennaFormBands(kind);

  function toggleLimitModal() {
    setOpenGainLimitModal(!openGainLimitModal);
  }

  useEffect(() => {
    // this effect sets the initial form data
    if (!isEmpty(formData)) {
      if (isEdit) {
        const formValues = {};
        Object.keys(getValues()).forEach((key: any) => {
          const getKey = key === 'part_number' ? 'display_partno' : key;
          formValues[getKey] = formData[getKey];
          if (getKey === 'beamwidth') {
            formValues[getKey] = Number(formData[getKey]).toFixed(1);
          }
          setValue(key, formValues[getKey]);
        });
      }
    }

    if (!isEdit) {
      if (polarities.includes(DUAL)) {
        setValue('polarization', DUAL);
      } else {
        setValue('polarization', SINGLE);
      }
    }

    // manually trigger the first validation
    const mandatoryBandName = mandatoryBand?.replace('.', '_');
    trigger(['manufacturer', 'description', mandatoryBandName]);
  }, [formData]);

  useEffect(() => {
    // used to enable/disable the OK button of the modal
    setFormValid(gainsValid && isValid);
  }, [gainsValid, isValid]);

  return (
    <div>
      <Form className="antennaForm">
        <div className="antennaInputs">
          <Grid columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Manufacturer</label>
                <Controller
                  name="manufacturer"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Required' },
                    maxLength: {
                      value: 60,
                      message:
                        'Manufacturer length can not exceed 60 characters',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <Form.Input
                      {...rest}
                      type="text"
                      id={'manufacturer'}
                      error={
                        errors.manufacturer && {
                          content: errors.manufacturer.message,
                        }
                      }
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Grid columns={2} style={{ marginTop: 0 }}>
            <Grid.Column className="d-flex">
              <Form.Field width={8}>
                <label>Diameter</label>
                <Controller
                  name="diameter"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Form.Input {...rest} type="number" step="0.01" min={0} />
                  )}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Controller
                  name="diameter_units"
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Form.Select
                      {...rest}
                      style={{
                        marginTop: 19,
                        padding: 5,
                        marginLeft: 5,
                        minWidth: 60,
                      }}
                      options={[
                        { text: 'Inches', value: 'inches' },
                        { text: 'Meters', value: 'meters' },
                        { text: 'Feet', value: 'feet' },
                      ]}
                      onChange={(_, { value }) => onChange(value)}
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
            {kind === SM_ANTENNA ? (
              <Grid.Column>
                <Form.Field>
                  <label>Beamwidth (deg)</label>
                  <Controller
                    name="beamwidth"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Form.Input {...rest} type="number" step="0.1" />
                    )}
                  />
                </Form.Field>
              </Grid.Column>
            ) : (
              null
            )}
          </Grid>
          <Grid columns={1} style={{ marginTop: 0 }}>
            <Grid.Column>
              <Form.Field>
                <label>Description</label>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Required' },
                    maxLength: {
                      value: 60,
                      message:
                        'Description length can not exceed 60 characters',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <Form.Input
                      {...rest}
                      type="text"
                      id='description'
                      error={
                        errors.description && {
                          content: errors.description.message,
                        }
                      }
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Grid columns={2} style={{ marginTop: 0 }}>
            <Grid.Column>
              <Form.Field>
                <label>Antenna Type</label>
                <Controller
                  name="antenna_type"
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Form.Select
                      {...rest}
                      options={[
                        { text: 'Other', value: 'Other' },
                        { text: 'Flat Plate', value: 'Flat Plate' },
                        { text: 'Horn', value: 'Horn' },
                        { text: 'Omni', value: 'Omni' },
                        { text: 'Parabolic', value: 'Parabolic' },
                        { text: 'Sector', value: 'Sector' },
                        { text: 'Whip', value: 'Whip' },
                        { text: 'Yagi', value: 'Yagi' },
                      ]}
                      onChange={(_, { value }) => onChange(value)}
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Part Number</label>
                <Controller
                  name="part_number"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 24,
                      message:
                        'Part Number length can not exceed 24 characters',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <Form.Input
                      {...rest}
                      type="text"
                      id='part_number'
                      error={
                        errors.part_number && {
                          content: errors.part_number.message,
                        }
                      }
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
        </div>
        <Grid columns={1} style={{ marginTop: 0 }}>
          <Grid.Column>
            <fieldset>
              <legend>Polarization</legend>
              <Form.Field>
                <Grid columns='equal'>
                  {POLARIZATION_OPTIONS.map((pol) => (
                    <Grid.Column className="p-0" key={pol}>
                      <Form.Field style={{ textAlign: 'center' }}>
                        <Controller
                          name="polarization"
                          control={control}
                          disabled={polarities.indexOf(pol) < 0}
                          render={({ field: { ref, value, onChange, ...rest } }) => {
                            return (
                              <Form.Radio
                                {...rest}
                                name={'polarization'}
                                label={pol}
                                value={value}
                                onChange={() => onChange(pol)}
                                checked={value === pol}
                              />
                            );
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  ))}
                </Grid>
              </Form.Field>
            </fieldset>
          </Grid.Column>
        </Grid>
        <div style={{ margin: '5px 0' }}>
          <p style={{ marginBottom: '5px' }}>
            {kind === PTP_ANTENNA
              ? formatMessage(messages.ptpAntennaNoteMsg)
              : formatMessage(messages.smAntennaNoteMsg)}
          </p>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <p style={{ margin: 0 }}>
              {formatMessage(messages.antennaFormCommonMsg)}
            </p>
            <Button
              size="small"
              className="ml-2"
              onClick={toggleLimitModal}
              circular
              icon
              color="blue"
            >
              <Icon name="info"></Icon>
            </Button>
          </div>
        </div>

        <Form.Field>
          <label style={{ display: 'inline-flex', marginBottom: 5 }}>
            Gain in each band
          </label>
          <BandGrid
            errors={errors}
            gainsValid={gainsValid}
            antennaFormBands={antennaFormBands}
            mandatoryBand={mandatoryBand}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        </Form.Field>

        {!gainsValid ? (
          <Message
            negative
            content={formatMessage(messages.minimumGainsMsg)}
          ></Message>
        ) : (
          null
        )}
      </Form>

      <GainLimitModal
        open={openGainLimitModal}
        kind={kind}
        togglePopup={toggleLimitModal}
      />
    </div>
  );
});

export default injectIntl(AntennaForm, { forwardRef: true });
