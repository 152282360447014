import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import additionalMessages from '../../messages';
import { readString } from 'react-papaparse';
import { Button, Form, Message } from 'semantic-ui-react';
import { get } from 'lodash';
import JSZip from 'jszip';
import { store } from 'src/store';
import messages from '../../messages';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
const jschardet = require('jschardet');

export type CSVFileSelectionPropsType = {
  handleNextStep: Function;
  customClassName: string;
  groupKind: string;
} & WrappedComponentProps;

type SiteObj = { name: string; lat: string; lng: string; description: string };
const extractSiteObjs = (kmlData): Array<SiteObj> => {
  const parser = new DOMParser();

  const xmlDoc = parser.parseFromString(kmlData.toString(), 'text/xml');
  const schema: any = xmlDoc.getElementsByTagName('Schema');
  let parentElement = 'Placemark';
  if (schema[0]) {
    const parent = schema[0].getAttribute('parent');
    if (parent == 'Placemark') {
      parentElement = schema[0].getAttribute('name');
    }
  }
  const siteXMLObjs = xmlDoc.getElementsByTagName(parentElement);
  const siteObjs: SiteObj[] = [];
  for (let i = 0; i < siteXMLObjs.length; i++) {
    const nameObj = siteXMLObjs[i].getElementsByTagName('name');
    const descriptionObj = siteXMLObjs[i].getElementsByTagName('description');
    let siteName: string = null;
    if (get(nameObj, '0.childNodes.0')) {
      siteName = nameObj[0].childNodes[0].nodeValue;
    }
    let description: string = null;
    if (get(descriptionObj, '0.childNodes.0')) {
      description = descriptionObj[0].childNodes[0].nodeValue;
    }
    const pointObj = siteXMLObjs[i].getElementsByTagName('Point');
    if (pointObj.length) {
      const coordsObj = pointObj[0].getElementsByTagName('coordinates')[0];
      const coordsArr = coordsObj.childNodes[0].nodeValue.split(',');
      const [lng, lat] = [coordsArr[0], coordsArr[1]];
      if (siteName != null && lng != null && lat != null) {
        siteObjs.push({ name: siteName, lat, lng, description });
      }
    }
  }
  return siteObjs;
};
function CSVFileSelection(props: CSVFileSelectionPropsType) {
  const { handleNextStep, customClassName, intl, groupKind } = props;
  const { formatMessage } = intl;
  const [fileData, setFileData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [fileLoading, setFileLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userLimits, networkSiteCount, subscriberSiteCount } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const nextStepHandler = () => {
    handleNextStep(fileData, fileName);
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const validateFileSize = (filesLength) => {
    switch (groupKind) {
      case 'network_sites': {
        if (networkSiteCount + (filesLength - 1) > userLimits.network_sites) {
          setError(
            formatMessage(messages.limitExceededError, {
              entityName: 'Network Site',
              limit: userLimits.network_sites,
            })
          );
        } else {
          setError(null);
        }
        break;
      }
      case 'subscriber_sites': {
        if (
          subscriberSiteCount + (filesLength - 1) >
          userLimits.subscriber_sites
        ) {
          setError(
            formatMessage(messages.limitExceededError, {
              entityName: 'Subscriber Site',
              limit: userLimits.subscriber_sites,
            })
          );
        } else {
          setError(null);
        }
        break;
      }
      default: {
        setError(null);
      }
    }
  };

  const fileChange = (files: FileList) => {
    const reader = new FileReader();
    let reRead = false;
    setFileLoading(true);
    reader.onload = function (e) {
      const fileName: string = files[0].name;
      const fileContent = e.target.result;
      setFileName(fileName);
      if (fileName.toLowerCase().includes('kmz')) {
        const jsZip = new JSZip();
        let foundKML = false;
        jsZip
          .loadAsync(files[0])
          .then((kmzFile) => {
            kmzFile.forEach((path, fileObj) => {
              const { name } = fileObj;
              //If there are multiple kml files only the first one is considered
              if (name.toLowerCase().includes('kml') && !foundKML) {
                foundKML = true;
                jsZip
                  .file(name)
                  .async('text')
                  .then((kmlFile) => {
                    const siteObjs = extractSiteObjs(kmlFile);
                    setFileData(siteObjs);
                    setFileLoading(false);
                  })
                  .catch((err) => {
                    console.error(err);
                    setError(err);
                  });
              }
              console.info('name', name, 'path', path);
            });
          })
          .catch((err) => {
            setFileLoading(false);
            setError('The data file provided is either empty or corrupt.');
          });
      } else if (fileName.toLowerCase().includes('kml')) {
        const siteObjs = extractSiteObjs(fileContent);
        setFileData(siteObjs);
        setFileLoading(false);
      } else if (fileName.toLowerCase().includes('csv')) {
        const encoding = jschardet.detect(fileContent).encoding;
        const data: any = readString(fileContent.toString(), {
          encoding: encoding,
          skipEmptyLines: 'greedy',
        } as any);
        setFileData(data.data);
        validateFileSize(data.data.length);
        if (!reRead) {
          reader.readAsText(files[0], encoding);
          reRead = true;
        }
        setFileLoading(false);
      }
    };
    reader.readAsBinaryString(files[0]);
  };

  return (
    <div className={customClassName}>
      <Form>
        <Form.Input
          type="file"
          icon="file"
          iconPosition="left"
          loading={fileLoading}
          accept=".csv, .kml, .kmz"
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
            onInputClick(e)
          }
          onChange={(e) => {
            fileChange(e.target.files);
          }}
        ></Form.Input>
        {error ? (
          <Message error className="create-ptp-warning">
            <p>{error}</p>
          </Message>
        ) : (
          ''
        )}
        <Button
          disabled={fileName === '' || fileLoading || error}
          loading={fileLoading}
          style={{ float: 'right' }}
          onClick={() => nextStepHandler()}
        >
          {formatMessage(additionalMessages.next)}
        </Button>
      </Form>
    </div>
  );
}

export default injectIntl(CSVFileSelection);
