import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import SMPerformanceSummaryEnds from './SMPerformanceSummaryEnds';
import SMPerformanceSummaryLink from './SMPerformanceSummaryLink';

function BaseSMPerformanceSummary({
  setModified,
  sm,
  local,
  remote,
  summary,
  warnings,
  choices,
  pathIndex,
}) {
  const { local: summaryL, remote: summaryR, link } = summary;
  return (
    <Segment style={{ container: 'performance-summary / inline-size' }} basic disabled={sm.dirty} loading={sm.dirty}>
      <div className="performance-summary">
        <div>
          <SMPerformanceSummaryEnds
            local={summaryL}
            localSiteName={local.site_name}
            remoteSiteName={remote.site_name}
            remote={summaryR}
            warnings={warnings}
            choices={choices}
            setModified={setModified}
            pathIndex={pathIndex}
          ></SMPerformanceSummaryEnds>
        </div>
        <SMPerformanceSummaryLink link={link} />
      </div>
    </Segment>
  );
}

export default BaseSMPerformanceSummary;
