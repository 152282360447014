import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { Button, Form, Message } from 'semantic-ui-react';
import { postWithAuth } from '../../../api';
import LatLngControl from '../../../components/controls/LatLngControl';
import SemanticField from '../../../components/controls/SemanticField';
import {
  anyToMeter,
  decodeLatLong,
  desktopDistance,
  encodeLatLong,
  meterToAny,
} from '../../../utils/useful_functions';
import messages from '../../../messages';
import { Coordinates } from '../../../utils/types/commonTypes';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from 'src/app.constants';

type AccessPointPropertiesType = {
  onChange: Function;
  apCoords: Coordinates;
  latitude: number;
  longitude: number;
  modified: boolean;
  accessPoint: any;
} & WrappedComponentProps;

function AccessPointProperties(props: AccessPointPropertiesType) {
  const {
    intl,
    onChange,
    accessPoint,
    apCoords,
    modified,
    latitude,
    longitude,
  } = props;
  const { values, setFieldValue } = useFormikContext();
  const { formatMessage } = intl;
  const [resetLoading, setResetLoading] = useState(false);
  const [resetErr, setResetErr] = useState(null);
  const { projectId, prefs } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const updateOffset = (longitude, latitude) => {
    const [decodeLat, validLat] = decodeLatLong(latitude);
    const [decodeLng, validLng] = decodeLatLong(longitude, false);
    if (validLat && validLng) {
      const distanceInMeter = distanceBetweenTwoPoints(
        { longitude: decodeLng, latitude: decodeLat },
        { latitude: apCoords.latitude, longitude: apCoords.longitude }
      );
      setTimeout(
        () =>
          setFieldValue(
            'offset',
            meterToAny(distanceInMeter, prefs.heightUnits)
          ),
        0
      );
    }
  };

  return (
    <>
      <SemanticField
        component={Form.Input}
        name="name"
        required
        label={formatMessage(messages.name)}
        placeholder={formatMessage(messages.name)}
        onChange={(event) => onChange(event)}
        maxLength={NAME_MAX_LENGTH}
      />
      <Form.Group>
        <LatLngControl
          width="6"
          label={formatMessage(messages.latitude)}
          name="latitude"
          controlValue={latitude}
          toFormat={prefs.latLngFormat}
          required
          onChange={(e, { value }) => {
            onChange(e);
            updateOffset(values['longitude'], value);
          }}
        />
        <LatLngControl
          width="6"
          isLat={false}
          label={formatMessage(messages.longitude)}
          name="longitude"
          controlValue={longitude}
          toFormat={prefs.latLngFormat}
          required
          onChange={(e, { value }) => {
            onChange(e);
            updateOffset(value, values['latitude']);
          }}
        />
        <div className="four wide field">
          <label>{formatMessage(messages.offset)}</label>
          <div
            title={
              anyToMeter(values['offset'], prefs.heightUnits) > 100
                ? formatMessage(messages.apPanelOffsetError)
                : formatMessage(messages.offset)
            }
            style={{ fontWeight: 'normal' }}
            className={`ui label basic no-border tiny ${
              anyToMeter(values['offset'], prefs.heightUnits) > 100 ? 'red' : ''
            }`}
          >
            <Field as="label" name="offset">
              {values['offset']}
            </Field>{' '}
            {prefs.heightUnits}
          </div>
        </div>
      </Form.Group>
      <Form.Group style={{ float: 'right' }}>
        <Form.Field
          control={Button}
          loading={resetLoading}
          type="button"
          title="Resets to site coordinates"
          disabled={!(!modified && values['offset'] > 0)}
          onClick={() => {
            setResetLoading(true);
            postWithAuth(
              `project/${projectId}/antenna/${get(
                accessPoint,
                'radios[0].antennas[0].id'
              )}/resetcoordinates`,
              null,
              'PUT'
            )
              .then((res) => {
                setResetLoading(false);
                setFieldValue('offset', 0);
                setFieldValue(
                  'latitude',
                  encodeLatLong(apCoords.latitude, prefs.latLngFormat)
                );
                setFieldValue(
                  'longitude',
                  encodeLatLong(apCoords.longitude, prefs.latLngFormat, false)
                );
              })
              .catch((err) => {
                setResetErr(err.detail);
                setResetLoading(false);
              });
          }}
          content={formatMessage(messages.reset)}
        ></Form.Field>
        {resetErr && (
          <Message negative>
            <p>{resetErr}</p>
          </Message>
        )}
      </Form.Group>
      <SemanticField
        component={Form.TextArea}
        name="description"
        label={formatMessage(messages.description)}
        placeholder={formatMessage(messages.description)}
        onChange={(event) => onChange(event)}
        maxLength={DESCRIPTION_MAX_LENGTH}
      />
    </>
  );
}

export default injectIntl(AccessPointProperties);

export const distanceBetweenTwoPoints = (
  from: Coordinates,
  to: Coordinates
): number => {
  let distanceInMeter = 0;
  try {
    distanceInMeter = desktopDistance(
      from.latitude,
      from.longitude,
      to.latitude,
      to.longitude
    );
  } catch (e) {
    console.error('Exception in distanceBetweenTwoPoints', e);
  }
  return distanceInMeter;
};
