import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Label } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { ReflectionLine } from 'src/model/Reflection';
import { HeightASL, Multiplier, getCalcSpacing } from './ReflectionPanel';
import { RootStateOrAny } from 'src/store';

type licensedReflectionPanelProps = {
  path: any;
  reflectionLine: ReflectionLine;
  setModified: Function;
};

const LicensedReflectionPanel = ({
  path,
  reflectionLine,
  setModified,
}: licensedReflectionPanelProps) => {
  const { prefs } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { watch } = useFormContext();
  const { heightUnits } = prefs;
  const units = heightUnits === 'ft' ? 'feet' : 'meters';
  const localSiteName = path.local.site.name;
  const remoteSiteName = path.remote.site.name;
  let { optimumDiversityLocal, optimumDiversityRemote } = reflectionLine;
  const currentMultiplier = watch('reflection.multiplier');
  if (optimumDiversityLocal) {
    optimumDiversityLocal = getCalcSpacing(
      optimumDiversityLocal,
      currentMultiplier,
      heightUnits
    );
  }
  if (optimumDiversityRemote) {
    optimumDiversityRemote = getCalcSpacing(
      optimumDiversityRemote,
      currentMultiplier,
      heightUnits
    );
  }

  return (
    <Form.Group className="reflectionPanel">
      <HeightASL path={path} setModified={setModified} />

      <Multiplier path={path} setModified={setModified} />
      <Form.Field className="bold">
        <label> {`Required Spacing at ${localSiteName}:`}</label>
        <Form.Group>
          <Form.Input
            value={optimumDiversityLocal}
            transparent={true}
            readOnly={true}
          />
          <Label>{units}</Label>
        </Form.Group>
      </Form.Field>

      <Form.Field className="bold">
        <label> {`Required Spacing at ${remoteSiteName}:`}</label>
        <Form.Group>
          <Form.Input
            value={optimumDiversityRemote}
            transparent={true}
            readOnly={true}
          />
          <Label>{units}</Label>
        </Form.Group>
      </Form.Field>
    </Form.Group>
  );
};

export default LicensedReflectionPanel;
