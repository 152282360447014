import React from 'react';
import { Form, Input, Select } from 'semantic-ui-react';
import { Level, EquipmentSelection } from 'src/model/EquipmentSelection';

type EquipmentParams = {
  fieldModified: {
    name: string;
    value: string;
  };
  isOnLoad: boolean;
  level: Level;
  equipment: EquipmentSelection;
  changeHandler: Function;
  isTSEdit: boolean;
};

const ReadOnlyCtrl: React.FC<EquipmentParams> = ({
  fieldModified,
  isOnLoad,
  level,
  equipment,
  changeHandler,
  isTSEdit,
}) => {
  if (isOnLoad && !isTSEdit) {
    equipment[level.attrname] = level.getDefault(equipment);
  }
  if (fieldModified && fieldModified.name !== level.attrname) {
    equipment[level.attrname] = level.getChoices(equipment)[0];
  }
  return equipment[level.attrname] ? (
    <Form.Field
      name={level.attrname}
      control={Input}
      label={level.displayName}
      placeholder={level.displayName}
      className="tsfield"
      value={level.stringify(equipment, equipment[level.attrname])}
      onChange={changeHandler}
      readOnly
      transparent
    />
  ) : null;
};

const ChoiceCtrl: React.FC<EquipmentParams> = ({
  fieldModified,
  isOnLoad,
  level,
  equipment,
  isTSEdit,
  changeHandler,
}) => {
  if (isOnLoad && !isTSEdit) {
    equipment[level.attrname] = level.getDefault(equipment);
  }
  if (fieldModified && fieldModified.name !== level.attrname) {
    const choices: any[] = level.getChoices(equipment);
    const defaultVal = level.getDefault(equipment);
    equipment[level.attrname] =
      choices[choices.indexOf(equipment[level.attrname])] || defaultVal;
  }
  return equipment[level.attrname] !== 'N/A' ? (
    <Form.Field
      name={level.attrname}
      control={Select}
      label={level.displayName}
      options={level.getChoices(equipment).map((option) => ({
        key: `${option}`,
        text: level.stringify(equipment, option),
        // The select treats the values as strings anyway
        // so we may as well force them to be strings
        value: level.stringify(equipment, option),
      }))}
      placeholder={level.displayName}
      className="tsfield"
      value={Object(level).stringify(equipment, equipment[level.attrname])}
      onChange={changeHandler}
      transparent
    />
  ) : null;
};

const EquipmentControl: React.FC<EquipmentParams> = ({
  fieldModified,
  isOnLoad,
  level,
  equipment,
  changeHandler,
  isTSEdit,
}) => {
  if (level.getChoices(equipment).length > 1)
    return (
      <ChoiceCtrl
        fieldModified={fieldModified}
        isOnLoad={isOnLoad}
        level={level}
        equipment={equipment}
        isTSEdit={isTSEdit}
        changeHandler={changeHandler}
      />
    );
  else
    return (
      <ReadOnlyCtrl
        fieldModified={fieldModified}
        isOnLoad={isOnLoad}
        level={level}
        equipment={equipment}
        isTSEdit={isTSEdit}
        changeHandler={changeHandler}
      />
    );
};

const EquipmentPanel = ({
  fieldModified,
  isOnLoad,
  equipmentSelection,
  powerSelection,
  equipment,
  changeHandler,
  isTSEdit,
}) => {
  const equPanel = [
    {
      clsName: 'equSelection',
      title: 'Equipment Selection',
      fields: equipmentSelection,
    },
    {
      clsName: 'pwrSelection',
      title: 'Power Selection',
      fields: powerSelection,
    },
  ];

  return (
    <Form style={{ flexBasis: '67%' }}>
      {equPanel.map((panel) => (
        <div className="fieldset">
          <h1>
            <span>{panel.title}</span>
          </h1>
          <div className={panel.clsName}>
            {panel.fields.map((level: Level) => (
              <EquipmentControl
                fieldModified={fieldModified}
                isOnLoad={isOnLoad}
                level={level}
                equipment={equipment}
                isTSEdit={isTSEdit}
                changeHandler={changeHandler}
              />
            ))}
          </div>
        </div>
      ))}
    </Form>
  );
};

export default EquipmentPanel;
