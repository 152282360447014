import React, { useEffect, useState, useRef } from 'react';
import { FEET_TO_METER, CLUTTER_LABELS } from 'src/app.constants';
import { meterToAny } from 'src/utils/useful_functions';
import { injectIntl, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { uiConfirmAction } from 'src/pages/mainframe/mainframe.reducer';
import NumberEditor from '../controls/lpgrid/celleditors/NumberEditor';
import {
  HeightRenderer,
  RangeRenderer,
} from '../controls/lpgrid/cellrenderers/UnitRenderer';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import Toolbar, { ToolbarType } from '../controls/Toolbar';
import messages from 'src/messages';
import AddPoint from './AddPoint';
import { processCellCallback } from '../controls/lpgrid/LPGridUtils';
import { cloneDeep } from 'lodash';
import { deletePoints, updatePoints, updateSelection } from './profile.reducer';
import AddNewPointsAtInterval from './AddNewPointsAtInterval';

export function interpolate(r1, h1, r2, h2, tr) {
  // Assume r2 is always greater than r1
  const r_delta = r2 - r1;
  // Assume that tr is greater than r1 and less than r2
  const tr_relative = tr - r1;
  return h1 + ((h2 - h1) * tr_relative) / r_delta;
}

type deletePointConfirmationProps = {
  startIndex: number;
  endIndex: number;
  ranges: number[];
  formatMessage: Function;
  prefs: {
    rangeUnits: string;
  };
  setUpdated: Function;
  profile: object;
  dispatch: Function;
};

const deletePointConfirmation = ({
  startIndex,
  endIndex,
  ranges,
  dispatch,
  formatMessage,
  prefs,
  profile,
  setUpdated,
}: deletePointConfirmationProps) => {
  // sort the start end index values
  let startIdx = Math.min(startIndex, endIndex);
  let endIdx = Math.max(startIndex, endIndex);

  // Prevent the user from deleting the start and end points
  if (startIdx === 0) {
    startIdx = 1;
  }
  if (endIdx === ranges.length - 1) {
    endIdx -= 1;
  }

  let message = '';
  if (startIdx === endIdx) {
    // user has selected a single row other than the start/end point
    const params = {
      deletePoint: meterToAny(ranges[startIdx], prefs.rangeUnits, 3).toFixed(3),
      rangeUnits: prefs.rangeUnits,
    };
    message = formatMessage(messages.deleteProfilePointMessage, params);
  } else {
    // user has selected multiple rows, but not the end points
    const params = {
      startPoint: meterToAny(ranges[startIdx], prefs.rangeUnits, 3).toFixed(3),
      endPoint: meterToAny(ranges[endIdx], prefs.rangeUnits, 3).toFixed(3),
      rangeUnits: prefs.rangeUnits,
    };
    message = formatMessage(messages.deleteProfilePointsMessage, params);
  }

  dispatch(
    uiConfirmAction({
      header: formatMessage(messages.deleteProfilePointsHeader),
      message,
      size: 'mini',
      onConfirm: () => {
        deletePointsHandler({
          startIndex: startIdx,
          endIndex: endIdx,
          profile,
          dispatch,
          setUpdated,
        });
      },
    })
  );
};

type deletePointsHandlerProps = {
  startIndex: number;
  endIndex: number;
  profile: any;
  dispatch: Function;
  setUpdated: Function;
};

const deletePointsHandler = ({
  startIndex,
  endIndex,
  profile,
  dispatch,
  setUpdated,
}: deletePointsHandlerProps) => {
  const profileTemp = cloneDeep(profile);
  const rowCount = endIndex - startIndex + 1;
  profileTemp.ranges.splice(startIndex, rowCount);
  profileTemp.heights.splice(startIndex, rowCount);
  profileTemp.clutter_types.splice(startIndex, rowCount);
  profileTemp.obstructions.splice(startIndex, rowCount);
  dispatch(deletePoints(profileTemp));
  setUpdated({ status: true });
};

type updatePointsHandlerProps = {
  gridApi: {
    getSelectedRows: Function;
  };
  columnName: string;
  value: number | string;
  data: object;
  heightUnits: string;
  clutterTypes: string[];
  clutterLabels: string[];
  profile: any;
  dispatch: Function;
  setUpdated: Function;
};

const updatePointsHandler = ({
  gridApi,
  columnName,
  value,
  data,
  heightUnits,
  clutterTypes,
  clutterLabels,
  profile,
  dispatch,
  setUpdated,
}: updatePointsHandlerProps) => {
  const { startIndex, endIndex } = profile;
  const columnReduxMapping = {
    terrain_height_m: 'heights',
    clutter_type: 'clutter_types',
    obstruction_height_m: 'obstructions',
  };
  const attr = columnReduxMapping[columnName];
  if (columnName.includes('height')) {
    // ensure that a number is pushed to the store rather than a string
    value = Number(value);
    if (heightUnits === 'ft') {
      value *= FEET_TO_METER;
    }
  }
  if (columnName === 'clutter_type') {
    value =
      clutterTypes[clutterLabels.indexOf(data['clutter_type'].split(' (')[0])];
  }
  const profileClone = cloneDeep(profile);
  const values = profileClone[attr];
  for (
    let idx = Math.min(startIndex, endIndex);
    idx <= Math.max(startIndex, endIndex);
    idx++
  ) {
    values[idx] = value;
  }
  dispatch(updatePoints(profileClone));
  setUpdated({ status: true });
};

const heightFormatter = (data: ValueFormatterParams) => {
  const { value, context } = data;
  const { prefs } = context;
  let formattedValue: string | number = meterToAny(value, prefs.heightUnits);
  if (typeof value === 'string') formattedValue = value;
  return formattedValue;
};

type cellValueChangeHandlerProps = {
  objPath: string;
  rowData: {
    data: {
      id: number;
    };
    newValue: string;
    oldValue: string;
    api: {
      applyTransaction: Function;
      getSelectedRows: Function;
    };
    objectPath: string;
    node: {
      rowPinned: string;
    };
  };
  heightUnits: string;
  clutterTypes: string[];
  clutterLabels: string[];
  profile: object;
  dispatch: Function;
  setUpdated: Function;
};

const cellValueChangeHandler = ({
  objPath,
  rowData,
  heightUnits,
  clutterTypes,
  clutterLabels,
  profile,
  dispatch,
  setUpdated,
}: cellValueChangeHandlerProps) => {
  const { data, newValue, api, objectPath = objPath } = rowData;
  updatePointsHandler({
    gridApi: api,
    columnName: objectPath,
    value: newValue,
    data,
    heightUnits,
    clutterTypes,
    clutterLabels,
    profile,
    dispatch,
    setUpdated,
  });
};

type onCellMouseDownProps = {
  evnt: any;
  startIndex: number;
  endIndex: number;
  dispatch: Function;
};

const onCellMouseDown = ({
  evnt,
  startIndex,
  endIndex,
  dispatch,
}: onCellMouseDownProps) => {
  const current = evnt.api.getSelectedNodes().map((node) => node.rowIndex);
  if (evnt.event.shiftKey) {
    // extend/update the current selection forward and backward
    const newEnd = evnt.node.rowIndex;
    let start = Math.min(newEnd > startIndex ? startIndex : endIndex, newEnd);
    let end = Math.max(newEnd > startIndex ? startIndex : endIndex, newEnd);
    dispatch(
      updateSelection({
        startIndex: start,
        endIndex: end,
        target: 'table',
      })
    );
    let selected = [];
    for (let i = start; i <= end; i++) {
      selected.push(i);
      evnt.api.getRowNode(i).setSelected(true);
    }
    current.forEach((idx) => {
      if (!selected.includes(idx)) {
        evnt.api.getRowNode(idx).setSelected(false);
      }
    });
  } else if (current.includes(evnt.node.rowIndex) && evnt.colDef.editable) {
    // do nothing when the user clicks inside the current selection
    // and the selection is editable
  } else {
    // select a new single row
    evnt.api.getSelectedNodes().forEach((node) => node.setSelected(false));
    evnt.node.setSelected(true);
    dispatch(
      updateSelection({
        startIndex: evnt.node.rowIndex,
        endIndex: evnt.node.rowIndex,
        target: 'table',
      })
    );
  }
};

const getColumnDefs = ({
  useClutter,
  rangeUnits,
  heightUnits,
  clutterTypes,
  clutterLabels,
  profile,
  dispatch,
  setUpdated,
  clutter_types,
  dropDownList,
}) => {
  return [
    {
      field: 'range_m',
      headerName: 'Range',
      minWidth: 90,
      editable: false,
      headerValueGetter: () => {
        return `Range (${rangeUnits})`;
      },
      cellRenderer: 'rangeRenderer',
      cellRendererParams: {
        precision: 3,
      },
      valueGetter: (params) => {
        return meterToAny(
          params.data.range_m,
          params.context.prefs.rangeUnits,
          3
        );
      },
    },
    {
      field: 'terrain_height_m',
      headerName: 'Terrain Height',
      minWidth: 80,
      headerValueGetter: () => {
        return `Terrain Height(${heightUnits})`;
      },
      cellRenderer: 'heightRenderer',
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      cellEditorParams: {
        precision: 1,
        min: 0,
        max: 30000,
        step: 0.1,
      },
      cellRendererParams: {
        precision: 1,
      },
      valueFormatter: heightFormatter,
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler({
          objPath: 'terrain_height_m',
          rowData,
          heightUnits,
          clutterTypes,
          clutterLabels,
          profile,
          dispatch,
          setUpdated,
        }),
    },
    ...(useClutter && !profile.is_lidar
      ? [
          {
            field: 'clutter_type',
            headerName: 'Clutter Type',
            minWidth: 170,
            headerValueGetter: () => {
              return `Clutter Type (${heightUnits})`;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: dropDownList },
            cellEditorPopup: true,
            onCellValueChanged: (rowData) =>
              cellValueChangeHandler({
                objPath: 'clutter_type',
                rowData,
                heightUnits,
                clutterTypes,
                clutterLabels,
                profile,
                dispatch,
                setUpdated,
              }),
          },
        ]
      : []),
    {
      field: 'obstruction_height_m',
      headerName: 'Obstruction Height',
      minWidth: 80,
      headerValueGetter: () => {
        return `Obstruction Height (${heightUnits})`;
      },
      cellRenderer: 'heightRenderer',
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      cellEditorParams: {
        precision: 1,
        min: 0,
        max: 30000,
        step: 0.1,
      },
      cellRendererParams: {
        precision: 1,
      },
      valueFormatter: heightFormatter,
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler({
          objPath: 'obstruction_height_m',
          rowData,
          heightUnits,
          clutterTypes,
          clutterLabels,
          profile,
          dispatch,
          setUpdated,
        }),
    },
  ];
};

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  editable: true,
  suppressMovable: true,
};

const adjustHeightHandler = ({ heightType, profile, dispatch, setUpdated }) => {
  const profileClone = JSON.parse(JSON.stringify(profile));
  const { ranges, startIndex, endIndex } = profileClone;
  const heights = profileClone[heightType];
  const start = Math.min(startIndex, endIndex);
  const end = Math.max(startIndex, endIndex);
  const startRange = ranges[start];
  const endRange = ranges[end];
  const startHeight = heights[start];
  const endHeight = heights[end];
  for (let idx = start + 1; idx < end; idx++) {
    const range = ranges[idx];
    const height = interpolate(
      startRange,
      startHeight,
      endRange,
      endHeight,
      range
    );
    heights[idx] = height;
  }
  dispatch(updatePoints(profileClone));
  setUpdated({ status: true });
};

const getActions = ({
  gridRef,
  ranges,
  dispatch,
  formatMessage,
  prefs,
  profile,
  setUpdated,
  startIndex,
  endIndex,
  setModalOpen,
  setAddPtsAtInrModalOpen,
}) => {
  let actions = [];

  if (!profile.is_lidar) {
    // Only show the Add/Delete buttons on low-res profiles
    actions.push(
      {
        icon: 'plus',
        title: formatMessage(messages.addPoint),
        onClick: () => setModalOpen(true),
        disabled: false,
      },
      {
        icon: 'trash alternate',
        title: formatMessage(messages.deletePoints),
        onClick: () =>
          deletePointConfirmation({
            startIndex,
            endIndex,
            ranges,
            dispatch,
            formatMessage,
            prefs,
            profile,
            setUpdated,
          }),
        disabled:
          // prevent the user from deleting when there is no selection
          // or when only the start/end points are selected
          // - no selection
          (!startIndex && !endIndex) ||
          // - only the first point selected
          (startIndex === 0 && startIndex === endIndex) ||
          // - only the last point selected
          (startIndex === ranges.length - 1 && startIndex === endIndex) ||
          // - short profile that only contains start/end points
          ranges.length <= 2,
      }
    );
  }

  actions.push({
    label: 'Interpolate',
    title: formatMessage(messages.interpolateHelp),
    type: 'dropdown',
    // enable when 3 rows or more are selected
    disabled: !(
      startIndex !== null &&
      endIndex !== null &&
      Math.abs(endIndex - startIndex) >= 2
    ),
    items: [
      {
        label: 'Terrain Height',
        title: formatMessage(messages.interpolateTerrainHeights),
        onClick: () => {
          adjustHeightHandler({
            heightType: 'heights',
            profile,
            dispatch,
            setUpdated,
          });
        },
      },
      {
        label: 'Obstruction',
        title: formatMessage(messages.interpolateObstructionHeights),
        onClick: () => {
          adjustHeightHandler({
            heightType: 'obstructions',
            profile,
            dispatch,
            setUpdated,
          });
        },
      },
    ],
  });

  if (!profile.is_lidar) {
    actions.push({
      icon: 'interpolate-icon',
      label: 'Interval',
      title: formatMessage(messages.interpolateAddPoints, {
        maxRangeVal: meterToAny(300, prefs.rangeUnits),
        rangeUnits: prefs.rangeUnits,
      }),
      onClick: () => {
        setAddPtsAtInrModalOpen(true);
      },
      disabled: profile.ranges[profile.ranges.length - 1] > 300.0,
    });
  }

  actions.push({
    icon: 'files-download-1-icon',
    label: 'Download CSV',
    onClick: () => {
      gridRef.current.api.exportDataAsCsv();
    },
    disabled: !Boolean(ranges.length),
  });

  return actions;
};

type ProfileEditorProps = {
  setUpdated: Function;
  csvFilename: string;
};

function ProfileEditor({  setUpdated, csvFilename }: ProfileEditorProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const gridRef = useRef<any>();
  const { clutterDetails, prefs, useClutter } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const profile = useSelector((state: RootStateOrAny) => state.profile);
  const [addPtsAtInrModalOpen, setAddPtsAtInrModalOpen] = useState(false);

  const {
    ranges,
    heights,
    obstructions,
    clutter_types,
    startIndex,
    endIndex,
    target,
  } = profile;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isScrolledToSelection = useRef(false);
  const { rangeUnits, heightUnits } = prefs;
  const clutterTypes = Object.keys(CLUTTER_LABELS);
  const clutterLabels = Object.values(CLUTTER_LABELS);
  const clutterDetailsData = {};

  // to maintain same order of desktop we have redeclared the dropDownFormat
  const dropDownFormat = {
    Unclassified: '',
    'Rural - Open': '',
    Water: '',
    'Light Trees - Shrubs': '',
    'Deciduous Forest': '',
    'Evergreen Forest': '',
    'Tropical Forest': '',
    'Sparse Houses': '',
    Suburban: '',
    'Dense Suburban': '',
    Urban: '',
    'Dense Urban': '',
    'High Rise Urban': '',
    'Industrial Zone': '',
  };

  const dropDownList = [];

  clutterDetails.forEach((item) => {
    clutterDetailsData[item.key] = {
      label: CLUTTER_LABELS[item.key],
      color: item.details[0],
      height: item.details[1],
    };
    dropDownFormat[CLUTTER_LABELS[item.key]] = meterToAny(
      item.details[1],
      heightUnits,
      1
    ).toFixed(1);
  });

  for (const [key, value] of Object.entries(dropDownFormat)) {
    dropDownList.push(`${key} (${value})`);
  }

  const columnDefs: any[] = getColumnDefs({
    useClutter,
    rangeUnits,
    heightUnits,
    clutterTypes,
    clutterLabels,
    profile,
    dispatch,
    setUpdated,
    clutter_types,
    dropDownList,
  });

  let actions: ToolbarType[] = getActions({
    gridRef,
    ranges,
    dispatch,
    formatMessage,
    prefs,
    profile,
    setUpdated,
    startIndex,
    endIndex,
    setModalOpen,
    setAddPtsAtInrModalOpen,
  });
  useEffect(() => {
    const temp = [];
    heights.forEach((item, index) => {
      temp.push({
        id: ranges[index],
        range_m: ranges[index],
        terrain_height_m: item,
        ...(clutter_types.length && {
          clutter_type: `${
            clutterDetailsData[clutter_types[index]]?.label
          } (${meterToAny(
            clutterDetailsData[clutter_types[index]]?.height,
            heightUnits,
            1
          ).toFixed(1)})`,
        }),
        obstruction_height_m: obstructions[index],
      });
    });
    // selected rows
    const seletedRows = gridRef.current?.api.getSelectedRows();
    // Save selected rows
    const selectedRowKeys = seletedRows.map((row) => row.id);
    gridRef.current?.api.setRowData(temp);
    // Reapply selections
    if (selectedRowKeys.length)
      gridRef.current?.api.forEachNode((node) => {
        if (selectedRowKeys.includes(node.data.id)) {
          node.setSelected(true);
        }
      });
  }, [heights, obstructions, ranges, clutter_types, heightUnits]);
  useEffect(() => {
    if (gridRef.current) {
      // selecting rows on grid
      if (startIndex !== null && endIndex !== null) {
        gridRef.current?.api.forEachNode((node, index) => {
          if (
            index >= Math.min(startIndex, endIndex) &&
            index <= Math.max(startIndex, endIndex)
          ) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        });
      }
      // to prvent scroll in table row click
      if (target !== 'table') {
        // scroll position resetted to 0 when no selection on profile chart
        if (startIndex === null && endIndex === null) {
          gridRef.current?.api.ensureIndexVisible(0, 'top');
          isScrolledToSelection.current = false;
        } else {
          if (startIndex <= endIndex) {
            // vertical scroll position setted to the start index of profile chart
            gridRef.current?.api.ensureIndexVisible(startIndex, 'top');
            isScrolledToSelection.current = true;
          }
          if (startIndex > endIndex) {
            // vertical scroll position setted to the end index of chart, when chart selection is doing from reverse side
            gridRef.current?.api.ensureIndexVisible(endIndex, 'top');
            isScrolledToSelection.current = true;
          }
        }
      }
    }
  }, [startIndex, endIndex, target]);

  return (
    <>
      <Toolbar
        actions={actions}
        clickHandlerParams={{ gridApi: gridRef.current?.api }}
        rowDataLength={gridRef.current?.api.getRenderedNodes().length || 0}
      ></Toolbar>
      <AddPoint
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        dropDownList={dropDownList}
        setUpdated={setUpdated}
      />
      <AddNewPointsAtInterval
        setUpdated={setUpdated}
        addPtsAtInrModalOpen={addPtsAtInrModalOpen}
        setAddPtsAtInrModalOpen={setAddPtsAtInrModalOpen}
      />
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        rowHeight={28}
        headerHeight={25}
        onCellMouseDown={(evnt) =>
          onCellMouseDown({ evnt, startIndex, endIndex, dispatch })
        }
        frameworkComponents={{
          heightRenderer: HeightRenderer,
          rangeRenderer: RangeRenderer,
          numberEditor: NumberEditor,
        }}
        context={{
          prefs: prefs,
        }}
        defaultCsvExportParams={{
          fileName: csvFilename,
          processCellCallback,
        }}
        suppressScrollOnNewData={true}
      ></AgGridReact>
    </>
  );
}

export default ProfileEditor;
