import React from 'react';
import { get } from 'lodash';
import { Form, Label } from 'semantic-ui-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GenericScaledField } from './GenericScaledField';
import { useParams } from 'react-router-dom';
import type { PTPPath } from 'src/pages/ptp/ptp-link-type';
import type { PTPChoices } from 'src/pages/ptp/utils';
import { getCablingFromChoices } from 'src/pages/ptp/utils';
import { RECALC_COLOR } from 'src/app.constants';
import { meterToAny, getUnitsLabels } from 'src/utils/useful_functions';
import { useSelector } from 'react-redux';

type Props = {
  label: string;
  getter: string;
  feederCalculateGetter: string; // checkbox
  feederTypeGetter: string; // cable dropdown
  feederLengthGetter: string; // text ctrl for cable len
  calculatedCableLossGetter: string; // readonly value after save if ticked
  endName: string;
  path: PTPPath;
  choices: PTPChoices;
  modified: boolean;
  setModified: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  min: number;
  max: number;
  units: string;
  precision: number;
};

export function CableChoicesField(props: Props) {
  const { control, getValues } = useFormContext();
  const { id } = useParams();
  const heightUnits = useSelector(
    (state: any) => state.mainFrame.prefs.heightUnits
  );

  const defaultFeederCalculate = get(props.path, props.feederCalculateGetter);
  let defaultCalculatedLoss = get(props.path, props.calculatedCableLossGetter);
  if (defaultCalculatedLoss == null || props.modified) {
    defaultCalculatedLoss = '--';
  } else {
    defaultCalculatedLoss = defaultCalculatedLoss.toFixed(1);
  }

  const currentCableChoices = getCablingFromChoices(
    props.choices, props.endName, props.pathIndex ?? 0
  );

  const defaultFeederType = get(
    props.path,
    props.feederTypeGetter,
    currentCableChoices.default_feeder_type,
  );
  const defaultFeederLength = get(
    props.path,
    props.feederLengthGetter,
    currentCableChoices.default_feeder_length,
  );

  const feederTypeChoices = currentCableChoices.cable_choices.map(
    (choice: string) => ({ text: choice, value: choice, key: choice })
  );

  const cableName = getValues(props.feederTypeGetter);
  const cableOpts = currentCableChoices.cable_options[cableName];

  const checked = useWatch({
    name: props.feederCalculateGetter,
    control,
    defaultValue: defaultFeederCalculate,
  });

  let recalcStyle = null;
  if (props.modified) {
    recalcStyle = { color: RECALC_COLOR };
  }

  const widthStyle = { width: '7rem' };

  return (
    <>
      <Form.Group>
        {checked ? (
          <Form.Field>
            <label style={recalcStyle}>Cable Loss</label>
            <Form.Group>
              <Form.Input
                value={defaultCalculatedLoss}
                disabled
                className="no-border"
                style={widthStyle}
              />
              <Label>{props.units}</Label>
            </Form.Group>
          </Form.Field>
        ) : (
          <GenericScaledField
            {...props}
            defaultValue={get(props.path, props.getter)}
            style={widthStyle}
            watch={id}
          />
        )}

        <Form.Field>
          <label>&nbsp;</label>
          <Controller
            control={control}
            name={props.feederCalculateGetter}
            defaultValue={defaultFeederCalculate}
            render={({ field: { ref, onChange, value, ...rest } }) => {
              return (
                <Form.Checkbox
                  label="Calculate"
                  onChange={(_, { checked }) => {
                    props.setModified(true);
                    onChange(checked);
                  }}
                  checked={value}
                  disabled={props.disabled}
                  style={{ paddingTop: '0.3rem' }}
                  {...rest}
                />
              );
            }}
          />
        </Form.Field>
      </Form.Group>

      {/* next row */}

      {checked ? (
        <Form.Group>
          <Form.Field>
            <label>Cable</label>
            <Controller
              control={control}
              name={props.feederTypeGetter}
              defaultValue={defaultFeederType}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Form.Select
                  options={feederTypeChoices}
                  {...rest}
                  compact
                  disabled={props.disabled}
                  style={{ width: '10rem' }}
                  onChange={(_, data) => {
                    onChange(data.value);
                    props.setModified(true);
                  }}
                />
              )}
            />
          </Form.Field>

          <Form.Field>
            {cableOpts.fixed_length ? (
              <>
                <label>Cable Length</label>
                <p style={{ marginTop: '0.7rem' }}>
                  {meterToAny(cableOpts.length, heightUnits)} {getUnitsLabels(heightUnits)}
                </p>
              </>
            ) : (
              <GenericScaledField
                label='Cable Length'
                getter={props.feederLengthGetter}
                defaultValue={defaultFeederLength}
                min={0}
                max={999}
                units={heightUnits}
                precision={1}
                disabled={props.disabled}
                setModified={props.setModified}
                style={widthStyle}
                watch={id}
              />)}
          </Form.Field>
        </Form.Group>
      ) : null}
    </>
  );
}
