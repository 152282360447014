import React from 'react';
import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Canvas from './leafletCanvas';
// import Canvas from './googleCanvas';

const initCanvas = (mapRef, formatMessage) => {
  const canvas = new Canvas(formatMessage);
  // The Google canvas needs the mapRef
  // but the Leaflet canvas doesn't
  canvas.initialise(mapRef);
};

/**
 * Leaflet/Google Map Container
 */
const LeafletMapContainer = (props) => {
  const mapRef = React.useRef('map');
  const { formatMessage } = props.intl;
  useEffect(() => {
    initCanvas(mapRef.current, formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div id="map" ref={mapRef} className="map"></div>;
};

export default injectIntl(LeafletMapContainer);
