import { get, set } from 'lodash';
import { METER_TO_FEET } from 'src/app.constants';
import {
  numberColumn,
  defaultColumn,
  unavailabilityColumn,
  valueGetter,
  makeHeightColumn,
  throughputColumn as _throughputColumn,
  reliabilityColumn as _reliabilityColumn,
  clutterTypeGetter,
} from 'src/components/controls/lpgrid/common/columnTypes';
import { store } from 'src/store';
import {
  meterToAny,
  metresToHeightUnits,
  postBulkUpdates,
  temperatureFromKelvin,
} from 'src/utils/useful_functions';
import { setBulkEditSelectedRows } from '../mainframe/mainframe.reducer';
import { VALID_PTP_BANDS } from 'src/model/BandDefinition';

function tooltip(params: any) {
  return params.data.warnings_hover || null;
}

function isTopLink(params: any) {
  return params.data.path_index === 0;
}

const throughputColumn = {
  ..._throughputColumn,
  editable: isTopLink,
};

const reliabilityColumn = {
  ..._reliabilityColumn,
  editable: isTopLink,
};

const makeEndCols = (endName, cellValueChangeHandler) => {
  const endLabel = endName === 'local' ? 'Left' : 'Right';
  return [
    {
      ...defaultColumn,
      minWidth: 280,
      field: `${endName}.antenna`,
      headerName: `${endLabel} Antenna`,
    },
    {
      ...numberColumn,
      field: `${endName}.annual1_way_availability`,
      headerName: `${endLabel} Annual 1-way Availability %`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4, '0'),
    },
    {
      ...numberColumn,
      field: `${endName}.antenna_height`,
      headerName: `${endLabel} Height`,
      headerValueGetter: ({ context }) => {
        return `${endLabel} Height (${context.prefs.heightUnits})`;
      },
      tooltipValueGetter: () => {
        return 'Range 0 to 3000m';
      },
      filterParams: {
        valueGetter: (params) => {
          if (store.getState().mainFrame.prefs.heightUnits === 'ft')
            return Number(
              params.data.local.antenna_height * METER_TO_FEET
            ).toFixed(1);
          else return Number(params.data.local.antenna_height).toFixed(1);
        },
      },
      getQuickFilterText: ({ data, context }) => {
        if (context.prefs.heightUnits === 'ft')
          return Number(data.local.antenna_height * METER_TO_FEET).toFixed(1);
        else return Number(data.local.antenna_height).toFixed(1);
      },
      editable: isTopLink,
      cellRenderer: 'heightRenderer',
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.antenna_height`),
      cellEditorParams: {
        precision: 1,
        min: 0,
        max: 3000,
        step: 0.1,
      },
    },
    {
      ...numberColumn,
      field: `${endName}.rx_antenna_gain`,
      headerName: `${endLabel} Gain (dBi)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1, '0'),
    },
    {
      ...numberColumn,
      field: `${endName}.bearing`,
      headerName: `${endLabel} Bearing, True N (deg)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1),
    },
    // {
    //     field: `${endName}.magnetic_bearing`,
    //     headerName: `${endLabel} Bearing, Mag N (deg)`,
    //     minWidth: 120,
    //     cellRenderer: 'fixedRenderer',
    //     isComparatorRequired: true,
    // },
    {
      ...numberColumn,
      field: `${endName}.eirp`,
      headerName: `${endLabel} Max EIRP (dBm)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...numberColumn,
      field: `${endName}.power`,
      headerName: `${endLabel} Max Power (dBm)`,
      cellRenderer: 'fixedRenderer',
    },
    {
      ...numberColumn,
      field: `${endName}.user_eirp`,
      headerName: `${endLabel} EIRP Limit (dBm)`,
      cellRenderer: 'fixedRenderer',
      cellEditorParams: {
        min: -10,
        max: 90,
        precision: 1,
      },
      editable: isTopLink,
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.user_eirp`),
    },
    {
      ...defaultColumn,
      field: `${endName}.use_user_eirp`,
      headerName: `${endLabel} User EIRP Limit?`,
      valueGetter: (params) => {
        return get(params, `data.${endName}.use_user_eirp`, '') === true ||
          get(params, `data.${endName}.use_user_eirp`, '') === 'Yes'
          ? 'Yes'
          : 'No';
      },
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Yes', 'No'],
      },
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.use_user_eirp`),
    },
    {
      ...numberColumn,
      field: `${endName}.user_power`,
      headerName: `${endLabel} Power Limit (dBm)`,
      editable: isTopLink,
      cellEditor: 'numberEditor',
      cellEditorParams: {
        min: -30,
        max: 40,
        precision: 1,
      },
      cellClass: ['number-cell'],
      valueGetter: (params) => valueGetter(params, 1),
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.user_power`),
    },
    {
      ...defaultColumn,
      field: `${endName}.use_noise`,
      headerName: `${endLabel} Interference?`,
      valueGetter: (params) => {
        return get(params, `data.${endName}.use_noise`, '') === true ||
          get(params, `data.${endName}.use_noise`, '') === 'Yes'
          ? 'Yes'
          : 'No';
      },
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Yes', 'No'],
      },
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.use_noise`),
    },
    {
      ...numberColumn,
      field: `${endName}.noise`,
      headerName: `${endLabel} Interference (dBm in b/w)`,
      cellRenderer: 'fixedRenderer',
      cellEditor: 'numberEditor',
      cellClass: ['number-cell'],
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.noise`),
      valueGetter: (params) => valueGetter(params, 1, ''),
      editable: (params) =>
        get(params, `data.${endName}.use_noise`, false) && isTopLink(params),
      cellEditorParams: {
        precision: 1,
        min: -144,
        max: -40,
      },
    },
    {
      ...defaultColumn,
      field: `${endName}.use_user_power`,
      headerName: `${endLabel} User Power Limit?`,
      valueGetter: (params) => valueGetter(params, 3),
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Yes', 'No'],
      },
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, `${endName}.use_user_power`),
    },
    {
      ...numberColumn,
      field: `${endName}.tilt`,
      headerName: `${endLabel} Antenna Tilt (deg)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...defaultColumn,
      field: `${endName}.ethernet_cable`,
      headerName: `${endLabel} Ethernet Cable`,
    },
    {
      ...numberColumn,
      field: `${endName}.feeder_loss`,
      headerName: `${endLabel} Feeder Loss (dB)`,
      valueGetter: (params) => valueGetter(params, 1),
    },

    // ................. Left Performance End Specific Parameters ..................

    {
      ...defaultColumn,
      field: `${endName}.predicted_receive_power`,
      headerName: `${endLabel} Predicted Receive Power`,
      cellRenderer: 'fixedRenderer',
    },
    {
      ...numberColumn,
      field: `${endName}.mean_data_rate_predicted`,
      headerName: `${endLabel} Mean IP Predicted (Mbps)`,
      valueGetter: (params) => valueGetter(params, 3),
      cellRendererParams: {
        precision: 3,
      },
      cellRenderer: 'fixedRenderer',
    },
    {
      ...throughputColumn,
      field: `${endName}.mean_data_rate_requirement`,
      headerName: `${endLabel} Mean IP Required (Mbps)`,
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(
          rowData,
          'summary.local.mean_data_rate_requirement'
        ),
    },
    {
      ...numberColumn,
      field: `${endName}.percentage_of_required_data_rate`,
      headerName: `${endLabel} % of Required IP`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 3),
    },
    {
      ...numberColumn,
      field: `${endName}.throughput_reliability`,
      headerName: `${endLabel} Min IP Availability Predicted (%)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4, '0'),
    },
    {
      ...throughputColumn,
      field: `${endName}.minimum_data_rate_requirement`,
      headerName: `${endLabel} Min IP Required (Mbps)`,
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(
          rowData,
          'summary.local.minimum_data_rate_requirement'
        ),
    },
    {
      ...reliabilityColumn,
      field: `${endName}.minimum_reliability_requirement`,
      headerName: `${endLabel} Min IP Availability Required %`,
      cellRenderer: 'fixedRenderer',
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(
          rowData,
          'summary.local.minimum_reliability_requirement'
        ),
    },
    {
      ...defaultColumn,
      field: `${endName}.transmit_frequency`,
      headerName: `${endLabel} Tx Frequency (MHz)`,
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2, ''),
    },
    {
      ...defaultColumn,
      field: `${endName}.tx_golay`,
      headerName: `${endLabel} Golay`,
    },
    {
      ...defaultColumn,
      field: `${endName}.polarity`,
      headerName: `${endLabel} Polarity`,
    },
    {
      field: `${endName}.diversity_spacing`,
      headerName: `${endLabel} Diversity Spacing`,
      headerValueGetter: ({ context }) => {
        return `Left Diversity Spacing (${context.prefs.heightUnits})`;
      },
      minWidth: 120,
      filterParams: {
        valueGetter: (params) => {
          if (params.data.local.diversity_spacing == null) return null;
          if (store.getState().mainFrame.prefs.heightUnits === 'ft')
            return Number(
              params.data.local.diversity_spacing * METER_TO_FEET
            ).toFixed(1);
          else return Number(params.data.local.diversity_spacing).toFixed(1);
        },
      },
      getQuickFilterText: ({ context, data }) => {
        if (data.local.diversity_spacing == null) return null;
        if (context.prefs.heightUnits === 'ft')
          return Number(data.local.diversity_spacing * METER_TO_FEET).toFixed(
            1
          );
        else return Number(data.local.diversity_spacing).toFixed(1);
      },
      cellRenderer: 'heightRenderer',
      ...defaultColumn,
    },
    {
      field: `${endName}.clutter_type`,
      headerName: `${endLabel} Clutter Type`,
      minWidth: 180,
      valueGetter: clutterTypeGetter,
    },
    {
      field: `${endName}.latitude`,
      headerName: `${endLabel} Latitude`,
      minWidth: 120,
      tooltipValueGetter: tooltip,
    },
    {
      field: `${endName}.longitude`,
      headerName: `${endLabel} Longitude`,
      minWidth: 120,
      tooltipValueGetter: tooltip,
    },
    {
      field: `${endName}.mac_address`,
      headerName: `${endLabel} MAC Address`,
      minWidth: 100,
      tooltipValueGetter: tooltip,
      cellRenderer: 'macRenderer',
    },
    makeHeightColumn(`${endName}.max_height`, `${endLabel} Max Height`),
    makeHeightColumn(`${endName}.height`, `${endLabel} Ground Height`),
    {
      field: `${endName}.name`,
      sort: 'asc',
      minWidth: 120,
      headerName: `${endLabel} Name`,
      cellClass: ['ag-grid-cell-truncate-text'],
      tooltipValueGetter: tooltip,
      cellRenderer: 'linkRenderer',
      cellRendererParams: {
        getHref: (data) => {
          const id = get(data, `${endName}.site_id`);
          return `/network_sites/${id}`;
        },
      },
      isComparatorRequired: true,
    },
  ];
};

export const getPTPColumns = (projectId) => {
  const postNDUpdates = (
    projectId,
    ids,
    objectPath,
    value,
    gridApi,
    heightUnits = ''
  ) => {
    postBulkUpdates(
      projectId,
      ids,
      objectPath,
      value,
      gridApi,
      'ptp',
      heightUnits
    );
  };

  const cellValueChangeHandler = (rowData, objectPath) => {
    let { data, newValue, oldValue, api, context } = rowData;
    let selectedIds = [data.id];
    const { prefs } = context;
    const heightPaths = ['local.antenna_height', 'remote.antenna_height'];
    let selectedRows = api?.getSelectedRows();
    if (!selectedRows?.length) selectedRows = [data];
    if (api !== null && selectedRows.length) {
      selectedIds = [];
      selectedRows.forEach((row) => {
        if (heightPaths.includes(objectPath)) {
          if (prefs.heightUnits === 'ft') {
            row[objectPath] = parseFloat(newValue) / METER_TO_FEET;
          } else {
            row[objectPath] = parseFloat(newValue);
          }
        } else {
          set(row, objectPath, newValue);
        }
        row.updated = false;
        selectedIds.push(row.id);
      });
      api.applyTransaction({ update: selectedRows });
    }
    if (
      heightPaths.includes(objectPath) &&
      parseFloat(newValue) !== parseFloat(oldValue) &&
      api != null
    ) {
      store.dispatch(
        setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
      );
      postNDUpdates(
        projectId,
        selectedIds,
        objectPath,
        newValue,
        api,
        context.prefs.heightUnits
      );
    } else {
      newValue =
        newValue === 'Yes'
          ? true
          : newValue === 'No'
          ? false
          : isNaN(Number(newValue))
          ? newValue
          : Number(newValue);
      store.dispatch(
        setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
      );
      postNDUpdates(projectId, selectedIds, objectPath, newValue, api);
    }
  };

  const isEditable = (params, dependentField) => {
    return get(params, 'data.' + dependentField);
  };

  const columns = [
    {
      ...defaultColumn,
      field: 'name',
      sort: 'asc',
      headerName: 'Name',
      pinned: 'left',
      cellClass: ['ag-grid-cell-truncate-text'],
      getQuickFilterText: (params) => {
        return (
          params.value &&
          params.value.toString().toLowerCase().replaceAll(' ', '')
        );
      },
      cellRenderer: 'linkRenderer',
      cellRendererParams: {
        getHref: (data) => `/ptp/${data.id}`,
      },
      cellStyle: (params) => {
        if (params.data.path_index !== 0) {
          return { paddingLeft: '45px' };
        }
        return {};
      },
    },
    {
      ...defaultColumn,
      field: 'range_m',
      minWidth: 90,
      headerName: 'Range',
      headerValueGetter: ({ context }) => {
        return `Range (${context.prefs.rangeUnits})`;
      },
      cellRenderer: 'rangeRenderer',
      cellRendererParams: {
        precision: 3,
      },
      valueGetter: (params) => {
        return meterToAny(
          params.data.range_m,
          params.context.prefs.rangeUnits,
          3
        );
      },
    },
    {
      ...numberColumn,
      field: 'fresnel_zone_clearance',
      headerName: 'Fresnel Zone Clearance',
      headerValueGetter: ({ context }) => {
        return `Fresnel Zone Clearance (${context.prefs.heightUnits})`;
      },
      filterParams: {
        valueGetter: ({ context, data }) => {
          if (context.prefs.heightUnits === 'ft')
            return Number(data.fresnel_zone_clearance * METER_TO_FEET).toFixed(
              1
            );
          else return Number(data.fresnel_zone_clearance).toFixed(1);
        },
      },
      getQuickFilterText: ({ context, data }) => {
        if (context.prefs.heightUnits === 'ft')
          return Number(data.fresnel_zone_clearance * METER_TO_FEET).toFixed(1);
        else return Number(data.fresnel_zone_clearance).toFixed(1);
      },
      cellRenderer: 'heightRenderer',
      cellRendererParams: {
        precision: 1,
      },
    },
    {
      ...defaultColumn,
      field: 'band',
      headerName: 'Band',
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellClass: ['select-cell'],
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, 'equipment.band'),
      cellEditorParams: {
        values: VALID_PTP_BANDS,
      },
      isComparatorRequired: true,
    },
  ];

  const additionalColumns = [
    // ....................... Common Equipment Parameters ...........................

    {
      ...defaultColumn,
      minWidth: 150,
      field: 'product',
      headerName: 'Product',
      isComparatorRequired: true,
    },
    {
      ...defaultColumn,
      minWidth: 150,
      field: 'link_protection',
      headerName: 'Link Type',
    },
    {
      ...defaultColumn,
      minWidth: 150,
      field: 'master_end',
      headerName: 'Master End',
    },
    {
      ...defaultColumn,
      field: 'regulation',
      headerName: 'Regulation',
      getQuickFilterText: (params) => {
        return (
          params.value &&
          params.value.toString().toLowerCase().replaceAll(' ', '')
        );
      },
    },
    {
      ...numberColumn,
      field: 'downlink_data',
      headerName: 'Downlink Data (%)',
    },
    {
      ...defaultColumn,
      field: 'frame_period',
      headerName: 'Frame Period',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 3),
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'frame_size',
      headerName: 'Frame Size',
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellClass: ['select-cell'],
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, 'summary.link.frame_size'),
      cellEditorParams: {
        values: ['64', '128', '256', '512', '1024', '1518', 'Tolly Mix'],
      },
      valueGetter: (params) => valueGetter(params),
    },
    {
      ...numberColumn,
      field: 'link_availability',
      headerName: 'Link Availability',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...numberColumn,
      field: 'system_fade_margin',
      headerName: 'System Gain Margin (dB)',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...numberColumn,
      field: 'npoints',
      headerName: '# Points in Profile',
    },
    {
      ...defaultColumn,
      field: 'profile_type',
      headerName: 'Profile Type',
    },
    {
      ...numberColumn,
      field: 'mean_aggregate_data_rate',
      headerName: 'Aggregate Throughput (Mbps)',
      valueGetter: (params) => valueGetter(params, 3),
    },
    {
      ...defaultColumn,
      minWidth: 150,
      field: 'remote_product',
      headerName: 'Remote Product',
    },
    {
      ...numberColumn,
      field: 'bandwidth',
      headerName: 'Bandwidth (MHz)',
    },
    {
      ...defaultColumn,
      field: 'dl_ul_ratio',
      headerName: 'DL/UL Ratio',
    },
    {
      ...defaultColumn,
      field: 'max_mod_mode',
      headerName: 'Maximum Mod Mode',
    },
    {
      ...defaultColumn,
      field: 'aes_encryption',
      headerName: 'Encryption Variant',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 3),
    },
    {
      ...defaultColumn,
      field: 'guard_interval',
      headerName: 'Guard Interval',
      valueGetter: (params) => valueGetter(params, 3),
    },
    {
      ...defaultColumn,
      field: 'tdm',
      headerName: 'E1/T1',
    },
    {
      ...defaultColumn,
      field: 'tdm_type',
      headerName: 'TDM Type',
    },
    {
      ...defaultColumn,
      field: 'lowest_ethernet_mode',
      headerName: 'Lowest Ethernet Mode',
    },
    {
      ...defaultColumn,
      field: 'lowest_telecoms_mode',
      headerName: 'Lowest Telecoms Mode',
    },
    {
      ...defaultColumn,
      field: 'sync',
      headerName: 'Sync',
    },
    {
      ...defaultColumn,
      field: 'optimisation',
      headerName: 'Optimization',
    },
    {
      ...defaultColumn,
      field: 'symmetry',
      headerName: 'Symmetry',
    },
    {
      ...defaultColumn,
      field: 'dual_payload',
      headerName: 'Dual Payload',
    },
    {
      ...defaultColumn,
      field: 'highest_mod_mode',
      headerName: 'Highest Mod Mode',
    },
    {
      ...defaultColumn,
      field: 'precise_network_timing',
      headerName: 'Precise Network Timing',
    },
    {
      ...defaultColumn,
      field: 'acmb',
      headerName: 'ACMB',
    },
    {
      ...defaultColumn,
      field: 'atpc',
      headerName: 'ATPC',
    },
    {
      ...defaultColumn,
      field: 'hi',
      headerName: 'Hi End',
    },
    {
      ...defaultColumn,
      field: 'lo',
      headerName: 'Lo End',
    },
    {
      ...defaultColumn,
      field: 'header_compression',
      headerName: 'Header Compression',
    },
    {
      ...defaultColumn,
      field: 'min_mod_mode',
      headerName: 'Minimum Mod Mode',
    },
    {
      ...defaultColumn,
      field: 'modulation_mode',
      headerName: 'Modulation Mode',
    },
    {
      ...defaultColumn,
      field: 'tr_spacing',
      headerName: 'T/R Spacing (MHz)',
    },
    {
      ...defaultColumn,
      field: 'polarization',
      headerName: 'Polarization',
    },
    {
      ...defaultColumn,
      field: 'remote_mount',
      headerName: 'Remote Mount',
    },

    // ..................  Performance Results Parameters  ......................

    {
      ...numberColumn,
      field: 'dn_dh',
      headerName: 'dN/dH (N units/km)',
    },
    {
      ...numberColumn,
      field: 'area_roughness',
      headerName: 'Area Roughness(m)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'geo_climatic_factor',
      headerName: 'Geoclimatic Factor',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'fade_occurrence_factor',
      headerName: 'Fade Occurrence Factor (Po)',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'inclination',
      headerName: 'Inclination (mr)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'terrain_roughness',
      headerName: 'Terrain Roughness',
      valueGetter: ({ context, data }) => {
        return metresToHeightUnits(
          data.terrain_roughness,
          context.prefs.heightUnits
        );
      },
      filterParams: {
        valueGetter: ({ context, data }) => {
          return metresToHeightUnits(
            data.terrain_roughness,
            context.prefs.heightUnits
          );
        },
      },
      getQuickFilterText: ({ context, data }) => {
        return metresToHeightUnits(
          data.terrain_roughness,
          context.prefs.heightUnits
        );
      },
    },
    {
      ...defaultColumn,
      field: 'climatic_factor',
      headerName: 'Climatic Factor',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...defaultColumn,
      field: 'c_factor',
      headerName: 'C Factor',
    },
    {
      ...defaultColumn,
      field: 'temperature',
      headerName: 'Temperature',
      valueGetter: ({ context, data }) => {
        // The value from the backend is in Kelvin.
        return temperatureFromKelvin(
          data.temperature,
          context.prefs.heightUnits
        );
      },
      filterParams: {
        valueGetter: ({ context, data }) => {
          // The value from the backend is in Kelvin.
          return temperatureFromKelvin(
            data.temperature,
            context.prefs.heightUnits
          );
        },
      },
      getQuickFilterText: ({ context, data }) => {
        return temperatureFromKelvin(
          data.temperature,
          context.prefs.heightUnits
        );
      },
    },
    {
      ...numberColumn,
      field: 'worst_earth_ke',
      headerName: 'Worst Earth (ke)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'free_space_path_loss',
      headerName: 'Free Space Path Loss (dB)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'gaseous_obsorption_loss',
      headerName: 'Gaseous Absorption Loss (dB)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...numberColumn,
      field: 'excess_path_loss',
      headerName: 'Excess Path Loss (dB)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1, '0'),
    },
    {
      ...numberColumn,
      field: 'excess_path_loss_at_ke',
      headerName: 'Excess Path Loss at ke (dB)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1, '0'),
    },
    {
      ...numberColumn,
      field: 'total_path_loss',
      headerName: 'Link Loss (dB)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'rain_rate',
      headerName: '0.01% Rain Rate',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
      isComparatorRequired: true,
    },
    {
      ...defaultColumn,
      field: 'rain_attenuation',
      headerName: 'Rain Attenuation (dB/km)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 1),
    },
    {
      ...numberColumn,
      field: 'rain_availability',
      headerName: 'Rain Availability %',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4, 0),
    },
    {
      ...unavailabilityColumn,
      field: 'rain_unavailability',
      headerName: 'Rain Unavailability',
      isComparatorRequired: true,
    },
    {
      ...numberColumn,
      field: 'annual_2_way_availability',
      headerName: 'Annual 2-way Availability %',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...numberColumn,
      field: 'annual_2_way_availability_with_rain',
      headerName: 'Annual 2-way Availability Including Rain %',
    },
    {
      ...unavailabilityColumn,
      field: 'annual_2_way_unavailability',
      headerName: 'Annual 2-way Unavailability',
      isComparatorRequired: true,
    },
    {
      ...unavailabilityColumn,
      field: 'annual_2_way_unavailability_with_rain',
      headerName: 'Annual 2-way Unavailability Including Rain',
      isComparatorRequired: true,
    },
    {
      ...reliabilityColumn,
      field: 'e1t1_reliability',
      headerName: 'E1/T1 Availability %',
      cellRenderer: 'fixedRenderer',
    },
    {
      ...reliabilityColumn,
      field: 'e1t1_reliability_requirement',
      headerName: 'E1/T1 Availability Required %',
      cellRenderer: 'fixedRenderer',
    },
    {
      ...numberColumn,
      field: 'e1t1_latency',
      headerName: 'E1/T1 1-way latency (ms)',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 2),
    },
    {
      ...defaultColumn,
      field: 'power_from_switch',
      headerName: 'Power from Switch',
      minWidth: 130,
      cellRenderer: 'noneStringRenderer',
      editable: isTopLink,
      cellEditor: 'agSelectCellEditor',
      cellClass: ['select-cell'],
      cellEditorParams: {
        values: ['None', 'Local', 'Remote', 'Both'],
      },
      onCellValueChanged: (rowData) =>
        cellValueChangeHandler(rowData, 'power_from_switch'),
    },
    {
      ...numberColumn,
      field: 'min_payload_capacity',
      headerName: 'FCC Minimum Payload Availability',
      cellRenderer: 'fixedRenderer',
      valueGetter: (params) => valueGetter(params, 4),
    },
    {
      ...defaultColumn,
      field: 'min_payload_mode',
      headerName: 'FCC Minimum Payload Mode',
    },

    ...makeEndCols('local', cellValueChangeHandler),
    ...makeEndCols('remote', cellValueChangeHandler),
  ];

  return [...columns, ...additionalColumns];
};
