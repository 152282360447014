import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getWithAuth } from 'src/api';
import { RootStateOrAny } from 'src/store';
import SiteForm from './SiteForm';
import { Loader, Segment } from 'semantic-ui-react';

function SitePanel(props) {
  const { pathname } = useLocation();
  const panelKind = pathname.includes('network_site')
    ? 'network_site'
    : 'subscriber_site';
  const { siteId } = useParams();
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { data: site, isError: siteIsError } = useQuery(
    [projectId, 'site', siteId],
    async () => {
      const site = await getWithAuth(`project/${projectId}/site/${siteId}`);
      return site;
    }
  );

  const { data: siteGraph, isError: graphError } = useQuery(
    [projectId, 'site', siteId, 'graph'],
    async () => {
      const graph = await getWithAuth(
        `project/${projectId}/site/${siteId}/graph`
      );
      return graph;
    }
  );
  if (siteIsError || graphError) {
    return (
      <Segment inverted color="red">
        An error occurred while fetching the site details.
      </Segment>
    );
  }
  if (site == null || siteGraph == null) {
    return (
      <div className="network-site-panel">
        <Loader active></Loader>;
      </div>
    );
  }

  return (
    <SiteForm
      projectId={projectId}
      id={siteId}
      site={site}
      graph={siteGraph}
      kind={panelKind}
    ></SiteForm>
  );
}

export default SitePanel;
