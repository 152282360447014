import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Button,
  Form,
  Header,
  Loader,
  List,
  Popup,
  Icon,
  Input,
  Dropdown,
  Modal,
  Grid,
  Message,
  Label,
} from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import config from '../../config';
import { store } from '../../store';
import additionalMessages from '../../messages';
import { postWithAuth } from '../../api';
import { runWithConfirmation } from '../../utils/useful_functions';
import {
  loadProject,
  getProjects,
  uiSet,
  longTaskStarted,
  longTaskComplete,
} from '../mainframe/mainframe.reducer';
import { PREDICTION_MODEL_OPTIONS } from 'src/app.constants';
import Footer from '../../components/Footer';
import ConditionalPopup from '../../components/ConditionalPopup';
import { NAME_MAX_LENGTH } from 'src/app.constants';
import NewProjectDialog from './NewProjectDialog';

class ProjectsPanelContainer extends Component {
  state = {
    busy: false,
    projectPromise: null,
    renaming: null,
    getProjectName: false,
    modifiedName: null,
    importProject: false,
    filter: '',
    filteredItems: null,
    showAll: false,
    predictionModel: PREDICTION_MODEL_OPTIONS[1]['value'],
    useClutter: true,
    error: null,
    projectsLimit: store.getState().mainFrame.userLimits?.projects,
  };

  componentDidMount = () => {
    this.getProjectInfo();
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.status === 'success' && props.action === 'import') {
      store.dispatch(getProjects());
    }
    return null;
  };

  getProjectInfo = () => {
    // this.setState({ busy: true });
    store.dispatch(getProjects());
  };

  createProject = (e) => {
    this.setState({ getProjectName: true, modifiedName: null });
  };

  cancelCreateProject = () => {
    this.setState({ getProjectName: false, modifiedName: null });
  };

  importProject = (e) => {
    store.dispatch(uiSet({ showLPImportDialog: true }));
  };

  loadProject = (pid) => {
    return (e) => {
      e.preventDefault();
      store.dispatch(
        loadProject({
          projectId: pid,
          projectName: e.target.name,
        })
      );
      this.props.navigate('/');
    };
  };

  rename = (id, name) => {
    return (e) => {
      this.setState({ renaming: { id, name, origName: name } });
    };
  };

  onNameKey = (e) => {
    if (e.key === 'Enter') {
      this.saveNameChange();
    }
  };

  saveNameChange = () => {
    const renaming = this.state.renaming;
    this.setState({ renaming: null });
    if (renaming && renaming.name !== renaming.origName) {
      const { name, id } = this.state.renaming;
      postWithAuth(`project/${id}`, { name }, 'PATCH').then(() => {
        this.getProjectInfo();
      });
    }
  };

  onNameChange = (e, { value }) => {
    this.setState({ renaming: { ...this.state.renaming, name: value } });
  };

  onPropertiesChange = (e, { name, value, checked }) => {
    const fieldValue = value ? value : checked;
    this.setState({ [name]: fieldValue });
  };
  onNameBlur = (e) => {
    this.saveNameChange();
  };

  delete = (id, name, modified, readOnly) => {
    const { formatMessage } = this.props.intl;

    return (e) => {
      let deleteFunc, header;
      // we don't want to show the delete message for a discard or a permission change
      let hardDelete = false;
      if (modified) {
        // discard changes (deletes in-work)
        deleteFunc = (id) => {
          return postWithAuth(`project/${id}/work`, {}, 'DELETE');
        };
        header = `${formatMessage(additionalMessages.discardChanges)}: ${name}`;
      } else if (readOnly) {
        // remove user from project
        deleteFunc = (id) => {
          return postWithAuth(`project/${id}/permissions`, {}, 'DELETE');
        };
        header = `${formatMessage(additionalMessages.leaveProject)}: ${name}`;
      } else {
        // delete project
        deleteFunc = (id) => {
          return postWithAuth(`project/${id}`, {}, 'DELETE');
        };
        header = `${formatMessage(additionalMessages.delete)}: ${name}`;
        hardDelete = true;
      }

      runWithConfirmation({
        header: header,
        onConfirm: () => {
          if (hardDelete) {
            store.dispatch(longTaskStarted({
              heading: "Deleting project",
              message: "This may take a while.",
            }));
          }
          deleteFunc(id)
            .then(() => {
              this.getProjectInfo();
            })
            .catch(console.error)
            .finally(() => {
              if (hardDelete) {
                store.dispatch(longTaskComplete());
              }
            });
        },
      });
    };
  };

  clone = (id, name) => {
    return (e) => {
      runWithConfirmation({
        header: `Duplicate: ${name}`,
        onConfirm: () => {
          postWithAuth(`project/${id}/clone`, {})
            .then(() => {
              this.getProjectInfo();
              this.setState({ error: null });
            })
            .catch((e) => {
              this.setState({ error: e.detail });
            });
        },
      });
    };
  };

  onClearFilter = (e) => {
    this.setState({
      filter: '',
      filteredItems: null,
      showAll: false,
    });
  };

  onFilterChange = (e, { value }) => {
    this.setState({
      filter: value,
      filteredItems: null,
    });
  };

  onShowAll = () => {
    this.setState({
      showAll: true,
    });
  };

  render = () => {
    // TODO: Sort the projects and allow the user
    // to delete them - when do we delete the local copy??
    const { formatMessage } = this.props.intl;
    //const projectId = this.props.projectId;
    const { projects, userLimits, preventDiscard } = this.props;
    const canCreateProjects = projects.length < userLimits?.projects;
    const { filter, showAll } = this.state;
    let filteredProjects = projects;
    try {
      const exp = new RegExp(filter, 'i');
      filteredProjects = projects.filter((p) => exp.test(p.project.name));
    } catch {
      // Invalid regex
    }
    const displayShowMore = !showAll && filteredProjects.length > 10;
    if (!showAll) {
      filteredProjects = filteredProjects.slice(0, 10);
    }

    const { renaming } = this.state;
    const today = new Date().toDateString();
    const dbLinks = filteredProjects.map((row, index) => {
      const { permission, last_accessed, project, in_work } = row;
      const { id, name, description, modified } = project;
      // TODO define js equivalent to PermissionEnum and compare here
      const readOnly = permission === 1; // read
      const permissionName = ['read', 'readwrite', 'admin'][permission - 1];
      // take a stab at guessing the locale for date formatting
      const locale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language || 'en-GB';
      const modifiedDate = new Date(Date.parse(last_accessed));
      const modifiedDateString =
        modifiedDate.toDateString() === today
          ? modifiedDate.toLocaleString(locale, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })
          : modifiedDate.toLocaleString(locale, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });

      // Note the  style={{ display: 'inline' }} is required so that the
      // icons line up correctly with the icon group
      let projectIcon = (
        <Icon name="file outline" style={{ display: 'inline' }} />
      );
      if (in_work && modified) {
        projectIcon = (
          <Popup
            size="tiny"
            trigger={
              <Icon
                name="file outline"
                color="red"
                style={{
                  display: 'inline',
                }}
              />
            }
            content={formatMessage(additionalMessages.unsavedChanges)}
          />
        );
      } else if (readOnly) {
        projectIcon = (
          <Popup
            size="tiny"
            trigger={
              <Icon.Group>
                <Icon name="file outline" />
                <Icon corner="top right" name="protect" />
              </Icon.Group>
            }
            content={formatMessage(additionalMessages[permissionName])}
          />
        );
      }
      return (
        <List.Item key={id} data-testid={`${name} List Item`}>
          <List.Content
            floated="right"
            data-testid={`${name}-ellipsisHorizontal`}
          >
            <Dropdown icon="ellipsis horizontal" direction="left">
              <Dropdown.Menu>
                <Dropdown.Item
                  text={`${formatMessage(additionalMessages.rename)} ${name}`}
                  disabled={readOnly}
                  onClick={this.rename(id, name)}
                  data-testid="rename-option"
                />
                <Dropdown.Item
                  text={formatMessage(additionalMessages.duplicateProject)}
                  onClick={this.clone(id, name)}
                  data-testid="clone-option"
                />
                <Dropdown.Item
                  text={
                    in_work && modified
                      ? formatMessage(additionalMessages.discardChanges)
                      : readOnly
                      ? formatMessage(additionalMessages.leaveProject)
                      : formatMessage(additionalMessages.delete)
                  }
                  onClick={this.delete(id, name, in_work, readOnly)}
                  disabled={preventDiscard === id}
                  data-testid="delete-option"
                />
              </Dropdown.Menu>
            </Dropdown>
          </List.Content>
          <List.Content floated="right" data-testid="modifiedDateString">
            {modifiedDateString}
          </List.Content>
          <List.Icon data-testid="projectIcon">{projectIcon}</List.Icon>
          <List.Content>
            <List.Header
              name={name}
              title={formatMessage(additionalMessages.openProject)}
              data-testid="projectHeaderName"
            >
              {renaming && renaming.id === id ? (
                <Input
                  value={renaming.name}
                  fluid
                  autoFocus
                  autoCapitalize="off"
                  onBlur={this.onNameBlur}
                  onChange={this.onNameChange}
                  onKeyPress={this.onNameKey}
                  maxLength={NAME_MAX_LENGTH}
                />
              ) : (
                <Button
                  onClick={this.loadProject(id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    fontSize: '1em',
                    fontWeight: '700',
                    textAlign: 'left',
                    color: '#4183C4',
                  }}
                >
                  {name}
                </Button>
              )}
            </List.Header>
            <List.Description>{description}</List.Description>
          </List.Content>
        </List.Item>
      );
    });

    return (
      <>
        <Grid container columns={2}>
          <Grid.Column
            widescreen={8}
            largeScreen={8}
            mobile={16}
            tablet={16}
            computer={16}
          >
            <Segment basic size="large">
              <NewProjectDialog
                isOpen={this.state.getProjectName}
                onClose={this.cancelCreateProject}
              />
              <ConditionalPopup
                showPopup={!canCreateProjects}
                message={formatMessage(additionalMessages.maximumNumberError, {
                  entityName: 'Project',
                  limit: store.getState().mainFrame.userLimits?.projects,
                })}
              >
                <Button
                  content={formatMessage(additionalMessages.createNewProject)}
                  onClick={this.createProject}
                  disabled={this.state.busy || !canCreateProjects}
                  data-testid="createNewProject"
                />
              </ConditionalPopup>
              <ConditionalPopup
                showPopup={!canCreateProjects}
                message={formatMessage(additionalMessages.maximumNumberError, {
                  entityName: 'Project',
                  limit: store.getState().mainFrame.userLimits?.projects,
                })}
              >
                <Button
                  content={formatMessage(
                    additionalMessages.importLINKPlannerProject
                  )}
                  onClick={this.importProject}
                  disabled={this.state.busy || !canCreateProjects}
                  data-testid="importLINKPlannerProject"
                />
              </ConditionalPopup>
              {this.state.error && <Message error>{this.state.error}</Message>}
              {dbLinks.length > 0 || this.state.filter !== '' ? (
                <>
                  <Header data-testid="recentProjects">
                    Projects
                    <Label
                      className="float-right"
                      title="LINKPlanner projects"
                      color="black"
                    >
                      {projects.length}
                    </Label>
                  </Header>
                  <Input
                    placeholder={formatMessage(additionalMessages.filter)}
                    fluid
                    icon={{
                      name: 'cancel',
                      onClick: this.onClearFilter,
                      link: true,
                      title: formatMessage(additionalMessages.clearFilter),
                    }}
                    value={this.state.filter}
                    onChange={this.onFilterChange}
                    autoFocus
                    data-testid="filter"
                  />

                  <List
                    celled
                    style={{
                      maxHeight: '72vh',
                      overflowY: 'auto',
                      minHeight: '40vh',
                    }}
                  >
                    {dbLinks}
                  </List>

                  {displayShowMore ? (
                    <Button onClick={this.onShowAll} data-testid="showAll">
                      {formatMessage(additionalMessages.showAll)}
                    </Button>
                  ) : null}
                </>
              ) : (
                <Segment basic>
                  <Header data-testid="gettingStartedWithLinkPlanner">
                    Getting started with LINKPlanner
                  </Header>
                  <p>
                    Click on <strong>"Create new project"</strong> button to
                    start a new LINKPlanner project.
                  </p>
                  <p>
                    To return to this menu and see your project list at any
                    time, click on the{' '}
                    <strong>"Projects" -> "Project List"</strong> in the header
                    menu.
                  </p>
                  <p>
                    For more information, see the&nbsp;
                    <a
                      href={config.userGuide}
                      title={formatMessage(additionalMessages.userGuide)}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid="userGuide"
                    >
                      user guide
                    </a>
                    .
                  </p>
                </Segment>
              )}
              <Loader active={this.state.busy} />
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center" only="large screen">
            <a
              href={window?.runtime?.marketingUrl}
              target="_blank"
              rel="noreferrer"
              title={window?.runtime?.marketingTitle}
            >
              <img
                alt={window?.runtime?.marketingTitle}
                style={{ margin: '20px' }}
                border="0"
                src={window?.runtime?.marketingImage}
                data-testid="marketingImage"
              />
            </a>
          </Grid.Column>
        </Grid>
        <Footer about></Footer>
      </>
    );
  };
}

const ProjectsPanel = connect((state, ownProps) => {
  return {
    ...ownProps,
    status: state.sockets.status,
    action: state.sockets.action,
    projects: state.mainFrame.projects,
    projectId: state.mainFrame.projectId,
    userLimits: state.mainFrame.userLimits,
    preventDiscard: state.mainFrame.preventDiscard,
  };
})(ProjectsPanelContainer);

function WrapProjectsPanel(props) {
  const navigate = useNavigate();
  return <ProjectsPanel {...props} navigate={navigate} />;
}

export default injectIntl(WrapProjectsPanel);
