import React from "react";
import { Popup } from "semantic-ui-react";

type PropsT = {
  showPopup: boolean;
  message: string;
  children: React.ReactNode;
};

export default function ConditionalPopup(props: PropsT) {
  if (props.showPopup) {
    return <Popup
      content={props.message}
      trigger={<span>{props.children}</span>}
    />
  } else {
    return props.children;
  }
}