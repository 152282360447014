import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import PTPLinksTable from './PTPLinksTable';
import { PanelHeading } from 'src/components/PanelHeading';

function PTPLinksPanel({ intl }) {
  const { ptpLinks, ptpLinksCount } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  let items = [];
  if (ptpLinks) {
    items = ptpLinks.features.map((link) => {
      return link.properties;
    });
  }

  const listView = <PTPLinksTable />;
  return (
    <>
      <PanelHeading
        title={
          <>
            <FormattedMessage id="common.ptpLinks" defaultMessage="PTP Links" />
            {` (${ptpLinksCount})`}
          </>
        }
      />
      <div className="detailWrapper">{listView}</div>
    </>
  );
}

export default injectIntl(PTPLinksPanel);
