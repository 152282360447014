export const interpolate = (t: number, lim0: number, lim1: number, v0: number, v1: number): number => {
    let proportion = (Math.log(t) - Math.log(lim0)) / (Math.log(lim1) - Math.log(lim0));
    return v0 * (1.0 - proportion) + v1 * proportion;
}

export const _worstEarthInterpolator = (val: number): number => {
    if (val > 200.0) {
        return 1.0;
    } else if (val >= 100.0) {
        return interpolate(val, 100.0, 200.0, 0.92, 1.0);
    } else if (val >= 70.0) {
        return interpolate(val, 70.0, 100.0, 0.87, 0.92);
    } else if (val >= 50.0) {
        return interpolate(val, 50.0, 70.0, 0.8, 0.87);
    } else if (val >= 30.0) {
        return interpolate(val, 30.0, 50.0, 0.69, 0.8);
    } else if (val >= 20.0) {
        return interpolate(val, 20.0, 30.0, 0.59, 0.69);
    } else if (val >= 10.0) {
        return interpolate(val, 10.0, 20.0, 0.4, 0.59);
    } else {
        return 0.4;
    }
}

// Returns the value of ke for the worst month at a probability of 99.9%
// using a mathematical implemnetation of Figure 2 in P530-12
// pathLength in meters
export const worstEarth = (pathLength: number): number => {
    if (pathLength < 10.0) {
        return 0.4;
    }

    return _worstEarthInterpolator(pathLength * 0.001);
}