import React from 'react';
/*
 * See the get_gain_limits.py script to generate the json data
 * using the desktop code.
 */
import ptpGainData from './ptp_antenna_gain_limits.json';
import smGainData from './sm_antenna_gain_limits.json';
import { Table } from 'semantic-ui-react';
import { PTP_ANTENNA } from './antenna.service';

/*
 * Reshape an array of cells into 2 column layout
 */
function* reshape(cells, kind) {
  for (let i = 0; i <= cells.length; i += 2) {
    const rowkey = `row-${kind}-${i}`;
    yield (
      <Table.Row key={rowkey}>
        {cells.slice(i, i + 2).map((col, j) => {
          return { ...col, key: `col-${kind}-${i}-${j}` };
        })}
      </Table.Row>
    );
  }
}

export const AntennaGainTable = ({ kind }) => {
  const limits = kind === PTP_ANTENNA ? ptpGainData : smGainData;
  const tableCells = limits.map((bandGroup) => {
    const [band, prodLimits] = bandGroup;
    return (
      <>
        <Table.Cell width={1} textAlign="center" key={`${kind}-${band}`}>
          {band}
        </Table.Cell>
        <Table.Cell width={3} key={`${kind}-${band}-limits`}>
          {prodLimits.map((p, idx) => (
            <div key={`${kind}-${band}-${idx}`}>{p}</div>
          ))}
        </Table.Cell>
      </>
    );
  });

  const tableBody = [];
  for (const row of reshape(tableCells, kind)) {
    tableBody.push(row);
  }

  return (
    <Table columns={4} striped celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Band</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Maximum Antenna Gain
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Band</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Maximum Antenna Gain
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
};
