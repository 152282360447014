import React, { useEffect, useRef, useState, useCallback } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ButtonProps, Segment, Loader, Header } from 'semantic-ui-react';
import LPGrid from '../../components/controls/lpgrid/LPGrid';
import { ToolbarType } from '../../components/controls/Toolbar';
import MeshLinkDialog from './MeshLinkDialog';
import { postWithAuth } from '../../api';
import { getMeshLinksColumns } from './mesh_links_columns';
import messages from 'src/messages';
import {
  panelNeedsRefresh,
  uiConfirmAction,
  bulkUpdateRowRemove,
} from 'src/pages/mainframe/mainframe.reducer';
import { store } from 'src/store';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import debounce from 'lodash/debounce';
import { DELETE_ACTION_TOOLBAR } from 'src/app.constants';

export type MeshLinksTableType = {
  apId: number;
  projectId: string;
  prefs: object;
  needsRefresh: boolean;
  disabled: boolean;
  permissionWrite: boolean;
  apsName: string;
} & WrappedComponentProps;

function MeshLinksTable({
  apId,
  projectId,
  prefs,
  disabled,
  needsRefresh,
  intl,
  permissionWrite,
  apsName,
}: MeshLinksTableType) {
  const tableRef = useRef(null);
  const { formatMessage } = intl;
  const [addMLModalOpen, setAddMLModalOpen] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { projectName, bulkEditSelectedRows } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  useEffect(() => {
    if (bulkEditSelectedRows.length) {
      bulkEditSelectedRows.forEach((data) => {
        if (data.updated) {
          tableRef.current?.api?.getRowNode(data.id)?.setData({ ...data });
          store.dispatch(bulkUpdateRowRemove(data));
        }
      });
    }
  }, [bulkEditSelectedRows]);

  // pmplinkpanel notification comes when we use copy from existing
  // dropdown, so we need to listen to it and refresh the grid
  useEffect(() => {
    if (needsRefresh) {
      tableRefreshCb();
    }
  }, [needsRefresh]);

  const addHandler = (event: any, data: ButtonProps, params) => {
    setAddMLModalOpen(true);
  };

  const tableRefreshCb = useCallback(
    debounce(() => tableRef?.current?.refresh(), 3000, {
      maxWait: 20000,
      leading: true,
      trailing: true,
    }),
    [tableRef.current]
  );

  const deleteHandler = (event: any, data: ButtonProps, { gridApi }) => {
    const selectedDNIds = gridApi.getSelectedRows().map((row) => row.id);
    store.dispatch(
      uiConfirmAction({
        header: formatMessage(messages.deleteMeshLinks),
        message: formatMessage(messages.confirm),
        size: 'mini',
        onConfirm: () => {
          gridApi.showLoadingOverlay();
          postWithAuth(
            `project/${projectId}/mesh_links`,
            selectedDNIds,
            'DELETE'
          )
            .then((res) => {
              tableRefreshCb();
            })
            .catch((err) => {
              setError(err.detail);
            })
            .finally(() => {
              gridApi.hideOverlay();
            });
        },
      })
    );
  };

  let deleteAction = {
    onClick: deleteHandler,
    disabled: true,
  };

  let actionsInit: ToolbarType[] = [
    {
      icon: 'add',
      label: formatMessage(messages.add),
      onClick: addHandler,
      disabled: !permissionWrite,
    },
    Object.assign(deleteAction, DELETE_ACTION_TOOLBAR),
  ];

  const [actions, setActions] = useState<ToolbarType[]>(actionsInit);

  useEffect(() => {
    const modifiedActions = actions.map((action: ToolbarType) => {
      if (action.label === formatMessage(messages.add))
        action.disabled = !permissionWrite;
      return action;
    });
    setActions(modifiedActions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionWrite]);
  const dropdownOptionsData = [];
  const allColumns: any = getMeshLinksColumns(prefs, projectId).map((col) =>
    col.hasOwnProperty('getQuickFilterText')
      ? { ...col }
      : {
          ...col,
          getQuickFilterText: (params) => {
            return (
              params.value &&
              params.value.toString().toLowerCase().replaceAll(' ', '')
            );
          },
        }
  );

  return (
    <>
      {' '}
      {disabled || needsRefresh ? (
        <Segment inverted className="update-required">
          {formatMessage(messages.saveChangesPanel, {
            panelName: 'mesh links',
          })}
        </Segment>
      ) : null}
      <Segment basic disabled={disabled}>
        {addMLModalOpen && (
          <MeshLinkDialog
            apId={apId}
            projectId={projectId}
            onClose={() => {
              setAddMLModalOpen(false);
            }}
            successCallback={() => {
              tableRefreshCb();
            }}
          ></MeshLinkDialog>
        )}
        {bulkEditSelectedRows.length > 0 && (
          <Header as="h3">
            <Loader active inline size="tiny" />{' '}
            {formatMessage(messages.calculatingResults)}
          </Header>
        )}
        <div style={{ position: 'relative' }}>
          <div
            className="overlay"
            style={{
              display: `${bulkEditSelectedRows.length > 0 ? 'block' : 'none'}`,
            }}
          ></div>
          <LPGrid
            actions={actions}
            addStatusColor={true}
            callback={(data) => {
              store.dispatch(
                panelNeedsRefresh({
                  panels: ['accessPointPanel'],
                  status: false,
                })
              );
            }}
            gridRef={tableRef}
            url={`project/${projectId}/access_point/${apId}/mesh_links`}
            //refreshOn={needsRefresh}
            defaultCsvExportParams={{
              fileName: `${projectName}_${apsName}_mesh_links.csv`,
            }}
            table_id="mesh_links_for_ap"
            isTableColConfigure={true}
            getRowNodeId={(data) => {
              return data.id;
            }}
            columnDefs={allColumns}
            enableBrowserTooltips
            getRowStyle={(params: any) => {
              if (!!params.data.strokeColor) {
                return { color: params.data.strokeColor };
              }
            }}
          ></LPGrid>
        </div>
      </Segment>
    </>
  );
}

export default injectIntl(MeshLinksTable);
