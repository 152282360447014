import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import messages from 'src/messages';

function CreateAccessPointModalFooter(props) {
  const { handleNextStep, handlePrevStep, closeHandler, children } = props;
  const { formatMessage } = useIntl();

  return (
    <div
      style={{
        width: 'auto',
        position: 'fixed',
        bottom: '0',
        right: '1.5rem',
      }}
    >
      {children}
      {handleNextStep && (
        <Button
          floated="right"
          style={{ marginBottom: '15px' }}
          onClick={handleNextStep}
        >
          {formatMessage(messages.next)}
        </Button>
      )}
      {handlePrevStep && (
        <Button
          floated="right"
          style={{ marginBottom: '15px' }}
          onClick={handlePrevStep}
        >
          {formatMessage(messages.previous)}
        </Button>
      )}
      {closeHandler && (
        <Button
          floated="right"
          style={{ marginBottom: '15px' }}
          onClick={closeHandler}
        >
          {formatMessage(messages.cancel)}
        </Button>
      )}
    </div>
  );
}

export default CreateAccessPointModalFooter;
