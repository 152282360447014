import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Segment,
  Icon,
  Message,
  Button,
  Modal,
  Loader,
  Header,
  Grid,
} from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { store } from '../../store';
import config from '../../config';
import additionalMessages from '../../messages';
import WorkbenchPanel from './Workbenchpanel';
import HeaderPanel from './Header';
import MainMenu from './MainMenu';
import ConfirmAction from '../../components/ConfirmAction';
import {
  uiCollapseSidebar,
  uiExpandSidebar,
  uiSet,
  warningMsg,
} from './mainframe.reducer';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import ImportProjectDialog from '../project/ImportDialog';
import { getLocalJSON, setLocalJSON } from 'src/utils/useful_functions';
import { useLocation } from "react-router-dom";

let eventCleanupFunc = null;

function handleDismiss() {
  store.dispatch(warningMsg());
}

const clearUpdate = () => {
  // clear the hasUpdated flag as soon as it has been read
  localStorage.removeItem('cn.lp.hasUpdated');
  store.dispatch(uiSet({ hasUpdated: false }));
};

const handleSidebarMove = (sidebarRef, resizeHandleRef, dispatch) => {
  let isResizing = false;
  const sb = sidebarRef.current;
  let sbSize = sb.offsetWidth;
  const clientWidth = document.getElementById('root').clientWidth;
  let lastPos = sbSize;

  function mousemove(e) {
    if (!isResizing) {
      return;
    }

    const pos = e.pageX;
    if (pos > clientWidth / 2) {
      return;
    }
    if (pos <= 60) {
      dispatch(uiCollapseSidebar());
    } else {
      dispatch(uiExpandSidebar());
      const delta = Number(pos) - lastPos;
      sbSize += delta;
      const pixelSize = `${sbSize}px`;
      sb.style.flexBasis = pixelSize;
      const sidebarMenu = sidebarRef.current
        ? sb.getElementsByClassName('sidebar-menu')
        : null;
      if (sidebarMenu && sidebarMenu.length > 0) {
        sidebarMenu[0].style.maxWidth = pixelSize;
      }

      setLocalJSON('cn.lp.sidebarWidth', pixelSize);
      lastPos = pos;
    }
  }

  function mousedown(e) {
    e.preventDefault();
    isResizing = true;
    dispatch(uiSet({ sidebarResizing: true }));
  }

  function mouseup(e) {
    isResizing = false;
    dispatch(uiSet({ sidebarResizing: false }));
  }

  const resizeHandle = resizeHandleRef.current;
  resizeHandle.addEventListener('mousedown', mousedown);
  document.addEventListener('mouseup', mouseup);
  document.addEventListener('mousemove', mousemove);

  return () => {
    resizeHandle.removeEventListener('mousedown', mousedown);
    document.removeEventListener('mouseup', mouseup);
    document.removeEventListener('mousemove', mousemove);
  };
};

function ApplicationUpdating() {
  return (
    <Modal
      open={true}
      dimmer="inverted"
      inverted
      centered={false}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Header>
        <Header as="h1">
          <FormattedMessage
            id="mainFrame.Updating"
            defaultMessage="LINKPlanner is updating"
          />
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column textAlign="center" width={2}>
              <Loader inline />
            </Grid.Column>
            <Grid.Column width={14}>
              <Segment basic size="huge">
                Please wait for the update to complete...
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

function UpdatedSuccessfully(props) {
  const { formatMessage } = props.intl;

  return (
    <Modal
      open={true}
      dimmer="inverted"
      inverted
      centered={false}
      closeOnDimmerClick={false}
      onClose={clearUpdate}
    >
      <Modal.Header>
        <Header as="h1">
          <FormattedMessage
            id="mainFrame.Updated"
            defaultMessage="LINKPlanner Updated"
          />
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <Segment basic size="huge">
          LINKPlanner has updated successfully to v{window.runtime.version}.
        </Segment>
        <Segment basic size="huge">
          The{' '}
          <a
            href={config.releaseNotes}
            title={formatMessage(additionalMessages.releaseNotes)}
            target="_blank"
            rel="noopener noreferrer"
          >
            release notes
          </a>{' '}
          document the changes in this version.
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={clearUpdate}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

const siteRouteRegex = /(network|subscriber)_sites\/[a-fA-F0-9-]+$/;

function MainFrame(props) {
  const {
    connected,
    warning,
    loggedIn,
    isUpdating,
    hasUpdated,
    expandSidebar,
    projectId,
  } = useSelector((state) => state.mainFrame);
  const [connectedAfterGrace, setConnectedAfterGrace] = useState(true);
  const connectionDelay = useRef(null);
  const sidebarRef = useRef(null);
  const mainContentRef = useRef(null);
  const resizeHandleRef = useRef(null);

  const {  pathname  } = useLocation();
  const isFullWidth = !(pathname === '/project' || siteRouteRegex.test(pathname));
  let halfWidthStyle = null;
  if (!isFullWidth) {
    halfWidthStyle = { display: 'flex', flexDirection: 'row-reverse' };
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!connected) {
      // start timeout waiting for connection
      // if not connected after 5 sec, show error
      connectionDelay.current = setTimeout(
        () => setConnectedAfterGrace(false),
        5000
      );
    } else {
      if (!connectedAfterGrace) {
        // show as connected if we are currently showing disconnected
        setConnectedAfterGrace(true);
      }

      if (connectionDelay.current != null) {
        // connected, clear timeout if there is one
        clearTimeout(connectionDelay.current);
        connectionDelay.current = null;
      }
    }

    return () => {
      if (connectionDelay.current != null) {
        // clear timeout on unmount
        clearTimeout(connectionDelay.current);
        connectionDelay.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  useEffect(() => {
    if (eventCleanupFunc) {
      eventCleanupFunc();
      eventCleanupFunc = null;
    }
    if (projectId) {
      eventCleanupFunc = handleSidebarMove(
        sidebarRef,
        resizeHandleRef,
        dispatch
      );
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      const sb = sidebarRef.current;
      const pixelSize = expandSidebar
        ? getLocalJSON('cn.lp.sidebarWidth', '250px')
        : '50px';
      sb.style.flexBasis = pixelSize;
      // no need to set the maxWidth of the children here since
      // it is controlled by the initial style for "sidebar-menu"
    }
  }, [expandSidebar, projectId]);

  let message = null;
  let warningHeight = 45;
  if (warning) {
    let dismiss = null;
    if (warning.allowClose) {
      dismiss = handleDismiss;
    }

    const additionalAttrs = {
      error: warning.error ? true : false,
      success: warning.success ? true : false,
      warning: warning.warning ? true : false,
      info: warning.info ? true : false,
    };
    const icon = warning.icon ? warning.icon : 'warning sign';

    message = (
      <Message {...additionalAttrs} icon onDismiss={dismiss} size="mini" style={{
        margin: 0,
        borderRadius: 0,
        height: `${warningHeight}px`,
      }}>
        <Icon name={icon} />
        <Message.Content>
          <Message.Header>{warning.heading}</Message.Header>
          {warning.message}
        </Message.Content>
      </Message>
    );
  }

  // show connection error when user is logged in and not connected
  const connectionError =
    !loggedIn || connectedAfterGrace ? null : (
      <Segment raised>
        <Message error icon>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>
              <FormattedMessage
                id="mainMenu.connectionError"
                defaultMessage="Server Connection Error"
              />
            </Message.Header>
            <FormattedMessage
              id="mainMenu.connectionErrorDetail"
              defaultMessage="The connection with the server has been lost. Please reload this page to try to reconnect."
            />
            <Segment basic>
              <Button
                onClick={() => window.location.reload()}
                color="black"
                size="large"
              >
                Reload Page
              </Button>
            </Segment>
          </Message.Content>
        </Message>
      </Segment>
    );

  const updateNotification = isUpdating
    ? ApplicationUpdating()
    : hasUpdated
    ? UpdatedSuccessfully({ ...props })
    : null;

  let sidebarClass = null;
  if (!projectId) {
    sidebarClass = 'hidden';
  } else if (!expandSidebar) {
    sidebarClass = 'collapsed';
  }

  let headerHeight = 48.4;
  let outerContainerStyle;
  if (warning) {
    outerContainerStyle = {
      height: `calc(100vh - ${headerHeight + warningHeight}px)`,
    };
  } else {
    outerContainerStyle = {
      height: `calc(100vh - ${headerHeight}px)`,
    };
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmAction />
      {updateNotification}
      <HeaderPanel {...props} />
      {message}
      {connectionError ? connectionError : null}
      <div className="outer-container" style={outerContainerStyle}>
        <div
          className={`col sidebar-${sidebarClass} noselect`}
          id="sidebar"
          ref={sidebarRef}
        >
          <MainMenu />
        </div>
        {projectId && (
          <div
            className="resize-handle"
            id="resizeHandle"
            ref={resizeHandleRef}
          >
            <div className="resize-handle-marker">⁞</div>
          </div>
        )}
        <div className="col" id="mainContent" ref={mainContentRef} style={halfWidthStyle}>
          <WorkbenchPanel isFullWidth={isFullWidth}>{props.children}</WorkbenchPanel>
        </div>
        <ImportProjectDialog />
      </div>
    </Fragment>
  );
}

export default injectIntl(MainFrame);
