import { METER_TO_FEET } from 'src/app.constants';
import { store } from '../../store';
import { meterToAny, postBulkUpdates } from '../../utils/useful_functions';
import { setBulkEditSelectedRows } from '../mainframe/mainframe.reducer';
import { ColDef, ICellEditorParams, NewValueParams } from 'ag-grid-community';
import {
  clutterTypeGetter,
  valueGetter,
} from 'src/components/controls/lpgrid/common/columnTypes';
import { range } from 'lodash';

function tooltip(params: any) {
  return params.data.warnings || null;
}

const linkNameGetter = (params) => {
  if (params.data.radio_qty > 1) {
    return `${params.data.ap_name} to ${params.data.sm_name} (${
      params.data.path_index + 1
    })`;
  } else {
    return `${params.data.ap_name} to ${params.data.sm_name}`;
  }
};

const smNameGetter = (params) => {
  if (params.data.radio_qty > 1) {
    return `${params.data.sm_name} (${params.data.path_index + 1})`;
  } else {
    return params.data.sm_name;
  }
};

export const getPMPLinksColumns = (prefs, projectId, includeAp?: boolean) => {
  let cols = [];
  if (includeAp) {
    cols.push({
      field: 'ap_name',
      sort: 'asc',
      minWidth: 120,
      headerName: 'ND Name',
      cellClass: ['ag-grid-cell-truncate-text'],
      tooltipValueGetter: tooltip,
      cellRenderer: 'linkRenderer',
      cellRendererParams: {
        getHref: (data) => `/aps/${data.local_end_id}`,
      },
      isComparatorRequired: true,
    });
  }
  cols.push({
    field: 'name',
    sort: 'asc',
    minWidth: 120,
    headerName: 'Name',
    pinned: includeAp ? 'left' : null,
    cellClass: ['ag-grid-cell-truncate-text'],
    cellRenderer: 'linkRenderer',
    cellRendererParams: {
      getHref: (data) => `/subscribers/${data.remote_end_id}`,
    },
    valueGetter: linkNameGetter,
    isComparatorRequired: true,
    cellStyle: (params) => {
      if (includeAp && params.data.path_index > 0) {
        return { paddingLeft: '45px' };
      }
      return {};
    },
  });
  cols.push(
    ...[
      {
        field: 'sm_name',
        sort: 'asc',
        headerName: 'SM Name',
        pinned: !includeAp ? 'left' : null,
        cellClass: ['ag-grid-cell-truncate-text'],
        minWidth: 120,
        tooltipValueGetter: tooltip,
        cellRenderer: 'linkRenderer',
        cellRendererParams: {
          getHref: (data) => `/subscribers/${data.remote_end_id}`,
        },
        isComparatorRequired: true,
        valueGetter: smNameGetter,
        cellStyle: (params) => {
          if (!includeAp && params.data.path_index > 0) {
            return { paddingLeft: '45px' };
          }
          return {};
        },
      },
      {
        field: 'range_m',
        headerName: 'Range',
        minWidth: 90,
        headerValueGetter: ({ context }) => {
          return `Range (${context.prefs.rangeUnits})`;
        },
        tooltipValueGetter: tooltip,
        cellRenderer: 'rangeRenderer',
        cellRendererParams: {
          precision: 3,
        },
        valueGetter: (params) => {
          return meterToAny(
            params.data.range_m,
            params.context.prefs.rangeUnits,
            3
          );
        },
      },
      {
        field: 'sm_site_name',
        headerName: 'SM Site Name',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'ap_site_name',
        headerName: 'ND Site Name',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'ap_msn',
        headerName: 'ND MSN',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'sm_msn',
        headerName: 'SM MSN',
        minWidth: 150,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'product',
        headerName: 'Product',
        minWidth: 150,
        cellClass: ['ag-grid-cell-truncate-text'],
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'sm_height_m',
        headerName: 'SM Height',
        minWidth: 80,
        headerValueGetter: ({ context }) => {
          return `SM Height (${context.prefs.heightUnits})`;
        },
        tooltipValueGetter: tooltip,
        cellRenderer: 'heightRenderer',
        cellEditor: 'numberEditor',
        cellClass: ['number-cell'],
        cellEditorParams: {
          precision: 1,
          min: 0,
          max: 3000,
          step: 0.1,
        },
        filterParams: {
          valueGetter: (params) => {
            if (store.getState().mainFrame.prefs.heightUnits === 'ft')
              return Number(params.data.sm_height_m * METER_TO_FEET).toFixed(1);
            else return Number(params.data.sm_height_m).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          if (store.getState().mainFrame.prefs.heightUnits === 'ft')
            return Number(params.data.sm_height_m * METER_TO_FEET).toFixed(1);
          else return Number(params.data.sm_height_m).toFixed(1);
        },
        onCellValueChanged: (rowData) => {
          const {
            data,
            newValue,
            oldValue,
            api,
            objectPath = 'radios[0].antennas[0].height',
            context,
          } = rowData;
          let selectedIds = [data.remote_end_id];
          const { prefs } = context;
          let selectedRows = api?.getSelectedRows() || [];
          if (!selectedRows?.length) selectedRows = [data];
          if (api != null && selectedRows?.length) {
            selectedIds = [];
            selectedRows.forEach((row) => {
              if (prefs.heightUnits === 'ft') {
                row.sm_height_m = parseFloat(newValue) / METER_TO_FEET;
              } else {
                row.sm_height_m = parseFloat(newValue);
              }
              row.updated = false;
              selectedIds.push(row.remote_end_id);
            });
            api.applyTransaction({ update: selectedRows });
          }
          if (parseFloat(newValue) != parseFloat(oldValue) && api != null) {
            store.dispatch(
              setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
            );
            postBulkUpdates(
              projectId,
              selectedIds,
              objectPath,
              newValue,
              api,
              'pmpLink',
              context.prefs.heightUnits
            );
          }
        },
      },
      {
        field: 'excess_path_loss_db',
        headerName: 'Excess Path Loss (dB)',
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        minWidth: 80,
        cellRendererParams: {
          precision: 2,
        },
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.excess_path_loss_db).toFixed(2);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.excess_path_loss_db).toFixed(2);
        },
      },
      {
        field: 'sm_receive_level_dbm',
        headerName: 'SM Receive Level (dBm)',
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        minWidth: 80,
        cellRendererParams: {
          precision: 1,
        },
        isComparatorRequired: true,
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.sm_receive_level_dbm).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.sm_receive_level_dbm).toFixed(1);
        },
      },
      {
        field: 'sm_rx_max_usable_mode',
        headerName: 'SM Rx Max Usable Mode',
        minWidth: 150,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'sm_predicted_availability',
        headerName: 'SM Predicted Availability (%)',
        minWidth: 90,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 4,
        },
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.sm_predicted_availability).toFixed(4);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.sm_predicted_availability).toFixed(4);
        },
      },
      {
        field: 'ap_receive_level_dbm',
        headerName: 'ND Receive Level (dBm)',
        minWidth: 90,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
        isComparatorRequired: true,
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.ap_receive_level_dbm).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.ap_receive_level_dbm).toFixed(1);
        },
      },
      {
        field: 'ap_rx_max_usable_mode',
        headerName: 'ND Rx Max Usable Mode',
        minWidth: 150,
        tooltipValueGetter: tooltip,
        isComparatorRequired: true,
      },
      {
        field: 'ap_predicted_availability',
        headerName: 'ND Predicted Availability (%)',
        tooltipValueGetter: tooltip,
        minWidth: 80,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 4,
        },
        filterParams: {
          valueGetter: (params) => {
            return parseFloat(params.data.ap_predicted_availability).toFixed(4);
          },
        },
        getQuickFilterText: (params) => {
          return parseFloat(params.data.ap_predicted_availability).toFixed(4);
        },
      },
      {
        field: 'spatial_frequency',
        headerName: 'Spatial Frequency',
        minWidth: 70,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
    ]
  );
  return cols;
};

export const getAdditionalPMPLinksColumns = (projectId) => {
  let cols: ColDef[] = [];

  cols.push(
    ...[
      {
        field: '001_rain_rate',
        headerName: '0.01% Rain rate',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 2,
        },
      },
      {
        field: 'rain_attenuation',
        headerName: 'Rain Attenuation (dB/km)',
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'antenna_beamwidth',
        headerName: 'SM Antenna Beamwidth (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'antenna_elevation',
        headerName: 'SM Antenna Elevation (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'antenna_gain',
        headerName: 'SM Gain (dBi)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'radio_qty',
        headerName: 'Number of Component Carriers',
        editable: ({ data }) => {
          return data.ap_radio_qty > 1 && data.path_index === 0 ? true : false;
        },
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'naStringRenderer',
        cellEditorSelector: (params: ICellEditorParams) => {
          const { data } = params;
          const { ap_radio_qty } = data;
          return {
            component: 'agSelectCellEditor',
            params: {
              values: range(1, ap_radio_qty + 1),
            },
          };
        },
        onCellValueChanged: (event: NewValueParams) => {
          const { newValue, api, data } = event;
          if (!api) {
            return;
          }

          let smIds = [data.remote_end_id];
          let selectedRows = api.getSelectedRows();
          if (!selectedRows?.length) selectedRows = [data];
          if (api !== null && selectedRows.length) {
            smIds = [];
            selectedRows.forEach((row) => {
              if (row.path_index === 0) {
                const { remote_end_id } = row;
                row.radio_qty = newValue;
                row.updated = false;
                smIds.push(remote_end_id);
              }
            });
            api.applyTransaction({ update: selectedRows });
          }
          store.dispatch(
            setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
          );
          postBulkUpdates(
            projectId,
            smIds,
            'radio_qty',
            newValue,
            api,
            'pmpLink',
            ''
          );
        },
      },
      {
        field: 'ap_radio_number',
        headerName: 'Component Carrier Number',
        editable: ({ data }) => {
          // only allow the user to edit the quantity when
          // there are more radios on the AP than on the SM
          return data.radio_qty < data.ap_radio_qty ? true : false;
        },
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'naStringRenderer',
        cellEditorSelector: (params: ICellEditorParams) => {
          const { data } = params;
          const { ap_radio_qty } = data;
          return {
            component: 'agSelectCellEditor',
            params: {
              values: range(1, ap_radio_qty + 1),
            },
          };
        },
        onCellValueChanged: (event: NewValueParams) => {
          const { newValue, api, data } = event;
          if (!api) {
            return;
          }

          let smIds = [data.remote_end_id];
          let selectedRows = api.getSelectedRows();
          if (!selectedRows?.length) selectedRows = [data];
          if (api !== null && selectedRows.length) {
            smIds = [];
            selectedRows.forEach((row) => {
              if (row.path_index === 0 && row.radio_qty !== 2) {
                const { remote_end_id } = row;
                row.ap_radio_number = newValue;
                row.updated = false;
                smIds.push(remote_end_id);
              }
            });
            api.applyTransaction({ update: selectedRows });
          }
          store.dispatch(
            setBulkEditSelectedRows(JSON.parse(JSON.stringify(selectedRows)))
          );
          postBulkUpdates(
            projectId,
            smIds,
            'radios.0.antennas.0.sector',
            newValue,
            api,
            'pmpLink',
            ''
          );
        },
      },
      {
        field: 'bearing',
        headerName: 'SM Bearing, True N (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      // {
      //   field: 'sm_bearing_mag_n',
      //   headerName: 'SM Bearing, Mag N (deg)',
      //   tooltipValueGetter: tooltip,
      //   cellRenderer: 'fixedRenderer',
      //   cellRendererParams: {
      //     precision: 1,
      //   },
      // },
      {
        field: 'bearing_from_boresight',
        headerName: 'SM Bearing from ND Boresight (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'bearing_ap_to_sm',
        headerName: 'ND Bearing, True N (deg)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      // {
      //   field: 'ap_bearing_mag_n',
      //   headerName: 'ND Bearing, Mag N (deg)',
      //   tooltipValueGetter: tooltip,
      //   cellRenderer: 'fixedRenderer',
      //   cellRendererParams: {
      //     precision: 1,
      //   },
      // },
      {
        field: 'ap_antenna_gain',
        headerName: 'ND Gain (dBi)',
        minWidth: 80,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'band',
        headerName: 'Band',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'bandwidth',
        headerName: 'Bandwidth (MHz)',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'country',
        headerName: 'Country',
        minWidth: 140,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'fresnel_clearence_m',
        headerName: 'Fresnel Zone Clearance',
        minWidth: 100,
        headerValueGetter: ({ context }) => {
          return `Fresnel Zone Clearance (${context.prefs.heightUnits})`;
        },
        tooltipValueGetter: tooltip,
        filterParams: {
          valueGetter: (params) => {
            if (store.getState().mainFrame.prefs.heightUnits === 'ft')
              return Number(
                params.data.fresnel_clearence_m * METER_TO_FEET
              ).toFixed(1);
            else return Number(params.data.fresnel_clearence_m).toFixed(1);
          },
        },
        getQuickFilterText: (params) => {
          if (store.getState().mainFrame.prefs.heightUnits === 'ft')
            return Number(
              params.data.fresnel_clearence_m * METER_TO_FEET
            ).toFixed(1);
          else return Number(params.data.fresnel_clearence_m).toFixed(1);
        },
      },
      {
        field: 'link_loss',
        headerName: 'Link Loss (dB)',
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'eirp',
        headerName: 'SM EIRP (dBm)',
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'fixedRenderer',
        cellRendererParams: {
          precision: 1,
        },
      },
      {
        field: 'mac_address',
        headerName: 'SM MAC Address',
        minWidth: 100,
        tooltipValueGetter: tooltip,
        cellRenderer: 'macRenderer',
      },
      {
        field: 'antenna',
        headerName: 'SM Antenna',
        minWidth: 250,
        cellClass: ['ag-grid-cell-truncate-text'],
        tooltipValueGetter: tooltip,
      },
      {
        field: 'latitude',
        headerName: 'SM Latitude',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'longitude',
        headerName: 'SM Longitude',
        minWidth: 120,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'sm_clutter_type',
        headerName: 'SM Clutter Type',
        minWidth: 180,
        tooltipValueGetter: tooltip,
        valueGetter: clutterTypeGetter,
      },
      {
        field: 'ap_clutter_type',
        headerName: 'AP Clutter Type',
        minWidth: 180,
        tooltipValueGetter: tooltip,
        valueGetter: clutterTypeGetter,
      },
      {
        field: 'sm_sector',
        headerName: 'SM Sector',
        minWidth: 130,
        tooltipValueGetter: tooltip,
      },
      {
        field: 'mu_mimo_grouping_is_ok',
        headerName: 'MU-MIMO Grouping OK ?',
        minWidth: 130,
        cellRenderer: 'yesNoNaRenderer',
      },
    ]
  );
  return cols;
};
