import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, InputProps, Label, Popup } from 'semantic-ui-react';
import { WARNING_COLOR } from 'src/app.constants';
import messages from 'src/messages';
import { RootStateOrAny } from 'src/store';
import { debounce, getUnitsLabels, round } from 'src/utils/useful_functions';

export type ValueType = 'integer' | 'decimal';

export type NumberControlType = {
  intl: any;
  precision: number;
  controlValue: any;
  scaleFactor: number;
  valueType: ValueType;
  helpText: string | null;
  refresh: boolean;
} & InputProps &
  WrappedComponentProps;

const isWithinRange = (value, min, max) => {
  value = parseFloat(value);
  let valid = false;
  if (value >= min && value <= max) {
    valid = true;
  }
  return valid;
};

const getParsedValue = (value: string, valueType: ValueType): number => {
  return valueType === 'integer' ? parseInt(value, 10) : parseFloat(value);
};

export const getValueWithPrecisionAsString = (
  value: number,
  valueType: ValueType,
  precision: number,
  valueStr: string = '',
  fixedPrecision: boolean = false
): string => {
  if (valueType === 'integer') {
    return value.toString();
  }
  const factor = 10 ** precision;
  let decimalValueStr = (round(value * factor) / factor).toString();
  if (fixedPrecision) {
    decimalValueStr = (round(value * factor) / factor).toFixed(precision);
  }
  const valueLen = value.toString().length;
  // when the numeric value (value) and stringified value (valuestr)
  // lengths are mismatching then it is ending with zero or decimal(.)
  // we need to append them
  if (
    (!fixedPrecision && valueStr.toString().endsWith('0')) ||
    valueStr.toString().endsWith('.')
  ) {
    const missingChars = valueStr.toString().slice(valueLen);
    return `${decimalValueStr}${missingChars}`;
  } else {
    return decimalValueStr;
  }
};

const isValidValue = (value: number, valueType: ValueType) =>
  (valueType === 'integer' && Number.isSafeInteger(value)) ||
  (valueType === 'decimal' && Number.isFinite(value));

export const validate = (value, min, max, valueType) => {
  const newValue = getParsedValue(value, valueType);
  return isWithinRange(newValue, min, max) && isValidValue(newValue, valueType);
};

function NumberControlSemantic(props: NumberControlType) {
  const {
    label = '',
    name,
    values = null,
    panels = [],
    value,
    attrName,
    scaleFactor = 1,
    onChange,
    precision = 1,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    units = null,
    errMsg = null,
    info = null,
    disabled = false,
    step = 'any', // Because of the firefox input type number issue we changed step from 1 to 'any'
    valueType = 'integer',
    helpContent = null,
    refresh,
    warning,
    callback,
    width = 5,
    reCalculate = false,
    className,
    modified,
    defaultValue = null,
    intl,
    /*/ if true, user cant enter any invalid,
     in case of false user enters invalid value but the control turns error (red)
     defaultValue is mandatory in strict mode, we are using it best server fields
     like target fade margin, antenna heights
     */
    strictMode = false,
    ...rest
  } = props;

  const { formatMessage } = intl;
  const [displayValue, setDisplayValue] = useState(value);
  const { permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  const [error, setError] = useState(null);
  const [helpText, setHelpText] = useState<string>(helpContent);
  const isFirstRunDone = useRef(false);
  const changeHandler = (
    e,
    data,
    min,
    max,
    valueType,
    scaleFactor,
    values,
    panels,
    units
  ) => {
    if (onChange) {
      if (
        data['value'] !== (parseFloat(value) * scaleFactor).toFixed(precision)
      ) {
        data['unScaledVal'] = parseFloat(data['value'] || '0') / scaleFactor;
      } else {
        data['unScaledVal'] = value;
      }
      const isValid = validate(data['value'], min, max, valueType);
      const error = isValid ? false : errMsg != null ? errMsg : true;
      setError(error);
      data['error'] = error;
      data['values'] = values;
      data['panels'] = panels;
      data['units'] = units;
      onChange(e, data);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const validateAndSetError = (
    fieldValue,
    min,
    max,
    valueType,
    scaleFactor,
    panels
  ) => {
    const isValid = validate(fieldValue, min, max, valueType);
    const error = isValid ? false : errMsg != null ? errMsg : true;
    setError(error);
    if (callback) {
      callback({
        ...props,
        unScaledVal: fieldValue / scaleFactor,
        error: error,
        value: fieldValue,
        panels: panels,
        values,
      });
    }
  };

  useEffect(() => {
    if (isFirstRunDone.current) {
      let fieldValue = value;
      if (attrName === 'offset') {
        fieldValue = getParsedValue(value, valueType) * scaleFactor;
        fieldValue = getValueWithPrecisionAsString(
          fieldValue,
          valueType,
          precision,
          value,
          true
        );
      }
      setDisplayValue(fieldValue);
      validateAndSetError(fieldValue, min, max, valueType, scaleFactor, panels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    validateAndSetError(value, min, max, valueType, scaleFactor, panels);
    if (
      helpContent == null &&
      min !== Number.MIN_SAFE_INTEGER &&
      max !== Number.MAX_SAFE_INTEGER
    ) {
      let minValue = min,
        maxValue = max;
      if (valueType === 'integer') {
        minValue = parseInt(min);
        maxValue = parseInt(max);
      }
      if (valueType === 'decimal') {
        minValue = parseFloat(min).toFixed(precision);
        maxValue = parseFloat(max).toFixed(precision);
      }
      setHelpText(`Range ${minValue}${units} to ${maxValue}${units}`);
    }
    if (helpContent) {
      setHelpText(helpContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, helpContent]);

  useEffect(() => {
    let fieldValue = value;
    fieldValue = getParsedValue(value, valueType) * scaleFactor;
    fieldValue = getValueWithPrecisionAsString(
      fieldValue,
      valueType,
      precision,
      value,
      true
    );
    validateAndSetError(fieldValue, min, max, valueType, scaleFactor, panels);
    setDisplayValue(fieldValue);
    isFirstRunDone.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleFactor, precision]);

  useEffect(() => {
    if (refresh) {
      let fieldValue = value;
      fieldValue = getParsedValue(value, valueType) * scaleFactor;
      fieldValue = getValueWithPrecisionAsString(
        fieldValue,
        valueType,
        precision,
        value,
        true
      );
      validateAndSetError(fieldValue, min, max, valueType, scaleFactor, panels);
      setDisplayValue(fieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  let customClass = disabled || !permissionWrite ? 'no-border' : '';
  if (reCalculate) {
    customClass = `${customClass} recalculate `;
  } else {
    customClass = `${customClass} `;
  }
  const numberControl = (
    <Form.Field
      disabled={disabled || !permissionWrite}
      className={`${className} ${customClass}`}
      title={helpText != null ? helpText : ''}
    >
      <label>{label}</label>
      <Form.Group>
        {disabled || !permissionWrite ? (
          <Form.Input
            className={customClass}
            disabled={true}
            width={width}
            name={name != null ? name : attrName}
            error={error}
            value={displayValue}
          ></Form.Input>
        ) : (
          <Form.Input
            className={customClass}
            width={width}
            name={name != null ? name : attrName}
            type="number"
            min={min}
            max={max}
            step={step}
            onClick={(e, data) => {
              e.target.select();
            }}
            onKeyPress={(e, data) => {
              const allowedKeys = [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '0',
                '-',
                '.',
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e, data) => {
              let changedValue = data['value'];
              const newValue = getParsedValue(changedValue, valueType);
              if (strictMode) {
                const valid = validate(newValue, min, max, valueType);
                if (valid) {
                  const parsedValue = getValueWithPrecisionAsString(
                    newValue,
                    valueType,
                    precision,
                    changedValue
                  );
                  setDisplayValue(parsedValue);
                  data['value'] = parsedValue;
                } else {
                  setDisplayValue(defaultValue);
                  data['value'] = defaultValue;
                }
                debounceChangeHandler(
                  e,
                  data,
                  min,
                  max,
                  valueType,
                  scaleFactor,
                  values,
                  panels,
                  units
                );
                console.log('updated value', data.value);
              } else {
                if (changedValue === '') {
                  setDisplayValue(changedValue);
                  validateAndSetError(
                    changedValue,
                    min,
                    max,
                    valueType,
                    scaleFactor,
                    panels
                  );
                } else if (isValidValue(newValue, valueType)) {
                  const parsedValue = getValueWithPrecisionAsString(
                    newValue,
                    valueType,
                    precision,
                    changedValue
                  );
                  setDisplayValue(parsedValue);
                  data['value'] = parsedValue;
                }
                if (changedValue != '')
                  debounceChangeHandler(
                    e,
                    data,
                    min,
                    max,
                    valueType,
                    scaleFactor,
                    values,
                    panels,
                    units
                  );
              }
            }}
            //{...rest}
            error={error}
            value={displayValue}
          ></Form.Input>
        )}
        {units != null && (
          // Need to move the units part to length or height control
          <Label>{getUnitsLabels(units)}</Label>
        )}
        {info != null && info !== '' && (
          <Label
            basic
            style={
              typeof warning === 'boolean' && warning
                ? { background: `${WARNING_COLOR}` }
                : null
            }
          >
            {info}
          </Label>
        )}
      </Form.Group>
      {typeof warning !== 'boolean' && warning != null && warning !== '' && (
        <Label pointing prompt={!modified} basic={modified} color="blue">
          {warning}
        </Label>
      )}
    </Form.Field>
  );
  if (reCalculate) {
    return (
      <Popup
        content={formatMessage(messages.saveChanges)}
        trigger={numberControl}
      />
    );
  } else {
    return numberControl;
  }
}
export default injectIntl(React.memo(NumberControlSemantic));
/* export default injectIntl(
  React.memo(NumberControlSemantic, function (prevsProps, currentProps) {
    console.log('props', prevsProps, currentProps);

    return false;
  })
); */
