import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonProps, Grid, Modal } from 'semantic-ui-react';
import { PERCENT, THROUGHPUT } from '../../../../app.constants';
import { adjustPrecision } from '../../../../utils/useful_functions';
import additionalMessages from '../../../../messages';
import FloodTestGrouping from './FloodTestGrouping';
import FloodTestSummary from './FloodTestSummary';
import { ObjectLiteral } from 'src/utils/types/commonTypes';

export const floodTestTableLayout = (
  attrs: string[],
  rawData: ObjectLiteral[]
): string[][] => {
  let rows: string[][] = [];
  if (rawData !== null && rawData.length) {
    rows = rawData.map((obj: ObjectLiteral) => {
      return attrs.map((attr) => obj[attr]);
    });
  }
  return rows;
};

export type FloodTestReportType = {
  report: any;
  closeHandler: (event: any, data: ButtonProps) => void;
} & WrappedComponentProps;

function FloodTestReport({ report, intl, closeHandler }: FloodTestReportType) {
  const { formatMessage } = intl;
  const open = useState(true)[0];

  let [grouping, summary] = [report.grouping, report.summary];
  const dlGrouping = grouping.downlink.data.map((obj) => {
    obj.distribution = adjustPrecision(obj.distribution, PERCENT);
    return obj;
  });
  const dlMaxMuxGain = grouping.downlink.max_mux_gain;
  const dlSummary = summary.downlink.data.map((obj) => {
    obj.percent = adjustPrecision(obj.percent, PERCENT);
    obj.throughput = adjustPrecision(obj.throughput, THROUGHPUT);
    return obj;
  });
  const dlTotal = summary.downlink.total;

  let ulGrouping = null;
  let ulMaxMuxGain = null;
  let ulSummary = null;
  let ulTotal = null;
  if (grouping.uplink) {
    ulGrouping = grouping.uplink.data.map((obj) => {
      obj.distribution = adjustPrecision(obj.distribution, PERCENT);
      return obj;
    });
    ulMaxMuxGain = grouping.uplink.max_mux_gain;
    ulSummary = summary.uplink.data.map((obj) => {
      obj.percent = adjustPrecision(obj.percent, PERCENT);
      obj.throughput = adjustPrecision(obj.throughput, THROUGHPUT);
      return obj;
    });
    ulTotal = summary.uplink.total;
  }

  return (
    <Modal open={open} closeOnEscape closeOnDimmerClick>
      <Modal.Header className="floodtest-modalheader">
        <Grid>
          <Grid.Row>
            <Grid.Column width="10" as="h3">
              {formatMessage(additionalMessages.floodTestTitle)}
            </Grid.Column>
            <Grid.Column width="5" as="h6">
              {formatMessage(additionalMessages.floodTestLowPriorityVC)}
            </Grid.Column>
            <Grid.Column width="1" floated="right">
              <Button circular floated="right" icon="close" className="mr-0" onClick={closeHandler} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <FloodTestGrouping
                tableLayout={floodTestTableLayout}
                downLink={dlGrouping}
                downLinkMaxMuxGain={dlMaxMuxGain}
                upLink={ulGrouping}
                upLinkMaxMuxGain={ulMaxMuxGain}
              ></FloodTestGrouping>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FloodTestSummary
                tableLayout={floodTestTableLayout}
                downLink={dlSummary}
                upLink={ulSummary}
                upLinkTotal={ulTotal}
                downLinkTotal={dlTotal}
              ></FloodTestSummary>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={closeHandler}>
          {formatMessage(additionalMessages.close)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default injectIntl(FloodTestReport);
