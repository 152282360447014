import React, { useEffect, useRef, useState } from 'react';
import {
  redundant_powerChoice,
  switch_typeChoice,
  RJ45PortChoice,
  fiber_portsChoice,
  CountryChoice,
  PowerChoice,
  power_cordChoice,
  sync_inputChoice,
  cnpulseChoice,
} from 'src/model/EquipmentSelection';

import CreateTransceivers from 'src/model/Transceivers';
import EquipmentPanel from './EquipmentPanel';
import TransceiversPanel from './TransceiversPanel';
import { postWithAuth } from 'src/api';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';

const equipmentLevels = {
  redundant_power: new redundant_powerChoice(
    'redundant_power',
    'Redundant Power',
    'equipment'
  ),
  switch_type: new switch_typeChoice('switch_type', 'Switch Type', 'equipment'),
  ports: new RJ45PortChoice('ports', 'RJ45 Ports', 'equipment'),
  fiber_ports: new fiber_portsChoice('fiber_ports', 'Fiber Ports', 'equipment'),
  country: new CountryChoice('country', 'Country', 'equipment'),
};
const powerLevels = {
  power: new PowerChoice('power', 'Power', 'equipment'),
  power_cord: new power_cordChoice('power_cord', 'Power Cord', 'equipment'),
  sync_input: new sync_inputChoice('sync_input', 'Sync Input', 'equipment'),
  cnpulse: new cnpulseChoice('cnpulse', 'cnPulse', 'equipment'),
};
const allLevels = { ...equipmentLevels, ...powerLevels };

function TowerSwitchEquipment({
  towerSwitch,
  siteId,
  selectedSiteIds,
  setTowerSwitch,
  listViewShowTowerSwitch,
  tableViewShowTowerSwitch,
}) {
  const towerSwitchEquSel = useRef({
    equipment: {
      redundant_power: false,
      switch_type: '',
      ports: '',
      fiber_ports: '',
      country: '',
      power: '',
      power_cord: '',
      sync_input: '',
      cnpulse: '',
    },
    transceivers: {
      copper_1gbps: 0,
      copper_10gbps: 0,
      fiber_long_1gbps: 0,
      fiber_long_10gbps: 0,
      fiber_short_1gbps: 0,
      fiber_short_10gbps: 0,
    },
  });

  if (towerSwitch)
    towerSwitchEquSel.current = {
      equipment: towerSwitch.equipment,
      transceivers: towerSwitch.transceivers,
    };

  const transceivers = useRef(
    new CreateTransceivers(
      ['Copper', 'Fiber - Long', 'Fiber - Short'],
      towerSwitchEquSel.current.transceivers
    )
  );

  const [fieldModified, setFieldModified] = useState(null);
  const [isOnLoad, setIsOnLoad] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { projectId } = useSelector((state: RootStateOrAny) => state.mainFrame);

  const postData = (method = 'POST') => {
    postWithAuth(
      `project/${projectId}/site/${siteId}/tower_switch`,
      {
        ...towerSwitchEquSel.current,
        selectedSiteIds: selectedSiteIds,
      },
      method
    )
      .then((res) => {
        setTowerSwitch(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (fieldModified) postData('PUT');
  }, [fieldModified]);

  useEffect(() => {
    if (
      refresh &&
      (listViewShowTowerSwitch?.state === 'add' || tableViewShowTowerSwitch)
    )
      postData();
  }, [refresh]);

  useEffect(() => {
    setIsOnLoad(false);
    transceivers.current.setTotal1GbpsCount();
    transceivers.current.setTotal10GbpsCount();
    transceivers.current.setTotalCount();
    transceivers.current.warning(
      towerSwitchEquSel.current.equipment?.fiber_ports
    );
    setRefresh(true);
  }, []);

  const changeHandler = (e, { name, value }) => {
    const level = allLevels[name];
    const newValue = level.fromString(
      towerSwitchEquSel.current.equipment,
      value
    );
    towerSwitchEquSel.current.equipment[name] = newValue;
    setFieldModified({ name: name, value: newValue });
    if (name === 'fiber_ports') transceivers.current.warning(newValue);
  };

  const transceiverOnKeyPressHandler = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const transceiverChangeHandler = (event, transceiver) => {
    if (event.target.name.includes('1gbps')) {
      transceiver.qty1Gbps = Math.min(Number(event.target.value), 12);
      transceivers.current.setTotal1GbpsCount();
      towerSwitchEquSel.current.transceivers[event.target.name] =
        transceiver.qty1Gbps;
    }
    if (event.target.name.includes('10gbps')) {
      transceiver.qty10Gbps = Math.min(Number(event.target.value), 4);
      transceivers.current.setTotal10GbpsCount();
      towerSwitchEquSel.current.transceivers[event.target.name] =
        transceiver.qty10Gbps;
    }
    transceivers.current.setTotalCount();
    transceivers.current.warning(
      towerSwitchEquSel.current.equipment.fiber_ports
    );
    setFieldModified({ name: event.target.name, value: event.target.value });
  };

  return (
    <div style={{ display: 'flex' }}>
      <EquipmentPanel
        fieldModified={fieldModified}
        isOnLoad={isOnLoad}
        equipmentSelection={Object.values(equipmentLevels)}
        powerSelection={Object.values(powerLevels)}
        equipment={towerSwitchEquSel.current.equipment}
        changeHandler={changeHandler}
        isTSEdit={towerSwitch ? true : false}
      />
      <TransceiversPanel
        keyPressHandler={transceiverOnKeyPressHandler}
        changeHandler={transceiverChangeHandler}
        transceivers={transceivers.current}
      />
    </div>
  );
}

export default TowerSwitchEquipment;
