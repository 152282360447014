import { createSlice } from '@reduxjs/toolkit';

export const blankProfile = {
  loc_lat: 0,
  loc_lng: 0,
  rem_lat: 0,
  rem_lng: 0,
  ranges: [],
  heights: [],
  clutter_types: [],
  obstructions: [],
  use_clutter: true,
  is_lidar: false,
  errMsg: null,
  loading: true,
};

const initialState = {
  startIndex: null,
  endIndex: null,
  target: null,
  ...blankProfile,
};

const setData = (state, action) => {
  state.loc_lat = action.payload.loc_lat;
  state.loc_lng = action.payload.loc_lng;
  state.rem_lat = action.payload.rem_lat;
  state.rem_lng = action.payload.rem_lng;
  state.ranges = action.payload.ranges;
  state.heights = action.payload.heights;
  state.clutter_types = action.payload.clutter_types;
  state.obstructions = action.payload.obstructions;
  state.use_clutter = action.payload.use_clutter;
  state.is_lidar = action.payload.is_lidar;
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      setData(state, action);
    },
    updatePoints: (state, action) => {
      setData(state, action);
    },
    addPoint: (state, action) => {
      setData(state, action);
    },
    deletePoints: (state, action) => {
      setData(state, action);
      state.startIndex = null;
      state.endIndex = null;
      state.target = null;
    },
    updateSelection: (state, action) => {
      state.startIndex = action.payload.startIndex;
      state.endIndex = action.payload.endIndex;
      state.target = action.payload.target;
    },
  },
});

export const {
  setProfile,
  updatePoints,
  addPoint,
  deletePoints,
  updateSelection,
} = profile.actions;
export default profile.reducer;
