import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Message, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { RootStateOrAny } from '../../store';
import { FCCLicenseCoordinationPopup } from './FCCLicenseCoordinationPopup';
import { useQuery } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';
import { downloadReport } from './reportHelpers';

function availableReports(data) {
  const initialStatuses = {
    proposal: 'new',
    fcc: 'new',
    bom: 'new',
  };

  return data.reduce((statuses, report) => {
    let statusKey;

    switch (report.report_kind) {
      case 'Project Proposal Report':
        statusKey = 'proposal';
        break;
      case 'FCC Coordination Report':
        statusKey = 'fcc';
        break;
      case 'Project BOM':
        statusKey = 'bom';
        break;
      default:
        return statuses;
    }

    if (report.status === 'ready') {
      statuses[statusKey] = report.id;
    } else if (report.status === 'in_progress') {
      statuses[statusKey] = 'in_progress';
    }

    return statuses;
  }, initialStatuses);
}

const ReportMenu = () => {
  const { formatMessage } = useIntl();
  const { projectId, projectName, permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );

  // Query for available reports
  const { data: reportStatuses, refetch: refetchAvailable } = useQuery({
    queryKey: ['ReportMenuAvailable', projectId],
    queryFn: async () => {
      return await getWithAuth(`project/${projectId}/reports/available`);
    },
    initialData: [],
    select: availableReports,
    retry: false,
    enabled: false, // Not triggered automatically
  });

  const [openFccModal, setOpenFccModal] = useState(false);
  const { data: enabedReports, refetch: refetchEnabled } = useQuery({
    queryKey: ['ReportMenuEnabled', projectId],
    queryFn: async () => {
      return await getWithAuth(`project/${projectId}/reports/enabled`);
    },
    retry: false,
  });

  // const fccLinksAvailable = fccLinksData?.length > 0;

  const downloadProjectProposalReport = async () => {
    getWithAuth(`project/${projectId}/reports/project_proposal_report`)
      .then((resp) => {
        toast(<Message>Proposal report generation started</Message>);
      })
      .catch((err) => {
        toast(<Message>Proposal report generation failed</Message>);
        console.error(err);
      });
  };

  const downloadProjectBomSpreadSheet = async () => {
    getWithAuth(`project/${projectId}/reports/project_bom_spreadsheet`)
      .then((resp) => {
        toast(<Message>BOM generation started</Message>);
      })
      .catch((err) => {
        toast(<Message>BOM generation failed</Message>);
        console.error(err);
      });
  };

  return (
    <>
      <Dropdown
        title=""
        text={formatMessage({
          id: 'reports.report',
          defaultMessage: 'Reports',
        })}
        floating
        labeled
        className="mr-2"
        icon={{ name: '' }}
        onOpen={() => {
          refetchAvailable(); // Fetch available reports
          refetchEnabled();
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon={{
              name:
                reportStatuses.proposal == 'in_progress'
                  ? 'spinner'
                  : reportStatuses.proposal == 'new'
                  ? 'file pdf outline'
                  : 'download',
              loading: reportStatuses.proposal == 'in_progress',
            }}
            text={
              reportStatuses.proposal == 'new'
                ? 'Project Proposal Report'
                : reportStatuses.proposal == 'in_progress'
                ? 'Processing Project Proposal Report'
                : 'Download Project Proposal Report'
            }
            className="projproposal-reports-menu"
            onClick={() => {
              if (reportStatuses.proposal == 'new') {
                downloadProjectProposalReport();
              } else if (reportStatuses.proposal == 'in_progress') {
                // Do nothing for report in process
                toast(<Message>Report Generation in Progress</Message>);
              } else {
                downloadReport(
                  projectId,
                  reportStatuses.proposal,
                  `${projectName}_Proposal_Report`,
                  'pdf'
                );
              }
            }}
          ></Dropdown.Item>
          <Dropdown.Item
            // Showing only icon for in progress since when links need to be selected each time.
            icon={{
              name:
                reportStatuses.fcc == 'in_progress'
                  ? 'spinner'
                  : 'file excel outline',
              loading: reportStatuses.fcc == 'in_progress',
            }}
            text={
              reportStatuses.fcc == 'in_progress'
                ? 'Processing FCC License Coordination'
                : 'FCC License Coordination'
            }
            onClick={() => {
              if (reportStatuses.fcc != 'in_progress') {
                setOpenFccModal(true);
              }
            }}
            disabled={!enabedReports?.comsearch}
          ></Dropdown.Item>

          <Dropdown.Item
            // Showing only icon for in progress since when links need to be selected each time.
            icon={{
              name:
                reportStatuses.bom == 'in_progress'
                  ? 'spinner'
                  : reportStatuses.bom == 'new'
                  ? 'file excel outline'
                  : 'download',
              loading: reportStatuses.bom == 'in_progress',
            }}
            text={
              reportStatuses.bom == 'new'
                ? 'Project BOM Spreadsheet'
                : reportStatuses.bom == 'in_progress'
                ? 'Processing Project BOM Spreadsheet'
                : 'Download Project BOM Spreadsheet'
            }
            onClick={() => {
              if (reportStatuses.bom == 'new') {
                downloadProjectBomSpreadSheet();
              } else if (reportStatuses.bom == 'in_progress') {
                // Do nothing for report in process
                toast(<Message>Report Generation in Progress</Message>);
              } else {
                downloadReport(
                  projectId,
                  reportStatuses.bom,
                  `${projectName}_BOM`,
                  'xlsx'
                );
              }
            }}
          ></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {enabedReports?.comsearch ? (
        <FCCLicenseCoordinationPopup
          openFccModal={openFccModal}
          setOpenFccModal={setOpenFccModal}
          // rowData={fccLinksData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ReportMenu;
