import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { store } from './store';
import { createRoot } from 'react-dom/client';
import { Provider, connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-less/semantic.less';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './css/index.css';
import { AppContainer } from './App';

if (window.runtime?.loggingKey) {
  const loggingKey = window.runtime?.loggingKey;
  const apiURL = window.runtime?.apiURL;
  const [url, hash] = loggingKey.split('/');
  const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node",
  ];
  Sentry.init({
    dsn: `https://${url}@o960733.ingest.sentry.io/${hash}`,
    integrations: [new BrowserTracing()],
    release: window.runtime.version,
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      if (error && error.message && ignoreErrors.indexOf(error.message)) {
        return null;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production

    // setting the tracesamplerate to 1 in production and 0 in other deployments
    tracesSampleRate: apiURL === 'https://api.lp.cambiumnetworks.com' ? 1.0 : 0,
  });
}
const App = connect((state) => ({
  location: state.location,
}))(AppContainer);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

registerServiceWorker();
