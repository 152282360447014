import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import additionalMessages from '../../messages';
import MapContainer from '../map/mapPanel';
import MapToolbar from '../map/mapToolbar';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import ViewshedModePanel from '../viewshed/ViewshedModePanel';
import { states } from '../map/leafletCanvas';
import SiteModePanel from '../map/SiteModePanel';
import PMPModePanel from '../map/PMPModePanel';
import PTPModePanel from '../map/PTPModePanel';
import MeshModePanel from '../map/MeshModePanel';
import AFCPanel from '../map/afc/AFCPanel';
import { getCanvas } from '../../utils/mapUtils';

const WorkbenchPanelContainer = (props) => {
  const { loadingProject, isFullWidth } = props;
  const { formatMessage } = props.intl;
  const location = useLocation();

  const [hasCentered, setHasCentered] = useState(false);
  const showDetail = location.pathname !== '/' && location.pathname !== '';
  const isAfc = location.pathname === '/afc';

  let wrapperStyle = null;
  if (showDetail) {
    if (isFullWidth && !isAfc) {
      wrapperStyle = { display: 'none' };
    } else {
      wrapperStyle = { flex: '1 1' };
    }
  }

  const { mapState } = useSelector((state) => state.mainFrame);
  const viewshedMode = mapState === states.MAP_VIEWSHED_MODE;
  const networkSiteMode = mapState === states.MAP_CREATE_NETWORK_SITE;
  const subscriberSiteMode = mapState === states.MAP_CREATE_SUBSCRIBER_SITE;
  const apMode = mapState === states.MAP_CREATE_ACCESS_POINT;
  const pmpLinkMode = mapState === states.MAP_CREATE_PMP_LINK;
  const ptpLinkMode = mapState === states.MAP_CREATE_PTP_LINK;
  const meshLinkMode = mapState === states.MAP_CREATE_MESH_LINK;
  const afcMode = mapState === states.MAP_AFC_MODE;

  useEffect(() => {
    if (!showDetail || !isFullWidth) {
      const canvas = getCanvas();
      canvas.map.invalidateSize();
      if (!hasCentered) {
        canvas.fit();
        setHasCentered(true);
      }
    }
  }, [showDetail, isFullWidth]);

  return (
    <>
      <div className="detailWrapper" style={wrapperStyle}>
        <Dimmer inverted active={loadingProject}>
          <Loader size="large" indeterminate>
            Loading...
          </Loader>
        </Dimmer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
          }}
        >
          <MapContainer />
          {afcMode ? <AFCPanel /> : null}
        </div>
        {viewshedMode ? <ViewshedModePanel /> : null}
        {networkSiteMode ? (
          <SiteModePanel
            isNetwork={true}
            header={formatMessage(additionalMessages.networkSiteMode)}
          />
        ) : null}
        {subscriberSiteMode ? (
          <SiteModePanel
            isNetwork={false}
            header={formatMessage(additionalMessages.subscriberSiteMode)}
          />
        ) : null}
        {apMode ? <PMPModePanel isAP={true} /> : null}
        {pmpLinkMode ? <PMPModePanel isAP={false} /> : null}
        {ptpLinkMode ? <PTPModePanel /> : null}
        {meshLinkMode ? <MeshModePanel /> : null}
        <MapToolbar />
      </div>
      <div style={{ height: '100%', width: isFullWidth ? '100%' : null }}>
        {props.children}
      </div>
    </>
  );
};

const WorkbenchPanel = injectIntl(
  connect((state, ownProps) => {
    const { expandSidebar, loadingProject } = state.mainFrame;
    return {
      ...ownProps,
      expandSidebar,
      loadingProject,
    };
  })(WorkbenchPanelContainer)
);

export default WorkbenchPanel;
