import React from 'react';
import { Button, Message } from 'semantic-ui-react';

function Download({ clickHandler, text = 'Download' }) {
  return (
    <Message positive>
      {text}&nbsp;&nbsp;&nbsp;
      <Button icon="cloud download" title="Download" onClick={clickHandler} />
    </Message>
  );
}

export default Download;
