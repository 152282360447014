import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, InputProps } from 'semantic-ui-react';
import SemanticField from './SemanticField';
import messages from '../../messages';

export type NumberControlType = {
  intl: any;
  precision: number;
  controlValue: any;
  scaleFactor: number;
} & InputProps &
  WrappedComponentProps;

function NumberControl({
  precision = 1,
  controlValue,
  name,
  min = -Infinity,
  max = Infinity,
  scaleFactor = 1,
  onChange,
  intl,
  ...rest
}: NumberControlType) {
  // eslint-disable-next-line
  const { values, setFieldValue } = useFormikContext();
  const { formatMessage } = intl;

  const isWithinRange = (value) => {
    value = parseFloat(value);
    let valid = false;
    if (value >= min && value <= max) {
      valid = true;
    }
    return valid;
  };

  useEffect(() => {
    const value = parseFloat(controlValue);
    let fieldValue = (value * scaleFactor).toFixed(precision);
    // Settimeout is hack for now.. it is bug in
    // formik https://github.com/formium/formik/issues/2266
    setTimeout(() => setFieldValue(name, fieldValue), 0);
    if (onChange) {
      onChange(null, {
        value: value.toString(),
        unScaledVal: value.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlValue, precision, scaleFactor, min, max]);

  return (
    <SemanticField
      component={Form.Input}
      type="number"
      step="any"
      name={name}
      showErrorsInline={false}
      onChange={(e, data) => {
        if (onChange) {
          if (
            data['value'] !==
            (parseFloat(controlValue) * scaleFactor).toFixed(precision)
          ) {
            data['unScaledVal'] = parseFloat(data['value']) / scaleFactor;
          } else {
            data['unScaledVal'] = controlValue;
          }
          onChange(e, data);
        }
      }}
      validate={(value: string) => {
        let error;
        if (!isWithinRange(value)) {
          error = formatMessage(messages.validFieldError, {
            fieldName: name,
          });
        }
        return error;
      }}
      {...rest}
    ></SemanticField>
  );
}

export default injectIntl(NumberControl);
