import React from 'react';
import { injectIntl } from 'react-intl';
import messages from 'src/messages';
import SiteModePanel from './SiteModePanel';

function PTPModePanel({ intl }) {
  const { formatMessage } = intl;
  return (
    <SiteModePanel
      isPTPMode={true}
      header={formatMessage(messages.ptpLinkMode)}
    />
  );
}

export default injectIntl(PTPModePanel);
