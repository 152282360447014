import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLinkKind } from './hooks';
import { Tab, Menu, Popup } from 'semantic-ui-react';
import PerformanceDetails, {
  Entities,
} from 'src/pages/performance/PerformanceDetails';
import { syncPtpTabs } from './ptp.reducer';
import { RootStateOrAny } from 'src/store';
import './PerformanceDetails.css';
import { useIntl } from 'react-intl';
import additionalMessages from 'src/messages';
import { isArray } from 'lodash';

export type PerformanceDetailsProps = {
  details: Array<any>;
  localEndName: string;
  remoteEndName: string;
  disabled: boolean;
  kind: 'ptp' | 'pmp';
  needsRefresh: boolean;
  perfChart?: Entities;
};

export function PerformanceDetailsTabs(props: PerformanceDetailsProps) {
  const { isComplex, summaryLabels, showAggregateDetails } = useLinkKind();
  const {
    details: detailsInfo,
    localEndName,
    remoteEndName,
    disabled,
    kind,
    needsRefresh,
  } = props;
  const { formatMessage } = useIntl();

  const details = isArray(detailsInfo) ? detailsInfo : [detailsInfo];

  const dispatch = useDispatch();
  let tabIndex = useSelector(
    (state: RootStateOrAny) => state.ptp.tabIndexes.performance
  );

  if (tabIndex >= summaryLabels.length) {
    tabIndex = 0;
  }

  if (!isComplex) {
    return (
      <PerformanceDetails
        perfChart={details[0].chart_data}
        common={details[0].common}
        local={details[0].local}
        remote={details[0].remote}
        localEndName={localEndName}
        remoteEndName={remoteEndName}
        kind={kind}
        disabled={disabled}
        needsRefresh={needsRefresh}
      />
    );
  }

  let panes = details.map((pathDetails, i) => {
    if (!showAggregateDetails && i === 0) {
      return null;
    }

    return {
      menuItem: summaryLabels[i],
      render: () => (
        <Tab.Pane key={i}>
          <PerformanceDetails
            perfChart={pathDetails.chart_data}
            common={pathDetails.common}
            local={pathDetails.local}
            remote={pathDetails.remote}
            localEndName={localEndName}
            remoteEndName={remoteEndName}
            kind={kind}
            disabled={disabled}
            needsRefresh={needsRefresh}
          />
        </Tab.Pane>
      ),
    };
  });

  if (panes.length < summaryLabels.length) {
    panes.push(...[...Array(summaryLabels.length - panes.length).keys()].map((i) => {
      return {
        menuItem: (
          <Menu.Item disabled>
            <Popup
              trigger={<p>{summaryLabels[panes.length + i]}</p>}
              content={`${formatMessage(additionalMessages.save)} the current settings to view this tab`}
            />
          </Menu.Item>
        ),
      };
    }));
  }

  let tabIdx;
  if (!showAggregateDetails && tabIndex === 0) {
    tabIdx = 1;
  } else {
    tabIdx = tabIndex;
  }

  return (
    <Tab
      panes={panes}
      defaultTabIndex={0}
      activeIndex={tabIdx}
      onTabChange={(_, data) => {
        dispatch(syncPtpTabs({ key: 'performance', value: data.activeIndex }));
      }}
    />
  );
}
