import { takeLatest, takeEvery, all, spawn, call } from 'redux-saga/effects';
import {
  getProjects,
  getProjectGeoJson,
  refreshToken,
  saveChanges,
  tokenRefreshed,
} from './api';
import { store } from './store';
import { crudRead as crudReadAction, crudSaveChanges } from './actions';
import {
  loadProject,
  tokenExpired,
  getProjects as getProjectsAction,
  tokenRefresh,
} from './pages/mainframe/mainframe.reducer';

/*
 * Watch for get projects request.
 *
 * Updates the list user's project list
 */
export function* watchGetProjects() {
  yield takeLatest(getProjectsAction, getProjects);
}

export function* watchLoadProject() {
  yield takeLatest(loadProject, getProjectGeoJson);
}

export function* watchToken() {
  yield takeLatest(tokenExpired, refreshToken);
}

export function* watchTokenRefresh() {
  yield takeLatest(tokenRefresh, tokenRefreshed);
}

function* crudStore(action) {
  if (store.getState().mainFrame.permissionWrite) {
    yield call(saveChanges, action);
  } else {
    console.warn('User does not have write permission');
  }
}

function* crudRead(action) {
  const func = action.payload.api;
  yield call(func, action);
}

export function* crudWatcher() {
  yield takeEvery(crudSaveChanges, crudStore);
  yield takeEvery(crudReadAction, crudRead);
}

/*
// Token refresh pattern from https://github.com/redux-saga/redux-saga/issues/14
const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* authorize(refresh) {
    try {
        const token = yield call(auth.authorize, refresh);
        yield call(auth.storeToken, token);
        yield put(authorizeSuccess(token));
        return token;
    } catch (e) {
        yield call(auth.storeToken, null);
        yield put(authorizeFailure(e));
        return null;
    }
}

function* authorizeLoop(token) {
    while (true) {
        const refresh = token !== null;
        token = yield call(authorize, refresh);
        if (token === null)
            return;

        yield call(delay, token.expires_in);
    }
}

function* authentication() {
    const storedToken = yield call(auth.getStoredToken);

    while (true) {
        if (!storedToken)
            yield take(SIGN_IN);

        const { signOutAction } = yield race({
            signOutAction: take(SIGN_OUT),
            authLoop: call(authorizeLoop, storedToken)
        });

        if (signOutAction) {
            yield call(auth.storeToken, null);
        }
    }
}

*/

export default function* rootSaga() {
  const sagas = [
    watchGetProjects,
    watchLoadProject,
    watchToken,
    crudWatcher,
    watchTokenRefresh,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
