import React from 'react';
import { get } from 'lodash';
import { CUSTOM_ANTENNA_OTHER } from 'src/app.constants';

export const tiltHelpText = (data) => {
  const { electrical_tilt, max_tilt, min_tilt } = data;
  const mechTilt = `Mechanical Tilt Range ${min_tilt}° to ${max_tilt}°`;
  const elecTilt = electrical_tilt
    ? `\nElectrical Tilt ${electrical_tilt}°`
    : '';
  const negative = `\nNegative values for downtilt.`;
  return `${mechTilt}${elecTilt}${negative}`;
};

export const antennaLabel = (antenna) => {
  const nominalGain = calcGain(antenna);
  const polarity = get(antenna, 'data.polarization.0');
  return `(${polarity}) ${get(
    antenna,
    'data.display_name',
    ''
  )} (${nominalGain.toFixed(1)} dBi)`;
};

export const calcGain = (antenna) => {
  const gains = get(antenna, 'gains', []);
  return doGainCalc(antenna.center_frequency, gains);
};

export const getIndex = (gains, targetFreq) => {
  let index = 0;
  for (let idx in gains) {
    const freq = gains[idx][0];
    if (freq >= Math.floor(targetFreq)) {
      return index;
    }
    index += 1;
  }
  return gains.length - 1;
};

const doGainCalc = (nominalFreq, initialGains) => {
  if (!initialGains || !nominalFreq) {
    return 0;
  }
  let gains = [...initialGains];
  gains.sort((a, b) => a[0] - b[0]);

  const minIdx = getIndex(gains, nominalFreq);
  // If the frequency is above the known gains or
  // there is only one value then use the max freq gain
  if (minIdx === gains.length - 1 || gains.length === 1) {
    return gains[minIdx][1];
  }
  const [freqMin, gainMin] = gains[minIdx];
  if (freqMin === nominalFreq) {
    return gainMin;
  }
  const [freqMax, gainMax] = gains[minIdx + 1];
  if (freqMax === nominalFreq) {
    return gainMax;
  }

  const nominalGain =
    gainMin +
    ((nominalFreq - freqMin) / (freqMax - freqMin)) * (gainMax - gainMin);
  return nominalGain;
};

const antennaSort = (antennas) => {
  // We want any Cambium antennas to come out first
  const other = antennas.filter((a) => a.id === CUSTOM_ANTENNA_OTHER.id);
  const cambium = antennas.filter(
    (a) =>
      a.data.manufacturer === 'Cambium Networks' &&
      a.id != CUSTOM_ANTENNA_OTHER.id
  );
  const nonCambium = antennas.filter(
    (a) =>
      a.data.manufacturer !== 'Cambium Networks' &&
      a.id != CUSTOM_ANTENNA_OTHER.id
  );
  // TODO: use a frequency to calculate the gain
  cambium.sort((a, b) => calcGain(a) - calcGain(b));
  nonCambium.sort((a, b) => calcGain(a) - calcGain(b));

  return [...other, ...cambium, ...nonCambium];
};

export function filterAndSortAntennas(antennas) {
  // Sort the antennas to match the desktop order
  // Current antennas should come before obsolete antennas.
  const sorted = antennaSort(antennas);
  const current = sorted.filter((a) => !a.data.is_obsolete);
  const obsolete = sorted.filter((a) => a.data.is_obsolete);
  return [...current, ...obsolete];
}

export const makeAntennaChoices = (antennas) => {
  const sortedChoices = filterAndSortAntennas(antennas);
  return sortedChoices.map((antenna) => {
    // TODO: use a frequency to calculate the gain
    const label = antennaLabel(antenna);
    const content = antenna.data.is_obsolete ? (
      <i
        style={{
          color: 'grey',
        }}
      >
        {label}
      </i>
    ) : (
      <>{label}</>
    );

    return {
      key: antenna.id,
      value: antenna.id,
      text: label,
      content: content,
    };
  });
};
