export type EquipmentSelection = {
  redundant_power: string;
  switch_type: string;
  ports: number;
  fiber_ports: string;
  country: string;
  power: string;
  power_cord: string;
  sync_input: string;
  cnpulse: string;
};

type redundant_power = [false, true];
type switch_type = ['TX1000', 'TX2000'] | ['TX2000'];
type RJ45Ports = [8, 16] | [8] | [16];
type fiber_ports = ['4 SFP+', '4 SFP+ plus 8 SFP'] | ['4 SFP+'];
type Country = ['Other', 'United States'];
type Power =
  | ['Internal - AC', 'Internal - DC']
  | [
      'AC - 600W',
      'AC - 930W',
      'AC - 1200W',
      'DC - 600W',
      'DC - 930W',
      'DC - 1200W'
    ];
type power_cord =
  | [
      'None',
      'Argentina',
      'Australia',
      'Brazil',
      'China',
      'Europe',
      'India',
      'United Kingdom',
      'United States'
    ]
  | ['United States']
  | [];
type sync_input = ['None', 'External', 'Bullet 720'] | ['No Sync'];
type cnpulse = ['No', 'Yes'] | [];
type EquipmentSelectionChoices =
  | redundant_power
  | switch_type
  | RJ45Ports
  | fiber_ports
  | Country
  | Power
  | power_cord
  | sync_input
  | cnpulse;

interface LevelSerialisable<TChoice> {
  stringify(equipment: EquipmentSelection, value: TChoice): string;
  fromString(equipment: EquipmentSelection, value: string): TChoice;
}

abstract class StringSerialisable implements LevelSerialisable<string> {
  stringify(equipment: EquipmentSelection, value: string): string {
    return value;
  }

  fromString(equipment: EquipmentSelection, value: string): string {
    return value;
  }
}

abstract class BooleanSerialisable implements LevelSerialisable<boolean> {
  stringify(equipment: EquipmentSelection, value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  fromString(equipment: EquipmentSelection, value: string): boolean {
    return value.toLowerCase() === 'yes';
  }
}

abstract class NumberSerialisable implements LevelSerialisable<number> {
  stringify(equipment: EquipmentSelection, value: number): string {
    return value.toString();
  }

  fromString(equipment: EquipmentSelection, value: string): number {
    return Number(value);
  }
}

export interface Level {
  attrname: string;
  displayName: string;
  panel: string;
  getChoices(equipment: EquipmentSelection): EquipmentSelectionChoices;
  getDefault(equipment: EquipmentSelection): string | boolean | number;
}

export class redundant_powerChoice
  extends BooleanSerialisable
  implements Level
{
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): redundant_power {
    return [false, true];
  }

  public getDefault(equipment: EquipmentSelection): boolean {
    return false;
  }
}

export class switch_typeChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): switch_type {
    if (equipment.redundant_power) return ['TX2000'];
    return ['TX1000', 'TX2000'];
  }

  public getDefault(equipment: EquipmentSelection): string {
    return 'TX2000';
  }
}

export class RJ45PortChoice extends NumberSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): RJ45Ports {
    if (equipment.switch_type === 'TX1000') return [8];
    if (equipment.redundant_power) return [16];
    return [8, 16];
  }

  public getDefault(equipment: EquipmentSelection): number {
    if (equipment?.switch_type === 'TX1000') return 8;
    return 16;
  }
}

export class fiber_portsChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): fiber_ports {
    if (
      equipment.switch_type === 'TX1000' ||
      equipment.redundant_power ||
      equipment.ports === 8
    )
      return ['4 SFP+'];
    return ['4 SFP+', '4 SFP+ plus 8 SFP'];
  }

  public getDefault(equipment: EquipmentSelection): string {
    return '4 SFP+';
  }
}

export class CountryChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): Country {
    return ['Other', 'United States'];
  }

  public getDefault(equipment: EquipmentSelection): string {
    return 'Other';
  }
}

export class PowerChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): Power {
    if (equipment.switch_type === 'TX1000')
      return ['Internal - AC', 'Internal - DC'];
    return [
      'AC - 600W',
      'AC - 930W',
      'AC - 1200W',
      'DC - 600W',
      'DC - 930W',
      'DC - 1200W',
    ];
  }

  public getDefault(equipment: EquipmentSelection): string {
    if (equipment.switch_type === 'TX1000') return 'Internal - AC';
    return 'DC - 600W';
  }
}

export class power_cordChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): power_cord {
    if (
      equipment.power.toString().startsWith('AC') ||
      equipment.power === 'Internal - AC'
    ) {
      if (equipment.country === 'Other')
        return [
          'None',
          'Argentina',
          'Australia',
          'Brazil',
          'China',
          'Europe',
          'India',
          'United Kingdom',
          'United States',
        ];
      return ['United States'];
    }
    return [];
  }

  public getDefault(equipment: EquipmentSelection): string {
    const choices = this.getChoices(equipment);
    if (choices) {
      return choices[0];
    }
    return '';
  }
}

export class sync_inputChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): sync_input {
    if (equipment.switch_type === 'TX1000') return ['No Sync'];
    return ['None', 'External', 'Bullet 720'];
  }
  public getDefault(equipment: EquipmentSelection): string {
    if (equipment.switch_type === 'TX1000') return 'No Sync';
    return 'External';
  }
}

export class cnpulseChoice extends StringSerialisable implements Level {
  public attrname: string;
  public displayName: string;
  public panel: string;

  constructor(attrname: string, displayName: string, panel: string) {
    super();
    this.attrname = attrname;
    this.displayName = displayName;
    this.panel = panel;
  }

  public getChoices(equipment: EquipmentSelection): cnpulse {
    if (equipment.switch_type === 'TX1000') return [];
    return ['No', 'Yes'];
  }

  public getDefault(equipment: EquipmentSelection): string {
    if (equipment.switch_type === 'TX1000') return '';
    return 'No';
  }
}
