import PTPEquipmentPanel from '../PTPEquipmentPanel';
import {
  PTPChoices,
  PTPFormPanel,
  PTPPanelConfigImpl,
  syncFormState,
  getEndFromChoices,
} from '../utils';
import {
  genericEndPerformanceSummary,
  genericLinkSummary,
  genericEndConfig,
  remoteProductNextValue,
} from './config-utils';
import additionalMessages from 'src/messages';
import { OperatingConditions } from 'src/components/OperatingConditions';

const equipment: PTPFormPanel<any> = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'equipment_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'band',
      getter: 'local.radios.0.equipment.band',
    },
    {
      attrName: 'product',
      getter: 'local.radios.0.equipment.product',
    },
    {
      attrName: 'remote_product',
      getter: 'remote.radios.0.equipment.product',
      nextValue: remoteProductNextValue,
    },
    {
      attrName: 'regulation',
      getter: 'local.radios.0.equipment.regulation',
    },
    {
      attrName: 'link_protection',
      getter: 'local.radios.0.equipment.link_protection',
    },
    {
      attrName: 'ptp_mode',
      getter: 'local.radios.0.equipment.ptp_mode',
    },
  ],
};

const config: PTPFormPanel<any> = {
  kind: 'config',
  title: 'Product Configuration',
  name: 'equipment_config_link_a',
  component: PTPEquipmentPanel,
  fields: [
    {
      attrName: 'bandwidth',
      getter: 'local.radios.0.equipment.bandwidth',
    },
    {
      attrName: 'dl_ul_ratio',
      getter: 'local.radios.0.equipment.dl_ul_ratio',
    },
    {
      attrName: 'frame_period',
      getter: 'local.radios.0.equipment.frame_period',
      units: 'ms',
    },
    {
      attrName: 'max_mod_mode',
      getter: 'local.radios.0.equipment.max_mod_mode',
    },
    {
      attrName: 'guard_interval',
      getter: 'local.radios.0.equipment.guard_interval',
    },
    {
      attrName: 'master',
      getter: 'local.radios.0.equipment.master',
    },
    {
      attrName: 'gb_ethernet_port',
      getter: 'local.radios.0.equipment.gb_ethernet_port',
    },
  ],
};

function getSummaryOperatingConditions(): PTPFormPanel<any> {
  return {
    name: 'summary_operating_conditions',
    title: additionalMessages.operatingConditions,
    show: ({ choices }: { choices: PTPChoices }) => {
      return getEndFromChoices(choices, "local")?.supports_frame_size ?? false;
    },
    component: OperatingConditions,
    fields: [
      {
        label: 'Frame Size',
        getter: 'summary.link.frame_size',
        choicesGetter: 'local.end.frame_size_choices',
        valueGetter: 'local.end.default_frame_size',
        units: 'Bytes',
        nextValue(currentValue: string, newChoices: PTPChoices) {
          const choices = newChoices.local.end.frame_size_choices;
          if (choices != null) {
            if (!choices.includes(currentValue)) {
              return newChoices.local.end.default_frame_size;
            }
          }
          return null;
        },
      },
    ],
  };
}

const PTPePMPPanelConfig: PTPPanelConfigImpl = {
  syncFormState,
  equipment: [equipment, config],
  performance: {
    summary: {
      link: [getSummaryOperatingConditions(), genericLinkSummary()],
      local: genericEndPerformanceSummary('local'),
      remote: genericEndPerformanceSummary('remote'),
    },
  },
  ends: {
    title: 'Configuration at Each End',
    local: genericEndConfig('local'),
    remote: genericEndConfig('remote'),
  },
};

export default PTPePMPPanelConfig;
