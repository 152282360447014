import { useQuery, useQueryClient } from '@tanstack/react-query';
import { injectIntl } from 'react-intl';
import { every, get, some } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Loader,
  Modal,
  Select,
  SemanticWIDTHS,
} from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import TxFrequencyEndList from 'src/pages/ptp/TxFrequencyEndList';
import { pyround } from 'src/utils/useful_functions';
import messages from 'src/messages';
import { RootStateOrAny } from 'src/store';

export function getCalculatedList(min, max, stepSize) {
  const rMin = pyround(min, 3);
  let result = [rMin];
  let calculatedValue = pyround(rMin + stepSize, 3);
  while (calculatedValue <= max) {
    result.push(calculatedValue);
    calculatedValue = pyround(calculatedValue + stepSize, 3);
  }
  return result;
}

export function buildFrequencyList(frequencies: any[], stepSize: number) {
  return frequencies
    .map(([low, high]) => getCalculatedList(low, high, stepSize))
    .flat(1);
}

async function fetchFrequencyLists(
  projectId,
  id,
  radios,
  componentCarrierQty,
  state
) {
  const postObj = {
    component_carrier_qty: componentCarrierQty,
    equipment: radios.map((r) => r.equipment),
    frequencies_mhz: state.frequencies,
    raster: state.raster,
  };
  const res = await postWithAuth(
    `project/${projectId}/access_point/${id}/frequency_list`,
    postObj
  );
  return res;
}

type FreqListProps = {
  index: number;
  label: string;
  bandwidth: string;
  freqList: number[];
  defaultValue?: number;
  setState: Function;
  loading: boolean;
  formatMessage: Function;
};

const FrequencySelectionList = (props: FreqListProps) => {
  const {
    setState,
    freqList,
    formatMessage,
    bandwidth,
    index,
    label,
    loading,
    defaultValue,
  } = props;
  const onSelect = (e) => {
    setState((prev) => {
      const freqs = prev.frequencies;
      freqs[index] = Number(e.target.value);
      return {
        ...prev,
        frequencies: freqs,
      };
    });
  };

  const onClear = () => {
    setState((prev) => {
      const freqs = prev.frequencies;
      freqs[index] = null;
      return {
        ...prev,
        frequencies: freqs,
      };
    });
  };

  return (
    <>
      <label>
        {formatMessage(messages.bandwidth)}: {bandwidth}
      </label>
      <TxFrequencyEndList
        label={label}
        list={freqList}
        disabled={loading}
        defaultValue={defaultValue}
        handleValuesSelection={onSelect}
        key={`${index}-${label}`}
      ></TxFrequencyEndList>

      <Button
        style={{ marginTop: '0.5em', marginLeft: '1.2em' }}
        disabled={defaultValue == null}
        onClick={onClear}
      >
        {formatMessage(messages.clearSelection)}
      </Button>
    </>
  );
};

function FrequencyModal(props) {
  const {
    showModal,
    closeModal,
    ap,
    setModified,
    intl: { formatMessage },
  } = props;
  const projectId = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { getValues, setValue } = useFormContext();
  const [carrierQty, componentCarrierQty, apRadios] = getValues([
    'radios.0.equipment.carrier_qty',
    'radio_qty',
    'radios',
  ]);

  const [state, setState] = useState({
    frequencies: apRadios
      .map((radio) => radio.frequency?.tx_frequency)
      .filter((freq, idx) => idx < componentCarrierQty),
    raster: ap.properties.raster,
  });
  const bandwidths = apRadios
    .map((radio) => radio.equipment.bandwidth)
    .filter((bw, idx) => idx < componentCarrierQty);
  const id = ap.id;
  const queryKey = [
    projectId,
    'nd',
    id,
    'frequencies',
    carrierQty,
    componentCarrierQty,
    bandwidths,
    state,
  ];
  const qc = useQueryClient();
  const choicesFetching = qc.isFetching([
    projectId,
    'access_point',
    id,
    'choices',
  ]);
  const antennaChoicesFetching = qc.isFetching([
    projectId,
    'access_point',
    id,
    'antenna_choices',
  ]);
  const {
    data: frequencyListData,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    enabled: !choicesFetching && !antennaChoicesFetching,
    keepPreviousData: true,
    queryFn: () =>
      fetchFrequencyLists(
        projectId,
        id,
        getValues('radios'),
        componentCarrierQty,
        state
      ),
  });
  if (isLoading && !isPreviousData) {
    return <Loader active></Loader>;
  }
  const { radios, raster, raster_choices, display_units } = frequencyListData;

  const rasterChoices = raster_choices.map((r) => {
    return { key: r, text: `${r} ${display_units}`, value: r };
  });

  // 1 column per radio
  const colWidth = (16 / componentCarrierQty) as SemanticWIDTHS;

  return (
    <Modal open={showModal} size="mini">
      <Modal.Header>
        {isLoading || isPreviousData
          ? 'Updating list...'
          : formatMessage(messages.selectTransmitFrequencies)}
        <Button
          circular
          icon="close"
          title="Close"
          onClick={closeModal}
          floated="right"
        />
      </Modal.Header>
      <Modal.Content>
        <Grid verticalAlign="middle" padded>
          <Grid.Row columns={componentCarrierQty}>
            {[...Array(componentCarrierQty).keys()].map((idx) => (
              <Grid.Column width={colWidth}>
                <FrequencySelectionList
                  bandwidth={bandwidths[idx]}
                  label={`Component Carrier ${idx + 1}`}
                  freqList={buildFrequencyList(
                    radios[idx].frequencies_mhz,
                    raster
                  )}
                  loading={isLoading || isPreviousData}
                  defaultValue={state.frequencies[idx]}
                  setState={setState}
                  index={idx}
                  formatMessage={formatMessage}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row columns={(8 / componentCarrierQty) as SemanticWIDTHS}>
            <Grid.Column width={(8 / componentCarrierQty) as SemanticWIDTHS}>
              <label>{formatMessage(messages.raster)}</label>
              <Select
                value={raster}
                options={rasterChoices}
                onChange={(e, data) => {
                  setState((prev) => {
                    return { ...prev, raster: Number(data.value) };
                  });
                }}
              ></Select>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          style={{ display: componentCarrierQty > 1 ? 'inline-block' : 'none' }}
          disabled={some(state.frequencies, (f) => f === null)}
          onClick={() => {
            setState({
              raster: null,
              frequencies: Array(componentCarrierQty).fill(null),
            });
          }}
        >
          {formatMessage(messages.clearAll)}
        </Button>
        <Button type="button" onClick={closeModal}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          type="button"
          color="blue"
          disabled={
            // Enable OK when no frequencies are selected or
            // when all of the frequencies are selected.
            some(state.frequencies, (f) => f === null) &&
            !every(state.frequencies, (f) => f === null)
          }
          onClick={() => {
            setModified(true);
            const { frequencies, raster } = state;
            frequencies.forEach((f, idx) => {
              setValue(`radios.${idx}.frequency.tx_frequency`, f, {
                shouldDirty: true,
                shouldValidate: true,
              });
            });
            setValue('properties.raster', raster);
            closeModal();
          }}
        >
          {formatMessage(messages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default injectIntl(FrequencyModal);
