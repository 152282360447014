import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getWithAuth } from '../../api';
const initialState = {
  equipmentConfigs: [],
  equipmentConfigsCount: 0,
  selectedItems: [],
  errorMessage: null,
  listConfigsError: null,
};


export const fetchEquipmentConfigs = createAsyncThunk(
  'equipment/fetchEquipmentConfigs',
  async (projectId) => {
    return await getWithAuth(`project/${projectId}/access_points/templates`);
  }
);


const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    setZeroEquipmentConfigs: (state, action) => {
      state.equipmentConfigs = [];
      state.equipmentConfigsCount = 0;
    },
    addEquipmentConfig: (state, action) => {
      state.equipmentConfigs.push(action.payload);
    },
  },
  extraReducers: {
    [fetchEquipmentConfigs.fulfilled]: (state, action) => {
      state.equipmentConfigs = action.payload;
      state.equipmentConfigsCount = action.payload.length;
    },
    [fetchEquipmentConfigs.rejected]: (state, action) => {
      state.listConfigsError = action.error.message;
    },
  },
});

export const {
  setZeroEquipmentConfigs,
  addEquipmentConfig,
} = equipmentSlice.actions;
export default equipmentSlice.reducer;
