import { last, range } from 'lodash';
import { Profile } from 'src/components/profilechart/Profile';
import { calcFresnelRadius, losHeight, radioHeight } from 'src/utils/P526_10';
import { pyround } from 'src/utils/useful_functions';

// always consider 200 points in the reflection line
const POINTS_IN_REFL = 200;

type ReflectionPoint = {
  x: number;
  y: number;
};

export type ReflectionLine = {
  optimumDiversityLocal: number | string;
  optimumDiversityRemote: number | string;
  // Points for the grey line in the plot.
  // They illustrate the reflection height including the worst earth curvature.
  greyReflection?: ReflectionPoint[];
  // Points for the blue line at the reflection height.
  // The start and end points can be used to plot the lines back
  // to the antenna points.
  blueReflection?: ReflectionPoint[];
};

/*
 * Calculate the Fresnel number for a point along a profile.
 */
const fresnelNumberLine = (
  pointRange: number, // metres
  pathLength: number, // metres
  localHeight: number, // ground + antenna in metres
  remoteHeight: number, // ground + antenna in metres
  frequencyGHz: number, // GHz
  reflectionHeight: number // metres
) => {
  const radio = radioHeight(pathLength, pointRange);
  const los = losHeight(pointRange, pathLength, localHeight, remoteHeight);
  const fresnel = calcFresnelRadius(frequencyGHz, pointRange, pathLength);
  return (radio + reflectionHeight - los) / fresnel;
};

/*
 * Return the reflection line from a given path profile.
 *
 * Returns null if the Fresnel radius calcuation gives invalid results.
 *
 * If the interpolated profile is used then it must be passed into this function.
 */
export const calculateReflectionLine = (
  profile: Profile,
  reflectionHeight: number,
  localFrequencyGHz: number,
  remoteFrequencyGHz: number
): ReflectionLine | null => {
  const { pathLength, localHeight, remoteHeight, heights } = profile;
  // The reflection line always uses the min of the local/remote frequencies
  const frequencyGHz = Math.min(localFrequencyGHz, remoteFrequencyGHz);
  const reflectionRanges = range(1, POINTS_IN_REFL).map(
    (i) => (i * pathLength) / POINTS_IN_REFL
  );
  const localAntennaHeight = heights[0] + localHeight;
  const remoteAntennaHeight = last(heights) + remoteHeight;
  const fresnelNumbers = reflectionRanges.map((r) =>
    fresnelNumberLine(
      r,
      pathLength,
      localAntennaHeight,
      remoteAntennaHeight,
      frequencyGHz,
      reflectionHeight
    )
  );

  const maxFresnelNumber = Math.max(...fresnelNumbers);

  // calculate the point of reflection along the path
  const midReflectionIndex = fresnelNumbers.indexOf(maxFresnelNumber);
  if (maxFresnelNumber > -1) {
    return null;
  }

  const reflectionRange = reflectionRanges[midReflectionIndex];
  // Use the limit to calculate the min/max reflection points
  const limit = -Math.sqrt(maxFresnelNumber * maxFresnelNumber + 0.79);

  // the "left" reflection point. Gives an index for the range value where
  // this point occurs.
  let earlyReflectionIndex = 0;
  while (earlyReflectionIndex < fresnelNumbers.length) {
    const fresnelNumber = fresnelNumbers[earlyReflectionIndex];
    if (fresnelNumber > limit) {
      break;
    }
    earlyReflectionIndex += 1;
  }

  // the "right" reflection point. Gives an index for the range value where
  // this point occurs.
  let lateReflectionIndex = midReflectionIndex;
  while (lateReflectionIndex < fresnelNumbers.length) {
    const fresnelNumber = fresnelNumbers[lateReflectionIndex];
    if (fresnelNumber < limit) {
      break;
    }
    lateReflectionIndex += 1;
  }

  // the lateReflectionIndex is off by one after running through the loop, so fix that
  lateReflectionIndex -= 1;

  const diff = 0.01;
  // calculate optimum spacing at each end of the link
  const diffFresnel = fresnelNumberLine(
    reflectionRange,
    pathLength,
    localAntennaHeight,
    remoteAntennaHeight,
    frequencyGHz,
    reflectionHeight + diff
  );

  // Calculate the optimum diversity spacing values for the UI
  const optimumHeightDiff =
    diff / (maxFresnelNumber * maxFresnelNumber - diffFresnel * diffFresnel);
  const optimumDiversityLocal =
    (optimumHeightDiff * pathLength) / (pathLength - reflectionRange);
  const optimumDiversityRemote =
    (optimumHeightDiff * pathLength) / reflectionRange;

  // Calculate the points that are used to plot the grey line
  let greyReflection = [];
  // calculate the points for the blue line at the reflection height
  let blueReflection = [];
  for (let idx = 0; idx < reflectionRanges.length; idx++) {
    const range = reflectionRanges[idx];
    const height = reflectionHeight + radioHeight(pathLength, range);
    greyReflection.push({ x: range, y: height });
    if (idx >= earlyReflectionIndex && idx <= lateReflectionIndex) {
      blueReflection.push({ x: range, y: height });
    }
  }

  return {
    optimumDiversityLocal: Number.isNaN(optimumDiversityLocal)
      ? ''
      : optimumDiversityLocal,
    optimumDiversityRemote: Number.isNaN(optimumDiversityRemote)
      ? ''
      : optimumDiversityRemote,
    greyReflection,
    blueReflection,
  };
};

/*
 * Test for calculateReflectionLine
 */
const calculateReflectionLineTest = () => {
  // testing
  const { ranges, heights, clutter_types, obstructions } = {
    ranges: [
      0.0, 29.901230084763785, 59.80246016952757, 89.70369025429136,
      119.60492033905514, 149.50615042381892, 179.40738050858272,
      209.3086105933465, 239.20984067811028, 269.1110707628741,
      299.01230084763785, 328.9135309324016, 358.81476101716544,
      388.7159911019292, 418.617221186693, 448.5184512714568,
      478.41968135622056, 508.32091144098433, 538.2221415257482,
      568.1233716105119, 598.0246016952757, 627.9258317800395,
      657.8270618648032, 687.728291949567, 717.6295220343309, 747.5307521190946,
      777.4319822038584, 807.3332122886222, 837.234442373386, 867.1356724581498,
      897.0369025429136, 926.9381326276773, 956.8393627124411, 986.740592797205,
      1016.6418228819687, 1046.5430529667324, 1076.4442830514963,
      1106.34551313626, 1136.2467432210237, 1166.1479733057877,
      1196.0492033905514, 1225.950433475315, 1255.851663560079,
      1285.7528936448427, 1315.6541237296065, 1345.5553538143704,
      1375.456583899134, 1405.3578139838978, 1435.2590440686618,
      1465.1602741534255, 1495.0615042381892, 1524.962734322953,
      1554.8639644077168, 1584.7651944924805, 1614.6664245772445,
      1644.5676546620082, 1674.468884746772, 1704.3701148315358,
      1734.2713449162995, 1764.1725750010633, 1794.0738050858272,
      1823.975035170591, 1853.8762652553546, 1883.7774953401185,
      1913.6787254248823, 1943.579955509646, 1973.48118559441,
      2003.3824156791736, 2033.2836457639373, 2063.1848758487013,
      2093.0861059334648, 2122.9873360182287, 2152.8885661029926,
      2182.789796187756, 2212.69102627252, 2242.592256357284,
      2272.4934864420475, 2302.3947165268114, 2332.2959466115753,
      2362.197176696339, 2392.0984067811028, 2421.9996368658667,
      2451.90086695063, 2481.802097035394, 2511.703327120158,
      2541.6045572049215, 2571.5057872896855, 2601.4070173744494,
      2631.308247459213, 2661.209477543977, 2691.110707628741,
      2721.0119377135043, 2750.913167798268, 2780.814397883032,
      2810.7156279677956, 2840.6168580525596, 2870.5180881373235,
      2900.419318222087, 2930.320548306851, 2960.221778391615,
      2990.1230084763783, 3020.0242385611423, 3049.925468645906,
      3079.8266987306697, 3109.7279288154336, 3139.6291589001976,
      3169.530388984961, 3199.431619069725, 3229.332849154489,
      3259.2340792392524, 3289.1353093240164, 3319.0365394087803,
      3348.937769493544, 3378.8389995783077, 3408.7402296630717,
      3438.641459747835, 3468.542689832599, 3498.443919917363,
      3528.3451500021265, 3558.2463800868904, 3588.1476101716544,
      3618.048840256418, 3647.950070341182, 3677.8513004259457,
      3707.752530510709, 3737.653760595473, 3767.554990680237,
      3797.4562207650006, 3827.3574508497645, 3857.2586809345285,
      3887.159911019292, 3917.061141104056, 3946.96237118882,
      3976.8636012735833, 4006.7648313583472, 4036.666061443111,
      4066.5672915278747, 4096.468521612638, 4126.3697516974025,
      4156.270981782166, 4186.1722118669295, 4216.073441951694,
      4245.974672036457, 4275.875902121221, 4305.777132205985,
      4335.678362290749, 4365.579592375512, 4395.480822460277, 4425.38205254504,
      4455.283282629804, 4485.184512714568, 4515.0857427993315,
      4544.986972884095, 4574.888202968859, 4604.789433053623,
      4634.690663138386, 4664.591893223151, 4694.493123307914,
      4724.394353392678, 4754.295583477442, 4784.1968135622055,
      4814.098043646969, 4843.999273731733, 4873.900503816497, 4903.80173390126,
      4933.702963986025, 4963.604194070788, 4993.505424155552,
      5023.406654240316, 5053.30788432508, 5083.209114409843,
      5113.1103444946075, 5143.011574579371, 5172.9128046641345,
      5202.814034748899, 5232.715264833662, 5262.616494918426, 5292.51772500319,
      5322.418955087954, 5352.320185172717, 5382.221415257482,
      5412.122645342245, 5442.0238754270085, 5471.925105511773,
      5501.826335596536, 5531.7275656813, 5561.628795766064, 5591.530025850828,
      5621.431255935591, 5651.332486020356, 5681.233716105119,
      5711.134946189883, 5741.036176274647, 5770.9374063594105,
      5800.838636444174, 5830.739866528938, 5860.641096613702,
      5890.542326698465, 5920.44355678323, 5950.344786867993, 5980.246016952757,
      6010.147247037521, 6040.048477122285, 6069.949707207048,
      6099.850937291812, 6129.752167376576, 6159.653397461339,
      6189.554627546104, 6219.455857630867, 6249.357087715631,
      6279.258317800395, 6309.159547885159, 6339.060777969922,
      6368.9620080546865, 6398.86323813945, 6428.7644682242135,
      6458.665698308978, 6488.566928393741, 6518.468158478505,
      6548.369388563269, 6578.270618648033, 6608.171848732796,
      6638.073078817561, 6667.974308902324, 6697.875538987088,
      6727.776769071852, 6757.677999156615, 6787.579229241379,
      6817.480459326143, 6847.381689410907, 6877.28291949567, 6907.184149580435,
      6937.085379665198, 6966.986609749962, 6996.887839834726,
      7026.7890699194895, 7056.690300004253, 7086.591530089017,
      7116.492760173781, 7146.393990258544, 7176.295220343309,
      7206.196450428072, 7236.097680512836, 7265.9989105976, 7295.900140682364,
      7325.801370767127, 7355.7026008518915, 7385.603830936655,
      7415.505061021418, 7445.406291106183, 7475.307521190946, 7505.20875127571,
      7535.109981360474, 7565.011211445238, 7594.912441530001,
      7624.813671614766, 7654.714901699529,
    ],
    heights: [
      80.06521599998635, 79.50071141183889, 79.87871250799901,
      80.77296212302124, 80.01450817889531, 80.9284105073948, 80.68143982886295,
      79.52733520398681, 77.91581833431323, 78.29796815718129,
      78.85913045727698, 77.45380852611491, 77.50620960638012, 79.0762992015143,
      81.64042735624274, 82.98338124763931, 81.38743884681298,
      79.56907019635582, 79.3546032948243, 79.89135532419368, 80.86128986005679,
      80.62589945937138, 79.92439180071253, 79.92782321976746, 80.6731636218883,
      80.84308381494508, 79.69369992249403, 76.09842057752181,
      73.83781727340211, 74.39187454187973, 76.25446300569588,
      77.40703597015039, 76.82362610060227, 76.9308108993414, 77.51713241405105,
      78.59969191234995, 79.85715342738223, 79.88460794351184,
      77.71942506138959, 75.05186224420537, 74.87991957443552,
      75.46566562277387, 73.02128932253638, 71.81825896855571, 70.4591481032401,
      68.69202376739395, 66.94607001668965, 66.04868768676013, 65.0,
      65.28325164621333, 65.64810130194189, 65.14041737096113,
      65.84194301842855, 65.09474281093503, 66.59161372291692, 68.5655397234288,
      68.19892989883903, 65.59951100155655, 63.83822778651506,
      62.06477189621302, 59.98596444201743, 58.07675073901237,
      56.33133977940268, 56.0, 55.962918170438186, 55.35901184885279,
      55.88451716292161, 57.176021755434476, 58.71927101387234,
      59.971720438960574, 60.86034156904821, 59.65941321441346,
      58.47497871407245, 56.734005148833695, 58.110380791526495,
      59.615032604271995, 59.804878983439224, 58.621181075426705,
      56.86681371243185, 56.1683460720755, 56.03567287931053, 56.72986834435296,
      55.27279983525614, 53.63716474444511, 52.7098947013817, 52.9613789486457,
      54.50023355652911, 55.369445000921985, 55.358040703762526,
      55.14403439855414, 54.08504103757696, 51.853840064470276,
      51.55530895207629, 51.38748480708063, 51.269809087677636,
      51.56993359552962, 51.340170677983906, 50.97057220226867,
      50.89086520055844, 50.99674286272304, 50.4152570117119, 50.90068785645079,
      51.03983226787372, 51.71383137845312, 51.50732609328497,
      49.93813943170491, 48.01568384185873, 47.43954546693067,
      47.07914727428397, 46.93263536258655, 46.82570033202819,
      46.000111965371616, 45.61184000279991, 45.41731750682948,
      44.39799264950943, 44.29107383885912, 43.66119169071359,
      42.329002863908954, 40.96066823685775, 40.16788575117462,
      41.13544563635378, 41.08623408981248, 40.542732996688756,
      41.36819343527327, 42.03843252852288, 42.523379271698104,
      44.0867757347869, 45.678581617604095, 45.817165676986775,
      46.62028784811406, 46.82520285036361, 45.76289905153503,
      45.24513479087226, 45.55112154394415, 45.48950468309911,
      43.72286440391433, 42.68471710979455, 41.424520510429375,
      40.71588012961662, 39.567844407896246, 38.550516502257096,
      37.63577234534305, 38.43763323395547, 39.4363223625342, 40.46004589258345,
      40.82996398634077, 40.82820119800922, 40.08229724971909,
      39.871779405464146, 40.13736197524041, 40.512178244608016,
      40.59684800478706, 42.56529088265688, 44.107707805544166,
      44.77542907876682, 45.03118096393729, 44.730587267405554,
      44.615424240590755, 45.08990165529667, 44.76957344845687,
      44.057061860790775, 44.093540840299156, 44.80864722448905,
      44.74978707865548, 45.13988474349706, 44.78090998606448, 44.5064158917566,
      44.143670242418125, 42.281228200137775, 40.87007619998309,
      40.09768296430146, 40.24346001491176, 41.371593979067036,
      43.11034455609044, 43.33058904722702, 43.8818819362823, 43.77516102055506,
      42.772881290176656, 41.04498897751911, 41.71963694756482,
      42.797458906723705, 43.722184695519864, 44.0, 43.02820545305076,
      42.389976449390815, 39.53557857029624, 37.606033822946905,
      36.95295444216284, 36.085053780188446, 33.78399982608971,
      29.04757331824139, 24.07652899133129, 17.55853522806006,
      9.134507817857534, 5.681562649905211, 4.802797921594641,
      5.545804348981063, 7.678585532348308, 8.758134209954278,
      10.246587527538564, 12.604567071156145, 16.008548305751468,
      19.52943784814733, 23.112938102938575, 28.711066425371037,
      34.937487204349196, 37.5553180575511, 36.910319709266105,
      34.96968338601756, 35.41818070788358, 39.933762913824516,
      40.99286404098548, 40.45565310479193, 39.474270399391315,
      38.554139448363685, 36.917075620093044, 34.59281034020023,
      30.226766543569774, 25.83391562841496, 23.231158944717585,
      22.15496938453324, 22.03843414054988, 22.164534669827745,
      19.084614524569446, 16.861325306721483, 14.077680171926945,
      10.21882058044092, 9.336696473935262, 9.159347091633038,
      8.507806048442944, 7.9884613745417745, 8.938426787461768,
      11.205033580587752, 11.83563121277325, 13.233274164392242,
      14.630911507204928, 16.51645717064572, 17.44889665139801,
      17.224094195638802, 14.908908950371671, 12.622131182518615,
      11.647655461791377, 10.863726774696783, 9.138820674923863,
      6.089624033118858, 4.554516970888471, 4.0, 4.646620948930446,
      6.898718005361843, 9.379853695541483, 11.850047411141986,
      15.000212244131177, 20.596851286597204, 25.68884833069751,
      28.164384152408907, 30.12505379403454, 32.09804799956664,
    ],
    clutter_types: [
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'A',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
      'H',
    ],
    obstructions: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0,
    ],
  };
  const clutterdetails = [
    {
      key: 'H',
      details: ['#FFCCD5', 9],
    },
    {
      key: 'C',
      details: ['#CCFFCC', 15],
    },
    {
      key: 'B',
      details: ['#0000FF', 0],
    },
    {
      key: 'I',
      details: ['#FF4D6A', 12],
    },
    {
      key: 'D',
      details: ['#66FF66', 15],
    },
    {
      key: 'A',
      details: ['#A22A2A', 0],
    },
    {
      key: 'J',
      details: ['#FF8080', 20],
    },
    {
      key: 'E',
      details: ['#00E600', 20],
    },
    {
      key: 'G',
      details: ['#EAAEAE', 5],
    },
    {
      key: 'K',
      details: ['#FF0000', 25],
    },
    {
      key: 'F',
      details: ['#004D00', 20],
    },
    {
      key: 'M',
      details: ['#808080', 50],
    },
    {
      key: 'L',
      details: ['#990000', 35],
    },
    {
      key: '',
      details: ['#FFFFFF', 0],
    },
  ];
  const profile = new Profile({
    ranges,
    heights,
    clutterTypes: clutter_types,
    obstructions,
    use_clutter: false,
    localHeight: 91.4,
    remoteHeight: 91.4,
    frequencyGHz: 5.8,
    clutterDetails: clutterdetails,
  });

  // Test the reflection line at 10 metres
  const reflLine10 = calculateReflectionLine(profile, 10, 5.8, 5.8);
  console.assert(
    pyround(reflLine10.optimumDiversityLocal, 2) == 0.87,
    reflLine10.optimumDiversityLocal
  );
  console.assert(
    pyround(reflLine10.optimumDiversityRemote, 2) == 0.62,
    reflLine10.optimumDiversityRemote
  );

  // Check that the blue line has the correct x/y coordinates
  console.assert(
    pyround(reflLine10.blueReflection[0].x, 1) == 4248.4,
    reflLine10.blueReflection[0].x
  );
  console.assert(
    pyround(reflLine10.blueReflection[0].y, 1) == 10.9,
    reflLine10.blueReflection[0].y
  );

  console.assert(
    pyround(last(reflLine10.blueReflection).x, 1) == 4707.6,
    last(reflLine10.blueReflection).x
  );
  console.assert(
    pyround(last(reflLine10.blueReflection).y, 1) == 10.8,
    last(reflLine10.blueReflection).y
  );

  // Test the reflection line at 20 metres
  const reflLine20 = calculateReflectionLine(profile, 20, 5.8, 5.8);
  console.assert(
    pyround(reflLine20.optimumDiversityLocal, 2) == 0.96,
    reflLine20.optimumDiversityLocal
  );
  console.assert(
    pyround(reflLine20.optimumDiversityRemote, 2) == 0.66,
    reflLine20.optimumDiversityRemote
  );
};

// run the test
// calculateReflectionLineTest();
