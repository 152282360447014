import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ReflectionLine } from 'src/model/Reflection';
import {
  HeightASL,
  Multiplier,
  getCalcOptimumDiversity,
  getCalcSpacing,
} from './ReflectionPanel';
import { RootStateOrAny } from 'src/store';

type unlicensedReflectionPanelProps = {
  path: any;
  reflectionLine: ReflectionLine;
  setModified: Function;
};

const diversityList = (local, remote) => [
  {
    key: 'local',
    value: 'local',
    text: local,
  },
  {
    key: 'remote',
    value: 'remote',
    text: remote,
  },
];

const UnlicensedReflectionPanel = ({
  path,
  reflectionLine,
  setModified,
}: unlicensedReflectionPanelProps) => {
  const { prefs } = useSelector((state: RootStateOrAny) => state.mainFrame);
  const { watch, control } = useFormContext();
  const { heightUnits } = prefs;
  const units = heightUnits === 'ft' ? 'feet' : 'meters';
  const localSiteName = path.local.site.name;
  const remoteSiteName = path.remote.site.name;
  let { optimumDiversityLocal, optimumDiversityRemote } = reflectionLine;
  const [currentMultiplier, currentDiversityEnd] = watch([
    'reflection.multiplier',
    'reflection.diversity_end',
  ]);
  const isLocal = currentDiversityEnd === 'local';
  const endSiteName = isLocal ? localSiteName : remoteSiteName;
  let endSiteSpacing = isLocal ? optimumDiversityLocal : optimumDiversityRemote;
  if (endSiteSpacing) {
    endSiteSpacing = getCalcSpacing(
      endSiteSpacing,
      currentMultiplier,
      heightUnits
    );
  }
  if (optimumDiversityLocal) {
    optimumDiversityLocal = getCalcOptimumDiversity(
      optimumDiversityLocal,
      heightUnits
    );
  }
  if (optimumDiversityRemote) {
    optimumDiversityRemote = getCalcOptimumDiversity(
      optimumDiversityRemote,
      heightUnits
    );
  }

  return (
    <Form.Group className="reflectionPanel">
      <HeightASL path={path} setModified={setModified} />

      <Form.Field>
        <label>Optimum Spacing at {localSiteName}:</label>
        <Form.Group>
          <Form.Input
            value={optimumDiversityLocal}
            transparent={true}
            readOnly={true}
          />
          <Label>{units}</Label>
        </Form.Group>
      </Form.Field>

      <Form.Field>
        <label>Optimum Spacing at {remoteSiteName}:</label>
        <Form.Group>
          <Form.Input
            value={optimumDiversityRemote}
            transparent={true}
            readOnly={true}
          />
          <Label>{units}</Label>
        </Form.Group>
      </Form.Field>

      <Form.Field>
        <label htmlFor="diversityEnd">End with Diversity:</label>
        <Controller
          name="reflection.diversity_end"
          control={control}
          defaultValue={path.reflection.diversity_end}
          render={({ field }) => (
            <Dropdown
              className="valueWidth"
              data-testid="diversityEnd"
              placeholder="Select Diversity"
              selection
              options={diversityList(localSiteName, remoteSiteName)}
              value={field.value}
              onChange={(e, { value }) => {
                field.onChange(value);
                setModified(true);
              }}
            />
          )}
        />
      </Form.Field>

      <Multiplier path={path} setModified={setModified} />

      <Form.Field className="bold">
        <label>Selected Spacing at {endSiteName}:</label>
        <Form.Group>
          <Form.Input
            value={endSiteSpacing}
            transparent={true}
            readOnly={true}
          />
          <Label>{units}</Label>
        </Form.Group>
      </Form.Field>
    </Form.Group>
  );
};

export default injectIntl(UnlicensedReflectionPanel);
