import React from 'react';
import { Form, Label, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { setModified } from 'src/pages/mesh/mesh.reducer';
import { Link } from 'react-router-dom';
import { meterToAny } from 'src/utils/useful_functions';
import { RECALC_COLOR } from 'src/app.constants';
import NumberControlSemantic from 'src/components/controls/NumberControlSemantic';
import { get, round } from 'lodash';
import { GenericScaledField } from 'src/components/controls/rhf/GenericScaledField';
import { RootStateOrAny } from 'src/store';

// to display selected values in ui with proper naming.
const equipmentSummaryLabelMap = {
  eq_summary: 'Summary',
  sector_choices: 'Sector',
  channel: 'Channel',
  link_golay_choices: 'Golay Code',
  polarity: 'Polarity',
  antenna_height: 'Antenna Height',
  antenna_azimuth: 'Antenna Azimuth',
  bearing: 'Link Bearing',
  tilt: 'Calculated Tilt',
  tx_antenna_gain: 'Antenna Gain',
  power: 'Power',
  noise: 'Interference',
};

function EquipmentSummaryPanel({
  currentMesh,
  panelData,
  title,
  endName,
}: any) {
  const { meshId } = useParams();
  const dispatch = useDispatch();
  const {
    control,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const { permissionWrite } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const prefs = useSelector((state: any) => state.mainFrame.prefs);
  const possibleRecalcRdx = useSelector(
    (state: any) => state.mesh.possibleRecalc
  );
  const possibleRecalc = possibleRecalcRdx[meshId] ?? false;

  const sectorIndex = watch(`${endName}.sector`, 1) - 1;
  const sectorNoise = currentMesh[endName].radios[sectorIndex].power.noise;

  let info, noiseFieldProps;
  const use_noise = currentMesh[endName].radios[sectorIndex].power.use_noise;
  if (use_noise) {
    info = (
      <Label basic>
        {`in ${currentMesh[endName].radios[sectorIndex].equipment.bandwidth} channel`}
      </Label>
    );
    noiseFieldProps = {
      units: 'dBm',
      type: 'number',
      hoverMessage: `Range -144 dBm to -40 dBm.\nSector set to ${sectorNoise.toFixed(
        1
      )} dBm.`,
      info: info,
      min: -144,
      max: -40,
      scaleFactor: 1,
      valueType: 'decimal',
      step: 0.1,
      precision: 1,
    };
  }

  const getFieldChoices = (choices, lnkField) => {
    return choices.map((item, index) => ({
      key: index,
      // by default dropdown value is 'Same as DN' and then concatinating dn_golay value
      // to display purpose only
      text: item === 'Same as DN' ? `${item} (${panelData['dn_golay']})` : item,
      value: item,
    }));
  };

  return (
    <Table celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Link to={`/aps/${currentMesh[endName].id}`}>{title}</Link>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Object.keys(panelData).map((key) => {
          if (key === 'noise' && !use_noise) {
            return null;
          }

          const label = equipmentSummaryLabelMap[key];
          const name = {
            sector_choices: 'sector',
            link_golay_choices: 'link_golay',
          }[key];

          let displayValue: string;
          let choices = null;
          const isNumberField = key === 'noise';

          if (key === 'link_golay_choices' || key === 'sector_choices') {
            choices = getFieldChoices(panelData[key], key);
            if (choices.length <= 1) {
              displayValue = panelData[key];
            }
          } else {
            if (key === 'antenna_height') {
              displayValue = `${meterToAny(
                panelData[key],
                prefs.heightUnits
              )} ${prefs.heightUnits}`;
            } else {
              displayValue = panelData[key];
            }
          }

          let textStyle = {};
          let borderStyle = {};
          if (
            possibleRecalc &&
            (key === 'link_golay_choices' ||
              key === 'channel' ||
              key === 'polarity' ||
              key === 'tx_antenna_gain' ||
              key === 'power')
          ) {
            textStyle = { color: RECALC_COLOR };
            borderStyle = { borderColor: RECALC_COLOR };
          }

          return (
            equipmentSummaryLabelMap[key] && (
              // fix the height so tables always appear same height, including
              // the case where one has sector choices and the other doesnt
              <Table.Row key={`${endName}.${key}`} style={{ height: '37px' }}>
                <Table.Cell
                  style={{ textAlign: 'right', ...textStyle }}
                  width={5}
                >
                  {label}
                </Table.Cell>
                <Table.Cell className="mesh-eq-summary">
                  {choices?.length > 1 ? (
                    <Controller
                      name={`${endName}.${name}`}
                      control={control}
                      defaultValue={panelData[name]}
                      render={({ field: { ref, onChange, ...rest } }) => (
                        <Form.Select
                          options={choices}
                          {...rest}
                          style={borderStyle}
                          disabled={!permissionWrite}
                          onChange={(_, { value }) => {
                            const sectorIndex = parseInt(value) - 1;
                            const meshNoise =
                              currentMesh.summary.equipment_summary[endName]
                                .noise;
                            const currentSectorNoise =
                              currentMesh[endName].radios[sectorIndex]?.power
                                .noise;
                            const prevSectorNoise = sectorNoise;

                            // Only update the noise value when
                            // the current selection has use_noise set to true
                            // and when the mesh end does not overwrite the
                            // sector noise value.
                            if (
                              key === 'sector_choices' &&
                              !dirtyFields[endName]?.noise &&
                              currentMesh[endName].radios[sectorIndex]?.power
                                .use_noise &&
                              (round(currentSectorNoise, 1) ==
                                round(meshNoise, 1) ||
                                round(prevSectorNoise, 1) ==
                                  round(meshNoise, 1))
                            ) {
                              const newSectorNoise =
                                currentMesh[endName].radios[sectorIndex]?.power
                                  .noise;
                              setValue(`${endName}.noise`, newSectorNoise, {
                                shouldDirty: false,
                              });
                            }
                            dispatch(setModified({ id: meshId, value: true }));
                            return onChange(value);
                          }}
                        />
                      )}
                    />
                  ) : isNumberField ? (
                    <div className="mesh-eq-number-field">
                      <GenericScaledField
                        getter={`${endName}.${key}`}
                        setModified={(value) => {
                          dispatch(setModified({ id: meshId, value: true }));
                        }}
                        watch={[meshId, sectorIndex, sectorNoise]}
                        {...noiseFieldProps}
                      />
                      {info}
                    </div>
                  ) : (
                    <span style={textStyle}>{displayValue}</span>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default injectIntl(EquipmentSummaryPanel);
