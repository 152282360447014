import React, { useEffect } from 'react';
import { Dimmer, Form, Loader, Popup } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { NetworkPlan } from './terragraph.modal';
import { injectIntl } from 'react-intl';
import messages from 'src/messages';

function NetworkPlanPanel(props: {
  networkPlanFormData: NetworkPlan;
  networkPlanFormDataChange: Function;
  loading: boolean;
  setValidate: Function;
  intl: any;
}) {
  let { networkPlanFormData, networkPlanFormDataChange, setValidate } = props;

  const { formatMessage } = props.intl;

  const {
    getValues,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: networkPlanFormData,
  });

  const onFormValueChange = () => {
    networkPlanFormDataChange(getValues());
    validateForm();
  };

  useEffect(() => {
    validateForm();
  });

  const validateForm = () => {
    setValidate(true);
  };

  if (props.loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted inline>
          Loading
        </Loader>
      </Dimmer>
    );
  }
  return (
    <Form
      onChange={onFormValueChange}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Form.Field style={{ display: 'inline-flex' }}>
        <Controller
          name="networkPlan"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Popup
              content={formatMessage(messages.distributionNetworkHelpText)}
              position="right center"
              trigger={
                <Form.Radio
                  {...rest}
                  name={'networkPlan'}
                  label={formatMessage(messages.Distribution)}
                  onChange={async () => {
                    setValue('networkPlan', 'Distribution');
                    onFormValueChange();
                  }}
                  checked={getValues('networkPlan') === 'Distribution'}
                  error={
                    errors.networkPlan && {
                      content: errors.networkPlan.message,
                    }
                  }
                />
              }
            />
          )}
        />
      </Form.Field>

      <Form.Field style={{ display: 'inline-flex' }}>
        <Controller
          name="networkPlan"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <Popup
              content={formatMessage(messages.meshOnlyNetworkCostModelHelpText)}
              position="right center"
              trigger={
                <Form.Radio
                  style={{ marginLeft: '64px' }}
                  {...rest}
                  name={'networkPlan'}
                  label={`${formatMessage(messages.Mesh)} - ${
                    networkPlanFormData.networkSubPlan
                  }`}
                  onChange={async () => {
                    setValue('networkPlan', 'Mesh');
                    onFormValueChange();
                  }}
                  checked={getValues('networkPlan') === 'Mesh'}
                  error={
                    errors.networkPlan && {
                      content: errors.networkPlan.message,
                    }
                  }
                />
              }
            />
          )}
        />
      </Form.Field>
    </Form>
  );
}

export default injectIntl(NetworkPlanPanel);
