import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { injectIntl } from 'react-intl';
import {
  Input,
  Button,
  Grid,
  Statistic,
  Checkbox,
  Dropdown,
  Table,
  Header,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import messages from 'src/messages';
import { getWithAuth } from '../api';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';

const dayMultiplier = 24 * 60 * 60 * 1000;

function dateDifferenceDays(start, end) {
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / dayMultiplier);
}

function UserStatsPanel({ intl }) {
  const { formatMessage } = intl;

  const { email } = useSelector((state: RootStateOrAny) => state.mainFrame);

  const [data, setData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [error, setError] = useState(null);
  // initail value is yesterdays date
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date());
  const [days, setDays] = useState(1);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const gridRef = useRef();
  const [currentDataPoint, setCurrentDataPoint] = useState('user_counts');
  const isItFirstRenderRef = useRef(true);

  const [userEmail, setUserEmail] = useState(email);

  useEffect(() => {
    const startString = startDate.toISOString().split('T')[0];
    const endString = endDate.toISOString().split('T')[0];
    const urlMapping = {
      user_counts: 'stats/users',
      projects_per_country: 'stats/country/projects',
      users_per_country: 'stats/country/users',
      user_stats: `stats/users/${userEmail}`,
    };

    const setRowDataProp = {
      user_counts: 'user_logins',
      projects_per_country: 'country_codes',
      users_per_country: 'country_codes',
    };
    if (isItFirstRenderRef.current || buttonPressed) {
      console.log(
        'urlMapping[currentDataPoint]-->',
        urlMapping[currentDataPoint]
      );
      getWithAuth(
        currentDataPoint === 'user_stats'
          ? urlMapping[currentDataPoint]
          : `${urlMapping[currentDataPoint]}?start=${startString}&end=${endString}`
      )
        .then((res) => {
          if (currentDataPoint !== 'user_stats') setData(res);
          const resData =
            currentDataPoint === 'user_stats'
              ? res
              : res[setRowDataProp[currentDataPoint]];
          setRowData(resData);
          setError(null);
        })
        .catch((err) => {
          setError(err.detail);
        });
      setButtonPressed(false);
      isItFirstRenderRef.current = false;
    }
  }, [buttonPressed]);

  function StartDateChanged(date) {
    setStartDate(date);
    setDays(dateDifferenceDays(date, endDate));
  }

  function EndDateChanged(date) {
    setEndDate(date);
    setDays(dateDifferenceDays(startDate, date));
  }

  function DataPointDrpDwnChanged(e, data) {
    setCurrentDataPoint(data.value);
    if (!buttonPressed) setButtonPressed(true);
  }

  const onButtonPress = () => {
    if (!buttonPressed) setButtonPressed(true);
  };

  const handleUserEmailChange = (email) => {
    setUserEmail(email.trim());
  };

  const columnDefs = {
    user_counts: [
      { field: 'date', headerName: 'Date', flex: 1 },
      { field: 'users', headerName: 'Users', flex: 1 },
      { field: 'new_users', headerName: 'New Users', flex: 1 },
    ],
    projects_per_country: [
      { field: 'cc', headerName: 'Country Code', flex: 1 },
      { field: 'sub', headerName: 'Sub', flex: 1 },
      { field: 'date', headerName: 'Date', flex: 1 },
      { field: 'qty', headerName: 'Quantity', flex: 1 },
    ],
    users_per_country: [
      { field: 'cc', headerName: 'Country Code', flex: 1 },
      { field: 'sub', headerName: 'Sub', flex: 1 },
      { field: 'date', headerName: 'Date', flex: 1 },
      { field: 'qty', headerName: 'Quantity', flex: 1 },
    ],
  };

  const dataPoints = {
    user_counts: 'User Counts',
    projects_per_country: 'Projects Per Country',
    users_per_country: 'Users Per Country',
    user_stats: 'User Stats',
  };

  const dataPointDrpDwnOptions = [];

  for (const [key, value] of Object.entries(dataPoints)) {
    dataPointDrpDwnOptions.push({
      key: key,
      text: value,
      value: key,
    });
  }

  const filedsList = ['Query Type', 'Start Date', 'End Date', ' No Of Days'];

  const handleDayChange = (event) => {
    if (event.target.value === '' || !/^[0-9]+$/.test(event.target.value)) {
      setStartDate(new Date(Date.now() - dayMultiplier));
      setDays(null);
      return;
    }

    const value = parseInt(event.target.value);
    setDays(value);

    setStartDate(new Date(endDate.getTime() - (value - 1) * dayMultiplier));
  };

  if (gridRef?.current) {
    if (showAll) {
      gridRef.current?.api?.paginationSetPageSize(Number(rowData.length));
    } else {
      gridRef.current?.api?.paginationSetPageSize(5);
    }
  }

  if (!error && (rowData == null || data == null))
    return (
      <Dimmer active inverted>
        <Loader inverted inline>
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <>
      <Grid columns={5} className="ui form">
        <Grid.Row style={{ paddingBottom: '5px' }}>
          {(currentDataPoint !== 'user_stats'
            ? filedsList
            : filedsList.filter((field) => field === 'Query Type')
          ).map((field) => {
            return (
              <Grid.Column>
                <Header as="h4">
                  <Header.Content>{field}</Header.Content>
                </Header>
              </Grid.Column>
            );
          })}
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0px' }}>
          <Grid.Column>
            <Dropdown
              search
              selection
              placeholder="User Counts"
              defaultValue={dataPoints.user_counts}
              options={dataPointDrpDwnOptions}
              onChange={DataPointDrpDwnChanged}
            />
          </Grid.Column>
          {currentDataPoint !== 'user_stats' ? (
            <>
              <Grid.Column>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => StartDateChanged(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </Grid.Column>
              <Grid.Column>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => EndDateChanged(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  type="text"
                  name="daysDatePicker"
                  value={days}
                  onChange={handleDayChange}
                />
              </Grid.Column>
            </>
          ) : (
            <Grid.Column width={8}>
              <Input
                label="Email"
                type="email"
                name="userEmail"
                value={userEmail}
                onChange={(e) => handleUserEmailChange(e.target.value)}
                style={{ width: '100%' }}
              />
            </Grid.Column>
          )}
          <Grid.Column>
            <Button
              onClick={onButtonPress}
              color="blue"
              disabled={
                (currentDataPoint === 'user_stats' && !userEmail) ||
                !startDate ||
                !endDate ||
                days > 1000
                  ? true
                  : false
              }
            >
              {formatMessage(messages.adminToolsRunQuery)}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {currentDataPoint !== 'user_stats' ? (
        <>
          <Grid columns={4} stretched padded>
            <Grid.Column>
              <Statistic label="Users" value={data.users} />
            </Grid.Column>
            <Grid.Column>
              <Statistic label="opt in users" value={data.opt_in} />
            </Grid.Column>
            <Grid.Column>
              <Statistic label="cambium users" value={data.cambium_users} />
            </Grid.Column>
            <Grid.Column>
              <Statistic
                label="opt in cambium users"
                value={data.cambium_opt_in}
              />
            </Grid.Column>
          </Grid>
          <div id="stats-container">
            <AgGridReact
              ref={gridRef}
              className="ag-theme-alpine generic-grid"
              width="50%"
              pagination={true}
              paginationPageSize={
                showAll && rowData?.length ? rowData.length : 5
              }
              rowData={Array.isArray(rowData) ? rowData : []}
              columnDefs={columnDefs[currentDataPoint]}
              domLayout={'autoHeight'}
              rowHeight={28}
              defaultColDef={{
                flex: 1,
                minWidth: 70,
                sortable: true,
                resizable: true,
                filter: true,
                wrapText: true,
                autoHeight: true,
              }}
              style={{ display: 'flex', height: '200px' }}
            />
          </div>
          <div className="showAllRows">
            <Checkbox
              label="Show All Rows"
              checked={showAll}
              onChange={() => {
                setShowAll(!showAll);
              }}
            />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <Message negative>
              <p>{error}</p>
            </Message>
          ) : (
            <Table
              celled
              style={{
                marginTop: '2rem',
                overflow: 'auto',
                display: 'inline-block',
                maxHeight: '45vh',
              }}
            >
              <Table.Body>
                {!Array.isArray(rowData) &&
                  Object.entries(rowData).map(([key, value]) => (
                    <Table.Row>
                      <Table.Cell style={{ width: '30%' }}>
                        <Header as="h4">
                          <Header.Content>
                            {key
                              .split('_')
                              .map(
                                (str) =>
                                  str.charAt(0).toUpperCase() + str.slice(1)
                              )
                              .join(' ')}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell style={{ width: '70%' }}>
                        {Array.isArray(value) ? value.join(' , ') : value}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          )}
        </>
      )}
    </>
  );
}
export default injectIntl(UserStatsPanel);
