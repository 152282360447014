import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Form, Popup, Select } from 'semantic-ui-react';
import { postWithAuth } from 'src/api';
import { useParams } from 'react-router-dom';
import { RootStateOrAny } from 'src/store';

const TiltAngleInput = ({
  label,
  value,
  setValue,
  setError,
  error,
  minTiltRange,
  maxTiltRange,
}) => {
  return (
    <Popup
      content={`Range ${minTiltRange}° to ${maxTiltRange}°`}
      trigger={
        <Form.Input
          label={label}
          type="number"
          units="°"
          value={value}
          onChange={(e) => {
            setError((error) => ({
              ...error,
              [label]: !/^-?\d+$/.test(e.target.value),
            }));
            if (
              parseFloat(e.target.value) < parseFloat(minTiltRange) ||
              parseFloat(e.target.value) > parseFloat(maxTiltRange)
            ) {
              setError((error) => ({
                ...error,
                [label]: true,
              }));
            }
            setValue(e.target.value);
          }}
          error={
            error[label] && {
              content: `Range ${minTiltRange}° to ${maxTiltRange}°`,
              pointing: 'up',
            }
          }
        />
      }
    />
  );
};

const TiltStepInput = ({ label, value, setValue }) => {
  const intervals = [
    { text: '0.5', value: 0.5 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
  ];
  return (
    <Form.Field
      name="interval"
      control={Select}
      label={label}
      options={intervals}
      value={value}
      onChange={(e, data) => setValue(data.value)}
    />
  );
};

const TiltSubmit = ({
  label,
  minTilt,
  maxTilt,
  intervalStep,
  setError,
  error,
}) => {
  const project_id = useSelector(
    (state: RootStateOrAny) => state.mainFrame.projectId
  );
  const { id } = useParams();
  const [calculating, setCalculating] = useState(false);

  function calculate() {
    setCalculating(true);
    postWithAuth(`project/${project_id}/${id}/calc_tilt`, {
      min_tilt: parseFloat(minTilt),
      max_tilt: parseFloat(maxTilt),
      interval: intervalStep,
    })
      .then(() => setCalculating(false))
      .catch((err) => {
        setCalculating(false);
      });
  }

  return (
    <Button
      color="blue"
      onClick={calculate}
      disabled={
        Object.values(error).some((v) => v) ||
        parseFloat(minTilt) > parseFloat(maxTilt) ||
        calculating
      }
    >
      {label}
    </Button>
  );
};

function TiltInputPanel(props) {
  const [minTilt, setMinTilt] = useState(props.minTilt);
  const [maxTilt, setMaxTilt] = useState(props.maxTilt);
  const minTiltRange = props.minTilt;
  const maxTiltRange = props.maxTilt;
  const [intervalStep, setIntervalStep] = useState(1);
  const [error, setError] = useState({});
  const status = useSelector((state: RootStateOrAny) => state.calcTilt.status);

  return (
    <Form className="min-max-values">
      <Form.Group widths={'equal'}>
        <TiltAngleInput
          label="Min Tilt"
          value={minTilt}
          setValue={setMinTilt}
          setError={setError}
          error={error}
          minTiltRange={minTiltRange}
          maxTiltRange={maxTiltRange}
        />
        <TiltAngleInput
          label="Max Tilt"
          value={maxTilt}
          setValue={setMaxTilt}
          setError={setError}
          error={error}
          minTiltRange={minTiltRange}
          maxTiltRange={maxTiltRange}
        />
        <TiltStepInput
          label="Interval"
          value={intervalStep}
          setValue={setIntervalStep}
        />
      </Form.Group>
      {status === 'running' ? (
        <Button onClick={props.cancel}>Cancel</Button>
      ) : (
        <TiltSubmit
          label="Calculate"
          minTilt={minTilt}
          maxTilt={maxTilt}
          intervalStep={intervalStep}
          setError={setError}
          error={error}
        />
      )}
    </Form>
  );
}

export default injectIntl(TiltInputPanel);
