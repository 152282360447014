import { encodeLatLong } from 'src/utils/useful_functions';

export const LatRenderer = (props) => {
  const { latLngFormat } = props;
  return encodeLatLong(props.value, latLngFormat);
};

export const LongRenderer = (props) => {
  const { latLngFormat } = props;
  return encodeLatLong(props.value, latLngFormat, false);
};
