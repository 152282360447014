import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootStateOrAny } from 'src/store';
import { postWithAuth } from 'src/api';
import { Button, Checkbox, Message, Modal } from 'semantic-ui-react';
import additionalMessages from '../../messages';
import { useIntl } from 'react-intl';
import { downloadReport } from './reportHelpers';
import { useMutation } from '@tanstack/react-query';
import messages from '../../messages';
import { useQuery } from '@tanstack/react-query';
import { getWithAuth } from 'src/api';

export const FCCLicenseCoordinationPopup = (props) => {
  const { projectId, projectName } = useSelector(
    (state: RootStateOrAny) => state.mainFrame
  );
  const { formatMessage } = useIntl();
  const { openFccModal, setOpenFccModal } = props;

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  
  const [selected, setSelected] = useState([]);
  const { data: fccLinksData, refetch: refetchFccLinks } = useQuery({
    queryKey: ['FCCLicense', projectId],
    queryFn: async () => {
      return await getWithAuth(`project/${projectId}/reports/fcc_license`);
    },
    retry: false,
    enabled: false,
  });
  
  const [isChecked, setIsChecked] = useState(
    Array(fccLinksData?.length).fill(false)
  );

  const createReport = async () => {
    try {
      const response = await postWithAuth(
        `project/${projectId}/reports/fcc_license`,
        {
          links: selected,
        }
      );
      // Report id is returned as string from server.
      return await response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setErrMessage(null);
    refetchFccLinks();
  }, [openFccModal]);

  const { mutate, isLoading } = useMutation(createReport, {
    onSuccess: (taskId: any) => {
      
      toast(<Message>FCC License Coordination report generation started</Message>);
      handleCancel();
    },
    onError: (err: any) => {
      setErrMessage(err.detail || 'Error downloading report');
    },
  });

  const handleOk = () => {
    mutate();
  };

  const selectionChange = (data, id, i) => {
    const newChecked = [...isChecked];
    newChecked[i] = data.checked;
    if (data.checked) {
      selected.push(id);
      setSelected(selected);
    } else {
      const newSelected = selected.filter(function (item) {
        return item !== id;
      });
      setSelected(newSelected);
    }
    setIsChecked(newChecked);
    if (newChecked.every((value) => value === true)) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  };

  const checkAll = () => {
    setIsCheckedAll(true);
    setIsChecked(Array(fccLinksData?.length).fill(true));
    setSelected(fccLinksData.map((el: any) => el.id));
  };

  const unCheckAll = () => {
    setIsCheckedAll(false);
    setIsChecked(Array(fccLinksData?.length).fill(false));
    setSelected([]);
  };

  const handleCancel = () => {
    unCheckAll();
    setOpenFccModal(false);
  };

  return (
    <Modal open={openFccModal} size="mini">
      <Modal.Header>
        {'FCC License Coordination'}
        <Button
          circular
          icon="close"
          title={formatMessage(messages.close)}
          floated="right"
          onClick={() => {
            setOpenFccModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Content>
        {errMessage ? <Message error>{errMessage}</Message> : ''}

        <Message info>
          Which links would you like to request licenses for: <br />
          (List contains licensed band links only)
        </Message>
        <div className="fcc-license-links">
          <b>Link Name</b>
          {fccLinksData?.map((el: any, i) => (
            <Checkbox
              key={i}
              label={el.identifier}
              onChange={(e, data) => selectionChange(data, el.id, i)}
              checked={isChecked[i]}
            ></Checkbox>
          ))}
        </div>
        <div className="d-flex">
          <Button disabled={isCheckedAll} onClick={checkAll}>
            Check All
          </Button>
          <Button disabled={!isCheckedAll} onClick={unCheckAll}>
            Uncheck All
          </Button>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>
          {formatMessage(additionalMessages.cancel)}
        </Button>
        <Button
          color="blue"
          onClick={handleOk}
          disabled={!selected.length}
          loading={isLoading}
        >
          {formatMessage(additionalMessages.ok)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
