import { YaxisMap } from './PerformanceChart.types';

export const MAX_AVAILABILITY = 99.99999;

export enum Capacity {
  Kbps = 'kbps',
  Mbps = 'Mbps',
  Gbps = 'Gbps',
}

// API data to Y-Axis value conversion
export const Y_AXIS_MAP: YaxisMap = {
  0: 0,
  10: 90,
  20: 99,
  30: 99.9,
  40: 99.99,
  50: 99.999,
  60: 99.9999,
  70: 99.99999,
};

export const MARGIN = {
  TOP: 20,
  RIGHT: 20,
  BOTTOM: 30,
  LEFT: 100,
};
