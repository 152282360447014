import {
  accessPointEvents,
  styleAccessPoint,
  apClusterMarker,
} from './apFeatures';
import {
  linkEvents,
  getLinkStyler,
  ptpLinkClusterMarker,
  pmpLinkClusterMarker,
} from './linkFeatures';
import {
  siteFeatureEvents,
  networkSiteMarker,
  subscriberSiteMarker,
} from './siteFeatures';

/**
 * Return the appropriate Leaflet GeoJSON for the feature kind.
 *
 * The resulting object can be passed when an new L.geoJSON
 * instance is created, e.g.
 *
 * new L.geoJSON(featureCollection, getLayer("network_site"));
 *
 * @param {String} kind - The object kind.
 *                        "network_site", "subscriber_site", "access_point",
 *                        "ptp_link" and "pmp_link" are currently supported.
 */

const getGeoJsonLayerOptions = (kind) => {
  return {
    network_site: {
      onEachFeature: siteFeatureEvents,
      pointToLayer: networkSiteMarker,
    },
    subscriber_site: {
      onEachFeature: siteFeatureEvents,
      pointToLayer: subscriberSiteMarker,
    },
    access_point: {
      onEachFeature: accessPointEvents,
      style: styleAccessPoint,
      pointToLayer: apClusterMarker,
    },
    ptp_link: {
      onEachFeature: linkEvents,
      style: getLinkStyler('#88DD88', 3),
      pointToLayer: ptpLinkClusterMarker,
    },
    mesh_link: {
      onEachFeature: linkEvents,
      style: getLinkStyler('#1550bd', 3),
      pointToLayer: ptpLinkClusterMarker,
    },
    pmp_link: {
      onEachFeature: linkEvents,
      style: getLinkStyler('#55a4f3', 1.5),
      pointToLayer: pmpLinkClusterMarker,
    },
  }[kind];
};

export default getGeoJsonLayerOptions;
