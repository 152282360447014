const mbpsLimits = {
  'PTP 850E': 200,
  'PTP 850EX': 200,
  'PTP 850C': 200,
  V1000: 0,
  V2000: 0,
  V3000: 0,
  V5000: 0,
};

export function getUnits(getValues, precision) {
  const equipment = getValues('local.radios.0.equipment');
  const { product, bandwidth } = equipment;
  if (bandwidth > mbpsLimits[product]) {
    return ['Gbps', 3];
  } else {
    return ['Mbps', precision];
  }
}
