import React from 'react';
import { injectIntl } from 'react-intl';
import { Step } from 'semantic-ui-react';

function CreateAccessPointWizard({ step, intl }) {
  if (
    step === 1 &&
    sessionStorage.getItem('create_pmp_ND_expanded_panels') !== null
  ) {
    sessionStorage.removeItem('create_pmp_ND_expanded_panels');
  }
  return (
    <Step.Group widths={3} ordered>
      <Step completed={step > 1} active={step === 1}>
        <Step.Content>
          <Step.Title>{'Create/Select Template'}</Step.Title>
          <Step.Description>{}</Step.Description>
        </Step.Content>
      </Step>

      <Step completed={step > 2} active={step === 2}>
        <Step.Content>
          <Step.Title>{'View/Update Configuration'}</Step.Title>
          <Step.Description>{}</Step.Description>
        </Step.Content>
      </Step>
      <Step completed={step > 3} active={step === 3}>
        <Step.Content>
          <Step.Title>{'Create PMP Network Devices'}</Step.Title>
          <Step.Description>{}</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}

export default injectIntl(CreateAccessPointWizard);
