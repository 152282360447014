import React from 'react';
import { injectIntl } from 'react-intl';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form, Label } from 'semantic-ui-react';
import StoredAccordion from 'src/components/StoredAccordion';
import {
  getAllEquipmentFromChoices,
  isChoicesComplex,
  complexControlGetters,
  configSupportsComplexGetters,
  complexLinkLabels,
} from './utils';
import { useLinkKind } from './hooks';
import './PTPEquipmentPanel.css';
import { getDisplayValue } from 'src/utils/useful_functions';
import get from 'lodash/get';

const getterRe = /(\w+)\.radios\.0\.equipment\.(\w+)/;

function EquipmentControl(props) {
  const {
    field,
    getter,
    level,
    choices,
    setModified,
    refreshChoices,
    pathIdx,
  } = props;
  const { control, setValue, getValues } = useFormContext();
  const { kind: lkKind } = useLinkKind();
  const permissionWrite = useSelector(
    (state: any) => state.mainFrame.permissionWrite
  );

  return (
    <Controller
      control={control}
      name={getter}
      render={({ field: { ref, onChange, ...rest } }) => (
        <Form.Select
          options={level.choices}
          {...rest}
          disabled={!permissionWrite}
          onChange={(e, data) => {
            onChange(data.value);

            // sync equipment to other paths if required
            // NOTE: pathIdx is only defined for complex links
            if (pathIdx != null) {
              // we get here if the list of getters > 1,
              // e.g. cross polar polarization
              // due to checks in complexControlGetters, we know that the structure
              // "field.lk[lkKind]" is not null
              if (field.lk[lkKind].sync != null) {
                // this will appropriately sync other paths, e.g. set the reverse
                // polarization on the other path(s)
                field.lk[lkKind].sync(getValues, setValue, pathIdx, data.value);
              }
            } else if (isChoicesComplex(choices)) {
              // we get here if its complex but the attr is synced across
              // all paths, e.g. 4+0 co polar polarization
              if (getterRe.test(getter)) {
                // TODO following line is broken (pathIdx is always null)
                const otherIdx = pathIdx === 1 ? 0 : 1;
                const sync = field.getter.replace(
                  getterRe,
                  `$1.radios.${otherIdx}.equipment.$2`
                );
                setValue(sync, data.value, { shouldDirty: true });
              }
            }

            if (field.afterOnChange != null) {
              field.afterOnChange(data.value, { getValues, setValue });
            }

            setModified(true);
            refreshChoices({ field });
          }}
        />
      )}
    />
  );
}

function isValidField(choices, attrName) {
  function isValidForChoice(choice) {
    return (
      choice.hasOwnProperty(attrName)
      || (choice.sync_params != null && get(choice, attrName) != null)
    );
  }

  if (choices.length === 1) {
    return isValidForChoice(choices[0]);
  } else {
    return choices.some(isValidForChoice);
  }
}

function PTPEquipmentPanel(props: any) {
  const { control, getValues, setValue } = useFormContext();
  const { lk, kind: lkKind } = useLinkKind();

  const { setModified, panel, path, choices } = props;
  const allEquipmentChoices = getAllEquipmentFromChoices(choices);

  return (
    <StoredAccordion name={`ptp_${panel.name}`} title={panel.title}>
      {panel.fields.map((field, i) => {
        if (!isValidField(allEquipmentChoices, field.attrName)) {
          return null;
        }

        if (
          field.show != null &&
          !field.show({ choices, formGetter: getValues })
        ) {
          return null;
        }

        let message: any = null;
        if (field.info != null) {
          const text = field.info({ path, choices, formGetter: getValues });
          if (text != null) {
            message = (
              <Label
                color={field.infoColor ?? 'yellow'}
                pointing
                style={{ marginTop: 0, width: '100%' }}
              >
                {text}
              </Label>
            );
          }
        }

        let ctrl: any = null;
        let showTopLabel = true;
        let level = null;

        const getterList = complexControlGetters(field, lk, lkKind);
        if (getterList.length > 1 && configSupportsComplexGetters(field, choices)) {
          showTopLabel = false;
          const levels = allEquipmentChoices.map((c) => get(c, field.attrName));
          ctrl = (
            <div className="splitEquipmentWrapper">
              {getterList.map((getter, i) => {
                // sometimes when changing link kind, we get an intermediate
                // render - in that case we need to fallback to "1+0 style"
                // choices
                const level =
                  levels[i] ?? get(allEquipmentChoices[0], field.attrName);
                return (
                  <span className="field" key={`${getter}-${i}`}>
                    <label>
                      {field.label ?? level.display_name} (
                      {complexLinkLabels[i]})
                    </label>
                    {level.choices != null && level.choices.length > 1 ? (
                      <EquipmentControl
                        field={field}
                        getter={getter}
                        level={level}
                        choices={choices}
                        setModified={setModified}
                        refreshChoices={props.refreshChoices}
                        pathIdx={i}
                      />
                    ) : (
                      <p>{getDisplayValue(field, level)}</p>
                    )}
                  </span>
                );
              })}
            </div>
          );
        } else {
          level = get(allEquipmentChoices[0], field.attrName);
          if (level.choices != null && level.choices.length > 1) {
            ctrl = (
              <EquipmentControl
                field={field}
                getter={getterList[0]}
                level={level}
                choices={choices}
                setModified={setModified}
                refreshChoices={props.refreshChoices}
              />
            );
          } else {
            ctrl = <p>{getDisplayValue(field, level)}</p>;
          }
        }

        return (
          <Form.Field key={field.getter}>
            {showTopLabel ? (
              <label>{field.label ?? level.display_name}</label>
            ) : null}
            {ctrl}
            {message}
          </Form.Field>
        );
      })}
    </StoredAccordion>
  );
}

export default injectIntl(PTPEquipmentPanel);
