import React from 'react';
import { Button, Grid, Modal, Icon } from 'semantic-ui-react';
import { AntennaGainTable } from './GainLimitTables';
import { PTP_ANTENNA } from './antenna.service';

type AntennaType = 'ptp' | 'sm';

type GainLimitProps = {
  open: boolean;
  kind: AntennaType;
  togglePopup: () => void;
};

/*
 * Gain Limit Modal that is shown when the "i" button is clicked
 * in the custom antenna panel.
 */
export const GainLimitModal = (props: GainLimitProps) => {
  const { open, kind, togglePopup } = props;

  return (
    <Modal size="small" open={open}>
      <Modal.Header>
        <Grid columns={'equal'}>
          <Grid.Column>
            <div>Antenna Gain Limits</div>
          </Grid.Column>
          <Grid.Column>
            <Button onClick={() => togglePopup()} circular floated="right" icon>
              <Icon name="close" />
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content scrolling>
        <AntennaGainTable kind={kind} />
      </Modal.Content>

      <Modal.Actions>
        <Button color="blue" type="button" onClick={() => togglePopup()}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
