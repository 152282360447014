import React, { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainFrame from '../pages/mainframe/MainFrame';
import { TERMS_VERSION } from '../app.constants';
import TermsAndConditions from './TermsAndConditions';
import { getWithAuth } from '../api';
import { setTermsVersion } from '../pages/mainframe/mainframe.reducer';
import config from '../config';

export function MainLayout() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { loggedIn, projectId, projectModified, termsVersion } = useSelector(
    (state: any) => state.mainFrame
  );

  useEffect(() => {
    if (loggedIn && termsVersion === undefined) {
      getWithAuth('terms/version')
        .then((response) => {
          dispatch(setTermsVersion(response.version));
        })
        .catch((err) => {
          dispatch(setTermsVersion(null));
          console.error(err);
        });
    }
  }, [loggedIn, termsVersion]);

  if (!loggedIn) {
    if (location.pathname === '/afc') {
      window.location.assign(`${config.apiURL}/saml/login?afc=1`);
    } else {
      return <Navigate to="/login" />;
    }
  }

  if (
    !projectId &&
    !(location.pathname === '/projects' || location.pathname === '/afc')
  ) {
    return <Navigate to="/projects" />;
  }

  // === because termsVersion defaults to undefined, we only want to show
  // the panel once we've definitely checked the terms
  if (termsVersion === null || termsVersion < TERMS_VERSION) {
    return <TermsAndConditions />;
  }

  return (
    <MainFrame className="App" modified={projectModified}>
      <Outlet />
    </MainFrame>
  );
}
