import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWithAuth } from 'src/api';
import { getCanvas } from 'src/utils/mapUtils';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import { VALID_PMP_BANDS } from 'src/model/BandDefinition';

const initialState = {
  viewshedMode: false,
  creating: false,
  selectingLocations: false,
  selectingPaused: false,
  selectedLocations: {
    sites: [],
    locations: [],
    formState: null,
  },
  viewsheds: {},
  pendingViewsheds: {},
  failedViewsheds: [],
  error: null,
};

export const VS_BAND_CHOICES = VALID_PMP_BANDS;

export const SITE_MAX_HEIGHT = 'site_max_height';
export const HEIGHT_ABOVE_GROUND = 'above_ground';
export const HEIGHT_ABOVE_CLUTTER = 'above_clutter';
export const MIN_HEIGHT_ABOVE_CLUTTER = 'min_height_above_clutter';

export const fetchViewsheds = createAsyncThunk(
  'viewshed/fetchViewsheds',
  async (projectId) => {
    return await getWithAuth(`project/${projectId}/viewsheds`);
  }
);

export const createViewshedsFromConfig = createAsyncThunk(
  'viewshed/createViewshedsFromConfig',
  async ({ projectId, key }: any) => {
    return await getWithAuth(`project/${projectId}/viewsheds/config/${key}`);
  }
);

export function anyVisibleViewsheds(viewsheds) {
  for (const keyObj of Object.values(viewsheds)) {
    for (const { visible } of Object.values(keyObj)) {
      if (visible) {
        return true;
      }
    }
  }
  return false;
}

export function anyHiddenViewsheds(viewsheds) {
  for (const keyObj of Object.values(viewsheds)) {
    for (const { visible } of Object.values(keyObj)) {
      if (!visible) {
        return true;
      }
    }
  }
  return false;
}
function removeViewshedFromPending(state, key, id) {
  if (key in state.pendingViewsheds) {
    const arr = state.pendingViewsheds[key].viewsheds;
    const idx = arr.findIndex((v) => v.id === id);
    if (idx !== -1) {
      state.pendingViewsheds[key].viewsheds.splice(idx, 1);
    }

    // remove key if all viewsheds are done
    if (state.pendingViewsheds[key].viewsheds.length === 0) {
      delete state.pendingViewsheds[key];
    }
  }
}

const viewshedsSlice = createSlice({
  name: 'viewsheds',
  initialState,
  reducers: {
    setCreateModalVisibility: (state, action) => {
      const value = action.payload;
      state.creating = value;
      if (!value) {
        state.selectingLocations = false;
      }
    },
    startLocationSelection: (state, action) => {
      state.selectingLocations = true;
      state.selectingPaused = false;
      state.selectedLocations.formState = action.payload.values;
      getCanvas().selectViewshedLocations(action.payload.data);
    },
    toggleSelectionPause: (state) => {
      state.selectingPaused = !state.selectingPaused;

      const canvas = getCanvas();
      if (state.selectingPaused) {
        canvas.pauseSelectViewshedLocations();
      } else {
        canvas.resumeSelectViewshedLocations();
      }
    },
    finishLocationSelection: (state) => {
      state.selectingLocations = false;
      const canvas = getCanvas();
      const { sites, locations } = canvas.selectedViewshedLocations();
      canvas.message(null);
      canvas.resetState();

      state.selectedLocations = {
        sites,
        locations,
        formState: state.selectedLocations.formState,
      };
    },
    resetSelectedLocations: (state) => {
      state.selectedLocations = {
        sites: [],
        locations: [],
        formState: null,
      };
    },
    resetSelectingViewsheds: (state) => {
      state.selectingLocations = false;
      state.selectingPaused = false;
      state.creating = false;
    },
    setPendingViewsheds: (state, action) => {
      // NOTE when viewsheds are cached, its likely that viewshedCompleted is called
      // before this method so we need to check a few things
      const { data, key, viewsheds } = action.payload;
      const completed = state.viewsheds[key] || {};
      const completedIds = Object.keys(completed);
      const pending = viewsheds.filter((v) => !completedIds.includes(v.id));

      if (pending.length !== 0) {
        state.pendingViewsheds[key] = { data, viewsheds: pending };
      }
    },
    viewshedCompleted: (state, action) => {
      const {
        key,
        id,
        name,
        site_name,
        latitude,
        longitude,
        color,
        image,
        bounds,
        radius,
        radio_los,
        band,
        tower_height,
        tower_height_type,
        remote_height_type,
        remote_height_clutter,
        remote_height,
        is_lidar,
        use_clutter,
      } = action.payload;

      removeViewshedFromPending(state, key, id);

      // add to completed viewsheds store
      if (!(key in state.viewsheds)) {
        state.viewsheds[key] = {};
      }

      state.viewsheds[key][id] = {
        name,
        site_name,
        latitude,
        longitude,
        color,
        image,
        bounds,
        visible: true,
        radius,
        radio_los,
        band,
        tower_height,
        tower_height_type,
        remote_height_type,
        remote_height_clutter,
        remote_height,
        is_lidar,
        use_clutter,
      };

      // draw on map
      getCanvas().showViewshed(id, image, bounds);
    },
    removeFailedViewshed: (state, action) => {
      const { key, id } = action.payload;
      removeViewshedFromPending(state, key, id);
      state.failedViewsheds = state.failedViewsheds.filter((i) => i !== id);
    },
    viewshedFailed: (state, action) => {
      const { id, message } = action.payload;
      state.failedViewsheds.push(id);
      if (message) {
        toast(<Message error>{message}</Message>, {
          autoClose: false,
        });
      }
    },
    showViewshed: (state, action) => {
      const { id, key } = action.payload;
      const viewshed = state.viewsheds[key][id];
      if (!viewshed.visible) {
        const { image, bounds } = viewshed;
        viewshed.visible = true;
        getCanvas().showViewshed(id, image, bounds);
      }
    },
    hideViewshed: (state, action) => {
      const { id, key } = action.payload;
      state.viewsheds[key][id].visible = false;
      getCanvas().hideViewshed(id);
    },
    hideAllViewsheds: (state) => {
      const canvas = getCanvas();
      for (const [key, obj] of Object.entries(state.viewsheds)) {
        for (const id of Object.keys(obj)) {
          state.viewsheds[key][id].visible = false;
          canvas.hideViewshed(id);
        }
      }
    },
    showAllViewsheds: (state) => {
      const canvas = getCanvas();
      for (const [key, obj] of Object.entries(state.viewsheds)) {
        for (const id of Object.keys(obj)) {
          if (!state.viewsheds[key][id].visible) {
            const { image, bounds } = state.viewsheds[key][id];
            state.viewsheds[key][id].visible = true;
            canvas.showViewshed(id, image, bounds);
          }
        }
      }
    },
    removeViewshed: (state, action) => {
      const { id, key } = action.payload;
      if (state.viewsheds[key][id].visible) {
        getCanvas().hideViewshed(id);
      }
      delete state.viewsheds[key][id];

      if (Object.keys(state.viewsheds[key]).length === 0) {
        delete state.viewsheds[key];
      }
    },
    deleteViewshedConfig: (state, action) => {
      const { key } = action.payload;
      const canvas = getCanvas();
      for (const [id, viewshed] of Object.entries(state.viewsheds[key])) {
        if ((viewshed as any).visible) {
          canvas.hideViewshed(id);
        }
      }
      delete state.viewsheds[key];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewsheds.fulfilled, (state, action) => {
      state.viewsheds = {};
      state.pendingViewsheds = {};
      state.failedViewsheds = [];

      if (action.payload.length === 0) {
        return;
      }

      for (const [viewshed, site] of action.payload) {
        if (!(viewshed.key in state.viewsheds)) {
          state.viewsheds[viewshed.key] = {};
        }
        if (!(viewshed.id in state.viewsheds[viewshed.key])) {
          state.viewsheds[viewshed.key][viewshed.id] = {
            name: viewshed.name,
            site_name: site.name,
            latitude: viewshed.latitude,
            longitude: viewshed.longitude,
            color: viewshed.color,
            image: viewshed.aws_url,
            bounds: viewshed.bounds,
            visible: false,
            radius: viewshed.radius,
            tower_height: viewshed.tower_height,
            tower_height_type: viewshed.tower_height_type,
            remote_height_type: viewshed.remote_height_type,
            remote_height_clutter: viewshed.remote_height_clutter,
            radio_los: viewshed.radio_los,
            band: viewshed.band,
            remote_height: viewshed.remote_height,
            is_lidar: viewshed.is_lidar,
            use_clutter: viewshed.include_clutter,
          };
        }
      }
    });

    builder.addCase(fetchViewsheds.rejected, (state, action) => {
      state.error = 'Failed to fetch viewsheds';
    });

    builder.addCase(createViewshedsFromConfig.fulfilled, (state, action) => {
      const {
        radius,
        tower_height_type,
        tower_height,
        remote_height_type,
        remote_height,
        remote_height_clutter,
        radio_los,
        band,
        include_clutter,
        color,
        is_lidar,
      } = action.payload;

      let remote_height_type_enum: string;
      if (remote_height_type === 1) {
        remote_height_type_enum = HEIGHT_ABOVE_CLUTTER;
      } else if (remote_height_type === 2) {
        remote_height_type_enum = MIN_HEIGHT_ABOVE_CLUTTER;
      } else {
        remote_height_type_enum = HEIGHT_ABOVE_GROUND;
      }

      state.creating = true;
      state.selectedLocations.formState = {
        siteMaxHeight: 10,
        towerHeightType: tower_height_type,
        towerHeight: tower_height,
        radius: radius,
        band: band,
        color: `#${color}`,
        radioLos: radio_los,
        useClutter: include_clutter,
        remoteHeightType: remote_height_type_enum,
        heightAboveGround: remote_height,
        heightAboveClutter: remote_height_clutter,
        highResViewshed: is_lidar,
      };
    });

    return builder;
  },
});

export const {
  setCreateModalVisibility,
  startLocationSelection,
  finishLocationSelection,
  resetSelectedLocations,
  setPendingViewsheds,
  viewshedCompleted,
  removeFailedViewshed,
  viewshedFailed,
  toggleSelectionPause,
  showViewshed,
  hideViewshed,
  hideAllViewsheds,
  showAllViewsheds,
  removeViewshed,
  deleteViewshedConfig,
  resetSelectingViewsheds,
} = viewshedsSlice.actions;
export default viewshedsSlice.reducer;
