import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  Form,
  Grid,
  Header,
  Segment,
  Accordion,
  Button,
} from 'semantic-ui-react';
import { isEmpty, set } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NDAntennaPanel from '../nd/NDAntennaPanel';
import NDEquipmentPanel from '../nd/NDEquipmentPanel';
import NDPowerPanel from '../nd/NDPowerPanel';
import NDSectorPanel from '../nd/NDSectorPanel';
import { getProductPanels } from '../nd/utils';
import { updatePostData } from '../nd/configs/config-utils';
import NDComponentCarriersPanel from '../nd/NDComponentCarriersPanel';

import { postWithAuth } from 'src/api';
import CreateAccessPointModalFooter from './CreateAccessPointModalFooter';
import messages from 'src/messages';
import { queryCacheKeys } from '../nd/useNDData';
import { getChoicePayload } from '../nd/NDForm';
import { isPMP450v } from '../config-utils';

function EquipmentTemplateForm({
  projectId,
  template,
  handleNextStep,
  handlePrevStep,
  closeHandler,
  choices,
  formatMessage,
}) {
  const [modified, setModified] = useState<boolean>(false);
  const formMethods = useForm({ defaultValues: template });
  const { handleSubmit, getValues, setValue, reset, formState } = formMethods;
  const { isSubmitting, errors } = formState;
  const product = getValues('radios.0.equipment.product');
  const qc = useQueryClient();
  const panels = getProductPanels(product, true);
  const [band, regulation] = getValues([
    'radios.0.equipment.band',
    'radios.0.equipment.regulation',
  ]);
  const templateName = `${band} / ${product} / ${regulation}`;
  const { key, choicesKey } = queryCacheKeys(projectId, true, template.id);
  // we need to clear the react query cache on component
  // unmount to avoid issues like rendering the stale data
  // when we search from menu and navigate to the page
  useEffect(() => {
    return () => {
      reset();
      qc.removeQueries({
        queryKey: key,
      });
    };
  }, [projectId, template.id]);

  const onSubmit = async (formData: FieldValues) => {
    formData = updatePostData(formData);
    set(formData, 'name', templateName);
    let putUrl = `project/${projectId}/access_point/${template.id}/template`;
    await postWithAuth(putUrl, formData, 'PUT');
    setModified(false);
    handleNextStep({ templateName, templateId: template.id });
  };

  const refreshChoices = ({ field }) => {
    if (!field) {
      // should not occur in production (programmer error)
      throw new Error('Refresh choices requested without field info');
    }

    const mergedFormData = getValues();
    // request new choices based on the updated form state (i.e.
    // in response to a user action: onChange)
    updateChoices.mutate({
      template: mergedFormData,
      attr: field.attrName,
    });
  };

  const updateChoices = useMutation(
    ({ template, attr }: { template: any; attr: string }) => {
      const payload = getChoicePayload(template);
      const choicesPayload = {
        project_id: projectId,
        ap: payload,
        changed: attr,
      };
      return Promise.allSettled([
        postWithAuth(`equipment/access_point/choices`, choicesPayload),
        Promise.resolve(attr),
      ]);
    },
    {
      onSuccess: (updateChoicesResponse) => {
        const equipmentChoicesResponse = (
          updateChoicesResponse[0] as PromiseFulfilledResult<any>
        ).value;
        const attr = (updateChoicesResponse[1] as PromiseFulfilledResult<any>)
          .value;
        // TODO after changing the backend api we can remove the below code
        // from 84 to 88 which changes equipment choices array to object
        const equipmentChoiceMap = {};
        equipmentChoicesResponse['equipment'].forEach((attr) => {
          equipmentChoiceMap[attr.attr_name] = attr;
        });
        equipmentChoicesResponse['equipment'] = equipmentChoiceMap;
        // update choices query data to cause form to rerender
        // with updated correct choices
        qc.setQueryData(choicesKey, () => ({ ...equipmentChoicesResponse }));

        // update react hook form internal state based on
        // new choices on a field by field basis (e.g. when form
        // values fall out of sync with choices like changing product)
        const product = equipmentChoicesResponse.equipment['product']['value'];
        const newPanels = getProductPanels(product, true);
        newPanels.syncFormState(
          getValues,
          setValue,
          newPanels,
          equipmentChoicesResponse,
          attr
        );
      },
    }
  );
  let style = { marginLeft: '22%', width: '53%' };
  return (
    <Segment
      style={style}
      className="detailPanel aplayout"
      floated="left"
      basic
      compact
    >
      <FormProvider {...formMethods}>
        <Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <Grid stackable columns={1}>
            <Grid.Column id="ap-details" width={16}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header as={Header}>{templateName}</Header>
              </div>
              <Accordion exclusive={false} fluid>
                <NDEquipmentPanel
                  ap={template}
                  isTemplate={true}
                  modified={modified}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                  choices={choices}
                  panel={panels.equipment}
                ></NDEquipmentPanel>
                {isPMP450v(product) && (
                  <NDComponentCarriersPanel
                    ap={template}
                    isTemplate={true}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.componentCarrier}
                  ></NDComponentCarriersPanel>
                )}
                {panels.sector && (
                  <NDSectorPanel
                    ap={template}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.sector}
                  ></NDSectorPanel>
                )}
                {panels.product && (
                  <NDEquipmentPanel
                    ap={template}
                    isTemplate={true}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.product}
                  ></NDEquipmentPanel>
                )}
                <NDAntennaPanel
                  ap={template}
                  modified={modified}
                  panel={panels.antenna}
                  choices={choices}
                  setModified={setModified}
                  refreshChoices={refreshChoices}
                ></NDAntennaPanel>
                {panels.power && (
                  <NDPowerPanel
                    ap={template}
                    modified={modified}
                    panel={panels.power}
                    choices={choices}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                  ></NDPowerPanel>
                )}
                {panels.sectorPower && (
                  <NDSectorPanel
                    ap={template}
                    modified={modified}
                    setModified={setModified}
                    refreshChoices={refreshChoices}
                    choices={choices}
                    panel={panels.sectorPower}
                  ></NDSectorPanel>
                )}
              </Accordion>
            </Grid.Column>
          </Grid>
          <CreateAccessPointModalFooter>
            <Button
              floated="right"
              type="submit"
              style={{ marginBottom: '15px' }}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {formatMessage(messages.next)}
            </Button>
            <Button
              floated="right"
              style={{ marginBottom: '15px' }}
              disabled={isSubmitting}
              onClick={handlePrevStep}
            >
              {formatMessage(messages.previous)}
            </Button>
            <Button
              floated="right"
              style={{ marginBottom: '15px' }}
              onClick={closeHandler}
            >
              {formatMessage(messages.cancel)}
            </Button>
          </CreateAccessPointModalFooter>
        </Form>
      </FormProvider>
    </Segment>
  );
}

export default EquipmentTemplateForm;
