import NDSectorPanel from '../../nd/NDSectorPanel';
import { PMPFormField, PMPFormPanel } from '../../nd/utils';
import {
  antennaPanelFields,
  pmp450EquipmentFields,
  productPanel,
  power,
  antenna,
  ndAntennaGain,
  capacity,
} from './PMP450ViewConfig';
import { get } from 'lodash';
import { band, getUserPower, product, regulation } from '../../config-utils';
import NDEquipmentPanel from '../../nd/NDEquipmentPanel';
import { getSMEIRPConfig, getSMPowerConfig } from './config-utils';
import { syncFormState } from '../utils';

const sectorTitles = ['Component Carrier 1', 'Component Carrier 2'];

const radioQuantity: PMPFormField = {
  attrName: 'radio_qty',
  getter: 'radio_qty',
  refreshesChoices: true,
  label: 'Number of Component Carriers',
  afterOnChange(newValue, { getValues, setValue }, newChoices) {
    const radio0 = getValues('radios.0');
    if (newValue === 2) {
      setValue('radios.1', radio0);
      setValue('radios.1.antennas.0.sector', 2);
      // Reset use_noise so that it reads from the AP
      setValue('radios.1.power.use_noise', false);
    } else {
      setValue('radios', [radio0]);
    }
  },
};

const equipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDEquipmentPanel,
  show({ formGetter }) {
    const radioQty = formGetter('radio_qty');
    return radioQty == null || radioQty === 1;
  },
  fields: [radioQuantity, ...pmp450EquipmentFields],
};

const multiRadioEquipment: PMPFormPanel = {
  kind: 'equipment',
  title: 'Equipment',
  name: 'sm_equipment',
  component: NDSectorPanel,
  // hacked radio quantity to appear on top
  // before titles, so ignore it fieldlayout
  // so 0 element indicates Component Carrier
  // with attrName radios.0.equipment.ap_radio_number
  fieldLayout: [[0, 1], [2, 3], [4], [5], [6]],
  show({ formGetter }) {
    return formGetter('radio_qty') > 1;
  },
  customClass: 'cnwave-power',
  sectorTitles,
  fields: [
    radioQuantity,
    {
      attrName: 'ap_radio_number',
      getter: 'radios.0.antennas.0.sector',
      label: 'Component Carrier',
      refreshesChoices: true,
      afterOnChange(newValue, formMethods, newChoices) {
        const { setValue } = formMethods;
        setValue('radios.0.antennas.0.sector', newValue);
      },
      componentProps({ ap }) {
        const options = [];
        options.push({
          id: 1,
          text: 1,
          value: 1,
        });
        return { options };
      },
    },
    {
      attrName: 'ap_radio_number',
      getter: 'radios.1.antennas.0.sector',
      refreshesChoices: true,
      label: 'Component Carrier',
      afterOnChange(newValue, formMethods, newChoices) {
        const { setValue } = formMethods;
        setValue('radios.1.antennas.0.sector', newValue);
      },
      componentProps({ ap }) {
        const options = [];
        options.push({
          id: 2,
          text: 2,
          value: 2,
        });
        return { options };
      },
    },
    {
      ...band,
      label: 'Band - Carrier 1',
    },
    {
      ...band,
      getter: 'radios.1.equipment.band',
      // since band is readonly value, reading from ap and displaying it
      componentProps({ ap }) {
        const carrier2Band = get(ap, `radios.${1}.equipment.band`);
        const carrierQty = get(ap, 'radios.0.equipment.carrier_qty');
        const label =
          carrierQty === 1 ? 'Band - Carrier 1' : 'Band - Carrier 2';
        const options = [
          { text: carrier2Band, value: carrier2Band, id: 'carrier2Band-2' },
        ];
        return { options, label };
      },
    },
    product,
    capacity,
    regulation,
  ],
};

export const sectorPower: PMPFormPanel = {
  kind: 'power',
  title: 'Power',
  name: 'sm_power',
  customClass: 'cnwave-power',
  sectorTitles: ['Carrier 1', 'Carrier 2'],
  show({ formGetter, ap }) {
    return (
      formGetter('radio_qty') > 1 &&
      get(ap, 'radios.0.equipment.carrier_qty') > 1
    );
  },
  fields: [
    getSMEIRPConfig(true),
    {
      ...getSMEIRPConfig(true, 1),
      warning({ sm }): string {
        return sm.radios[1]?.warnings?.eirp;
      },
    },
    getSMPowerConfig(true),
    getSMPowerConfig(true, 1),
    {
      ...getUserPower(true),
      nextValue(currentValue, newChoices, formGetter, attr) {
        return newChoices.power[0].user_power;
      },
      min: -30,
      max: 40,
    },
    {
      ...getUserPower(true, 1),
      nextValue(currentValue, newChoices, formGetter, attr) {
        return newChoices.power[1].user_power;
      },
      min: -30,
      max: 40,
    },
  ],
};

export const pmp450vViewConfig = {
  syncFormState,
  equipment,
  multiRadioEquipment,
  product: productPanel,
  sectorPower,
  antenna: {
    ...antenna,
    fieldLayout: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9, 10]],
    fields: [
      ...antennaPanelFields,
      {
        ...ndAntennaGain,
        label: 'ND Antenna Gain - Carrier 2',
        getter: 'radios.1.antennas.0.ap_antenna_gain',
        show({ formGetter, ap }) {
          return (
            formGetter('radio_qty') > 1 &&
            get(ap, 'radios.0.equipment.carrier_qty') > 1
          );
        },
        warning({ formGetter }) {
          return formGetter(`radios.1.warnings.gain_warning`);
        },
      },
    ],
  },
  power: {
    ...power,
    show({ formGetter, ap }) {
      const radioQty = formGetter('radio_qty');
      const carrierQty = get(ap, 'radios.0.equipment.carrier_qty');
      // if the ND is 450 family product other than 450v and SM is 45v product
      // then radioQty is null before save and carrierQty is null after save
      // so we need to check both cases (radioQty == null || carrierQty == null)
      return (
        radioQty == null ||
        carrierQty == null ||
        (carrierQty >= 1 && radioQty == 1) ||
        (carrierQty == 1 && radioQty >= 1)
      );
    },
  },
};
